import { groupBy } from "remeda";

import { Brand } from "@models/Brand";
import { Collection } from "@models/Collection";
import { Showroom } from "@models/Showroom";

export namespace CalendarFilter {
  export type CalendarFilterShowroom = {
    id: string;
    name: string;
    collections: {
      id: string;
      name: string;
      brand: {
        id: string;
        name: string;
      };
    }[];
  };

  export type FilterValues = Record<
    Showroom["id"],
    Record<Brand["id"], Array<Collection["id"]>>
  >;

  type ShowroomBrandCollections = {
    id: string;
    name: string;
    brands: {
      id: string;
      name: string;
      collections: {
        id: string;
        name: string;
      }[];
    }[];
  }[];

  export class Manager {
    filterAvailableValues: FilterValues;

    // helper for displaying the filters
    filterDisplay: ShowroomBrandCollections;

    values: FilterValues;

    constructor(showrooms: CalendarFilterShowroom[], values: FilterValues) {
      this.filterDisplay = Manager.buildFilterDisplay(showrooms);
      this.filterAvailableValues = Manager.buildAvailableValues(showrooms);
      this.values = values;
    }

    /**
     * This method gives the informations needed to display the calendar filters for a specific list of showrooms
     * @param showrooms List of the showrooms to display filters for
     * @returns the list of the available filters (showrooms, brands, collections)
     */
    static buildFilterDisplay(
      showrooms: CalendarFilterShowroom[],
    ): ShowroomBrandCollections {
      return showrooms.map((showroom) => {
        const collectionsByBrand = groupBy(
          showroom.collections,
          (collection) => collection.brand.id,
        );
        return {
          name: showroom.name,
          id: showroom.id,
          brands: Object.entries(collectionsByBrand).map(
            ([brandId, collections]) => ({
              name: collections[0].brand.name,
              id: brandId,
              collections: collections.map((collection) => ({
                id: collection.id,
                name: collection.name,
              })),
            }),
          ),
        };
      });
    }

    /**
     * This method gives the available values for the calendar filters given a list of showrooms
     * @param showrooms the list of showrooms
     * @returns FilterValues matching "everything is selected"
     */
    static buildAvailableValues(
      showrooms: CalendarFilterShowroom[],
    ): FilterValues {
      return Manager.buildFilterDisplay(showrooms).reduce(
        (showroomAcc, showroom) => ({
          ...showroomAcc,
          [showroom.id]: showroom.brands.reduce(
            (brandAcc, brand) => ({
              ...brandAcc,
              [brand.id]:
                brand.collections.map((collection) => collection.id) || [],
            }),
            {} as FilterValues[string],
          ),
        }),
        {} as FilterValues,
      );
    }

    isShowroomSelected(showroomId: string) {
      return showroomId in this.values;
    }

    isBrandSelected(showroomId: string, brandId: string) {
      return (
        this.isShowroomSelected(showroomId) &&
        brandId in this.values[showroomId]
      );
    }

    isCollectionSelected(
      showroomId: string,
      brandId: string,
      collectionId: string,
    ) {
      return (
        this.isShowroomSelected(showroomId) &&
        this.isBrandSelected(showroomId, brandId) &&
        this.values[showroomId][brandId].includes(collectionId)
      );
    }

    toggleShowroom(showroomId: string) {
      const showroomSelected = this.isShowroomSelected(showroomId);
      const newValues = { ...this.values };

      if (showroomSelected) {
        delete newValues[showroomId];
        return newValues;
      }

      newValues[showroomId] = this.filterAvailableValues[showroomId];
      return newValues;
    }

    toggleBrand(showroomId: string, brandId: string) {
      const showroomSelected = this.isShowroomSelected(showroomId);
      const brandSelected = this.isBrandSelected(showroomId, brandId);
      const newValues = { ...this.values };
      // if showroom is not selected, select it with just that brand
      if (!showroomSelected) {
        newValues[showroomId] = {
          [brandId]: this.filterAvailableValues[showroomId][brandId],
        };
        return newValues;
      }

      // showroom is selected
      // if brand is not selected
      if (!brandSelected) {
        newValues[showroomId][brandId] =
          this.filterAvailableValues[showroomId][brandId];
        return newValues;
      }

      // if brand is selected, unselect it
      delete newValues[showroomId][brandId];
      if (Object.keys(newValues[showroomId]).length === 0) {
        delete newValues[showroomId];
      }
      return newValues;
    }

    toggleCollection(
      showroomId: string,
      brandId: string,
      collectionId: string,
    ) {
      const showroomSelected = this.isShowroomSelected(showroomId);
      const brandSelected = this.isBrandSelected(showroomId, brandId);
      const collectionSelected = this.isCollectionSelected(
        showroomId,
        brandId,
        collectionId,
      );
      const newValues = { ...this.values };

      // if showroom not selected, select it with that brand & that collection
      if (!showroomSelected) {
        newValues[showroomId] = {
          [brandId]: [collectionId],
        };
        return newValues;
      }

      // showroom is selected
      // if brand not selected, select it with that collection
      if (!brandSelected) {
        newValues[showroomId][brandId] = [collectionId];
        return newValues;
      }

      // showroom & brand is selected
      // if collection not selected, select it
      if (!collectionSelected) {
        newValues[showroomId][brandId].push(collectionId);
        return newValues;
      }

      // collection is selected, unselect it
      newValues[showroomId][brandId] = newValues[showroomId][brandId].filter(
        (id) => id !== collectionId,
      );
      if (newValues[showroomId][brandId].length === 0) {
        delete newValues[showroomId][brandId];
        if (Object.keys(newValues[showroomId]).length === 0) {
          delete newValues[showroomId];
        }
      }
      return newValues;
    }
  }
}
