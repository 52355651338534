import React from "react";

import { v4 as uuid } from "uuid";

import { UploadValidationError } from "@models/types/UploadValidationError";

function UploadError({
  error,
  type,
}: {
  error: UploadValidationError[] | string;
  type: string;
}) {
  return (
    <div className="my-4">
      <div role="alert" className="text-primaryRed">
        {typeof error === "string" && `error found in your ${type} upload`}
        {typeof error === "object" &&
          `${error.length} error${
            error.length > 1 ? "s" : ""
          } found in your ${type} upload`}
      </div>
      {typeof error === "string" && (
        <span role="alert" className="text-primaryRed">
          {error}
        </span>
      )}
      {typeof error !== "string" && (
        <>
          <div className="text-primaryRed">Errors listing:</div>
          <ul
            role="alert"
            aria-label="upload-errors"
            className="list-disc mx-4"
          >
            {error.map((e) =>
              e.issues.map((issue) => (
                <li key={`${uuid()}`}>
                  line {e.lineNumber} : {issue}
                </li>
              )),
            )}
          </ul>
        </>
      )}
    </div>
  );
}

export default UploadError;
