import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { useAuthenticatedUser } from "@services/authentication/useAuthentication";

/**
 * Returns the current user's representative id based on the current organization context
 */
export default function useUserRepresentativeId() {
  const user = useAuthenticatedUser();
  const { organization } = useOrganizationAppContext();

  const userOrganizationAssociation = user.associatedOrganizations.find(
    (b) => b.organizationId === organization.id,
  );

  if (!userOrganizationAssociation) {
    throw new Error(
      `Could not retrieve user-organization association for user "${user.id}" and organization "${organization.id}"`,
    );
  }

  return userOrganizationAssociation.representativeId;
}
