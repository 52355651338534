import { groupBy, mapValues, pipe, values } from "remeda";

import countryList from "@services/countries";

export interface City {
  name: string;
}

export interface Country {
  countryCode: string;
  cities: City[];
}

export interface Continent {
  continentCode: string;
  countries: Country[];
}

export type Geography = Continent[];

export type GeographicalFilterType = "continent" | "country" | "city";

export interface GeographicalFilter {
  value: string;
  type: GeographicalFilterType;
}

interface Localization {
  city: string;
  countryCode: string;
  continentCode: string;
}

function buildCities(cities: Localization[]): City[] {
  const { countryCode } = cities[0];
  const distinctCities = new Set(cities.map((l) => l.city));
  return Array.from(distinctCities).map((name) => ({
    countryCode,
    name,
  }));
}

function buildCountries(countries: Localization[]): Country[] {
  const { continentCode } = countries[0];
  return pipe(
    countries,
    groupBy((l: Localization) => l.countryCode),
    mapValues(
      (localizations, countryCode) =>
        ({
          continentCode,
          countryCode,
          cities: buildCities(localizations),
        }) as Country,
    ),
    values,
  );
}

function buildGeographyFromLocalizations(
  localizations: Localization[],
): Geography {
  return pipe(
    localizations,
    groupBy((l) => l.continentCode),
    mapValues(
      (countries, continentCode) =>
        ({
          continentCode,
          countries: buildCountries(countries),
        }) as Continent,
    ),
    values,
  );
}

export function buildGeography(
  accountDetails: { city: string; countryCode: string }[],
): Geography {
  return buildGeographyFromLocalizations(
    accountDetails.map((a) => ({
      continentCode: countryList[a.countryCode].continentCode,
      countryCode: a.countryCode,
      city: a.city,
    })),
  );
}
