import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import axiosInstance from "@services/api/config";
import { CustomQueryOptions, getAPIQueryKey } from "@services/api/helper";

export interface GetCompetitorsGroupsCountParams {
  organizationId: string;
}

export const getCompetitorsGroupsCountSchema = z.object({
  count: z.number().int(),
});

export type GetCompetitorsGroupsCountResponse = z.infer<
  typeof getCompetitorsGroupsCountSchema
>;

export const getCompetitorsGroupsCountEndpoint = {
  path: ({ organizationId }: GetCompetitorsGroupsCountParams) =>
    `/organizations/${organizationId}/competitors-groups/count`,
  call: ({ organizationId }: GetCompetitorsGroupsCountParams) =>
    axiosInstance
      .get<GetCompetitorsGroupsCountResponse>(
        getCompetitorsGroupsCountEndpoint.path({ organizationId }),
      )
      .then((response) => response.data),
};

export function useGetCompetitorsGroupsCount(
  params: GetCompetitorsGroupsCountParams,
  options: CustomQueryOptions = {},
) {
  return useQuery({
    queryKey: getAPIQueryKey(getCompetitorsGroupsCountEndpoint.path(params)),
    queryFn: () => getCompetitorsGroupsCountEndpoint.call(params),
    ...options,
  });
}
