import { useMutation, useQuery } from "@tanstack/react-query";

import { Collection, CollectionCreate } from "@models/old/Collection";
import { getAPIQueryKey } from "@services/api/helper";
import { fetchCollectionsEndpoint } from "@services/api/old/collections/fetch-collections";
import OrganizationAPI from "@services/api/old/organization/OrganizationAPI";

export function useGetCollections(organizationId: string) {
  return useQuery<Collection[]>({
    queryKey: getAPIQueryKey(fetchCollectionsEndpoint.path(organizationId)),
    queryFn: async ({ signal }) =>
      OrganizationAPI.getCollections(organizationId, signal),
  });
}

export function useCreateCollection(organizationId: string) {
  return useMutation({
    mutationFn: (collection: CollectionCreate) =>
      OrganizationAPI.createCollection(organizationId, collection),
  });
}

export function useArchiveCollection(organizationId: string) {
  return useMutation({
    mutationFn: (collectionId: string) =>
      OrganizationAPI.archiveCollection(organizationId, collectionId),
  });
}

export function useRenameCollection(organizationId: string) {
  return useMutation({
    mutationFn: (renamedCollection: Collection) =>
      OrganizationAPI.updateCollection(organizationId, renamedCollection),
  });
}
