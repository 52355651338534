import React, { useRef, useState } from "react";

import { t } from "i18next";
import { Trans } from "react-i18next";
import { toast } from "react-toastify";

import ContactsFilters, {
  ContactFilters,
} from "@app/modules/crm/components/contacts/contacts-filter";
import ContactsTable from "@app/modules/crm/components/contacts/contacts-table";
import UpsertContactForm from "@app/modules/crm/components/contacts/upsert-contact-form";
import CRMHeader from "@app/modules/crm/components/header";
import { useCRMDrawerContext } from "@app/modules/crm/context/crm-drawers";
import filterContacts from "@app/modules/crm/helpers/filter-contacts";
import queryClient from "@app/queryClient";
import ConfirmModal from "@components/feedback/ConfirmModal";
import Drawer from "@components/feedback/Drawer";
import InformationModal from "@components/feedback/InformationModal";
import Loading from "@components/feedback/Loading";
import { GetContactsEndpoint } from "@services/api/contacts/get-contacts";
import { queryKeysFetchContactsArchivedCount } from "@services/api/old/contacts/fetch-archived-count";
import { queryKeysFetchContactsCount } from "@services/api/old/contacts/fetch-contacts-count";
import { usePutArchiveContact } from "@services/api/old/contacts/put-archive-contact";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import LogService from "@services/log/service";
import { pageWithAccessControl } from "@shared/components/access-control";
import { ACL_ADMINS_AGENTS_MANAGERS } from "@shared/components/access-control/helpers";
import { fullName } from "@shared/helpers/formatters";

function CRMContacts() {
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const crmContext = useCRMDrawerContext();

  const drawerRef = useRef(null);
  const [salesCampaignConflicted, setSalesCampaignConflicted] = useState<
    string[] | undefined
  >();
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<ContactFilters>({});

  const [selectedContactToArchive, setSelectedContactToArchive] = useState<
    GetContactsEndpoint.OutputItem | undefined
  >();
  const [selectedContactToEdit, setSelectedContactToEdit] = useState<
    GetContactsEndpoint.OutputItem | undefined
  >();

  const { data: contacts, isLoading: isLoadingList } =
    GetContactsEndpoint.useHook({
      organizationId,
    });

  const { mutateAsync: putArchiveContact } =
    usePutArchiveContact(organizationId);

  const handleConfirmArchiveContact = () => {
    if (!selectedContactToArchive) return;

    putArchiveContact(selectedContactToArchive?.id)
      .then(async () => {
        await queryClient.invalidateQueries({
          queryKey: GetContactsEndpoint.queryKeys({ organizationId }),
        });
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchContactsCount(organizationId),
        });
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchContactsArchivedCount(organizationId),
        });
        setIsConfirmModalOpen(false);
        toast.success(t("CRM.contacts.confirm-contact-archive"));
      })
      .catch((err) => {
        setIsConfirmModalOpen(false);
        if (err.statusCode === 409 && err.message) {
          setSalesCampaignConflicted(err.message);
          setIsInfoModalOpen(true);
        } else {
          LogService.error(err);
        }
      });
  };

  const filteredContacts = contacts && filterContacts(contacts, filters);

  const closeDrawer = () => {
    setSelectedContactToEdit(undefined);
    crmContext.closeDrawer();
  };

  return (
    <>
      <CRMHeader />
      {isLoadingList && (
        <div className="flex items-end justify-center">
          <Loading type="screen" />
        </div>
      )}
      {!isLoadingList && (
        <div className="flex flex-col gap-6 p-6">
          <ContactsFilters filters={filters} onChange={setFilters} />
          <ContactsTable
            contacts={filteredContacts || []}
            onClickEdit={(contact) => {
              setSelectedContactToEdit(contact);
              crmContext.openContactUpsertDrawer();
            }}
            onClickDelete={(contact) => {
              setIsConfirmModalOpen(true);
              setSelectedContactToArchive(contact);
            }}
          />
        </div>
      )}

      <Drawer
        name="creating or updating new contact drawer"
        backdrop
        isOpen={crmContext.drawer === "upsert-contact"}
        size="LARGE"
        drawerTitle={
          <h2 className="heading-2-mobile lg:heading-2">
            {selectedContactToEdit?.id
              ? fullName(selectedContactToEdit)
              : t("CRM.contacts.form.new-contact")}
          </h2>
        }
        closeWithConfirmation={closeDrawer}
        drawerRef={drawerRef}
      >
        <UpsertContactForm
          selectedContact={selectedContactToEdit}
          onCancel={closeDrawer}
          onSuccess={closeDrawer}
          onError={closeDrawer}
        />
      </Drawer>

      <InformationModal
        show={isInfoModalOpen}
        onClose={() => {
          setIsInfoModalOpen(false);
          setSalesCampaignConflicted(undefined);
        }}
        title={t("CRM.contacts.archive-contact-error-title")}
      >
        <p>{t("CRM.contacts.archive-contact-error-content")}</p>
        <ul className="my-4 list-disc pl-7">
          {salesCampaignConflicted?.map((campaign) => (
            <li key={`sales_key_${campaign}`}>{campaign}</li>
          ))}
        </ul>
        <p>{t("CRM.contacts.archive-contact-error-wait")}</p>
      </InformationModal>
      <ConfirmModal
        show={isConfirmModalOpen}
        title={t("CRM.contacts.archive-contact-title")}
        confirmLabel={t("CRM.contacts.archive-contact-confirm-button")}
        cancelLabel={t("Components.buttons.cancel")}
        theme="DANGER"
        onCancel={() => {
          setIsConfirmModalOpen(false);
          setSelectedContactToArchive(undefined);
        }}
        onConfirm={() => {
          handleConfirmArchiveContact();
          setSelectedContactToArchive(undefined);
        }}
      >
        <Trans
          i18nKey="CRM.contacts.archive-contact-content"
          values={{
            contactName:
              selectedContactToArchive && fullName(selectedContactToArchive),
          }}
          components={{
            bold: <strong className="font-bold" />,
            break: <br />,
          }}
        >
          CRM.contacts.archive-contact-content
        </Trans>
      </ConfirmModal>
    </>
  );
}

export default pageWithAccessControl(ACL_ADMINS_AGENTS_MANAGERS, CRMContacts);
