import React, { useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import Button from "@components/data-entry/Button";
import CalloutBox from "@components/feedback/CalloutBox";
import ConfirmModal from "@components/feedback/ConfirmModal";
import Loading from "@components/feedback/Loading";
import { Collection } from "@models/old/Collection";
import {
  invalidateCollectionsWithCapabilitiesQuery,
  useFetchCollectionsWithCapabilities,
} from "@services/api/old/collections/fetch-collections-with-capabilities";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { ACL_ADMINS } from "@shared/components/access-control/helpers";

import { useArchiveCollection, useRenameCollection } from "./collection-hooks";
import CollectionItem from "./collection-item";
import CreateCollectionItem from "./create-collection";

interface CollectionsOverviewProps {
  isOnboarding?: boolean;
}

function CollectionsOverview({ isOnboarding }: CollectionsOverviewProps) {
  const {
    organization: { id: organizationId, accountPortfolioType },
  } = useOrganizationAppContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const { data, isLoading, isError, error, isFetching } =
    useFetchCollectionsWithCapabilities(organizationId);
  const collections = data?.sort((a, b) => a.name.localeCompare(b.name)) || [];

  const [collectionToArchive, setCollectionToArchive] = useState<
    Collection | undefined
  >();
  const archiveMutation = useArchiveCollection(organizationId);
  const renameMutation = useRenameCollection(organizationId);

  const onDelete = () => {
    if (!collectionToArchive) return;
    archiveMutation
      .mutateAsync(collectionToArchive.id)
      .then(() => invalidateCollectionsWithCapabilitiesQuery(organizationId))
      .finally(() => {
        setCollectionToArchive(undefined);
      });
  };

  if (isError) {
    return (
      <div className="flex items-center justify-center my-10 grow">
        {`Oops ${error}`}
      </div>
    );
  }

  if (isLoading || isFetching) {
    return (
      <div className="flex items-center justify-center my-10 grow">
        <Loading type="screen" />
      </div>
    );
  }

  const hasCollectionsMissingPortfolio =
    collections.filter((c) => c.portfolios.length === 0).length > 0 &&
    accountPortfolioType === "PORTFOLIO_BASED_ON_COLLECTIONS";

  return (
    <div className="w-full flex flex-wrap-reverse lg:no-wrap gap-4">
      <div className="flex flex-col gap-4 w-full lg:w-auto lg:max-w-[50%] lg:grow">
        <main>
          <Button
            label="Add new collection"
            onClick={() => setIsCreating(true)}
            theme="PRIMARY"
            className="w-52 mb-6"
            disabled={isCreating}
            aclRoles={ACL_ADMINS}
          >
            <HiOutlinePlusCircle className="w-6 h-6" />
            <span>{t("OrganizationProfile.collections.new-collection")}</span>
          </Button>
          {isCreating && (
            <CreateCollectionItem
              key="create-collection"
              organizationId={organizationId}
              onClose={() => setIsCreating(false)}
            />
          )}
          <ul aria-label="list of collections" className="flex flex-col gap-4">
            {collections.map((collection) => (
              <li aria-label={collection.name} key={collection.id}>
                <CollectionItem
                  key={collection.id}
                  collection={collection}
                  onRename={(collectionToRename) =>
                    renameMutation
                      .mutateAsync(collectionToRename)
                      .then(() =>
                        invalidateCollectionsWithCapabilitiesQuery(
                          organizationId,
                        ),
                      )
                  }
                  onArchive={setCollectionToArchive}
                  isLastCollection={collections.length === 1}
                />
              </li>
            ))}
          </ul>
        </main>
        <ConfirmModal
          show={!!collectionToArchive}
          title={t("Shared.collections.archive.title")}
          confirmLabel={t("Shared.collections.archive.confirm")}
          onCancel={() => {
            setCollectionToArchive(undefined);
          }}
          onConfirm={onDelete}
        >
          <Trans
            i18nKey="OrganizationProfile.collections.archive.content"
            values={{ name: collectionToArchive?.name }}
          >
            OrganizationProfile.
            <strong className="font-medium">collections</strong>
            <br />
            .archive.content
          </Trans>
        </ConfirmModal>
      </div>
      <div className="w-full lg:max-w-[50%] lg:grow flex flex-col gap-6">
        {!isOnboarding && (
          <>
            <CalloutBox aclRoles={ACL_ADMINS}>
              {t("OrganizationProfile.collections.callout")}
            </CalloutBox>
            {hasCollectionsMissingPortfolio && (
              <CalloutBox aclRoles={ACL_ADMINS} type="WARNING">
                <div className="pb-2">
                  {t(
                    "OrganizationProfile.collections.callout-missing-portfolio",
                  )}
                </div>
                <Button
                  theme="PRIMARY"
                  onClick={() => navigate("/organization-profile/portfolios")}
                >
                  {t("OrganizationProfile.collections.manage-portfolios")}
                </Button>
              </CalloutBox>
            )}
          </>
        )}
        {isOnboarding && (
          <CalloutBox aclRoles={ACL_ADMINS}>
            <p className="mb-4">
              {t("OrganizationProfile.collections.structure-callout-part1")}
            </p>
            <p className="mb-4">
              {t("OrganizationProfile.collections.structure-callout-part2")}
            </p>
            <p>
              {t("OrganizationProfile.collections.structure-callout-part3")}
            </p>
          </CalloutBox>
        )}
      </div>
    </div>
  );
}

CollectionsOverview.defaultProps = {
  isOnboarding: false,
};

export default CollectionsOverview;
