import React from "react";

import { Portal } from "@headlessui/react";
import { t } from "i18next";
import { IoIosClose } from "react-icons/io";

import Button from "@components/data-entry/Button";
import FadeInOut from "@components/transitions/FadeInOut";

import Backdrop from "../layout/Backdrop";

interface ConfirmModalProps {
  title: string;
  cancelLabel?: string;
  confirmLabel?: string;
  theme?: "PRIMARY" | "DANGER";
  type?: "INFO" | "DANGER";
  onConfirm: () => void;
  onCancel: () => void;
  children: React.ReactNode;
  show: boolean;
  isConfirmLoading?: boolean;
}

function ConfirmModal(props: ConfirmModalProps) {
  const {
    title,
    children,
    theme = "DANGER",
    onConfirm,
    onCancel,
    isConfirmLoading,
    cancelLabel,
    confirmLabel,
    type = "DANGER",
    show,
  } = props;

  return (
    <Portal as="div" className="fixed z-modal">
      <FadeInOut show={show}>
        <div className="flex justify-center items-center fixed top-0 left-0 w-screen h-screen">
          <Backdrop />
          <div
            role="dialog"
            aria-modal="true"
            className="mx-3 max-w-lg lg:w-[28rem] fixed bg-white p-6 shadow-md rounded-lg"
          >
            <div className="flex justify-between">
              <div className="py-2 font-medium">{title}</div>
              <Button
                type="button"
                theme="ICON"
                className="cursor-pointer"
                onClick={onCancel}
                label="modal-close"
              >
                <IoIosClose className="w-8 h-8" />
              </Button>
            </div>
            <div>
              <div className="py-4">{children}</div>
              <div className="flex justify-end w-full gap-2">
                {type === "DANGER" && (
                  <Button
                    type="button"
                    theme="NONE"
                    className="w-32 text-gray-500 underline cursor-pointer"
                    onClick={onCancel}
                    label="modal-cancel"
                  >
                    {cancelLabel || (
                      <span>{t("Components.confirm-modal.cancel")}</span>
                    )}
                  </Button>
                )}
                <Button
                  type="button"
                  theme={theme}
                  onClick={onConfirm}
                  loading={isConfirmLoading}
                  label="modal-confirm"
                  testId="confirm-modal-confirm-button"
                >
                  {confirmLabel || (
                    <span>{t("Components.confirm-modal.yes")}</span>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </FadeInOut>
    </Portal>
  );
}

export default ConfirmModal;
