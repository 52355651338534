import React, { useMemo } from "react";

import BusySlot from "@calendar/components/slots/busy";
import CalendarHelper from "@calendar/helpers/calendar";
import {
  CalendarCell,
  buildCalendarColumn,
} from "@calendar/helpers/calendar-column";
import { DayParams } from "@calendar/helpers/day-params";
import { ROW_HEIGHT } from "@calendar/style";
import { Calendar } from "@calendar/types";

import AppointmentSlot from "./slots/appointment";
import AvailableSlot from "./slots/available";

const renderCellFactory = (
  sellerId: string,
  onClickAppointmentSlot: (appointment: Calendar.Appointment) => void,
  handleBookAvailableSlot: (startTime: Date, sellerId: string) => void,
  handleBlockSlot: (startTime: Date, sellerId: string) => void,
) =>
  function renderCell(cell: CalendarCell) {
    const { cellType, startTime } = cell;
    const startTimeAsString = startTime.toISOString();
    if (cellType === "appointment") {
      const id = `appointment-slot-${CalendarHelper.toDroppableId(
        sellerId,
        startTime,
      )}`;
      return (
        <div className="border relative" key={id} data-testid={id}>
          <AppointmentSlot
            appointmentCell={cell}
            onClick={onClickAppointmentSlot}
          />
        </div>
      );
    }

    if (cellType === "busy") {
      const id = `appointment-slot-${CalendarHelper.toDroppableId(
        sellerId,
        startTime,
      )}`;
      return (
        <div className="border relative" key={id} data-testid={id}>
          <BusySlot appointmentCell={cell} onClick={onClickAppointmentSlot} />
        </div>
      );
    }

    return (
      <AvailableSlot
        type={cellType}
        sellerId={sellerId}
        startTime={startTime}
        key={`available-${sellerId}-${startTimeAsString}`}
        handleBookAppointment={handleBookAvailableSlot}
        handleBlockSlot={handleBlockSlot}
      />
    );
  };

interface SellerAgendaProps {
  dayParams: DayParams;
  seller: Calendar.SellerAgenda;
  onClickAppointmentSlot: (appointment: Calendar.Appointment) => void;
  handleBookAppointment: (startTime: Date, sellerId: string) => void;
  handleBlockSlot: (startTime: Date, sellerId: string) => void;
}

function SellerAgenda(props: SellerAgendaProps) {
  const {
    dayParams,
    seller,
    onClickAppointmentSlot,
    handleBookAppointment,
    handleBlockSlot,
  } = props;
  const { appointments } = seller;

  // Does useMemo make sense here? and works on Date objects?
  const { numberOfRows, cells } = useMemo(
    () => buildCalendarColumn(appointments, dayParams),
    [dayParams, appointments],
  );

  const column = cells.map(
    renderCellFactory(
      seller.id,
      onClickAppointmentSlot,
      handleBookAppointment,
      handleBlockSlot,
    ),
  );

  const gridTemplateRows = `repeat(${numberOfRows}, ${ROW_HEIGHT}rem)`;
  const testId = `daily-agenda-for-${seller.id}`;

  return (
    <div
      data-testid={testId}
      className="grid text-sm"
      style={{
        gridTemplateRows,
      }}
    >
      {column}
    </div>
  );
}

export default React.memo(SellerAgenda);
