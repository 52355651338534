import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { z } from "zod";

import { accountSchema } from "@models/Account";
import { organizationRepresentativeSchema } from "@models/OrganizationRepresentative";
import { portfolioSchema } from "@models/Portfolio";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";
import {
  GetPortfoliosResponseItem,
  getPortfoliosEndpoint,
} from "@services/api/portfolios/get-portfolios";
import LogService from "@services/log/service";

export interface UpdatePortfolioParams {
  organizationId: string;
}

const updatePortfolioBodySchema = portfolioSchema
  .pick({
    id: true,
    name: true,
    color: true,
    collectionId: true,
  })
  .extend({
    manager: organizationRepresentativeSchema.pick({ id: true }),
    sellers: z.array(organizationRepresentativeSchema.pick({ id: true })),
    accounts: z.array(accountSchema.pick({ id: true })),
  });
export type UpdatePortfolioBody = z.infer<typeof updatePortfolioBodySchema>;

export const updatePortfolioEndpoint = {
  path: ({
    organizationId,
    portfolioId,
  }: {
    organizationId: string;
    portfolioId: string;
  }) => `/organizations/${organizationId}/portfolios/${portfolioId}`,
  call: (
    { organizationId }: UpdatePortfolioParams,
    portfolio: UpdatePortfolioBody,
  ) =>
    axiosInstance
      .put<GetPortfoliosResponseItem>(
        updatePortfolioEndpoint.path({
          organizationId,
          portfolioId: portfolio.id,
        }),
        portfolio,
      )
      .then((response) => response.data),
};

export function useUpdatePortfolio(params: UpdatePortfolioParams) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation({
    mutationFn: ({ portfolio }: { portfolio: UpdatePortfolioBody }) =>
      updatePortfolioEndpoint.call(params, portfolio),
    onSuccess: () => {
      toast.success(t("Toast.portfolio-saved"));
      queryClient.invalidateQueries({
        queryKey: getAPIQueryKey(getPortfoliosEndpoint.path(params)),
      });
    },
    onError: (err) => {
      toast.error(t("Toast.portfolio-saved-fail"));
      toast.error(`${err}`);
      LogService.error(`${err}`);
    },
  });
}
