import React from "react";

import Tag, { TagProps } from "@components/data-display/Tag";

interface TagCellProps {
  values: string[];
  className?: string;
  tag?: TagProps;
  maxWidth?: string;
  children?: React.ReactNode;
}

export default function TagCell({
  values,
  tag,
  className,
  maxWidth,
  children,
}: TagCellProps) {
  return (
    <div className="flex items-center gap-2">
      {values.map((text) => (
        <Tag
          maxWidth={maxWidth}
          title={text}
          className={className}
          key={text}
          {...tag}
        >
          {text}
        </Tag>
      ))}
      {children}
    </div>
  );
}
