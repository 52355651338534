import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { z } from "zod";

import { accountSchema } from "@models/Account";
import { organizationRepresentativeSchema } from "@models/OrganizationRepresentative";
import { portfolioSchema } from "@models/Portfolio";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";
import {
  GetPortfoliosResponseItem,
  getPortfoliosEndpoint,
} from "@services/api/portfolios/get-portfolios";

export interface CreatePortfolioParams {
  organizationId: string;
}

const createPortfolioBodySchema = portfolioSchema
  .pick({
    name: true,
    color: true,
    collectionId: true,
    brandId: true,
  })
  .extend({
    manager: organizationRepresentativeSchema.pick({ id: true }),
    sellers: z.array(organizationRepresentativeSchema.pick({ id: true })),
    accounts: z.array(accountSchema.pick({ id: true })),
  });
export type CreatePortfolioBody = z.infer<typeof createPortfolioBodySchema>;

export const createPortfolioEndpoint = {
  path: ({ organizationId }: CreatePortfolioParams) =>
    `/organizations/${organizationId}/portfolios`,
  call: (
    { organizationId }: CreatePortfolioParams,
    body: CreatePortfolioBody,
  ) =>
    axiosInstance
      .post<GetPortfoliosResponseItem>(
        createPortfolioEndpoint.path({ organizationId }),
        body,
      )
      .then((response) => response.data),
};

export function useCreatePortfolio(params: CreatePortfolioParams) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation({
    mutationFn: ({ portfolio }: { portfolio: CreatePortfolioBody }) =>
      createPortfolioEndpoint.call(params, portfolio),
    onSuccess: () => {
      toast.success(t("Toast.portfolio-saved"));
      queryClient.invalidateQueries({
        queryKey: getAPIQueryKey(getPortfoliosEndpoint.path(params)),
      });
    },
  });
}
