import { useQuery } from "@tanstack/react-query";

import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

export const fetchAccountsCountEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/accounts/count`,
};

export const queryKeysFetchAccountsCount = (organizationId: string) =>
  fetchAccountsCountEndpoint.path(organizationId).split("/");

export const useFetchAccountsCount = (organizationId: string) =>
  useQuery({
    queryKey: queryKeysFetchAccountsCount(organizationId),
    queryFn: () =>
      axiosInstance
        .get<{ count: number }>(fetchAccountsCountEndpoint.path(organizationId))
        .then((res) => res.data),
  });
