import React from "react";

import { isAfter, subMinutes } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { Calendar } from "@calendar/types";
import Button from "@components/data-entry/Button";
import DatePicker from "@components/data-entry/DatePicker";
import Select from "@components/data-entry/Select";
import TextField from "@components/data-entry/TextField";
import TimePicker from "@components/data-entry/TimePicker";
import CalloutBox from "@components/feedback/CalloutBox";
import {
  LocalDate,
  ZonedDate,
  combineDateAndTime,
  formatDateToISO,
  toLocalTime,
} from "@helpers/Date";
import { Organization } from "@models/Organization";
import { DeleteAppointmentEndpoint } from "@services/api/appointments/delete-appointment";
import { UpsertAppointmentEndpoint } from "@services/api/appointments/upsert-appointment";
import LogService from "@services/log/service";
import { useOrganizationRepresentativeOptions } from "@shared/helpers/sellers";

export namespace BusyAppointmentEditForm {
  export type FormData = {
    id: string;
    title: string;
    sellerId: string;
    date: Date;
    startTime: Date;
    endTime: Date;
    showroomId: string;
  };
  export const formDataValidationSchema = z
    .object({
      id: z.string(),
      title: z.string(),
      sellerId: z.string(),
      date: z.date(),
      startTime: z.date(),
      endTime: z.date(),
      accountOtb: z.number().nullable().default(null),
      showroomId: z.string().uuid(),
    })
    .refine((values) => !isAfter(values.startTime, values.endTime), {
      message: "Calendar.BusySlotEditForm.error-starttime-endtime",
    });
  export type FormDataValidated = z.infer<typeof formDataValidationSchema>;

  export function toFormData(
    appointment: Calendar.BusyAppointment,
  ): FormDataValidated {
    return {
      title: appointment.title,
      date: appointment.startTime.toLocalDate(appointment.showroom.timezone),
      startTime: toLocalTime(
        appointment.startTime.toLocalDate(appointment.showroom.timezone),
      ),
      endTime: toLocalTime(
        appointment.endTime.toLocalDate(appointment.showroom.timezone),
      ),
      id: appointment.id,
      sellerId: appointment.seller.id,
      accountOtb: appointment.accountOtb,
      showroomId: appointment.showroom.id,
    };
  }

  export function toUpdateInput(
    appointment: Calendar.BusyAppointment,
    formData: FormDataValidated,
  ) {
    return {
      ...appointment,
      title: formData.title,
      startTime: ZonedDate.fromLocalDate(
        combineDateAndTime(formData.date, formData.startTime) as LocalDate,
        appointment.showroom.timezone,
      ),
      endTime: ZonedDate.fromLocalDate(
        combineDateAndTime(formData.date, formData.endTime) as LocalDate,
        appointment.showroom.timezone,
      ),
      seller: {
        id: formData.sellerId,
      },
    } satisfies UpsertAppointmentEndpoint.Input;
  }
}

interface Props {
  organizationId: Organization["id"];
  appointment: Calendar.BusyAppointment;
  initialValues: BusyAppointmentEditForm.FormData;
  onSubmit: (v: BusyAppointmentEditForm.FormDataValidated) => void;
  onCancel: () => void;
  onDelete: () => void;
  sellers: { id: string; firstName: string; lastName: string }[];
  showroomDates: Date[];
}

export function BusySlotEditForm({
  appointment,
  organizationId,
  initialValues,
  showroomDates,
  sellers,
  onSubmit,
  onCancel,
  onDelete,
}: Props) {
  const { t } = useTranslation();
  const form = useForm<BusyAppointmentEditForm.FormData>({
    defaultValues: initialValues,
  });

  const endTime = form.watch("endTime");
  const startTime = form.watch("startTime");

  const sellerOptions = useOrganizationRepresentativeOptions({ sellers });

  const dayAsString = formatDateToISO(
    appointment.startTime.toLocalDate(appointment.showroom.timezone),
  );
  const updateMutation = UpsertAppointmentEndpoint.useHook({
    organizationId,
    dayAsString,
  });
  const deleteMutation = DeleteAppointmentEndpoint.useHook({
    organizationId,
    dayAsString,
  });

  const handleDeleteAppointment = () => {
    deleteMutation.mutateAsync({ appointmentId: appointment.id }).then(() => {
      onDelete();
    });
  };

  const submit = (values: BusyAppointmentEditForm.FormData) => {
    const parsing =
      BusyAppointmentEditForm.formDataValidationSchema.safeParse(values);
    if (parsing.success) {
      updateMutation
        .mutateAsync({
          appointment: BusyAppointmentEditForm.toUpdateInput(
            appointment,
            parsing.data,
          ),
        })
        .then(() => {
          onSubmit(parsing.data);
        });
    } else {
      const allErrors = parsing.error.formErrors;
      LogService.error(
        `BusySlotEditForm - validation error - ${JSON.stringify(allErrors)}`,
      );
      form.setError("root", { message: t("Common.unknown-error") });
    }
  };

  const formErrors = form.formState.errors;

  return (
    <form
      onSubmit={form.handleSubmit(submit)}
      className="grid grid-cols-1 gap-4 mt-8"
    >
      {formErrors.root && formErrors.root.message ? (
        <CalloutBox type="ERROR">{formErrors.root.message}</CalloutBox>
      ) : null}
      <div>
        <label htmlFor="title">
          {t("Calendar.BusyAppointmentEditForm.title-label")}
          {formErrors.title && formErrors.title.message && (
            <span className="text-primaryRed text-sm ml-2">
              {formErrors.title.message}
            </span>
          )}
        </label>
        <Controller
          control={form.control}
          name="title"
          rules={{
            required: t("Calendar.BusyAppointmentEditForm.field-required"),
          }}
          render={({ field }) => (
            <TextField
              value={field.value || ""}
              onChange={field.onChange}
              id="title"
              placeholder={t(
                "Calendar.BusyAppointmentEditForm.title-placeholder",
              )}
            />
          )}
        />
      </div>
      <div>
        <label htmlFor="date">
          {t("Calendar.BusyAppointmentEditForm.date-label")}
          {formErrors.date && formErrors.date.message && (
            <span className="text-primaryRed text-sm ml-2">
              {formErrors.date.message}
            </span>
          )}
        </label>
        <Controller
          control={form.control}
          name="date"
          rules={{
            required: t("Calendar.BusyAppointmentEditForm.field-required"),
          }}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              defaultValue={value}
              onChange={onChange}
              enabledDates={showroomDates}
            />
          )}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="start">
            {t("Calendar.BusyAppointmentEditForm.start-label")}
            {formErrors.startTime && formErrors.startTime.message && (
              <span className="text-primaryRed text-sm ml-2">
                {formErrors.startTime.message}
              </span>
            )}
          </label>
          <Controller
            control={form.control}
            name="startTime"
            rules={{
              required: t("Calendar.BusyAppointmentEditForm.field-required"),
            }}
            render={({ field: { value, onChange } }) => (
              <TimePicker
                id="start"
                className="inline w-34"
                placeholder={t(
                  "Calendar.BusyAppointmentEditForm.start-placeholder",
                )}
                name="start"
                options={{
                  firstHourOfDay: 6,
                  totalHours: 16,
                  timeStep: 15,
                }}
                onChange={onChange}
                defaultValue={value || undefined}
                maxTime={endTime && subMinutes(endTime, 1)}
              />
            )}
          />
        </div>
        <div>
          <label htmlFor="end">
            {t("Calendar.BusyAppointmentEditForm.end-label")}
            {formErrors.endTime && formErrors.endTime.message && (
              <span className="text-primaryRed text-sm ml-2">
                {formErrors.endTime.message}
              </span>
            )}
          </label>
          <Controller
            control={form.control}
            name="endTime"
            rules={{
              required: t("Calendar.BusyAppointmentEditForm.field-required"),
            }}
            render={({ field: { value, onChange } }) => (
              <TimePicker
                className="inline w-34"
                placeholder={t(
                  "Calendar.BusyAppointmentEditForm.end-placeholder",
                )}
                name="end"
                id="end"
                options={{
                  firstHourOfDay: 6,
                  totalHours: 16,
                  timeStep: 15,
                }}
                minTime={startTime}
                onChange={onChange}
                defaultValue={value || undefined}
              />
            )}
          />
        </div>
      </div>
      <div>
        <label htmlFor="sellerId">
          {t("Calendar.BusyAppointmentEditForm.sellerId-label")}
          {formErrors.sellerId && formErrors.sellerId.message && (
            <span className="text-primaryRed text-sm ml-2">
              {formErrors.sellerId.message}
            </span>
          )}
        </label>
        <Controller
          control={form.control}
          name="sellerId"
          rules={{
            required: t("Calendar.BusyAppointmentEditForm.field-required"),
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              name="sellerId"
              options={[
                {
                  key: "grp1",
                  label: "grp1",
                  options: sellerOptions,
                },
              ]}
              onChange={onChange}
              defaultValue={{ value }}
            />
          )}
        />
      </div>

      <div className="grid grid-cols-3 gap-2">
        <Button
          type="button"
          loading={deleteMutation.isPending}
          theme="DANGER"
          onClick={handleDeleteAppointment}
          className="justify-center"
        >
          {t("Common.delete")}
        </Button>
        <Button
          theme="SECONDARY"
          type="button"
          onClick={onCancel}
          className="justify-center"
        >
          {t("Common.cancel")}
        </Button>
        <Button theme="PRIMARY" type="submit" className="justify-center">
          {t("Common.apply")}
        </Button>
      </div>
    </form>
  );
}
