import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { Organization } from "@models/Organization";
import {
  Representative,
  representativeSchema,
} from "@models/OrganizationRepresentative";
import { portfolioSchema } from "@models/Portfolio";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export namespace GetRepresentativeEndpoint {
  export const outputSchema = representativeSchema
    .pick({
      id: true,
      firstName: true,
      lastName: true,
      email: true,
      role: true,
      invitationStatus: true,
      markets: true,
      languages: true,
      phoneNumber: true,
      position: true,
    })
    .extend({
      portfolios: portfolioSchema
        .pick({
          id: true,
          name: true,
          collectionId: true,
        })
        .array(),
    });

  export type Output = z.infer<typeof outputSchema>;

  export interface HookParams {
    organizationId: Organization["id"];
    representativeId: Representative["id"];
  }

  export function path({ organizationId, representativeId }: HookParams) {
    return `/organizations/${organizationId}/representatives/${representativeId}`;
  }

  export const getQueryKeys = (p: HookParams) => getAPIQueryKey(path(p));

  export function call(params: HookParams) {
    return axiosInstance
      .get<Output>(path(params))
      .then((r) => outputSchema.parse(r.data));
  }

  export function useHook(params: HookParams) {
    return useQuery({
      queryKey: getQueryKeys(params),
      queryFn: () => call(params),
    });
  }
}
