import { useQuery } from "@tanstack/react-query";

import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

export const fetchRepresentativesArchivedCountEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/representatives/archived/count`,
};

export const queryKeysFetchRepresentativesArchivedCount = (
  organizationId: string,
) => fetchRepresentativesArchivedCountEndpoint.path(organizationId).split("/");

export const useFetchRepresentativesArchivedCount = (organizationId: string) =>
  useQuery({
    queryKey: queryKeysFetchRepresentativesArchivedCount(organizationId),
    queryFn: () =>
      axiosInstance
        .get<{
          count: number;
        }>(fetchRepresentativesArchivedCountEndpoint.path(organizationId))
        .then((res) => res.data),
  });
