import React, { ChangeEvent, useState } from "react";

import { HiCheck } from "react-icons/hi2";

import WithConditionalRendering from "@components/high-order-components/conditional-render";

type Props = {
  label: string;
  value: string;
  onSave: (value: string) => void;
  display?: string | React.ReactNode;
  className?: string;
  placeholder?: string;
  inputClassName?: string;
};

function ET({
  value,
  onSave,
  label,
  display,
  className,
  placeholder,
  inputClassName,
}: Props) {
  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [placeholderValue, setPlaceholderValue] = useState(display || value);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (!display) {
      setPlaceholderValue(e.target.value);
    }
  };

  const handleExit = () => {
    onSave(inputValue);
    setEditMode(false);
  };

  return editMode ? (
    <>
      <input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        placeholder={placeholder}
        aria-label={label}
        type="text"
        className={`px-2 p-1 ${inputClassName || ""}`}
        value={inputValue}
        onChange={handleInputChange}
      />
      <button
        className="border rounded-full border-primaryElectricBlue px-2 text-primaryElectricBlue hover:bg-primaryLightElectricBlue"
        aria-label="save"
        onClick={handleExit}
        type="button"
      >
        <HiCheck className="w-6 h-6" />
      </button>
    </>
  ) : (
    <button
      className={className}
      type="button"
      onClick={() => setEditMode(true)}
    >
      {placeholderValue}
    </button>
  );
}

const EditableText = WithConditionalRendering(ET);
export default EditableText;
