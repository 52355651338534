import React, { useState } from "react";

import { t } from "i18next";
import { HiPencil } from "react-icons/hi2";
import { Calendar, DateObject } from "react-multi-date-picker";

import Button from "@components/data-entry/Button";
import { useIsMobile } from "@components/layout/Breakpoint";
import FadeInOut from "@components/transitions/FadeInOut";
import { extractDateInformation } from "@helpers/Date";

interface AppointmentSlotCalendarProps {
  minDate: string | number | Date;
  maxDate: string | number | Date;
  selectedDate?: Date;
  availableDates?: Date[];
  disableAllDates?: boolean;
  onDateSelected: (date: Date) => void;
}

export default function AppointmentSlotCalendar({
  minDate,
  maxDate,
  availableDates,
  selectedDate,
  disableAllDates = true,
  onDateSelected,
}: AppointmentSlotCalendarProps) {
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const isMobile = useIsMobile();

  return (
    <div data-testid="container-slot-select-calendar">
      {isMobile && (
        <Button
          theme="LINK"
          className="w-full flex justify-center items-center"
          onClick={() => setDisplayCalendar(!displayCalendar)}
        >
          {selectedDate?.toLocaleString(undefined, { dateStyle: "long" })}
          <HiPencil className="w-4 h-4" />
        </Button>
      )}
      <FadeInOut show={!isMobile || displayCalendar}>
        <Calendar
          className="w-80 md:w-768 lg:w-98 open-calendar"
          weekStartDayIndex={1}
          months={[
            t("Common.months.full.JANUARY"),
            t("Common.months.full.FEBRUARY"),
            t("Common.months.full.MARCH"),
            t("Common.months.full.APRIL"),
            t("Common.months.full.MAY"),
            t("Common.months.full.JUNE"),
            t("Common.months.full.JULY"),
            t("Common.months.full.AUGUST"),
            t("Common.months.full.SEPTEMBER"),
            t("Common.months.full.OCTOBER"),
            t("Common.months.full.NOVEMBER"),
            t("Common.months.full.DECEMBER"),
          ]}
          weekDays={[
            t("Common.weekdays.abbreviation.SUNDAY"),
            t("Common.weekdays.abbreviation.MONDAY"),
            t("Common.weekdays.abbreviation.TUESDAY"),
            t("Common.weekdays.abbreviation.WEDNESDAY"),
            t("Common.weekdays.abbreviation.THURSDAY"),
            t("Common.weekdays.abbreviation.FRIDAY"),
            t("Common.weekdays.abbreviation.SATURDAY"),
          ]}
          shadow={false}
          value={selectedDate}
          zIndex={0}
          minDate={minDate}
          maxDate={maxDate}
          mapDays={({ date }) => {
            const props = {
              disabled: disableAllDates,
            };

            if (
              availableDates?.find(
                (availDate) =>
                  availDate.toDateString() === date.toDate().toDateString(),
              ) ||
              selectedDate?.toDateString() === date.toDate().toDateString()
            ) {
              props.disabled = false;
            }

            return props;
          }}
          onChange={(dateObject: DateObject) => {
            if (
              selectedDate?.toDateString() !==
              dateObject.toDate().toDateString()
            ) {
              onDateSelected(extractDateInformation(dateObject.toDate()));
              setDisplayCalendar(false);
            }
          }}
        />
      </FadeInOut>
    </div>
  );
}
