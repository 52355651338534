import React from "react";

import { cva } from "class-variance-authority";
import { useTranslation } from "react-i18next";
import { HiExclamationTriangle, HiPencil, HiTrash } from "react-icons/hi2";

import { CruisingShowroomSingleForm } from "@app/modules/cruising/components/showroom/single-form";
import { ShowroomsTableState } from "@app/modules/cruising/components/showroom/table.helper";
import Tooltip from "@components/data-display/Tooltip";
import Button from "@components/data-entry/Button";
import Modal, { useModal } from "@components/feedback/Modal";
import { formatDateShort, formatTimeInterval } from "@helpers/Date";

type TableState = ReturnType<typeof ShowroomsTableState.useState>;

interface CellErrorProps {
  error: string | undefined;
  children: React.ReactNode;
}
function CellErrorTooltip({ error, children }: CellErrorProps) {
  const { t } = useTranslation();
  return error ? (
    <Tooltip content={t(error)}>
      <div className="flex items-center gap-2">
        {children}
        <HiExclamationTriangle className="text-primaryRed" />
      </div>
    </Tooltip>
  ) : (
    children
  );
}

interface ShowroomRowProps {
  showroom: ShowroomsTableState.Row;
  index: number;
  dispatch: TableState["dispatch"];
  error?: string;
}

function ShowroomRow({ showroom, index, dispatch, error }: ShowroomRowProps) {
  const modalState = useModal();
  const { t } = useTranslation();
  const updateInState = (
    formData: CruisingShowroomSingleForm.FormDataValidated,
  ) => {
    const updatedShowroom = formData;
    dispatch({ type: "editShowroom", index, showroom: updatedShowroom });
    modalState.close();
  };

  const rowStyle = cva(
    "grid grid-cols-21 gap-2 items-stretch my-2 border-b border-primaryLightElectricBlue p-2 rounded hover:bg-primaryLightestGrey",
    {
      variants: {
        error: {
          true: "border border-primaryRed",
          false: "",
        },
      },
    },
  );

  const errors = ShowroomsTableState.validateShowroom(showroom);

  return (
    <tr className={rowStyle({ error: !!error })}>
      <td className="col-span-21 md:col-span-3">{showroom.name}</td>

      <td className="col-span-21 md:col-span-5">
        <CellErrorTooltip error={errors.address}>
          {showroom.address.formattedAddress}
        </CellErrorTooltip>
      </td>

      <td className="col-span-21 md:col-span-5 md:col-end-auto text-center">
        <CellErrorTooltip error={errors.fromTo}>
          {`${formatDateShort(showroom.from)} - ${formatDateShort(
            showroom.to,
          )}`}
        </CellErrorTooltip>
      </td>

      <td className="col-span-21 md:col-span-4 md:col-end-auto text-center">
        {formatTimeInterval(showroom.startTime, showroom.endTime)}
      </td>

      <td className="col-span-21 md:col-span-2">
        <CellErrorTooltip error={errors.duration}>
          {showroom.duration} min
        </CellErrorTooltip>
      </td>

      <td className="col-span-21 md:col-span-2 flex">
        <Button
          className="w-6 h-6"
          theme="NONE"
          label="Update showroom"
          onClick={modalState.open}
        >
          <HiPencil />
        </Button>
        <Button
          className="w-6 h-6 text-primaryRed"
          theme="NONE"
          label="Delete showroom"
          onClick={() => dispatch({ type: "removeShowroom", index })}
        >
          <HiTrash />
        </Button>

        <Modal
          state={modalState}
          title={t("Cruising.ShowroomsTable.edit-showroom")}
        >
          <CruisingShowroomSingleForm.Component
            onSubmit={updateInState}
            defaultValues={showroom}
          />
        </Modal>
      </td>
    </tr>
  );
}

interface ShowroomsTableProps extends TableState {
  state: ShowroomsTableState.State;
  dispatch: (action: ShowroomsTableState.Action) => void;
}

export default function ShowroomsTable({
  state,
  dispatch,
}: ShowroomsTableProps) {
  const { t } = useTranslation();
  return (
    <>
      <table className="border border-primaryElectricBlue rounded-t-xl">
        <thead className="bg-primaryElectricBlue text-white rounded-t-xl">
          <tr className="grid grid-cols-21">
            <th className="p-2 font-bold col-span-21 md:hidden">
              {t("Cruising.ShowroomsTable.headers.mobile")}
            </th>
            <th className="p-2 font-bold hidden md:block md:col-span-3">
              {t("Cruising.ShowroomsTable.headers.name")}
            </th>
            <th className="p-2 font-bold hidden md:block md:col-span-5">
              {t("Cruising.ShowroomsTable.headers.address")}
            </th>
            <th className="p-2 font-bold hidden md:block md:col-span-5">
              {t("Cruising.ShowroomsTable.headers.opening-days")}
            </th>
            <th className="p-2 font-bold hidden md:block md:col-span-4">
              {t("Cruising.ShowroomsTable.headers.opening-hours")}
            </th>
            <th className="p-2 font-bold hidden md:block md:col-span-2">
              {t("Cruising.ShowroomsTable.headers.duration")}
            </th>
            <th className="p-2 font-bold hidden md:col-span-2 md:grid grid-cols-2">
              &nbsp;
            </th>
          </tr>
        </thead>
        <tbody>
          {state.showrooms.map((showroom, index) => (
            <ShowroomRow
              // eslint-disable-next-line react/no-array-index-key
              key={`showroom-${index}`}
              showroom={showroom}
              dispatch={dispatch}
              index={index}
              error={state.errors[index]}
            />
          ))}
        </tbody>
      </table>
      {state.showrooms.length === 0 ? (
        <li className="rounded bg-primaryLightestGrey text-primaryBlack p-4 flex flex-col items-center text-lg">
          <p>{t("Cruising.ShowroomsTable.no-showrooms-yet")}</p>
          <p>{t("Cruising.ShowroomsTable.add-at-least-2-showrooms")}</p>
        </li>
      ) : null}
      {state.showrooms.length === 1 ? (
        <li className="rounded bg-primaryLightestGrey text-primaryBlack p-4 flex flex-col items-center text-lg">
          <p>{t("Cruising.ShowroomsTable.add-1-more-showroom")}</p>
        </li>
      ) : null}
    </>
  );
}
