import { useQuery } from "@tanstack/react-query";

import { Collection } from "@models/old/Collection";
import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

export const fetchCollectionsEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/collections`,
};

type FetchCollectionsResponse = Collection[];

export const useFetchCollections = (organizationId: string) =>
  useQuery({
    queryKey: [...fetchCollectionsEndpoint.path(organizationId).split("/")],
    queryFn: () =>
      axiosInstance
        .get<FetchCollectionsResponse>(
          fetchCollectionsEndpoint.path(organizationId),
        )
        .then((res) => res.data),
  });
