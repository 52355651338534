import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { accountSchema } from "@models/Account";
import { appointmentHydratingSchema } from "@models/Appointment";
import { contactSchema } from "@models/Contact";
import { invitationSchema } from "@models/Invitation";
import { organizationSchema } from "@models/Organization";
import { showroomSchema } from "@models/Showroom";
import axiosInstance from "@services/api/config";
import {
  developmentMock,
  getAPIQueryKey,
  handleParsingError,
} from "@services/api/helper";

export namespace GetBuyerInvitations {
  export const outputSchema = z.array(
    invitationSchema.extend({
      showroom: showroomSchema.pick({
        id: true,
        city: true,
        countryCode: true,
        season: true,
        year: true,
        openingDays: true,
      }),
      appointments: z.array(
        appointmentHydratingSchema.pick({
          id: true,
        }),
      ),
      contact: contactSchema
        .pick({ id: true })
        .extend({ account: accountSchema.pick({ id: true, name: true }) }),
      organization: organizationSchema.pick({ id: true, name: true }),
    }),
  );

  export type Output = z.infer<typeof outputSchema>;

  export function path() {
    return `/buyer/invitations`;
  }

  export const getQueryKeys = () => getAPIQueryKey(path());

  export function call() {
    return axiosInstance
      .get<Output>(path())
      .then((r) =>
        handleParsingError(
          outputSchema,
          r.data,
          "Failed to fetch buyer invitations",
        ),
      )
      .catch(() => developmentMock(outputSchema));
  }

  export function useHook() {
    return useQuery({
      queryKey: getQueryKeys(),
      queryFn: () => call(),
    });
  }
}
