import React, { useState } from "react";

import { cva } from "class-variance-authority";
import { SubmitHandler, useForm } from "react-hook-form";
import { FcGoogle } from "react-icons/fc";
import { TiVendorMicrosoft } from "react-icons/ti";
import { useSearchParams } from "react-router-dom";

import useAuthentication from "@app/services/authentication/useAuthentication";
import { GuestOnly } from "@shared/components/guards/authentication";

type AuthenticationState = "signin" | "reset-password";

type CredentialsInput = {
  email: string;
  password: string;
};

const accessTypeButtonStyle = cva("text-center p-4", {
  variants: {
    active: {
      true: "bg-primaryLightElectricBlue text-primaryElectricBlue font-bold border border-primaryElectricBlue",
      false: "bg-gray-100 text-gray-400 hover:text-primaryElectricBlue",
    },
  },
});

type AccessType = "representative" | "buyer";

export default function LoginPage({
  context = "representative",
}: {
  context?: AccessType;
}) {
  const [searchParams] = useSearchParams(
    new URLSearchParams([["context", context]]),
  );
  const [accessType, setAccessType] = useState<AccessType>(
    (searchParams.get("context") as AccessType) || "representative",
  );
  const [authenticationState, setAuthenticationState] =
    useState<AuthenticationState>("signin");
  const { microsoftSignIn, googleSignIn, credentialsSignIn, resetPassword } =
    useAuthentication();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<CredentialsInput>();

  const signInSubmit: SubmitHandler<CredentialsInput> = (data) =>
    credentialsSignIn("representative", data.email, data.password).catch(() =>
      setError(
        "email",
        { type: "custom", message: "this email and password are invalid" },
        { shouldFocus: true },
      ),
    );

  const resetPasswordSubmit: SubmitHandler<CredentialsInput> = (data) =>
    resetPassword(data.email);

  const retrieveSubmitFn = () => {
    if (authenticationState === "reset-password") {
      return resetPasswordSubmit;
    }
    return signInSubmit;
  };

  return (
    <GuestOnly>
      <section className="bg-blueGray-50">
        <div className="w-full lg:w-4/12 px-4 mx-auto pt-6">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
            {authenticationState === "signin" ? (
              <>
                <div className="grid grid-cols-2">
                  <button
                    type="button"
                    className={accessTypeButtonStyle({
                      active: accessType === "representative",
                    })}
                    onClick={() => setAccessType("representative")}
                  >
                    I am a representative
                  </button>
                  <button
                    type="button"
                    className={accessTypeButtonStyle({
                      active: accessType === "buyer",
                    })}
                    onClick={() => setAccessType("buyer")}
                  >
                    I am a buyer
                  </button>
                </div>
                <div className="rounded-t mb-0 px-6 py-6 space-y-4">
                  <div className="text-center mb-3">
                    <h6 className="text-blueGray-500 text-sm font-bold">
                      Sign in with
                    </h6>
                  </div>
                  <div className="btn-wrapper text-center">
                    <button
                      className="bg-white active:bg-blueGray-50 text-blueGray-700 px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                      type="button"
                      title="google-signin"
                      onClick={() => googleSignIn(accessType)}
                    >
                      <FcGoogle />
                      Google
                    </button>
                  </div>
                  <div className="btn-wrapper text-center">
                    <button
                      className="bg-white active:bg-blueGray-50 text-blueGray-700 px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                      type="button"
                      title="microsoft-signin"
                      onClick={() => microsoftSignIn(accessType)}
                    >
                      <TiVendorMicrosoft />
                      Microsoft
                    </button>
                  </div>
                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                </div>
              </>
            ) : null}

            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              {authenticationState === "signin" ? (
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Or sign in with credentials</small>
                </div>
              ) : null}
              <form onSubmit={handleSubmit(retrieveSubmitFn())}>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    {...register("email", {
                      required: "required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Entered value does not match email format",
                      },
                    })}
                    type="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                  />
                  {errors.email && (
                    <span
                      role="alert"
                      title="email-alert"
                      className="text-primaryRed"
                    >
                      {errors.email.message}
                    </span>
                  )}
                </div>
                {authenticationState !== "reset-password" ? (
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      id="password"
                      {...register("password", { required: true })}
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                    />
                  </div>
                ) : null}
                {errors.password && (
                  <span role="alert" className="text-primaryRed">
                    This field is required
                  </span>
                )}
                <div className="text-center mt-6">
                  <button
                    title={
                      authenticationState === "signin"
                        ? "credentials-signin"
                        : "reset-password"
                    }
                    className="bg-blueGray-800 active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {authenticationState === "reset-password"
                      ? "Reset password"
                      : "Sign in"}
                  </button>
                </div>
                {authenticationState === "signin" ? (
                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      title="password-lost"
                      onClick={() => setAuthenticationState("reset-password")}
                    >
                      Password lost ?
                    </button>
                  </div>
                ) : (
                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 6"
                      type="button"
                      onClick={() => setAuthenticationState("signin")}
                    >
                      Return to Sign In
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </GuestOnly>
  );
}
