import { z } from "zod";

import { addressSchema } from "@models/types/Address";

const accountPortfolioTypes = [
  "PORTFOLIO_BASED_ON_COLLECTIONS",
  "PORTFOLIO_BASED_ON_WHOLE_SHOWROOM",
] as const;
export const accountPortfolioTypeSchema = z.enum(accountPortfolioTypes);

export const onboardingStatuses = [
  "STARTED",
  "DONE",
  "EMAIL_SENT",
  "REPRESENTATIVES_UPLOADED",
] as const;
export const onboardingStatusSchema = z.enum(onboardingStatuses);

export const organizationTypes = ["AGENCY", "BRAND"] as const;
export const organizationTypeSchema = z.enum(organizationTypes);
export type OrganizationType = z.infer<typeof organizationTypeSchema>;

export const organizationSchema = z.object({
  id: z.string(),
  type: organizationTypeSchema,
  name: z.string(),
  address: addressSchema,
  timezone: z.string(),
  onboardingStatus: onboardingStatusSchema,
  accountPortfolioType: accountPortfolioTypeSchema,
  banner: z.string().nullable(),
});

export type Organization = z.infer<typeof organizationSchema>;
