import { useQuery } from "@tanstack/react-query";

import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";
import { GetContactsEndpoint } from "@services/api/contacts/get-contacts";

export const fetchContactsArchivedEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/contacts/archived`,
};

type FetchContactsResponse = GetContactsEndpoint.Output;

export const queryKeysFetchContactsArchived = (organizationId: string) =>
  fetchContactsArchivedEndpoint.path(organizationId).split("/");

export const useFetchContactsArchived = (organizationId: string) =>
  useQuery({
    queryKey: queryKeysFetchContactsArchived(organizationId),
    queryFn: () =>
      axiosInstance
        .get<FetchContactsResponse>(
          fetchContactsArchivedEndpoint.path(organizationId),
        )
        .then((res) => res.data),
  });
