import { useMutation } from "@tanstack/react-query";

import axiosInstance from "@services/api/config";

export const sendConfirmationEmailsEndpoint = {
  path: (invitationId: string) =>
    `/bookings/${invitationId}/send-confirmation-email`,
};

export function useSendConfirmationEmails(invitationId: string) {
  return useMutation({
    mutationFn: (appointmentsIds: string[]) =>
      axiosInstance.post(
        sendConfirmationEmailsEndpoint.path(invitationId),
        appointmentsIds,
      ),
  });
}
