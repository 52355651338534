import React from "react";

import { flag } from "country-emoji";

import Tag from "@components/data-display/Tag";
import { CheckboxMark } from "@components/data-entry/Checkbox";
import MultiSelect from "@components/data-entry/MultiSelect";
import LoadingFetch from "@components/feedback/LoadingFetch";
import { arrayFilterUnique } from "@helpers/Array";
import { usePrefixedTranslation } from "@helpers/Translation";
import { Collection } from "@models/Collection";
import { Showroom } from "@models/Showroom";
import { AppointmentTypeEnum } from "@models/types/enums";
import { BookingGetBookedAppointmentsEndpoint } from "@services/api/booking/get-booked-appointments";
import { translateAppointmentType } from "@shared/helpers/translater";

import BookingCardPanel from "./appointment/card";
import { useBookingContext } from "./hook";

type Props = {
  invitationId: string;
  hiddenCollectionsIds: Collection["id"][];
  showrooms: {
    id: string;
    name: string;
    appointmentTypes: AppointmentTypeEnum[];
    city: Showroom["city"];
    countryCode: Showroom["countryCode"];
    collections: {
      id: string;
      name: string;
      brand: {
        name: string;
      };
    }[];
  }[];
  onNext: () => void;
};

export default function ShowroomSelect({
  invitationId,
  hiddenCollectionsIds,
  showrooms,
  onNext,
}: Props) {
  const { t, pt } = usePrefixedTranslation("Booking.ShowroomSelect");
  const {
    state,
    dispatch,
    hasAppointmentForCollection,
    hasWalkthroughInterestInCollection,
    selectWalkthroughCollectionInterests,
    selectShowroomAppointmentTypes,
    selectCollectionsSelected,
  } = useBookingContext();

  const { data: bookedAppointments = [], status: bookedAppointmentsStatus } =
    BookingGetBookedAppointmentsEndpoint.useHook({ invitationId });

  const getVisibleCollections = (showroom: Props["showrooms"][number]) =>
    showroom.collections
      //  filter collections where status = INACTIVE
      .filter((collection) => !hiddenCollectionsIds.includes(collection.id))
      // filter collections that have an appointment
      .filter(
        (collection) =>
          !bookedAppointments.some(
            (appt) =>
              appt.collection?.id === collection.id &&
              appt.showroom.id === showroom.id,
          ),
      );

  const isAppointmentTypeAccessible = (
    showroom: Props["showrooms"][number],
    type: AppointmentTypeEnum,
  ) => {
    if (type === AppointmentTypeEnum.BUYING_APPOINTMENT) {
      return getVisibleCollections(showroom).length > 0;
    }
    return bookedAppointments.every((appt) => appt.type !== type);
  };

  return (
    <BookingCardPanel
      title={pt("select-showrooms-collections")}
      step={1}
      onNext={onNext}
      isNextDisabled={state.appointments.length === 0}
    >
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <LoadingFetch
          error={pt("error-loading-booked-appointments")}
          status={bookedAppointmentsStatus}
        >
          {showrooms.map((showroom) => (
            <div
              className="border rounded border-primaryElectricBlue flex flex-col gap-4"
              key={showroom.id}
            >
              <div className="p-2 flex rounded-t items-center gap-4 bg-primaryLightElectricBlue font-bold border-b border-b-primaryLightGrey">
                {showroom.collections
                  .map((c) => c.brand.name)
                  .filter(arrayFilterUnique).length === 1
                  ? `${showroom.collections[0].brand.name} `
                  : ""}
                {showroom.name}
                <Tag theme="PRIMARY-REVERSED" contentClassName="gap-2">
                  <span>{showroom.city}</span>
                  <span>{flag(showroom.countryCode)}</span>
                </Tag>
              </div>
              <div className="px-4 pb-4 flex flex-col gap-4">
                {showroom.appointmentTypes.map((type) => (
                  <React.Fragment key={type}>
                    <button
                      disabled={!isAppointmentTypeAccessible(showroom, type)}
                      className="flex gap-3 items-center disabled:text-primaryGrey disabled:line-through"
                      key={type}
                      type="button"
                      onClick={() =>
                        dispatch(
                          type === AppointmentTypeEnum.BUYING_APPOINTMENT
                            ? {
                                type: "TOGGLE_BUYING",
                                showroomId: showroom.id,
                                collections: [
                                  {
                                    id: getVisibleCollections(showroom)[0].id,
                                    name: `${
                                      getVisibleCollections(showroom)[0].brand
                                        .name
                                    } ${
                                      getVisibleCollections(showroom)[0].name
                                    }`,
                                  },
                                ],
                              }
                            : {
                                type: "TOGGLE_WALKTHROUGH",
                                showroomId: showroom.id,
                                collectionInterests: [],
                              },
                        )
                      }
                    >
                      <CheckboxMark
                        state={selectShowroomAppointmentTypes(
                          showroom.id,
                        ).includes(type)}
                      />{" "}
                      {t(translateAppointmentType(type))}
                    </button>
                    {type === AppointmentTypeEnum.WALKTHROUGH && (
                      <MultiSelect
                        disabled={
                          !isAppointmentTypeAccessible(showroom, type) ||
                          !selectShowroomAppointmentTypes(showroom.id).includes(
                            AppointmentTypeEnum.WALKTHROUGH,
                          )
                        }
                        isOptionSelected={(option) =>
                          hasWalkthroughInterestInCollection(
                            showroom.id,
                            option.value,
                          )
                        }
                        value={selectWalkthroughCollectionInterests(
                          showroom.id,
                        )?.collectionInterests.map((c) => ({
                          label: c.name,
                          value: c.id,
                        }))}
                        options={[
                          {
                            options: getVisibleCollections(showroom)
                              .filter(
                                (c) =>
                                  !hasAppointmentForCollection(
                                    c.id,
                                    showroom.id,
                                  ),
                              )
                              .map((c) => ({
                                label: `${c.brand.name} - ${c.name}`,
                                value: c.id,
                              })),
                          },
                        ]}
                        onChange={(value) =>
                          dispatch({
                            type: "SET_INTERESTING_COLLECTIONS_TO_WALKTHROUGH",
                            showroomId: showroom.id,
                            collections: value.map((v) => ({
                              id: v.value,
                              name: v.label,
                            })),
                          })
                        }
                      />
                    )}
                    {type === AppointmentTypeEnum.BUYING_APPOINTMENT && (
                      <MultiSelect
                        disabled={
                          !isAppointmentTypeAccessible(showroom, type) ||
                          !selectShowroomAppointmentTypes(showroom.id).includes(
                            AppointmentTypeEnum.BUYING_APPOINTMENT,
                          )
                        }
                        isOptionSelected={(option) =>
                          hasAppointmentForCollection(option.value, showroom.id)
                        }
                        value={selectCollectionsSelected(showroom.id).map(
                          (c) => ({
                            label: c.name,
                            value: c.id,
                          }),
                        )}
                        options={[
                          {
                            options: getVisibleCollections(showroom).map(
                              (c) => ({
                                label: `${c.brand.name} - ${c.name}`,
                                value: c.id,
                              }),
                            ),
                          },
                        ]}
                        onChange={(value) =>
                          dispatch({
                            type: "SET_COLLECTIONS_TO_BUY",
                            showroomId: showroom.id,
                            collections: value.map((v) => ({
                              id: v.value,
                              name: v.label,
                            })),
                          })
                        }
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          ))}
        </LoadingFetch>
      </div>
    </BookingCardPanel>
  );
}
