import { format } from "date-fns";

import { getStartAndEnd } from "@app/helpers/Date";
import { OpeningDay } from "@models/Showroom";

export default function getShowroomOpeningPeriod(
  openingDays: OpeningDay[],
  fmt: string = "MMM d, yyyy",
): string {
  const [start, end] = getStartAndEnd(
    openingDays.map((openingDay) => openingDay.day),
  );

  return `${format(start, fmt)} - ${format(end, fmt)}`;
}
