import { parse } from "date-fns";

import { AppointmentFormat } from "@models/types/enums";

const defaultOptions: Pick<
  ShowroomData,
  | "openingHour"
  | "closingHour"
  | "contact"
  | "coordinates"
  | "address"
  | "zipCode"
> = {
  openingHour: "08-00",
  closingHour: "19-00",
  contact: {},
  address: "",
  zipCode: "",
  coordinates: [0, 0],
};

const showroomsData: ShowroomData[] = [
  {
    ...defaultOptions,
    id: "Showroom Romeo",
    name: "Showroom Romeo",
    organization: "Romeo",
    formats: ["IN_PERSON"],
    from: parse("28/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("06/10/2023", "dd/MM/yyyy", new Date()),
    address: "18 rue Ferdinand Duval",
    zipCode: "75004",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8564959, 2.3595474],
  },
  {
    ...defaultOptions,
    id: "Meryll Rogge",
    name: "Meryll Rogge",
    organization: "Meryll Rogge",
    formats: ["IN_PERSON"],
    from: parse("28/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("01/10/2023", "dd/MM/yyyy", new Date()),
    address: "51 rue Saint-Louis en l'Ile",
    zipCode: "75004",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8521493, 2.3555582],
  },
  {
    ...defaultOptions,
    id: "Paper Mache Tiger Showroom",
    name: "Paper Mache Tiger Showroom",
    organization: "Paper Mache",
    formats: ["IN_PERSON"],
    from: parse("28/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("05/10/2023", "dd/MM/yyyy", new Date()),
    address: "4 rue d'Uzès",
    zipCode: "75002",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8705024, 2.3451156],
  },
  {
    ...defaultOptions,
    id: "Boon Showroom",
    name: "Boon Showroom",
    organization: "Boon",
    formats: ["IN_PERSON"],
    from: parse("29/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("07/10/2023", "dd/MM/yyyy", new Date()),
    address: "5bis rue de Beauce",
    zipCode: "75003",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.863101, 2.3614296],
  },
  {
    ...defaultOptions,
    id: "Diesel",
    name: "Diesel",
    organization: "Diesel",
    formats: ["IN_PERSON"],
    from: parse("28/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("02/10/2023", "dd/MM/yyyy", new Date()),
    address: "23 rue du Mail",
    zipCode: "75002",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8669877, 2.3428565],
  },
  {
    ...defaultOptions,
    id: "Charlotte Chesnais",
    name: "Charlotte Chesnais",
    organization: "Charlotte Chesnais",
    formats: ["IN_PERSON"],
    from: parse("28/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("05/10/2023", "dd/MM/yyyy", new Date()),
    address: "2 rue de Valois",
    zipCode: "75001",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8628803, 2.3376524],
  },
  {
    ...defaultOptions,
    id: "Erdem",
    name: "Erdem",
    organization: "Erdem",
    formats: ["IN_PERSON"],
    from: parse("29/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("05/10/2023", "dd/MM/yyyy", new Date()),
    address: "1 place André Malraux",
    zipCode: "75001",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8638076, 2.3347536],
  },
  {
    ...defaultOptions,
    id: "Sunnei",
    name: "Sunnei",
    organization: "Sunnei",
    formats: ["IN_PERSON"],
    from: parse("29/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("04/10/2023", "dd/MM/yyyy", new Date()),
    address: "51 rue de Monceau",
    zipCode: "75008",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8767126, 2.3085902],
  },
  {
    ...defaultOptions,
    id: "Cinq à Sept",
    name: "Cinq à Sept",
    organization: "Cinq à Sept",
    formats: ["IN_PERSON"],
    from: parse("29/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("04/10/2023", "dd/MM/yyyy", new Date()),
    address: "9 avenue Hoche",
    zipCode: "75008",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8772209, 2.3037551],
  },
  {
    ...defaultOptions,
    id: "Sphere Showroom",
    name: "Sphere Showroom",
    organization: "Sphere",
    formats: ["IN_PERSON"],
    from: parse("28/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("04/10/2023", "dd/MM/yyyy", new Date()),
    address: "Palais de Tokyo",
    zipCode: "75116",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8639905, 2.296521513],
  },
  {
    ...defaultOptions,
    id: "Présentation Sonia Carrasco",
    name: "Présentation Sonia Carrasco",
    organization: "Boon",
    formats: ["IN_PERSON"],
    from: parse("29/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("07/10/2023", "dd/MM/yyyy", new Date()),
    address: "7 rue Quentin Bauchart",
    zipCode: "75008",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.869177, 2.2997159],
  },
  {
    ...defaultOptions,
    id: "247 Showroom",
    name: "247 Showroom",
    organization: "247",
    formats: ["IN_PERSON"],
    from: parse("28/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("03/10/2023", "dd/MM/yyyy", new Date()),
    address: "9 rue Lincoln",
    zipCode: "75008",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8703417, 2.3028023],
  },
  {
    ...defaultOptions,
    id: "Showroom No Season",
    name: "Showroom No Season",
    organization: "No Season",
    formats: ["IN_PERSON"],
    from: parse("29/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("05/10/2023", "dd/MM/yyyy", new Date()),
    address: "8bis rue de Braque",
    zipCode: "75003",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8605918, 2.3573796],
  },
  {
    ...defaultOptions,
    id: "Amca Oval",
    name: "Amca Oval",
    organization: "Amca Oval",
    formats: ["IN_PERSON"],
    from: parse("30/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("10/10/2023", "dd/MM/yyyy", new Date()),
    address: "15 rue Tiquetonne",
    zipCode: "75002",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8645912, 2.3487249],
  },
  {
    ...defaultOptions,
    id: "Rochas",
    name: "Rochas",
    organization: "Rochas",
    formats: ["IN_PERSON"],
    from: parse("30/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("04/10/2023", "dd/MM/yyyy", new Date()),
    address: "4 rue de Jarente",
    zipCode: "75004",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8556569, 2.3632108],
  },
  {
    ...defaultOptions,
    id: "Ideal Showroom",
    name: "Ideal Showroom",
    organization: "Ideal",
    formats: ["IN_PERSON"],
    from: parse("28/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("05/10/2023", "dd/MM/yyyy", new Date()),
    address: "16 rue des Minimes",
    zipCode: "75003",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8574478, 2.3649288],
  },
  {
    ...defaultOptions,
    id: "Greta Constantine",
    name: "Greta Constantine",
    organization: "Greta Constantine",
    formats: ["IN_PERSON"],
    from: parse("28/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("05/10/2023", "dd/MM/yyyy", new Date()),
    address: "52 rue de Ponthieu",
    zipCode: "75008",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8716682, 2.3066524],
  },
  {
    ...defaultOptions,
    id: "JW Anderson",
    name: "JW Anderson",
    organization: "JW Anderson",
    formats: ["IN_PERSON"],
    from: parse("29/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("03/10/2023", "dd/MM/yyyy", new Date()),
    address: "20 rue Vivienne",
    zipCode: "75002",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8684234, 2.3398787],
  },
  {
    ...defaultOptions,
    id: "Eckhaus Latta",
    name: "Eckhaus Latta",
    organization: "Eckhaus Latta",
    formats: ["IN_PERSON"],
    from: parse("27/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("03/10/2023", "dd/MM/yyyy", new Date()),
    address: "236 rue Saint Martin",
    zipCode: "75003",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8644072, 2.353191],
  },
  {
    ...defaultOptions,
    id: "Présentation Minuit",
    name: "Présentation Minuit",
    organization: "Minuit",
    formats: ["IN_PERSON"],
    from: parse("28/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("04/10/2023", "dd/MM/yyyy", new Date()),
    address: "14 rue du Vertbois",
    zipCode: "75003",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8667809, 2.3592751],
  },
  {
    ...defaultOptions,
    id: "Ester Manas",
    name: "Ester Manas",
    organization: "Ester Manas",
    formats: ["IN_PERSON"],
    from: parse("01/10/2023", "dd/MM/yyyy", new Date()),
    to: parse("03/10/2023", "dd/MM/yyyy", new Date()),
    address: "62 rue Saint Sabin",
    zipCode: "75011",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8593028, 2.3689081],
  },
  {
    ...defaultOptions,
    id: "Awaykin Showroom",
    name: "Awaykin Showroom",
    organization: "Awaykin",
    formats: ["IN_PERSON"],
    from: parse("30/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("05/10/2023", "dd/MM/yyyy", new Date()),
    address: "78 rue Amelot",
    zipCode: "75011",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8606142, 2.3679603],
  },
  {
    ...defaultOptions,
    id: "Forte Forte",
    name: "Forte Forte",
    organization: "Forte Forte",
    formats: ["IN_PERSON"],
    from: parse("30/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("05/10/2023", "dd/MM/yyyy", new Date()),
    address: "58 rue Charlot",
    zipCode: "75003",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8634871, 2.3633365],
  },
  {
    ...defaultOptions,
    id: "Issey Miyake",
    name: "Issey Miyake",
    organization: "Issey Miyake",
    formats: ["IN_PERSON"],
    from: parse("02/10/2023", "dd/MM/yyyy", new Date()),
    to: parse("05/10/2023", "dd/MM/yyyy", new Date()),
    address: "60 rue de Richelieu",
    zipCode: "75002",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8684115, 2.3383691],
  },
  {
    ...defaultOptions,
    id: "Victoria Beckham",
    name: "Victoria Beckham",
    organization: "Victoria Beckham",
    formats: ["IN_PERSON"],
    from: parse("03/10/2023", "dd/MM/yyyy", new Date()),
    to: parse("06/10/2023", "dd/MM/yyyy", new Date()),
    address: "40 rue de Richelieu",
    zipCode: "75001",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8658601, 2.3370249],
  },
  {
    ...defaultOptions,
    id: "Showroom Together (by Plan 8)",
    name: "Showroom Together (by Plan 8)",
    organization: "Plan 8",
    formats: ["IN_PERSON"],
    from: parse("28/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("03/10/2023", "dd/MM/yyyy", new Date()),
    address: "71 rue de la Fontaine au Roi",
    zipCode: "75011",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8687529, 2.3756021],
  },
  {
    ...defaultOptions,
    id: "Spazio38",
    name: "Spazio38",
    organization: "Spazio38",
    formats: ["IN_PERSON"],
    from: parse("30/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("05/10/2023", "dd/MM/yyyy", new Date()),
    address: "16 rue Neuve Popincourt",
    zipCode: "75011",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8637187, 2.3745852],
  },
  {
    ...defaultOptions,
    id: "Gauchère",
    name: "Gauchère",
    organization: "Gauchère",
    formats: ["IN_PERSON"],
    from: parse("02/10/2023", "dd/MM/yyyy", new Date()),
    to: parse("06/10/2023", "dd/MM/yyyy", new Date()),
    address: "188 rue de Rivoli",
    zipCode: "75001",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8635231, 2.3330873],
  },
  {
    ...defaultOptions,
    id: "Sid Neigum",
    name: "Sid Neigum",
    organization: "Sid Neigum",
    formats: ["IN_PERSON"],
    from: parse("01/10/2023", "dd/MM/yyyy", new Date()),
    to: parse("03/10/2023", "dd/MM/yyyy", new Date()),
    address: "Place André Malraux",
    zipCode: "75001",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8635405, 2.3353617],
  },
  {
    ...defaultOptions,
    id: "Ganni",
    name: "Ganni",
    organization: "Ganni",
    formats: ["IN_PERSON"],
    from: parse("01/10/2023", "dd/MM/yyyy", new Date()),
    to: parse("04/10/2023", "dd/MM/yyyy", new Date()),
    address: "3 rue Portefoin",
    zipCode: "75003",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8635239, 2.3601903],
  },
  {
    ...defaultOptions,
    id: "Coperni",
    name: "Coperni",
    organization: "Coperni",
    formats: ["IN_PERSON"],
    from: parse("01/10/2023", "dd/MM/yyyy", new Date()),
    to: parse("06/10/2023", "dd/MM/yyyy", new Date()),
    address: "108 rue Vieille du Temple",
    zipCode: "75003",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.860777, 2.3630551],
  },
  {
    ...defaultOptions,
    id: "Filippa K",
    name: "Filippa K",
    organization: "Filippa K",
    formats: ["IN_PERSON"],
    from: parse("29/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("04/10/2023", "dd/MM/yyyy", new Date()),
    address: "11 rue des Arquebusiers",
    zipCode: "75003",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8594876, 2.3666497],
  },
  {
    ...defaultOptions,
    id: "American Vintage",
    name: "American Vintage",
    organization: "American Vintage",
    formats: ["IN_PERSON"],
    from: parse("26/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("04/10/2023", "dd/MM/yyyy", new Date()),
    address: "23 rue Etienne Marcel",
    zipCode: "75001",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.864505, 2.346343],
  },
  {
    ...defaultOptions,
    id: "Dover Street Market",
    name: "Dover Street Market",
    organization: "Dover Street Market",
    formats: ["IN_PERSON"],
    from: parse("02/10/2023", "dd/MM/yyyy", new Date()),
    to: parse("06/10/2023", "dd/MM/yyyy", new Date()),
    address: "35-37 rue des Francs-Bourgeois",
    zipCode: "75004",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8568706, 2.3629214],
  },
  {
    ...defaultOptions,
    id: "Moschino",
    name: "Moschino",
    organization: "Moschino",
    formats: ["IN_PERSON"],
    from: parse("02/10/2023", "dd/MM/yyyy", new Date()),
    to: parse("06/10/2023", "dd/MM/yyyy", new Date()),
    address: "43 rue du Faubourg St-Honoré",
    zipCode: "75008",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8698959, 2.3182968],
  },
  {
    ...defaultOptions,
    id: "MM6",
    name: "MM6",
    organization: "MM6",
    formats: ["IN_PERSON"],
    from: parse("03/10/2023", "dd/MM/yyyy", new Date()),
    to: parse("05/10/2023", "dd/MM/yyyy", new Date()),
    address: "42 avenue d'Iéna",
    zipCode: "75116",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8666624, 2.2953186],
  },
  {
    ...defaultOptions,
    id: "Parrot Showroom (Wandler)",
    name: "Parrot Showroom (Wandler)",
    organization: "Wandler",
    formats: ["IN_PERSON"],
    from: parse("30/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("05/10/2023", "dd/MM/yyyy", new Date()),
    address: "1 rue de Longchamp",
    zipCode: "75116",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.864807, 2.2930357],
  },
  {
    ...defaultOptions,
    id: "Staud",
    name: "Staud",
    organization: "Staud",
    formats: ["IN_PERSON"],
    from: parse("30/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("05/10/2023", "dd/MM/yyyy", new Date()),
    address: "5 rue des Ursulines",
    zipCode: "75005",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8425867, 2.3424853],
  },
  {
    ...defaultOptions,
    id: "Nanushka",
    name: "Nanushka",
    organization: "Nanushka",
    formats: [],
    from: parse("30/09/2023", "dd/MM/yyyy", new Date()),
    to: parse("06/10/2023", "dd/MM/yyyy", new Date()),
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8767126, 2.3085902],
  },
  {
    ...defaultOptions,
    id: "Balmain",
    name: "Balmain",
    organization: "Balmain",
    formats: ["IN_PERSON", "VIRTUAL"],
    from: parse("04/10/2023", "dd/MM/yyyy", new Date()),
    to: parse("06/10/2023", "dd/MM/yyyy", new Date()),
    address: "25 rue Pasquier",
    zipCode: "75008",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8728964, 2.3234536],
  },
  {
    ...defaultOptions,
    id: "Lanvin",
    name: "Lanvin",
    organization: "Lanvin",
    formats: ["IN_PERSON"],
    from: parse("06/10/2023", "dd/MM/yyyy", new Date()),
    to: parse("10/10/2023", "dd/MM/yyyy", new Date()),
    address: "8 rue Alfred de Vigny",
    zipCode: "75008",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8788089, 2.3052452],
  },
  {
    ...defaultOptions,
    id: "Rokh",
    name: "Rokh",
    organization: "Rokh",
    formats: ["IN_PERSON"],
    from: parse("04/10/2023", "dd/MM/yyyy", new Date()),
    to: parse("07/10/2023", "dd/MM/yyyy", new Date()),
    address: "11 bis rue Elzevir",
    zipCode: "75003",
    country: "fr",
    city: "Paris",
    duration: 60,
    coordinates: [48.8583995, 2.3616438],
  },
];

export type ShowroomData = {
  id: string;
  organization: string;
  name: string;
  duration: number;
  address: string;
  country: string;
  zipCode: string;
  city: string;
  formats: AppointmentFormat[];
  contact: {
    email?: string;
    phoneNumber?: string;
  };
  openingHour: string;
  closingHour: string;
  coordinates: [number, number];
  from: Date;
  to: Date;
};

export default showroomsData;
