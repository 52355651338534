import React, { useEffect } from "react";

import { isEqual } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HiOutlineCalendar } from "react-icons/hi";
import { concat, find, intersectionWith } from "remeda";

import Button from "@components/data-entry/Button";
import CustomDatePicker from "@components/data-entry/CustomDatepicker";
import CalloutBox from "@components/feedback/CalloutBox";
import LoadingFetch from "@components/feedback/LoadingFetch";
import BottomBar from "@components/layout/BottomBar";
import { localDateToUnzonedDate } from "@helpers/Date";
import { Showroom } from "@models/Showroom";
import { GetShowroomEndpoint } from "@services/api/showroom/get-showroom";
import { ShowroomUpdateEndpoint } from "@services/api/showroom/update-showroom";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { buildOpeningDay } from "@shared/showroom/helpers";

interface ShowroomEditOpeningDaysFormProps {
  showroomId: Showroom["id"];
  onSuccess: () => void;
}

export interface ShowroomEditOpeningDaysFormData {
  openingDays: Date[];
}

export function useSalesCampaignEditOpeningDaysForm(
  defaultValues: Date[] = [],
) {
  const form = useForm<ShowroomEditOpeningDaysFormData>({
    defaultValues: {
      openingDays: defaultValues,
    },
  });
  return {
    form,
  };
}

function ShowroomEditOpeningDaysForm(props: ShowroomEditOpeningDaysFormProps) {
  const { showroomId, onSuccess } = props;
  const { t } = useTranslation();
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const { data: showroom, status: showroomFetchStatus } =
    GetShowroomEndpoint.useHook({
      organizationId,
      showroomId,
    });
  const { form } = useSalesCampaignEditOpeningDaysForm(
    showroom?.openingDays.map((d) => d.day) || [],
  );
  useEffect(() => {
    if (showroom) {
      form.reset({
        openingDays: showroom.openingDays.map((d) => d.day),
      });
    }
  }, [showroom]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = form;

  const { mutateAsync: updateShowroom, isPending: isUpdating } =
    ShowroomUpdateEndpoint.useHook({
      organizationId,
      showroomId,
    });

  const onSubmit = (data: ShowroomEditOpeningDaysFormData) => {
    if (showroom) {
      const customIsEqual = (a: Date, b: Date) =>
        isEqual(a, localDateToUnzonedDate(b));
      const showroomDays = showroom.openingDays.map((d) => d.day);
      const daysUntouched = intersectionWith(
        showroomDays,
        data.openingDays,
        customIsEqual,
      );
      const daysToAdd: Date[] = data.openingDays.filter(
        (d) => find(showroomDays, (sd) => customIsEqual(sd, d)) === undefined,
      );
      const openingDays = concat(
        showroom.openingDays.filter((op) => daysUntouched.includes(op.day)),
        daysToAdd.map((d) => buildOpeningDay(d)),
      );

      updateShowroom({
        data: {
          ...showroom,
          id: showroomId,
          openingDays,
        },
        files: [],
      }).then(onSuccess);
    }
  };

  return (
    <LoadingFetch status={showroomFetchStatus} error={t("Common.error")}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col grow">
        <div className="flex flex-col gap-2 grow p-10 pt-6">
          <CalloutBox type="WARNING">
            {t(
              "SalesCampaign.overview.edit-opening-days-form.days-with-appointments-warning",
            )}
          </CalloutBox>
          <label className="font-medium" htmlFor="showroomOpeningDays">
            {t("SalesCampaign.showroom.global-informations.openingDays.label")}
            <div className="text-xs mb-1 text-primaryGrey">
              {t(
                "SalesCampaign.showroom.global-informations.openingDays.description",
              )}
            </div>
          </label>
          <div className="max-w-lg">
            <Controller
              name="openingDays"
              control={control}
              rules={{
                validate: {
                  atLeastOneRequired: (value) =>
                    (value && value.length > 0) ||
                    t(
                      "SalesCampaign.showroom.global-informations.openingDays.required",
                    ),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  placeholder={
                    <div className="flex items-center gap-2 w-74">
                      <HiOutlineCalendar />{" "}
                      <span className="text-grey">
                        {t(
                          "SalesCampaign.showroom.global-informations.openingDays.placeholder",
                        )}
                      </span>
                    </div>
                  }
                  value={value}
                  multiple
                  onChange={(date) => {
                    if (date === null) {
                      onChange([]);
                    } else if (Array.isArray(date)) {
                      onChange(date);
                    } else {
                      onChange([date]);
                    }
                  }}
                  intlOptions={{
                    month: "numeric",
                    day: "numeric",
                    year: "numeric",
                  }}
                  minDate={Math.min(
                    Date.now(),
                    ...(value || []).map((d) => d.getTime()),
                  )}
                />
              )}
            />
            {errors.openingDays?.message && (
              <p className="text-xs italic text-primaryRed">
                {`${errors.openingDays?.message}`}
              </p>
            )}
          </div>
        </div>
        <BottomBar>
          <Button
            theme="PRIMARY"
            onClick={onSuccess}
            disabled={!isValid}
            loading={isUpdating}
          >
            {t("Common.save")}
          </Button>
        </BottomBar>
      </form>
    </LoadingFetch>
  );
}

export default ShowroomEditOpeningDaysForm;
