import React from "react";

import { useTranslation } from "react-i18next";

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center align-middle items-center gap-16 p-16">
      <img
        alt="not found gif"
        src="https://media.giphy.com/media/ApRgeCd4T7GKY/giphy.gif"
      />
      <h1 className="text-2xl">{t("NotFound.text")}</h1>
    </div>
  );
}
