import React, { ForwardedRef, ReactNode, forwardRef } from "react";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { buttonStyle } from "@components/data-entry/Button";
import WithConditionalRendering from "@components/high-order-components/conditional-render";

const Content = forwardRef(
  (
    { className, ...props }: DropdownMenu.DropdownMenuContentProps,
    ref?: ForwardedRef<HTMLDivElement>,
  ) => (
    <DropdownMenu.Content
      {...props}
      ref={ref}
      className={`${className} bg-white border rounded-lg shadow-lg z-select animate-slideDownAndFade`}
    />
  ),
);

const Item = forwardRef(
  (
    { className, ...props }: DropdownMenu.DropdownMenuItemProps,
    ref?: ForwardedRef<HTMLDivElement>,
  ) => (
    <DropdownMenu.DropdownMenuItem
      {...props}
      ref={ref}
      className={`rounded flex items-center gap-2 transition-all duration-200 px-4 text-left py-3 hover:bg-gray-100 cursor-pointer ${className}`}
    />
  ),
);

interface DropDownProps {
  trigger: ReactNode;
  triggerType?: "button" | "none";
  items: {
    content: ReactNode;
    onSelect: () => void;
  }[];
  btnStyle?: Parameters<typeof buttonStyle>[0];
}

function DropDownComponent({
  items,
  trigger,
  triggerType = "button",
  btnStyle = {
    rounded: "full",
    size: "medium",
    theme: "TERTIARY",
  },
}: DropDownProps) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        className={triggerType === "button" ? buttonStyle(btnStyle) : ""}
      >
        {trigger}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <Content>
          {items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Item key={index} onSelect={item.onSelect}>
              {item.content}
            </Item>
          ))}
        </Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

export const DropDown = WithConditionalRendering(DropDownComponent);

export default {
  ...DropdownMenu,
  Content,
  Item,
};
