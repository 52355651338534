import { GetAllBrandsEndpoint } from "@services/api/brands/get-all-brands";

export default function getOptionMaker<Option>(
  getLabel: (o: Option) => string,
  getValue: (o: Option) => string = (o) => o as string, // default will return the actual option, makes it easier for enums
) {
  return (option: Option) => ({
    label: getLabel(option),
    value: getValue(option),
  });
}

export const brandToOption = getOptionMaker<GetAllBrandsEndpoint.OutputItem>(
  (brand) => brand.name,
  (brand) => brand.id,
);
