import React, { useState } from "react";

import {
  Popover as HeadlessPopover,
  Portal,
  Transition,
} from "@headlessui/react";
import { useDebounce } from "usehooks-ts";

import WithConditionalRendering from "@components/high-order-components/conditional-render";
import { fadeInOutProps } from "@components/transitions/FadeInOut";
import usePopup, { Placement } from "@helpers/Popup";

export interface PopoverProps {
  button: JSX.Element;
  placement?: Placement;
  buttonClassName?: string;
  cardClassname?: string;
  children: React.ReactNode;
}

function Popover({
  button,
  placement = "top",
  buttonClassName,
  cardClassname,
  children,
}: PopoverProps) {
  const [isOpen, setIsOpen] = useState(false);
  const debouncedIsOpen = useDebounce(isOpen, 200);
  const popup = usePopup({ placement });

  return (
    <HeadlessPopover className="relative cursor-pointer">
      <HeadlessPopover.Button
        as="div"
        ref={popup.setReferenceElement}
        className={`outline-none ${buttonClassName || ""}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {button}
      </HeadlessPopover.Button>
      <Portal as="div" className="fixed z-popover">
        <Transition
          show={isOpen || debouncedIsOpen}
          {...fadeInOutProps}
          ref={popup.setPopupElement}
          {...popup.attributes.popper}
          style={popup.styles.popper}
        >
          <HeadlessPopover.Panel
            className={`p-4 shadow-lg bg-white rounded border border-primaryLightElectricBlue ${
              cardClassname || ""
            }`}
          >
            {children}
          </HeadlessPopover.Panel>
        </Transition>
      </Portal>
    </HeadlessPopover>
  );
}

export default WithConditionalRendering(Popover);
