/* eslint-disable react/no-unstable-nested-components */
import React from "react";

import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";

import HoverCard from "@components/data-display/HoverCard";
import MRTable from "@components/data-display/MRTable";
import Button from "@components/data-entry/Button";
import { Representative } from "@models/old/Representative";
import { ACL_ADMINS } from "@shared/components/access-control/helpers";
import { fullName } from "@shared/helpers/formatters";

interface ArchivedRepresentativesTableProps {
  representatives: Representative[];
  onClickRestore: (representative: Representative) => void;
}

export default function ArchivedRepresentativesTable({
  representatives,
  onClickRestore,
}: ArchivedRepresentativesTableProps) {
  const columnHelper = createColumnHelper<Representative>();

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        header: t("Representatives.archived.table-header.name"),
        cell: ({ row: { original } }) => (
          <HoverCard
            renderIf={fullName(original).length > 20}
            fallbackProp="children"
            button={
              <div className="min-w-[10rem] max-w-[30rem] truncate">
                {fullName(original)}
              </div>
            }
          >
            <div className="min-w-[10rem]">{fullName(original)}</div>
          </HoverCard>
        ),
      }),
      columnHelper.accessor("position", {
        header: t("Representatives.archived.table-header.position"),
        cell: (info) => info.getValue() || "-",
      }),
      columnHelper.display({
        id: "actions",
        meta: {
          cellClassName: "sticky right-0 bg-white",
        },
        cell: ({ row }) => {
          const { id } = row.original;
          return (
            <div className="flex items-center justify-end mr-4">
              <Button
                aclRoles={ACL_ADMINS}
                type="button"
                theme="PRIMARY"
                testId={`restore-representative-${id}`}
                onClick={() => onClickRestore(row.original)}
              >
                {t("Representatives.archived.table-header.restore-button")}
              </Button>
            </div>
          );
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnHelper],
  );

  return (
    <MRTable
      data={representatives}
      columns={columns}
      cellClassName="border rounded-md"
      noDataLabel={t("Representatives.archived.no-results")}
      displayDataLabel={t("Representatives.archived.data-label")}
    />
  );
}
