import React from "react";

import { format } from "date-fns";
import { Trans } from "react-i18next";
import {
  HiChevronLeft,
  HiChevronRight,
  HiOutlineCalendar,
  HiOutlineClock,
  HiOutlineStar,
} from "react-icons/hi2";
import { DateObject } from "react-multi-date-picker";

import {
  ShowroomFormData,
  useShowroomForm,
  validShowroomFormDataSchema,
} from "@app/modules/showroom/form/hook";
import TogglePanel from "@components/data-display/TogglePanel";
import Button from "@components/data-entry/Button";
import CustomDatePicker from "@components/data-entry/CustomDatepicker";
import TimePicker from "@components/data-entry/TimePicker";
import BottomBar from "@components/layout/BottomBar";
import { combineDateAndTime, formatDatetime } from "@helpers/Date";
import { usePrefixedTranslation } from "@helpers/Translation";
import {
  getFirstOpeningDay,
  getLastOpeningDay,
  getOpeningDates,
  getOpeningDayForDate,
} from "@shared/showroom/helpers";

const restrictionsSchema = validShowroomFormDataSchema
  .pick({
    appointmentDeadline: true,
    keyAccountDatetime: true,
  })
  .passthrough();

interface Props {
  defaultValues?: Partial<ShowroomFormData>;
  onSubmit: (d: ShowroomFormData) => void;
  onBack: () => void;
}

export default function ShowroomRestrictionForm({
  defaultValues,
  onSubmit,
  onBack,
}: Props) {
  const {
    form,
    setDeadline,
    removeDeadline,
    setKeyAccountDatetime: setKeyAccountCustomHours,
    removeKeyAccountDatetime: removeKeyAccountCustomHours,
  } = useShowroomForm({ defaultValues, schema: restrictionsSchema });

  const { pt, t, pk } = usePrefixedTranslation(
    "Showroom.ShowroomRestrictionForm",
  );

  const {
    appointmentDeadline,
    openingDays,
    keyAccountDatetime,
    openingHour,
    closingHour,
  } = form.watch();

  const {
    day: firstOpeningDate,
    customOpeningHour: firstOpeningDayOpeningHour,
  } = getFirstOpeningDay({ openingDays });
  const { day: lastOpeningDate, customClosingHour: lastOpeningDayClosingHour } =
    getLastOpeningDay({ openingDays });
  const openingDatetime = combineDateAndTime(
    firstOpeningDate,
    firstOpeningDayOpeningHour || openingHour,
  );
  const closingDatetime = combineDateAndTime(
    lastOpeningDate,
    lastOpeningDayClosingHour || closingHour,
  );

  const keyAccountDay = keyAccountDatetime
    ? getOpeningDayForDate(keyAccountDatetime, { openingDays })
    : undefined;

  const openingHourOnKeyAccountDate =
    keyAccountDay && keyAccountDay.customOpeningHour
      ? keyAccountDay.customOpeningHour
      : openingHour;
  const closingHourOnKeyAccountDate =
    keyAccountDay && keyAccountDay.customClosingHour
      ? keyAccountDay.customClosingHour
      : closingHour;

  const handleSubmit = form.handleSubmit(onSubmit);

  return (
    <>
      <div className="grow p-4 flex flex-col gap-4">
        <TogglePanel
          initiallyOpened={!!appointmentDeadline}
          title={pt("booking-deadline.title")}
          id={pt("booking-deadline.subtitle")}
          icon={<HiOutlineClock className="w-4 h-4" />}
          onOff={removeDeadline}
        >
          <p>
            <Trans
              i18nKey="SalesCampaign.showroom.restrictions.booking-deadline.explanation"
              components={{
                bold: <strong className="font-bold" />,
              }}
            />
          </p>
          <CustomDatePicker
            placeholder={
              !appointmentDeadline ? (
                <div className="flex items-center gap-2 w-74">
                  <HiOutlineCalendar />{" "}
                  <span className="text-grey">
                    {pt("booking-deadline.placeholder")}
                  </span>
                </div>
              ) : (
                format(appointmentDeadline, "MMM d, yyyy")
              )
            }
            value={appointmentDeadline}
            onChange={(d) =>
              d instanceof Date ? setDeadline(d) : removeDeadline()
            }
            multiple={false}
            minDate={Date.now()}
            maxDate={lastOpeningDate}
            currentDate={new DateObject(firstOpeningDate)}
          />
        </TogglePanel>

        <TogglePanel
          initiallyOpened={!!keyAccountDatetime}
          id="key-accounts-dedicated-slots.id"
          title={pt("key-accounts-dedicated-slots.title")}
          subtitle={pt("key-accounts-dedicated-slots.subtitle")}
          icon={<HiOutlineStar className="w-4 h-4" />}
          onOff={removeKeyAccountCustomHours}
        >
          <div className="bg-primaryLightestGrey rounded-b-lg">
            <p className="p-4">
              <Trans
                i18nKey={pk(
                  "key-accounts-dedicated-slots.key-accounts-will-be-able-to-book",
                )}
                values={{
                  date: formatDatetime(openingDatetime),
                }}
                components={{
                  strong: <strong className="font-bold" />,
                }}
              />
            </p>
            <div className="px-4 flex gap-2 items-center">
              <CustomDatePicker
                allowedDates={getOpeningDates({ openingDays })}
                placeholder={
                  !keyAccountDatetime ? (
                    <div className="flex items-center gap-2">
                      <HiOutlineCalendar />{" "}
                      <span className="text-grey">
                        {pt("key-accounts-dedicated-slots.date-placeholder")}
                      </span>
                    </div>
                  ) : (
                    format(keyAccountDatetime, "MMM d, yyyy")
                  )
                }
                value={keyAccountDatetime}
                onChange={(v) => {
                  if (v instanceof Date) {
                    setKeyAccountCustomHours(
                      combineDateAndTime(
                        v,
                        keyAccountDatetime || closingHourOnKeyAccountDate,
                      ),
                    );
                  }
                }}
                multiple={false}
                minDate={firstOpeningDate}
                maxDate={lastOpeningDate}
                currentDate={
                  new DateObject(
                    combineDateAndTime(firstOpeningDate, openingHour),
                  )
                }
              />
              <div className="w-36">
                <TimePicker
                  placeholder={pt(
                    "key-accounts-dedicated-slots.time-placeholder",
                  )}
                  name="keyAccountDate"
                  options={{
                    firstHourOfDay: 6,
                    totalHours: 16,
                    timeStep: 15,
                  }}
                  minTime={openingHourOnKeyAccountDate}
                  maxTime={closingHourOnKeyAccountDate}
                  onChange={(v) => {
                    if (keyAccountDatetime && v) {
                      setKeyAccountCustomHours(
                        combineDateAndTime(keyAccountDatetime, v),
                      );
                    }
                  }}
                  defaultValue={keyAccountDatetime}
                />
              </div>
            </div>
            <p className="p-4">
              <Trans
                i18nKey={pk(
                  "key-accounts-dedicated-slots.other-accounts-will-be-able-to-book",
                )}
                values={{
                  date: formatDatetime(closingDatetime),
                }}
                components={{
                  strong: <strong className="font-bold" />,
                }}
              />
            </p>
          </div>
        </TogglePanel>
      </div>
      <BottomBar>
        <Button onClick={onBack}>
          <HiChevronLeft />
          {t("Common.previous")}
        </Button>
        <Button theme="PRIMARY" onClick={handleSubmit}>
          {t("Common.next")}
          <HiChevronRight />
        </Button>
      </BottomBar>
    </>
  );
}
