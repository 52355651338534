import { Address } from "@models/types/Address";

type ElementType =
  | "country"
  | "postal_code"
  | "postal_town"
  | "locality"
  | "sublocality"
  | "administrative_area_level_2";

// TODO : check what information are mandatories
export const findElementInAddressComponents =
  (address_components: google.maps.GeocoderAddressComponent[]) =>
  (type: ElementType): string | undefined => {
    const element = address_components.find((a) => a.types.includes(type));
    return element?.short_name;
  };

export const findCity = (
  finder: (type: ElementType) => string | undefined,
): string => {
  let city = finder("locality");
  if (city === undefined) {
    city = finder("postal_town");
  }
  if (city === undefined) {
    city = finder("sublocality");
  }
  if (city === undefined) {
    city = finder("administrative_area_level_2");
  }
  if (city === undefined) {
    throw new Error(
      "google API result should have [locality, postal_town, sublocality, administrative_area_level_2] in address_components",
    );
  }
  return city;
};

const addressAutocompleteResultMapper = (
  googlePlaceResult: google.maps.places.PlaceResult | string,
): Address => {
  if (typeof googlePlaceResult === "string") {
    throw new Error(
      `google API returning "${googlePlaceResult}" as string without address components`,
    );
  }
  if (!googlePlaceResult.formatted_address) {
    throw new Error("google API result is missing formatted_address");
  }
  if (!googlePlaceResult.address_components) {
    throw new Error("google API result is missing address_components");
  }
  const finder = findElementInAddressComponents(
    googlePlaceResult.address_components,
  );
  const countryCode = finder("country");
  if (!countryCode) {
    throw new Error(
      "google API result should have country in address_components",
    );
  }
  return {
    formattedAddress: googlePlaceResult.formatted_address,
    postalCode: finder("postal_code") || "unknown",
    city: findCity(finder),
    countryCode,
    addressComponents: googlePlaceResult.address_components,
  };
};

export default addressAutocompleteResultMapper;
