import { getDetails } from "use-places-autocomplete";

import addressAutocompleteResultMapper from "@components/data-entry/AddressAutocompleteResultMapper";
import { Address } from "@models/types/Address";

export default async function validateAddressWithGoogleMaps(
  address: string,
): Promise<Address> {
  let newAddress = {
    city: "",
    postalCode: "",
    countryCode: "",
    formattedAddress: address,
  };
  try {
    const service = new window.google.maps.places.AutocompleteService();
    const result = await service.getPlacePredictions({
      input: address,
    });
    const parameter = {
      // Use the "place_id" of suggestion from the dropdown (object), here just taking first suggestion for brevity
      placeId: result.predictions[0].place_id,
      fields: ["address_components", "formatted_address"],
    };

    const details = await getDetails(parameter);
    newAddress = addressAutocompleteResultMapper(details);
  } catch (e) {
    console.error(e);
  }
  return newAddress;
}
