import React, { Dispatch, SetStateAction } from "react";

import { t } from "i18next";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";

import { Appointment as AppointmentModel } from "@models/Appointment";

interface Appointment {
  status: AppointmentModel["status"];
}

interface TablePaginationProps {
  paginationState: {
    paginatedAppointments: Appointment[];
    numberOfItemsPerPage: number;
    numberOfAppointmetntsPerSeller: number;
    currentPage: number;
    canGoToPreviousPage: boolean;
    canGoToNextPage: boolean;
    allPageNumbers: (number | string)[];
    setCurrentPage: Dispatch<SetStateAction<number>>;
  };
}

export function TablePagination({
  paginationState: {
    paginatedAppointments,
    numberOfItemsPerPage,
    numberOfAppointmetntsPerSeller,
    currentPage,
    canGoToPreviousPage,
    canGoToNextPage,
    allPageNumbers,
    setCurrentPage,
  },
}: TablePaginationProps) {
  return (
    <>
      {/*  display the table pagination only when there are results */}
      {paginatedAppointments.length > 0 ? (
        <div className="flex flex-col flex-wrap items-center gap-2 px-4 py-2 md:gap-0 md:justify-between md:flex-row bg-primaryLightestGrey rounded-lg">
          <div>
            {t("Dashboard.todays-appointments.displaying-results", {
              display:
                numberOfItemsPerPage > paginatedAppointments.length
                  ? paginatedAppointments.length
                  : numberOfItemsPerPage,
              total: numberOfAppointmetntsPerSeller,
            })}
          </div>
          <div className="flex items-center self-end justify-center gap-2">
            <button
              type="button"
              aria-label={t("Common.previous-page")}
              className="enabled:hover:rounded-full enabled:hover:bg-primaryElectricBlue"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              <span className="hidden">{t("Common.previous-page")}</span>
              <HiChevronLeft
                className={`w-8 h-8 ${
                  canGoToPreviousPage
                    ? "text-primaryElectricBlue hover:text-white"
                    : "text-primaryLightGrey"
                }`}
              />
            </button>

            {/* Pagination numbers */}
            <div className="flex items-center gap-2">
              {allPageNumbers.map((page, index) => {
                if (page === "...") {
                  return (
                    <span key={index} className="text-primaryDarkGrey">
                      ...
                    </span>
                  );
                }

                return (
                  <button
                    key={index}
                    type="button"
                    className={`px-3 py-1 rounded-full ${
                      page === currentPage
                        ? "bg-primaryElectricBlue text-white"
                        : "bg-primaryLightestGrey text-black hover:bg-primaryElectricBlue hover:text-white"
                    }`}
                    onClick={() => setCurrentPage(page as number)}
                  >
                    {page}
                  </button>
                );
              })}
            </div>

            <button
              type="button"
              name="next page"
              aria-label={t("Common.next-page")}
              className="enabled:hover:rounded-full enabled:hover:bg-primaryElectricBlue enabled:hover:text-white"
              onClick={() =>
                setCurrentPage((prev) =>
                  paginatedAppointments.length < numberOfItemsPerPage
                    ? prev
                    : prev + 1,
                )
              }
              disabled={!canGoToNextPage}
            >
              <span className="hidden">{t("Common.next-page")}</span>
              <HiChevronRight
                className={`w-8 h-8 ${
                  canGoToNextPage
                    ? "text-primaryElectricBlue hover:text-white"
                    : "text-primaryLightGrey"
                }`}
              />
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}
