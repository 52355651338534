import { GetAllBrandsEndpoint } from "@services/api/brands/get-all-brands";

function getBrandName(id: string, brands: { id: string; name: string }[]) {
  const brand = brands.find((b) => b.id === id);
  return brand ? `${brand.name} - ` : "";
}

function getPortfolioLabel(
  portfolio: { brandId: string | null; name: string },
  brands: { id: string; name: string }[],
) {
  const brandName = portfolio.brandId
    ? getBrandName(portfolio.brandId, brands)
    : "";
  return `${brandName}${portfolio.name}`;
}

export function useBrands() {
  const { data: brands = [] } = GetAllBrandsEndpoint.useHook();

  return {
    brands,
    getBrandName: (id: string) => getBrandName(id, brands),
    getPortfolioLabel: (portfolio: { brandId: string | null; name: string }) =>
      getPortfolioLabel(portfolio, brands),
  };
}
