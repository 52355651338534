import React, { useState } from "react";

import { FloatingArrow } from "@floating-ui/react";
import { Popover, Portal, Transition } from "@headlessui/react";

import WithConditionalRendering from "@components/high-order-components/conditional-render";
import { fadeInOutProps } from "@components/transitions/FadeInOut";
import usePopup, { Placement } from "@helpers/Popup";
import { addAccessControlProps } from "@shared/components/access-control";

interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  placement?: Placement;
  className?: string;
}

function Tooltip({
  content,
  children,
  placement = "top-start",
  disabled = false,
  className,
}: TooltipProps) {
  const popup = usePopup({ placement, hasArrow: true });
  const [open, setOpen] = useState(false);

  return (
    <Popover
      className={`relative ${className}`}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Popover.Button as="div" ref={popup.setReferenceElement}>
        {children}
      </Popover.Button>
      <Portal
        as="div"
        className="group fixed z-tooltip"
        ref={popup.setPopupElement}
        style={popup.styles.popper}
        {...popup.attributes.popper}
      >
        <Transition show={open && !disabled} {...fadeInOutProps}>
          <Popover.Panel
            static
            className="min-w-[10rem] flex-col flex gap-1 px-4 py-2 bg-primaryBlack bg-primaryWhite rounded-lg text-start text-white text-sm"
          >
            <FloatingArrow
              width={10}
              height={5}
              ref={popup.arrowElement}
              context={popup.context}
            />
            {content}
          </Popover.Panel>
        </Transition>
      </Portal>
    </Popover>
  );
}

export default addAccessControlProps(WithConditionalRendering(Tooltip));
