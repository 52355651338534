import { GetDailyCalendarEndpoint } from "@services/api/sales-campaigns/get-daily-calendar";

export namespace Calendar {
  export type DailyCalendar = GetDailyCalendarEndpoint.Output;
  export type SellerAgenda = GetDailyCalendarEndpoint.OutputItem;
  export type Appointment = GetDailyCalendarEndpoint.Appointment;
  export type AccountAppointment = GetDailyCalendarEndpoint.AccountAppointment;
  export type BusyAppointment = GetDailyCalendarEndpoint.BusyAppointment;

  export const { checkIsBusyAppointment, checkIsAccountAppointment } =
    GetDailyCalendarEndpoint;
}
