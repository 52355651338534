import React from "react";

import * as ScrollArea from "@radix-ui/react-scroll-area";

type Props = {
  root: ScrollArea.ScrollAreaProps;
  children: React.ReactNode;
};

export default function ScrollView({ children, root }: Props) {
  return (
    <ScrollArea.Root {...root}>
      <ScrollArea.Viewport className="w-full h-full">
        {children}
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar
        className="h-2 transparent z-scrollbar"
        orientation="horizontal"
      >
        <ScrollArea.Thumb
          className="bg-primaryElectricBlue relative rounded-full"
          style={{ height: "0.5rem" }}
        />
      </ScrollArea.Scrollbar>
      <ScrollArea.Scrollbar
        className="w-2 transparent z-scrollbar"
        orientation="vertical"
      >
        <ScrollArea.Thumb className="bg-primaryElectricBlue relative rounded-full" />
      </ScrollArea.Scrollbar>
      <ScrollArea.Corner />
    </ScrollArea.Root>
  );
}
