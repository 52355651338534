export interface MultiOptionItemTree {
  label: string;
  value: string;
  type: string;
  parent?: MultiOptionItemTree;
  options?: MultiOptionItemTree[];
}

export const optionEquality = (
  o1: MultiOptionItemTree,
  o2: MultiOptionItemTree,
) => o1.type === o2.type && o1.value === o2.value;

export const hasOption = (
  options: MultiOptionItemTree[],
  option: MultiOptionItemTree,
): boolean => options.some((o) => optionEquality(o, option));
