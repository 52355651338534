import React from "react";

import addDays from "date-fns/addDays";
import format from "date-fns/format";
import subDays from "date-fns/subDays";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import {
  HiChevronLeft,
  HiChevronRight,
  HiOutlineCalendar,
  HiOutlineChevronLeft,
  HiOutlineChevronRight,
  HiOutlinePrinter,
} from "react-icons/hi2";

import SpecialRequestsList from "@calendar/components/special-requests-list";
import Button from "@components/data-entry/Button";
import { Showroom } from "@models/Showroom";

interface DatePickerProps {
  selectedDate: Date;
  onSelectDate(date: Date): void;
  showrooms: Pick<Showroom, "specialRequestsAllowed" | "id" | "timezone">[];
  handlePrint: () => void;
}

function CalendarDatePicker(props: DatePickerProps) {
  const { t } = useTranslation();
  const { selectedDate, onSelectDate, showrooms, handlePrint } = props;
  const currentDate = new Date(selectedDate);

  const handlePreviousDay = () => {
    onSelectDate(subDays(selectedDate, 1));
  };

  const handleNextDay = () => {
    onSelectDate(addDays(selectedDate, 1));
  };

  const handleToday = () => {
    onSelectDate(new Date());
  };

  return (
    <div className="h-16 flex w-full items-center justify-start py-4 gap-4">
      <Button theme="PRIMARY" onClick={handleToday}>
        {t("Common.today")}
      </Button>
      <DatePicker
        calendarClassName="z-select"
        selected={currentDate}
        startDate={currentDate}
        onChange={onSelectDate}
        wrapperClassName="flex items-center"
        withPortal
        customInput={
          <h3
            className="text-2xl font-semibold flex items-center gap-2 whitespace-nowrap cursor-pointer"
            data-testid="datepicker-selected-date"
          >
            {format(currentDate, "MMMM do, yyyy")}
            <HiOutlineCalendar className="h-10 w-10 rounded-full text-primaryElectricBlue hover:bg-primaryLightElectricBlue p-2" />
          </h3>
        }
        renderCustomHeader={({
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex items-center justify-between px-2 py-2">
            <div className="md:space-x-2">
              <button
                aria-label="Previous Month"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                type="button"
                className={`
                        ${
                          prevMonthButtonDisabled &&
                          "cursor-not-allowed opacity-50"
                        }
                        inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                    `}
              >
                <HiChevronLeft className="w-5 h-5 text-gray-600" />
              </button>

              <button
                aria-label="Next Month"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                type="button"
                className={`
                        ${
                          nextMonthButtonDisabled &&
                          "cursor-not-allowed opacity-50"
                        }
                        inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                    `}
              >
                <HiChevronRight className="w-5 h-5 text-gray-600" />
              </button>
            </div>
          </div>
        )}
      />
      <button
        aria-label="Previous Day"
        data-testid="calendar-datepicker-previous-button"
        onClick={handlePreviousDay}
        type="button"
        className="flex items-center justify-center p-1 bg-white w-10 h-10 rounded-full hover:bg-primaryLightestGrey"
      >
        <HiOutlineChevronLeft className="text-gray-600 w-7 h-7" />
      </button>
      <button
        aria-label="Next Day"
        data-testid="calendar-datepicker-next-button"
        onClick={handleNextDay}
        type="button"
        className="flex items-center justify-center p-1 bg-white w-10 h-10 rounded-full hover:bg-primaryLightestGrey"
      >
        <HiOutlineChevronRight className="text-gray-600 w-7 h-7" />
      </button>
      {showrooms.some((s) => s.specialRequestsAllowed) && (
        <SpecialRequestsList
          setSelectedDate={onSelectDate}
          selectedDate={selectedDate}
          showrooms={showrooms}
        />
      )}
      <Button
        theme="ICON"
        onClick={handlePrint}
        className="p-2 text-primaryElectricBlue hover:bg-primaryLightElectricBlue"
      >
        <HiOutlinePrinter className="w-6 h-6" />
      </Button>
    </div>
  );
}

export default CalendarDatePicker;
