import React from "react";

import { useTranslation } from "react-i18next";

import BuyerInvitationRowsDisplay from "@app/modules/invitation/BuyerInvitationRowsDisplay";
import Loading from "@components/feedback/Loading";
import { GetBuyerInvitations } from "@services/api/invitations/GetBuyerInvitations";
import { withBuyerOnly } from "@shared/components/guards/authentication";

function BuyerInvitationsPage() {
  const { data: buyerInvitations, status: buyerInvitationsStatus } =
    GetBuyerInvitations.useHook();

  const { t } = useTranslation();
  return (
    <div>
      {buyerInvitationsStatus === "pending" ? (
        <div className="flex items-center justify-center h-screen">
          <Loading type="screen" />
        </div>
      ) : (
        <div>
          {buyerInvitations && buyerInvitations.length > 0 ? (
            <>
              <p className="text-4xl font-medium p-8">
                {t("BuyerAccount.buyer-invitations.title.my-invites")}
              </p>
              <div className="w-full">
                <div className="pl-8 mt-7 mb-2 grid grid-cols-8">
                  <p className="pl-2 font-light text-left px-2 text-sm col-span-1">
                    {t("BuyerAccount.buyer-invitations.table-header.brand")}
                  </p>
                  <p className="pl-2 font-light text-left px-2 text-sm col-span-1">
                    {t(
                      "BuyerAccount.buyer-invitations.table-header.start-date",
                    )}
                  </p>
                  <p className="pl-2 font-light text-left px-2 text-sm col-span-1">
                    {t("BuyerAccount.buyer-invitations.table-header.end-date")}
                  </p>
                  <p className="pl-2 font-light text-left px-2 text-sm col-span-1">
                    {t("BuyerAccount.buyer-invitations.table-header.location")}
                  </p>
                  <p className="pl-2 font-light text-left px-2 text-sm col-span-1">
                    {t("BuyerAccount.buyer-invitations.table-header.season")}
                  </p>
                  <p className="pl-2 font-light text-left px-2 text-sm col-span-1">
                    {t("BuyerAccount.buyer-invitations.table-header.status")}
                  </p>
                  <p className="pl-2 font-light text-left px-2 text-sm col-span-1">
                    {t(
                      "BuyerAccount.buyer-invitations.table-header.appointments",
                    )}
                  </p>
                  <p className="pl-2 font-light text-left px-2 text-sm col-span-1">
                    {t("BuyerAccount.buyer-invitations.table-header.actions")}
                  </p>
                </div>
                <div className="px-8 pt-1">
                  {buyerInvitations.map((buyerInvitation, i) => (
                    <BuyerInvitationRowsDisplay
                      key={i}
                      buyerInvitation={buyerInvitation}
                    />
                  ))}
                </div>
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center h-screen">
              <div className="text-center">
                <p className="text-3xl mb-4 font-bold text-primaryElectricBlue">
                  No Data {/* // TODO: Add translation */}
                </p>
                <p className="text-lg">
                  {t("BuyerAccount.buyer-invitations.empty")}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default withBuyerOnly(BuyerInvitationsPage);
