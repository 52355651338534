import * as React from "react";

import AutoCompleteSelect, {
  AutocompleteState,
  OptionComponent,
  OptionComponentProps,
} from "@components/data-entry/Autocomplete";
import { contains } from "@helpers/String";
import timezones from "@models/types/timezones";

interface TZ {
  label: string;
  tzCode: string;
  name: string;
  utc: string;
}

// component displayed when no timezone is found
function EmptyOption() {
  return (
    <div className="flex items-center px-4 py-2 cursor-pointer">
      No Timezone found
    </div>
  );
}
// component displayed to suggest a timezone
function TimezoneOption({
  isCursorOn,
  isSelected,
  onClick,
  option,
}: OptionComponentProps<TZ>) {
  return (
    <OptionComponent
      isCursorOn={isCursorOn}
      isSelected={isSelected}
      onClick={onClick}
    >
      <span className="pl-2">{option.label}</span>
    </OptionComponent>
  );
}
// function that will render the selected timezone in the filter input
function renderTimezoneValue(tz: TZ) {
  return tz.label;
}
// async function that will filter the timezones using the filter input value
async function filterTimezone(filter: AutocompleteState<TZ>["filter"]) {
  return timezones.filter((tz) => contains(tz.label, filter));
}

// function that will extract the value passed to the "onChange" event
// does the opposite of hydrateTimezone
function extractValue(tz: TZ | undefined): string | undefined {
  return tz?.tzCode;
}
// since value is a string, we need to find the corresponding timezone when we get a value
// does the opposite from extractValue
function hydrateTimezone(value: string | undefined): TZ | undefined {
  if (value === undefined) return undefined;
  const found = timezones.find(
    (t) => t.tzCode.toLowerCase() === value.toLowerCase(),
  );
  return found || undefined;
}

interface TimezonesAutocompleteProps {
  onChange: (v: string | null) => void;
  value: string;
  id?: string;
  readonly?: boolean;
  className?: string;
}

export default function TimezoneAutocomplete({
  onChange,
  value,
  id,
  readonly = false,
  className,
}: TimezonesAutocompleteProps) {
  return (
    <AutoCompleteSelect<TZ>
      containerClassName={className}
      readOnly={readonly}
      id={id}
      value={hydrateTimezone(value)}
      onChange={(tz) => onChange(extractValue(tz) || null)}
      emptyComponent={EmptyOption}
      optionComponent={TimezoneOption}
      filterResults={filterTimezone}
      renderValue={renderTimezoneValue}
      title="search timezone"
      placeholder="Search timezone"
    />
  );
}
