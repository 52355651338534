import React from "react";

import { useTranslation } from "react-i18next";
import { HiOutlineSearch } from "react-icons/hi";
import { useDebouncedCallback } from "use-debounce";

import TextField from "@components/data-entry/TextField";

interface RepresentativesFiltersProps {
  filter?: string;
  onChange: (value: string) => void;
}

function RepresentativesFilters(props: RepresentativesFiltersProps) {
  const { filter, onChange } = props;
  const { t } = useTranslation();

  const [textValue, setTextValue] = React.useState<string | undefined>(filter);

  const debounced = useDebouncedCallback((value: string | undefined) => {
    if (value !== undefined) {
      onChange(value);
    }
  }, 300);
  return (
    <div className="max-w-[400px]">
      <TextField
        id="text-filter"
        icon={<HiOutlineSearch className="w-5 h-5 text-gray-600" />}
        placeholder={t("Representatives.filters.global.placeholder")}
        value={textValue}
        onChange={(value: string) => {
          setTextValue(value);
          debounced(value);
        }}
      />
    </div>
  );
}

export default RepresentativesFilters;
