import React from "react";

import { useTranslation } from "react-i18next";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

import BookingCardTitle from "@booking/components/appointment/card-title";
import Button from "@components/data-entry/Button";
import BottomBar from "@components/layout/BottomBar";

interface BookingCardPanelProps {
  title: string;
  step: number;
  children: React.ReactNode;
  nextLabel?: string;
  nextIcon?: React.ReactNode;
  isNextDisabled?: boolean;
  onNext: () => void;
  onPrevious?: () => void;
  onSkip?: () => void;
}
function BookingCardPanel(props: BookingCardPanelProps) {
  const {
    title,
    step,
    onSkip,
    onPrevious,
    onNext,
    nextLabel,
    nextIcon,
    children,
    isNextDisabled = false,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full h-full">
      <BookingCardTitle title={title} step={step} />
      <div className="flex flex-col justify-between grow h-full">
        <div className="flex justify-center flex-col lg:flex-col py-6 px-4 md:px-10 gap-6 grow h-full">
          <div className="flex flex-col lg:flex-row gap-6">{children}</div>
        </div>
        <BottomBar className="rounded-b-lg mt-6">
          {onPrevious && (
            <Button onClick={() => onPrevious()} theme="TERTIARY">
              <HiChevronLeft className="w-5 h-5" />
              {t("Common.previous")}
            </Button>
          )}
          {onSkip && (
            <Button onClick={() => onSkip()} theme="TERTIARY">
              {t("Booking.skip-step")}
            </Button>
          )}
          <Button
            onClick={() => onNext()}
            theme="PRIMARY"
            type="submit"
            disabled={isNextDisabled}
          >
            {nextLabel || t("Common.next")}
            {nextIcon || <HiChevronRight className="w-5 h-5" />}
          </Button>
        </BottomBar>
      </div>
    </div>
  );
}

export default BookingCardPanel;
