import { z } from "zod";

export const portfolioSchema = z.object({
  id: z.string(),
  name: z.string(),
  color: z.string(),
  organizationId: z.string(),
  collectionId: z.string().nullable(),
  isShared: z.boolean(),
  brandId: z.string().nullable(),
});

export type Portfolio = z.infer<typeof portfolioSchema>;
