import { useQuery } from "@tanstack/react-query";

import {
  SpecialRequestOldModelSerialized,
  unserializeSpecialRequestOldModel,
} from "@models/SpecialRequest";
import axiosInstance from "@services/api/config";

export const fetchSpecialRequestsEndpoint = {
  path: (invitationId: string) => `/bookings/${invitationId}/special-requests`,
};

export type FetchSpecialRequestsResponse = SpecialRequestOldModelSerialized[];

export const queryKeysFetchSpecialRequests = (invitationId: string) =>
  fetchSpecialRequestsEndpoint.path(invitationId).split("/");

const callApi = async (invitationId: string) =>
  axiosInstance
    .get<FetchSpecialRequestsResponse>(
      fetchSpecialRequestsEndpoint.path(invitationId),
    )
    .then((res) => res.data)
    .then((specialRequests) =>
      specialRequests.map(unserializeSpecialRequestOldModel),
    );

export const useFetchSpecialRequests = (invitationId: string) =>
  useQuery({
    queryKey: queryKeysFetchSpecialRequests(invitationId),
    queryFn: () => callApi(invitationId),
  });
