import React from "react";

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { routes } from "generouted/react-router";
import posthog from "posthog-js";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Authenticated from "@app/Authenticated";
import { initSentry, sentryCreateBrowserRouter } from "@app/sentry";
import { ApplicationContextProvider } from "@services/application/ApplicationContext";
import { AuthenticationProvider } from "@services/authentication/AuthenticationContext";

import queryClient from "./queryClient";

const isProduction = process.env.NODE_ENV === "production";

posthog.init("phc_IidCFgPEUXI98ujeZicGww2T4IoN5ph534T096Kaqx2", {
  api_host: "https://eu.i.posthog.com",
  person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
});

if (isProduction) {
  initSentry();
}

const router = isProduction
  ? sentryCreateBrowserRouter(routes)
  : createBrowserRouter(routes);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ApplicationContextProvider>
        <AuthenticationProvider>
          <Authenticated>
            <RouterProvider router={router} />
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </Authenticated>
        </AuthenticationProvider>
      </ApplicationContextProvider>
    </QueryClientProvider>
  );
}

export default App;
