import React from "react";

import { isAdmin } from "@models/old/User";
import NotFound from "@pages/404";
import LoginPage from "@pages/login";
import useAuthentication from "@services/authentication/useAuthentication";

interface AdminOnlyProps extends React.PropsWithChildren<{}> {}

export default function AdminOnly({ children }: AdminOnlyProps) {
  const { currentUser } = useAuthentication();
  if (!currentUser) {
    return <LoginPage context="representative" />;
  }

  if (!isAdmin(currentUser)) {
    return <NotFound />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
