import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { groupBy, sortBy } from "remeda";

import { OptionGroupItem } from "@components/data-entry/Select";
import {
  AvailabilityStatus,
  AvailabilityStatusList,
  VirtualMeetingApps,
} from "@models/types/enums";
import { fullName } from "@shared/helpers/formatters";

export function getSellersWithoutVirtualCoverage<
  S extends {
    virtualMeetingAppLinks?: Partial<Record<VirtualMeetingApps, string>> | null;
  },
>(sellers: S[]): S[] {
  return sellers.filter(
    (s) =>
      !s.virtualMeetingAppLinks ||
      Object.entries(s.virtualMeetingAppLinks).length === 0,
  );
}

export function useOrganizationRepresentativeOptions({
  sellers,
}: {
  sellers: {
    firstName: string;
    lastName: string;
    id: string;
  }[];
}) {
  return sellers.map((seller) => ({
    label: fullName(seller),
    value: seller.id,
  }));
}

export function useOrganizationRepresentativeOptionsByAvailability({
  sellers,
}: {
  sellers: {
    firstName: string;
    lastName: string;
    id: string;
    availabilityStatus: AvailabilityStatus;
  }[];
}) {
  const { t } = useTranslation();

  return useMemo(() => {
    const groupedByAvailability = groupBy(
      sellers,
      (seller) => seller.availabilityStatus,
    );
    const items: OptionGroupItem[] = Object.entries(groupedByAvailability).map(
      (entry) => ({
        key: entry[0],
        label: t(`Common.sellers-groups.${entry[0]}`),
        options: entry[1].map((seller) => ({
          label: fullName(seller),
          value: seller.id,
          disabled: seller.availabilityStatus === "BOOKED",
        })),
      }),
    );

    const groups = sortBy(items, (a) =>
      AvailabilityStatusList.findIndex((v) => v === a.key),
    );
    return groups;
  }, [sellers, t]);
}
