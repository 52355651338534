import React from "react";

import Button from "@components/data-entry/Button";

type Props = {
  imageClassNames: string;
  newImage: File | null;
  existingImage: string | null;
  onChange: (v: File | null) => void;
  labels: {
    input?: string;
    replace?: string;
    upload?: string;
    delete?: string;
    subtitle?: string;
  };
  name: string;
  alt: string;
};

export default function ImageUpload({
  existingImage,
  newImage = null,
  imageClassNames = "",
  onChange,
  labels,
  name,
  alt,
}: Props) {
  return (
    <>
      <div className="flex">
        {/** DISPLAY THE NEW IMAGE */}
        {newImage && (
          <img
            className={`${imageClassNames} border border-primaryGrey flex justify-center items-center rounded  bg-primaryLightestGrey`}
            src={URL.createObjectURL(newImage)}
            alt={alt}
          />
        )}
        {/** DISPLAY THE EXISTING IMAGE */}
        {!newImage && existingImage && (
          <img
            className={`${imageClassNames} border border-primaryGrey flex justify-center items-center rounded  bg-primaryLightestGrey`}
            src={existingImage}
            alt={alt}
          />
        )}
        {/** DISPLAY A PLACEHOLDER WITH A BUTTON TO UPLOAD A NEW IMAGE */}
        {!newImage && !existingImage && (
          <label
            className={`${imageClassNames} border group border-primaryGrey flex flex-col justify-center items-center rounded  bg-primaryLightestGrey cursor-pointer`}
            htmlFor="bannerFile"
          >
            <span className="bg-primaryElectricBlue group-hover:bg-primaryElectricBlue/80 rounded-lg border border-primaryElectricBlue  text-white duration-200 transition-all px-4 cursor-pointer py-2">
              {labels.upload}
            </span>
            {labels.subtitle && (
              <span className="text-primaryGrey select-none">
                {labels.subtitle}
              </span>
            )}
          </label>
        )}
      </div>
      <div className="flex">
        <input
          className="hidden"
          type="file"
          id={name}
          name={name}
          accept="image/*"
          onChange={(ev) => {
            onChange(ev.target.files ? ev.target.files[0] : null);
          }}
        />
        {/** BUTTONS TO REPLACE THE IMAGE OR DELETE IT */}
        {(newImage || existingImage) && (
          <>
            <label
              className="bg-primaryElectricBlue hover:bg-primaryElectricBlue/80 rounded-lg rounded-r-none border border-primaryElectricBlue  text-white duration-200 transition-all px-4 cursor-pointer py-2"
              htmlFor={name}
            >
              {labels.replace}
            </label>
            <Button
              className="rounded-lg rounded-l-none hover:bg-primaryRed/80"
              theme="DANGER"
              onClick={() => {
                onChange(null);
              }}
            >
              {labels.delete}
            </Button>
          </>
        )}
      </div>
    </>
  );
}
