import React, { useState } from "react";

import { parse } from "date-fns";
import { useTranslation } from "react-i18next";
import { isEmpty } from "remeda";

import { Cruising } from "@app/modules/cruising/types";
import Button from "@components/data-entry/Button";
import Checkbox from "@components/data-entry/Checkbox";
import FilesUploadInput, {
  FileToUpload,
} from "@components/data-entry/FilesUploadInput";
import Select from "@components/data-entry/Select";
import CalloutBox from "@components/feedback/CalloutBox";
import Modal, { useModal } from "@components/feedback/Modal";
import validateAddressWithGoogleMaps from "@helpers/Address";
import { formatDate, formatTime } from "@helpers/Date";
import ExcelService from "@services/xlsx";

function displayCellValue(
  value: string | Date,
  dateType: "time" | "date" = "date",
) {
  const formatter = dateType === "date" ? formatDate : formatTime;

  const cellValue = value || "empty cell";
  const display = cellValue instanceof Date ? formatter(cellValue) : cellValue;

  return `(first row value: ${display})`;
}

const columnNames = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

function buildOptions(firstRow: ExcelRow, dateType: "time" | "date") {
  return columnNames.map((columnName, index) => ({
    label: `${columnName} ${displayCellValue(firstRow[index], dateType)}`,
    value: `${index}`,
  }));
}

const dateFormatOptions = [
  {
    key: "grp1",
    label: "grp1",
    options: [
      {
        label: "dd/MM/yyyy",
        value: "dd/MM/yyyy",
      },
      {
        label: "MM/dd/yyyy",
        value: "MM/dd/yyyy",
      },
      {
        label: "yyyy-MM-dd",
        value: "yyyy-MM-dd",
      },
    ],
  },
];

type Props = {
  onFileUploadOk: (rows: Cruising.NewShowroom[]) => void;
};

type ExcelRow = string[];

type ParsePromise = ReturnType<typeof ExcelService.parseFile<ExcelRow>>;

export default function ShowroomUpload2({ onFileUploadOk }: Props) {
  const { t } = useTranslation();
  const [error, setError] = useState<Error>();
  const modalState = useModal();

  const [currentFile, setCurrentFile] = useState<Awaited<ParsePromise>>();
  const [currentSheet, setCurrentSheet] = useState<string>();
  const [ignoreFirstRow, setIgnoreFirstRow] = useState<boolean>(false);
  const [nameRow, setNameRow] = useState(0);
  const [addressRow, setAddressRow] = useState(1);
  const [fromRow, setFromRow] = useState(2);
  const [toRow, setToRow] = useState(3);
  const [openingHourRow, setOpeningHourRow] = useState(4);
  const [closingHourRow, setClosingHourRow] = useState(5);
  const [durationRow, setDurationRow] = useState(6);
  const [dateFormat, setDateFormat] = useState(
    dateFormatOptions[0].options[0].value,
  );

  const parseFile = (files: FileToUpload[]) => {
    Promise.all(files.map((f) => ExcelService.parseFile<ExcelRow>(f.file)))
      .then((parsedFiles) => {
        const parsedFile = parsedFiles[0];
        if (parsedFile) {
          setCurrentFile(parsedFile);
          setCurrentSheet(parsedFile.sheetsNames[0]);
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const currentRows =
    currentFile && currentSheet
      ? currentFile.rows(currentFile.sheet(currentSheet))
      : [];

  const firstRowIndex = ignoreFirstRow ? 1 : 0;
  const firstRow =
    currentRows.length > firstRowIndex ? currentRows[firstRowIndex] : [];

  const currentSheetValue =
    currentFile && currentSheet
      ? {
          label: `${currentSheet} (${currentRows.length} rows)`,
          value: currentSheet,
        }
      : undefined;

  const clear = () => {
    setError(undefined);
    setCurrentFile(undefined);
    setCurrentSheet(undefined);
    setIgnoreFirstRow(false);
    setNameRow(0);
    setAddressRow(1);
    setFromRow(2);
    setToRow(3);
    setOpeningHourRow(4);
    setClosingHourRow(5);
    setDurationRow(6);
  };

  const validateFile = async () => {
    try {
      const parsedRows = await Promise.all(
        currentRows
          .slice(ignoreFirstRow ? 1 : 0) // ignore first row if specified
          .map((row) => {
            const startTime = isEmpty(row[openingHourRow])
              ? "09:00"
              : row[openingHourRow];
            const endTime = isEmpty(row[closingHourRow])
              ? "19:00"
              : row[closingHourRow];
            return {
              name: row[nameRow],
              address: row[addressRow],
              from: parse(row[fromRow], dateFormat, new Date()),
              to: parse(row[toRow], dateFormat, new Date()),
              startTime: parse(startTime, "HH:mm", new Date()),
              endTime: parse(endTime, "HH:mm", new Date()),
              duration: parseInt(row[durationRow], 10),
            };
          })
          .map((r) => {
            if (Number.isNaN(r.from.getTime())) {
              throw new Error(
                `${t(
                  "Cruising.ShowroomUpload.errors.could-not-parse-date",
                )} for column "from" for row ${r.name}`,
              );
            }
            if (Number.isNaN(r.to.getTime())) {
              throw new Error(
                `${t(
                  "Cruising.ShowroomUpload.errors.could-not-parse-date",
                )} for column "to" for row ${r.name}`,
              );
            }
            if (Number.isNaN(r.startTime.getTime())) {
              throw new Error(
                `${t(
                  "Cruising.ShowroomUpload.errors.could-not-parse-date",
                )} for column "opening hour" for row ${r.name}`,
              );
            }
            if (Number.isNaN(r.duration)) {
              throw new Error(
                `${t(
                  "Cruising.ShowroomUpload.errors.could-not-parse-number",
                )} for column "duration" for row ${r.name}`,
              );
            }
            return r;
          })
          // autocomplete address with same logic as in "AddressAutocomplete"
          .map(async (r) => ({
            ...r,
            address: await validateAddressWithGoogleMaps(r.address),
          })),
      );

      // @todo check for invalid dates
      onFileUploadOk(parsedRows);
      modalState.close();
      clear();
    } catch (e: any) {
      setError(e);
    }
  };

  const columnOptions = (
    exampleData: ExcelRow,
    dateType: "time" | "date" = "date",
  ) => [
    {
      key: "grp1",
      label: "grp1",
      options: buildOptions(exampleData, dateType),
    },
  ];

  return (
    <>
      <Button
        className="whitespace-nowrap"
        theme="PRIMARY"
        onClick={() => modalState.open()}
      >
        {t("Cruising.ShowroomUpload.upload-file")}
      </Button>
      <Modal
        title={t("Cruising.ShowroomUpload.upload-excel-file")}
        state={modalState}
      >
        {error && <CalloutBox type="ERROR">{error.message}</CalloutBox>}
        {currentFile ? (
          <table>
            <tbody>
              <tr className="pb-4">
                <td colSpan={2}>
                  <Button theme="SECONDARY" className="w-full" onClick={clear}>
                    {t("Cruising.ShowroomUpload.clear-file")}
                  </Button>
                </td>
              </tr>
              <tr>
                <td>{t("Cruising.ShowroomUpload.sheet")}</td>
                <td>
                  <Select
                    options={[
                      {
                        key: "grp1",
                        label: "grp1",
                        options: currentFile.sheetsNames.map((sn) => ({
                          label: `${sn} (${
                            currentFile.rows(currentFile.sheet(sn)).length
                          } rows)`,
                          value: sn,
                        })),
                      },
                    ]}
                    name="sheet-options"
                    onChange={(v) => {
                      setCurrentSheet(v);
                    }}
                    defaultValue={currentSheetValue}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label
                    htmlFor="ignore-first-row"
                    className="flex items-center gap-2"
                  >
                    <Checkbox
                      name="ignore-first-row"
                      checked={ignoreFirstRow}
                      id="ignore-first-row"
                      onChange={() => setIgnoreFirstRow(!ignoreFirstRow)}
                    />
                    {t("Cruising.ShowroomUpload.ignore-first-row")}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="text-lg">
                    {t("Cruising.ShowroomUpload.select-corresponding-columns")}
                  </p>
                  <p className="text-primaryLightGrey">
                    {t(
                      "Cruising.ShowroomUpload.first-row-values-in-parenthesis",
                    )}
                  </p>
                </td>
              </tr>
              <tr>
                <td>{t("Cruising.ShowroomUpload.name")}</td>
                <td>
                  <Select
                    name="name-row"
                    onChange={(v) => setNameRow(v)}
                    defaultValue={{
                      label: `${columnNames[nameRow]} ${displayCellValue(
                        firstRow[nameRow],
                      )}`,
                      value: `${nameRow}`,
                    }}
                    options={columnOptions(firstRow)}
                  />
                </td>
              </tr>
              <tr>
                <td>{t("Cruising.ShowroomUpload.address")}</td>
                <td>
                  <Select
                    name="address-row"
                    onChange={(v) => setAddressRow(v)}
                    defaultValue={{
                      label: `${columnNames[addressRow]} ${displayCellValue(
                        firstRow[addressRow],
                      )}`,
                      value: addressRow,
                    }}
                    options={columnOptions(firstRow)}
                  />
                </td>
              </tr>
              <tr>
                <td>{t("Cruising.ShowroomUpload.from")}</td>
                <td>
                  <Select
                    name="from-row"
                    onChange={(v) => setFromRow(v)}
                    defaultValue={{
                      label: `${columnNames[fromRow]} ${displayCellValue(
                        firstRow[fromRow],
                      )}`,
                      value: fromRow,
                    }}
                    options={columnOptions(firstRow)}
                  />
                </td>
              </tr>
              <tr>
                <td>{t("Cruising.ShowroomUpload.to")}</td>
                <td>
                  <Select
                    name="to-row"
                    onChange={(v) => setToRow(v)}
                    defaultValue={{
                      label: `${columnNames[toRow]} ${displayCellValue(
                        firstRow[toRow],
                      )}`,
                      value: toRow,
                    }}
                    options={columnOptions(firstRow)}
                  />
                </td>
              </tr>
              <tr>
                <td>{t("Cruising.ShowroomUpload.opening-hour")}</td>
                <td>
                  <Select
                    name="opening-hour-row"
                    onChange={(v) => setOpeningHourRow(v)}
                    defaultValue={{
                      label: `${columnNames[openingHourRow]} ${displayCellValue(
                        firstRow[openingHourRow],
                        "time",
                      )}`,
                      value: openingHourRow,
                    }}
                    options={columnOptions(firstRow, "time")}
                  />
                </td>
              </tr>
              <tr>
                <td>{t("Cruising.ShowroomUpload.closing-hour")}</td>
                <td>
                  <Select
                    name="closing-hour-row"
                    onChange={(v) => setClosingHourRow(v)}
                    defaultValue={{
                      label: `${columnNames[closingHourRow]} ${displayCellValue(
                        firstRow[closingHourRow],
                        "time",
                      )}`,
                      value: closingHourRow,
                    }}
                    options={columnOptions(firstRow, "time")}
                  />
                </td>
              </tr>
              <tr>
                <td>{t("Cruising.ShowroomUpload.duration")}</td>
                <td>
                  <Select
                    name="duration-row"
                    onChange={(v) => setNameRow(v)}
                    defaultValue={{
                      label: `${columnNames[durationRow]} ${displayCellValue(
                        firstRow[durationRow],
                      )}`,
                      value: `${durationRow}`,
                    }}
                    options={columnOptions(firstRow)}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>{t("Cruising.ShowroomUpload.options")}</td>
              </tr>
              <tr>
                <td>{t("Cruising.ShowroomUpload.date-format")}</td>
                <td>
                  <Select
                    options={dateFormatOptions}
                    name="date-format"
                    defaultValue={dateFormatOptions[0].options.find(
                      (o) => o.value === dateFormat,
                    )}
                    onChange={(v) => setDateFormat(v)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <FilesUploadInput
            onFileChange={parseFile}
            parentFiles={[]}
            authorizedFileExtension={[".xls", ".xlsx", ".odt", ".csv"]}
          />
        )}
        <Button theme="PRIMARY" onClick={validateFile}>
          OK
        </Button>
      </Modal>
    </>
  );
}
