import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { competitorsGroupSchema } from "@models/CompetitorGroup";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export interface GetCompetitorsGroupsParams {
  organizationId: string;
}

export const getCompetitorsGroupsSchema = z.array(competitorsGroupSchema);

export type GetCompetitorsGroupsResponse = z.infer<
  typeof getCompetitorsGroupsSchema
>;

export const getCompetitorsGroupsEndpoint = {
  path: ({ organizationId }: GetCompetitorsGroupsParams) =>
    `/organizations/${organizationId}/competitors-groups`,
  call: ({ organizationId }: GetCompetitorsGroupsParams) =>
    axiosInstance
      .get<GetCompetitorsGroupsResponse>(
        getCompetitorsGroupsEndpoint.path({ organizationId }),
      )
      .then((response) => response.data),
};

export function useGetCompetitorsGroups(params: GetCompetitorsGroupsParams) {
  return useQuery({
    queryKey: getAPIQueryKey(getCompetitorsGroupsEndpoint.path(params)),
    queryFn: () => getCompetitorsGroupsEndpoint.call(params),
  });
}
