import React, { CSSProperties, useState } from "react";

import { Active, useDroppable } from "@dnd-kit/core";
import { cva } from "class-variance-authority";
import { useTranslation } from "react-i18next";
import { HiOutlinePlusCircle } from "react-icons/hi2";
import { MdOutlineEventBusy } from "react-icons/md";
import { twMerge } from "tailwind-merge";

import CalendarHelper from "@calendar/helpers/calendar";
import { CalendarCell } from "@calendar/helpers/calendar-column";
import DropdownMenu from "@components/feedback/DropdownMenu";
import { useAuthenticatedUser } from "@services/authentication/useAuthentication";

export type AvailableSlotType = Omit<CalendarCell["cellType"], "appointment">;

interface AvailableSlotProps {
  type: AvailableSlotType;
  sellerId: string;
  startTime: Date;
  handleBookAppointment: (startTime: Date, sellerId: string) => void;
  handleBlockSlot: (startTime: Date, sellerId: string) => void;
}

/**
 * values of "type" prop that should trigger a display with a stripe pattern
 */
const greyedOutTypes: AvailableSlotType[] = [
  "lunch",
  "before_opening",
  "after_closing",
  "closed_day",
];

const computeStyle = (active: Active | null): CSSProperties => {
  if (active) {
    return {
      width: active.rect.current.initial?.width,
      height: active.rect.current.initial?.height,
    };
  }
  return {};
};

function AvailableSlot(props: AvailableSlotProps) {
  const currentUser = useAuthenticatedUser();
  const { type, sellerId, startTime, handleBookAppointment, handleBlockSlot } =
    props;
  const droppableId = CalendarHelper.toDroppableId(sellerId, startTime);
  const { isOver, setNodeRef, active } = useDroppable({
    id: droppableId,
  });

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const isRoundStartTime = startTime.getMinutes() === 0;
  const isGreyedOut = greyedOutTypes.includes(type);

  const cellClassName = cva(
    "border-l border-r border-t hover:border-primaryElectricBlue hover:border-dashed hover:border-2",
    {
      variants: {
        isRoundStartTime: {
          true: "border-t-solid hover:border-t-dashed",
          false: "border-t-dashed",
        },
        isOpen: {
          true: "border-primaryElectricBlue border-dashed border-2",
          false: "",
        },
        isGreyedOut: {
          true: "bg-greyed",
          false: "",
        },
      },
    },
  );

  return (
    <DropdownMenu.Root onOpenChange={(open) => setIsOpen(open)}>
      <DropdownMenu.Trigger
        className={twMerge(
          cellClassName({ isOpen, isRoundStartTime, isGreyedOut }),
        )}
        tabIndex={0}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleBookAppointment(new Date(startTime), sellerId);
          }
        }}
        data-testid={`available-slot-${droppableId}`}
        ref={setNodeRef}
        onClick={() => handleBookAppointment(new Date(startTime), sellerId)}
      >
        {isOver && (
          <div
            className="border-dashed border-primaryElectricBlue border-2 rounded-md"
            style={{
              ...computeStyle(active),
              backgroundColor: "rgba(220, 220, 255, 0.3)",
            }}
          />
        )}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content>
          {currentUser.role !== "FREELANCE" && (
            <DropdownMenu.Item
              onSelect={() =>
                handleBookAppointment(new Date(startTime), sellerId)
              }
            >
              <HiOutlinePlusCircle className="w-6 h-6" />
              {t("Calendar.AvailableSlot.context-menu.book-appointment")}
            </DropdownMenu.Item>
          )}
          <DropdownMenu.Item
            onSelect={() => handleBlockSlot(new Date(startTime), sellerId)}
          >
            <MdOutlineEventBusy className="w-6 h-6" />
            {t("Calendar.AvailableSlot.context-menu.mark-as-busy")}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

export default AvailableSlot;
