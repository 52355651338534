import { Language } from "@booking/components/appointment/language-select";
import { SellerInformations } from "@booking/helpers/booking";
import { arrayFilterUnique } from "@helpers/Array";
import { AppointmentFormatWithMeetingApp } from "@models/types/enums";

export default function groupLanguagesByFormatWithApp(
  sellerInformations: SellerInformations[],
  filterValues: {
    formats: AppointmentFormatWithMeetingApp[];
    languages: Language[];
  },
) {
  return sellerInformations.reduce(
    (acc, si) => {
      const sellerFilteredLanguages = si.languages.filter(
        (l) =>
          filterValues.languages.length === 0 ||
          filterValues.languages.includes(l),
      );
      si.formatsWithMeetingApps.forEach((formatWithApp) => {
        if (!acc[formatWithApp]) {
          acc[formatWithApp] = [];
        }
        if (
          filterValues.formats.length === 0 ||
          filterValues.formats.includes(formatWithApp)
        ) {
          // only take into account the filtered languages/formats
          acc[formatWithApp] = [
            ...acc[formatWithApp],
            ...sellerFilteredLanguages,
          ].filter(arrayFilterUnique);
        }
      });
      return acc;
    },
    {} as Record<AppointmentFormatWithMeetingApp, Language[]>,
  );
}
