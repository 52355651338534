import React, { useState } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Row, createColumnHelper } from "@tanstack/react-table";
import { HiCheck, HiChevronLeft, HiRefresh } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import AdminOnboardingBreadcrumb from "@backoffice/components/onboarding/components/AdminOnboardingBreadcrumb";
import UploadError from "@backoffice/components/onboarding/components/UploadError";
import MRTable from "@components/data-display/MRTable";
import Button from "@components/data-entry/Button";
import FilesUploadInput, {
  FileToUpload,
} from "@components/data-entry/FilesUploadInput";
import CalloutBox from "@components/feedback/CalloutBox";
import ConfirmModal from "@components/feedback/ConfirmModal";
import Loading from "@components/feedback/Loading";
import BottomBar from "@components/layout/BottomBar";
import { AdminOnboardingStep } from "@models/old/Organization";
import { Representative } from "@models/old/Representative";
import { UploadValidationError } from "@models/types/UploadValidationError";
import { VirtualMeetingApps } from "@models/types/enums";
import OrganizationAPI from "@services/api/old/organization/OrganizationAPI";

const getOrganizationQuery = (organizationId: string) => ({
  queryKey: ["get-organization", organizationId],
  queryFn: () => OrganizationAPI.getOrganization(organizationId),
});

const getOrganizationRepresentativesQuery = (organizationId: string) => ({
  queryKey: ["get-organization-representatives", organizationId],
  queryFn: () => OrganizationAPI.getRepresentatives(organizationId),
});

const displayMeetingAppLink = (
  row: Row<Representative>,
  type: VirtualMeetingApps,
) => {
  if (
    row.original.virtualMeetingAppLinks &&
    row.original.virtualMeetingAppLinks[type]
  )
    return (
      <a href={row.original.virtualMeetingAppLinks[type]}>
        {row.original.virtualMeetingAppLinks[type]}
      </a>
    );
  return "";
};

function AdminOnboardingRepresentativeUploadPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { organizationId: organizationIdParam } = useParams();
  if (!organizationIdParam) {
    throw new Error(
      "AccoBackOfficeOnboardingAccountUploadPage - no organizationId",
    );
  }
  const {
    data: organization,
    isPending: isLoadingOrganization,
    isError: isErrorOrganization,
    error: errorOrganization,
  } = useQuery(getOrganizationQuery(organizationIdParam));

  const organizationRepsQuery =
    getOrganizationRepresentativesQuery(organizationIdParam);
  const { data: representatives } = useQuery({
    ...organizationRepsQuery,
    initialData: [],
  });

  const onlyMainAdmin =
    representatives.filter((rep) => rep.role !== "ORGANIZATION_MAIN_ADMIN")
      .length === 0;

  const mutation = useMutation({
    mutationFn: (organizationId: string) =>
      OrganizationAPI.sendOnboardingEmail(organizationId),
    onSuccess: () => navigate(`/backoffice/list-organizations/`),
  });

  const [error, setError] = useState<UploadValidationError[] | string | null>(
    null,
  );
  const [isRedo, setIsRedo] = useState<boolean>(false);

  const columnHelper = createColumnHelper<Representative>();
  const columns = React.useMemo(
    () => [
      columnHelper.accessor("firstName", {
        header: () => "Firstname",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("lastName", {
        header: () => "Lastname",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("email", {
        header: () => "Email",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("phoneNumber", {
        header: () => "Phone",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("role", {
        header: () => "Role",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("position", {
        header: () => "Position",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("markets", {
        header: () => "Markets",
        cell: (info) => info.getValue().join(", "),
      }),
      columnHelper.display({
        id: "google-meet",
        header: "Google Meet",
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => displayMeetingAppLink(row, "GOOGLE_MEET"),
      }),
      columnHelper.display({
        id: "zoom",
        header: "Zoom",
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => displayMeetingAppLink(row, "ZOOM"),
      }),
      columnHelper.display({
        id: "microsoft teams",
        header: "Microsoft Teams",
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => displayMeetingAppLink(row, "MICROSOFT_TEAMS"),
      }),
    ],
    [columnHelper],
  );

  const [fileList, setFileList] = React.useState<FileToUpload[]>([]);

  if (isLoadingOrganization) {
    return <Loading type="screen" />;
  }

  if (isErrorOrganization) {
    throw errorOrganization;
  }

  const handleFileChange = (files: FileToUpload[] | null) => {
    if (files === null || files.length === 0) {
      setError(null);
      setFileList([]);
    } else {
      OrganizationAPI.uploadOrganizationRepresentatives(
        organization.id,
        files[0].file,
      )
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: organizationRepsQuery.queryKey,
          });
          setError(null);
          setFileList(files.map((file) => ({ ...file, status: "ok" })));
          toast.success("Organization representatives uploaded");
        })
        .catch((err) => {
          setFileList(files.map((file) => ({ ...file, status: "error" })));
          if (err.errors) {
            setError(err.errors);
          } else {
            setError(err.message);
          }
        });
    }
  };

  return (
    <div>
      <AdminOnboardingBreadcrumb
        currentStep={AdminOnboardingStep.REPRESENTATIVES_UPLOAD}
      />
      <h1 className="heading-1">{organization.name}</h1>
      {mutation.isError && (
        <CalloutBox className="w-3/4 my-4" type="ERROR">
          {(mutation.error as any).message}{" "}
          <a
            href={`../${organization.id}/information`}
            className="underline text-primaryElectricBlue"
          >
            Change it there
          </a>
        </CalloutBox>
      )}

      <CalloutBox className="w-2/4 my-4">
        Make sure that all the information in the file is written correctly and
        that there are no duplicates before importing it. Please import the
        three tabs of the file separately.
      </CalloutBox>
      <h3 className="font-bold">Organization Representatives</h3>
      {onlyMainAdmin && (
        <FilesUploadInput
          onFileChange={handleFileChange}
          parentFiles={fileList}
          authorizedFileExtension={[".csv"]}
          className="w-5/12 max-w-xl"
        />
      )}

      <div className="flex flex-col p-8 my-4 border rounded-lg bg-primaryLightElectricBlue item">
        {representatives.length === 0 && (
          <>
            <div>No representatives imported so far</div>
            {error && (
              <UploadError type="Organization representatives" error={error} />
            )}
          </>
        )}
        {representatives.length > 0 && (
          <>
            <div className="pb-2 text-primaryElectricBlue">
              {representatives.length} imported Representatives
            </div>
            <MRTable
              data={representatives}
              columns={columns}
              paginationPlacement="none"
            />
          </>
        )}
      </div>

      <BottomBar>
        <div>
          {!onlyMainAdmin && (
            <Button
              onClick={() => {
                setIsRedo(true);
              }}
              theme="DANGER"
            >
              <HiRefresh className="w-6 h-6" />
              <span>Redo representatives</span>
            </Button>
          )}
        </div>
        <div className="flex justify-end gap-4">
          <Button
            onClick={() => {
              navigate(
                `/backoffice/onboard-organization/${organization.id}/contacts-upload`,
              );
            }}
            theme="TERTIARY"
          >
            <HiChevronLeft className="w-6 h-6" />
            <span>Import contacts</span>
          </Button>
          <Button
            disabled={onlyMainAdmin}
            loading={mutation.isPending}
            onClick={() => {
              mutation.mutate(organization.id);
            }}
            theme="PRIMARY"
          >
            <span>Send onboarding email to organization admin</span>
            <HiCheck className="w-6 h-6" />
          </Button>
        </div>
      </BottomBar>

      <ConfirmModal
        show={isRedo}
        title="Delete all organization representatives ?"
        confirmLabel="Yes, redo"
        onCancel={() => {
          setIsRedo(false);
        }}
        onConfirm={() => {
          OrganizationAPI.redoUploadRepresentatives(organization.id).then(
            () => {
              queryClient.invalidateQueries({
                queryKey: organizationRepsQuery.queryKey,
              });
              setFileList([]);
              setIsRedo(false);
            },
          );
        }}
      >
        <span>
          You are about to delete all imported organization representatives.
          <br />
          Are you sure of your choice?
        </span>
      </ConfirmModal>
    </div>
  );
}

export default AdminOnboardingRepresentativeUploadPage;
