import { z } from "zod";

export const meetingReportSchema = z.object({
  appointmentId: z.string(),
  otb: z.number().nullable(),
  actualBudget: z.number().nullable(),
  notes: z.string().nullable(),
});

export type MeetingReport = z.infer<typeof meetingReportSchema>;
