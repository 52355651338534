import React from "react";

import { useTranslation } from "react-i18next";
import {
  HiOutlineCalendar,
  HiOutlineLocationMarker,
  HiOutlineVideoCamera,
} from "react-icons/hi";

import { Organization } from "@models/Organization";
import { ShowroomSeller } from "@models/OrganizationRepresentative";
import { Showroom } from "@models/Showroom";
import getShowroomOpeningPeriod from "@sales-campaign/helpers/opening-days";

interface InvitationInformationsProps {
  organization: Organization;
  showrooms: (Pick<Showroom, "id" | "openingDays" | "city"> & {
    sellers: Pick<ShowroomSeller, "virtualMeetingAppLinks">[];
  })[];
}

function InvitationInformations(props: InvitationInformationsProps) {
  const { organization, showrooms } = props;
  const { t } = useTranslation();

  return (
    <div className="p-4 hidden lg:block max-w-[33%] min-w-[25%] rounded-lg bg-primaryLightElectricBlue">
      <div className="flex items-center gap-2 pb-4">
        <h3 className="heading-3-mobile lg:heading-3">{organization.name}</h3>-
      </div>
      {showrooms.map((showroom) => {
        const virtualMeetingApps = [
          ...new Set(
            showroom.sellers.flatMap((seller) =>
              Object.keys(seller.virtualMeetingAppLinks || {}),
            ),
          ),
        ];
        return (
          <div key={`showroom-${showroom.id}`}>
            <div className="flex items-center gap-2 pb-2">
              <HiOutlineCalendar />
              {getShowroomOpeningPeriod(
                showroom.openingDays,
                "EEEE, MMM d, yyyy",
              )}
            </div>
            <div className="flex items-center gap-2">
              <HiOutlineLocationMarker />
              <div>{showroom.city}</div>
              {" | "}
              <HiOutlineVideoCamera />
              <div>
                {virtualMeetingApps
                  .map((virtualMeetingApp) =>
                    t(`Common.virtual-tool.${virtualMeetingApp}`),
                  )
                  .join(", ")}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default InvitationInformations;
