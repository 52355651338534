import { useRef } from "react";

import {
  Placement as FloatingPlacement,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";

export type Placement = FloatingPlacement;

interface UsePopupProps {
  placement: FloatingPlacement;
  hasOffset?: [number, number];
  sameWidth?: boolean;
  hasArrow?: boolean;
}

export default function usePopup({
  placement = "bottom",
  sameWidth = false,
  hasOffset = [5, 0],
  hasArrow = false,
}: UsePopupProps) {
  const popupElement = useRef<any>(null);
  const referenceElement = useRef<any>(null);
  const arrowElement = useRef<any>(null);

  const setReferenceElement = (el: HTMLElement | null) => {
    referenceElement.current = el;
  };
  const setArrowElement = (el: HTMLElement | null) => {
    arrowElement.current = el;
  };
  const setPopupElement = (el: HTMLElement | null) => {
    popupElement.current = el;
  };

  const middleware = [
    flip(),
    shift(),
    offset({ mainAxis: hasOffset[0], crossAxis: hasOffset[1] }),
    sameWidth
      ? size({
          apply: ({ elements: { floating }, rects }) => {
            Object.assign(floating.style, {
              width: `${rects.reference.width}px`,
            });
          },
        })
      : undefined,
    hasArrow ? arrow({ element: arrowElement }) : undefined,
  ];

  const {
    floatingStyles,
    placement: computedPlacement,
    context,
  } = useFloating({
    elements: {
      reference: referenceElement.current,
      floating: popupElement.current,
    },
    whileElementsMounted: autoUpdate,
    placement,
    middleware,
  });

  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([role]);

  return {
    setReferenceElement,
    setPopupElement,
    setArrowElement,

    referenceElement,
    popupElement,
    arrowElement,

    context,
    styles: {
      popper: floatingStyles,
    },
    attributes: {
      popper: {
        ...getFloatingProps(),
        "data-popper-placement": computedPlacement,
      },
      reference: getReferenceProps(),
    },
  };
}
