import React, { useCallback } from "react";

import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { toast } from "react-toastify";
import { useDebounce } from "usehooks-ts";
import { z } from "zod";

import queryClient from "@app/queryClient";
import Button from "@components/data-entry/Button";
import MultiSelect from "@components/data-entry/MultiSelect";
import TextField from "@components/data-entry/TextField";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import BottomBar from "@components/layout/BottomBar";
import { ContactDetails, ContactUpsertData } from "@models/old/Contact";
import { MarketType, MarketTypesList } from "@models/old/MarketType";
import {
  GetFullAccountsResponseItem,
  useGetFullAccounts,
} from "@services/api/accounts/get-full-accounts";
import { GetContactsEndpoint } from "@services/api/contacts/get-contacts";
import { queryKeysFetchContacts } from "@services/api/old/contacts/fetch-contacts";
import { fetchContactsSearchByEmail } from "@services/api/old/contacts/fetch-contacts-search-by-email";
import { useUpsertContact } from "@services/api/old/contacts/upsert-contact";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import AccountSelect from "@shared/accounts/account-select";
import { useUserRole } from "@shared/components/access-control/helpers";
import useUserRepresentativeId from "@shared/hooks/useUserRepresentativeId";

function isPortfolioManager(
  userRepresentativeId: string,
  portfolio: {
    manager:
      | {
          id: string;
        }
      | undefined;
  },
) {
  return portfolio.manager && portfolio.manager.id === userRepresentativeId;
}

export function isPortfolioMember(
  userRepresentativeId: string,
  portfolio: {
    sellers: {
      id: string;
    }[];
  },
) {
  return portfolio.sellers.map((s) => s.id).includes(userRepresentativeId);
}

type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
  accountId: string;
  markets: SelectOptionBase[];
  position?: string;
  phoneNumber?: string;
};

interface UpsertContactFormProps {
  selectedContact?: GetContactsEndpoint.OutputItem;
  onSuccess: () => void;
  onError: (error: string) => void;
  onCancel: () => void;
}

export default function UpsertContactForm({
  selectedContact,
  onSuccess,
  onError,
  onCancel,
}: UpsertContactFormProps) {
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const userRepresentativeId = useUserRepresentativeId();
  const { isAgent } = useUserRole();

  const filterAccountsForAgent = useCallback(
    (account: GetFullAccountsResponseItem) =>
      isAgent
        ? account.portfolios?.some(
            (p) =>
              isPortfolioManager(userRepresentativeId, p) ||
              isPortfolioMember(userRepresentativeId, p),
          )
        : true,
    [isAgent, userRepresentativeId],
  );

  const {
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
    control,
  } = useForm<FormValues>({
    mode: "onTouched",
    defaultValues: {
      email: selectedContact?.email,
      firstName: selectedContact?.firstName,
      lastName: selectedContact?.lastName,
      accountId: selectedContact?.account.id,
      markets: selectedContact?.markets?.map((market) => ({
        value: market,
        label: market,
      })),
      position: selectedContact?.position || "",
      phoneNumber: selectedContact?.phoneNumber || "",
    },
  });

  const { data: accounts } = useGetFullAccounts(organizationId);

  const contactEmail = watch("email");
  const debouncedAccountEmail = useDebounce(contactEmail, 300);

  const { mutateAsync: upsertContact, isPending } = useUpsertContact(
    organizationId,
    selectedContact?.id,
  );

  const onSubmitForm = (data: FormValues) => {
    const contactData: ContactUpsertData = {
      id: selectedContact?.id,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      accountId: data.accountId,
      markets: data.markets.map((market) => market.value as MarketType),
      position: data.position || null,
      phoneNumber: data.phoneNumber || null,
    };

    upsertContact(contactData)
      .then(async () => {
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchContacts(organizationId),
        });
        reset();
        if (selectedContact?.id) {
          toast.success(t("CRM.contacts.form.contact-updated"));
        } else {
          toast.success(t("CRM.contacts.form.contact-created"));
        }
        onSuccess();
      })
      .catch((err) => {
        onError(err.message);
      });
  };

  const verifyContactEmail = async (email: string) => {
    let contacts: ContactDetails[] = [];
    if (
      (selectedContact && debouncedAccountEmail !== selectedContact.email) ||
      (!selectedContact && email === debouncedAccountEmail)
    ) {
      contacts = await fetchContactsSearchByEmail(
        organizationId,
        debouncedAccountEmail,
      );
    }
    if (contacts && contacts.length > 0) {
      return contacts.some((contact) => {
        if (selectedContact) {
          return contact.email === email && contact.id !== selectedContact.id;
        }
        return contact.email === email;
      });
    }

    return false;
  };

  return (
    <form
      className="flex flex-col h-full grow"
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <div className="flex flex-col gap-10 px-4 py-6 lg:p-10 grow">
        <div className="flex flex-col gap-2">
          <p className="text-base">
            {t("CRM.contacts.form.global-information")}
          </p>
          <hr />
        </div>
        <div className="flex flex-col gap-6 mb-4">
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="email">
              {t("CRM.contacts.form.email-label")}
            </label>
            <div className="mt-2">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  validate: {
                    exists: async (value) => {
                      const exists = await verifyContactEmail(value);
                      return !exists;
                    },
                    invalid: (value) =>
                      z.string().email().safeParse(value).success,
                  },
                }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="email"
                    placeholder={t("CRM.contacts.form.email-placeholder")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    aria-invalid={errors.email ? "true" : "false"}
                    hasError={!!error}
                  />
                )}
              />
              {errors.email && errors.email.type === "required" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
              {errors.email && errors.email.type === "exists" && (
                <p className="text-sm italic text-primaryRed">
                  {t("CRM.contacts.form.email-error-already-exists")}
                </p>
              )}
              {errors.email && errors.email.type === "invalid" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-not-valid-email")}
                </p>
              )}
            </div>
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="firstName">
              {t("CRM.contacts.form.firstName-label")}
            </label>
            <div className="mt-2">
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="firstName"
                    placeholder={t("CRM.contacts.form.firstName-placeholder")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    hasError={!!error}
                  />
                )}
              />
              {errors.firstName && errors.firstName.type === "required" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
            </div>
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="lastName">
              {t("CRM.contacts.form.lastName-label")}
            </label>
            <div className="mt-2">
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="lastName"
                    placeholder={t("CRM.contacts.form.lastName-placeholder")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    hasError={!!error}
                  />
                )}
              />
              {errors.lastName && errors.lastName.type === "required" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
            </div>
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="accountId">
              {t("CRM.contacts.form.account-select")}
            </label>
            <div className="mt-2">
              <Controller
                name="accountId"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <AccountSelect
                    id="accountId"
                    name="accountId"
                    placeholder={t(
                      "CRM.contacts.form.account-select-placeholder",
                    )}
                    className={error && "border-primaryRed"}
                    value={value}
                    onChange={(accountOpt) => onChange(accountOpt?.id)}
                    onBlur={onBlur}
                    accounts={
                      accounts
                        ? accounts
                            .filter(filterAccountsForAgent)
                            .sort((a, b) => a.name.localeCompare(b.name))
                        : []
                    }
                  />
                )}
              />
              {errors.accountId && errors.accountId.type === "required" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
            </div>
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="position">
              {t("CRM.contacts.form.position-label")}
            </label>
            <div className="mt-2">
              <Controller
                name="position"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <TextField
                    id="position"
                    placeholder={t("CRM.contacts.form.position-placeholder")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="markets">
              {t("CRM.contacts.form.markets-select")}
            </label>
            <div className="mt-2">
              <Controller
                name="markets"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <MultiSelect
                    id="markets"
                    name="markets"
                    placeholder={t(
                      "CRM.contacts.form.markets-select-placeholder",
                    )}
                    value={value}
                    onChange={onChange}
                    options={MarketTypesList.map<SelectOptionBase>((m) => ({
                      label: t(`Common.market-type.${m}`),
                      value: m,
                    }))}
                    className={error && "border-primaryRed"}
                  />
                )}
              />
              {errors.markets && errors.markets.type === "required" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
            </div>
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="phoneNumber">
              {t("CRM.contacts.form.phoneNumber-label")}
            </label>
            <div className="mt-2">
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <TextField
                    id="phoneNumber"
                    placeholder={t("CRM.contacts.form.phoneNumber-placeholder")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <BottomBar theme="WHITE">
        <Button type="button" onClick={onCancel} label="cancel-form">
          {selectedContact?.id
            ? t("CRM.contacts.buttons.cancel-update")
            : t("Components.buttons.cancel")}
        </Button>
        <Button theme="PRIMARY" disabled={!isValid || isPending}>
          {selectedContact?.id
            ? t("CRM.contacts.buttons.update")
            : t("CRM.contacts.buttons.create")}
          <HiOutlineCheckCircle className="w-5 h-5" />
        </Button>
      </BottomBar>
    </form>
  );
}
