import * as React from "react";

import Button from "@components/data-entry/Button";
import { useError } from "@services/error/error-boundary";

export default function ErrorPage() {
  const { throwError } = useError();
  return (
    <Button
      className="m-8"
      onClick={() => {
        throwError(new Error("This is an error"));
      }}
    >
      Click me
    </Button>
  );
}
