import * as React from "react";

import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

import modaResaLogo from "@app/assets/logos/moda-resa.svg";
import BottomBar from "@components/layout/BottomBar";

export default function CruisingWelcomePage() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className="relative flex items-center justify-center w-full h-full">
      <Outlet />

      {location.pathname.includes("plan-my-trip") ? null : (
        <BottomBar sticky={false} className="absolute bottom-0 items-center">
          <span>{t("Cruising.welcome.footer")}</span>
          <img alt="booked" className="h-4" src={modaResaLogo} />
        </BottomBar>
      )}
    </div>
  );
}
