import React, { useMemo, useState } from "react";

import { Dialog } from "@headlessui/react";

export function useModal() {
  const [isOpen, setIsOpen] = useState(false);

  const memoized = useMemo(
    () => ({
      isOpen,
      close: () => setIsOpen(false),
      open: () => setIsOpen(true),
    }),
    [isOpen],
  );

  return memoized;
}

interface ModalProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
  state: ReturnType<typeof useModal>;
  centeredTitle?: Boolean;
  padding?: string;
  increaseWidth?: Boolean;
}

export default function Modal({
  title,
  children,
  subtitle,
  state,
  centeredTitle,
  padding,
  increaseWidth,
}: ModalProps) {
  return (
    <Dialog
      open={state.isOpen}
      onClose={state.close}
      className="relative z-modal"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}

      <div
        className="fixed inset-0 bg-black/30 animate-slideDownAndFade"
        aria-hidden="true"
      />
      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel
          className={`mx-auto ${increaseWidth ? "w-[50vw]" : "max-w-[90vw]"} max-h-[90vh] bg-white p-4 ${
            padding ?? "p-9"
          } rounded-lg shadow animate-slideDownAndFade`}
        >
          <Dialog.Title
            className={`mb-4 ${centeredTitle ? "flex justify-center" : ""}`}
          >
            <span className="font-bold text-2xl">{title}</span>
            {subtitle ? <span>{subtitle}</span> : null}
          </Dialog.Title>
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
