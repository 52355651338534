import React, { useState } from "react";

import {
  Popover as HeadlessPopover,
  Portal,
  Transition,
} from "@headlessui/react";
import { useDebounce } from "use-debounce";

import WithConditionalRendering from "@components/high-order-components/conditional-render";
import { fadeInOutProps } from "@components/transitions/FadeInOut";
import usePopup, { Placement } from "@helpers/Popup";

export interface HoverCardProps {
  button: JSX.Element;
  placement?: Placement;
  buttonClassName?: string;
  cardClassname?: string;
  children: React.ReactNode;
  buttonAsToggle?: boolean;
}

function HoverCard({
  button,
  placement = "top",
  buttonClassName,
  cardClassname,
  children,
  buttonAsToggle = false,
}: HoverCardProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [debouncedIsOpen] = useDebounce(isOpen, 300);
  const popup = usePopup({ placement });

  return (
    <HeadlessPopover
      className="relative cursor-pointer"
      onBlur={() => setIsOpen(false)}
      onMouseEnter={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
    >
      <HeadlessPopover.Button
        as="div"
        ref={popup.setReferenceElement}
        className={`outline-none ${buttonClassName || ""}`}
        onClick={() => {
          if (buttonAsToggle) {
            setIsOpen(!isOpen);
          }
        }}
      >
        {button}
      </HeadlessPopover.Button>
      <Portal
        as="div"
        className="fixed z-popover"
        ref={popup.setPopupElement}
        {...popup.attributes.popper}
        style={popup.styles.popper}
      >
        <Transition show={isOpen && debouncedIsOpen} {...fadeInOutProps}>
          <HeadlessPopover.Panel
            className={`shadow-lg bg-white rounded border border-primaryLightElectricBlue ${
              cardClassname || ""
            }`}
          >
            {children}
          </HeadlessPopover.Panel>
        </Transition>
      </Portal>
    </HeadlessPopover>
  );
}

export default WithConditionalRendering(HoverCard);
