import React from "react";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";

import Button from "@components/data-entry/Button";
import Checkbox from "@components/data-entry/Checkbox";
import ColorPicker from "@components/data-entry/ColorPicker";
import MultiSelect from "@components/data-entry/MultiSelect";
import Select, { OptionItem } from "@components/data-entry/Select";
import TextField from "@components/data-entry/TextField";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import BottomBar from "@components/layout/BottomBar";
import { PortfolioFormData } from "@models/old/Portfolio";
import {
  Representative,
  RepresentativeUpdate,
  canBePortfolioManager,
} from "@models/old/Representative";
import { useUserRole } from "@shared/components/access-control/helpers";
import { fullName, getRoleName } from "@shared/helpers/formatters";

interface SetupTabProps {
  representatives: Representative[];
  accountPortfolio: PortfolioFormData;
  onSave: (portfolio: PortfolioFormData) => void;
}

type FormValues = {
  portfolioName: string;
  managerId: string;
  portfolioColor: string;
  isAlsoSeller: boolean;
  sellers: SelectOptionBase[];
};

const sellerToOptionItem = (m: Representative): OptionItem => ({
  label: fullName(m),
  subLabel: getRoleName(m.role),
  value: m.id,
});

const isManagerAlsoSeller = (
  managerId?: string,
  sellers?: RepresentativeUpdate[],
): boolean =>
  managerId !== undefined &&
  sellers !== undefined &&
  sellers.map((s) => s.id).includes(managerId);

const convertSellersToOption = (
  representatives: Representative[],
  sellers: { id: string }[],
) => {
  const selectedSeller: Representative[] = [];
  sellers.forEach((s) => {
    const representative = representatives.find((m) => s.id === m.id);
    if (representative) {
      selectedSeller.push(representative);
    }
  });
  return selectedSeller.map(sellerToOptionItem);
};

function SetupTab(props: SetupTabProps) {
  const { representatives, accountPortfolio, onSave } = props;
  const { t } = useTranslation();

  const { isAgent } = useUserRole();
  const managerFieldDisabled = isAgent;

  const {
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    defaultValues: {
      portfolioName: accountPortfolio.name || "",
      managerId: accountPortfolio.manager?.id || undefined,
      portfolioColor: accountPortfolio.color || "",
      sellers: convertSellersToOption(
        representatives,
        accountPortfolio.sellers,
      ),
      isAlsoSeller: accountPortfolio.manager
        ? isManagerAlsoSeller(
            accountPortfolio.manager.id,
            accountPortfolio.sellers,
          )
        : false,
    },
  });

  const handleManagerIsAlsoSeller = (isAlsoSeller: boolean) => {
    const managerId = getValues("managerId");
    const sellers = getValues("sellers") || [];
    if (!managerId) return;
    const managerAlreadySeller = isManagerAlsoSeller(
      managerId,
      sellers.map((o) => ({ id: o.value })),
    );
    if (isAlsoSeller && !managerAlreadySeller && representatives) {
      const representative = representatives.find((m) => managerId === m.id);
      if (representative) {
        setValue("sellers", [...sellers, sellerToOptionItem(representative)]);
      }
    } else if (!isAlsoSeller && managerAlreadySeller) {
      setValue(
        "sellers",
        sellers.filter((o) => o.value !== managerId),
      );
    }
  };

  const onSubmitForm = (data: FormValues) => {
    const sellers = data.sellers.map(
      (c) =>
        ({
          id: c.value as string,
        }) as RepresentativeUpdate,
    );
    onSave({
      ...accountPortfolio,
      name: data.portfolioName,
      color: data.portfolioColor,
      manager: {
        id: data.managerId,
      },
      sellers,
    });
  };

  if (representatives === undefined || representatives.length === 0) {
    return <div>There is no representatives loaded :(</div>;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmitForm)}
      className="flex flex-col  overflow-y-auto grow"
    >
      <div className="p-10 pt-6 flex flex-col gap-6 grow">
        <div className="flex flex-col w-full gap-2 item">
          <label className="font-medium item" htmlFor="portfolio-name">
            {t("Shared.portfolios.setup-tab.name-input-label")}
          </label>
          <Controller
            name="portfolioName"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="portfolio-name"
                className={`w-2/5 item ${error && "border-primaryRed"}`}
                placeholder={t(
                  "Shared.portfolios.setup-tab.name-input-placeholder",
                )}
                onChange={onChange}
                value={value}
              />
            )}
          />
          {errors.portfolioName && errors.portfolioName.type === "required" && (
            <p className="text-xs italic text-primaryRed">
              {t("Common.form.this-is-required")}
            </p>
          )}
        </div>
        <div className="flex items-center w-full gap-2 item">
          <label className="font-medium item" htmlFor="portfolio-color">
            {t("Shared.portfolios.setup-tab.color-input-label")}
          </label>
          <Controller
            name="portfolioColor"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <ColorPicker
                name="portfolioColor"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.portfolioName && errors.portfolioName.type === "required" && (
            <p className="text-xs italic text-primaryRed">
              {t("Common.form.this-is-required")}
            </p>
          )}
        </div>
        <div className="flex flex-col w-full gap-2 item">
          <label className="font-medium item" htmlFor="portfolio-manager">
            {t("Shared.portfolios.setup-tab.manager-input-label")}
            <br />
            <span className="text-sm font-normal item text-primaryDarkGrey">
              {t("Shared.portfolios.setup-tab.manager-input-description")}
            </span>
          </label>
          <div className="flex items-center gap-8">
            <div className="w-1/2">
              <Controller
                name="managerId"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Select
                    name="manager"
                    id="portfolio-manager"
                    disabled={managerFieldDisabled}
                    placeholder={t(
                      "Shared.portfolios.setup-tab.manager-input-placeholder",
                    )}
                    className={`item ${error && "border-primaryRed"}`}
                    showGroups={false}
                    icon={
                      <HiOutlineSearch className="w-5 h-5 text-primaryBlack" />
                    }
                    defaultValue={{
                      value,
                    }}
                    onChange={onChange}
                    options={[
                      {
                        key: "grp1",
                        label: "group",
                        options: representatives
                          .filter((r) => canBePortfolioManager(r))
                          .map((r) => ({
                            label: fullName(r),
                            subLabel: getRoleName(r.role),
                            value: r.id,
                          })),
                      },
                    ]}
                  />
                )}
              />
            </div>
            <Controller
              name="isAlsoSeller"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  label={t(
                    "Shared.portfolios.setup-tab.manager-is-also-a-seller",
                  )}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    handleManagerIsAlsoSeller(e.target.checked);
                  }}
                  name="isAlsoSeller"
                  id="isAlsoSeller"
                  checked={value || false}
                />
              )}
            />
          </div>
          {errors.managerId && errors.managerId.type === "required" && (
            <p className="text-xs italic text-primaryRed">
              {t("Common.form.this-is-required")}
            </p>
          )}
        </div>
        <div className="flex flex-col w-full gap-2 item">
          <label className="font-medium item" htmlFor="portfolio-sellers">
            {t("Shared.portfolios.setup-tab.seller-input-label")}
          </label>
          <Controller
            name="sellers"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <MultiSelect
                id="portfolio-sellers"
                placeholder={t(
                  "Shared.portfolios.setup-tab.seller-input-placeholder",
                )}
                name="sellers"
                onChange={(values) => {
                  onChange(values);
                  const managerId = getValues("managerId");
                  setValue(
                    "isAlsoSeller",
                    values.map((o) => o.value).includes(managerId),
                  );
                }}
                value={value}
                options={representatives.map(sellerToOptionItem)}
                className={`w-full ${error && "border-primaryRed"}`}
              />
            )}
          />
          {errors.sellers && errors.sellers.type === "required" && (
            <p className="text-xs italic text-primaryRed">
              {t("Common.form.this-is-required")}
            </p>
          )}
        </div>
      </div>
      <BottomBar theme="WHITE" sticky={false}>
        <Button theme="PRIMARY">
          {t("Common.next")}
          <HiChevronRight className="w-6 h-6" />
        </Button>
      </BottomBar>
    </form>
  );
}

export default SetupTab;
