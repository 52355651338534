import React, { useEffect, useState } from "react";

import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { HiOutlineCheckCircle, HiOutlineVideoCamera } from "react-icons/hi";
import { toast } from "react-toastify";
import { useDebounce } from "usehooks-ts";
import { z } from "zod";

import queryClient from "@app/queryClient";
import Button from "@components/data-entry/Button";
import MultiSelect from "@components/data-entry/MultiSelect";
import Select from "@components/data-entry/Select";
import TextField from "@components/data-entry/TextField";
import Toggle from "@components/data-entry/Toggle";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import CalloutBox from "@components/feedback/CalloutBox";
import Loading from "@components/feedback/Loading";
import BottomBar from "@components/layout/BottomBar";
import { Representative } from "@models/OrganizationRepresentative";
import { Portfolio } from "@models/Portfolio";
import { MarketType, MarketTypesList } from "@models/old/MarketType";
import {
  RepresentativeRole,
  RepresentativeRoleList,
  RepresentativeUpsertData,
} from "@models/old/Representative";
import { VirtualMeetingAppsList } from "@models/types/enums";
import { useFetchCollections } from "@services/api/old/collections/fetch-collections";
import { queryKeysFetchRepresentatives } from "@services/api/old/representatives/fetch-representatives";
import { useUpsertRepresentative } from "@services/api/old/representatives/upsert-representative";
import { useGetPortfolios } from "@services/api/portfolios/get-portfolios";
import { SearchRepresentativesByEmail } from "@services/api/representatives/search-by-emails";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { useAuthenticatedUser } from "@services/authentication/useAuthentication";
import {
  getCodeByLanguage,
  getLanguageByCode,
  getLanguages,
} from "@services/languages";
import { useBrands } from "@shared/hooks/useBrands";
import virtualToolsValidator from "@shared/representative/virtual-tools-validator";

export function isEmailValid(email: string | undefined, isRequired: boolean) {
  if (!isRequired) {
    // Check email format only if freelancer and field is not empty
    if (email?.trim() !== "") {
      const isValidEmail = z.string().email().safeParse(email).success;
      return isValidEmail || "Invalid email address";
    }
  } else {
    // Always check email format if not a freelancer
    const isValidEmail = z.string().email().safeParse(email).success;
    return isValidEmail || "Invalid email address";
  }
  return true; // Validation passed
}

type FormValues = {
  email?: string;
  firstName: string;
  lastName: string;
  markets: SelectOptionBase[];
  position?: string;
  phoneNumber?: string;
  role: RepresentativeRole;
  languages: SelectOptionBase[];
  portfolios: Record<string, SelectOptionBase[]>;
  virtualMeetingAppLinks: Record<string, string> | null;
};

interface UpsertRepresentativeFormProps {
  selectedRepresentative?: Omit<Representative, "status"> & {
    useInActiveSalesCampaignNames?: string[];
    portfolios: Pick<Portfolio, "id" | "name" | "collectionId">[];
  };
  onSuccess: () => void;
  onError: (error: string) => void;
  onCancel: () => void;
}

export default function UpsertRepresentativeForm({
  selectedRepresentative,
  onSuccess,
  onError,
  onCancel,
}: UpsertRepresentativeFormProps) {
  const { getPortfolioLabel } = useBrands();
  const user = useAuthenticatedUser();
  const {
    organization: { id: organizationId, accountPortfolioType },
  } = useOrganizationAppContext();

  const canUpdateRole =
    user.role === "ORGANIZATION_ADMIN" ||
    user.role === "ORGANIZATION_MAIN_ADMIN";

  const canNotBeUpdated =
    selectedRepresentative &&
    selectedRepresentative.useInActiveSalesCampaignNames &&
    selectedRepresentative.useInActiveSalesCampaignNames?.length > 0;

  const isAccountPortfolioBasedOnCollections =
    accountPortfolioType === "PORTFOLIO_BASED_ON_COLLECTIONS";

  const getPortfoliosByCollection = (collectionId: string | null) => {
    if (!collectionId || !selectedRepresentative) return [];

    const { portfolios } = selectedRepresentative!;
    if (!portfolios) return [];

    const collectionPortfolios = portfolios.filter(
      (portfolio) => portfolio.collectionId === collectionId,
    );
    return collectionPortfolios.map((portfolio) => ({
      label: portfolio.name,
      value: portfolio.id,
    }));
  };

  const {
    handleSubmit,
    watch,
    trigger,
    formState: { errors, isValid },
    control,
    setError,
  } = useForm<FormValues>({
    mode: "onTouched",
    defaultValues: {
      firstName: selectedRepresentative?.firstName,
      lastName: selectedRepresentative?.lastName,
      email: selectedRepresentative?.email || "",
      position: selectedRepresentative?.position || "",
      phoneNumber: selectedRepresentative?.phoneNumber || "",
      role: selectedRepresentative?.role,
      markets: selectedRepresentative?.markets?.map((market) => ({
        label: market,
        value: market,
      })),
      languages: selectedRepresentative?.languages?.map((code) => ({
        label: getLanguageByCode(code.toLowerCase()),
        value: code.toLowerCase(),
      })),
      virtualMeetingAppLinks: selectedRepresentative?.virtualMeetingAppLinks,
      portfolios: isAccountPortfolioBasedOnCollections
        ? selectedRepresentative?.portfolios?.reduce(
            (acc, portfolio) => ({
              ...acc,
              [portfolio.collectionId!]: getPortfoliosByCollection(
                portfolio.collectionId,
              ),
            }),
            {},
          )
        : {
            wholeshowroom:
              selectedRepresentative?.portfolios &&
              selectedRepresentative?.portfolios.length > 0
                ? selectedRepresentative.portfolios.map((portfolio) => ({
                    label: portfolio.name,
                    value: portfolio.id,
                  }))
                : [],
          },
    },
  });

  const { data: collections, isLoading: isLoadingCollections } =
    useFetchCollections(organizationId);
  const { data: porfolios, isLoading: isLoadingPortfolios } = useGetPortfolios({
    organizationId,
  });

  const roleList: RepresentativeRole[] = RepresentativeRoleList.filter(
    (i: RepresentativeRole) =>
      !["ORGANIZATION_MAIN_ADMIN", "INTERNAL_MEMBER"].includes(i),
  );

  const hasVirtualMeetingAppLinks =
    selectedRepresentative !== undefined &&
    selectedRepresentative.virtualMeetingAppLinks !== null
      ? Object.keys(
          (selectedRepresentative.virtualMeetingAppLinks || {}) as Record<
            string,
            string
          >,
        ).length > 0
      : false;

  const [isAllowVirtualTools, setIsAllowVirtualTools] = useState<boolean>(
    hasVirtualMeetingAppLinks,
  );

  const representativeEmail = watch("email");
  const debouncedRepresentativeEmail = useDebounce(representativeEmail, 300);

  const { mutateAsync: upsertRepresentative } = useUpsertRepresentative(
    organizationId,
    selectedRepresentative?.id,
  );

  const roleStatus = watch("role");

  // derived state
  const isRequired = roleStatus !== "FREELANCE";

  const onSubmitForm = (data: FormValues) => {
    const virtualMeetingAppLinks =
      isAllowVirtualTools && data.virtualMeetingAppLinks
        ? Object.entries(data.virtualMeetingAppLinks)
            .filter(([_key, value]) => !!value) // remove empty values
            .reduce(
              (acc, [key, value]) => ({
                ...acc,
                [key]: value,
              }),
              {},
            )
        : null;

    const portfolios = isAccountPortfolioBasedOnCollections
      ? Object.values(data.portfolios)
          .filter((value) => !!value) // remove empty values
          .reduce((acc, value) => [...acc, ...value], []) // flatten array
          .map((portfolio) => ({
            id: portfolio.value,
          }))
      : data.portfolios.wholeshowroom.map((portfolio) => ({
          id: portfolio.value,
        }));

    const representativeData: RepresentativeUpsertData = {
      id: selectedRepresentative?.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email || null,
      position: data.position || null,
      phoneNumber: data.phoneNumber || null,
      role: data.role,
      markets: data.markets.map((market) => market.value as MarketType),
      languages: data.languages.map((language) => language.value),
      virtualMeetingAppLinks,
      portfolios,
    };

    upsertRepresentative(representativeData)
      .then(async () => {
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchRepresentatives(organizationId),
        });
        if (selectedRepresentative?.id) {
          toast.success(t("Representatives.form.representative-updated"));
        } else {
          toast.success(t("Representatives.form.representative-created"));
        }
        onSuccess();
      })
      .catch((err) => {
        onError(err.message);
      });
  };

  const q = SearchRepresentativesByEmail.useHook({
    email: debouncedRepresentativeEmail || "",
    organizationId,
  });

  const emailInError = (q.data || []).some((representative) => {
    if (selectedRepresentative) {
      return (
        representative.email === debouncedRepresentativeEmail &&
        representative.id !== selectedRepresentative.id
      );
    }
    return representative.email === debouncedRepresentativeEmail;
  });
  useEffect(
    () => (emailInError ? setError("email", { type: "exists" }) : undefined),
    [emailInError],
  );

  const isVirtualToolRequiredFilled = virtualToolsValidator(
    isAllowVirtualTools,
    selectedRepresentative?.virtualMeetingAppLinks,
  );
  const virtualMeetingAppLinks = watch("virtualMeetingAppLinks");
  const virtualToolNeeded =
    isAllowVirtualTools &&
    (!virtualMeetingAppLinks ||
      Object.values(virtualMeetingAppLinks).filter((l) => l).length === 0);

  if (isLoadingCollections || isLoadingPortfolios) {
    return <Loading type="screen" />;
  }

  return (
    <form
      className="flex flex-col h-full grow"
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <CalloutBox className="mt-2 mx-4 lg:mx-10" type="INFORMATIVE">
        {t("Representatives.form.informative")}
      </CalloutBox>
      <div className="px-4 py-6 lg:p-10 flex flex-col grow gap-10">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <p className="text-base">
              {t("Representatives.form.global-information")}
            </p>
            <hr />
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="firstName">
              {t("Representatives.form.firstName-label")}
            </label>
            <div className="mt-2">
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="firstName"
                    placeholder={t(
                      "Representatives.form.firstName-placeholder",
                    )}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    hasError={!!error}
                  />
                )}
              />
              {errors.firstName && errors.firstName.type === "required" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
            </div>
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="lastName">
              {t("Representatives.form.lastName-label")}
            </label>
            <div className="mt-2">
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="lastName"
                    placeholder={t("Representatives.form.lastName-placeholder")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    hasError={!!error}
                  />
                )}
              />
              {errors.lastName && errors.lastName.type === "required" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
            </div>
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="email">
              {t("Representatives.form.email-label")}
            </label>
            <div className="mt-2">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: isRequired,
                  validate: {
                    invalid: (value) => isEmailValid(value, isRequired),
                  },
                }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="email"
                    placeholder={t("Representatives.form.email-placeholder")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={isRequired ? !!selectedRepresentative : false}
                    aria-invalid={errors.email ? "true" : "false"}
                    hasError={!!error}
                  />
                )}
              />
              {isRequired &&
                errors.email &&
                errors.email.type === "required" && (
                  <p className="text-sm italic text-primaryRed">
                    {t("Common.form.this-is-required")}
                  </p>
                )}
              {errors.email && errors.email.type === "exists" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Representatives.form.email-error-already-exists")}
                </p>
              )}
              {errors.email && errors.email.type === "invalid" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-not-valid-email")}
                </p>
              )}
            </div>
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="phoneNumber">
              {t("Representatives.form.phoneNumber-label")}
            </label>
            <div className="mt-2">
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <TextField
                    id="phoneNumber"
                    placeholder={t(
                      "Representatives.form.phoneNumber-placeholder",
                    )}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="position">
              {t("Representatives.form.position-label")}
            </label>
            <div className="mt-2">
              <Controller
                name="position"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <TextField
                    id="position"
                    placeholder={t("Representatives.form.position-placeholder")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="role">
              {t("Representatives.form.role-select")}
            </label>
            <div className="mt-2">
              <Controller
                name="role"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <Select
                    id="role"
                    name="role"
                    disabled={!canUpdateRole}
                    placeholder={t(
                      "Representatives.form.role-select-placeholder",
                    )}
                    className={error && "border-primaryRed"}
                    defaultValue={{
                      value,
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={[
                      {
                        key: "roles_grp1",
                        label: "Roles",
                        options: selectedRepresentative
                          ? RepresentativeRoleList.map((role) => ({
                              label: t(`Common.representative-role.${role}`),
                              value: role,
                            }))
                          : roleList.map((role) => ({
                              label: t(`Common.representative-role.${role}`),
                              value: role,
                            })),
                      },
                    ]}
                  />
                )}
              />
              {errors.role && errors.role.type === "required" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
            </div>
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="markets">
              {t("Representatives.form.markets-select")}
            </label>
            <div className="mt-2">
              <Controller
                name="markets"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <MultiSelect
                    id="markets"
                    name="markets"
                    placeholder={t(
                      "Representatives.form.markets-select-placeholder",
                    )}
                    value={value}
                    onChange={onChange}
                    options={MarketTypesList.map<SelectOptionBase>((m) => ({
                      label: t(`Common.market-type.${m}`),
                      value: m,
                    }))}
                    className={error && "border-primaryRed"}
                  />
                )}
              />
              {errors.markets && errors.markets.type === "required" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
            </div>
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="languages">
              {t("Representatives.form.languages-select")}
            </label>
            <div className="mt-2">
              <Controller
                name="languages"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <MultiSelect
                    id="languages"
                    name="languages"
                    placeholder={t(
                      "Representatives.form.languages-select-placeholder",
                    )}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={getLanguages().map<SelectOptionBase>((l) => ({
                      label: t(`Common.language.${l}`),
                      value: getCodeByLanguage(l),
                    }))}
                    className={error && "border-primaryRed"}
                  />
                )}
              />
              {errors.languages && errors.languages.type === "required" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
            </div>
          </div>
        </div>
        {porfolios && porfolios.length > 0 && (
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <p className="text-base">
                {t("Representatives.form.portfolios-associated-as-seller")}
              </p>
              <hr />
            </div>
            <div className="flex flex-col gap-6 lg:flex-wrap lg:flex-row">
              {!isAccountPortfolioBasedOnCollections && (
                <div className="w-full lg:w-max lg:min-w-max">
                  <label
                    className="font-medium"
                    htmlFor="portfolios.wholeshowroom"
                  >
                    {t("Representatives.form.whole-showroom-portfolio-label")}
                  </label>
                  <div className="w-full lg:max-w-lg mt-2">
                    <Controller
                      name="portfolios.wholeshowroom"
                      control={control}
                      rules={{ required: false }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <MultiSelect
                          id="portfolios.wholeshowroom"
                          name="portfolios.wholeshowroom"
                          disabled={canNotBeUpdated}
                          placeholder={t(
                            "Representatives.form.porfolios-label-placeholder",
                          )}
                          onChange={onChange}
                          value={value}
                          options={porfolios
                            .filter(
                              (porfolio) => porfolio.collectionId === null,
                            )
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((portfolio) => ({
                              label: getPortfolioLabel(portfolio),
                              value: portfolio.id,
                            }))}
                          className={error && "border-primaryRed"}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
              {isAccountPortfolioBasedOnCollections &&
                collections &&
                collections.map((collection) => (
                  <div
                    key={`collection-portfolio-${collection.id}`}
                    className="w-full lg:w-max lg:min-w-max"
                  >
                    <label
                      className="font-medium"
                      htmlFor={`portfolios.${collection.id}`}
                    >
                      {t("Representatives.form.porfolios-label", {
                        name: collection.name,
                      })}
                    </label>
                    <div className="w-full lg:min-w-80 lg:max-w-lg mt-2">
                      <Controller
                        name={`portfolios.${collection.id}`}
                        control={control}
                        rules={{ required: false }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <MultiSelect
                            id={`portfolios.${collection.id}`}
                            name={`portfolios.${collection.id}`}
                            disabled={canNotBeUpdated}
                            placeholder={t(
                              "Representatives.form.porfolios-label-placeholder",
                            )}
                            onChange={onChange}
                            value={value}
                            options={porfolios
                              .filter(
                                (porfolio) =>
                                  porfolio.collectionId === collection.id,
                              )
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((portfolio) => ({
                                label: portfolio.name,
                                value: portfolio.id,
                              }))}
                            className={error && "border-primaryRed"}
                          />
                        )}
                      />
                    </div>
                  </div>
                ))}
            </div>
            <CalloutBox type="INFORMATIVE">
              {t("Representatives.form.to-add-portfolios")}
            </CalloutBox>
          </div>
        )}
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <p className="text-base">
              {t("Representatives.form.virtual-tool-ids")}
            </p>
            <hr />
          </div>
          <div
            className={`flex items-center p-6 border border-grey rounded-lg ${
              canNotBeUpdated && "bg-primaryLightestGrey"
            }`}
          >
            <div className="min-w-[20px]">
              <HiOutlineVideoCamera className="w-5 h-5" />
            </div>
            <Button
              type="button"
              theme="NONE"
              disabled={canNotBeUpdated && isAllowVirtualTools}
              onClick={() => setIsAllowVirtualTools(!isAllowVirtualTools)}
              className="flex flex-col mx-4 grow disabled:opacity-100"
            >
              <div className="text-start">
                <p>{t("Representatives.form.virtual-tool-label")}</p>
                <p className="text-sm text-grey">
                  {t("Representatives.form.virtual-tool-description")}
                </p>
              </div>
            </Button>
            <Toggle
              id="allowVirtualTools"
              showLabel
              checked={isAllowVirtualTools}
              disabled={canNotBeUpdated}
              onChange={async (checked) => {
                setIsAllowVirtualTools(checked);
                await trigger();
              }}
            />
          </div>
          {virtualToolNeeded && (
            <p className="text-sm -mt-6 italic text-primaryRed">
              {t("Representatives.form.virtual-tool-is-required")}
            </p>
          )}
          {isAllowVirtualTools &&
            VirtualMeetingAppsList.map((app) => (
              <div className="w-full lg:w-2/3 xl:w-1/2" key={app}>
                <label
                  className="font-medium"
                  htmlFor={`virtualMeetingAppLinks.${app}`}
                >
                  {t(`Representatives.form.virtual-tool.${app}`)}
                </label>
                <div className="mt-2">
                  <Controller
                    name={`virtualMeetingAppLinks.${app}`}
                    control={control}
                    rules={{
                      validate: {
                        required: (value) =>
                          isVirtualToolRequiredFilled(app, value),
                        invalid: async (value) => {
                          if (value) {
                            return z.string().url().safeParse(value).success;
                          }
                          return true;
                        },
                      },
                    }}
                    render={({
                      field: { onChange, value, onBlur },
                      fieldState: { error },
                    }) => (
                      <TextField
                        id={`virtualMeetingAppLinks.${app}`}
                        placeholder={t(
                          "Representatives.form.virtual-tool-field-placeholder",
                        )}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        hasError={!!error}
                      />
                    )}
                  />
                  {errors.virtualMeetingAppLinks &&
                    errors.virtualMeetingAppLinks[app] &&
                    errors.virtualMeetingAppLinks[app]?.type === "invalid" && (
                      <p className="text-sm italic text-primaryRed">
                        {t("Common.form.this-is-not-valid-url")}
                      </p>
                    )}
                  {errors.virtualMeetingAppLinks &&
                    errors.virtualMeetingAppLinks[app] &&
                    errors.virtualMeetingAppLinks[app]?.type === "required" && (
                      <p className="text-sm italic text-primaryRed">
                        {t("Common.form.virtual-tool-error-empty")}
                      </p>
                    )}
                </div>
              </div>
            ))}
        </div>
      </div>
      <BottomBar theme="WHITE">
        <Button
          type="button"
          onClick={onCancel}
          label="cancel representative form"
        >
          {selectedRepresentative?.id
            ? t("Representatives.buttons.cancel-update")
            : t("Components.buttons.cancel")}
        </Button>
        <Button theme="PRIMARY" disabled={virtualToolNeeded || !isValid}>
          {selectedRepresentative?.id
            ? t("Representatives.buttons.update")
            : t("Representatives.buttons.create")}
          <HiOutlineCheckCircle className="w-5 h-5" />
        </Button>
      </BottomBar>
    </form>
  );
}
