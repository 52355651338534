import { UserRole } from "@models/types/enums";
import useAuthentication from "@services/authentication/useAuthentication";

export const ACL_ADMINS: UserRole[] = [
  "ORGANIZATION_ADMIN",
  "ORGANIZATION_MAIN_ADMIN",
];
export const ACL_AGENTS: UserRole[] = ["AGENT"];
export const ACL_MANAGERS: UserRole[] = ["ORGANIZATION_MANAGER"];
export const ACL_FREELANCES: UserRole[] = ["FREELANCE"];

export const ACL_ADMINS_MANAGERS: UserRole[] = [...ACL_ADMINS, ...ACL_MANAGERS];
export const ACL_ADMIN_AGENTS: UserRole[] = [...ACL_ADMINS, ...ACL_AGENTS];
export const ACL_ADMINS_AGENTS_MANAGERS: UserRole[] = [
  ...ACL_ADMINS,
  ...ACL_MANAGERS,
  ...ACL_AGENTS,
];
export const ACL_EVERYONE: UserRole[] = [
  ...ACL_ADMINS_AGENTS_MANAGERS,
  ...ACL_FREELANCES,
];

export function hasRole(role: UserRole | undefined, roleList: UserRole[]) {
  return !!role && roleList.includes(role);
}

export function hasRoleOrIsAdmin(
  role: UserRole | undefined,
  roleList: UserRole[],
) {
  return hasRole(role, [...roleList, "MODARESA_ADMIN"]);
}

export function useUserRole() {
  const { currentUser } = useAuthentication();

  return {
    isAdmin: hasRole(currentUser?.role, ACL_ADMINS),
    isAgent: hasRole(currentUser?.role, ACL_AGENTS),
    isManager: hasRole(currentUser?.role, ACL_MANAGERS),
    isFreelance: hasRole(currentUser?.role, ACL_FREELANCES),
  };
}
