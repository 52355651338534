import React, { useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import Loading from "@components/feedback/Loading";
import { FetchBookingResponse } from "@services/api/old/booking/fetch-booking";
import { useFetchSpecialRequests } from "@services/api/old/booking/fetch-special-requests";
import { useSendConfirmationEmails } from "@services/api/old/booking/send-confirmation-emails";
import { useError } from "@services/error/error-boundary";

import AppointmentBookingAdditionalEmailsSelect from "./appointment/additional-emails-select";
import AttendeesOtbFill from "./attendees-otb-fill";
import ShowroomSelect from "./showroom-select";
import SlotSelect from "./slot-select";

interface BookingWizardProps {
  bookingData: FetchBookingResponse;
}

export default function BookingWizard(props: BookingWizardProps) {
  const [currentStep, setStep] = useState(1);
  const { bookingData } = props;

  const navigate = useNavigate();
  const { throwError } = useError();
  const { invitationId } = useParams();
  if (invitationId === undefined) {
    throw Error("invitationId is undefined");
  }

  const {
    // data: bookingSpecialRequests,
    isLoading: isLoadingBookingSpecialRequests,
    error: errorBookingSpecialRequests,
    isError: isErrorBookingSpecialRequests,
  } = useFetchSpecialRequests(invitationId);

  const { mutate: sendConfirmationEmails } =
    useSendConfirmationEmails(invitationId);

  if (isLoadingBookingSpecialRequests) {
    return <Loading type="screen" />;
  }

  if (isErrorBookingSpecialRequests) {
    return throwError(errorBookingSpecialRequests);
  }

  return (
    <>
      {currentStep === 1 && (
        <ShowroomSelect
          invitationId={invitationId}
          hiddenCollectionsIds={bookingData.contact.account.collectionStatuses
            .filter(
              (collectionStatus) =>
                collectionStatus.status === "NOT_ACCESSIBLE",
            )
            .map((collectionStatus) => collectionStatus.collectionId)}
          showrooms={bookingData.showrooms}
          onNext={() => setStep(currentStep + 1)}
        />
      )}
      {currentStep === 2 && (
        <AttendeesOtbFill
          colleagues={bookingData.invitedColleagues}
          invitationId={invitationId}
          budgets={bookingData.contact.account.salesBudgets}
          showrooms={bookingData.showrooms}
          onNext={() => setStep(currentStep + 1)}
          onPrevious={() => setStep(currentStep - 1)}
        />
      )}
      {currentStep === 3 && (
        <SlotSelect
          contact={bookingData.contact}
          onPrevious={() => setStep(currentStep - 1)}
          onSubmit={(appointments, specialRequests) => {
            if (appointments.length > 0) {
              setStep(currentStep + 1);
              // trigger emails send for booked appointments
              sendConfirmationEmails(appointments.map((a) => a.id));
            } else if (specialRequests.length > 0) {
              navigate(`/booking/${invitationId}`);
            }
          }}
          showrooms={bookingData.showrooms}
          portfolios={bookingData.portfolios}
          invitationId={invitationId}
          organization={bookingData.organization}
        />
      )}
      {currentStep === 4 && (
        <AppointmentBookingAdditionalEmailsSelect
          contacts={bookingData.invitedColleagues}
          onSkip={() => {
            navigate(`/booking/${invitationId}`);
          }}
          invitationId={invitationId}
          onSubmit={() => {
            navigate(`/booking/${invitationId}`);
          }}
        />
      )}
    </>
  );
}
