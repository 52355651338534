import { WorkSheet, read, utils } from "xlsx";

class ExcelService {
  public static async parseFile<Row>(file: File) {
    const wb = read(await file.arrayBuffer(), { cellDates: true });
    return {
      sheetsNames: wb.SheetNames,
      sheet: (sheetName: string) => wb.Sheets[sheetName],
      rows: (s: WorkSheet) =>
        utils.sheet_to_json<Row>(s, {
          header: 1,
          blankrows: false,
          raw: false,
        }),
    };
  }
}

export default ExcelService;
