import React from "react";

import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import Tooltip from "@components/data-display/Tooltip";
import Button from "@components/data-entry/Button";
import Modal, { useModal } from "@components/feedback/Modal";
import { InvitationStatusEnum } from "@models/types/enums";

import { BlueRow } from "../../components/data-display/BlueRow";
import BookedAppointmentCardContainer from "./BookedAppointmentCardContainer";

interface Appointment {
  id: string;
}

interface Account {
  id: string;
  name: string;
}

interface Organization {
  id: string;
  name: string;
}

interface OpeningDays {
  day: Date;
  keyAccountsOpeningHour: Date | null;
  keyAccountsClosingHour: Date | null;
  customOpeningHour: Date | null;
  customClosingHour: Date | null;
}

interface Showroom {
  id: string;
  city: string;
  countryCode: string;
  season: string;
  year: number;
  openingDays: OpeningDays[];
}

interface Contact {
  id: string;
  account: Account;
}

export interface BuyerInvitation {
  id: string;
  invitationId: string;
  invitationStatus: InvitationStatusEnum;
  showroom: Showroom;
  appointments: Appointment[];
  contact: Contact;
  organization: Organization;
}

export interface BuyerInvitationProps {
  buyerInvitation: BuyerInvitation;
}

// display the status for the "status" collumn
function getStatusDisplay(status: string) {
  if (status === "INVITED" || status === "FOLLOWED_UP") {
    return "Invited";
  }

  if (status === "BOOKED" || status === "BOOKED_BY_ORGANIZATION") {
    return "Booked";
  }

  if (status === "CANCELLED" || status === "CANCELLED_BY_ORGANIZATION") {
    return "Cancelled";
  }

  if (status === "JOKER" || status === "FAILED" || status === "NOT_INVITED") {
    return null;
  }

  return null;
}

// render buttons in the "action" collumn based on the status
function renderButtons(
  status: string,
  id: string,
  invitationId: string,
  t: Function,
  navigate: Function,
) {
  if (status === "INVITED" || status === "FOLLOWED_UP") {
    return (
      <Button
        theme="PRIMARY"
        className="w-40 items-center justify-center"
        onClick={() => navigate(`/booking/${invitationId}`)}
        aria-label={t("BuyerAccount.buyer-invitations.buttons.book")}
      >
        {t("BuyerAccount.buyer-invitations.buttons.book")}
      </Button>
    );
  }

  if (status === "BOOKED" || status === "BOOKED_BY_ORGANIZATION") {
    return (
      <Button
        theme="SECONDARY"
        className="w-40 items-center justify-center"
        onClick={() =>
          navigate(`/booking/${invitationId}/book-appointment/edit/${id}`)
        }
        aria-label={t("BuyerAccount.buyer-invitations.buttons.edit")}
      >
        {t("BuyerAccount.buyer-invitations.buttons.edit")}
      </Button>
    );
  }

  if (status === "CANCELLED" || status === "CANCELLED_BY_ORGANIZATION") {
    return (
      <Button
        theme="PRIMARY"
        className="w-40 items-center justify-center"
        onClick={() => navigate(`/booking/${invitationId}`)}
        aria-label={t("BuyerAccount.buyer-invitations.buttons.book-again")}
      >
        {t("BuyerAccount.buyer-invitations.buttons.book-again")}
      </Button>
    );
  }

  // for JOKER, FAILED, NOT_INVITED - don't display anything
  return null;
}

function BuyerInvitationRowsDisplay({ buyerInvitation }: BuyerInvitationProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const appointmentCount = buyerInvitation.appointments.length;
  const modalState = useModal();

  return (
    <BlueRow colsClass="grid-cols-8">
      {/* organization name */}
      <p className="py-3 px-4 font-light text-left truncate flex items-center col-span-1 pl-2">
        {buyerInvitation.organization.name}
      </p>
      {/* opening day */}
      <p className="py-3 px-4 font-light text-left truncate flex items-center col-span-1">
        {format(buyerInvitation.showroom.openingDays[0].day, "MMM d yyyy")}
      </p>
      {/* closing day */}
      <p className="py-3 px-4 font-light text-left truncate flex items-center col-span-1">
        {format(
          new Date(
            buyerInvitation.showroom.openingDays[
              buyerInvitation.showroom.openingDays.length - 1
            ].day,
          ),
          "MMM d yyyy",
        )}
      </p>
      {/* city + country */}
      <p className="py-3 px-4 font-light text-left truncate flex items-center col-span-1">
        {buyerInvitation.showroom.city}, {buyerInvitation.showroom.countryCode}
      </p>
      {/* season + year */}
      <p className="py-3 px-4 font-light text-left truncate flex items-center col-span-1">
        {buyerInvitation.showroom.season} {buyerInvitation.showroom.year}
      </p>
      {/* invitaiton status */}
      <div className="py-3 px-4 font-light text-left truncate flex items-center col-span-1">
        {getStatusDisplay(buyerInvitation.invitationStatus)}

        {buyerInvitation.invitationStatus === "BOOKED" ||
        buyerInvitation.invitationStatus === "BOOKED_BY_ORGANIZATION" ? (
          <Tooltip
            placement="bottom-start"
            content={t(
              "BuyerAccount.buyer-invitations.table-content.invitation-tooltip",
            )}
            fallbackProp="children"
          >
            <Button
              theme="NONE"
              className="ml-2 items-center justify-center"
              onClick={() =>
                navigate(`/booking/${buyerInvitation.invitationId}`)
              }
              aria-label={t("BuyerAccount.buyer-invitations.buttons.book")}
            >
              <FaExternalLinkAlt className="text-primaryElectricBlue" />
            </Button>
          </Tooltip>
        ) : null}
      </div>
      {/* appointment count */}
      <div className="py-3 px-4 font-light text-left truncate flex items-center col-span-1">
        {appointmentCount > 0 ? (
          <>
            <Button
              className="whitespace-nowrap text-primaryElectricBlue hover:underline"
              theme="NONE"
              onClick={() => modalState.open()}
              aria-label={t(
                "BuyerAccount.buyer-invitations.buttons.appointments",
              )}
            >
              {appointmentCount}{" "}
              {appointmentCount === 1 ? "appointment" : "appointments"}
            </Button>
            <Modal
              title={t("BuyerAccount.buyer-invitations.modal.all-appointments")}
              centeredTitle
              increaseWidth
              state={modalState}
            >
              {buyerInvitation.appointments.map((appt) => (
                <BookedAppointmentCardContainer
                  key={appt.id}
                  appointmentId={appt.id}
                />
              ))}
            </Modal>
          </>
        ) : (
          "-"
        )}
      </div>

      <div className="py-3 px-4 font-light text-left truncate flex items-center col-span-1">
        {renderButtons(
          buyerInvitation.invitationStatus,
          buyerInvitation.id,
          buyerInvitation.invitationId,
          t,
          navigate,
        )}
      </div>
    </BlueRow>
  );
}

export default BuyerInvitationRowsDisplay;
