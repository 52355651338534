import { useMutation } from "@tanstack/react-query";

import queryClient from "@app/queryClient";
import { Organization } from "@models/old/Organization";
import { Representative } from "@models/old/Representative";
import axiosInstance from "@services/api/config";
import { queryKeysFetchRepresentatives } from "@services/api/old/representatives/fetch-representatives";

export const postInviteRepresentativeEndpoint = {
  path: (
    organizationId: Organization["id"],
    representativeId: Representative["id"],
  ) =>
    `/organizations/${organizationId}/representatives/${representativeId}/invite`,
};

export function usePostInviteRepresentative(organizationId: string) {
  return useMutation({
    mutationFn: (RepresentativeId: string) =>
      axiosInstance.post(
        postInviteRepresentativeEndpoint.path(organizationId, RepresentativeId),
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeysFetchRepresentatives(organizationId),
      });
    },
  });
}
