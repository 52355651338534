import React, { PropsWithChildren, useState } from "react";

import Toggle from "@components/data-entry/Toggle";

type TogglePanelProps = PropsWithChildren<{
  title: string;
  subtitle?: string;
  id: string;
  icon: React.ReactNode;
  initiallyOpened?: boolean;
  onOn?: () => void;
  onOff?: () => void;
}>;

export default function TogglePanel({
  children,
  icon,
  id,
  title,
  subtitle,
  initiallyOpened = false,
  onOff = () => {},
  onOn = () => {},
}: TogglePanelProps) {
  const [toggled, setToggled] = useState(initiallyOpened);
  const turnOn = () => {
    setToggled(true);
    onOn();
  };
  const turnOff = () => {
    setToggled(false);
    onOff();
  };
  const toggle = () => (toggled ? turnOff() : turnOn());
  return (
    <div className="flex flex-col gap-2 border rounded-lg border-primaryLightGrey p-4">
      <label
        className="cursor-pointer flex items-center justify-between"
        htmlFor={id}
      >
        <div className="flex items-center gap-4">
          {icon}
          {title && <p>{title}</p>}
          {subtitle && (
            <p className="col-span-8 text-sm text-grey">{subtitle}</p>
          )}
        </div>
        <Toggle id={id} checked={toggled} onChange={toggle} />
      </label>
      {toggled && children}
    </div>
  );
}
