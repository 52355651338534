import React from "react";

import { cva } from "class-variance-authority";

const style = cva(
  "last:rounded-r first:rounded-l p-1 bg-primaryLightElectricBlue text-primaryElectricBlue",
  {
    variants: {
      checked: {
        true: "border border-primaryElectricBlue font-bold",
        false: "",
      },
    },
  },
);

type Props = {
  checked: boolean;
  onClick: () => void;
};

export default function InlineTableFilterToggle({
  checked,
  onClick,
  children,
}: React.PropsWithChildren<Props>) {
  const className = style({ checked });
  return (
    <button type="button" className={className} onClick={onClick}>
      {children}
    </button>
  );
}
