import { Showroom } from "@models/Showroom";

export const isDraftShowroom = (showroom: { status: Showroom["status"] }) =>
  showroom.status === "DRAFT";

export const isPastShowroom = (showroom: { status: Showroom["status"] }) =>
  showroom.status === "PAST";

export const isReadyToLaunch = (showroom: { status: Showroom["status"] }) =>
  showroom.status === "READY_TO_LAUNCH";

export const isActiveShowroom = (showroom: { status: Showroom["status"] }) =>
  !isDraftShowroom(showroom) && !isPastShowroom(showroom);
