import React, { CSSProperties } from "react";

import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

import { BusyCell } from "@calendar/helpers/calendar-column";
import { COLUMN_WIDTH, ROW_HEIGHT } from "@calendar/style";
import { Calendar } from "@calendar/types";

type BusySlotProps = {
  appointmentCell: BusyCell;
  onClick: (appointment: Calendar.Appointment) => void;
};

export default function BusySlot({ appointmentCell, onClick }: BusySlotProps) {
  const { appointment, rowSpans } = appointmentCell;
  const { id } = appointment;

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: `${id}`,
    });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    border: "1px solid #00000",
    width: `${COLUMN_WIDTH}rem`,
    height: `${ROW_HEIGHT * (rowSpans === 1 ? rowSpans : rowSpans)}rem`,
    position: "absolute",
    zIndex: isDragging ? 1000 : 0,
    boxShadow: isDragging
      ? "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
      : "",
  };

  return (
    <div
      data-testid={appointment.id}
      className="py-1 px-2 h-full cursor-pointer relative bg-primaryLightestGrey flex items-center justify-center"
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      role="button"
      tabIndex={0}
      onKeyUp={() => onClick(appointment)}
      onClick={() => onClick(appointment)}
    >
      {appointment.title}
    </div>
  );
}
