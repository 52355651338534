import Address from "./Address";

export enum AdminOnboardingStep {
  ORGANIZATION_CREATION,
  ACCOUNTS_UPLOAD,
  CONTACTS_UPLOAD,
  REPRESENTATIVES_UPLOAD,
}
export enum OnboardingStep {
  ORGANIZATION_CONFIRMATION,
  SHOWROOM_STRUCTURE,
  ACCOUNT_PORTFOLIO,
}

export type AccountPortfolioType =
  | "PORTFOLIO_BASED_ON_COLLECTIONS"
  | "PORTFOLIO_BASED_ON_WHOLE_SHOWROOM";

export interface Organization {
  id: string;
  name: string;
  type: "BRAND" | "AGENCY";
  address: Address;
  timezone: string;
  onboardingStatus: "STARTED" | "DONE" | "EMAIL_SENT";
  accountPortfolioType: AccountPortfolioType;
  banner: string | null;
}
