import { useQuery } from "@tanstack/react-query";

import { AccountWithCollections } from "@models/old/Account";
import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

const fetchAccountsArchivedEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/accounts/archived`,
};

type FetchAccountsResponse = AccountWithCollections[];

export const queryKeysFetchAccountsArchived = (organizationId: string) =>
  fetchAccountsArchivedEndpoint.path(organizationId).split("/");

export const useFetchAccountsArchived = (organizationId: string) =>
  useQuery({
    queryKey: queryKeysFetchAccountsArchived(organizationId),
    queryFn: () =>
      axiosInstance
        .get<FetchAccountsResponse>(
          fetchAccountsArchivedEndpoint.path(organizationId),
        )
        .then((res) => res.data),
  });
