/* eslint-disable react/no-array-index-key */
import React from "react";

import { HiCheck, HiChevronLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import Button from "@components/data-entry/Button";
import BottomBar from "@components/layout/BottomBar";
import { OnboardingStep } from "@models/old/Organization";
import OnboardingBreadcrumb from "@onboarding/components/Breadcrumb";
import OrganizationAPI from "@services/api/old/organization/OrganizationAPI";
import useApplicationContext, {
  useOrganizationAppContext,
  useOrganizationContextRefresh,
} from "@services/application/useApplicationContext";
import PortfoliosOverview from "@shared/portfolios/portfolios-overview";

export default function ClientAccountPortfolioPage() {
  const navigate = useNavigate();
  const { setOrganization } = useApplicationContext();
  const { organization } = useOrganizationAppContext();

  useOrganizationContextRefresh();

  if (!organization) {
    throw Error(
      "Account Portfolio should have an organizationId uuid in the path",
    );
  }

  const screenMeasure = "px-10";

  return (
    <div className="flex flex-col justify-center grow">
      <div className="flex flex-col justify-between w-full h-screen">
        <header className="px-10 my-12 h-fit">
          <OnboardingBreadcrumb
            currentStep={OnboardingStep.ACCOUNT_PORTFOLIO}
          />
        </header>
        <h1 className="heading-1 px-10 pb-10">Account Portfolio</h1>
        <div
          className={`flex items-start justify-between w-full grow gap-16 ${screenMeasure}`}
        >
          <div className="flex flex-col grow w-full gap-6 mb-10">
            <PortfoliosOverview />
          </div>
        </div>
        <BottomBar>
          <Button
            onClick={() => navigate(`/onboarding/showroom-structure`)}
            theme="TERTIARY"
          >
            <HiChevronLeft className="w-6 h-6" />
            Showroom structure
          </Button>
          <Button
            onClick={() =>
              OrganizationAPI.finishOnboarding(organization.id)
                .then(() => {
                  setOrganization({
                    ...organization,
                    onboardingStatus: "DONE",
                  });
                })
                .then(() => {
                  navigate("/dashboard");
                })
            }
            theme="SUCCESS"
          >
            Finish onboarding
            <HiCheck className="w-6 h-6" />
          </Button>
        </BottomBar>
      </div>
    </div>
  );
}
