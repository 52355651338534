import { useMutation } from "@tanstack/react-query";

import { ContactDetails } from "@models/old/Contact";
import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

export const putArchiveContactEndpoint = {
  path: (organizationId: Organization["id"], contactId: ContactDetails["id"]) =>
    `/organizations/${organizationId}/contacts/${contactId}/archive`,
};

export function usePutArchiveContact(organizationId: string) {
  return useMutation({
    mutationFn: (contactId: string) =>
      axiosInstance.put(
        putArchiveContactEndpoint.path(organizationId, contactId),
      ),
  });
}
