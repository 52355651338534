export function mapZodError(map: Record<string, string>) {
  const replacePlaceholders = (p: (string | number)[]) => {
    let parsed = p;
    // replace numbers with %d
    parsed = parsed.map((seg) => (/^\d+$/.test(`${seg}`) ? "%d" : seg));
    return parsed;
  };

  return (error: { message: string; path: (string | number)[] }) => {
    const pathStr = replacePlaceholders(error.path).join(".");
    const message = map[pathStr] || error.message;
    return message;
  };
}
