import React from "react";

import { getYear } from "date-fns";

import SingleSelect, {
  SingleSelectProps,
} from "@components/data-entry/SingleSelect";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import { ShowroomSeason, ShowroomSeasonList } from "@models/types/enums";

// get next 2 years
const currentYear = getYear(new Date());
const years = [currentYear, currentYear + 1, currentYear + 2];

// combine season and year
export function combineYearAndSeason(season: ShowroomSeason, year: number) {
  return `${season} ${year}`;
}
export function extractYearAndSeason(str: string) {
  const [season, year] = str.split(" ") as [ShowroomSeason, number];
  return {
    season,
    year: Number(year),
  };
}

const seasons = years.flatMap((year) =>
  ShowroomSeasonList.map((season) => combineYearAndSeason(season, year)),
);

// map to option
const mapToOption = (value: string) => ({
  label: value,
  value,
});
const seasonOptions: SelectOptionBase[] = seasons.map(mapToOption);

interface SeasonSelectProps
  extends Omit<SingleSelectProps, "value" | "onChange"> {
  value: {
    season: ShowroomSeason | null;
    year: number | null;
  };
  onChange: (season: ShowroomSeason, year: number) => void;
}

export default function SeasonSelect({
  value,
  onChange,
  ...rest
}: SeasonSelectProps) {
  const realValue = mapToOption(
    combineYearAndSeason(value.season || "PRE_AW", value.year || currentYear),
  );
  return (
    <SingleSelect
      value={realValue}
      options={seasonOptions}
      onChange={(seasonOption) => {
        if (seasonOption) {
          const { season, year } = extractYearAndSeason(seasonOption.value);
          onChange(season, year);
        }
      }}
      {...rest}
    />
  );
}
