import React from "react";

import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";

import Button from "@components/data-entry/Button";
import MultiSelect from "@components/data-entry/MultiSelect";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import BottomBar from "@components/layout/BottomBar";
import { Contact } from "@models/Contact";

type FormValues = {
  contacts: SelectOptionBase[];
};

interface UpdateAttendeesFormProps {
  selectedContacts:
    | Pick<Contact, "id" | "email" | "firstName" | "lastName" | "phoneNumber">[]
    | undefined;
  invitedColleagues: Pick<
    Contact,
    "id" | "email" | "firstName" | "lastName" | "phoneNumber"
  >[];
  handleCancel: () => void;
  onSubmit: (contactIds: string[]) => void;
}

export default function UpdateAttendeesForm({
  selectedContacts,
  invitedColleagues,
  handleCancel,
  onSubmit,
}: UpdateAttendeesFormProps) {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    defaultValues: {
      contacts:
        selectedContacts &&
        selectedContacts.map<SelectOptionBase>((c) => ({
          label: c.firstName,
          value: c.id,
        })),
    },
  });

  const onSubmitForm = (data: FormValues) => {
    onSubmit(data.contacts.map((c) => c.value));
  };

  return selectedContacts && selectedContacts.length > 0 ? (
    <form
      className="flex flex-col h-full grow"
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <div className="py-10 px-10 flex flex-col gap-10 grow">
        <h2 className="heading-2-mobile lg:heading-2">
          {t("Booking.dashboard.update-attendees.title")}
        </h2>
        <div>{t("Booking.dashboard.update-attendees.description")}</div>
        <div className="flex flex-col gap-6">
          <div>
            <label className="font-medium" htmlFor="contacts">
              {t("Booking.dashboard.update-attendees.field-label")}
            </label>
            <div className="max-w-sm mt-2">
              <Controller
                name="contacts"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <MultiSelect
                    id="contacts"
                    name="contacts"
                    onChange={onChange}
                    value={value}
                    options={invitedColleagues.map<SelectOptionBase>((c) => ({
                      label: c.firstName,
                      value: c.id,
                    }))}
                    className={error && "border-primaryRed"}
                  />
                )}
              />
              {errors.contacts && errors.contacts.type === "required" && (
                <p className="text-xs italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <BottomBar theme="WHITE">
        <Button type="button" onClick={handleCancel}>
          {t("Components.buttons.cancel")}
        </Button>
        <Button theme="PRIMARY">{t("Components.buttons.update")}</Button>
      </BottomBar>
    </form>
  ) : (
    <div aria-label="contact-not-found">Contacts not found</div>
  );
}
