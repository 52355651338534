import { z } from "zod";

export const competitorsGroupSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  organizationId: z.string().uuid(),
  accounts: z.array(
    z.object({
      id: z.string().uuid(),
      name: z.string().optional(),
    }),
  ),
});

export type CompetitorsGroup = z.infer<typeof competitorsGroupSchema>;
