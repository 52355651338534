import React from "react";

import OrganizationInformationForm, {
  OrganizationInformationFormData,
  useOrganizationInformationForm,
} from "@shared/organization/organization-information-form";

interface OnboardingFormProps
  extends ReturnType<typeof useOrganizationInformationForm> {
  admin?: boolean;
  existing?: OrganizationInformationFormData;
  onSubmit: (
    onboardingInformation: OrganizationInformationFormData,
  ) => Promise<void>;
  className?: string;
}

function OnboardingForm(props: OnboardingFormProps) {
  return (
    <OrganizationInformationForm brandsOptions={[]} admin={false} {...props} />
  );
}

export default OnboardingForm;
