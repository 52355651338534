import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { Organization } from "@models/Organization";
import { representativeSchema } from "@models/OrganizationRepresentative";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export namespace SearchRepresentativesByEmail {
  export const outputSchema = z.array(
    representativeSchema.pick({
      id: true,
      firstName: true,
      lastName: true,
      email: true,
      status: true,
      invitationStatus: true,
      languages: true,
      markets: true,
      phoneNumber: true,
      position: true,
      role: true,
      virtualMeetingAppLinks: true,
    }),
  );

  export type Output = z.infer<typeof outputSchema>;

  export interface HookParams {
    organizationId: Organization["id"];
    email: string;
  }

  export function path({ organizationId, email }: HookParams) {
    return `/organizations/${organizationId}/representatives/search-by-email/${email}`;
  }

  export const getQueryKeys = (p: HookParams) => getAPIQueryKey(path(p));

  export function call(params: HookParams) {
    return axiosInstance
      .get<Output>(path(params))
      .then((r) => outputSchema.parse(r.data));
  }

  export function useHook(params: HookParams) {
    return useQuery({
      queryKey: getQueryKeys(params),
      queryFn: () => call(params),
      enabled: !!params.email,
    });
  }
}
