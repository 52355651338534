/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

import { HiCheck, HiExclamation, HiLightBulb, HiX } from "react-icons/hi";

import { addAccessControlProps } from "@shared/components/access-control";

type CalloutType = "INFORMATIVE" | "WARNING" | "ERROR" | "SUCCESS";

interface CalloutBoxProps {
  type?: CalloutType;
  className?: string;
  children: React.ReactNode;
}

function Box({
  type,
  children,
}: {
  type: CalloutType;
  children: React.ReactNode;
}) {
  switch (type) {
    case "ERROR":
      return (
        <div className="p-4 border rounded-lg bg-statusRedLight border-statusRedDark">
          {children}
        </div>
      );
    case "SUCCESS":
      return (
        <div className="p-4 border rounded-lg bg-statusGreenLight border-statusGreenDark">
          {children}
        </div>
      );
    case "WARNING":
      return (
        <div className="p-4 border rounded-lg bg-statusOrangeLight border-statusOrangeDark">
          {children}
        </div>
      );
    case "INFORMATIVE":
    default:
      return (
        <div className="p-4 border rounded-lg bg-statusBlueLight border-statusBlueDark">
          {children}
        </div>
      );
  }
}

function Icon({ type }: { type: CalloutType }) {
  switch (type) {
    case "ERROR":
      return (
        <div
          aria-label="calloutbox-error-icon"
          className="p-2 lg:p-4 rounded-full bg-statusRed"
        >
          <HiX className="w-6 h-6 fill-statusRedDark" />
        </div>
      );
    case "SUCCESS":
      return (
        <div
          aria-label="calloutbox-success-icon"
          className="p-2 lg:p-4 rounded-full bg-statusGreen"
        >
          <HiCheck className="w-6 h-6 fill-statusGreenDark" />
        </div>
      );
    case "WARNING":
      return (
        <div
          aria-label="calloutbox-warning-icon"
          className="p-2 lg:p-4 rounded-full bg-statusOrange"
        >
          <HiExclamation className="w-6 h-6 fill-statusOrangeDark" />
        </div>
      );
    case "INFORMATIVE":
    default:
      return (
        <div
          aria-label="calloutbox-informative-icon"
          className="p-2 lg:p-4 rounded-full bg-statusBlue"
        >
          <HiLightBulb className="w-6 h-6 fill-statusBlueDark" />
        </div>
      );
  }
}

function CalloutBox(props: CalloutBoxProps) {
  const { type = "INFORMATIVE", className, children } = props;

  return (
    <div aria-label={`calloutbox-of-${type}`} className={className}>
      <Box type={type || "INFORMATIVE"}>
        <div className="flex items-center gap-6">
          <Icon type={type || "INFORMATIVE"} />
          <div className="w-full">{children}</div>
        </div>
      </Box>
    </div>
  );
}

export default addAccessControlProps(CalloutBox);
