import { Appointment } from "@models/Appointment";
import { Representative } from "@models/OrganizationRepresentative";
import { VirtualMeetingApps } from "@models/types/enums";

type Appt = Pick<Appointment, "format" | "virtualMeetingApp"> & {
  seller: Pick<Representative, "virtualMeetingAppLinks">;
};

export default function getAppointmentVirtualLink(appt: Appt): string | false {
  const {
    format,
    seller: { virtualMeetingAppLinks: sellerLinks },
    virtualMeetingApp,
  } = appt;
  const app = virtualMeetingApp;

  if (format !== "VIRTUAL") {
    return false;
  }

  // app && links should be defined
  if (!sellerLinks) {
    return false;
  }
  if (!app) {
    return false;
  }

  return (
    sellerLinks[
      app === "ANY" ? (Object.keys(sellerLinks)[0] as VirtualMeetingApps) : app
    ] || false
  );
}
