import React, { useMemo } from "react";

import {
  addHours,
  addMinutes,
  format,
  isAfter,
  isBefore,
  startOfDay,
  subMinutes,
} from "date-fns";

import SingleSelect from "@components/data-entry/SingleSelect";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import { formatTime } from "@helpers/Date";
import {
  SLOT_DURATION,
  START_OF_DAY,
  TOTAL_HOURS,
} from "@models/types/Constant";

/**
 * Create a list of time options
 * @param {Date} day - in case to use a specific date to match the times
 * @param {number = 7} firstHourOfDay - start hour of the time options
 * @param {number = 12} totalHours - total de hours the options will be multiplied
 * @param {number = 15} timeStepInMinutes - total the steps in minutes used to multiple one hour
 * @param {Date} minDate - constrain to minimal time start of the time options
 * @param {Date} maxDate - constrain to maximal time start of the time options
 * @returns {SelectOptionBase[]} options created by the set of parameters
 */
export const createOptions = (
  day: Date,
  firstHourOfDay: number,
  totalHours: number,
  timeStepInMinutes: number,
  minDate?: Date | null,
  maxDate?: Date | null,
) => {
  const options: SelectOptionBase[] = [];
  const totalSteps = (60 / timeStepInMinutes) * totalHours;
  let time = addHours(startOfDay(day), firstHourOfDay);

  let minDateTime = minDate;
  if (minDate) {
    minDateTime = addMinutes(
      addHours(startOfDay(day), minDate.getHours()),
      minDate.getMinutes(),
    );
  }

  let maxDateTime = maxDate;
  if (maxDate) {
    maxDateTime = addMinutes(
      addHours(startOfDay(day), maxDate.getHours()),
      maxDate.getMinutes(),
    );
  }

  for (let i = 0; i <= totalSteps; i++) {
    const isSkip =
      (minDateTime &&
        isAfter(minDateTime, subMinutes(time, timeStepInMinutes))) ||
      (maxDateTime && isBefore(maxDateTime, time));

    if (!isSkip) {
      const label = format(time, "HH:mm");
      options.push({
        label,
        value: time.toISOString(),
      });
    }

    time = addMinutes(time, timeStepInMinutes);
  }

  return options;
};

export type TimePickerOptions = {
  firstHourOfDay: number;
  totalHours: number;
  timeStep: number;
};

interface TimePickerProps {
  id?: string;
  name: string;
  day?: Date;
  placeholder?: string;
  defaultValue?: Date | null;
  minTime?: Date | null;
  maxTime?: Date | null;
  onChange(date: Date | null): void;
  onBlur?(): void;
  options?: TimePickerOptions;
  className?: string;
  nullable?: boolean;
  disabled?: boolean;
  "aria-labelledby"?: string;
}

function TimePicker(props: TimePickerProps) {
  const {
    id,
    name,
    day = new Date(0),
    defaultValue: value,
    minTime,
    maxTime,
    onChange,
    onBlur,
    placeholder = "Select time",
    options: timePickerOptions,
    className,
    nullable = false,
    disabled,
    "aria-labelledby": ariaLabelledBy,
  } = props;

  const { firstHourOfDay, totalHours, timeStep } = timePickerOptions || {
    firstHourOfDay: START_OF_DAY,
    totalHours: TOTAL_HOURS,
    timeStep: SLOT_DURATION,
  };

  const defaultValue = value && {
    label: format(value, "HH : mm"),
    value,
  };

  const options = useMemo(
    () =>
      createOptions(
        day,
        firstHourOfDay,
        totalHours,
        timeStep,
        minTime,
        maxTime,
      ),
    [day, firstHourOfDay, maxTime, minTime, timeStep, totalHours],
  );

  if (options.length <= 0 || nullable) {
    options.unshift({
      label: "-- : --",
      value: "",
    });
  }

  return (
    <SingleSelect
      disabled={disabled}
      id={id}
      name={name}
      className={className}
      placeholder={placeholder}
      onChange={(v) => onChange(v && v.value ? new Date(v.value) : null)}
      onBlur={onBlur}
      options={options}
      aria-labelledby={ariaLabelledBy}
      value={
        defaultValue
          ? {
              label: formatTime(defaultValue.value),
              value: defaultValue.value.toISOString(),
            }
          : undefined
      }
    />
  );
}

export default TimePicker;
