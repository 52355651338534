import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Account } from "@models/Account";
import { Appointment } from "@models/Appointment";
import { Organization } from "@models/Organization";
import { Showroom } from "@models/Showroom";

import { GetAccount } from "../accounts/get-account";
import axiosInstance from "../config";
import { GetAppointment } from "./get-appointment";

export namespace PostMeetingReport {
  interface HookParams {
    organizationId: Organization["id"];
    appointmentId: Appointment["id"];
    accountId: Account["id"];
    showroomId: Showroom["id"];
  }

  interface MutationParams {
    otb: number | null;
    actualBudget: number | null;
    notes: string | null;
  }

  type Params = HookParams & MutationParams;

  export const path = ({ organizationId, appointmentId }: Params) =>
    `/organizations/${organizationId}/appointments/${appointmentId}/meeting-report`;

  export const call = (pathParams: Params) =>
    axiosInstance
      .post(path(pathParams), pathParams)
      .then((response) => response.data);

  export function useHook(params: HookParams) {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: ({ otb, actualBudget, notes }: MutationParams) =>
        call({
          ...params,
          otb,
          actualBudget,
          notes,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: GetAccount.getQueryKeys(params),
        });
        const calendarQueryKeys = GetAppointment.queryKeys({
          organizationId: params.organizationId,
          appointmentId: params.appointmentId,
        });
        queryClient.invalidateQueries({
          queryKey: calendarQueryKeys.slice(0, -2),
        });
      },
    });
  }
}
