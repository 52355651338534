import React, { PropsWithChildren } from "react";

interface DashboardPanelProps extends PropsWithChildren<{}> {
  className?: string;
}

export function DashboardPanel({ children, className }: DashboardPanelProps) {
  return (
    <div
      style={{ boxShadow: "0px 0px 16px 0px rgba(45, 42, 54, 0.08)" }}
      className={`bg-white p-6 mb-4 rounded-lg ${className}`}
    >
      {children}
    </div>
  );
}
