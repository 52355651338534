import { z } from "zod";

export const addressSchema = z.object({
  formattedAddress: z.string(),
  postalCode: z.string(),
  city: z.string(),
  countryCode: z.string(),
  addressComponents: z.any().optional(),
});

export type Address = z.infer<typeof addressSchema>;
