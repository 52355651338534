import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import axiosInstance from "@services/api/config";

export namespace InvitationSendFollowupsEndpoint {
  export const inputSchema = z.object({
    contactIds: z.array(z.string()),
    showroomIds: z.array(z.string()),
  });
  export type Input = z.infer<typeof inputSchema>;

  export const outputSchema = z.object({});
  export type Output = z.infer<typeof outputSchema>;

  interface HookParams {
    organizationId: string;
  }

  interface MutationParams {}

  type Params = HookParams & MutationParams;

  export const path = ({ organizationId }: Params) =>
    `/organizations/${organizationId}/invitations/send-followups`;

  export const call = (params: Params) =>
    axiosInstance
      .post(path(params), inputSchema.parse(params))
      .then((res) => res.data);

  export function useHook(hookParams: HookParams) {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
          ...hookParams,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [],
        });
      },
    });
  }
}
