import { areIntervalsOverlapping, isAfter, isBefore, isEqual } from "date-fns";
import addMinutes from "date-fns/addMinutes";
import differenceInMinutes from "date-fns/differenceInMinutes";

import { DayParams } from "@calendar/helpers/day-params";
import { Calendar } from "@calendar/types";
import { SLOT_DURATION } from "@models/types/Constant";
import { DateInterval } from "@models/types/DateInterval";

interface TimeSlot {
  startTime: Date;
}

export type EmptyCell = TimeSlot & { cellType: "available" };
const emptyCell = (startTime: Date): EmptyCell => ({
  startTime,
  cellType: "available",
});

export type LunchCell = TimeSlot & { cellType: "lunch" };
const lunchCell = (startTime: Date): LunchCell => ({
  startTime,
  cellType: "lunch",
});

export type BeforeOpeningCell = TimeSlot & { cellType: "before_opening" };
const beforeOpeningCell = (startTime: Date): BeforeOpeningCell => ({
  startTime,
  cellType: "before_opening",
});

export type AfterClosingCell = TimeSlot & { cellType: "after_closing" };
const afterClosingCell = (startTime: Date): AfterClosingCell => ({
  startTime,
  cellType: "after_closing",
});

export type ClosedDayCell = TimeSlot & { cellType: "closed_day" };
const closedDay = (startTime: Date): ClosedDayCell => ({
  startTime,
  cellType: "closed_day",
});

export type AppointmentCell = TimeSlot & {
  cellType: "appointment";
  rowSpans: number;
  appointment: Calendar.AccountAppointment;
};

export type BusyCell = TimeSlot & {
  cellType: "busy";
  rowSpans: number;
  appointment: Calendar.BusyAppointment;
};

const appointmentCell = (
  appointment: Calendar.AccountAppointment,
  rowSpans: number,
): AppointmentCell => ({
  startTime: appointment.startTime.toLocalDate(appointment.showroom.timezone),
  cellType: "appointment",
  rowSpans,
  appointment,
});
const busyCell = (
  appointment: Calendar.BusyAppointment,
  rowSpans: number,
): BusyCell => ({
  startTime: appointment.startTime.toLocalDate(appointment.showroom.timezone),
  cellType: "busy",
  rowSpans,
  appointment,
});

export type CalendarCell =
  | AppointmentCell
  | BusyCell
  | EmptyCell
  | LunchCell
  | BeforeOpeningCell
  | AfterClosingCell
  | ClosedDayCell;

export interface CalendarColumn {
  numberOfRows: number;
  cells: CalendarCell[];
}

const isDuringLunchTime = (currentTime: Date, lunch: DateInterval) =>
  (isEqual(currentTime, lunch.start) || isAfter(currentTime, lunch.start)) &&
  isBefore(currentTime, lunch.end);

const isBeforeOpeningTime = (currentTime: Date, openingTime: Date) =>
  isBefore(currentTime, openingTime);

const isAfterClosingTime = (currentTime: Date, closingTime: Date) =>
  isEqual(currentTime, closingTime) || isAfter(currentTime, closingTime);

const buildCells = (
  appointments: Calendar.Appointment[],
  numberOfRows: number,
  dayParams: DayParams,
): CalendarCell[] => {
  const appointmentsByStartTime = new Map<string, Calendar.Appointment[]>();
  appointments.forEach((appointment) => {
    const key = appointment.startTime
      .toLocalDate(appointment.showroom.timezone)
      .toISOString();
    const value = appointmentsByStartTime.get(key) || [];
    value.push(appointment);
    appointmentsByStartTime.set(key, value);
  });

  const cells: CalendarCell[] = [];
  let bookedBusySlots: number = 0;
  let bookedAccountSlots: number = 0;
  for (let index = 0; index < numberOfRows; index++) {
    const currentRangeStart = addMinutes(
      dayParams.calendar.start,
      SLOT_DURATION * index,
    );
    const currentRangeEnd = addMinutes(currentRangeStart, SLOT_DURATION);

    const appointmentsOverlappingThisRange = appointments.filter((a) =>
      areIntervalsOverlapping(
        { start: currentRangeStart, end: currentRangeEnd },
        {
          start: a.startTime.toLocalDate(a.showroom.timezone),
          end: a.endTime.toLocalDate(a.showroom.timezone),
        },
      ),
    );
    const accountAppointmentOverlappingThisRange =
      appointmentsOverlappingThisRange.find(Calendar.checkIsAccountAppointment);
    const busyAppointmentOverlappingThisRange =
      appointmentsOverlappingThisRange.find(Calendar.checkIsBusyAppointment)!;

    if (accountAppointmentOverlappingThisRange && bookedAccountSlots === 0) {
      const rowSpans =
        differenceInMinutes(
          accountAppointmentOverlappingThisRange.endTime.toLocalDate(
            accountAppointmentOverlappingThisRange.showroom.timezone,
          ),
          currentRangeStart,
        ) / SLOT_DURATION;
      bookedAccountSlots += rowSpans;

      cells.push(
        appointmentCell(accountAppointmentOverlappingThisRange, rowSpans),
      );
    } else if (busyAppointmentOverlappingThisRange && bookedBusySlots === 0) {
      const rowSpans =
        differenceInMinutes(
          busyAppointmentOverlappingThisRange.endTime.toLocalDate(
            busyAppointmentOverlappingThisRange.showroom.timezone,
          ),
          currentRangeStart,
        ) / SLOT_DURATION;
      bookedBusySlots += rowSpans;
      cells.push(busyCell(busyAppointmentOverlappingThisRange, rowSpans));
    } else if (dayParams.type === "closed_day") {
      cells.push(closedDay(currentRangeStart));
    } else {
      const openingTime =
        dayParams.customOpeningHour || dayParams.showroom.start;
      const closingTime = dayParams.customClosingHour || dayParams.showroom.end;
      if (
        dayParams.lunch &&
        isDuringLunchTime(currentRangeStart, dayParams.lunch)
      ) {
        cells.push(lunchCell(currentRangeStart));
      } else if (isBeforeOpeningTime(currentRangeStart, openingTime)) {
        cells.push(beforeOpeningCell(currentRangeStart));
      } else if (isAfterClosingTime(currentRangeStart, closingTime)) {
        cells.push(afterClosingCell(currentRangeStart));
      } else {
        cells.push(emptyCell(currentRangeStart));
      }
    }
    bookedAccountSlots -= bookedAccountSlots > 0 ? 1 : 0;
    bookedBusySlots -= bookedBusySlots > 0 ? 1 : 0;
  }

  return cells;
};

export function buildCalendarColumn(
  appointments: Calendar.Appointment[],
  dayParams: DayParams,
): CalendarColumn {
  const numberOfRows =
    differenceInMinutes(dayParams.calendar.end, dayParams.calendar.start) /
    SLOT_DURATION;

  const cells = buildCells(appointments, numberOfRows, dayParams);

  return {
    numberOfRows,
    cells,
  };
}
