/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable prettier/prettier */
// import "./wdyr";
import React from "react";

import ReactDOM from "react-dom/client";

import App from "./App";
import "./i18n";
import "./styles/index.css";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <App />,
);
