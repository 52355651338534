import React from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import Button from "@components/data-entry/Button";
import TextField from "@components/data-entry/TextField";
import BottomBar from "@components/layout/BottomBar";
import { Contact } from "@models/Contact";
import { BookingMutations } from "@services/api/old/booking/BookingAPI";
import fetchBookingEndpoint from "@services/api/old/booking/fetch-booking";
import { useError } from "@services/error/error-boundary";

type FormValues = {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
};

interface ContactInformationFormProps {
  invitationId: string;
  contact: Pick<
    Contact,
    "firstName" | "lastName" | "phoneNumber" | "id" | "email"
  >;
  handleCancel: () => void;
  onSuccess: () => void;
}

export default function ContactInformationForm({
  invitationId,
  contact,
  handleCancel,
  onSuccess,
}: ContactInformationFormProps) {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    defaultValues: {
      firstName: contact.firstName,
      lastName: contact.lastName,
      phoneNumber: contact.phoneNumber || undefined,
    },
  });

  const queryClient = useQueryClient();
  const { throwError } = useError();
  const { mutateAsync: updateContactMutation, isPending: isLoading } =
    useMutation({
      ...BookingMutations.updateContact(),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: fetchBookingEndpoint.query(invitationId).queryKey,
        });
      },
    });

  const onSubmitForm = (data: FormValues) => {
    updateContactMutation({
      invitationId,
      contactId: contact.id,
      formData: { ...data },
    })
      .then(() => {
        onSuccess();
        toast.success(t("Booking.contact.edit.updated"));
      })
      .catch((err) => {
        throwError(err);
      });
  };
  return contact.id ? (
    <form
      className="flex flex-col h-full"
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <div className="py-10 px-10 flex flex-col gap-10 grow">
        <div>{t("Booking.contact.edit.description")}</div>
        <div className="flex flex-col gap-6">
          <div>
            <label className="font-medium" htmlFor="firstName">
              {t("Booking.contact.edit.firstname")}
            </label>
            <div className="max-w-sm mt-2">
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({
                  fieldState: { error },
                  field: { onChange, value },
                }) => (
                  <TextField
                    id="firstName"
                    placeholder={t("Booking.contact.edit.firstname")}
                    value={value}
                    onChange={onChange}
                    hasError={!!error}
                  />
                )}
              />
              {errors.firstName && errors.firstName.type === "required" && (
                <p className="text-xs italic text-primaryRed">
                  {t("Booking.contact.edit.firstname.required")}
                </p>
              )}
            </div>
          </div>
          <div>
            <label className="font-medium" htmlFor="lastName">
              {t("Booking.contact.edit.lastname")}
            </label>
            <div className="max-w-sm mt-2">
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({
                  fieldState: { error },
                  field: { onChange, value },
                }) => (
                  <TextField
                    id="lastName"
                    placeholder={t("Booking.contact.edit.lastName")}
                    value={value}
                    onChange={onChange}
                    hasError={!!error}
                  />
                )}
              />
              {errors.lastName && errors.lastName.type === "required" && (
                <p className="text-xs italic text-primaryRed">
                  {t("Booking.contact.edit.lastName.required")}
                </p>
              )}
            </div>
          </div>
          <div>
            <label className="font-medium flex flex-col" htmlFor="email">
              {t("Booking.contact.edit.email")}
              <span className="font-normal text-xs">
                {t("Booking.contact.edit.email.description")}
              </span>
            </label>
            <div className="max-w-sm mt-2">
              <TextField id="email" value={contact.email} disabled />
            </div>
          </div>
          <div>
            <label className="font-medium" htmlFor="phoneNumber">
              {t("Booking.contact.edit.phonenumber")}
            </label>
            <div className="max-w-sm mt-2">
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="phoneNumber"
                    placeholder={t("Booking.contact.edit.phonenumber")}
                    value={value || undefined}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <BottomBar theme="WHITE">
        <Button type="button" disabled={isLoading} onClick={handleCancel}>
          {t("Components.buttons.cancel")}
        </Button>
        <Button theme="PRIMARY" loading={isLoading}>
          {t("Components.buttons.update")}
        </Button>
      </BottomBar>
    </form>
  ) : (
    <div aria-label="contact-not-found">Contact id not found</div>
  );
}
