import { RealDate, ZonedDate } from "@helpers/Date";
import Showroom from "@models/old/Showroom";
import { AppointmentTypeEnum } from "@models/types/enums";
import axiosInstance from "@services/api/config";
import { Endpoint, Params } from "@services/api/helper";

export type GetShowroomAppointmentsParams = Params<[string, Showroom["id"]]>;

export type AppointmentSerialized = {
  sellerId: string;
  isCompetitor: boolean;
  startTime: string;
  endTime: string;
  type: AppointmentTypeEnum;
};

export type AppointmentUnserialized = {
  sellerId: string;
  isCompetitor: boolean;
  startTime: ZonedDate;
  endTime: ZonedDate;
  type: AppointmentTypeEnum;
};

function unserialize(appt: AppointmentSerialized): AppointmentUnserialized {
  return {
    sellerId: appt.sellerId,
    isCompetitor: appt.isCompetitor,
    startTime: new ZonedDate(new Date(appt.startTime) as RealDate),
    endTime: new ZonedDate(new Date(appt.endTime) as RealDate),
    type: appt.type,
  };
}

const path = (...[invitationId, showroomId]: GetShowroomAppointmentsParams) =>
  `/bookings/${invitationId}/showrooms/${showroomId}/booked-appointments`;

const asyncCall = async (...params: GetShowroomAppointmentsParams) =>
  axiosInstance
    .get<AppointmentSerialized[]>(path(...params))
    .then((res) => res.data.map(unserialize));

const getShowroomAppointmentsEndpoint = new Endpoint<
  GetShowroomAppointmentsParams,
  AppointmentUnserialized[]
>(path, asyncCall, [
  "organization",
  "sales-campaigns",
  "showrooms",
  "appointments",
]);

export default getShowroomAppointmentsEndpoint;
