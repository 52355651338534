import React from "react";

import { Transition } from "@headlessui/react";

import HoverCard from "@components/data-display/HoverCard";
import {
  AccessControl,
  AddedAccessControlProps,
  addAccessControlProps,
} from "@shared/components/access-control";
import { ACL_EVERYONE } from "@shared/components/access-control/helpers";

import NavigationItem, { SideBarItemProps } from "./NavigationItem";
import { useSidebarContext } from "./context/sidebar";

function SideBarItem(props: SideBarItemProps) {
  const {
    label,
    icon,
    path,
    subItems,
    className,
    isExpanded = false,
    onClick,
    isSubItem = false,
  } = props;
  const { isFold } = useSidebarContext();

  return (
    <>
      {/* MENU ITEMS WITHOUT SUBITEMS */}
      {!subItems && (
        <NavigationItem
          label={label}
          path={path}
          icon={icon}
          className={className}
          isSubItem={isSubItem}
        />
      )}
      {/* MENU ITEMS WITH SUBITEMS WHEN MENUE IS FOLDED */}
      {subItems && isFold && (
        <HoverCard
          button={
            <NavigationItem
              onClick={onClick}
              label={label}
              path={path}
              icon={icon}
              className={className}
              subItems={subItems}
            />
          }
          placement="right-start"
          buttonClassName="w-full"
        >
          {/* EXPANDED SUBITEMS WHEN MENUE IS FOLDED */}
          {isFold && (
            <Transition
              appear
              show
              as="ul"
              className="flex flex-col gap-1 items-start w-full"
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {subItems.map((item) => (
                <Transition.Child
                  key={`sidebar_${path}_${item.path}`}
                  className="flex flex-col items-start w-full"
                  as="li"
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-y-full"
                  enterTo="translate-y-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-y-0"
                  leaveTo="-translate-y-full"
                >
                  <AccessControl
                    roles={item.aclRoles || ACL_EVERYONE}
                    fallback={item.aclFallback}
                    fallbackNotAuthenticated={item.aclFallbackNotAuthenticated}
                  >
                    <SideBarItem
                      {...item}
                      isExpanded={false}
                      className="text-primaryDarkGrey"
                      isSubItem
                    />
                  </AccessControl>
                </Transition.Child>
              ))}
            </Transition>
          )}
        </HoverCard>
      )}
      {/* MENU ITEMS WITH EXPANDED SUBITEMS WHEN MENUE IS NOT FOLDED */}
      {subItems && !isFold && (
        <>
          <NavigationItem
            isExpanded={isExpanded}
            onClick={onClick}
            label={label}
            path={path}
            icon={icon}
            className={className}
            subItems={subItems}
            isSubItem={isSubItem}
          />
          <Transition
            appear
            show={isExpanded}
            as="ul"
            className="flex flex-col gap-1 mt-1"
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {subItems.map((item) => (
              <Transition.Child
                key={`sidebar_${path}_${item.path}`}
                as="li"
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-y-full"
                enterTo="translate-y-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-y-0"
                leaveTo="-translate-y-full"
              >
                <AccessControl
                  roles={item.aclRoles || ACL_EVERYONE}
                  fallback={item.aclFallback}
                  fallbackNotAuthenticated={item.aclFallbackNotAuthenticated}
                >
                  <SideBarItem
                    {...item}
                    isExpanded={false}
                    className="py-1 pl-8 text-primaryDarkGrey"
                  />
                </AccessControl>
              </Transition.Child>
            ))}
          </Transition>
        </>
      )}
    </>
  );
}

export interface ACLSideBarItemProps
  extends SideBarItemProps,
    AddedAccessControlProps {}

export default addAccessControlProps(SideBarItem);
