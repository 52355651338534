/* eslint-disable react/no-unstable-nested-components */
import React from "react";

import { createColumnHelper } from "@tanstack/react-table";
import { flag } from "country-emoji";
import { t } from "i18next";

import HoverCard from "@components/data-display/HoverCard";
import MRTable from "@components/data-display/MRTable";
import Tag from "@components/data-display/Tag";
import Button from "@components/data-entry/Button";
import { UserRole } from "@models/types/enums";
import { GetContactsEndpoint } from "@services/api/contacts/get-contacts";
import { fullName } from "@shared/helpers/formatters";

const rolesWithAccess: UserRole[] = [
  "AGENT",
  "ORGANIZATION_ADMIN",
  "ORGANIZATION_MAIN_ADMIN",
  "ORGANIZATION_MANAGER",
];

interface ArchivedContactsTableProps {
  contacts: GetContactsEndpoint.Output;
  onClickRestore: (contact: GetContactsEndpoint.OutputItem) => void;
}

export default function ArchivedContactsTable({
  contacts,
  onClickRestore,
}: ArchivedContactsTableProps) {
  const columnHelper = createColumnHelper<GetContactsEndpoint.OutputItem>();

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        header: t("CRM.archived-contacts.table-header.name"),
        cell: ({ row: { original } }) => (
          <HoverCard
            renderIf={fullName(original).length > 20}
            fallbackProp="children"
            button={
              <div className="min-w-[10rem] max-w-[30rem] truncate">
                {fullName(original)}
              </div>
            }
          >
            <div className="min-w-[10rem]">{fullName(original)}</div>
          </HoverCard>
        ),
      }),
      columnHelper.display({
        header: t("CRM.archived-contacts.table-header.account"),
        cell: ({ row }) => {
          const {
            original: {
              account: { name },
            },
          } = row;

          return (
            <HoverCard
              renderIf={name.length > 20}
              fallbackProp="children"
              button={
                <div className="min-w-[10rem] max-w-[30rem] truncate">
                  {name}
                </div>
              }
            >
              <div className="min-w-[10rem]">{name}</div>
            </HoverCard>
          );
        },
      }),
      columnHelper.display({
        header: t("CRM.archived-contacts.table-header.origin"),
        cell: ({ row }) => {
          const {
            original: {
              account: { city, countryCode },
            },
          } = row;
          return (
            countryCode && (
              <Tag icon={flag(countryCode)} className="w-fit">
                {city}
              </Tag>
            )
          );
        },
      }),
      columnHelper.display({
        id: "actions",
        meta: {
          cellClassName: "sticky right-0 bg-white",
        },
        cell: ({ row }) => {
          const { id } = row.original;
          return (
            <div className="flex items-center justify-end mr-4">
              <Button
                aclRoles={rolesWithAccess}
                type="button"
                theme="PRIMARY"
                testId={`restore-contact-${id}`}
                onClick={() => onClickRestore(row.original)}
              >
                {t("CRM.archived-contacts.table-header.restore-button")}
              </Button>
            </div>
          );
        },
      }),
    ],
    [columnHelper, onClickRestore],
  );

  return (
    <MRTable
      data={contacts}
      columns={columns}
      cellClassName="border rounded-md"
      noDataLabel={t("CRM.archived-contacts.no-results")}
      displayDataLabel={t("CRM.archived-contacts.data-label")}
    />
  );
}
