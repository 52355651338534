import React from "react";

import { t } from "i18next";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { useMatch, useNavigate } from "react-router-dom";

import Tabs, { TabsProps, useTabs } from "@components/data-display/Tabs";
import Button from "@components/data-entry/Button";
import { useFetchRepresentativesArchivedCount } from "@services/api/old/representatives/fetch-representatives-archived-count";
import { useFetchRepresentativesCount } from "@services/api/old/representatives/fetch-representatives-count";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { ACL_ADMINS_MANAGERS } from "@shared/components/access-control/helpers";

interface RepresentativesHeaderProps {
  onOpenDrawer?: () => void;
}

export default function RepresentativesHeader({
  onOpenDrawer,
}: RepresentativesHeaderProps) {
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const navigate = useNavigate();
  const match = useMatch("/representatives/*");

  const currentFile =
    match && match?.params ? Object.values(match!.params)[0] : "";

  const { data: representativesCount } =
    useFetchRepresentativesCount(organizationId);
  const { data: archivedCount } =
    useFetchRepresentativesArchivedCount(organizationId);

  const tabs: TabsProps["items"] = [
    {
      label: t("Representatives.header.tabs.organization-representatives"),
      tab: "list",
      count: representativesCount?.count,
    },
    {
      label: t("Representatives.header.tabs.archived"),
      tab: "archived",
      count: archivedCount?.count,
      aclRoles: [
        "ORGANIZATION_MAIN_ADMIN",
        "ORGANIZATION_ADMIN",
        "ORGANIZATION_MANAGER",
        "AGENT",
      ],
    },
  ];

  const { tab: currentTab, setTab } = useTabs({
    initial: tabs.find((item) => item.tab === currentFile)?.tab,
  });

  return (
    <>
      <div className="flex flex-col justify-between gap-3 px-10 mt-12 md:flex-row">
        <h1 className="self-start heading-1">
          {t("Representatives.header.title")}
        </h1>
        <Button
          aclRoles={ACL_ADMINS_MANAGERS}
          type="button"
          theme="PRIMARY"
          className="self-end w-34"
          onClick={() => {
            if (onOpenDrawer && currentTab === currentFile) {
              onOpenDrawer();
            } else {
              navigate("/representatives/list", {
                state: "open-drawer",
              });
            }
          }}
        >
          {t("Representatives.header.add-new")}
          <HiOutlinePlusCircle className="w-6 h-6" />
        </Button>
      </div>
      <Tabs
        aria-label="Organization Representatives tabs"
        className="mt-10 mb-4 border-b"
        items={tabs}
        tab={currentTab}
        handleClick={(item) => {
          setTab(item.tab);
          navigate(`/representatives/${item.tab}`);
        }}
      />
    </>
  );
}
