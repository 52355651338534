import React from "react";

type Props = {};

export default function InlineTableFilterText({
  children,
}: React.PropsWithChildren<Props>) {
  return (
    <div className="last:rounded-r first:rounded-l bg-primaryLightestGrey p-1 px-2">
      {children}
    </div>
  );
}
