import React from "react";

import { useTranslation } from "react-i18next";

import { buildAvailableSlots } from "@booking/helpers/appointments";
import {
  combineDateAndTime,
  formatMinutes,
  formatTimeInterval,
} from "@helpers/Date";

type Props = {
  increment: number | undefined;
  collection: {
    name: string | undefined;
    duration: number;
  };
  openingHour: Date;
  closingHour: Date;
};

export default function AppointmentIncrementPreview({
  increment,
  openingHour,
  closingHour,
  collection,
}: Props) {
  const { t } = useTranslation();

  const from = combineDateAndTime(new Date(), openingHour);
  const to = combineDateAndTime(new Date(), closingHour);

  const slots = buildAvailableSlots(
    from,
    to,
    collection.duration,
    [],
    [],
    increment,
  );

  return (
    <div className="flex flex-col gap-2 border rounded border-primaryLightGrey p-2 bg-primaryLightestGrey">
      <p>
        <span>
          {t("SalesCampaign.edit.appointments.appointment-increment.preview")}
          <strong className="font-bold"> {collection.name} </strong>(
          {formatMinutes(collection.duration)})
        </span>
      </p>
      <div className="grid grid-cols-5 gap-1">
        {slots.slice(0, 5).map((s) => (
          <span
            key={s.start.toISOString()}
            className="text-sm text-center rounded border border-primaryElectricBlue bg-primaryLightElectricBlue text-primaryElectricBlue whitespace-nowrap"
          >
            {formatTimeInterval(s.start, s.end)}
          </span>
        ))}
      </div>
    </div>
  );
}
