import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import { collectionSchema } from "@models/Collection";
import { Organization } from "@models/Organization";
import { representativeSchema } from "@models/OrganizationRepresentative";
import { showroomSchema } from "@models/Showroom";
import { AccountAppointmentTypeList } from "@models/types/enums";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export namespace GetOngoingShowroomsEndpoint {
  export const outputSchema = z.array(
    showroomSchema
      .pick({
        id: true,
        organizationId: true,
        season: true,
        year: true,
        name: true,
        openingHour: true,
        closingHour: true,
        timezone: true,
        openingDays: true,
        specialRequestsAllowed: true,
        lunchBreakEndingHour: true,
        lunchBreakStartingHour: true,
        status: true,
      })
      .extend({
        sellers: z.array(
          representativeSchema
            .pick({
              firstName: true,
              id: true,
              languages: true,
              lastName: true,
              virtualMeetingAppLinks: true,
            })
            .extend({
              appointmentTypes: z.array(z.enum(AccountAppointmentTypeList)),
            }),
        ),
        collections: z.array(
          collectionSchema
            .pick({
              id: true,
              name: true,
            })
            .extend({
              brand: brandSchema.pick({
                id: true,
                name: true,
              }),
            }),
        ),
      }),
  );

  export type Output = z.infer<typeof outputSchema>;

  export interface HookParams {
    organizationId: Organization["id"];
  }

  export function path({ organizationId }: HookParams) {
    return `/organizations/${organizationId}/showrooms?ongoing`;
  }
  export const getQueryKeys = (p: HookParams) => getAPIQueryKey(path(p));

  export function call(params: HookParams) {
    return axiosInstance.get<Output>(path(params)).then((r) => {
      const d = outputSchema.safeParse(r.data);
      if (!d.success) {
        throw new Error(d.error.errors.map((e) => e.message).join(", "));
      }
      return d.data;
    });
  }

  export function useHook(params: HookParams) {
    return useQuery({
      queryKey: getAPIQueryKey(path(params)),
      queryFn: () => call(params),
    });
  }
}
