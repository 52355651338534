import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import UpsertRepresentativeForm from "@app/modules/representatives/components/upsert-representative-form";
import { PageTitle } from "@components/data-display/Page";
import Loading from "@components/feedback/Loading";
import { usePrefixedTranslation } from "@helpers/Translation";
import { GetRepresentativeEndpoint } from "@services/api/representatives/get-representative";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";

export default function RepresentativePage() {
  const { representativeId } = useParams();
  const { pt } = usePrefixedTranslation("Representatives.RepresentativePage");

  if (!representativeId) {
    throw new Error("no representative id given");
  }
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const navigate = useNavigate();

  const { data } = GetRepresentativeEndpoint.useHook({
    organizationId,
    representativeId,
  });

  return data ? (
    <>
      <PageTitle>{pt("title")}</PageTitle>
      <UpsertRepresentativeForm
        selectedRepresentative={data}
        onCancel={() => navigate("/representatives")}
        onSuccess={() => navigate("/representatives")}
        onError={toast.error}
      />
    </>
  ) : (
    <Loading type="screen" />
  );
}
