import React, { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { HiChevronLeft, HiChevronRight, HiRefresh } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import queryClient from "@app/queryClient";
import AdminOnboardingBreadcrumb from "@backoffice/components/onboarding/components/AdminOnboardingBreadcrumb";
import UploadError from "@backoffice/components/onboarding/components/UploadError";
import MRTable from "@components/data-display/MRTable";
import Button from "@components/data-entry/Button";
import FilesUploadInput, {
  FileToUpload,
} from "@components/data-entry/FilesUploadInput";
import CalloutBox from "@components/feedback/CalloutBox";
import ConfirmModal from "@components/feedback/ConfirmModal";
import Loading from "@components/feedback/Loading";
import BottomBar from "@components/layout/BottomBar";
import { ContactDetails } from "@models/old/Contact";
import { AdminOnboardingStep } from "@models/old/Organization";
import { UploadValidationError } from "@models/types/UploadValidationError";
import OrganizationAPI from "@services/api/old/organization/OrganizationAPI";

const getOrganizationQuery = (organizationId: string) => ({
  queryKey: ["get-organization", organizationId],
  queryFn: () => OrganizationAPI.getOrganization(organizationId),
});

const getOrganizationContactsQuery = (organizationId: string) => ({
  queryKey: ["get-organization-contacts", organizationId],
  queryFn: () => OrganizationAPI.getContacts(organizationId),
});

function AdminOnboardingContactUploadPage() {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  if (!organizationId) {
    throw new Error("AdminOnboardingContactUploadPage - no organizationId");
  }
  const {
    data: organization,
    isPending: isLoadingOrganization,
    isError: isErrorOrganization,
    error: errorOrganization,
  } = useQuery({
    ...getOrganizationQuery(organizationId),
  });

  const organizationContactsQuery =
    getOrganizationContactsQuery(organizationId);
  const { data: contacts, isFetching: isFetchingContacts } = useQuery({
    ...organizationContactsQuery,
    initialData: [],
  });

  const [error, setError] = useState<UploadValidationError[] | string | null>(
    null,
  );
  const [isRedo, setIsRedo] = useState<boolean>(false);

  const columnHelper = createColumnHelper<ContactDetails>();
  const columns = React.useMemo(
    () => [
      columnHelper.accessor("firstName", {
        header: () => "Firstname",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("lastName", {
        header: () => "Lastname",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("email", {
        header: () => "Email",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("phoneNumber", {
        header: () => "Phone",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("account.name", {
        header: () => "Account",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("position", {
        header: () => "Position",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("markets", {
        header: () => "Markets",
        cell: (info) => info.getValue().join(", "),
      }),
    ],
    [columnHelper],
  );

  const [fileList, setFileList] = React.useState<FileToUpload[]>([]);

  if (isLoadingOrganization) {
    return <Loading type="screen" />;
  }
  if (isErrorOrganization) {
    throw errorOrganization;
  }

  const handleFileChange = (files: FileToUpload[] | null) => {
    if (files === null || files.length === 0) {
      setFileList([]);
      setError(null);
    } else {
      OrganizationAPI.uploadContacts(organization.id, files[0].file)
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: organizationContactsQuery.queryKey,
          });
          setError(null);
          setFileList(files.map((file) => ({ ...file, status: "ok" })));
          toast.success("Organization contacts uploaded");
        })
        .catch((err) => {
          setFileList(files.map((file) => ({ ...file, status: "error" })));
          if (err.errors) {
            setError(err.errors);
          } else {
            setError(err.message);
          }
        });
    }
  };

  return (
    <div className="p-4">
      <AdminOnboardingBreadcrumb
        currentStep={AdminOnboardingStep.CONTACTS_UPLOAD}
      />
      <h1 className="heading-1">{organization.name}</h1>
      <CalloutBox className="my-4 w-2/4">
        Make sure that all the information in the file is written correctly and
        that there are no duplicates before importing it. Please import the
        three tabs of the file separately.
      </CalloutBox>
      <h3 className="font-bold">Organization Contacts</h3>
      {contacts.length === 0 && (
        <FilesUploadInput
          onFileChange={handleFileChange}
          parentFiles={fileList}
          authorizedFileExtension={[".csv"]}
          disabled={contacts.length !== 0}
          className="w-5/12 max-w-xl"
        />
      )}

      <div className="flex flex-col p-8 my-4 border rounded-lg bg-primaryLightElectricBlue item">
        {contacts.length === 0 && (
          <>
            <div>No contacts imported so far</div>
            {error && <UploadError type="Contacts" error={error} />}
          </>
        )}
        {contacts.length > 0 && (
          <>
            <div className="pb-2 text-primaryElectricBlue">
              {contacts.length} imported Contacts
            </div>
            <MRTable
              data={contacts}
              columns={columns}
              paginationPlacement="none"
            />
          </>
        )}
      </div>

      <BottomBar>
        <div>
          {contacts.length > 0 && (
            <Button
              onClick={() => {
                setIsRedo(true);
              }}
              theme="DANGER"
            >
              <HiRefresh className="w-6 h-6" />
              <span>Redo contacts</span>
            </Button>
          )}
        </div>
        <div className="flex justify-end gap-4">
          <Button
            onClick={() => {
              navigate(
                `/backoffice/onboard-organization/${organization.id}/accounts-upload`,
              );
            }}
            theme="TERTIARY"
          >
            <HiChevronLeft className="w-6 h-6" />
            <span>Import accounts</span>
          </Button>
          <Button
            disabled={isFetchingContacts || contacts.length === 0}
            onClick={() => {
              navigate(
                `/backoffice/onboard-organization/${organization.id}/representatives-upload`,
              );
            }}
            theme="PRIMARY"
          >
            <span>Next : import organization representatives</span>
            <HiChevronRight className="w-6 h-6" />
          </Button>
        </div>
      </BottomBar>

      <ConfirmModal
        show={isRedo}
        title="Delete all contacts ?"
        confirmLabel="Yes, redo"
        onCancel={() => {
          setIsRedo(false);
        }}
        onConfirm={() => {
          OrganizationAPI.redoUploadContacts(organization.id).then(() => {
            queryClient.invalidateQueries({
              queryKey: organizationContactsQuery.queryKey,
            });
            setIsRedo(false);
          });
        }}
      >
        <span>
          You are about to delete all imported contacts.
          <br />
          Are you sure of your choice?
        </span>
      </ConfirmModal>
    </div>
  );
}

export default AdminOnboardingContactUploadPage;
