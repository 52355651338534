import React from "react";

import { Transition } from "@headlessui/react";

export const fadeSlideInOutProps = {
  enter: "duration-200 ease-in-out transition-all transform",
  enterFrom: "-translate-y-2 opacity-0",
  enterTo: "translate-y-0 opacity-100",
  leave: "duration-200 ease-in-out transition-all transform",
  leaveFrom: "translate-y-0 opacity-100",
  leaveTo: "-translate-y-2 opacity-0",
};

export const fadeInOutProps = {
  enter: "transition-all ease-in-out duration-[350ms]",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "transition-all ease-in-out  duration-[350ms]",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0",
};

interface FadeInOutProps {
  show: boolean;
  children: React.ReactNode;
  onClose?: () => void;
  className?: string;
  beforeEnter?: () => void;
}
export default function FadeInOut({
  children,
  show,
  onClose,
  className,
  beforeEnter,
}: FadeInOutProps) {
  return (
    <Transition
      beforeEnter={beforeEnter}
      className={className}
      show={show}
      afterLeave={onClose}
      {...fadeInOutProps}
    >
      {children}
    </Transition>
  );
}
