import { useQuery } from "@tanstack/react-query";

import queryClient from "@app/queryClient";
import { Organization } from "@models/old/Organization";
import { Portfolio } from "@models/old/Portfolio";
import { SalesCampaign } from "@models/old/SalesCampaign";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export const fetchCollectionsWithCapabilitiesEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/collections/with-capabilities`,
};

export interface CollectionWithCapabilities {
  id: string;
  name: string;
  portfolios: Portfolio["name"][];
  updatingBlockedBy: SalesCampaign["name"][];
  archivingBlockedBy: SalesCampaign["name"][];
}

type FetchCollectionsWithCapabilitiesResponse = CollectionWithCapabilities[];

export const invalidateCollectionsWithCapabilitiesQuery = (
  organizationId: Organization["id"],
) =>
  queryClient.invalidateQueries({
    queryKey: getAPIQueryKey(
      fetchCollectionsWithCapabilitiesEndpoint.path(organizationId),
    ),
  });

export const useFetchCollectionsWithCapabilities = (organizationId: string) =>
  useQuery({
    queryKey: getAPIQueryKey(
      fetchCollectionsWithCapabilitiesEndpoint.path(organizationId),
    ),
    queryFn: () =>
      axiosInstance
        .get<FetchCollectionsWithCapabilitiesResponse>(
          fetchCollectionsWithCapabilitiesEndpoint.path(organizationId),
        )
        .then((res) => res.data),
  });
