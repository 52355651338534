/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

import { t } from "i18next";

interface ToggleProps {
  id?: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  showLabel?: boolean;
  disabled?: boolean;
}
function Toggle(props: ToggleProps) {
  const {
    id = undefined,
    checked,
    onChange,
    disabled = false,
    showLabel = false,
  } = props;

  return (
    <label
      htmlFor={id}
      className={`flex items-center gap-2  ${
        disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
      }`}
    >
      <div
        className={`flex items-center w-14 h-8 border-2 px-1 rounded-full select-none transition duration-200 ease-in ${
          checked ? "bg-primaryElectricBlue" : "bg-transparent"
        } ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
      >
        <input
          type="checkbox"
          className={`block w-5 h-5 rounded-full appearance-none transition-all duration-200 ${
            checked ? "ml-6 bg-white" : "ml-0 bg-primaryLightGrey border-white"
          } ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
          id={id}
          disabled={disabled}
          defaultChecked={checked}
          onChange={() => {
            onChange(!checked);
          }}
        />
      </div>
      {showLabel && <span>{checked ? t("Common.on") : t("Common.off")}</span>}
    </label>
  );
}

export default Toggle;
