import { z } from "zod";

import {
  RealDate,
  ZonedDate,
  localDateToUnzonedDate,
  localTimeToUnzonedTime,
  unzonedDateToLocalDate,
  unzonedTimeToLocalTime,
} from "@helpers/Date";

/**
 * Parses a date/time field from the API
 */
export const dateHydratingSchema = z
  .string()
  .datetime()
  .transform((v) => new Date(v));
export const dateSerializingSchema = z.date().transform((v) => v.toISOString());

/**
 * Parses a date field from the API
 */
export const localDateSerializingSchema = z
  .date()
  .transform((d) => localDateToUnzonedDate(d).toISOString());

/**
 * Parses a date field to send to the API
 */
export const localDateHydratingSchema = z
  .string()
  .datetime()
  .transform((s) => unzonedDateToLocalDate(s));

/**
 * Parses a time field from the API
 */
export const localTimeSerializingSchema = z
  .date()
  .transform((d) => localTimeToUnzonedTime(d).toISOString());

/**
 * Parses a time field to send to the API
 */
export const localTimeHydratingSchema = z
  .string()
  .datetime()
  .transform((s) => unzonedTimeToLocalTime(s));

/**
 * Parses a date+time (hence zoned) field from the API
 */
export const zonedDateHydratingSchema = z
  .string()
  .datetime()
  .transform((v) => new ZonedDate(new Date(v) as RealDate));

/**
 * Parses a date+time (hence zoned) field to send to the API
 */
export const zonedDateSerializingSchema = z
  .instanceof(ZonedDate)
  .transform((v) => v.realDate.toISOString());
