import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { accountSchema } from "@models/Account";
import { appointmentHydratingSchema } from "@models/Appointment";
import { collectionSchema } from "@models/Collection";
import { contactSchema } from "@models/Contact";
import { meetingReportSchema } from "@models/MeetingReport";
import {
  organizationRepresentativeSchema,
  representativeSchema,
} from "@models/OrganizationRepresentative";
import { portfolioSchema } from "@models/Portfolio";
import { showroomSchema } from "@models/Showroom";
import { VirtualMeetingAppsList } from "@models/types/enums";

import axiosInstance from "../config";
import { getAPIQueryKey } from "../helper";

export namespace GetAppointment {
  export type Params = {
    organizationId: string;
    appointmentId: string;
  };

  export const outputSchema = appointmentHydratingSchema.extend({
    showroom: showroomSchema.pick({
      id: true,
      formattedAddress: true,
      timezone: true,
      city: true,
      countryCode: true,
      season: true,
      year: true,
    }),
    collection: collectionSchema.pick({ id: true, name: true }).nullable(),
    account: accountSchema.pick({
      id: true,
      name: true,
      status: true,
      city: true,
      countryCode: true,
      isKeyClient: true,
    }),
    attendees: z.array(
      contactSchema.pick({
        id: true,
        firstName: true,
        lastName: true,
        email: true,
        markets: true,
        position: true,
        phoneNumber: true,
      }),
    ),
    seller: representativeSchema
      .pick({
        id: true,
        firstName: true,
        lastName: true,
        email: true,
        languages: true,
      })
      .extend({
        virtualMeetingAppLinks: z
          .record(z.enum(VirtualMeetingAppsList), z.string().optional())
          .nullable(),
      }),
    portfolios: z.array(
      portfolioSchema
        .pick({ id: true, name: true, color: true, collectionId: true })
        .extend({
          manager: representativeSchema.pick({
            id: true,
            firstName: true,
            lastName: true,
            role: true,
          }),
          sellers: z.array(
            organizationRepresentativeSchema.pick({
              id: true,
              firstName: true,
              lastName: true,
              role: true,
              virtualMeetingApps: true,
            }),
          ),
        }),
    ),
    meetingReport: meetingReportSchema
      .pick({
        otb: true,
        actualBudget: true,
        notes: true,
      })
      .nullable(),
  });
  export type Output = z.infer<typeof outputSchema>;

  export const path = ({ organizationId, appointmentId }: Params) =>
    `/organizations/${organizationId}/appointments/${appointmentId}`;

  export const call = (params: Params) =>
    axiosInstance
      .get<Output>(path(params))
      .then((response) => outputSchema.parse(response.data));

  export const queryKeys = (params: Params) => getAPIQueryKey(path(params));

  export function useHook(params: Params) {
    return useQuery({
      queryKey: queryKeys(params),
      queryFn: () => call(params),
    });
  }
}
