import {
  localDateToUnzonedDate,
  localTimeToUnzonedTime,
  unzonedDateToLocalDate,
  unzonedTimeToLocalTime,
} from "@helpers/Date";
import { Merge } from "@helpers/Object";
import Showroom from "@models/old/Showroom";

interface ShowroomApiOverride {
  openingHour: string;
  closingHour: string;
  lunchBreakStartingHour: string | null;
  lunchBreakEndingHour: string | null;
  openingDays: {
    day: string;
    keyAccountsOpeningHour: string | null;
    keyAccountsClosingHour: string | null;
    customOpeningHour: string | null;
    customClosingHour: string | null;
  }[];
  lastAllowedBookingDate: string | null;
}

type SerializedShowroom<T> = Merge<T, ShowroomApiOverride>;

export function serializeShowroom<
  T extends Pick<
    Showroom,
    | "openingHour"
    | "closingHour"
    | "lunchBreakStartingHour"
    | "lunchBreakEndingHour"
    | "openingDays"
    | "lastAllowedBookingDate"
  >,
>(showroom: T): SerializedShowroom<T> {
  const {
    openingHour,
    closingHour,
    lunchBreakStartingHour,
    lunchBreakEndingHour,
    openingDays,
    lastAllowedBookingDate,
    ...rest
  } = showroom;
  return {
    ...rest,
    openingHour: localTimeToUnzonedTime(openingHour).toISOString(),
    closingHour: localTimeToUnzonedTime(closingHour).toISOString(),
    lunchBreakStartingHour: lunchBreakStartingHour
      ? localTimeToUnzonedTime(lunchBreakStartingHour).toISOString()
      : null,
    lunchBreakEndingHour: lunchBreakEndingHour
      ? localTimeToUnzonedTime(lunchBreakEndingHour).toISOString()
      : null,
    openingDays: openingDays.map((openingDay) => ({
      keyAccountsOpeningHour: openingDay.keyAccountsOpeningHour
        ? localTimeToUnzonedTime(
            openingDay.keyAccountsOpeningHour,
          ).toISOString()
        : null,
      keyAccountsClosingHour: openingDay.keyAccountsClosingHour
        ? localTimeToUnzonedTime(
            openingDay.keyAccountsClosingHour,
          ).toISOString()
        : null,
      customOpeningHour: openingDay.customOpeningHour
        ? localTimeToUnzonedTime(openingDay.customOpeningHour).toISOString()
        : null,
      customClosingHour: openingDay.customClosingHour
        ? localTimeToUnzonedTime(openingDay.customClosingHour).toISOString()
        : null,
      day: localDateToUnzonedDate(openingDay.day).toISOString(),
    })),
    lastAllowedBookingDate: lastAllowedBookingDate
      ? localDateToUnzonedDate(lastAllowedBookingDate).toISOString()
      : null,
  };
}

export function unserializeShowroom(
  showroom: SerializedShowroom<Showroom>,
): Showroom {
  return {
    ...showroom,
    openingHour: unzonedTimeToLocalTime(showroom.openingHour),
    closingHour: unzonedTimeToLocalTime(showroom.closingHour),
    sellers: showroom.sellers.map((s) => ({
      ...s,
      virtualMeetingAppLinks: s.virtualMeetingAppLinks || {},
    })),
    lunchBreakStartingHour: showroom.lunchBreakStartingHour
      ? unzonedTimeToLocalTime(showroom.lunchBreakStartingHour)
      : null,
    lunchBreakEndingHour: showroom.lunchBreakEndingHour
      ? unzonedTimeToLocalTime(showroom.lunchBreakEndingHour)
      : null,
    openingDays: showroom.openingDays.map((openingDay) => ({
      ...openingDay,
      keyAccountsOpeningHour: openingDay.keyAccountsOpeningHour
        ? unzonedTimeToLocalTime(openingDay.keyAccountsOpeningHour)
        : null,
      keyAccountsClosingHour: openingDay.keyAccountsClosingHour
        ? unzonedTimeToLocalTime(openingDay.keyAccountsClosingHour)
        : null,
      customOpeningHour: openingDay.customOpeningHour
        ? unzonedTimeToLocalTime(openingDay.customOpeningHour)
        : null,
      customClosingHour: openingDay.customClosingHour
        ? unzonedTimeToLocalTime(openingDay.customClosingHour)
        : null,
      day: unzonedDateToLocalDate(openingDay.day),
    })),
    lastAllowedBookingDate: showroom.lastAllowedBookingDate
      ? unzonedDateToLocalDate(showroom.lastAllowedBookingDate)
      : null,
  };
}
