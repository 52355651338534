import React from "react";

import GoogleMapReact from "google-map-react";

interface MarkerProps {
  lat: number;
  lng: number;
  showroom: { name: string };
}

function Marker({ lat: _unused, lng: _unused2, showroom }: MarkerProps) {
  return (
    <div className="font-bold text-lg w-fit h-fit bg-white border border-primaryElectricBlue text-primaryBlack shadow px-4 py-2 rounded -translate-x-[50%] -translate-y-[50%] text-center fixed hover:z-select">
      {showroom.name}
    </div>
  );
}

type Props = {
  showrooms: { lat: number; lng: number; name: string }[];
};

export default function CruisingShowroomsMap({ showrooms }: Props) {
  const bounds = new window.google.maps.LatLngBounds();
  showrooms.forEach((s) => {
    const latLng = new window.google.maps.LatLng({ lat: s.lat, lng: s.lng });
    bounds.extend(latLng);
  });
  return (
    <div className="w-full h-full">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAPQ_sRazjjJBWkm3D5QgIkUDXi2zGiQLs" }}
        defaultCenter={{
          lat: bounds.getCenter().lat(),
          lng: bounds.getCenter().lng(),
        }}
        defaultZoom={12}
        onGoogleApiLoaded={({ map }) => {
          map.fitBounds(bounds);
        }}
      >
        {showrooms.map((s) => (
          <Marker key={s.name} lat={s.lat} lng={s.lng} showroom={s} />
        ))}
      </GoogleMapReact>
    </div>
  );
}
