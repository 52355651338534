import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CompetitorsGroup } from "@models/CompetitorGroup";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";
import { getCompetitorsGroupsEndpoint } from "@services/api/organization/get-competitors-groups";

export interface UpdateCompetitorsGroupHookParams {
  organizationId: string;
}
export interface UpdateCompetitorsGroupCallParams {
  competitorsGroupId: string;
  competitorsGroup: Omit<CompetitorsGroup, "accounts"> & {
    accounts: { id: string }[];
  };
}

export type UpdateCompetitorsGroupParams = UpdateCompetitorsGroupHookParams &
  UpdateCompetitorsGroupCallParams;

export const updateCompetitorsGroupEndpoint = {
  path: ({
    competitorsGroupId,
    organizationId,
  }: UpdateCompetitorsGroupParams) =>
    `/organizations/${organizationId}/competitors-groups/${competitorsGroupId}`,
  call: ({
    organizationId,
    competitorsGroupId,
    competitorsGroup,
  }: UpdateCompetitorsGroupParams) =>
    axiosInstance
      .put(
        updateCompetitorsGroupEndpoint.path({
          organizationId,
          competitorsGroupId,
          competitorsGroup,
        }),
        competitorsGroup,
      )
      .then((response) => response.data),
};

export function useUpdateCompetitorsGroup(
  hookParams: UpdateCompetitorsGroupHookParams,
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (callParams: UpdateCompetitorsGroupCallParams) =>
      updateCompetitorsGroupEndpoint.call({
        ...hookParams,
        ...callParams,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getAPIQueryKey(getCompetitorsGroupsEndpoint.path(hookParams)),
      });
    },
  });
}
