"use client";

import React from "react";

import {
  ErrorBoundary as ReactErrorBoundary,
  useErrorBoundary,
} from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "@components/data-entry/Button";
import LogService from "@services/log/service";

export function useError() {
  const { showBoundary, resetBoundary } = useErrorBoundary();

  return {
    throwError: (error: any) => {
      showBoundary(error);
      return null; // when throwing an error, we return null, so that the component that calls this hook can return null as well
    },
    resetError: resetBoundary,
  };
}

export interface ErrorFallbackComponentProps {
  error: Error;
  resetErrorBoundary: () => void;
}

export interface MakeFallbackComponentProps {
  navigate: ReturnType<typeof useNavigate>;
  t: (key: string) => string;
}

function buildErrorMessage(error: Error) {
  if (error.message === "auth/network-request-failed") {
    return "It seems you have network issues. Please check your internet connection and try again.";
  }
  return error.message;
}

/**
 * this function returns a function component
 *
 * we need to do this because we cannot use hooks inside the fallback component directly
 * so the error boundary triggers the necessary hooks and passes them to this function
 * we can then use the hooks inside the component that this function returns
 */
function makeDefaultFallbackComponent({
  navigate,
  t,
}: MakeFallbackComponentProps) {
  return function FallbackComponent({
    error,
    resetErrorBoundary,
  }: ErrorFallbackComponentProps) {
    return (
      <div role="alert" className="p-4">
        <p className="text-xl font-bold">{t("ErrorFallback.default.title")}</p>
        <pre>{buildErrorMessage(error)}</pre>
        <Button onClick={resetErrorBoundary}>
          {t("ErrorFallback.default.try-again")}
        </Button>
        <Button
          onClick={() => {
            resetErrorBoundary();
            navigate("/");
          }}
        >
          {t("ErrorFallback.default.go-home")}
        </Button>
      </div>
    );
  };
}

type Props = {
  makeFallbackComponent?: (
    p: MakeFallbackComponentProps,
  ) => (p: ErrorFallbackComponentProps) => React.JSX.Element;
  children?: React.ReactNode;
};

export default function ErrorBoundary({
  children,
  makeFallbackComponent = makeDefaultFallbackComponent,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <ReactErrorBoundary
      fallbackRender={makeFallbackComponent({ navigate, t })}
      onError={(error) => {
        if (error.message === "auth/network-request-failed") {
          console.warn(error.message, error);
        } else {
          LogService.error(error);
        }
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
}
