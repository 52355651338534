import React, { ReactNode } from "react";

import {
  QueryObserverSuccessResult,
  QueryStatus,
  UseQueryResult,
} from "@tanstack/react-query";

import Loading from "@components/feedback/Loading";

export function loadingFetch<TData, TError>(
  query: UseQueryResult<TData, TError>,
  render: (data: TData) => React.ReactNode,
) {
  if (query.status === "pending") {
    return <Loading type="screen" />;
  }
  if (query.status === "error") {
    throw new Error(query.error as string);
  }
  return render(query.data);
}

type SingleFetchProps = {
  status: QueryStatus;
  error: string | Error | null;
  children: ReactNode;
};

type MultipleFetchProps = {
  status: QueryStatus[];
  error: (string | Error | null)[];
  children: ReactNode;
};

type LoadingFetchProps = SingleFetchProps | MultipleFetchProps;

export function loadAndRender<TData, TError>(
  query: UseQueryResult<TData, TError>,
  render: (query: QueryObserverSuccessResult<TData, TError>) => React.ReactNode,
) {
  if (query.status === "pending") {
    return <Loading type="screen" />;
  }

  if (query.status === "error") {
    throw new Error(query.error as string);
  }

  return render(query);
}

export default function LoadingFetch({
  status,
  error,
  children,
}: LoadingFetchProps) {
  if (
    status === "pending" ||
    (Array.isArray(status) && status.includes("pending"))
  ) {
    return <Loading type="screen" />;
  }

  if (status === "error") {
    if (error instanceof Error) {
      throw error;
    }
    throw new Error(error || "generic error: could not fetch data");
  }

  if (
    Array.isArray(status) &&
    Array.isArray(error) &&
    status.includes("error")
  ) {
    const errorIndex = status.findIndex((s) => s === "error");
    const err = error[errorIndex];

    if (err instanceof Error) {
      throw err;
    }
    throw new Error(err || "generic error: could not fetch data");
  }

  return children;
}
