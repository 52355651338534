import * as React from "react";

import { useTranslation } from "react-i18next";

import { Language } from "@booking/components/appointment/language-select";
import { SellerInformations } from "@booking/helpers/booking";
import groupLanguagesByFormatWithApp from "@booking/helpers/group-languages-formats";
import {
  extractFormat,
  extractMeetingApp,
  isVirtualMeetingApp,
} from "@booking/helpers/virtual-apps-handler";
import Button from "@components/data-entry/Button";
import Select, { OptionGroupItem } from "@components/data-entry/Select";
import { formatTimeInterval } from "@helpers/Date";
import { AppointmentFormatWithMeetingApp } from "@models/types/enums";
import ConditionalRender from "@shared/components/on";

function combineFormatAndLanguage(
  format: AppointmentFormatWithMeetingApp,
  language: Language,
): FormatLanguageCombination {
  return `${format}%%%${language}`;
}

function extractFormatAndLanguage(combination: FormatLanguageCombination) {
  const [format, language] = (combination || "%%%").split("%%%") as [
    AppointmentFormatWithMeetingApp,
    Language,
  ];

  return { format, language };
}

function buildOptionsFromAvailableCombinations(
  availableLanguagesByFormatWithApp: Record<
    AppointmentFormatWithMeetingApp,
    Language[]
  >,
  t: (key: string) => string,
) {
  return Object.entries(availableLanguagesByFormatWithApp).reduce(
    (acc, [formatWithApp, languages]) => {
      const format = extractFormat(
        formatWithApp as AppointmentFormatWithMeetingApp,
      );
      const formatTrans = t(`Common.appointment-format.${format}`);
      const app = extractMeetingApp(
        formatWithApp as AppointmentFormatWithMeetingApp,
      );
      const appTrans = t(`Common.virtual-tool.${app}`);

      const options = languages.map((language) => {
        const languageTrans = t(`Common.language.${language}`);
        return {
          key: `${formatWithApp}_${language}`,
          label: `${app ? appTrans : formatTrans} - ${languageTrans}`,
          value: combineFormatAndLanguage(
            formatWithApp as AppointmentFormatWithMeetingApp,
            language,
          ),
        };
      });

      const correspondingOptionGroup = {
        key: `${format}_languages`,
        label: `${formatTrans}`,
      };

      const correspondingOptionGroupIndex = acc.findIndex(
        (o) => o.key === correspondingOptionGroup.key,
      );

      if (correspondingOptionGroupIndex === -1) {
        acc.push({
          ...correspondingOptionGroup,
          options,
        });
      } else {
        acc[correspondingOptionGroupIndex].options.push(...options);
      }

      return acc;
    },
    [] as OptionGroupItem[],
  );
}

function getStyles(expanded: boolean, disabled: boolean) {
  const styles = {
    container: "",
    dot: "",
  };

  if (expanded) {
    styles.container = "rounded-lg bg-primaryElectricBlue text-white";
  } else {
    styles.container =
      "rounded-full bg-white text-primaryBlack border border-primaryGray enabled:hover:bg-primaryLightElectricBlue";
  }

  if (disabled) {
    styles.dot = "bg-primaryDarkGrey";
  } else {
    styles.dot = "bg-statusGreenDark";
  }

  return styles;
}

interface Slot {
  start: Date;
  end: Date;
  language: Language;
  format: AppointmentFormatWithMeetingApp;
  matchingSellers: SellerInformations[];
}

interface TimeSlotProps {
  start: Date;
  end: Date;
  timeZone: string; // @todo
  id: string;
  onExpand: (e: HTMLAnchorElement | null) => void;
  onBook: (slot: Slot) => Promise<any>; // @todo type the promise better
  expanded: boolean;
  filterValues: {
    formats: AppointmentFormatWithMeetingApp[];
    languages: Language[];
  };
  sellerInformations: SellerInformations[]; // @todo
  disabled: boolean;
}

type FormatLanguageCombination =
  `${AppointmentFormatWithMeetingApp}%%%${Language}`;

export default function AvailableTimeSlot(props: TimeSlotProps) {
  const {
    start,
    end,
    timeZone,
    onBook,
    onExpand,
    disabled = false,
    sellerInformations,
    filterValues,
    expanded,
    id,
  } = props;
  const { t } = useTranslation();

  const availableLanguagesByFormatWithApp = React.useMemo(
    () => groupLanguagesByFormatWithApp(sellerInformations, filterValues),
    [sellerInformations, filterValues],
  );

  // create options from available combinations
  // get combinations matching the filters first
  // display other combinations in a "other" group
  const options: OptionGroupItem[] = buildOptionsFromAvailableCombinations(
    availableLanguagesByFormatWithApp,
    t,
  );

  const flatOptions = options.flatMap((o) => o.options);
  const only1AvailableOption = flatOptions.length === 1;

  // state
  const containerRef = React.useRef<HTMLAnchorElement>(null);
  const [state, setState] = React.useState<"idle" | "booking">("idle");
  const [selectedFormatWithLanguage, setSelectedFormatWithLanguage] =
    React.useState<FormatLanguageCombination>(
      only1AvailableOption ? flatOptions[0].value : undefined,
    );
  const { format: selectedFormatWithApp, language: selectedLanguage } =
    extractFormatAndLanguage(selectedFormatWithLanguage);
  const selectedFormat = extractFormat(selectedFormatWithApp);
  const selectedApp = extractMeetingApp(selectedFormatWithApp);

  const selectedFormatTrans = selectedFormat
    ? t(`Common.appointment-format.${selectedFormat.toUpperCase()}`)
    : "";
  const selectedAppTrans =
    isVirtualMeetingApp(selectedFormatWithApp) && selectedApp
      ? t(`Common.virtual-tool.${selectedApp.toUpperCase()}`)
      : "";
  const selectedLanguageTrans = selectedLanguage
    ? t(`Common.language.${selectedLanguage}`)
    : "";

  // when the filters change, if there is only 1 available option, preselect it
  React.useEffect(() => {
    if (
      only1AvailableOption &&
      selectedFormatWithLanguage !== flatOptions[0].value
    ) {
      setSelectedFormatWithLanguage(flatOptions[0].value);
    }
    // eslint-disable-next-line
  }, [flatOptions]);

  // handlers
  const handleBook = (e: React.MouseEvent) => {
    e.stopPropagation();
    setState("booking");
    const { format, language } = extractFormatAndLanguage(
      selectedFormatWithLanguage,
    );

    if (!format || !language) {
      throw new Error(
        "format or language is undefined. This should not happen.",
      );
    }
    // match a seller to the selected format and language
    const matchingSellers = sellerInformations.filter(
      (si) =>
        si.formatsWithMeetingApps.includes(format) &&
        si.languages.includes(language),
    );

    const slot: Slot = {
      start,
      end,
      format,
      language,
      matchingSellers,
    };

    onBook(slot).finally(() => {
      setState("idle");
    });
  };

  // effects
  const styles = getStyles(expanded, disabled);
  return (
    <a
      href={`#${id}`}
      id={id}
      ref={containerRef}
      onMouseDown={() => {
        if (!expanded && !disabled) {
          onExpand(containerRef.current);
        }
      }}
      aria-label={formatTimeInterval(start, end)}
      className={`relative flex flex-col items-center py-2 px-4 mb-2 transition-colors duration-200 min-w-[16rem] ${
        disabled ? "opacity-50" : ""
      } ${styles.container} w-full cursor-pointer`}
    >
      <span className="flex items-center justify-center gap-2">
        <i className={`w-2 h-2 rounded-full ${styles.dot}`} />
        <span>{formatTimeInterval(start, end)}</span>
      </span>
      <span>{timeZone}</span>

      <ConditionalRender renderIf={expanded}>
        <>
          {flatOptions.length > 1 ? (
            <label className="w-full mt-4">
              {t("Preferred Format & language :")}
              <Select
                showGroups
                name="timeslot-format-language"
                placeholder="Select a format & language"
                defaultValue={
                  options.flatMap((o) =>
                    o.options.filter(
                      (option) => option.value === selectedFormatWithLanguage,
                    ),
                  )[0]
                }
                onChange={(value) => {
                  setSelectedFormatWithLanguage(value);
                }}
                options={options}
              />
            </label>
          ) : (
            <div className="w-full mt-4 text-center">
              <p>
                {selectedAppTrans || selectedFormatTrans}
                {" - "}
                {selectedLanguageTrans}
              </p>
            </div>
          )}
          <Button
            className="mt-6 w-full justify-center"
            loading={state === "booking"}
            disabled={disabled || !selectedFormatWithLanguage}
            theme="SECONDARY"
            rounded="full"
            onClick={handleBook}
            type="button"
          >
            {t("Booking.AppointmentBookingSlotSelect.book-this-slot")}
          </Button>
        </>
      </ConditionalRender>
    </a>
  );
}
