import { MultiOptionItemTree } from "@helpers/MultiOptionItemTree";

export type AccountPortfolioFilter = {
  showOnlySelected?: boolean;
  accountName?: string;
  geography?: MultiOptionItemTree[];
  accountStatus?: string;
};

const hiddenFields = ["accountStatus"] as const;

export const anyFilterActivated = (filter: AccountPortfolioFilter): boolean =>
  Object.values(filter).some((v) => v !== undefined && v !== false);

export const numberOfHiddenFilters = (filter: AccountPortfolioFilter): number =>
  hiddenFields.filter((f) => filter[f]).length;
