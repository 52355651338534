import React from "react";

import Tag from "@components/data-display/Tag";

interface BookingCardTitleProps {
  title?: string;
  step: number;
}

function BookingCardTitle(props: BookingCardTitleProps) {
  const { title, step } = props;

  return (
    <div className="flex items-center gap-4 p-4 lg:px-10 lg:py-6 border-b border-b-primaryLightGrey">
      <Tag>{`${step}/6`}</Tag>
      {title && <h3 className="heading-3-mobile lg:heading-3">{title}</h3>}
    </div>
  );
}

export default BookingCardTitle;
