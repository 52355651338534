import React, { useRef } from "react";

import ReactSelectWrapper, {
  ReactSelectWrapperProps,
  SelectOptionBase,
} from "@components/data-entry/wrapper/ReactSelect";

function useSingleSelect({
  options = [],
  value = undefined,
  onChange,
}: {
  options: SingleSelectProps["options"];
  value: SingleSelectProps["value"];
  onChange: SingleSelectProps["onChange"];
}) {
  const valueRef = useRef(value);
  valueRef.current = value;

  const isOptionSelected = (option: SelectOptionBase) =>
    !!valueRef.current && valueRef.current.value === option.value;

  return {
    onChange,
    options,
    isOptionSelected,
  };
}

export type SingleSelectProps = ReactSelectWrapperProps<false> & {
  disabled?: boolean;
  value?: SelectOptionBase;
  onChange: (option: SelectOptionBase) => void;
};

export default function SingleSelect(props: SingleSelectProps) {
  const {
    disabled,
    value = undefined,
    onChange: onChangeProp,
    options: optionsProp = [],
  } = props;

  const { isOptionSelected, onChange, options } = useSingleSelect({
    options: optionsProp,
    value,
    onChange: onChangeProp,
  });

  return (
    <ReactSelectWrapper
      {...props}
      menuPlacement="auto"
      isMulti={false}
      closeMenuOnSelect
      hideSelectedOptions={false}
      onChange={onChange}
      value={value}
      isOptionSelected={isOptionSelected}
      options={options}
      isDisabled={disabled}
      filterOption={(option, search) =>
        option.label.toLowerCase().includes(search.toLowerCase())
      }
    />
  );
}
