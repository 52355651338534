import React, { PropsWithChildren } from "react";

import { addAccessControlProps } from "@shared/components/access-control";

type GridItemProps = {
  color?: string;
  title: string;
  actions?: React.ReactNode;
};

function GridItem({
  title,
  children,
  color,
  actions = null,
}: PropsWithChildren<GridItemProps>) {
  return (
    <div className="border rounded-lg">
      <div className="flex items-center justify-between gap-2 px-4 py-2 border-b rounded-t-lg bg-primaryLightestGrey">
        <div className="flex items-center gap-2">
          {color && (
            <span
              className="flex-none inline-block w-5 h-5 rounded-full"
              style={{
                backgroundColor: color,
              }}
            />
          )}
          <h3 className="heading-3">{title}</h3>
        </div>
        <span className="flex">{actions}</span>
      </div>
      {children}
    </div>
  );
}
export default addAccessControlProps(GridItem);
