import React from "react";

import { HiExclamationCircle, HiPlus, HiRocketLaunch } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import Button from "@components/data-entry/Button";
import { usePrefixedTranslation } from "@helpers/Translation";

import { AccessControl } from "./components/access-control";
import { ACL_ADMINS_MANAGERS } from "./components/access-control/helpers";

export default function NoOngoingShowroom() {
  const { pt } = usePrefixedTranslation("Shared.NoOngoingShowroom");
  const navigate = useNavigate();
  return (
    <div className="flex h-full items-center justify-center">
      <div className="flex flex-col gap-4 items-center border-2 border-dashed p-8 rounded-2xl border-primaryElectricBlue">
        <p>
          <HiExclamationCircle className="w-24 h-24 fill-primaryElectricBlue" />
        </p>
        <p>{pt("need-an-active-showroom-to-access-this-page")}</p>
        <p>{pt("you-can-launch-a-draft-or-create-a-new-one")}</p>
        <AccessControl roles={ACL_ADMINS_MANAGERS}>
          <div className="flex gap-2">
            <Button theme="SECONDARY" onClick={() => navigate("/showroom")}>
              {pt("launch-draft")}
              <HiRocketLaunch className="w-4 h-4" />
            </Button>
            <Button
              theme="PRIMARY"
              onClick={() => navigate("/showroom/create")}
            >
              {pt("create-showroom")}
              <HiPlus className="w-4 h-4" />
            </Button>
          </div>
        </AccessControl>
      </div>
    </div>
  );
}
