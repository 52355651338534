import { useMutation } from "@tanstack/react-query";

import axiosInstance from "@services/api/config";

interface SendForwardEmailsInput {
  appointmentIds: string[];
  additionalRecipientEmails: string[];
}

export const sendForwardEmailsEndpoint = {
  path: (invitationId: string) =>
    `/bookings/${invitationId}/forward-confirmation-email`,
};

export function useSendForwardEmails(invitationId: string) {
  return useMutation({
    mutationFn: (body: SendForwardEmailsInput) =>
      axiosInstance.post(sendForwardEmailsEndpoint.path(invitationId), body),
  });
}
