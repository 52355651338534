import React from "react";

import { t } from "i18next";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { useMatch, useNavigate } from "react-router-dom";

import { useCRMDrawerContext } from "@app/modules/crm/context/crm-drawers";
import { PageHeader, PageTitle } from "@components/data-display/Page";
import Tabs, { TabsProps } from "@components/data-display/Tabs";
import ButtonMenu from "@components/data-entry/ButtonMenu";
import { useFetchAccountsCount } from "@services/api/old/accounts/fetch-accounts-count";
import { useFetchAccountsArchivedCount } from "@services/api/old/accounts/fetch-archived-count";
import { useFetchContactsArchivedCount } from "@services/api/old/contacts/fetch-archived-count";
import { useFetchContactsCount } from "@services/api/old/contacts/fetch-contacts-count";
import { useGetCompetitorsGroupsCount } from "@services/api/organization/get-competitors-groups-count";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import {
  ACL_ADMINS_AGENTS_MANAGERS,
  ACL_ADMINS_MANAGERS,
  useUserRole,
} from "@shared/components/access-control/helpers";

export default function CRMHeader() {
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const { isAdmin, isManager } = useUserRole();

  const navigate = useNavigate();
  const match = useMatch("/crm/*");

  const currentFile =
    match && match?.params ? Object.values(match!.params)[0] : "";

  const { data: accountsCount } = useFetchAccountsCount(organizationId);
  const { data: accountsArchivedCount } =
    useFetchAccountsArchivedCount(organizationId);
  const { data: contactsCount } = useFetchContactsCount(organizationId);
  const { data: contactsArchivedCount } =
    useFetchContactsArchivedCount(organizationId);
  const { data: competitorsGroupsCount } = useGetCompetitorsGroupsCount(
    {
      organizationId,
    },
    {
      enabled: isAdmin || isManager,
    },
  );

  const crmContext = useCRMDrawerContext();

  const crmTabs: TabsProps["items"] = [
    {
      label: t("CRM.header.tabs.accounts"),
      tab: "accounts",
      count: accountsCount?.count,
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    },
    {
      label: t("CRM.header.tabs.contacts"),
      tab: "contacts",
      count: contactsCount?.count,
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    },
    {
      label: t("CRM.header.tabs.competitors-groups"),
      tab: "competitors",
      count: competitorsGroupsCount?.count,
      aclRoles: ACL_ADMINS_MANAGERS,
    },
    {
      label: t("CRM.header.tabs.accounts-archived"),
      tab: "archived-accounts",
      count: accountsArchivedCount?.count,
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    },
    {
      label: t("CRM.header.tabs.contacts-archived"),
      tab: "archived-contacts",
      count: contactsArchivedCount?.count,
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    },
  ];

  const currentTab = crmTabs.find((item) => item.tab === currentFile)?.tab;

  return (
    <>
      <PageHeader>
        <PageTitle>{t("CRM.header.title")}</PageTitle>
        <ButtonMenu
          label={t("CRM.header.add-new")}
          icon={<HiOutlinePlusCircle className="w-6 h-6" />}
          items={[
            {
              label: t("CRM.header.add-new-account"),
              aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
              onClick: () => {
                navigate("/crm/accounts");
                crmContext.openAccountUpsertDrawer();
              },
            },
            {
              label: t("CRM.header.add-new-contact"),
              aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
              onClick: () => {
                navigate("/crm/contacts");
                crmContext.openContactUpsertDrawer();
              },
            },
            {
              label: t("CRM.header.add-new-competitors-group"),
              aclRoles: ACL_ADMINS_MANAGERS,
              onClick: () => {
                navigate("/crm/competitors");
                crmContext.openCompetitorsGroupUpsertDrawer();
              },
            },
          ]}
        />
      </PageHeader>
      <Tabs
        aria-label="CRM-tabs"
        className="mt-6 mb-4 border-b"
        items={crmTabs}
        tab={currentTab}
        handleClick={(item) => {
          navigate(`/crm/${item.tab}`);
        }}
      />
    </>
  );
}
