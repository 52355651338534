import { z } from "zod";

import { InvitationStatusList } from "./types/enums";

export const invitationSchema = z.object({
  id: z.string().uuid(), // unique identifier for each invitation
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  invitationStatus: z.enum(InvitationStatusList),
  contactId: z.string().uuid(),
  invitationId: z.string().uuid(), // shared across multiple invitations (e.g., several showrooms tied to one invitation)
  showroomId: z.string().uuid(),
  organizationId: z.string().uuid(),
});

export type Invitation = z.infer<typeof invitationSchema>;
