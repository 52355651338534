/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

interface BackdropProps {
  onClick?: () => void;
  onEscapeDown?: () => void;
  zIndex?: number;
}

function Backdrop(props: BackdropProps) {
  const { onClick, onEscapeDown, zIndex } = props;

  return (
    <div
      data-testid="backdrop"
      className={`fixed top-0 left-0 w-full h-full ${
        Number.isInteger(zIndex) ? "" : ""
      }`}
      onClick={onClick}
      onKeyDown={(e) => {
        if (onEscapeDown && e.key === "Escape") {
          onEscapeDown();
        }
      }}
    >
      <div className="w-full h-full bg-fixed bg-gray-400 bg-opacity-40" />
    </div>
  );
}

export default Backdrop;
