import React from "react";

import { subMinutes } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Calendar } from "react-multi-date-picker";
import { z } from "zod";

import Button from "@components/data-entry/Button";
import MultiSelect from "@components/data-entry/MultiSelect";
import TextField from "@components/data-entry/TextField";
import TimePicker from "@components/data-entry/TimePicker";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import { Showroom } from "@models/Showroom";
import { useOrganizationRepresentativeOptions } from "@shared/helpers/sellers";

interface BusySlotFormData {
  title: string;
  start: Date | null;
  end: Date | null;
  dates: Date[];
  sellers: SelectOptionBase[];
  showroomIds: Showroom["id"][];
}

const initialValues: BusySlotFormData = {
  title: "",
  dates: [],
  start: null,
  end: null,
  sellers: [],
  showroomIds: [],
};

const busySlotFormDataSchema = z.object({
  title: z.string(),
  sellers: z.array(
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ),
  showroomIds: z.array(z.string().uuid()),
  dates: z.array(z.date()),
  start: z.date(),
  end: z.date(),
});
type BusySlotValidFormData = z.infer<typeof busySlotFormDataSchema>;

type BusySlotCreateFormProps = {
  onSubmit: (values: BusySlotValidFormData) => void;
  onCancel: () => void;
  defaultValues: Partial<BusySlotFormData>;
  showrooms: Array<{
    id: string;
    name: string;
  }>;
  sellers: {
    id: string;
    firstName: string;
    lastName: string;
  }[];
};

export default function BusySlotCreateForm({
  onSubmit,
  onCancel,
  defaultValues,
  sellers,
  showrooms,
}: BusySlotCreateFormProps) {
  const { t } = useTranslation();
  const form = useForm<BusySlotFormData>({
    defaultValues: {
      ...initialValues,
      ...defaultValues,
    },
  });
  const startTime = form.watch("start");
  const endTime = form.watch("end");

  const sellerOptions = useOrganizationRepresentativeOptions({ sellers });

  const submit = (values: BusySlotFormData) => {
    const parsing = busySlotFormDataSchema.safeParse(values);

    if (parsing.success) {
      onSubmit(parsing.data);
    }
  };

  const formErrors = form.formState.errors;

  return (
    <form
      onSubmit={form.handleSubmit(submit)}
      className="grid grid-cols-1 gap-4 mt-8"
    >
      <div>
        <label htmlFor="title">
          {t("Calendar.BusySlotCreateForm.title-label")}
          {formErrors.title && formErrors.title.message && (
            <span className="text-primaryRed text-sm ml-2">
              {formErrors.title.message}
            </span>
          )}
        </label>
        <Controller
          control={form.control}
          name="title"
          rules={{
            required: t("Calendar.BusySlotCreateForm.field-required"),
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              id="title"
              placeholder={t("Calendar.BusySlotCreateForm.title-placeholder")}
              hasError={!!error}
            />
          )}
        />
      </div>
      <div>
        <label htmlFor="showroomIds">
          {t("Calendar.BusySlotCreateForm.showroomIds-label")}
          {formErrors.showroomIds && formErrors.showroomIds.message && (
            <span className="text-primaryRed text-sm ml-2">
              {formErrors.showroomIds.message}
            </span>
          )}
        </label>
        <Controller
          control={form.control}
          name="showroomIds"
          rules={{
            required: t("Calendar.BusySlotCreateForm.field-required"),
          }}
          render={({ field: { value, onChange } }) => (
            <MultiSelect
              options={showrooms.map((s) => ({
                label: s.name,
                value: s.id,
              }))}
              value={value.map((sid) => ({
                label:
                  showrooms.find((s) => s.id === sid)?.name ||
                  "unknown showroom",
                value: sid,
              }))}
              onChange={(selected) => {
                onChange(selected.map((o) => o.value));
              }}
              id="showroomIds"
              placeholder={t(
                "Calendar.BusySlotCreateForm.showroomIds-placeholder",
              )}
            />
          )}
        />
      </div>
      <div className="flex gap-4">
        <div className="grow">
          <label htmlFor="start">
            {t("Calendar.BusySlotCreateForm.start-label")}
          </label>
          <Controller
            control={form.control}
            name="start"
            rules={{
              required: t("Calendar.BusySlotCreateForm.field-required"),
            }}
            render={({ field: { value, onChange } }) => (
              <TimePicker
                id="start"
                className="inline w-34"
                placeholder={t("Calendar.BusySlotCreateForm.start-placeholder")}
                name="start"
                maxTime={endTime && subMinutes(endTime, 1)}
                options={{
                  firstHourOfDay: 6,
                  totalHours: 16,
                  timeStep: 15,
                }}
                onChange={onChange}
                defaultValue={value || undefined}
              />
            )}
          />
        </div>
        <div className="grow">
          <label htmlFor="end">
            {t("Calendar.BusySlotCreateForm.end-label")}
          </label>
          <Controller
            control={form.control}
            name="end"
            rules={{
              required: t("Calendar.BusySlotCreateForm.field-required"),
            }}
            render={({ field: { value, onChange } }) => (
              <TimePicker
                className="inline w-34"
                placeholder={t("Calendar.BusySlotCreateForm.end-placeholder")}
                name="end"
                id="end"
                minTime={startTime}
                options={{
                  firstHourOfDay: 6,
                  totalHours: 16,
                  timeStep: 15,
                }}
                onChange={onChange}
                defaultValue={value || undefined}
              />
            )}
          />
        </div>
      </div>
      <div>
        <label htmlFor="dates">
          {t("Calendar.BusySlotCreateForm.dates-label")}
          {formErrors.dates && formErrors.dates.message && (
            <p className="text-primaryRed text-sm ml-2">
              {formErrors.dates.message}
            </p>
          )}
        </label>
        <Controller
          control={form.control}
          name="dates"
          rules={{
            required: t("Calendar.BusySlotCreateForm.field-required"),
          }}
          render={({ field: { value, onChange } }) => (
            <Calendar
              multiple
              value={value}
              onChange={(v) => onChange(v.map((dateObj) => dateObj.toDate()))}
              showOtherDays
            />
          )}
        />
      </div>
      <div>
        <label htmlFor="sellers">
          {t("Calendar.BusySlotCreateForm.sellers-label")}
          {formErrors.sellers && formErrors.sellers.message && (
            <p className="text-primaryRed text-sm ml-2">
              {formErrors.sellers.message}
            </p>
          )}
        </label>
        <Controller
          control={form.control}
          name="sellers"
          rules={{
            required: t("Calendar.BusySlotCreateForm.field-required"),
          }}
          render={({ field: { value, onChange } }) => (
            <MultiSelect
              options={sellerOptions}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </div>
      <div className="grid grid-cols-2 gap-2">
        <Button
          theme="SECONDARY"
          type="button"
          onClick={onCancel}
          className="justify-center"
        >
          {t("Common.cancel")}
        </Button>
        <Button theme="PRIMARY" type="submit" className="justify-center">
          {t("Calendar.BusySlotCreateForm.submit")}
        </Button>
      </div>
    </form>
  );
}
