import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { RepresentativeRole } from "@models/OrganizationRepresentative";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export interface GetOrganizationRepresentativesForAdminParams {
  organizationId: string;
}

export const OrganizationRepresentativesForAdminSchema = z.object({
  id: z.string().uuid(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  organizationId: z.string().uuid(),
  role: RepresentativeRole,
  invitationStatus: z.string().nullable(),
  userId: z.string().nullable(),
});

export type OrganizationRepresentativesForAdmin = z.infer<
  typeof OrganizationRepresentativesForAdminSchema
>;

export type GetOrganizationRepresentativesForAdminResponse =
  OrganizationRepresentativesForAdmin[];

export const getOrganizationRepresentativesForAdminEndpoint = {
  path: ({ organizationId }: GetOrganizationRepresentativesForAdminParams) =>
    `/organizations/${organizationId}/back-office-representatives-view`,
  call: ({ organizationId }: GetOrganizationRepresentativesForAdminParams) =>
    axiosInstance
      .get<GetOrganizationRepresentativesForAdminResponse>(
        getOrganizationRepresentativesForAdminEndpoint.path({ organizationId }),
      )
      .then((response) => response.data),
};

export function useGetOrganizationRepresentativesForAdmin(
  params: GetOrganizationRepresentativesForAdminParams,
) {
  return useQuery({
    queryKey: getAPIQueryKey(
      getOrganizationRepresentativesForAdminEndpoint.path(params),
    ),
    queryFn: () => getOrganizationRepresentativesForAdminEndpoint.call(params),
  });
}
