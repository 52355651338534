import { useQuery } from "@tanstack/react-query";

import queryClient from "@app/queryClient";
import { Organization } from "@models/Organization";
import { OrganizationRepresentative } from "@models/OrganizationRepresentative";
import { Portfolio } from "@models/Portfolio";
import { SalesCampaign } from "@models/SalesCampaign";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export type PortfolioWithCapabilities = Portfolio & {
  accounts: {
    id: string;
  }[];
  manager: OrganizationRepresentative;
  sellers: OrganizationRepresentative[];
  deletingBlockedBy: SalesCampaign["name"][];
};

type FetchPortfoliosWithCapabilitiesApiResponse = PortfolioWithCapabilities[];

export const fetchPortfoliosWithCapabilitiesEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/portfolios/with-capabilities`,
  callApi: (organizationId: Organization["id"]) =>
    axiosInstance
      .get<FetchPortfoliosWithCapabilitiesApiResponse>(
        fetchPortfoliosWithCapabilitiesEndpoint.path(organizationId),
      )
      .then((res) => res.data),
};

export const removePortfoliosWithCapabilitiesQuery = (
  organizationId: Organization["id"],
) =>
  queryClient.invalidateQueries({
    queryKey: getAPIQueryKey(
      fetchPortfoliosWithCapabilitiesEndpoint.path(organizationId),
    ),
  });

export const invalidatePortfoliosWithCapabilitiesQuery = (
  organizationId: Organization["id"],
) =>
  queryClient.invalidateQueries({
    queryKey: getAPIQueryKey(
      fetchPortfoliosWithCapabilitiesEndpoint.path(organizationId),
    ),
  });

export const useFetchPortfoliosWithCapabilities = (organizationId: string) =>
  useQuery({
    queryKey: getAPIQueryKey(
      fetchPortfoliosWithCapabilitiesEndpoint.path(organizationId),
    ),
    queryFn: () =>
      fetchPortfoliosWithCapabilitiesEndpoint.callApi(organizationId),
  });
