import React, { useEffect } from "react";

import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";

import Loading from "@components/feedback/Loading";
import LoadingFetch from "@components/feedback/LoadingFetch";
import { GetOngoingShowroomsEndpoint } from "@services/api/showroom/get-ongoing-showrooms";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import NoOngoingShowroom from "@shared/no-ongoing-showroom";
import { getFirstOpeningDate } from "@shared/showroom/helpers";

export default function CalendarIndexPage() {
  const navigate = useNavigate();
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();
  const { data: showrooms, status } = GetOngoingShowroomsEndpoint.useHook({
    organizationId,
  });

  useEffect(() => {
    if (showrooms && showrooms.length > 0) {
      const { season, year } = showrooms[0];
      const openingDay = getFirstOpeningDate(showrooms[0]);
      navigate(
        `/calendar/${season}/${year}/daily/${format(
          parseISO(openingDay.toISOString()),
          "yyyy-MM-dd",
        )}`,
      );
    }
  }, [navigate, showrooms]);

  return (
    <LoadingFetch status={status} error="could not fetch ongoing showrooms">
      {showrooms && showrooms.length === 0 ? (
        <NoOngoingShowroom />
      ) : (
        <Loading type="screen" />
      )}
    </LoadingFetch>
  );
}
