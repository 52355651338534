import React, { KeyboardEvent, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { ZodIssue, z } from "zod";

import Tag from "@components/data-display/Tag";
import Button from "@components/data-entry/Button";
import TextField from "@components/data-entry/TextField";
import CalloutBox from "@components/feedback/CalloutBox";
import LoadingFetch from "@components/feedback/LoadingFetch";
import { GetAllBrandsEndpoint } from "@services/api/brands/get-all-brands";
import { GetOrganizationCollectionsEndpoint } from "@services/api/organization/get-collections";
import { OrganizationPartnersWithOnboardedBrandEndpoint } from "@services/api/organization/post-partner-with-existing-brand";
import { OrganizationPartnersWithNonOnboardedBrandEndpoint } from "@services/api/organization/post-partner-with-new-brand";
import LogService from "@services/log/service";
import { brandToOption } from "@shared/helpers/option-maker";

import { mapZodError } from "./map-zod-error";

const agencyPartnerWithExistingBrandFormDataSchema = z.object({
  brandId: z.string().uuid(),
  name: z.string(),
  collections: z.array(z.string()),
});
const agencyPartnerWithNewBrandFormDataSchema = z.object({
  brandId: z.null(),
  name: z.string(),
  collections: z.array(z.string()).nonempty(),
});
export const agencyPartnerWithBrandFormDataValidSchema = z.union([
  agencyPartnerWithExistingBrandFormDataSchema,
  agencyPartnerWithNewBrandFormDataSchema,
]);
export type AgencyPartnerWithBrandFormDataValid = z.infer<
  typeof agencyPartnerWithBrandFormDataValidSchema
>;

interface AgencyPartnerWithBrandFormData {
  brandId?: string | null;
  name: string;
  collections: string[];
}

const defaultValues: AgencyPartnerWithBrandFormData = {
  name: "",
  brandId: null,
  collections: [],
};

type Props = {
  organizationId: string;
};

export default function AgencyCollectionForm({ organizationId }: Props) {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<ZodIssue[]>([]);
  const [collectionName, setCollectionName] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const { control, watch, setValue, getValues, reset } =
    useForm<AgencyPartnerWithBrandFormData>({
      defaultValues,
    });

  // remote actions
  const { mutateAsync: partnerWithOnboardedBrand } =
    OrganizationPartnersWithOnboardedBrandEndpoint.useHook({ organizationId });
  const { mutateAsync: partnerWithNonOnboardedBrand } =
    OrganizationPartnersWithNonOnboardedBrandEndpoint.useHook({
      organizationId,
    });

  // remote state
  const { data: brands = [], status: brandsFetchStatus } =
    GetAllBrandsEndpoint.useHook();

  const {
    data: allCollections = [],
    status: allCollectionStatus,
    error: allCollectionsError,
  } = GetOrganizationCollectionsEndpoint.useHook({
    organizationId,
  });

  // computings
  const values = watch();
  const partnerBrandsIds = allCollections
    .map((c) => c.brand?.id)
    .filter((b) => !!b);
  const selectedBrandId = values.brandId;
  const brandsOptions = brands
    .filter((b) => !partnerBrandsIds.includes(b.id)) // filter out the brands already partnered with
    .map(brandToOption);
  const selectedBrand = brands.find((b) => b.id === selectedBrandId);
  const selectedBrandOption = brandsOptions.find(
    (b) => b.value === selectedBrandId,
  );
  const isSelectedBrandMR = selectedBrand && !!selectedBrand.organizationId;

  // handlers
  const handleAddPartnerBrand = () => {
    const formValues = getValues();
    if (collectionName !== "") {
      formValues.collections.push(collectionName);
    }
    const formValidation =
      agencyPartnerWithBrandFormDataValidSchema.safeParse(formValues);

    if (!formValidation.success) {
      setErrors(formValidation.error.issues);
      LogService.error(formValidation.error);
      throw new Error("Form data are invalid");
    }
    setErrors([]);

    return (
      formValidation.data.brandId !== null
        ? partnerWithOnboardedBrand({
            data: {
              brandId: formValidation.data.brandId,
              collections: formValidation.data.collections,
            },
          })
        : partnerWithNonOnboardedBrand({
            data: {
              collections: formValidation.data.collections,
              name: formValidation.data.name,
            },
          })
    ).then(() => {
      reset({
        brandId: null,
        collections: [],
        name: "",
      });
      setCollectionName("");
    });
  };

  const errorDisplayer = mapZodError({
    collections: "Collections must be non empty",
  });

  return (
    <div>
      <Button
        theme="PRIMARY"
        className="mb-4"
        onClick={() => setIsExpanded(true)}
        disabled={isExpanded}
      >
        {t("Shared.AgencyCollectionForm.add-brand")}
      </Button>

      {isExpanded && (
        <div className="p-4 rounded border border-primaryLightElectricBlue">
          <div className="grid grid-cols-2 gap-4">
            <LoadingFetch
              status={[allCollectionStatus, brandsFetchStatus]}
              error={[allCollectionsError, "could not retrieve brands options"]}
            >
              <div className="mb-2">
                <label htmlFor="organizationName">
                  {t("Shared.AgencyCollectionForm.type-brand-name")}
                </label>
                <div>
                  <Controller
                    name="brandId"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <CreatableSelect
                        isClearable
                        name="brandId"
                        options={brandsOptions}
                        value={
                          selectedBrandOption || values.name
                            ? { label: values.name, value: "" }
                            : null
                        }
                        onCreateOption={(brandName) => {
                          onChange(null);
                          setValue("name", brandName);
                        }}
                        onChange={(o) => {
                          onChange(o?.value);
                          if (o) {
                            setValue("name", o.label);
                          } else {
                            setValue("name", "");
                          }
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </LoadingFetch>
          </div>

          <div className="flex gap-2 mb-2 flex-wrap">
            {selectedBrand &&
              selectedBrand.organization &&
              selectedBrand.organization.collections.length > 0 &&
              selectedBrand.organization.collections.map((c) => (
                <Tag className="w-" key={c.id}>
                  {c.name}
                </Tag>
              ))}
          </div>
          {!isSelectedBrandMR && (
            <div>
              <label htmlFor="collection-name">
                {t(
                  "Shared.AgencyCollectionForm.type-collection-names-and-validate-with-enter",
                )}
              </label>
              <Controller
                name="collections"
                control={control}
                render={({ field: { onChange, value: collectionNames } }) => (
                  <>
                    <TextField
                      type="text"
                      clearable={false}
                      id="collection-name"
                      placeholder="Ex: Ready to wear"
                      value={collectionName}
                      onChange={setCollectionName}
                      onBlur={(e) => {
                        if (e.target.value !== "") {
                          onChange([...collectionNames, e.target.value.trim()]);
                          setCollectionName("");
                        }
                      }}
                      onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                        if (
                          "value" in e.target &&
                          typeof e.target.value === "string" &&
                          e.target.value !== "" &&
                          ["Enter", "Tab"].includes(e.key)
                        ) {
                          onChange([...collectionNames, e.target.value.trim()]);
                          setCollectionName("");
                        }
                      }}
                    />
                    <div className="flex my-2 flex-wrap min-h-[3rem]">
                      {collectionNames.map((c, i) => (
                        <Tag
                          className="transition-opacity animate-slideDownAndFade"
                          key={`collection-name-${i}`}
                          closable
                          onClose={() => {
                            onChange(collectionNames.toSpliced(i, 1));
                          }}
                        >
                          {c}
                        </Tag>
                      ))}
                    </div>
                  </>
                )}
              />
            </div>
          )}

          {errors.length > 0 ? (
            <CalloutBox type="ERROR" className="mb-4">
              {errors.map(errorDisplayer).join(", ")}
            </CalloutBox>
          ) : null}

          <div className="flex gap-2">
            <Button onClick={() => setIsExpanded(false)} theme="SECONDARY">
              {t("Shared.AgencyCollectionForm.close")}
            </Button>
            <Button
              theme="PRIMARY"
              disabled={
                !agencyPartnerWithBrandFormDataValidSchema.safeParse({
                  ...values,
                  collections: [...values.collections, collectionName],
                }).success
              }
              onClick={() =>
                handleAddPartnerBrand().then(() => {
                  toast.success(t("Shared.AgencyCollectionForm.toast-success"));
                })
              }
            >
              {t("Shared.AgencyCollectionForm.save-brand")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
