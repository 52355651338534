import * as React from "react";
import { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { useBookingContext } from "@booking/components/hook";
import SlotSelect from "@booking/components/slot-select";
import Loading from "@components/feedback/Loading";
import { BookingGetBookedAppointmentsEndpoint } from "@services/api/booking/get-booked-appointments";
import { BookingGetInvitationInfoEndpoint } from "@services/api/booking/get-invitation-infos";

export default function AppointmentEditPage() {
  const { invitationId, appointmentId } = useParams();
  const { dispatch } = useBookingContext();
  const [appointmentToEdit, setAppointmentToEdit] =
    React.useState<BookingGetBookedAppointmentsEndpoint.Output[number]>();

  if (!invitationId || !appointmentId) {
    throw new Error("missing parameters");
  }

  const navigate = useNavigate();

  const {
    data: invitationData,
    isError,
    error,
    isPending,
  } = BookingGetInvitationInfoEndpoint.useHook({ invitationId });

  const {
    data: appointments = [],
    isPending: isLoadingAppointments,
    isError: isErrorAppointments,
    error: errorAppointments,
  } = BookingGetBookedAppointmentsEndpoint.useHook({ invitationId });

  useEffect(() => {
    const appointment = appointments.find((appt) => appt.id === appointmentId);
    if (!appointment) {
      return;
    }
    dispatch({
      type: "PREPARE_TO_EDIT_APPOINTMENT",
      appointment,
    });
    setAppointmentToEdit(appointment);
  }, [appointmentId, appointments]);

  if (!appointmentToEdit || isPending || isLoadingAppointments) {
    return <Loading type="screen" />;
  }

  if (isError) {
    throw error;
  }
  if (isErrorAppointments) {
    throw errorAppointments;
  }

  const goToDashboard = () => navigate(`/booking/${invitationId}`);

  return (
    <SlotSelect
      editedAppointmentId={appointmentToEdit.id}
      invitationId={invitationId}
      onPrevious={goToDashboard}
      onSubmit={goToDashboard}
      organization={invitationData.organization}
      contact={invitationData.contact}
      showrooms={invitationData.showrooms}
      portfolios={invitationData.portfolios}
    />
  );
}
