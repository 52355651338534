import React, { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { HiChevronLeft } from "react-icons/hi";
import { Link, Outlet, useParams } from "react-router-dom";

import BuyerInformations from "@booking/components/appointment/buyer-informations";
import InvitationInformations from "@booking/components/appointment/invitation-informations";
import ContactInformationForm from "@booking/components/contact-information-form";
import { BookingContextProvider } from "@booking/components/hook";
import Drawer, { useDrawer } from "@components/feedback/Drawer";
import Loading from "@components/feedback/Loading";
import fetchBookingEndpoint from "@services/api/old/booking/fetch-booking";
import ErrorBoundary from "@services/error/error-boundary";
import LogService from "@services/log/service";

export default function BookingPickLayout() {
  const { t } = useTranslation();
  const { invitationId } = useParams();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (!invitationId) {
    throw new Error("no invitation id");
  }

  const {
    data: invitationData,
    isError,
    error,
    isPending,
  } = useQuery(fetchBookingEndpoint.query(invitationId));

  const contactInformationDrawer = useDrawer();

  if (isPending) {
    return <Loading type="screen" />;
  }

  if (isError) {
    LogService.error(error);
    throw error;
  }

  const { organization, showrooms, contact } = invitationData;

  return (
    <BookingContextProvider defaultAttendee={invitationData.contact}>
      <div className="flex flex-col 2xl:max-w-10xl h-screen mx-6 py-10 lg:mx-10 lg:py-12 xl:mx-20">
        <div className="flex justify-between">
          <div className="w-full lg:max-w-[60%]">
            <Link
              to={`/booking/${invitationId}`}
              className="flex items-center pb-4 underline text-primaryElectricBlue h-fit"
            >
              <HiChevronLeft className="w-6 h-6" />
              {t("Booking.back-to-first-step")}
            </Link>
            <BuyerInformations
              contact={contact}
              onClickEdit={() => setIsOpen(!isOpen)}
            />
          </div>
          <InvitationInformations
            organization={organization}
            showrooms={showrooms}
          />
        </div>
        <div
          className="mt-10 w-full rounded-lg grow flex"
          style={{ boxShadow: "0px 0px 24px rgba(45, 42, 54, 0.12)" }}
        >
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </div>
        <Drawer
          {...contactInformationDrawer.props}
          backdrop
          name="booking-editContactInformation"
          size="LARGE"
          drawerTitle={
            <h2 className="heading-2-mobile lg:heading-2">
              {t("Booking.contact.edit.title")}
            </h2>
          }
        >
          <ContactInformationForm
            invitationId={invitationId}
            contact={contact}
            handleCancel={contactInformationDrawer.closeWithoutConfirmation}
            onSuccess={contactInformationDrawer.closeWithoutConfirmation}
          />
        </Drawer>
      </div>
    </BookingContextProvider>
  );
}
