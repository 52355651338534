import { useLocalStorage } from "usehooks-ts";
import { z } from "zod";

import {
  openingDayHydratingSchema,
  openingDaySerializingSchema,
} from "@models/Showroom";
import {
  dateHydratingSchema,
  dateSerializingSchema,
} from "@models/types/Dates";

import {
  ShowroomFormData,
  defaultFormValues,
  showroomFormSchema,
} from "./hook";

export const STORAGEKEY_SHOWROOM_CREATION_STEP = "SHOWROOM_CREATION_STEP";
export const STORAGEKEY_SHOWROOM_CREATION_FORM_DATA =
  "SHOWROOM_CREATION_FORM_DATA";

const storedDataParser = showroomFormSchema
  .extend({
    openingHour: dateHydratingSchema,
    closingHour: dateHydratingSchema,
    lunchStartingHour: dateHydratingSchema.nullable(),
    lunchEndingHour: dateHydratingSchema.nullable(),
    openingDays: z.array(openingDayHydratingSchema),
    appointmentDeadline: dateHydratingSchema.nullable(),
    keyAccountDatetime: dateHydratingSchema.nullable(),
  })
  .partial();
const storedDataSerializer = showroomFormSchema
  .extend({
    openingHour: dateSerializingSchema,
    closingHour: dateSerializingSchema,
    lunchStartingHour: dateSerializingSchema.nullable(),
    lunchEndingHour: dateSerializingSchema.nullable(),
    openingDays: z.array(openingDaySerializingSchema),
    appointmentDeadline: dateSerializingSchema.nullable(),
    keyAccountDatetime: dateSerializingSchema.nullable(),
  })
  .partial();

export function useShowroomStorage() {
  const [storedData, setStoredData] = useLocalStorage(
    STORAGEKEY_SHOWROOM_CREATION_FORM_DATA,
    storedDataSerializer.parse(defaultFormValues),
  );

  const parsedData = storedDataParser.parse(storedData);
  const serializeAndStoreData = (data: ShowroomFormData) => {
    setStoredData(storedDataSerializer.parse(data));
  };

  return [parsedData, serializeAndStoreData] as const;
}
