import { MailDeliveryStatus } from "@models/types/enums";

export function getLatestEmailTracking(
  emailTrackings: { createdAt: Date; status: MailDeliveryStatus }[],
) {
  return emailTrackings.reduce(
    (latest, et) => (et.createdAt > latest.createdAt ? et : latest),
    { createdAt: new Date(0), status: "NOT_SENT" },
  );
}
