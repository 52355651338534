import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { HiCheck } from "react-icons/hi";

import Button from "@components/data-entry/Button";
import BottomBar from "@components/layout/BottomBar";
import { Account } from "@models/Account";
import { Contact } from "@models/Contact";
import { OrganizationRepresentative } from "@models/OrganizationRepresentative";
import { Portfolio } from "@models/Portfolio";
import filterAccounts from "@onboarding/components/accountPortfolio/components/AccountFiltering";
import { AccountPortfolioFilter } from "@onboarding/components/accountPortfolio/components/AccountPortfolioFilter";
import accountRowsBuilder from "@onboarding/components/accountPortfolio/components/AccountRowsBuilder";
import AccountsTable from "@shared/accounts/AccountsTable";
import Filters from "@shared/accounts/Filters";

type AccountDetails = Pick<
  Account,
  "id" | "city" | "name" | "status" | "countryCode"
> & {
  contacts?: Pick<
    Contact,
    | "id"
    | "firstName"
    | "lastName"
    | "email"
    | "phoneNumber"
    | "position"
    | "markets"
  >[];
};

type PortfolioWithCapabilities = Portfolio & {
  accounts: Pick<Account, "id">[];
  manager: OrganizationRepresentative;
  sellers: OrganizationRepresentative[];
  deletingBlockedBy: string[];
};

export interface PortfolioFormData {
  id?: string;
  brandId: string | null;
  collectionId: string | null;
  name?: string;
  color: string | null;
  manager?: {
    id: string;
  };
  sellers: { id: string }[];
  accounts: { id: string }[];
}

export interface PortfolioValidFormData {
  id?: string;
  brandId: string;
  collectionId: string | null;
  name: string;
  color: string;
  manager: {
    id: string;
  };
  sellers: { id: string }[];
  accounts: { id: string }[];
}

interface StructureTabProps {
  accounts: AccountDetails[];
  portfolio: PortfolioFormData;
  portfolios: PortfolioWithCapabilities[];
  onPrevious: (item: PortfolioFormData) => void;
  onSave: (item: PortfolioFormData) => void;
}

function StructureTab(props: StructureTabProps) {
  const { t } = useTranslation();
  const { accounts, portfolio, portfolios, onPrevious, onSave } = props;
  const isCreation = portfolio.id === undefined;

  const [filters, setFilters] = useState<AccountPortfolioFilter>({});
  const [structureProps, setStructureProps] = useState<PortfolioFormData>({
    ...portfolio,
  });

  const filteredAccounts = filterAccounts(
    accounts || [],
    filters,
    structureProps.accounts?.map((a) => a.id) || [],
  );

  const onSelectionChange = (selectedAccounts: string[]) => {
    setStructureProps({
      ...portfolio,
      accounts: selectedAccounts.map((id) => ({ id })),
    });
  };

  const buildAccountRows = accountRowsBuilder(portfolio, portfolios);
  const accountRows = buildAccountRows(filteredAccounts);

  return (
    <div className="flex flex-col grow overflow-y-auto">
      <div className="flex flex-col gap-2 p-10 grow overflow-y-auto">
        <Filters
          accounts={accounts || []}
          filters={filters}
          onChange={setFilters}
        />
        <div className="flex justify-start gap-2">
          <span className="text-primaryElectricBlue">
            {structureProps.accounts.length} accounts selected
          </span>
          <button
            type="button"
            className={`px-2 rounded-full border-2 border-primaryElectricBlurounded-full shadow-sm cursor-pointer ${
              filters.showOnlySelected
                ? "text-white bg-primaryElectricBlue"
                : "text-primaryElectricBlue"
            }`}
            onClick={() => {
              setFilters({
                ...filters,
                showOnlySelected: !filters.showOnlySelected,
              });
            }}
          >
            show only selected
          </button>
        </div>
        <AccountsTable
          accounts={accountRows}
          selectedAccounts={structureProps.accounts?.map((a) => a.id) || []}
          onSelectionChange={onSelectionChange}
        />
      </div>
      <BottomBar theme="WHITE" sticky>
        <Button theme="TERTIARY" onClick={() => onPrevious(structureProps)}>
          {t("Common.previous")}
        </Button>
        <Button
          theme="PRIMARY"
          disabled={structureProps.accounts.length === 0}
          onClick={() => onSave(structureProps)}
        >
          {isCreation
            ? t("Shared.portfolios.structure-tab.create")
            : t("Shared.portfolios.structure-tab.save")}
          <HiCheck className="w-6 h-6" />
        </Button>
      </BottomBar>
    </div>
  );
}

export default StructureTab;
