import React from "react";

import SingleSelect, {
  SingleSelectProps,
} from "@components/data-entry/SingleSelect";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import { Account } from "@models/Account";

interface AccountOption {
  name: Account["name"];
  id: Account["id"];
}

function accountToOption(
  account: AccountOption,
  props: Partial<SelectOptionBase> = {},
): SelectOptionBase {
  return {
    label: account.name,
    value: account.id,
    ...props,
  };
}

interface AccountSelectProps
  extends Omit<SingleSelectProps, "value" | "onChange"> {
  accounts: AccountOption[];
  onChange: (account: AccountOption | undefined) => void;
  value: string | undefined;
}

export default function AccountSelect({
  accounts,
  onChange,
  value,
  ...props
}: AccountSelectProps) {
  const selectedAccount = value
    ? accounts.find((acc) => acc.id === value)
    : undefined;
  const selectedOption = selectedAccount
    ? accountToOption(selectedAccount)
    : undefined;

  return (
    <SingleSelect
      onChange={(newValue) => {
        if (newValue) {
          onChange(accounts.find((acc) => acc.id === newValue.value));
        } else {
          onChange(undefined);
        }
      }}
      value={selectedOption}
      options={accounts.map((acc) => accountToOption(acc))}
      {...props}
    />
  );
}
