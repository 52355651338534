import * as React from "react";

import { Popover, Portal, Transition } from "@headlessui/react";

import Button from "@components/data-entry/Button";
import { fadeInOutProps } from "@components/transitions/FadeInOut";
import usePopup from "@helpers/Popup";

function ColorTile({
  color,
  selected = false,
}: {
  color: string;
  selected?: boolean;
}) {
  return (
    <i
      role="figure"
      aria-label={color}
      className={`transition-all duration-200 block w-5 h-5 rounded hover:border-primaryElectricBlue hover:border-2 ${
        selected
          ? "border-4 border-primaryElectricBlue"
          : "border border-primaryGrey"
      }`}
      style={{ backgroundColor: color }}
    />
  );
}

const colorOptions = [
  { value: "#FF9999" },
  { value: "#FFD099" },
  { value: "#FFF785" },
  { value: "#C9FF94" },
  { value: "#94E580" },
  { value: "#9EFFDC" },
  { value: "#A3EEFF" },
  { value: "#A3BDFF" },
  { value: "#D7A3FF" },
  { value: "#FFA3E3" },
];

interface ColorPickerProps {
  name: string;
  value: string;
  onChange: (value: string) => void;
}

export default function ColorPicker({
  name,
  value,
  onChange,
}: ColorPickerProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  const popup = usePopup({
    placement: "right",
  });

  return (
    <Popover role="group" aria-label={name}>
      <>
        <Popover.Button
          as={Button}
          ref={popup.setReferenceElement}
          className="relative flex items-center gap-2 rounded-lg form-input"
          type="button"
          theme="NONE"
          label="trigger"
          onClick={open}
        >
          {value ? <ColorTile color={value} /> : <ColorTile color="white" />}
        </Popover.Button>
        <Portal
          role="dialog"
          aria-label={name}
          as="div"
          className="fixed z-select"
        >
          <div
            ref={popup.setPopupElement}
            style={popup.styles.popper}
            {...popup.attributes.popper}
          >
            <Transition show={isOpen} {...fadeInOutProps}>
              <Popover.Panel
                static
                as="ul"
                className="grid grid-cols-10 gap-1 p-2 bg-white border rounded shadow-lg w-60 border-primaryLightElectricBlue"
              >
                {colorOptions.map((c) => (
                  <li key={c.value} className="flex items-center w-fit">
                    <button
                      value={c.value}
                      type="button"
                      aria-label={c.value}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onChange(c.value);
                        close();
                      }}
                    >
                      <ColorTile selected={c.value === value} color={c.value} />
                    </button>
                  </li>
                ))}
              </Popover.Panel>
            </Transition>
          </div>
        </Portal>
      </>
    </Popover>
  );
}
