import React from "react";

import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { HiExclamation } from "react-icons/hi";

import Tooltip from "@components/data-display/Tooltip";
import Button from "@components/data-entry/Button";
import Modal, { useModal } from "@components/feedback/Modal";

import OrderDeadlineForm from "./orderDeadlineForm";

interface OrderDeadlineIndicatorProps {
  deadline: Date | null | undefined;
  brandId: string | undefined;
  season: string;
  year: number;
}

function OrderDeadlineIndicator({
  deadline,
  brandId,
  season,
  year,
}: OrderDeadlineIndicatorProps) {
  const modalState = useModal();
  const { t } = useTranslation();

  if (!brandId) {
    return null;
  }

  return (
    <div>
      {deadline ? (
        <p className="flex flex-col items-center text-xs text-center w-36 ml-3">
          <span className="font-bold">Order deadline: </span>
          <span> {format(deadline, "MMM d, yyyy")}</span>
        </p>
      ) : (
        <div>
          <Tooltip
            placement="bottom"
            content={t("Calendar.order-deadline.missing-deadline")}
          >
            <Button
              theme="NONE"
              className="w-full items-center justify-center"
              onClick={modalState.open}
              label="Open order deadline form"
            >
              <HiExclamation className="h-7 w-7 fill-statusOrangeDark cursor-pointer" />
            </Button>
          </Tooltip>
          <Modal
            title={t("Calendar.order-deadline.add-order-deadline")}
            state={modalState}
            centeredTitle
            padding="p-9"
            aria-label={t("Calendar.order-deadline.add-order-deadline")}
          >
            <OrderDeadlineForm
              deadline={deadline}
              brandId={brandId}
              season={season}
              year={year}
              closeModal={modalState.close}
            />
          </Modal>
        </div>
      )}
    </div>
  );
}

export default OrderDeadlineIndicator;
