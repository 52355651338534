import React, { ReactNode } from "react";

import Loading from "@components/feedback/Loading";
import useAuthentication from "@services/authentication/useAuthentication";

function Authenticated({ children }: { children: ReactNode }) {
  const { authenticating } = useAuthentication();
  return (
    <>
      {!authenticating && children}
      {authenticating && <Loading type="screen" />}
    </>
  );
}

export default Authenticated;
