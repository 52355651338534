import React, { useId } from "react";

import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";

import Button from "@components/data-entry/Button";

interface CollapseProps {
  title: React.ReactNode;
  children: React.ReactNode;
  collapsed: boolean;
  onToggle: () => void;
  disabled?: boolean;
  className?: string;
  buttonClassName?: string;
  contentClassName?: string;
  divider?: boolean;
  titleJustify?: "start" | "end" | "center" | "between";
}

function Collapse({
  title,
  children,
  collapsed,
  onToggle,
  disabled = false,
  className = "",
  buttonClassName = "",
  contentClassName = "",
  divider = false,
  titleJustify = "center",
}: CollapseProps) {
  const id = useId();

  return (
    <div
      className={`rounded-lg mb-4 md:mb-6 flex flex-col gap-4 md:gap-6 ${className} ${
        collapsed ? "" : "pb-4 md:pb-6"
      } ${
        disabled
          ? "opacity-50 bg-primaryLightGrey cursor-not-allowed pointer-events-none"
          : "cursor-pointer"
      }`}
    >
      <Button
        theme="NONE"
        type="button"
        className={`w-full pt-4 md:pt-6 px-4 md:px-6 ${buttonClassName}`}
        onClick={onToggle}
        aria-expanded={!collapsed}
        aria-controls={`children-${id}`}
        justify={titleJustify}
      >
        {title}
        {collapsed ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />}
      </Button>
      {divider && collapsed && (
        <div className="h-1 border-b border-primaryLightGrey" />
      )}
      {collapsed && (
        <div
          id={`children-${id}`}
          className={`px-4 md:px-6 pb-4 md:pb-6 ${contentClassName}`}
          role="region"
          aria-labelledby={`button-${id}`}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export default Collapse;
