import React from "react";

import {
  HiChevronLeft,
  HiChevronRight,
  HiExclamationCircle,
  HiOutlineUsers,
} from "react-icons/hi2";

import {
  ShowroomFormData,
  useShowroomForm,
  validShowroomFormDataSchema,
} from "@app/modules/showroom/form/hook";
import InputLabel from "@components/data-display/InputLabel";
import TogglePanel from "@components/data-display/TogglePanel";
import Button from "@components/data-entry/Button";
import SingleSelect from "@components/data-entry/SingleSelect";
import TextField from "@components/data-entry/TextField";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import CalloutBox from "@components/feedback/CalloutBox";
import BottomBar from "@components/layout/BottomBar";
import { formatMinutes, generateTimeLengths } from "@helpers/Date";
import { usePrefixedTranslation } from "@helpers/Translation";
import { AppointmentTypeEnum } from "@models/types/enums";
import AppointmentIncrementPreview from "@sales-campaign/components/showroom/appointment-setup/increment-preview";
import { translateAppointmentType } from "@shared/helpers/translater";

const appointmentsSchema = validShowroomFormDataSchema
  .pick({
    appointmentFormats: true,
    appointmentDurations: true,
    specialRequestsAllowed: true,
    numberOfSeats: true,
    increment: true,
  })
  .passthrough();

function useAvailableIncrements(minDuration: number, maxDuration: number) {
  const { pt } = usePrefixedTranslation("Showroom.ShowroomAppointmentsForm");

  const availableIncrements: SelectOptionBase[] = [
    {
      label: pt("increment-appointment-duration"),
      value: "",
    },
  ];

  if (minDuration >= 30 && maxDuration > 30)
    availableIncrements.push({
      label: formatMinutes(30),
      value: "30",
    });

  if (minDuration >= 60 && maxDuration > 60)
    availableIncrements.push({
      label: formatMinutes(60),
      value: "60",
    });

  return availableIncrements;
}

interface Props {
  defaultValues?: Partial<ShowroomFormData>;
  onSubmit: (d: ShowroomFormData) => void;
  onBack: () => void;
}

export default function ShowroomAppointmentsForm({
  defaultValues,
  onSubmit,
  onBack,
}: Props) {
  const {
    form,
    toggleAppointmentFormat,
    setIncrement,
    setAppointmentDuration,
    setNumberOfSeats,
    toggleSpecialRequest,
  } = useShowroomForm({ defaultValues, schema: appointmentsSchema });
  const { pt, t } = usePrefixedTranslation("Showroom.ShowroomAppointmentsForm");

  const formValues = form.watch();
  const {
    collections,
    increment,
    appointmentDurations,
    appointmentFormats,
    openingHour,
    closingHour,
    appointmentTypes,
    numberOfSeats,
    specialRequestsAllowed,
  } = formValues;

  const durationOptions = generateTimeLengths(24).map<SelectOptionBase>(
    (time) => ({
      label: time.formatted,
      value: time.minutes.toString(),
    }),
  );

  const selectedDurations = appointmentDurations.map((aptd) => aptd.duration);

  const minDuration = Math.min(...selectedDurations);
  const maxDuration = Math.max(...selectedDurations);
  const availableIncrements = useAvailableIncrements(minDuration, maxDuration);

  const canSetupIncrements =
    appointmentTypes.length > 0 &&
    selectedDurations.length > 0 &&
    availableIncrements.length > 1;

  const longestAppointmentDuration = appointmentDurations.reduce(
    (acc, curr) => (curr.duration > acc.duration ? curr : acc),
    appointmentDurations[0],
  );
  const incrementPreview = longestAppointmentDuration
    ? {
        name:
          "collectionId" in longestAppointmentDuration
            ? collections.find(
                (c) => c.id === longestAppointmentDuration.collectionId,
              )?.name
            : t(translateAppointmentType(longestAppointmentDuration.type)),
        duration: longestAppointmentDuration.duration,
      }
    : {
        name: pt("no-collection"),
        duration: 30,
      };

  const getCollectionDuration = (collection: { id: string }) =>
    appointmentDurations.find((d) =>
      "collectionId" in d ? d.collectionId === collection.id : false,
    );
  const getTypeDuration = (type: AppointmentTypeEnum) =>
    appointmentDurations.find((d) => d.type === type);

  const handleSubmit = form.handleSubmit(onSubmit);

  return (
    <>
      <div className="grow p-4 flex flex-col gap-4">
        <InputLabel
          error={
            form.formState.errors.appointmentFormats
              ? pt("appointment-format-required")
              : undefined
          }
        >
          {pt("appoitment-formats-title")}
        </InputLabel>
        <TogglePanel
          initiallyOpened={appointmentFormats.includes("IN_PERSON")}
          title={t("SalesCampaign.edit.appointments.in-person")}
          subtitle={t("SalesCampaign.edit.appointments.in-person-description")}
          id="toggle-in-person"
          icon={<HiOutlineUsers className="w-4 h-4" />}
          onOn={() => toggleAppointmentFormat(true, "IN_PERSON")}
          onOff={() => toggleAppointmentFormat(false, "IN_PERSON")}
        />
        <TogglePanel
          initiallyOpened={appointmentFormats.includes("VIRTUAL")}
          title={t("SalesCampaign.edit.appointments.virtual")}
          subtitle={t("SalesCampaign.edit.appointments.virtual-description")}
          id="toggle-virtual"
          icon={<HiOutlineUsers className="w-4 h-4" />}
          onOn={() => toggleAppointmentFormat(true, "VIRTUAL")}
          onOff={() => toggleAppointmentFormat(false, "VIRTUAL")}
        />

        <InputLabel
          error={
            form.formState.errors.appointmentDurations
              ? pt("appointment-durations-required")
              : undefined
          }
        >
          {pt("appoitment-durations")}
        </InputLabel>
        <div className="grid grid-cols-2 gap-4">
          {collections.map((c) => {
            const collectionDuration = getCollectionDuration(c);
            return (
              <div key={c.id}>
                <p>
                  {c.brand.name} - {c.name}
                </p>
                <SingleSelect
                  aria-label={c.name}
                  options={durationOptions}
                  onChange={(durationOption) => {
                    if (durationOption) {
                      setAppointmentDuration(
                        AppointmentTypeEnum.BUYING_APPOINTMENT,
                        c.id,
                        parseInt(durationOption?.value, 10),
                      );
                    }
                  }}
                  value={
                    collectionDuration
                      ? {
                          label: formatMinutes(collectionDuration.duration),
                          value: `${collectionDuration.duration}`,
                        }
                      : undefined
                  }
                />
              </div>
            );
          })}
          {appointmentTypes
            .filter((type) => [AppointmentTypeEnum.WALKTHROUGH].includes(type))
            .map((type) => {
              const typeDuration = getTypeDuration(type);
              return (
                <div key={type}>
                  <p>{t(translateAppointmentType(type))}</p>
                  <SingleSelect
                    options={durationOptions}
                    onChange={(durationOption) => {
                      if (durationOption) {
                        setAppointmentDuration(
                          type,
                          null,
                          parseInt(durationOption?.value, 10),
                        );
                      }
                    }}
                    value={
                      typeDuration
                        ? {
                            label: formatMinutes(typeDuration.duration),
                            value: `${typeDuration.duration}`,
                          }
                        : undefined
                    }
                  />
                </div>
              );
            })}
        </div>

        <p className="font-bold text-lg">{pt("advanced-settings")}</p>
        <TogglePanel
          initiallyOpened={specialRequestsAllowed}
          title={pt("special-requests-title")}
          subtitle={pt("special-requests-subtitle")}
          id="special-request-toggle"
          icon={<HiExclamationCircle className="w-6 h-6" />}
          onOn={() => toggleSpecialRequest(true)}
          onOff={() => toggleSpecialRequest(false)}
        />

        <div className="flex flex-col gap-2 p-4 border rounded-lg border-primaryLightGrey">
          <InputLabel
            htmlFor="numberOfSeats"
            error={
              form.formState.errors.numberOfSeats
                ? pt("number-of-seats-should-be-positive")
                : undefined
            }
          >
            {pt("number-of-seats-label")}
          </InputLabel>
          <TextField
            id="numberOfSeats"
            placeholder="ex: 5"
            value={`${numberOfSeats}`}
            onChange={setNumberOfSeats}
            type="number"
          />
        </div>

        {canSetupIncrements ? (
          <div className="flex flex-row gap-4">
            <div className="grid grid-cols-1 items-center">
              <label className="font-medium" id="appointment-increment">
                {pt("increment-label")}
              </label>
              <SingleSelect
                aria-labelledby="appointment-increment"
                name="appointmentIncrement"
                placeholder={pt("increment-placeholder")}
                disabled={!canSetupIncrements}
                options={availableIncrements}
                value={
                  increment
                    ? {
                        label: `${increment} minutes`,
                        value: `${increment}`,
                      }
                    : undefined
                }
                onChange={(v) => setIncrement(v?.value)}
              />
            </div>
            <AppointmentIncrementPreview
              increment={increment || undefined}
              collection={incrementPreview}
              openingHour={openingHour}
              closingHour={closingHour}
            />
          </div>
        ) : (
          <CalloutBox type="INFORMATIVE">
            {t(
              "SalesCampaign.edit.appointments.appointment-increment.unavailable-explanation",
            )}
          </CalloutBox>
        )}
      </div>

      <BottomBar>
        <Button onClick={onBack}>
          <HiChevronLeft />
          {t("Common.previous")}
        </Button>
        <Button theme="PRIMARY" onClick={handleSubmit}>
          {t("Common.next")}
          <HiChevronRight />
        </Button>
      </BottomBar>
    </>
  );
}
