import {
  addMinutes,
  areIntervalsOverlapping,
  format,
  isBefore,
  isEqual,
  subMinutes,
} from "date-fns";

import { SellerInformations } from "@booking/helpers/booking";
import { AppointmentFormatWithMeetingApp } from "@models/types/enums";

export interface TimeSlot {
  start: Date;
  end: Date;
}
export interface TimeSlotWithDisability extends TimeSlot {
  disabled: boolean;
}

/**
 * When a competitor has booked an appointment, we don't want to present slots that are too close
 * So we add a safety margin (in minutes) before and after the appointment.
 *
 * WHY 30 MINUTES ? because we made 2 assumptions :
 * - a buyer can arrive 15min early
 * - a buyer can stay 15min longer
 * so we need 30 minutes
 *
 * Ex: if a competitor books an appointment from 9:00 to 10:00, we won't present options from 8:30 to 10:30
 */
const COMPETITORS_GROUPS_MARGIN_MINUTES = 30;

/**
 * This function is used to get the "Theoretical available slots" for a day.
 * @see /doc/booking/available-slots-computing.png
 *
 * @param start datetime the showroom starts
 * @param end datetime the showroom ends
 * @param durationMin duration in minutes
 * @param breaks timeslots where the showroom closes
 * @param excludeHours timeslots where appointments cannot be taken
 * @param everyMin increment between available timeslots in minutes
 *
 * WARNING : start & end parameters should use the showroom's timezone, not the current user's timezone.
 *
 * This function creates a list of available slots:
 * - from "start" to "end" (included)
 * - with the specified "durationMin" in minutes
 * - every "everyMin" minutes.
 *
 * By default, everyMin = durationMin, so the function will return non-overlapping slots.
 *
 * Examples:
 * start = 10:00, end = 11:00, durationMin = 30
 * will return:
 * [
 *   { start: 10:00, end: 10:30 },
 *   { start: 10:30, end: 11:00 },
 * ]
 *
 * start = 10:00, end = 11:00, durationMin = 30, everyMin = 15
 * will return:
 * [
 *   { start: 10:00, end: 10:30 },
 *   { start: 10:15, end: 10:45 },
 *   { start: 10:30, end: 11:00 },
 * ]
 *
 */
export function buildAvailableSlots(
  start: Date,
  end: Date,
  durationMin: number,
  breaks: TimeSlot[],
  excludeHours: TimeSlot[],
  everyMin: number = durationMin,
) {
  const slots: TimeSlot[] = [];

  if (durationMin <= 0 || isBefore(end, start) || isEqual(end, start)) {
    return slots;
  }

  // determine each portion of day to calculate, depending on breaks
  const portions: TimeSlot[] = [];
  if (breaks.length === 0) {
    portions.push({
      start,
      end,
    });
  } else {
    // if there are N breaks add N+1 portions
    // sort breaks by start time
    let portionStart = start;
    breaks.sort((a, b) => a.start.getTime() - b.start.getTime());
    breaks.forEach((breakTimeslot) => {
      if (isBefore(portionStart, breakTimeslot.start)) {
        portions.push({
          start: portionStart,
          end: breakTimeslot.start,
        });
        portionStart = breakTimeslot.end;
      }
    });
    portions.push({
      start: portionStart,
      end,
    });
  }

  // for each portion, compute the theoretically available slots
  portions.forEach((portion, portionIndex, arr) => {
    let appointmentStart = portion.start;
    let appointmentEnd = addMinutes(appointmentStart, durationMin);
    const isLastPortion = portionIndex + 1 === arr.length;
    while (
      isBefore(appointmentEnd, portion.end) ||
      isEqual(appointmentEnd, portion.end)
    ) {
      slots.push({
        start: appointmentStart,
        end: appointmentEnd,
      });
      appointmentStart = addMinutes(appointmentStart, everyMin);
      appointmentEnd = addMinutes(appointmentEnd, everyMin);
    }
    // the last slot is available if
    // 1. it starts before the end
    // 2. it ends at most 30 mins after the end
    // the reason for those 2 conditions :
    // 1. a slot should not start outside of the expected hours
    // 2. a slot should be available if an appointment lasts 2hrs and only the last 30mins are outside the expected hours
    if (
      isBefore(appointmentStart, portion.end) &&
      isLastPortion &&
      isBefore(appointmentEnd, addMinutes(portion.end, 31))
    ) {
      slots.push({
        start: appointmentStart,
        end: appointmentEnd,
      });
    }
  });

  return slots.filter((s) =>
    excludeHours.every(
      (excludeInterval) => !areIntervalsOverlapping(s, excludeInterval),
    ),
  );
}

function getGroupingKey(slot: TimeSlot) {
  return `${format(slot.start, "HH:mm")} - ${format(slot.end, "HH:mm")}`;
}

function isSlotInThePast(slot: TimeSlot, now: Date) {
  return slot.start < now;
}

// a slot is booked if there are booked appointments
// and if there is at least one that overlaps the theoretically available slot
function isSlotBooked(slot: TimeSlot, appointments: TimeSlot[]) {
  return (
    appointments.length > 0 &&
    undefined !==
      appointments.find((appointment) =>
        areIntervalsOverlapping(appointment, slot),
      )
  );
}

export type GroupedAvailableSlots = TimeSlotWithDisability & {
  key: string;
  sellerInformations: SellerInformations[];
};

export function computeAvailableSlots(
  atTime: Date,
  from: Date,
  to: Date,
  appointmentDuration: number,
  breaks: TimeSlot[],
  sellerInformations: SellerInformations[],
  bookedSlots: ({
    sellerId: string;
    isCompetitor: boolean;
  } & TimeSlot)[],
  filterValues: {
    formats: AppointmentFormatWithMeetingApp[];
    languages: string[];
  },
  step: number | null,
): GroupedAvailableSlots[] {
  const { formats: filteredFormats, languages: filteredLanguages } =
    filterValues;

  // FILTER SELLERINFORMATIONS BY FILTERVALUES
  const filteredSellerInformations = sellerInformations.filter(
    (si) =>
      (filteredFormats.length === 0 ||
        filteredFormats.some((f) => si.formatsWithMeetingApps.includes(f))) &&
      (filteredLanguages.length === 0 ||
        filteredLanguages.some((l) => si.languages.includes(l))),
  );

  if (filteredSellerInformations.length === 0) {
    return [];
  }

  // CREATE A TEMPLATE OF THE THEORETICAL AVAILABLE SLOTS THAT WILL BE USED AS A BASE FOR EACH SELLER

  // first filter out the bookedAppointments that are from competitors and enlarge them by 30min
  const competitorsSlotsToExclude = bookedSlots
    .filter((bs) => bs.isCompetitor)
    .map((bs) => ({
      ...bs,
      start: subMinutes(bs.start, COMPETITORS_GROUPS_MARGIN_MINUTES),
      end: addMinutes(bs.end, COMPETITORS_GROUPS_MARGIN_MINUTES),
    }));

  const theoreticalAvailableSlots = buildAvailableSlots(
    from,
    to,
    appointmentDuration,
    breaks,
    [...competitorsSlotsToExclude],
    step || appointmentDuration,
  );

  // MARK AS DISABLED THE SLOTS OVERLAPPING WITH ANY BOOKED SLOT
  const filteredBookedSlots = bookedSlots.filter((b) =>
    filteredSellerInformations.map((si) => si.id).includes(b.sellerId),
  );

  // PUT ALL THE SLOTS OF EVERY SELLER IN A FLAT ARRAY
  const slotsWithDisability: (TimeSlotWithDisability & SellerInformations)[] =
    [];
  filteredSellerInformations.forEach((sellerInfo) => {
    // retrieve all the seller's appointments
    const sellerBookedSlots = filteredBookedSlots.filter(
      (b) => b.sellerId === sellerInfo.id,
    );

    // mark each slot that overlaps a booked appointment as disabled
    const sellerSlotsWithDisability = theoreticalAvailableSlots.map(
      (theoreticalAvailableSlot) => ({
        ...theoreticalAvailableSlot,
        ...sellerInfo,
        disabled:
          isSlotBooked(theoreticalAvailableSlot, sellerBookedSlots) ||
          isSlotInThePast(theoreticalAvailableSlot, atTime),
      }),
    );

    slotsWithDisability.push(...sellerSlotsWithDisability);
  });

  // GROUP THE SLOTS FROM THE FLAT ARRAY BY THEIR STARTTIME/ENDTIME COMBINATION
  const aggregatedSlots = slotsWithDisability.reduce(
    (acc, slot, index, arr) => {
      // each slot's startTime/endTime combination will serve as a grouping key
      const currentSlotGroupingKey = getGroupingKey(slot);

      // if that grouping key was already processed, continue to next item
      if (acc.some((a) => a.key === currentSlotGroupingKey)) {
        return acc;
      }

      // otherwise process that date
      // get all available slots with the same grouping key
      // this should at least return 1 slot : the current slot
      const matchingSlots = arr.filter(
        (s) => getGroupingKey(s) === currentSlotGroupingKey,
      );

      // check out if there are any enabled slot within that group
      const availableMatchingSlots = matchingSlots.filter((s) => !s.disabled);

      acc.push({
        key: currentSlotGroupingKey,
        start: slot.start,
        end: slot.end,
        // the aggregated slot is disabled there is no enabled matching slots
        disabled: availableMatchingSlots.length === 0,
        // the seller informations are taken from each enabled matching slot
        sellerInformations: availableMatchingSlots.map(
          ({ start: _start, end: _end, disabled: _disabled, ...rest }) => rest,
        ),
      });
      return acc;
    },
    [] as GroupedAvailableSlots[],
  );

  // NOW ORDER THAT BY THEIR STARTTIME/ENDTIME COMBINATION
  return aggregatedSlots.sort((a, b) => {
    if (a.start < b.start) {
      return -1;
    }
    if (a.start > b.start) {
      return 1;
    }
    return 0;
  });
}

export function getSlotsWithNoSeatsAvailable(
  seats: number | null,
  bookedSlots: TimeSlot[],
) {
  if (seats === null) {
    return [];
  }

  // flatten the bookedSlots start/end to get every single event (start or end of an appointment)
  const events = bookedSlots.flatMap((bookedSlot) => [
    { time: bookedSlot.start, type: "take_seat" },
    { time: bookedSlot.end, type: "free_seat" },
  ]);
  // sort them chronologically, with "free_seat" events first (cause it makes more sense to free a)
  events.sort((a, b) => {
    const diff = a.time.getTime() - b.time.getTime();
    if (diff === 0) {
      return a.type === "free_seat" ? -1 : 1;
    }
    return diff;
  });

  const slotsWithNoSeats: TimeSlot[] = [];
  let cursorSeatsTaken: number = 0;
  let currentSlotWithoutSeatsStart: Date | null = null;

  events.forEach((event) => {
    if (event.type === "take_seat") {
      cursorSeatsTaken += 1;
      // if we are reaching the limit record the event time as the start
      if (cursorSeatsTaken === seats) {
        currentSlotWithoutSeatsStart = event.time;
      }
    } else if (event.type === "free_seat") {
      cursorSeatsTaken -= 1;
      // if we are reaching the limit, record the event time as the end
      if (currentSlotWithoutSeatsStart && cursorSeatsTaken < seats) {
        slotsWithNoSeats.push({
          start: currentSlotWithoutSeatsStart,
          end: event.time,
        });
        currentSlotWithoutSeatsStart = null;
      }
    }
  });

  return slotsWithNoSeats;
}
