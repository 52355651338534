import { RealDate, ZonedDate } from "@helpers/Date";
import { Account, AccountStatus } from "@models/Account";
import { Brand } from "@models/Brand";
import { Collection } from "@models/Collection";
import { Appointment, SerializedAppointment } from "@models/old/Appointment";
import { Contact } from "@models/old/Contact";
import { Organization } from "@models/old/Organization";
import Showroom from "@models/old/Showroom";
import { ShowroomSeason } from "@models/types/enums";
import axiosInstance from "@services/api/config";
import { Endpoint, Params } from "@services/api/helper";
import { unserializeShowroom } from "@services/api/old/sales-campaign/ShowroomMapper";
import { GetPortfoliosResponse } from "@services/api/portfolios/get-portfolios";

type FetchBookingParams = Params<[string]>; // we pass one argument: the invitationId

export interface FetchBookingResponse {
  organization: Organization;
  showrooms: Array<
    Showroom & {
      collections: Array<
        Collection & {
          brand: Brand;
        }
      >;
    }
  >;
  contact: Contact & {
    account: Account & {
      collectionStatuses: Array<{
        collectionId: string;
        status: AccountStatus;
      }>;
      salesBudgets: Array<{
        collectionId: string;
        accountId: string;
        season: ShowroomSeason;
        year: number;
        otb: number | null;
      }>;
    };
  };
  invitedColleagues: Contact[];
  portfolios: GetPortfoliosResponse;
}

const path = (...params: FetchBookingParams) => `/bookings/${params[0]}`;

const get = async (...[invitationId]: FetchBookingParams) =>
  axiosInstance
    .get(path(invitationId))
    .then((res) => res.data)
    .then(
      (data) =>
        ({
          ...data,
          appointments: (data.appointments || []).map(
            (serializedAppointment: SerializedAppointment): Appointment => ({
              ...serializedAppointment,
              startTime: new ZonedDate(
                new Date(serializedAppointment.startTime) as RealDate,
              ),
              endTime: new ZonedDate(
                new Date(serializedAppointment.endTime) as RealDate,
              ),
            }),
          ),
          showrooms: data.showrooms.map(unserializeShowroom),
        }) as FetchBookingResponse,
    );

const fetchBookingEndpoint = new Endpoint<
  FetchBookingParams,
  FetchBookingResponse
>(path, get, ["booking"]);

export default fetchBookingEndpoint;
