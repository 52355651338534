import React from "react";

import SingleSelect, {
  SingleSelectProps,
} from "@components/data-entry/SingleSelect";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import { Representative } from "@models/OrganizationRepresentative";
import { fullName } from "@shared/helpers/formatters";

function representativeToOption(
  representative: Representative,
  props: Partial<SelectOptionBase> = {},
): SelectOptionBase {
  return {
    label: fullName(representative),
    value: representative.id,
    ...props,
  };
}

interface RepresentativeSelectProps
  extends Omit<SingleSelectProps, "value" | "onChange"> {
  representatives: Representative[];
  onChange: (representative: Representative | undefined) => void;
  value: string | undefined;
}

export default function RepresentativeSelect({
  representatives,
  onChange,
  value,
  ...props
}: RepresentativeSelectProps) {
  const selectedRepresentative = value
    ? representatives.find((acc) => acc.id === value)
    : undefined;
  const selectedOption = selectedRepresentative
    ? representativeToOption(selectedRepresentative)
    : undefined;

  return (
    <SingleSelect
      onChange={(newValue) => {
        if (newValue) {
          onChange(representatives.find((acc) => acc.id === newValue.value));
        } else {
          onChange(undefined);
        }
      }}
      value={selectedOption}
      options={representatives.map((acc) => representativeToOption(acc))}
      {...props}
    />
  );
}
