import { useQuery } from "@tanstack/react-query";

import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

export const fetchAccountsArchivedCountEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/accounts/archived/count`,
};

export const queryKeysFetchAccountsArchivedCount = (organizationId: string) =>
  fetchAccountsArchivedCountEndpoint.path(organizationId).split("/");

export const useFetchAccountsArchivedCount = (organizationId: string) =>
  useQuery({
    queryKey: queryKeysFetchAccountsArchivedCount(organizationId),
    queryFn: () =>
      axiosInstance
        .get<{
          count: number;
        }>(fetchAccountsArchivedCountEndpoint.path(organizationId))
        .then((res) => res.data),
  });
