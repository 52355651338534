import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { accountSchema } from "@models/Account";
import { organizationRepresentativeSchema } from "@models/OrganizationRepresentative";
import { portfolioSchema } from "@models/Portfolio";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export interface GetPortfoliosParams {
  organizationId: string;
}

export const getPortfoliosItemSchema = portfolioSchema.extend({
  manager: organizationRepresentativeSchema.pick({
    id: true,
    firstName: true,
    lastName: true,
    role: true,
  }),
  sellers: z.array(organizationRepresentativeSchema),
  accounts: z.array(accountSchema.pick({ id: true })),
});
export type GetPortfoliosResponseItem = z.infer<typeof getPortfoliosItemSchema>;
export type GetPortfoliosResponse = Array<GetPortfoliosResponseItem>;

export const getPortfoliosEndpoint = {
  path: ({ organizationId }: GetPortfoliosParams) =>
    `/organizations/${organizationId}/portfolios`,
  call: ({ organizationId }: GetPortfoliosParams) =>
    axiosInstance
      .get<GetPortfoliosResponse>(
        getPortfoliosEndpoint.path({ organizationId }),
      )
      .then((response) => response.data),
};

export function useGetPortfolios(params: GetPortfoliosParams) {
  return useQuery({
    queryKey: getAPIQueryKey(getPortfoliosEndpoint.path(params)),
    queryFn: () => getPortfoliosEndpoint.call(params),
  });
}
