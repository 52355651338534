import React from "react";

import InlineTableFilterSelect from "@components/data-entry/inline-filter/select";
import InlineTableFilterText from "@components/data-entry/inline-filter/text";
import InlineTableFilterWrapper from "@components/data-entry/inline-filter/wrapper";
import { usePrefixedTranslation } from "@helpers/Translation";
import { InvitationStatusEnum } from "@models/types/enums";
import { translateInvitationStatus } from "@shared/helpers/translater";

type FilterValues = {
  invitationStatus: InvitationStatusEnum | "any";
  showroomId: string | "any";
};

type Props = {
  values?: FilterValues;
  showrooms: {
    id: string;
    name: string;
  }[];
  onChange: (values: FilterValues) => void;
};

export default function InvitationStatusFilter({
  showrooms,
  onChange,
  values = {
    invitationStatus: "any",
    showroomId: "any",
  },
}: Props) {
  const { pt, t } = usePrefixedTranslation("Invitation.InvitationStatusFilter");

  const handleValueChange =
    (key: keyof FilterValues) => (value: FilterValues[typeof key]) => {
      const newValues: FilterValues = {
        ...values,
        [key]: value,
      };
      onChange(newValues);
    };

  return (
    <InlineTableFilterWrapper>
      <InlineTableFilterText>
        {pt("filter-invite-status-is")}
      </InlineTableFilterText>
      <InlineTableFilterSelect
        value={values.invitationStatus}
        options={[
          {
            label: pt("any-invitation-status"),
            value: "any",
          },
          {
            label: t(
              translateInvitationStatus(InvitationStatusEnum.NOT_INVITED),
            ),
            value: InvitationStatusEnum.NOT_INVITED,
          },
          {
            label: t(translateInvitationStatus(InvitationStatusEnum.INVITED)),
            value: InvitationStatusEnum.INVITED,
          },
        ]}
        onChange={(status) => handleValueChange("invitationStatus")(status)}
      />
      <InlineTableFilterText>
        {pt("filter-invite-status-for-showroom")}
      </InlineTableFilterText>
      <InlineTableFilterSelect
        value={values.showroomId}
        options={[
          {
            label: pt("any-showrooms"),
            value: "any",
          },
          ...showrooms.map((s) => ({
            label: s.name,
            value: s.id,
          })),
        ]}
        onChange={(showroomId) => handleValueChange("showroomId")(showroomId)}
      />
    </InlineTableFilterWrapper>
  );
}
