import { AccountStatus } from "@models/Account";
import { OrganizationType } from "@models/Organization";
import {
  AppointmentFormat,
  AppointmentTypeEnum,
  InvitationStatusEnum,
  MailDeliveryStatusEnum,
  MarketType,
  VirtualMeetingApps,
} from "@models/types/enums";

export function translateAccountStatus(accountStatus: AccountStatus): string {
  return `Common.account-status.${accountStatus}`;
}

export function translateOrganizationType(type: OrganizationType): string {
  return `Common.organization-type.${type}`;
}

export function translateAppointmentType(appointmentType: AppointmentTypeEnum) {
  return `Common.appointment-type.${appointmentType}`;
}

export function translateAppointmentFormatWithApp(
  format: AppointmentFormat,
  app?: VirtualMeetingApps | "ANY" | null,
) {
  if (format === "IN_PERSON") {
    return "Common.appointment-format.IN_PERSON";
  }

  if (app) {
    return `Common.virtual-tool.${app}`;
  }

  return "Common.appointment-format.VIRTUAL";
}

export function translateVirtualApp(app: VirtualMeetingApps | "ANY") {
  return `Common.virtual-tool.${app}`;
}

export function translateInvitationStatus(status: InvitationStatusEnum) {
  return `Common.invitation-status.${status}`;
}

export function translateMarketType(type: MarketType) {
  return `Common.market-type.${type}`;
}

export function translateMailDeliveryStatus(status: MailDeliveryStatusEnum) {
  return `Common.mail-delivery-status.${status}`;
}
