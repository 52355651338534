import React from "react";

import { useTranslation } from "react-i18next";

import BookedAppointmentCard from "@booking/components/booked-appointment-card";
import { loadAndRender } from "@components/feedback/LoadingFetch";
import { GetBuyerAppointment } from "@services/api/appointments/GetBuyerAppointment";
import { translateAppointmentType } from "@shared/helpers/translater";

interface BookedAppointmentCardContainerProps {
  appointmentId: string;
}

function BookedAppointmentCardContainer({
  appointmentId,
}: BookedAppointmentCardContainerProps) {
  const { t } = useTranslation();
  const query = GetBuyerAppointment.useHook({ appointmentId });

  return loadAndRender(query, ({ data: appointment }) => (
    <>
      <div
        className="text-base pb-2"
        data-testid={`appointment-list-collectionId-${
          appointment.collection?.id || appointment.type
        }`}
      >
        {appointment.collection ? (
          <>
            <span className="text-lg font-bold">
              {appointment.collection.name}
            </span>
            <span className="text-sm text-primaryDarkGrey">
              {" - "}
              {appointment.collection.brand.name}
            </span>
          </>
        ) : (
          t(translateAppointmentType(appointment.type))
        )}
      </div>
      <BookedAppointmentCard appointment={appointment} />
    </>
  ));
}

export default BookedAppointmentCardContainer;
