import React from "react";

import { Navigate } from "react-router-dom";

import useApplicationContext from "@services/application/useApplicationContext";
import useAuthentication from "@services/authentication/useAuthentication";
import ConditionalRender from "@shared/components/on";

export default function OnboardingNotDone({
  children,
}: {
  children: React.JSX.Element;
}) {
  const { currentUser } = useAuthentication();
  const { organization } = useApplicationContext();

  if (currentUser && currentUser.role === "MODARESA_ADMIN" && !organization) {
    return <Navigate to="/backoffice/list-organizations" replace />;
  }

  if (!organization) {
    throw new Error(
      "You are not associated with a organization. Please contact support.",
    );
  }

  return (
    <ConditionalRender
      renderIf={organization.onboardingStatus !== "DONE"}
      fallback={<Navigate to="/dashboard" />}
    >
      {children}
    </ConditionalRender>
  );
}
