import React, { useEffect, useState } from "react";

import { t } from "i18next";
import { Trans } from "react-i18next";
import { toast } from "react-toastify";

import RepresentativesHeader from "@app/modules/representatives/components/header";
import RepresentativesFilters from "@app/modules/representatives/components/representatives-filters";
import RepresentativesTable from "@app/modules/representatives/components/representatives-table";
import UpsertRepresentativeForm from "@app/modules/representatives/components/upsert-representative-form";
import globalFilterRepresentatives from "@app/modules/representatives/helpers/filter-representatives";
import queryClient from "@app/queryClient";
import ConfirmModal from "@components/feedback/ConfirmModal";
import Drawer, { useDrawer } from "@components/feedback/Drawer";
import InformationModal from "@components/feedback/InformationModal";
import LoadingFetch from "@components/feedback/LoadingFetch";
import { queryKeysFetchRepresentatives } from "@services/api/old/representatives/fetch-representatives";
import { queryKeysFetchRepresentativesArchivedCount } from "@services/api/old/representatives/fetch-representatives-archived-count";
import { queryKeysFetchRepresentativesCount } from "@services/api/old/representatives/fetch-representatives-count";
import { usePostInviteRepresentative } from "@services/api/old/representatives/post-invite-representative";
import { usePutArchiveRepresentative } from "@services/api/old/representatives/put-archive-representative";
import { GetRepresentativesFromOrganizationEndpoint } from "@services/api/representatives/get-organization-representatives";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import LogService from "@services/log/service";
import { fullName } from "@shared/helpers/formatters";

type Representative = GetRepresentativesFromOrganizationEndpoint.Output[number];

function RepresentativesList() {
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const { usr: locationState } = window.history.state;

  const [selectedRepresentativeToArchive, setSelectedRepresentativeToArchive] =
    useState<Representative | undefined>();
  const [selectedRepresentativeToEdit, setSelectedRepresentativeToEdit] =
    useState<Representative | undefined>();
  const upsertRepresentativeDrawer = useDrawer({
    onClose: () => {
      setSelectedRepresentativeToEdit(undefined);
    },
  });
  const [conflictError, setConflictError] = useState<
    { type: string; conflicts: { name: string }[] } | undefined
  >();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<string | undefined>();

  // When locationState, validate if exists a state to open the drawer
  // on this case, the usage is navigate("/representatives/list", { state: "open-drawer" });
  useEffect(() => {
    if (locationState && locationState === "open-drawer") {
      if (!upsertRepresentativeDrawer.isOpen) {
        setTimeout(() => {
          upsertRepresentativeDrawer.openDrawer();
          window.history.replaceState({}, document.title);
        }, 300);
      }
    }
  }, [locationState, upsertRepresentativeDrawer]);

  const { mutateAsync: putArchiveRepresentative } =
    usePutArchiveRepresentative(organizationId);

  const { mutateAsync: postInviteRepresentative } =
    usePostInviteRepresentative(organizationId);

  const { data: representativesList = [], status: representativesFetchStatus } =
    GetRepresentativesFromOrganizationEndpoint.useHook({ organizationId });

  const filteredRepresentatives =
    representativesList &&
    globalFilterRepresentatives(representativesList, filter);

  const handleConfirmArchiveAccount = () => {
    if (!selectedRepresentativeToArchive) return;

    putArchiveRepresentative(selectedRepresentativeToArchive?.id)
      .then(async () => {
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchRepresentatives(organizationId),
        });
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchRepresentativesCount(organizationId),
        });
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchRepresentativesArchivedCount(organizationId),
        });
        setIsConfirmModalOpen(false);
        toast.success(t("Representatives.confirm-representative-archive"));
      })
      .catch((err) => {
        setIsConfirmModalOpen(false);
        if (err.statusCode === 409) {
          setConflictError(err);
        } else {
          LogService.error(err);
        }
      });
  };

  return (
    <div>
      <RepresentativesHeader
        onOpenDrawer={() => {
          upsertRepresentativeDrawer.openDrawer();
        }}
      />

      <div className="flex flex-col gap-6 p-6 pt-2">
        <RepresentativesFilters filter={filter} onChange={setFilter} />
        <LoadingFetch
          error={t("Representatives.could-not-fetch-representatives")}
          status={representativesFetchStatus}
        >
          <RepresentativesTable
            representatives={
              filteredRepresentatives?.sort((a, b) =>
                a.firstName.localeCompare(b.firstName),
              ) || []
            }
            onClickEdit={(representative) => {
              setSelectedRepresentativeToEdit(representative);
              upsertRepresentativeDrawer.openDrawer();
            }}
            onClickDelete={(representative) => {
              setSelectedRepresentativeToArchive(representative);
              setIsConfirmModalOpen(true);
            }}
            onClickInvite={(representativeId) => {
              postInviteRepresentative(representativeId).then(() => {
                toast.success(t("Representatives.send-invite-success"));
              });
            }}
          />
        </LoadingFetch>
      </div>

      <Drawer
        {...upsertRepresentativeDrawer.props}
        name="creating or updating representative drawer"
        backdrop
        drawerTitle={
          <h2 className="heading-2-mobile lg:heading-2">
            {selectedRepresentativeToEdit
              ? fullName(selectedRepresentativeToEdit)
              : t("Representatives.form.new-representative")}
          </h2>
        }
        size="LARGE"
      >
        <UpsertRepresentativeForm
          selectedRepresentative={selectedRepresentativeToEdit}
          onCancel={upsertRepresentativeDrawer.closeWithoutConfirmation}
          onSuccess={upsertRepresentativeDrawer.closeWithoutConfirmation}
          onError={upsertRepresentativeDrawer.closeWithoutConfirmation}
        />
      </Drawer>
      <InformationModal
        show={conflictError !== undefined}
        onClose={() => {
          setConflictError(undefined);
        }}
        title={t("Representatives.archive-representative-error-title")}
      >
        {conflictError?.type === "useInActiveSalesCampaigns" && (
          <p>
            {t(
              "Representatives.archive-representative-error-content-sales-campaign",
            )}
          </p>
        )}
        {conflictError?.type === "lastSellerOrManagerInPortfolios" && (
          <p>
            {t(
              "Representatives.archive-representative-error-content-portfolio",
            )}
          </p>
        )}
        <ul className="list-disc my-4 pl-7">
          {conflictError?.conflicts?.map(({ name }) => (
            <li key={`sales_key_${name}`}>{name}</li>
          ))}
        </ul>
        {conflictError?.type === "useInActiveSalesCampaigns" && (
          <p>
            {t(
              "Representatives.archive-representative-error-wait-sales-campaign",
            )}
          </p>
        )}
      </InformationModal>
      <ConfirmModal
        show={isConfirmModalOpen}
        title={t("Representatives.archive-representative-title")}
        confirmLabel={t(
          "Representatives.archive-representative-confirm-button",
        )}
        cancelLabel={t("Components.buttons.cancel")}
        theme="DANGER"
        onCancel={() => {
          setIsConfirmModalOpen(false);
          setSelectedRepresentativeToArchive(undefined);
        }}
        onConfirm={() => {
          handleConfirmArchiveAccount();
          setSelectedRepresentativeToArchive(undefined);
        }}
      >
        <Trans
          i18nKey="Representatives.archive-representative-content"
          values={{
            accountName:
              selectedRepresentativeToArchive &&
              fullName(selectedRepresentativeToArchive),
          }}
          components={{
            bold: <strong className="font-bold" />,
            break: <br />,
          }}
        >
          Representatives.archive-representative-content
        </Trans>
      </ConfirmModal>
    </div>
  );
}

export default RepresentativesList;
