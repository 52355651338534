import { useReducer } from "react";

import { isBefore } from "date-fns";
import { z } from "zod";

import { Cruising } from "@app/modules/cruising/types";

export namespace ShowroomsTableState {
  type RowWithoutGeoCode = Cruising.NewShowroom;
  type RowWithGeoCode = Cruising.NewShowroom & {
    lat: number;
    lng: number;
    id: string;
  };

  export type Row = RowWithGeoCode | RowWithoutGeoCode;

  export function isRowWithGeocode(s: Row): s is RowWithGeoCode {
    return "lat" in s;
  }

  export function removeGeocode({
    lat: _unused1,
    lng: _unused2,
    id: _unused3,
    ...r
  }: RowWithGeoCode): RowWithoutGeoCode {
    return r;
  }

  export type State = {
    showrooms: Row[];
    errors: string[];
  };
  const makeInitialState = (initialShowrooms: Row[]): State => ({
    showrooms: initialShowrooms,
    errors: [],
  });

  type SetShowroomsAction = {
    type: "setShowrooms";
    showrooms: Row[];
  };
  const setShowrooms = (state: State, showrooms: Row[]): State => ({
    ...state,
    showrooms,
  });

  type AddShowroomAction = {
    type: "addShowroom";
    showroom: Row;
  };
  const addShowroom = (state: State, showroom: Row): State => ({
    ...state,
    showrooms: [...state.showrooms, showroom],
  });

  type EditShowroomAction = {
    type: "editShowroom";
    index: number;
    showroom: Row;
  };
  const editShowroom = (state: State, index: number, showroom: Row): State => {
    const addressChanged =
      state.showrooms[index].address.formattedAddress !==
      showroom.address.formattedAddress;
    const newShowroom =
      addressChanged && isRowWithGeocode(showroom)
        ? removeGeocode(showroom)
        : (showroom satisfies Row);
    return {
      ...state,
      showrooms: state.showrooms.toSpliced(index, 1, newShowroom),
    };
  };

  type RemoveShowroomAction = {
    type: "removeShowroom";
    index: number;
  };
  const removeShowroom = (state: State, index: number) => ({
    ...state,
    showrooms: state.showrooms.toSpliced(index, 1),
  });

  type ClearAction = {
    type: "clear";
  };

  export type Action =
    | SetShowroomsAction
    | AddShowroomAction
    | ClearAction
    | RemoveShowroomAction
    | EditShowroomAction;

  const reducer = (state: State, action: Action) => {
    switch (action.type) {
      case "setShowrooms":
        return setShowrooms(state, action.showrooms);
      case "addShowroom":
        return addShowroom(state, action.showroom);
      case "removeShowroom": {
        return removeShowroom(state, action.index);
      }
      case "editShowroom":
        return editShowroom(state, action.index, action.showroom);
      case "clear":
        return {
          ...state,
          showrooms: [],
        };
      default:
        return state;
    }
  };

  export interface UseStateProps {
    initialShowrooms: Row[];
  }

  export function useState({ initialShowrooms }: UseStateProps) {
    const [state, dispatch] = useReducer(reducer, null, () =>
      makeInitialState(initialShowrooms),
    );

    return {
      state,
      dispatch,
    };
  }

  const addressValidationSchema = z
    .object({
      city: z.string(),
      countryCode: z.string(),
      formattedAddress: z.string(),
      postalCode: z.string(),
      addressComponents: z.any(),
    })
    .refine(
      (address) =>
        address.formattedAddress.length > 0 &&
        address.city.length > 0 &&
        address.countryCode.length > 0 && {
          message: "Cruising.ShowroomsTable.errors.address-invalid",
        },
    );
  const fromToValidationSchema = z
    .object({
      from: z.date(),
      to: z.date(),
    })
    .refine((value) => isBefore(value.from, value.to), {
      message: "Cruising.ShowroomsTable.errors.from-must-be-before-to",
    });
  const startEndValidationSchema = z
    .object({
      endTime: z.date(),
      startTime: z.date(),
    })
    .refine((value) => isBefore(value.startTime, value.endTime), {
      message:
        "Cruising.ShowroomsTable.errors.start-time-must-be-before-end-time",
    });
  const durationValidationSchema = z.object({
    duration: z.number().min(15, {
      message: "Cruising.ShowroomsTable.errors.duration-must-be-more-than-15m",
    }),
  });

  export function validateShowroom(showroom: Row) {
    const fromToValidation = fromToValidationSchema.safeParse(showroom);
    const startEndValidation = startEndValidationSchema.safeParse(showroom);
    const addressValidation = addressValidationSchema.safeParse(
      showroom.address,
    );
    const durationValidation = durationValidationSchema.safeParse(showroom);

    return {
      address: addressValidation.success
        ? undefined
        : addressValidation.error.errors[0].message,
      fromTo: fromToValidation.success
        ? undefined
        : fromToValidation.error.errors[0].message,
      startEnd: startEndValidation.success
        ? undefined
        : startEndValidation.error.errors[0].message,
      duration: durationValidation.success
        ? undefined
        : durationValidation.error.errors[0].message,
    };
  }

  export function tableHasErrors(state: State) {
    return state.showrooms
      .map(validateShowroom)
      .some(
        (errors) =>
          errors.address !== undefined ||
          errors.fromTo !== undefined ||
          errors.startEnd !== undefined ||
          errors.duration !== undefined,
      );
  }
}
