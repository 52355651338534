import {
  City,
  Country,
  GeographicalFilterType,
  Geography,
} from "@models/types/Geography";

import continentsList from "../services/continents";
import countriesList from "../services/countries";
import { MultiOptionItemTree } from "./MultiOptionItemTree";

function convertCityToMultiOptionItemTree(
  city: City,
  parent: MultiOptionItemTree,
): MultiOptionItemTree {
  return {
    label: city.name,
    value: city.name,
    type: "city",
    parent,
  };
}

function convertCountryToMultiOptionItemTree(
  country: Country,
  parent: MultiOptionItemTree,
): MultiOptionItemTree {
  const value = country.countryCode;
  const label = countriesList[value].name;
  const option: MultiOptionItemTree = {
    label,
    value,
    type: "country",
    parent,
  };
  option.options = country.cities.map((city) =>
    convertCityToMultiOptionItemTree(city, option),
  );
  return option;
}

export function convertGeographyToMultiOptionItemTree(
  geography: Geography,
): MultiOptionItemTree[] {
  return geography.map((continent) => {
    const option: MultiOptionItemTree = {
      label: continentsList[continent.continentCode].name,
      value: continent.continentCode,
      type: "continent",
    };
    option.options = continent.countries.map((country) =>
      convertCountryToMultiOptionItemTree(country, option),
    );
    return option;
  });
}

export function convertMultiOptionItemTreeToGeographicalFilter(
  options: MultiOptionItemTree[],
) {
  return options.map((o) => ({
    value: o.value,
    type: o.type as GeographicalFilterType,
  }));
}
