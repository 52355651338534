import React, { useCallback, useState } from "react";

import { Cell, Pie, PieChart, Sector } from "recharts";

import { ChartData } from "./chart-data";

const renderActiveShape = () =>
  function innerRenderer(props: any) {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      payload,
      percent,
      value,
      fill,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius - 10) * cos;
    const sy = cy + (outerRadius - 10) * sin;
    const mx = cx + (outerRadius + 20) * cos;
    const my = cy + (outerRadius + 20) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    // function to truncate text with ellipsis
    const truncateText = (text: string, maxLength: number) => {
      if (text.length <= maxLength) return text;
      return `${text.substring(0, maxLength - 3)}...`;
    };

    // truncate name if it's too long
    const truncatedName = truncateText(payload.name, 20); // Adjust maxLength as needed

    // calculate percentage
    const percentageValue = percent * 100;

    // determine if the value should be formatted with or without decimals
    const formattedPercentage = Number.isInteger(percentageValue)
      ? `${percentageValue}`
      : `${percentageValue.toFixed(1)}`;

    return (
      <g>
        <text
          className="font-bold truncate"
          x={cx}
          y={cy}
          dy={15}
          textAnchor="middle"
          fontSize="14px"
        >
          {truncatedName}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke="#000"
          fill="none"
        />
        <circle cx={sx} cy={sy} r={2} fill="#000" stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
          fontSize="14px"
        >{`${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
          fontSize="14px"
        >
          {`${formattedPercentage}%`}
        </text>
      </g>
    );
  };

interface CustomDoughnutChartProps {
  data: ChartData[];
  subtitle?: string;
}

function CustomDoughnutChart(props: CustomDoughnutChartProps) {
  const { data, subtitle } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_: any, index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex],
  );

  return (
    <PieChart height={300} width={380}>
      {subtitle && (
        <text
          fill="#A6A6B0"
          x={190}
          y={140}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="14px"
        >
          {subtitle}
        </text>
      )}
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape()}
        data={data}
        cx="50%"
        cy="50%"
        innerRadius={70}
        outerRadius={95}
        dataKey="value"
        onMouseEnter={onPieEnter}
        fill="#8884d8"
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={entry.color}
            style={{ outline: "none" }}
          />
        ))}
      </Pie>
    </PieChart>
  );
}

export default CustomDoughnutChart;
