import React from "react";

import { flag } from "country-emoji";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { HiCalendar, HiExclamation } from "react-icons/hi";
import { TbHanger } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

import { getAppointmentFormatIcon } from "@calendar/helpers/formatters";
import Tag from "@components/data-display/Tag";
import Button from "@components/data-entry/Button";
import { formatDateToISO, formatTimeInterval } from "@helpers/Date";
import { AccountStatus } from "@models/Account";
import { AccountsStatusColors } from "@models/types/enums";
import { GetAppointment } from "@services/api/appointments/get-appointment";

interface AppointmentInformationCardProps {
  appointment: GetAppointment.Output;
}

function AppointmentInformationCard(props: AppointmentInformationCardProps) {
  const { appointment } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const startTimeAtTimezone = appointment.startTime.toLocalDate(
    appointment.showroom.timezone,
  );
  const endTimeAtTimezone = appointment.endTime.toLocalDate(
    appointment.showroom.timezone,
  );

  return (
    <div className="border border-primaryLightGrey rounded-lg w-full md:w-1/2 2xl:w-1/3 mx-6">
      <div className="p-4 border-b border-primaryLightGrey">
        <div className="flex gap-2 items-center">
          <p className="font-bold">{appointment.account.name}</p>
          <Tag size="sm" className="w-fit flex gap-2 items-center">
            {flag(appointment.account.countryCode)} {appointment.account.city}
          </Tag>
        </div>
        <div className="flex items-center gap-2">
          <Button
            theme="LINK"
            onClick={() =>
              // @todo navigate to correct path
              navigate(
                `/calendar/${appointment.showroom.season}/${
                  appointment.showroom.year
                }/daily/${formatDateToISO(
                  appointment.startTime.toLocalDate(
                    appointment.showroom.timezone,
                  ),
                )}`,
              )
            }
          >
            <HiCalendar />
            {format(
              appointment.startTime.toLocalDate(appointment.showroom.timezone),
              "PPP",
            )}
          </Button>
          {" | "}
          {formatTimeInterval(startTimeAtTimezone, endTimeAtTimezone)}
          {" | "}
          {appointment.format ? (
            <span>{getAppointmentFormatIcon(appointment.format)}</span>
          ) : null}
          <span>
            {appointment.format === "VIRTUAL"
              ? t(`Common.virtual-tool.${appointment.virtualMeetingApp}`)
              : t("Common.appointment-format.IN_PERSON")}
          </span>
        </div>
      </div>
      <div className="p-4 flex flex-col gap-3">
        {appointment.collection ? (
          <div className="flex items-center gap-2">
            <TbHanger />
            <span className="whitespace-nowrap">
              {t("Calendar.AppointmentSlot.collection")}
            </span>
            <span>{appointment.collection.name} </span>
            <Tag
              type="dotted"
              theme={
                AccountsStatusColors[
                  appointment.account.status as AccountStatus
                ]
              }
            >
              <span className="capitalize">{appointment.account.status}</span>
            </Tag>
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <TbHanger />
            <span className="whitespace-nowrap">
              {t("Calendar.AppointmentSlot.type")}
            </span>
            <span>{t(`Common.appointment-type.${appointment.type}`)} </span>
            <Tag
              type="dotted"
              theme={
                AccountsStatusColors[
                  appointment.account.status as AccountStatus
                ]
              }
            >
              <span className="capitalize">{appointment.account.status}</span>
            </Tag>
          </div>
        )}
      </div>
      {appointment.warnings && appointment.warnings.length > 0 && (
        <div
          data-testid="warning-icon"
          className="absolute -top-2 -left-2 bg-primaryRed text-white rounded-full p-1"
        >
          <HiExclamation className="w-4 h-4" />
        </div>
      )}
    </div>
  );
}

export default AppointmentInformationCard;
