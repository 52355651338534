import { RepresentativeRole } from "@models/old/Representative";

export const getRoleName = (role: RepresentativeRole) => {
  switch (role) {
    case "ORGANIZATION_MAIN_ADMIN":
      return "Admin";
    case "ORGANIZATION_ADMIN":
      return "Admin";
    case "ORGANIZATION_MANAGER":
      return "Manager";
    case "AGENT":
      return "Agent";
    case "FREELANCE":
      return "Freelance";
    default:
      return role;
  }
};

/**
 * Takes an array of anything and returns an array of the first N values + the number of sliced values
 */
export function ellipseWithCount<T>(values: T[], size: number) {
  return {
    slice: values.slice(0, size),
    sliced: values.slice(size),
    length: Math.max(values.length - size, 0),
  };
}

/**
 * Combines the person's firstname and lastname
 */
export function fullName(
  person: { firstName: string; lastName: string },
  short: boolean = false,
) {
  return `${person.firstName} ${
    short ? `${person.lastName[0].toUpperCase()}.` : person.lastName
  }`;
}
