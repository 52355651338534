import React from "react";

import { flag } from "country-emoji";
import { format } from "date-fns";
import { t } from "i18next";
import { HiCalendar } from "react-icons/hi";
import { HiOutlineUsers } from "react-icons/hi2";
import { TbHanger } from "react-icons/tb";

import { getAppointmentFormatIcon } from "@calendar/helpers/formatters";
import Button from "@components/data-entry/Button";
import Loading from "@components/feedback/Loading";
import { formatTimeInterval } from "@helpers/Date";
import { AccountsStatusColors } from "@models/old/Account";
import { GetAccount } from "@services/api/accounts/get-account";
import { GetAppointment } from "@services/api/appointments/get-appointment";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import getAppointmentVirtualLink from "@shared/helpers/appointment";
import { fullName } from "@shared/helpers/formatters";
import {
  translateAccountStatus,
  translateAppointmentFormatWithApp,
} from "@shared/helpers/translater";

import Tag from "./Tag";

interface AppointmentCardProps {
  appointmentId: string;
  accountId: string;
}

function AppointmentCard({ appointmentId, accountId }: AppointmentCardProps) {
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const { data: appointment } = GetAppointment.useHook({
    organizationId,
    appointmentId,
  });

  const { data: account } = GetAccount.useHook({
    organizationId,
    accountId,
  });
  const appointmentWithAccountInfos = account?.appointments.find(
    (appt) => appt.id === appointmentId,
  );

  const seller = undefined;
  const appointmentVirtualLink =
    seller !== undefined && appointment !== undefined
      ? getAppointmentVirtualLink({
          format: appointment.format,
          seller,
          virtualMeetingApp: appointment.virtualMeetingApp,
        })
      : undefined;

  const startTimeAtTimezone = appointment?.startTime.toLocalDate(
    appointment.showroom.timezone,
  );
  const endTimeAtTimezone = appointment?.endTime.toLocalDate(
    appointment.showroom.timezone,
  );

  return appointment && account ? (
    <div className="border border-primaryLightGrey rounded-lg mx-10 my-10">
      <div className="p-4 border-b border-primaryLightGrey">
        <div className="flex items-center gap-2">
          <span className="text-lg font-bold">{account?.name}</span>
          {account && (
            <Tag className="inline">
              {flag(account.countryCode)} {account.city}
            </Tag>
          )}
        </div>
        <p className="flex items-center gap-2">
          <HiCalendar className="text-primaryElectricBlue" />
          <span className="text-primaryElectricBlue">
            {startTimeAtTimezone && format(startTimeAtTimezone, "PPP")}
          </span>
          {" | "}
          <span>
            {startTimeAtTimezone &&
              endTimeAtTimezone &&
              formatTimeInterval(startTimeAtTimezone, endTimeAtTimezone)}
          </span>
          {" | "}
          {appointment?.format ? (
            <span>{getAppointmentFormatIcon(appointment.format)}</span>
          ) : null}
          <span>
            {appointment && appointment.format !== null
              ? t(
                  translateAppointmentFormatWithApp(
                    appointment.format,
                    appointment.virtualMeetingApp,
                  ),
                )
              : null}
          </span>
        </p>
      </div>
      <div className="p-4 flex flex-col gap-3">
        {appointment && account && account.appointments ? (
          <div className="flex items-center gap-2">
            <TbHanger />
            <span className="whitespace-nowrap">
              {t("Calendar.AppointmentSlot.collection")}
            </span>
            {account && account.appointments && (
              <Tag
                type="dotted"
                theme={
                  AccountsStatusColors[
                    appointmentWithAccountInfos?.collection
                      .collectionInformationByAccount[0]?.status || "PROSPECT"
                  ]
                }
              >
                {t(
                  translateAccountStatus(
                    appointmentWithAccountInfos?.collection
                      .collectionInformationByAccount[0]?.status || "PROSPECT",
                  ),
                )}
              </Tag>
            )}
          </div>
        ) : (
          <p className="flex items-center gap-2">
            <TbHanger />
            <span className="whitespace-nowrap">
              {t("Calendar.AppointmentSlot.type")}
            </span>
            {account && (
              <Tag type="dotted" theme={AccountsStatusColors[account.status]}>
                {t(translateAccountStatus(account.status))}
              </Tag>
            )}
          </p>
        )}
        <p className="flex items-baseline gap-2">
          <HiOutlineUsers className="relative top-1" />
          <span className="whitespace-nowrap">
            {t("Calendar.AppointmentSlot.attendees")}
          </span>
          <span>
            {appointment?.attendees.map((a) => fullName(a)).join(", ")}
          </span>
        </p>
        {appointment?.format === "VIRTUAL" && (
          <p>
            <Button
              renderIf={!!appointmentVirtualLink}
              theme="PRIMARY"
              className="w-full items-center justify-center"
              onClick={() => {
                if (appointmentVirtualLink) {
                  window.open(appointmentVirtualLink);
                }
              }}
            >
              <span>{getAppointmentFormatIcon(appointment.format)}</span>
              {t("Calendar.AppointmentSlot.virtual-link", {
                tool: t(`Common.virtual-tool.${appointment.virtualMeetingApp}`),
              })}
            </Button>
          </p>
        )}
      </div>
    </div>
  ) : (
    <Loading type="screen" />
  );
}

export default AppointmentCard;
