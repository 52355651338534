import * as React from "react";

import { Trans, useTranslation } from "react-i18next";
import { HiOutlinePlusCircle, HiPencil, HiTrash } from "react-icons/hi2";
import { toast } from "react-toastify";

import CompetitorsGroupForm, {
  useCompetitorsGroupForm,
} from "@app/modules/crm/components/competitors/form";
import CRMHeader from "@app/modules/crm/components/header";
import { useCRMDrawerContext } from "@app/modules/crm/context/crm-drawers";
import GridItem from "@components/data-display/GridItem";
import Tag from "@components/data-display/Tag";
import Button from "@components/data-entry/Button";
import CalloutBox from "@components/feedback/CalloutBox";
import ConfirmModal from "@components/feedback/ConfirmModal";
import Drawer from "@components/feedback/Drawer";
import Loading from "@components/feedback/Loading";
import { CompetitorsGroup } from "@models/CompetitorGroup";
import { useDeleteCompetitorsGroup } from "@services/api/organization/delete-competitors-group";
import { useGetCompetitorsGroups } from "@services/api/organization/get-competitors-groups";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import LogService from "@services/log/service";
import { pageWithAccessControl } from "@shared/components/access-control";
import {
  ACL_ADMINS_AGENTS_MANAGERS,
  ACL_ADMINS_MANAGERS,
} from "@shared/components/access-control/helpers";
import ConditionalRender from "@shared/components/on";

function CRMCompetitors() {
  const { t } = useTranslation();
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [competitorsGroupToDelete, setCompetitorsGroupToDelete] =
    React.useState<CompetitorsGroup | undefined>();
  const [competitorsGroupToEdit, setCompetitorsGroupToEdit] = React.useState<
    CompetitorsGroup | undefined
  >();

  const { data: fetchedCompetitorsGroups, isFetching } =
    useGetCompetitorsGroups({
      organizationId,
    });
  const competitorsGroups: CompetitorsGroup[] = fetchedCompetitorsGroups || [];

  const drawerRef = React.useRef(null);
  const crmContext = useCRMDrawerContext();

  const { mutateAsync: apiDeleteCompetitorsGroup } = useDeleteCompetitorsGroup({
    organizationId,
  });

  const deleteCompetitorsGroup = React.useCallback(() => {
    setIsConfirmModalOpen(false);
    if (!competitorsGroupToDelete) {
      return LogService.error(
        "Tried deleting a competitors group without specifying which one",
      );
    }

    return apiDeleteCompetitorsGroup({
      competitorsGroupId: competitorsGroupToDelete.id,
    }).then(() => {
      toast.success(t("CRM.competitors.toast.delete-success"));
    });
  }, [apiDeleteCompetitorsGroup, competitorsGroupToDelete, t]);

  const formState = useCompetitorsGroupForm({
    competitorsGroup: competitorsGroupToEdit,
    onSuccess: crmContext.closeDrawer,
  });

  return (
    <>
      <CRMHeader />
      <div className="p-4">
        <CalloutBox type="INFORMATIVE" className="max-w-[60%]">
          <p>{t("CRM.competitors.callout")}</p>
          <p className="italic">{t("CRM.competitors.callout-example")}</p>
        </CalloutBox>
        <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
          <ConditionalRender
            renderIf={!isFetching}
            fallback={<Loading type="screen" />}
          >
            {competitorsGroups.length === 0 && (
              <Button
                theme="NONE"
                className="w-full h-24 text-primaryElectricBlue rounded-md border-2 border-primaryGrey border-dashed"
                onClick={() => {
                  crmContext.openCompetitorsGroupUpsertDrawer();
                }}
              >
                <HiOutlinePlusCircle className="w-6 h-6" />
                {t("CRM.competitors.new-competitors-group")}
              </Button>
            )}
            {competitorsGroups.map((cg) => (
              <GridItem
                key={cg.id}
                title={cg.name}
                actions={
                  <div className="flex gap-2">
                    <Button
                      aclRoles={ACL_ADMINS_MANAGERS}
                      theme="NONE"
                      onClick={() => {
                        setCompetitorsGroupToEdit(cg);
                        crmContext.openCompetitorsGroupUpsertDrawer();
                      }}
                    >
                      <HiPencil />
                    </Button>
                    <Button
                      theme="NONE"
                      className="text-primaryRed"
                      aclRoles={ACL_ADMINS_MANAGERS}
                      onClick={() => {
                        setIsConfirmModalOpen(true);
                        setCompetitorsGroupToDelete(cg);
                      }}
                    >
                      <HiTrash />
                    </Button>
                  </div>
                }
              >
                <div className="p-2 flex gap-2 text-center flex-wrap">
                  {cg.accounts.map((acc) => (
                    <Tag className="inline-block" key={acc.id}>
                      {acc.name}
                    </Tag>
                  ))}
                </div>
              </GridItem>
            ))}
          </ConditionalRender>
        </div>
      </div>

      <Drawer
        drawerTitle={
          <h2 className="heading-2-mobile lg:heading-2">
            {competitorsGroupToEdit?.name || t("CRM.competitors.drawer-title")}
          </h2>
        }
        size="LARGE"
        backdrop
        isOpen={crmContext.drawer === "upsert-competitors"}
        drawerRef={drawerRef}
        title="Competitor Group"
        closeWithConfirmation={() => {
          crmContext.closeDrawer();
          if (competitorsGroupToEdit) {
            setCompetitorsGroupToEdit(undefined);
          }
        }}
      >
        <CompetitorsGroupForm {...formState} />
      </Drawer>

      <ConfirmModal
        show={isConfirmModalOpen}
        title={t("CRM.competitors.confirm-delete-modal.title")}
        confirmLabel={t("Common.yes-delete")}
        onCancel={() => {
          setIsConfirmModalOpen(false);
        }}
        onConfirm={deleteCompetitorsGroup}
      >
        <Trans
          i18nKey="CRM.competitors.confirm-delete-modal.body"
          values={{
            competitorsGroupName: competitorsGroupToDelete?.name,
          }}
          components={{
            br: <br />,
            strong: <strong className="font-bold" />,
          }}
        />
      </ConfirmModal>
    </>
  );
}

export default pageWithAccessControl(
  ACL_ADMINS_AGENTS_MANAGERS,
  CRMCompetitors,
);
