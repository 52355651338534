/* eslint-disable no-param-reassign */
import axios, { AxiosHeaders } from "axios";
import { isAfter } from "date-fns";

import { auth } from "@app/services/authentication/firebase";
import LogService from "@services/log/service";

import { baseURL } from "../global";

const axiosInstance = axios.create({
  baseURL,
});

// TODO : confirm that it logs every errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === "ERR_CANCELED") {
      // return Promise.resolve({ status: 499 });
      return Promise.reject(new Error("Client closed request"));
    }
    LogService.error(error);
    return Promise.reject((error.response && error.response.data) || error);
  },
);

const retrieveToken = async (): Promise<string | null> => {
  if (auth.currentUser) {
    const idTokenResult = await auth.currentUser.getIdTokenResult();
    if (isAfter(new Date(idTokenResult.expirationTime), new Date())) {
      return idTokenResult.token;
    }

    const refreshedTokenResult = await auth.currentUser.getIdTokenResult();
    return refreshedTokenResult.token;
  }
  return null;
};

axiosInstance.interceptors.request.use(async (config) => {
  const token = await retrieveToken();

  if (token) {
    if (!config.headers) {
      config.headers = new AxiosHeaders();
    }
    config.headers.set("Authorization", `Bearer ${token}`);
    const impersonation = localStorage.getItem("impersonation");
    if (impersonation && impersonation !== "null") {
      config.headers.set("impersonation", impersonation);
    }
  }
  return config;
});

export default axiosInstance;
