import React from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AdminOnboardingBreadcrumb from "@backoffice/components/onboarding/components/AdminOnboardingBreadcrumb";
import { AdminOnboardingStep } from "@models/old/Organization";
import OnboardingFormContainer from "@onboarding/components/OnboardingFormContainer";
import { StartOrganizationOnboardingEndpoint } from "@services/api/organization/start-onboarding";
import { OrganizationInformationFormData } from "@shared/organization/organization-information-form";

export default function AdminOnboardingNewOrganizationInformationPage() {
  const navigate = useNavigate();
  const { mutateAsync: startOnboardingMutation } =
    StartOrganizationOnboardingEndpoint.useHook({});

  const onSubmit = (onboarding: OrganizationInformationFormData) =>
    startOnboardingMutation(onboarding).then((createdOrganization) => {
      toast.success(`Organization ${createdOrganization.name} created`);
      navigate(
        `/backoffice/onboard-organization/${createdOrganization.id}/accounts-upload`,
      );
    });

  return (
    <div className="h-full flex flex-col">
      <div className="px-10">
        <AdminOnboardingBreadcrumb
          currentStep={AdminOnboardingStep.ORGANIZATION_CREATION}
        />
        <h1 className="heading-1 pt-10">New organization</h1>
      </div>
      <OnboardingFormContainer admin onSubmit={onSubmit} />
    </div>
  );
}
