import React from "react";

import { ErrorBoundary } from "@sentry/react";
import { useTranslation } from "react-i18next";

import { pageWithAccessControl } from "@shared/components/access-control";
import PortfoliosOverview from "@shared/portfolios/portfolios-overview";

function OrganizationProfilePortfolios() {
  const { t } = useTranslation();

  return (
    <ErrorBoundary fallback={<>Ceci est une page d&quot;erreur</>}>
      <div className="p-10 grow">
        <h1 className="heading-1 mb-10">
          {t("OrganizationProfile.portfolios.title")}
        </h1>
        <div className="flex gap-6">
          <PortfoliosOverview />
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default pageWithAccessControl(
  [
    "ORGANIZATION_MAIN_ADMIN",
    "ORGANIZATION_ADMIN",
    "ORGANIZATION_MANAGER",
    "AGENT",
  ],
  OrganizationProfilePortfolios,
);
