import { Representative } from "@models/OrganizationRepresentative";

type RepresentativeToFilter = Pick<
  Representative,
  "firstName" | "lastName" | "role" | "email" | "position"
>;

function globalFilterRepresentatives<Rep extends RepresentativeToFilter>(
  representatives: Rep[],
  filter: string | undefined,
) {
  return representatives.filter((representative) => {
    if (!filter) {
      return true;
    }
    return (
      representative.firstName.toLowerCase().includes(filter.toLowerCase()) ||
      representative.lastName.toLowerCase().includes(filter.toLowerCase()) ||
      representative.role.toLowerCase().includes(filter.toLowerCase()) ||
      representative.email?.toLowerCase().includes(filter.toLowerCase()) ||
      (representative.position &&
        representative.position?.toLowerCase().includes(filter.toLowerCase()))
    );
  });
}

export default globalFilterRepresentatives;
