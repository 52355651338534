import { z } from "zod";

import { AccountStatusList } from "@models/Account";

export const collectionSchema = z.object({
  id: z.string(),
  name: z.string(),
  status: z.enum(AccountStatusList).nullish(),
  brandId: z.string().uuid().nullish(),
});

export type Collection = z.infer<typeof collectionSchema>;
