import { VirtualMeetingApps } from "@models/types/enums";

export default function virtualToolsValidator(
  validationRequired: boolean,
  virtualMeetingAppLinks: Record<string, string> | null | undefined,
) {
  return (app: VirtualMeetingApps, value: string) => {
    if (validationRequired) {
      const isEmptyValue = !value || (value && value.length === 0);
      const hasOldValue =
        virtualMeetingAppLinks && virtualMeetingAppLinks[app] !== undefined;

      if (hasOldValue && isEmptyValue) {
        return false;
      }
    }
    return true;
  };
}
