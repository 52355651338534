import { useQuery } from "@tanstack/react-query";

import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

export const fetchContactsArchivedCountEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/contacts/archived/count`,
};

export const queryKeysFetchContactsArchivedCount = (organizationId: string) =>
  fetchContactsArchivedCountEndpoint.path(organizationId).split("/");

export const useFetchContactsArchivedCount = (organizationId: string) =>
  useQuery({
    queryKey: queryKeysFetchContactsArchivedCount(organizationId),
    queryFn: () =>
      axiosInstance
        .get<{
          count: number;
        }>(fetchContactsArchivedCountEndpoint.path(organizationId))
        .then((res) => res.data),
  });
