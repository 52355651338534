import React, { HTMLProps, useRef } from "react";

import Tag from "@components/data-display/Tag";

interface MultiTextFieldProps
  extends Omit<
    HTMLProps<HTMLInputElement>,
    "onChange" | "defaultValue" | "value"
  > {
  className?: string;
  values?: string[];
  onEnter: (value: string) => void;
  onRemove: (value: string) => void;
}

function MultiTextField(props: MultiTextFieldProps) {
  const { values, className, id, onEnter, onRemove, ...rest } = props;
  const ref = useRef<HTMLInputElement>(null);

  return (
    <div
      data-testid={`container_${id}`}
      className={`relative w-full h-full text-gray-700 flex flex-col rounded-lg form-input ${
        rest.disabled && "bg-primaryLightestGrey"
      } ${className || ""}`}
    >
      <div
        className={`w-full items-center ${
          values && values.length > 0 && "mb-3"
        }`}
      >
        <input
          ref={ref}
          id={id}
          name={id}
          type="text"
          className="w-full h-8 outline-none"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              if (onEnter && e.currentTarget.value) {
                onEnter(e.currentTarget.value);
                e.currentTarget.value = "";
              }
            }
          }}
          onBlur={(e) => {
            e.preventDefault();
            if (onEnter && e.currentTarget.value) {
              onEnter(e.currentTarget.value);
              e.currentTarget.value = "";
            }
          }}
          {...rest}
        />
      </div>
      {values && values.length > 0 && (
        <div data-testid={`values_${id}`} className="flex flex-wrap gap-1">
          {values &&
            values.map((v) => (
              <Tag key={`${id}_tag_${v}`} closable onClose={() => onRemove(v)}>
                {v}
              </Tag>
            ))}
        </div>
      )}
    </div>
  );
}

export default MultiTextField;
