import { useQuery } from "@tanstack/react-query";

import { Organization } from "@models/old/Organization";
import { Representative } from "@models/old/Representative";
import axiosInstance from "@services/api/config";

export const fetchRepresentativesArchivedEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/representatives/archived`,
};

type FetchRepresentativesResponse = Representative[];

export const queryKeysFetchRepresentativesArchived = (organizationId: string) =>
  fetchRepresentativesArchivedEndpoint.path(organizationId).split("/");

export const useFetchRepresentativesArchived = (organizationId: string) =>
  useQuery({
    queryKey: queryKeysFetchRepresentativesArchived(organizationId),
    queryFn: () =>
      axiosInstance
        .get<FetchRepresentativesResponse>(
          fetchRepresentativesArchivedEndpoint.path(organizationId),
        )
        .then((res) => res.data),
  });
