import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { z } from "zod";

import { Appointment } from "@models/Appointment";
import { showroomSchema } from "@models/Showroom";
import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";
import { GetDailyCalendarEndpoint } from "@services/api/sales-campaigns/get-daily-calendar";
import LogService from "@services/log/service";

export namespace DeleteAppointmentEndpoint {
  const outputSchema = z.object({
    showroom: showroomSchema.pick({ id: true }),
  });
  type Output = z.infer<typeof outputSchema>;

  interface HookParams {
    organizationId: Organization["id"];
    dayAsString: string;
  }

  interface MutationParams {
    appointmentId: Appointment["id"];
  }

  type Params = HookParams & MutationParams;

  export const path = ({ organizationId, appointmentId }: Params) =>
    `/organizations/${organizationId}/appointments/${appointmentId}`;

  export const call = (pathParams: Params) =>
    axiosInstance
      .delete<Output>(path(pathParams))
      .then((response) => outputSchema.parse(response.data));

  export function useHook(params: HookParams) {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    return useMutation({
      mutationFn: ({ appointmentId }: MutationParams) =>
        call({
          ...params,
          appointmentId,
        }),
      onSuccess: (result) => {
        toast.success(t("Toast.appointment-deleted"));
        queryClient.invalidateQueries({
          queryKey: GetDailyCalendarEndpoint.queryKeys({
            ...params,
            showroomId: result.showroom.id,
          }),
        });
      },
      onError: (err) => {
        toast.error(t("Toast.appointment-deleted-fail"));
        LogService.error(`${err}`);
      },
    });
  }
}
