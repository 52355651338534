import React from "react";

import { Outlet } from "react-router-dom";

import Button from "@components/data-entry/Button";
import ErrorBoundary, {
  ErrorFallbackComponentProps,
  MakeFallbackComponentProps,
} from "@services/error/error-boundary";

/**
 * src/services/error/error-boundary.tsx
 */
function makeBookingFallbackComponent({ t }: MakeFallbackComponentProps) {
  return function BookingFallbackComponent(props: ErrorFallbackComponentProps) {
    const { resetErrorBoundary } = props;
    return (
      <div>
        <p>{t("ErrorFallback.booking.title")}</p>
        <Button onClick={resetErrorBoundary}>Try again</Button>
      </div>
    );
  };
}

export default function BookingLayout() {
  return (
    <ErrorBoundary makeFallbackComponent={makeBookingFallbackComponent}>
      <Outlet />
    </ErrorBoundary>
  );
}
