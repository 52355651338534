import { FieldValues, Path, UseFormReturn } from "react-hook-form";

export function useEnhancedForm<T extends FieldValues>(form: UseFormReturn<T>) {
  return {
    validate: {
      string: {
        nonEmpty: (fieldName: Path<T>, value: string) => {
          if (value === "") {
            form.setError(fieldName, { type: "required" });
          } else {
            form.clearErrors(fieldName);
          }
        },
      },
      array: {
        nonEmpty: (fieldName: Path<T>, value: any[]) => {
          if (value.length === 0) {
            form.setError(fieldName, { type: "required" });
          } else {
            form.clearErrors(fieldName);
          }
        },
      },
    },
  };
}
