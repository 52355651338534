import { QueryClient, useQuery } from "@tanstack/react-query";

import { RealDate, ZonedDate } from "@helpers/Date";
import { Appointment, SerializedAppointment } from "@models/old/Appointment";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export type FetchBookingAppointmentsResponse = SerializedAppointment[];

const fetchBookingAppointmentsEndpoint = {
  path: (invitationId: string) => `/bookings/${invitationId}/appointments`,
  invalidate: (queryClient: QueryClient, invitationId: string) => {
    queryClient.invalidateQueries({
      queryKey: getAPIQueryKey(
        fetchBookingAppointmentsEndpoint.path(invitationId),
      ),
    });
  },
};

const callApi = async (invitationId: string) =>
  axiosInstance
    .get<FetchBookingAppointmentsResponse>(
      fetchBookingAppointmentsEndpoint.path(invitationId),
    )
    .then((res) => res.data)
    .then((appointments) =>
      appointments.map(
        (serializedAppointment: SerializedAppointment): Appointment => ({
          ...serializedAppointment,
          startTime: new ZonedDate(
            new Date(serializedAppointment.startTime) as RealDate,
          ),
          endTime: new ZonedDate(
            new Date(serializedAppointment.endTime) as RealDate,
          ),
        }),
      ),
    );

export function useFetchBookingAppointments(invitationId: string) {
  return useQuery({
    queryKey: getAPIQueryKey(
      fetchBookingAppointmentsEndpoint.path(invitationId),
    ),
    queryFn: () => callApi(invitationId),
  });
}

export default fetchBookingAppointmentsEndpoint;
