import { initializeApp } from "firebase/app";
import {
  AuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";

export type OAuthProviderType = "google" | "microsoft";

const FIREBASE_CONFIG = JSON.parse(import.meta.env.VITE_FIREBASE_CONFIG);

// Initialize Firebase
const firebaseApp = initializeApp(FIREBASE_CONFIG);
export const auth = getAuth(firebaseApp);

// microsoftProvider.setCustomParameters({
//   prompt: "consent",
//   tenant: "the tenant id provided by outlook",
// });

function retrieveProvider(providerType: OAuthProviderType): AuthProvider {
  if (providerType === "microsoft") {
    return new OAuthProvider("microsoft.com");
  }

  const googleProvider = new GoogleAuthProvider();
  googleProvider.addScope("https://www.googleapis.com/auth/userinfo.profile");
  return googleProvider;
}

export interface AuthenticationService {
  oauthSignIn: (provider: OAuthProviderType) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  signUpWithEmailAndPassword: (
    email: string,
    password: string,
  ) => Promise<void>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  confirmPasswordReset: (oobCode: string, newPassword: string) => Promise<void>;
}

export const firebaseAuthenticationService: AuthenticationService = {
  oauthSignIn: async (providerType: OAuthProviderType) => {
    await signInWithPopup(auth, retrieveProvider(providerType));
  },
  signIn: async (email: string, password: string) => {
    await signInWithEmailAndPassword(auth, email, password);
  },
  signOut: () => signOut(auth),
  signUpWithEmailAndPassword: async (email: string, password: string) => {
    await createUserWithEmailAndPassword(auth, email, password);
  },
  sendPasswordResetEmail: async (email: string): Promise<void> =>
    sendPasswordResetEmail(auth, email),
  confirmPasswordReset: async (
    oobCode: string,
    newPassword: string,
  ): Promise<void> => confirmPasswordReset(auth, oobCode, newPassword),
};
