import React from "react";

import { addMinutes, addYears, eachDayOfInterval, isAfter } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isEmpty } from "remeda";
import { z } from "zod";

import AddressAutocomplete from "@components/data-entry/AddressAutocomplete";
import Button from "@components/data-entry/Button";
import DatePicker from "@components/data-entry/DatePicker";
import TextField from "@components/data-entry/TextField";
import TimePicker from "@components/data-entry/TimePicker";
import { addressSchema } from "@models/types/Address";

export namespace CruisingShowroomSingleForm {
  type NullPartial<T extends Record<string, any>, IgnoreKeys = undefined> = {
    [k in keyof T]: IgnoreKeys extends k ? T[k] : T[k] | null;
  };

  export const formDataValidationSchema = z.object({
    name: z.string(),
    startTime: z.date(),
    endTime: z.date(),
    from: z.date(),
    to: z.date(),
    address: addressSchema,
    duration: z.number().min(15),
  });

  export type FormData = NullPartial<
    z.input<typeof formDataValidationSchema>,
    "address"
  >;

  export type FormDataValidated = z.infer<typeof formDataValidationSchema>;

  type Props = {
    onSubmit: (values: FormDataValidated) => void;
    defaultValues?: FormData;
  };

  export function Component({
    onSubmit,
    defaultValues = {
      name: "",
      duration: 60,
      startTime: null,
      endTime: null,
      from: null,
      to: null,
      address: {
        city: "",
        countryCode: "",
        formattedAddress: "",
        postalCode: "",
      },
    },
  }: Props) {
    const { t } = useTranslation();

    const {
      handleSubmit,
      control,
      formState: { errors },
      reset,
      watch,
    } = useForm<FormData>({
      defaultValues,
    });

    const submit = (v: FormData) => {
      const parsing = formDataValidationSchema.safeParse(v);
      if (parsing.success) {
        onSubmit(parsing.data);
        reset({});
      }
    };

    const to = watch("to");
    const from = watch("from");
    const startTime = watch("startTime");
    const endTime = watch("endTime");

    return (
      <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-2">
        <div>
          <label htmlFor="name">
            {t("Cruising.CruisingShowroomSingleForm.name.label")}
            {errors.name && errors.name.message && (
              <p className="text-primaryRed">{t(errors.name.message)}</p>
            )}
          </label>
          <Controller
            name="name"
            control={control}
            rules={{
              required: t("Cruising.CruisingShowroomSingleForm.field-required"),
            }}
            render={({ field: { value, onChange } }) => (
              <TextField value={value || ""} onChange={onChange} />
            )}
          />
        </div>
        <div>
          <label htmlFor="address">
            {t("Cruising.CruisingShowroomSingleForm.address.label")}
            {errors.address && errors.address.message && (
              <p className="text-primaryRed">{t(errors.address.message)}</p>
            )}
          </label>
          <Controller
            name="address"
            control={control}
            rules={{
              required: t("Cruising.CruisingShowroomSingleForm.field-required"),
            }}
            render={({ field: { value, onChange } }) => (
              <AddressAutocomplete defaultValue={value} onChange={onChange} />
            )}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="from">
              {t("Cruising.CruisingShowroomSingleForm.from.label")}
            </label>
            <Controller
              control={control}
              name="from"
              rules={{
                required: t(
                  "Cruising.CruisingShowroomSingleForm.field-required",
                ),
              }}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  id="from"
                  placeholder={t(
                    "Cruising.CruisingShowroomSingleForm.from.placeholder",
                  )}
                  onChange={onChange}
                  defaultValue={value}
                  enabledDates={
                    to && isAfter(to, new Date())
                      ? eachDayOfInterval({
                          start: new Date(),
                          end: to,
                        })
                      : undefined
                  }
                />
              )}
            />
          </div>
          <div>
            <label htmlFor="to">
              {t("Cruising.CruisingShowroomSingleForm.to.label")}
            </label>
            <Controller
              control={control}
              name="to"
              rules={{
                required: t(
                  "Cruising.CruisingShowroomSingleForm.field-required",
                ),
              }}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  id="to"
                  placeholder={t(
                    "Cruising.CruisingShowroomSingleForm.to.placeholder",
                  )}
                  onChange={onChange}
                  defaultValue={value}
                  enabledDates={
                    from
                      ? eachDayOfInterval({
                          start: from,
                          end: addYears(new Date(), 1),
                        })
                      : undefined
                  }
                />
              )}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="startTime">
              {t("Cruising.CruisingShowroomSingleForm.startTime.label")}
            </label>
            <Controller
              control={control}
              name="startTime"
              rules={{
                required: t(
                  "Cruising.CruisingShowroomSingleForm.field-required",
                ),
              }}
              render={({ field: { value, onChange } }) => (
                <TimePicker
                  id="startTime"
                  name="startTime"
                  placeholder={t(
                    "Cruising.CruisingShowroomSingleForm.startTime.placeholder",
                  )}
                  options={{
                    firstHourOfDay: 8,
                    totalHours: 11,
                    timeStep: 30,
                  }}
                  onChange={onChange}
                  defaultValue={value || undefined}
                  maxTime={endTime && addMinutes(endTime, 1)}
                />
              )}
            />
          </div>
          <div>
            <label htmlFor="endTime">
              {t("Cruising.CruisingShowroomSingleForm.endTime.label")}
            </label>
            <Controller
              control={control}
              name="endTime"
              rules={{
                required: t(
                  "Cruising.CruisingShowroomSingleForm.field-required",
                ),
              }}
              render={({ field: { value, onChange } }) => (
                <TimePicker
                  id="endTime"
                  name="endTime"
                  placeholder={t(
                    "Cruising.CruisingShowroomSingleForm.endTime.placeholder",
                  )}
                  options={{
                    firstHourOfDay: 8,
                    totalHours: 11,
                    timeStep: 30,
                  }}
                  onChange={onChange}
                  defaultValue={value || undefined}
                  minTime={startTime}
                />
              )}
            />
          </div>
        </div>
        <div>
          <label htmlFor="name">
            {t("Cruising.CruisingShowroomSingleForm.duration.label")}
            {errors.duration && errors.duration.message && (
              <p className="text-primaryRed">{t(errors.duration.message)}</p>
            )}
          </label>
          <Controller
            name="duration"
            control={control}
            rules={{
              required: t("Cruising.CruisingShowroomSingleForm.field-required"),
              min: {
                value: 15,
                message: t(
                  "Cruising.CruisingShowroomSingleForm.duration-must-be-more-than-15m",
                ),
              },
            }}
            render={({ field: { value, onChange } }) => (
              <TextField
                type="number"
                min="15"
                step="15"
                value={value?.toString() || ""}
                onChange={(v) => {
                  if (isEmpty(v)) {
                    onChange(null);
                    return;
                  }
                  const parsed = parseInt(v, 10);
                  if (!Number.isNaN(parsed)) {
                    onChange(parsed);
                  }
                }}
              />
            )}
          />
        </div>
        <Button type="submit" className="flex justify-center" theme="PRIMARY">
          {t("Cruising.CruisingShowroomSingleForm.submit")}
        </Button>
      </form>
    );
  }
}
