import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CompetitorsGroup } from "@models/CompetitorGroup";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";
import { getCompetitorsGroupsEndpoint } from "@services/api/organization/get-competitors-groups";

export interface CreateCompetitorsGroupHookParams {
  organizationId: string;
}

export interface CreateCompetitorsGroupCallParams {
  competitorsGroup: Pick<CompetitorsGroup, "name" | "organizationId"> & {
    accounts: {
      id: string;
    }[];
  };
}

export type CreateCompetitorsGroupParams = CreateCompetitorsGroupHookParams &
  CreateCompetitorsGroupCallParams;

export const createCompetitorsGroupEndpoint = {
  path: ({ organizationId }: CreateCompetitorsGroupParams) =>
    `/organizations/${organizationId}/competitors-groups`,
  call: (params: CreateCompetitorsGroupParams) =>
    axiosInstance
      .post(
        createCompetitorsGroupEndpoint.path(params),
        params.competitorsGroup,
      )
      .then((response) => response.data),
};

export function useCreateCompetitorsGroup(
  hookParams: CreateCompetitorsGroupHookParams,
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (callParams: CreateCompetitorsGroupCallParams) =>
      createCompetitorsGroupEndpoint.call({
        ...hookParams,
        ...callParams,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getAPIQueryKey(getCompetitorsGroupsEndpoint.path(hookParams)),
      });
    },
  });
}
