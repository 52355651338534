import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import { Organization, organizationSchema } from "@models/Organization";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

// @todo rename endpoint
export namespace GetOrganizationEndpoint {
  // @todo add output schema
  export const outputSchema = organizationSchema.extend({
    brand: brandSchema.nullable(),
    partnerBrands: z.array(brandSchema),
  });

  export type Output = z.infer<typeof outputSchema>;

  // @todo add hook params
  export interface HookParams {
    organizationId: Organization["id"];
  }

  // @todo add path
  export function path({ organizationId }: HookParams) {
    return `/organizations/${organizationId}`;
  }
  export const getQueryKeys = (p: HookParams) => getAPIQueryKey(path(p));

  export function call(params: HookParams) {
    return axiosInstance
      .get<Output>(path(params))
      .then((r) => outputSchema.parse(r.data));
  }

  export function useHook(params: HookParams) {
    return useQuery({
      queryKey: getAPIQueryKey(path(params)),
      queryFn: () => call(params),
    });
  }
}
