import { useMediaQuery } from "usehooks-ts";

type ScreenSize = "sm" | "md" | "lg" | "xl" | "2xl";

const BREAKPOINTS: Record<ScreenSize, number> = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};
export function useBreakpoint(from?: ScreenSize, to?: ScreenSize) {
  const verifiesFrom = useMediaQuery(
    `(min-width: ${from ? BREAKPOINTS[from] : 0}px)`,
  );
  const verifiesTo = useMediaQuery(
    `(max-width: ${to ? BREAKPOINTS[to] : 999999}px)`,
  );

  return verifiesFrom && verifiesTo;
}

export function useIsMobile() {
  return useBreakpoint(undefined, "md");
}

export function useIsLargerScreen() {
  return useBreakpoint("md");
}

export function useIsLargestScreen() {
  return useBreakpoint("lg");
}

interface BreakpointProps {
  children: JSX.Element;
  from?: ScreenSize;
  to?: ScreenSize;
}

export default function Breakpoint({ children, from, to }: BreakpointProps) {
  const verifiesFromTo = useBreakpoint(from, to);
  return verifiesFromTo ? children : null;
}
