import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { Organization } from "@models/Organization";
import { representativeSchema } from "@models/OrganizationRepresentative";
import { portfolioSchema } from "@models/Portfolio";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export namespace GetRepresentativesFromOrganizationEndpoint {
  export const outputSchema = z.array(
    representativeSchema
      .pick({
        id: true,
        firstName: true,
        lastName: true,
        role: true,
        email: true,
        invitationStatus: true,
        languages: true,
        markets: true,
        phoneNumber: true,
        position: true,
        status: true,
        virtualMeetingAppLinks: true,
      })
      .extend({
        portfolios: z.array(
          portfolioSchema.pick({
            id: true,
            name: true,
            collectionId: true,
          }),
        ),
      }),
  );

  export type Output = z.infer<typeof outputSchema>;

  export interface HookParams {
    organizationId: Organization["id"];
  }

  export function path({ organizationId }: HookParams) {
    return `/organizations/${organizationId}/representatives/full`;
  }

  export function call(params: HookParams) {
    return axiosInstance.get<Output>(path(params)).then((r) => {
      const result = outputSchema.safeParse(r.data);
      if (!result.success) {
        throw result.error;
      } else {
        return result.data;
      }
    });
  }

  export function useHook(params: HookParams) {
    return useQuery({
      queryKey: getAPIQueryKey(path(params)),
      queryFn: () => call(params),
    });
  }
}
