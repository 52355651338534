import React, { useState } from "react";

import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  HiOutlineChat,
  HiOutlineColorSwatch,
  HiOutlineUsers,
  HiOutlineVideoCamera,
  HiOutlineViewGrid,
} from "react-icons/hi";
import { TbHanger } from "react-icons/tb";
import { intersection } from "remeda";

import Collapse from "@components/data-display/Collapse";
import Button from "@components/data-entry/Button";
import Select from "@components/data-entry/Select";
import { Showroom } from "@models/Showroom";
import { SpecialRequestOldModel } from "@models/SpecialRequest";
import { AppointmentTypeEnum } from "@models/types/enums";
import { getLanguageByCode } from "@services/languages";
import { useOrganizationRepresentativeOptionsByAvailability } from "@shared/helpers/sellers";

interface SpecialRequestItemProps {
  specialRequest: SpecialRequestOldModel;
  showroom: Pick<Showroom, "timezone">;
  onDecline: (item: SpecialRequestOldModel) => void;
  onConfirm: (
    item: SpecialRequestOldModel,
    sellerId: string,
    language: string,
  ) => void;
  isLoading: boolean;
}

function SpecialRequestItem({
  specialRequest,
  showroom,
  onDecline,
  onConfirm,
  isLoading,
}: SpecialRequestItemProps) {
  const { t } = useTranslation();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedSellerId, setSelectedSellerId] = useState<string | undefined>(
    undefined,
  );
  const [selectedLanguage, setSelectedLanguage] = useState<
    string | undefined
  >();

  const selectFirstSellerLanguage = (sellerId: string) => {
    const seller = specialRequest.sellers.find((sel) => sel.id === sellerId);

    if (seller) {
      const languages = intersection(
        seller.languages!,
        specialRequest.languages,
      );
      if (languages.length > 0) {
        setSelectedLanguage(languages[0]);
      } else {
        setSelectedLanguage(undefined);
      }
    }
  };

  const { account, attendees, startTime, endTime, sellers } = specialRequest;
  const contact = attendees[0];

  const startHour = startTime.toLocalDate(showroom.timezone);
  const endHour = endTime.toLocalDate(showroom.timezone);

  const {
    countryCode,
    name: accountName,
    portfolios: accountPortfolios,
  } = account;

  const portfolios = accountPortfolios?.flatMap((portfolio) => portfolio.name);

  const sellerOptions = useOrganizationRepresentativeOptionsByAvailability({
    sellers,
  });

  return (
    <div
      role="listitem"
      className="flex flex-col border p-4 border-primaryLightGrey rounded-lg"
    >
      <div className="flex items-center justify-between">
        <span className="text-xs">
          {`${format(startHour, "HH:mm")} - ${format(endHour, "HH:mm")}`}
        </span>
        <span className="text-primaryDarkGrey">
          {specialRequest.format === "VIRTUAL" && (
            <HiOutlineVideoCamera className="w-4 h-4" />
          )}
          {specialRequest.format === "IN_PERSON" && (
            <HiOutlineUsers className="w-4 h-4" />
          )}
        </span>
      </div>
      <h3 className="text-lg line-clamp-2 font-medium">
        {accountName} ({countryCode})
      </h3>
      <Collapse
        title={
          <span className="text-primaryElectricBlue underline">
            {t("Calendar.special-requests.see-more-information")}
          </span>
        }
        className="!gap-2 !mt-2 !mb-0"
        buttonClassName="!px-0 !pt-0"
        contentClassName="!px-0 !pb-0 mb-4"
        collapsed={isCollapsed}
        onToggle={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        <div className="flex flex-col gap-1 text-sm">
          <span className="flex items-center gap-2">
            <HiOutlineUsers className="min-w-[1rem] h-4" />
            <span className="line-clamp-2">
              {contact.firstName} {contact.lastName}
            </span>
          </span>
          {specialRequest.type === AppointmentTypeEnum.BUYING_APPOINTMENT && (
            <span className="flex items-center gap-2">
              <TbHanger className="min-w-[1rem] h-4" />
              <span className="line-clamp-2">
                {specialRequest.collection.name}
              </span>
            </span>
          )}
          {specialRequest.type === AppointmentTypeEnum.WALKTHROUGH && (
            <span className="flex items-center gap-2">
              <HiOutlineColorSwatch className="w-4 h-4" />
              {t(`Common.appointment-type.${specialRequest.type}`)}
            </span>
          )}
          {portfolios && (
            <span className="flex items-center gap-2">
              <HiOutlineViewGrid className="min-w-[1rem] h-4" />
              <span className="line-clamp-2">{portfolios.join(", ")}</span>
            </span>
          )}
          {specialRequest.format === "VIRTUAL" &&
            specialRequest.virtualMeetingApp && (
              <span className="flex items-center gap-2">
                <HiOutlineVideoCamera className="min-w-[1rem] h-4" />
                {t(`Common.virtual-tool.${specialRequest.virtualMeetingApp}`)}
              </span>
            )}
          <span className="flex items-center gap-2">
            <HiOutlineChat className="min-w-[1rem] h-4" />
            <span className="line-clamp-2">
              {specialRequest.languages
                .map((language) => getLanguageByCode(language))
                .join(", ")}
            </span>
          </span>
        </div>
      </Collapse>
      <div className="flex flex-col">
        <Select
          id="sellerId"
          name="sellerId"
          placeholder={t("Calendar.special-requests.select-placeholder")}
          showGroups
          defaultValue={{
            value: selectedSellerId,
          }}
          onChange={(sellerId: string) => {
            setSelectedSellerId(sellerId);
            selectFirstSellerLanguage(sellerId);
          }}
          options={sellerOptions}
        />
      </div>
      <div className="flex mt-4 justify-between">
        <Button
          loading={isLoading}
          theme="DANGER"
          size="small"
          type="button"
          onClick={() => onDecline(specialRequest)}
        >
          {t("Calendar.special-requests.decline")}
        </Button>
        <Button
          loading={isLoading}
          theme="PRIMARY"
          size="small"
          type="button"
          disabled={!selectedSellerId || !selectedLanguage}
          onClick={() =>
            onConfirm(specialRequest, selectedSellerId!, selectedLanguage!)
          }
        >
          {t("Calendar.special-requests.confirm")}
        </Button>
      </div>
    </div>
  );
}

export default SpecialRequestItem;
