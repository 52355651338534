import { ContactDetails } from "@models/old/Contact";
import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

export const fetchContactsSearchByEmailEndpoint = {
  path: (organizationId: Organization["id"], email?: string) =>
    `/organizations/${organizationId}/contacts/search-by-email/${email}`,
};

type FetchContactsByParamResponse = ContactDetails[];

export const fetchContactsSearchByEmail = (
  organizationId: string,
  email: string,
) =>
  axiosInstance
    .get<FetchContactsByParamResponse>(
      fetchContactsSearchByEmailEndpoint.path(organizationId, email),
    )
    .then((res) => res.data);
