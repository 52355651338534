import React, { useId } from "react";

import { RowData, Table } from "@tanstack/react-table";
import { t } from "i18next";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { concat, range } from "remeda";

export type PaginationItem = "ellipsis" | number;

export const buildPaginationItems = (
  pageCount: number,
  pageIndex: number,
  maxPageItems: number = 9,
): PaginationItem[] => {
  if (pageCount === 0) return [];
  if (pageCount <= maxPageItems) {
    return range(0, pageCount);
  }

  if (pageIndex < maxPageItems - 3) {
    return concat(range(0, maxPageItems - 2), ["ellipsis", pageCount - 1]);
  }
  const upperLimit = pageCount - (maxPageItems - 2);
  if (pageIndex > upperLimit) {
    return concat([0, "ellipsis"], range(upperLimit, pageCount));
  }

  const paginationItems: PaginationItem[] = [];
  paginationItems.push(0);
  paginationItems.push("ellipsis");
  for (let i = pageIndex - 2; i <= pageIndex + 2; i++) {
    paginationItems.push(i);
  }
  paginationItems.push("ellipsis");
  paginationItems.push(pageCount - 1);
  return paginationItems;
};

function PageButton({
  item,
  table,
}: {
  item: PaginationItem;
  table: Table<any>;
}) {
  let className =
    "flex flex-col items-center justify-center w-8 h-8 cursor-pointer";
  if (item === "ellipsis") {
    return <span className={className}>...</span>;
  }
  if (item === table.getState().pagination.pageIndex) {
    className += " rounded-full bg-primaryElectricBlue text-white";
  } else {
    className +=
      " hover:rounded-full hover:bg-primaryElectricBlue hover:text-white";
  }
  return (
    <button
      onClick={() => {
        table.setPageIndex(item);
      }}
      type="button"
      disabled={item === table.getState().pagination.pageIndex}
      className={className}
    >
      {item + 1}
    </button>
  );
}

export function Pagination<TData extends RowData>({
  table,
}: {
  table: Table<TData>;
}) {
  const id = useId();
  const buttons = buildPaginationItems(
    table.getPageCount(),
    table.getState().pagination.pageIndex,
  ).map((item, index) => {
    const key = `${id}-${index}`;
    return <PageButton key={key} item={item} table={table} />;
  });
  return <div className="flex gap-2">{buttons}</div>;
}

export function PaginationBottomBar<TData extends RowData>({
  table,
  displayDataLabel,
}: {
  table: Table<TData>;
  displayDataLabel?: string;
}) {
  if (table.getRowModel().rows.length === 0) return null;

  return (
    <div className="flex flex-col flex-wrap items-center gap-2 px-4 py-2 md:gap-0 md:justify-between md:flex-row bg-primaryLightestGrey">
      <div className="self-start">
        {t("Common.displaying-results", {
          display: table.getRowModel().rows.length,
          total: table.getCoreRowModel().rows.length,
          type: displayDataLabel,
        })}
      </div>
      <div className="flex items-center self-end justify-center gap-2">
        <button
          type="button"
          aria-label={t("Common.previous-page")}
          className="enabled:hover:rounded-full enabled:hover:bg-primaryElectricBlue"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <span className="hidden">{t("Common.previous-page")}</span>
          <HiChevronLeft
            className={`w-8 h-8 ${
              table.getCanPreviousPage()
                ? "text-primaryElectricBlue hover:text-white"
                : "text-primaryLightGrey"
            }`}
          />
        </button>
        <Pagination table={table} />
        <button
          type="button"
          name="next page"
          aria-label={t("Common.next-page")}
          className="enabled:hover:rounded-full enabled:hover:bg-primaryElectricBlue enabled:hover:text-white"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <span className="hidden">{t("Common.next-page")}</span>
          <HiChevronRight
            className={`w-8 h-8 ${
              table.getCanNextPage()
                ? "text-primaryElectricBlue hover:text-white"
                : "text-primaryLightGrey"
            }`}
          />
        </button>
      </div>
    </div>
  );
}
