import React from "react";

import { Outlet } from "react-router-dom";

import ErrorBoundary from "@services/error/error-boundary";

const appVersion = import.meta.env.VITE_APP_VERSION;
console.log("APP_VERSION", appVersion);

export default function Layout() {
  return (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  );
}
