import { filter, flatMapToObj, map, pipe } from "remeda";

import { AccountDetails } from "@models/old/Account";
import { PortfolioFormData } from "@models/old/Portfolio";
import { PortfolioWithCapabilities } from "@services/api/old/organization/fetch-portfolios-with-capabilities";

export default function accountRowsBuilder(
  portfolio: PortfolioFormData,
  portfolios: PortfolioWithCapabilities[],
) {
  const accountToPortfolio = pipe(
    portfolios,
    filter((p) => p.brandId === portfolio.brandId),
    flatMapToObj((p) => map(p.accounts, (account) => [account.id, p])),
  );
  return (rows: AccountDetails[]) =>
    rows.map((account) => {
      // see if account is linked to a portfolio
      // if no, it's selectable
      // if yes and not the portfolio being edited, it's not selectable
      // if yes and it's the portfolio being edited, it's unselectable
      const linkedPortfolio = accountToPortfolio[account.id];
      return {
        ...account,
        portfolioName: linkedPortfolio?.name,
        canBeSelected:
          linkedPortfolio === undefined || linkedPortfolio.id === portfolio.id,
      };
    });
}
