import { useContext, useEffect } from "react";

import OrganizationAPI from "@services/api/old/organization/OrganizationAPI";

import { ApplicationContext } from "./ApplicationContext";

export default function useApplicationContext() {
  const context = useContext(ApplicationContext);
  if (context === undefined) {
    throw new Error(
      "useApplicationContext must be used within a ApplicationContextProvider",
    );
  }
  return context;
}

export function useOrganizationAppContext() {
  const { organization } = useApplicationContext();

  if (!organization) {
    throw new Error(
      "You are not associated with a organization. Please contact support.",
    );
  }

  return { organization };
}

export function useOrganizationContextRefresh() {
  const { setOrganization, organization } = useApplicationContext();

  useEffect(() => {
    if (organization) {
      OrganizationAPI.getOrganization(organization.id).then((org) => {
        setOrganization(org);
      });
    }
  }, []);
}
