// import { useQuery } from "@tanstack/react-query";
// import { generateMock } from "@anatine/zod-mock";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { accountSchema } from "@models/Account";
import { appointmentHydratingSchema } from "@models/Appointment";
import { collectionSchema } from "@models/Collection";
import { CollectionInformationByAccountSchema } from "@models/CollectionInformationByAccount";
import { contactSchema } from "@models/Contact";
import { meetingReportSchema } from "@models/MeetingReport";
// import { representativeSchema } from "@models/OrganizationRepresentative";
import { showroomSchema } from "@models/Showroom";

import axiosInstance from "../config";
import { getAPIQueryKey } from "../helper";

// define a namespace for GetAccount functionality
export namespace GetAccount {
  // define outputSchema

  export const outputSchema = accountSchema
    .pick({
      id: true,
      name: true,
      type: true,
      status: true,
      city: true,
      countryCode: true,
      continentCode: true,
      isKeyClient: true,
    })
    //* extend with meeting report data
    .extend({
      appointments: z.array(
        appointmentHydratingSchema
          .pick({
            id: true,
            startTime: true,
            endTime: true,
            format: true,
            virtualMeetingApp: true,
            type: true,
          })
          .extend({
            showroom: showroomSchema.pick({
              id: true,
              season: true,
              year: true,
              timezone: true,
            }),
            meetingReport: meetingReportSchema
              .pick({ otb: true, actualBudget: true, notes: true })
              .nullable(),
            collection: collectionSchema.pick({ name: true, id: true }).extend({
              collectionInformationByAccount: z.array(
                CollectionInformationByAccountSchema.pick({
                  status: true,
                }),
              ),
            }),
            attendees: z.array(
              contactSchema.pick({ firstName: true, lastName: true }),
            ),
          }),
      ),
    });

  // infer the Output type from the outputSchema
  export type Output = z.infer<typeof outputSchema>;
  export type HookParams = {
    organizationId: string;
    accountId: string;
  };

  // construct a URL path based on HookParams
  export function path({ organizationId, accountId }: HookParams) {
    return `/organizations/${organizationId}/accounts/${accountId}`;
  }

  export const getQueryKeys = (p: HookParams) => getAPIQueryKey(path(p));

  // make the API call and validate the response against the outputSchema

  //! To comment out after updating the API
  export function call(params: HookParams) {
    return axiosInstance
      .get<Output>(path(params))
      .then((r) => outputSchema.parse(r.data));
  }

  // custom hook for fetching
  export function useHook(params: HookParams) {
    return useQuery({
      queryKey: getQueryKeys(params),
      queryFn: () => call(params),
    });
  }
}
