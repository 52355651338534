import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { Organization } from "@models/Organization";
import { dateHydratingSchema } from "@models/types/Dates";
import {
  InvitationStatusList,
  MailDeliveryStatusList,
  ShowroomSeason,
} from "@models/types/enums";
import LogService from "@services/log/service";

import axiosInstance from "../config";
import { getAPIQueryKey } from "../helper";

export namespace GetInvitations {
  export type Params = {
    organizationId: Organization["id"];
    season: ShowroomSeason;
    year: number;
  };

  export const outputSchema = z.array(
    z
      .object({
        id: z.string(),
        invitationStatus: z.enum(InvitationStatusList),
        contactId: z.string(),
        invitationId: z.string(),
        showroomId: z.string(),
      })
      .extend({
        emailTrackings: z.array(
          z.object({
            createdAt: dateHydratingSchema,
            email: z.string().email(),
            messageId: z.string(),
            status: z.enum(MailDeliveryStatusList),
          }),
        ),
      }),
  );

  export type Output = z.infer<typeof outputSchema>;
  export type OutputItem = Output[number];

  export const path = ({ organizationId, season, year }: Params) =>
    `/organizations/${organizationId}/invitations/season/${season}/${year}`;

  export const call = (params: Params) =>
    axiosInstance
      .get<Output>(path(params))
      .then((r) => outputSchema.parse(r.data))
      .catch(LogService.error);

  export const queryKeys = (params: Params) => getAPIQueryKey(path(params));

  export function useHook(params: Params) {
    return useQuery({
      queryKey: queryKeys(params),
      queryFn: () => call(params),
    });
  }
}
