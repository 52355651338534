import React from "react";

type Props<V> = {
  value: V;
  options: {
    label: string;
    value: V;
  }[];
  onChange: (value: V) => void;
};

export default function InlineTableFilterSelect<V>({
  options,
  onChange,
  value,
}: Props<V>) {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(options[parseInt(e.target.value, 10)].value);
  };
  const valueIndex = options.findIndex((o) => o.value === value);
  return (
    <select
      className="first:rounded-l last:rounded-r bg-primaryLightElectricBlue border text-primaryElectricBlue border-primaryElectricBlue"
      onChange={handleChange}
      value={valueIndex}
    >
      {options.map((o, i) => (
        <option key={o.value as string} value={i}>
          {o.label}
        </option>
      ))}
    </select>
  );
}
