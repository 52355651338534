import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import { collectionSchema } from "@models/Collection";
import { Organization } from "@models/Organization";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export namespace GetOrganizationCollectionsEndpoint {
  export const outputSchema = z.array(
    collectionSchema
      .pick({
        id: true,
        name: true,
      })
      .extend({
        brand: brandSchema.pick({
          id: true,
          name: true,
          organizationId: true,
        }),
      }),
  );

  export type Output = z.infer<typeof outputSchema>;

  export interface HookParams {
    organizationId: Organization["id"];
  }

  export const path = ({ organizationId }: HookParams) =>
    `/organizations/${organizationId}/collections`;

  export const getQueryKeys = (p: HookParams) => getAPIQueryKey(path(p));

  export function call(params: HookParams) {
    return axiosInstance
      .get<Output>(path(params))
      .then((r) => outputSchema.parse(r.data));
  }

  export function useHook(params: HookParams) {
    return useQuery({
      queryKey: getAPIQueryKey(path(params)),
      queryFn: () => call(params),
    });
  }
}
