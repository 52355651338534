import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

import axiosInstance from "@services/api/config";
import { organizationInformationFormDataSchema } from "@shared/organization/organization-information-form";

export namespace StartOrganizationOnboardingEndpoint {
  export const inputSchema = organizationInformationFormDataSchema;
  export type Input = z.infer<typeof inputSchema>;

  export const outputSchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
  });
  export type Output = z.infer<typeof outputSchema>;

  interface HookParams {}

  interface MutationParams extends Input {}

  type Params = HookParams & MutationParams;

  export const path = () => `/organizations/onboarding`;

  export const call = (params: Params) =>
    axiosInstance
      .post(path(), inputSchema.parse(params))
      .then((res) => outputSchema.parse(res.data));

  export function useHook(hookParams: HookParams) {
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
          ...hookParams,
        }),
    });
  }
}
