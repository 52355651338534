import { useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { unzonedDateToLocalDate, unzonedTimeToLocalTime } from "@helpers/Date";
import axiosInstance from "@services/api/config";

export namespace GetCruisingShowroomsEndpoint {
  export const outputSchema = z.array(
    z.object({
      closingHour: z.string().transform((s) => unzonedTimeToLocalTime(s)),
      dates: z.array(z.string().transform((s) => unzonedDateToLocalDate(s))),
      formattedAddress: z.string(),
      id: z.string().uuid(),
      latitude: z.number(),
      longitude: z.number(),
      postalCode: z.string(),
      city: z.string(),
      countryCode: z.string(),
      name: z.string(),
      openingHour: z.string().transform((s) => unzonedTimeToLocalTime(s)),
      duration: z.number().min(15),
    }),
  );
  export type Output = z.infer<typeof outputSchema>;
  export type OutputItem = Output[number];

  export const queryKeys = () => ["cruising", "showrooms"];
  export const call = () =>
    axiosInstance
      .get("/cruising/showrooms")
      .then((r) => outputSchema.parse(r.data));

  export const useHook = () =>
    useQuery({
      queryKey: queryKeys(),
      queryFn: call,
    });

  export const useInvalidate = () => {
    const qc = useQueryClient();
    return () => qc.invalidateQueries({ queryKey: queryKeys() });
  };
}
