import { z } from "zod";

import {
  AccountAppointmentTypeList,
  MarketTypesList,
  RepresentativeInvitationStatusList,
  VirtualMeetingAppsList,
} from "@models/types/enums";

export const RepresentativeRoleList = [
  "ORGANIZATION_MAIN_ADMIN",
  "ORGANIZATION_ADMIN",
  "ORGANIZATION_MANAGER",
  "AGENT",
  "FREELANCE",
] as const;

export const RepresentativeRole = z.enum(RepresentativeRoleList);

export const virtualMeetingAppSchema = z.array(z.enum(VirtualMeetingAppsList));
export const virtualMeetingAppLinksSchema = z.record(
  z.enum(VirtualMeetingAppsList),
  z.string(),
);
export const representativeSchema = z.object({
  id: z.string(),
  email: z.string().nullable().default(""),
  firstName: z.string(),
  lastName: z.string(),
  invitationStatus: z.enum(RepresentativeInvitationStatusList),
  languages: z.array(z.string()),
  markets: z.array(z.enum(MarketTypesList)),
  phoneNumber: z.string().nullable(),
  position: z.string().nullable(),
  role: RepresentativeRole,
  status: z.string(),
  virtualMeetingAppLinks: virtualMeetingAppLinksSchema.nullish(),
});

export type Representative = z.infer<typeof representativeSchema>;

export const showroomSellerSchema = representativeSchema
  .pick({
    firstName: true,
    id: true,
    languages: true,
    lastName: true,
    virtualMeetingAppLinks: true,
  })
  .extend({
    appointmentTypes: z.array(z.enum(AccountAppointmentTypeList)),
  });

export type ShowroomSeller = z.infer<typeof showroomSellerSchema>;

/** @todo remove following code */
export const organizationRepresentativeSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  role: RepresentativeRole,
  virtualMeetingApps: z.array(z.enum(VirtualMeetingAppsList)),
});

export type OrganizationRepresentative = z.infer<
  typeof organizationRepresentativeSchema
>;
