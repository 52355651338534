import { useQuery } from "@tanstack/react-query";

import { ContactDetails } from "@models/old/Contact";
import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

export const fetchContactsEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/contacts`,
};

type FetchContactsResponse = ContactDetails[];

export const queryKeysFetchContacts = (organizationId: string) =>
  fetchContactsEndpoint.path(organizationId).split("/");

export const useFetchContacts = (organizationId: string) =>
  useQuery({
    queryKey: queryKeysFetchContacts(organizationId),
    queryFn: () =>
      axiosInstance
        .get<FetchContactsResponse>(fetchContactsEndpoint.path(organizationId))
        .then((res) => res.data),
  });
