import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { Appointment } from "@models/Appointment";
import { meetingReportSchema } from "@models/MeetingReport";
import { Organization } from "@models/Organization";

import axiosInstance from "../config";
import { getAPIQueryKey } from "../helper";

export namespace GetMeetingReport {
  export type Params = {
    organizationId: Organization["id"];
    appointmentId: Appointment["id"];
  };

  export const outputSchema = meetingReportSchema;

  export type Output = z.infer<typeof outputSchema>;

  export const path = ({ organizationId, appointmentId }: Params) =>
    `/organizations/${organizationId}/appointments/${appointmentId}/meeting-report`;

  export const call = (params: Params) =>
    axiosInstance
      .get<Output>(path(params))
      .then((response) => outputSchema.parse(response.data));

  export const queryKeys = (params: Params) => getAPIQueryKey(path(params));

  export function useHook(params: Params) {
    return useQuery({
      queryKey: queryKeys(params),
      queryFn: () => call(params),
    });
  }
}
