import { unzonedDateToLocalDate } from "@helpers/Date";
import { Merge } from "@helpers/Object";
import { SalesCampaign } from "@models/old/SalesCampaign";

interface SalesCampaignApiOverride {
  openingDate: string;
  closingDate: string;
}

type SalesCampaignFromApi = Merge<SalesCampaign, SalesCampaignApiOverride>;

const salesCampaignMapper = (salesCampaign: SalesCampaignFromApi) => ({
  ...salesCampaign,
  openingDate:
    salesCampaign.openingDate &&
    unzonedDateToLocalDate(salesCampaign.openingDate),
  closingDate:
    salesCampaign.closingDate &&
    unzonedDateToLocalDate(salesCampaign.closingDate),
});

export default salesCampaignMapper;
