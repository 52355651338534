/* eslint-disable no-continue */
import { differenceInMinutes, format } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import addHours from "date-fns/addHours";
import addMinutes from "date-fns/addMinutes";
import eachMinuteOfInterval from "date-fns/eachMinuteOfInterval";
import startOfDay from "date-fns/startOfDay";

export function generateTime(
  hour: number,
  minutes: number = 0,
  seconds: number = 0,
) {
  const date = new Date(0);
  date.setUTCHours(hour, minutes, seconds);
  return date;
}

/**
 * This function creates a new Date object supposed to be read in UTC
 */
export function getUTCDate() {
  const today = new Date();
  today.setUTCDate(today.getDate());
  today.setUTCMonth(today.getMonth());
  today.setUTCFullYear(today.getFullYear());
  today.setUTCHours(0, 0, 0, 0);

  return today;
}

export const setTimeOfDate = (time: Date, selectedDate: Date = new Date()) =>
  addMinutes(
    addHours(startOfDay(selectedDate), time.getHours()),
    time.getMinutes(),
  );

export function getStartAndEnd(dates: Date[]): [Date, Date] {
  const timestamps = dates.map((date) => date.getTime());
  return [new Date(Math.min(...timestamps)), new Date(Math.max(...timestamps))];
}

export function formatMinutes(minutes: number) {
  const levels: any[] = [
    [Math.floor(minutes / 525960), "years"],
    [Math.floor((minutes % 525960) / 1440), "days"],
    [Math.floor(((minutes % 525960) % 1440) / 60), "hours"],
    [Math.floor(((minutes % 525960) % 1440) % 60), "minutes"],
  ];
  let returntext = "";

  for (let i = 0, max = levels.length; i < max; i++) {
    if (levels[i][0] === 0) continue;
    returntext += ` ${levels[i][0]} ${
      levels[i][0] === 1
        ? levels[i][1].substr(0, levels[i][1].length - 1)
        : levels[i][1]
    }`;
  }
  return returntext.trim();
}

type DurationLength = {
  datetime: Date;
  minutes: number;
  formatted: string;
};

export const generateTimeLengths = (
  quantity: number,
  stepper: number = 15,
  skipZero: boolean = true,
) => {
  const startTime = startOfDay(new Date());
  const endTime = addMinutes(startTime, stepper * quantity);
  const durations: DurationLength[] = [];

  eachMinuteOfInterval(
    { start: startTime, end: endTime },
    { step: 15 },
  ).forEach((i) => {
    const diffMinutes = differenceInMinutes(i, startTime);
    if (!(skipZero && diffMinutes <= 0)) {
      durations.push({
        datetime: i,
        minutes: diffMinutes,
        formatted: formatMinutes(diffMinutes),
      });
    }
  });

  return durations;
};

export function formatTime(time: Date, timeFormat: string = "H:mm") {
  return format(time, timeFormat);
}

export function formatTimeInterval(from: Date, to: Date) {
  return `${formatTime(from)} - ${formatTime(to)}`;
}

export function formatDate(date: Date) {
  return format(date, "dd MMMM yyyy");
}

export function formatDateShort(date: Date) {
  return format(date, "yyyy/MM/dd");
}

export function formatDateToISO(date: Date) {
  return format(date, "yyyy-MM-dd");
}

export function formatDatetimeIntervalAtTimezone(
  startTime: ZonedDate,
  endTime: ZonedDate,
  timezone: string,
  dateFormat: string = "dd/MM/yy",
) {
  const startTimeAtTimezone = startTime.toLocalDate(timezone);
  const endTimeAtTimezone = endTime.toLocalDate(timezone);
  const date = format(startTimeAtTimezone, dateFormat);

  return `${date} | ${formatTimeInterval(
    startTimeAtTimezone,
    endTimeAtTimezone,
  )}`;
}

export function formatLongLocalizedDate(date: Date) {
  return format(date, "PPPP");
}

export function formatDatetime(date: Date) {
  return format(date, "PPPp");
}

/**
 * Extracts the date information from the DateObject
 * The time information is not supposed to be picked from the datepicker but from the timepicker
 */
export function extractDateInformation(dateObj: Date) {
  dateObj.setHours(0, 0, 0, 0);
  return dateObj;
}

export function combineDateAndTime(date: Date, hours: Date) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours.getHours(),
    hours.getMinutes(),
    0,
  );
}

export function localTimeToUnzonedTime(localTime: Date) {
  const unzonedTime = new Date(0);
  unzonedTime.setUTCHours(localTime.getHours());
  unzonedTime.setUTCMinutes(localTime.getMinutes());

  return unzonedTime;
}

export function unzonedTimeToLocalTime(timeString: Date | string) {
  const timeDate = new Date(timeString);
  const [hours, minutes] = [timeDate.getUTCHours(), timeDate.getUTCMinutes()];

  timeDate.setHours(hours, minutes);
  return timeDate;
}

export function toLocalTime(date: Date): Date {
  date.setFullYear(1970, 0, 1);
  return date;
}

export function timeStringToLocalTime(timeStr: string) {
  const [hours, minutes] = timeStr.split(":");
  const date = new Date(0);
  date.setHours(parseInt(hours, 10), parseInt(minutes, 10));
  return date;
}

export function localDateToUnzonedDate(localDate: Date) {
  const unzonedDate = new Date(0);
  unzonedDate.setUTCFullYear(localDate.getFullYear());
  unzonedDate.setUTCMonth(localDate.getMonth());
  unzonedDate.setUTCDate(localDate.getDate());

  return unzonedDate;
}

export function unzonedDateToLocalDate(dateString: Date | string) {
  const date = new Date(dateString);
  const [year, month, day] = [
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
  ];

  date.setFullYear(year);
  date.setMonth(month);
  date.setDate(day);

  return date;
}

/**
 * This object will wrap dates that don't point to the timestamp they are supposed to
 * It is done to help avoid comparing Date objects that are not correct.
 *
 * Here are the two scenarios :
 * - we write a date and time to the API (booking an appointment)
 * - we read a date and time from the API to display it in pickers (editing an appointment)
 *
 * EXAMPLE : WRITE
 * I want to book an appointment for May 25th, at 02:00 in the morning
 * ```const dateFromDatePicker = new Date('2023-05-22T02:00:00.000Z'); // Europe/Paris```
 *
 * "dateFromDatePicker" describes the appointment date and time "locally", but it does not point to the right timestamp
 *
 * My showroom is in a different timezone though :
 * ```const showroomTimezone = 'America/New_York';```
 *
 * so I have to do this to get the real time :
 * ```const realDate = zonedTimeToUtc(dateFromDatePicker, showroomTimezone);```
 *
 * And so :
 * ```
 * the appointment is on : dateFromDatePicker.toString()
 * but we will save in base : realDate.toISOString()
 * ```
 *
 * EXAMPLE : READ
 * I receive from the API an appointment described with
 * ```const dateFromApi = new Date('2023-05-22T06:00:00.000Z');```
 *
 * "dateFromApi" describes the appointment date and time globally, but we don't know in which timezone
 * so we cannot do "dateFromApi.getHours()" to get the hours when it will start, because we might not be in the same timezone.
 *
 * We have to get the showroom's timezone first :
 * ```const showroomTimezone = 'America/New_York';```
 *
 * Now I want to edit that appointment, so I need the date and time values to put them in the date and time pickers.
 * I have to use :
 * ```const fakeDate = utcToZonedTime(dateFromApi, showroomTimezone);```
 *
 * And so fakeDate describes IN THE LOCAL TIMEZONE the date and time of the appointment
 * so we can use it with date and time pickers to display the right informations :
 * ```
 * <TimePicker value={fakeDate.getHour()+':'+fakeDate.getMinutes()} />
 * <DatePicker value={fakeDate.getDate()} />
 * ```
 */
export type RealDate = Date & { __type: "real" };
export type LocalDate = Date & { __type: "local" };

export class ZonedDate {
  /**
   * the real timestamp described
   */
  realDate: RealDate;

  constructor(realDate: RealDate) {
    this.realDate = realDate;
  }

  /**
   * used to parse data from the API
   */
  static fromRealDate(date: RealDate) {
    return new ZonedDate(date);
  }

  /**
   * used to parse data from the pickers (date/time)
   */
  static fromLocalDate(date: LocalDate, timezone: string) {
    return new ZonedDate(ZonedDate.toRealDate(date, timezone));
  }

  static toRealDate(localDate: LocalDate, timezone: string): RealDate {
    return zonedTimeToUtc(localDate, timezone) as RealDate;
  }

  static toLocalDate(realDate: RealDate, timezone: string): LocalDate {
    return utcToZonedTime(realDate, timezone) as LocalDate;
  }

  toLocalDate(timezone: string) {
    return ZonedDate.toLocalDate(this.realDate, timezone) as LocalDate;
  }

  formatTimeAtTimezone(timezone: string) {
    return formatTime(this.toLocalDate(timezone));
  }

  formatLocalizedDateAtTimezone(timezone: string) {
    return formatLongLocalizedDate(this.toLocalDate(timezone));
  }

  formatDateAtTimezone(timezone: string) {
    return formatDate(this.toLocalDate(timezone));
  }
}
