import * as React from "react";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Button from "@components/data-entry/Button";
import TextField from "@components/data-entry/TextField";
import Loading from "@components/feedback/Loading";
import BottomBar from "@components/layout/BottomBar";
import { CompetitorsGroup } from "@models/CompetitorGroup";
import filterAccounts from "@onboarding/components/accountPortfolio/components/AccountFiltering";
import { AccountPortfolioFilter } from "@onboarding/components/accountPortfolio/components/AccountPortfolioFilter";
import { useGetFullAccounts } from "@services/api/accounts/get-full-accounts";
import { useCreateCompetitorsGroup } from "@services/api/organization/create-competitors-group";
import { useUpdateCompetitorsGroup } from "@services/api/organization/update-competitors-group";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import AccountsTable from "@shared/accounts/AccountsTable";
import Filters from "@shared/accounts/Filters";

interface CompetitorsGroupFormValues {
  name: string;
  accounts: string[];
}

const emptyFormValues: CompetitorsGroupFormValues = {
  name: "",
  accounts: [],
};

function toCompetitorsGroupFormValues(
  competitorsGroup: CompetitorsGroup,
): CompetitorsGroupFormValues {
  const accountsIds = competitorsGroup.accounts.map(({ id }) => id);
  return {
    name: competitorsGroup.name,
    accounts: accountsIds,
  };
}

interface UseCompetitorsGroupFormProps {
  competitorsGroup?: CompetitorsGroup;
  onSuccess: () => void;
}

export function useCompetitorsGroupForm({
  competitorsGroup = undefined,
  onSuccess,
}: UseCompetitorsGroupFormProps) {
  const defaultValues = competitorsGroup
    ? toCompetitorsGroupFormValues(competitorsGroup)
    : {
        name: "",
        accounts: [],
      };

  const form = useForm({
    mode: "onChange",
    values: defaultValues,
  });

  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const { data, isLoading } = useGetFullAccounts(organizationId);
  const accounts = data || [];

  const [filters, setFilters] = React.useState<AccountPortfolioFilter>({
    showOnlySelected: undefined,
    accountName: undefined,
    geography: undefined,
    accountStatus: undefined,
  });

  const groupAccounts = form.watch("accounts");

  return {
    form,
    filters,
    setFilters,

    onSuccess,
    accounts,
    isLoading,
    filteredAccounts: filterAccounts(accounts, filters, groupAccounts),

    editId: competitorsGroup?.id || null,
  };
}

export default function CompetitorsGroupForm({
  accounts,
  filteredAccounts,
  filters,
  isLoading,
  setFilters,
  editId,
  onSuccess,
  form: {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  },
}: ReturnType<typeof useCompetitorsGroupForm>) {
  const { t } = useTranslation();

  const accountsRows = filteredAccounts.map((acc) => ({
    ...acc,
    portfolioName: acc.portfolios.map((p) => p.name).join(","),
    canBeSelected: true,
  }));

  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();
  const { mutateAsync: createCompetitorsGroup } = useCreateCompetitorsGroup({
    organizationId,
  });
  const { mutateAsync: updateCompetitorsGroup } = useUpdateCompetitorsGroup({
    organizationId,
  });

  const onSubmitForm = (values: CompetitorsGroupFormValues) => {
    // retrieve form values and adapt to API input
    const competitorsGroup = {
      ...values,
      organizationId,
      accounts: values.accounts.map((id) => ({ id })),
    };
    if (editId) {
      updateCompetitorsGroup({
        competitorsGroup: { id: editId, ...competitorsGroup },
        competitorsGroupId: editId,
      }).then(() => {
        toast.success(t("CRM.competitors.form.update-success"));
        onSuccess();
        reset(emptyFormValues);
      });
    } else {
      createCompetitorsGroup({
        competitorsGroup,
      }).then(() => {
        toast.success(t("CRM.competitors.form.create-success"));
        onSuccess();
        reset(emptyFormValues);
      });
    }
  };

  if (isLoading) {
    return <Loading type="screen" />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className="p-4 flex-col space-y-2">
        <div>
          <label className="font-medium" htmlFor="name">
            {t("CRM.competitors.form.name-label")}
          </label>
          <Controller
            control={control}
            name="name"
            rules={{
              required: t("CRM.competitors.form.errors.name-required"),
            }}
            render={({
              field: { name, onChange, value },
              fieldState: { error },
            }) => (
              <>
                <span className="text-primaryRed">
                  {error && error.message}
                </span>
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  value={value}
                  hasError={error !== undefined}
                />
              </>
            )}
          />
        </div>
        <div className="my-2 flex-col space-y-2">
          <label className="font-medium">
            {t("CRM.competitors.form.accounts-label")}
          </label>
          <Controller
            name="accounts"
            control={control}
            rules={{
              validate: {
                moreThan2Selected: (v) =>
                  v.length >= 2 ||
                  t(
                    "CRM.competitors.form.errors.less-than-2-accounts-selected",
                  ),
              },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <span className="text-primaryRed">
                  {error && error.message}
                </span>
                <Filters
                  accounts={accounts}
                  filters={filters}
                  onChange={setFilters}
                />

                <AccountsTable
                  accounts={accountsRows}
                  selectedAccounts={value}
                  onSelectionChange={onChange}
                />
              </>
            )}
          />
        </div>
      </div>
      <BottomBar>
        <Button theme="PRIMARY" disabled={!isValid}>
          Save
        </Button>
      </BottomBar>
    </form>
  );
}
