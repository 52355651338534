import React from "react";

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import BookingWizard from "@booking/components/wizard";
import Loading from "@components/feedback/Loading";
import fetchBookingEndpoint from "@services/api/old/booking/fetch-booking";
import LogService from "@services/log/service";

export default function AppointmentBookingPage() {
  const { invitationId } = useParams();

  if (!invitationId) {
    throw new Error("no invitation id");
  }

  const {
    data: bookingData,
    isError: isBookingDataError,
    error: bookingDataError,
    isPending,
  } = useQuery({
    ...fetchBookingEndpoint.query(invitationId),
  });

  if (isPending) {
    return <Loading type="screen" />;
  }

  if (isBookingDataError) {
    LogService.error(bookingDataError);
    throw bookingDataError;
  }

  return <BookingWizard bookingData={bookingData} />;
}
