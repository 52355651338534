import React from "react";

import { useTranslation } from "react-i18next";
import { HiPencil, HiTrash } from "react-icons/hi";

import GridItem from "@components/data-display/GridItem";
import Tooltip from "@components/data-display/Tooltip";
import Button from "@components/data-entry/Button";
import { PortfolioWithCapabilities } from "@services/api/old/organization/fetch-portfolios-with-capabilities";
import {
  ACL_ADMINS,
  useUserRole,
} from "@shared/components/access-control/helpers";
import { fullName } from "@shared/helpers/formatters";
import useUserRepresentativeId from "@shared/hooks/useUserRepresentativeId";

interface PortfolioProps {
  portfolio: PortfolioWithCapabilities;
  onEditPortfolio: (portfolio: PortfolioWithCapabilities) => void;
  onDeletePortfolio: (portfolio: PortfolioWithCapabilities) => void;
  deleteDisabled?: boolean;
}

function PortfolioItem(props: PortfolioProps) {
  const { t } = useTranslation();
  const {
    portfolio,
    onEditPortfolio,
    onDeletePortfolio,
    deleteDisabled = false,
  } = props;

  const { isAdmin, isAgent } = useUserRole();
  const userRepresentativeId = useUserRepresentativeId();

  if (!portfolio) return null;

  const isPortfolioManager = portfolio.manager.id === userRepresentativeId;
  const canEdit = isAdmin || (isAgent && isPortfolioManager);

  return (
    <GridItem
      color={portfolio.color}
      title={portfolio.name}
      actions={
        <>
          <Button
            renderIf={canEdit}
            type="button"
            theme="ICON"
            label={`edit portfolio ${portfolio.name}`}
            className="p-2"
            onClick={() => onEditPortfolio(portfolio)}
          >
            <HiPencil className="w-6 h-6" />
          </Button>
          <Tooltip
            placement="left"
            aclRoles={ACL_ADMINS}
            renderIf={deleteDisabled}
            fallbackProp="children"
            content={
              <div className="w-96 flex flex-col gap-4 text-center">
                <p>
                  {t(
                    "Shared.portfolios.overview.tooltip.archiving-blocked-part-1",
                  )}
                </p>
                <p>
                  {t(
                    "Shared.portfolios.overview.tooltip.archiving-blocked-part-2",
                  )}
                </p>
              </div>
            }
          >
            <Button
              type="button"
              theme="ICON"
              label={`delete portfolio ${portfolio.name}`}
              className="p-2 text-primaryRed"
              onClick={() => onDeletePortfolio(portfolio)}
              disabled={deleteDisabled}
            >
              <HiTrash className="w-6 h-6" />
            </Button>
          </Tooltip>
        </>
      }
    >
      <div className="p-4">
        {t("Shared.portfolios.manager")}
        {fullName(portfolio.manager)}
        <br />
        {t("Shared.portfolios.sellers")}
        {portfolio.sellers.map((s) => fullName(s)).join(", ")}
      </div>
    </GridItem>
  );
}

export default PortfolioItem;
