import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import axiosInstance from "@services/api/config";

// @todo rename endpoint
export namespace InvitationSendEndpoint {
  // @todo add input schema
  export const inputSchema = z.object({
    contactIds: z.array(z.string()),
    showroomIds: z.array(z.string()),
    grouped: z.boolean(),
  });
  export type Input = z.infer<typeof inputSchema>;

  // @todo add output schema
  export const outputSchema = z.object({});
  export type Output = z.infer<typeof outputSchema>;

  // @todo add hook params
  interface HookParams {
    organizationId: string;
  }

  // @todo add mutation params
  interface MutationParams {}

  type Params = HookParams & MutationParams;

  // @todo add path
  export const path = ({ organizationId }: Params) =>
    `/organizations/${organizationId}/invitations/send-invitations`;

  // @todo add method (put, post, delete, etc...)
  export const call = (params: Params) =>
    axiosInstance
      .post(path(params), inputSchema.parse(params))
      .then((res) => res.data);

  export function useHook(hookParams: HookParams) {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
          ...hookParams,
        }),
      onSuccess: () => {
        // @todo add queries to invalidate
        queryClient.invalidateQueries({
          queryKey: [],
        });
      },
    });
  }
}
