import React, { useState } from "react";

import { t } from "i18next";
import { Trans } from "react-i18next";

import Button from "@components/data-entry/Button";
import TextField from "@components/data-entry/TextField";
import ConfirmModal from "@components/feedback/ConfirmModal";
import { Representative } from "@models/old/Representative";

interface LoginProps {
  user: Representative;
  onChangePassword: () => void;
}

function Login({ user, onChangePassword }: LoginProps) {
  const [isConfirmReset, setIsConfirmReset] = useState<boolean>(false);

  return (
    <>
      <div className="h-full w-full lg:w-1/3 p-6 lg:p-10">
        <label className="font-medium" htmlFor="email">
          {t("MyProfile.form.email-label")}
        </label>
        <div className="mt-2">
          <TextField
            id="email"
            placeholder={t("MyProfile.form.email-placeholder")}
            value={user?.email || undefined}
            disabled
          />
        </div>
        <Button
          type="button"
          className="mt-6"
          onClick={() => {
            setIsConfirmReset(true);
          }}
          theme="SECONDARY"
        >
          {t("MyProfile.change-password-button")}
        </Button>
      </div>
      <ConfirmModal
        show={isConfirmReset}
        title={t("MyProfile.change-password-modal.title")}
        confirmLabel={t("MyProfile.change-password-modal.confirm")}
        onCancel={() => {
          setIsConfirmReset(false);
        }}
        onConfirm={() => {
          onChangePassword();
          setIsConfirmReset(false);
        }}
      >
        <span>
          <Trans
            i18nKey="MyProfile.change-password-modal.are-you-sure"
            components={{
              br: <br />,
            }}
          />
        </span>
      </ConfirmModal>
    </>
  );
}

export default Login;
