import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Organization } from "@models/Organization";
import { OrganizationRepresentative } from "@models/OrganizationRepresentative";
import { Showroom } from "@models/Showroom";
import { getAPIQueryKey } from "@services/api/helper";
import { GetShowroomEndpoint } from "@services/api/showroom/get-showroom";

import axiosInstance from "../config";
import { GetShowroomsEndpoint } from "./get-showrooms";

export namespace RemoveSellersFromShowroom {
  interface HookParams {
    organizationId: Organization["id"];
    showroomId: Showroom["id"];
  }

  interface MutationParams {
    sellerIds: OrganizationRepresentative["id"][];
  }

  type Params = HookParams & MutationParams;

  export const path = ({ organizationId, showroomId }: Params) =>
    `/organizations/${organizationId}/showrooms/${showroomId}/remove-sellers`;

  export const call = (pathParams: Params) =>
    axiosInstance
      .post(path(pathParams), pathParams)
      .then((response) => response.data);

  export function useHook(params: HookParams) {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation({
      mutationFn: (variables: MutationParams) =>
        call({
          ...params,
          ...variables,
        }),
      onSuccess: () => {
        toast.success(t("Toast.sellers-removed"));
        queryClient.invalidateQueries({
          // old salesCampaignAPI.getShowrooms
          queryKey: getAPIQueryKey(GetShowroomsEndpoint.path(params)),
        });
        queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(GetShowroomEndpoint.path(params)),
        });
      },
    });
  }
}
