import ISO6391 from "iso-639-1";

// There are 183 languages supported by the API, we want to use only the following languages:
const languages: string[] = [
  "Arabic",
  "Bengali",
  "Chinese",
  "Croatian",
  "Danish",
  "Dutch",
  "English",
  "French",
  "German",
  "Greek",
  "Hindi",
  "Hungarian",
  "Indonesian",
  "Italian",
  "Japanese",
  "Korean",
  "Norwegian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Serbian",
  "Spanish",
  "Swedish",
  "Swahili",
  "Thai",
  "Turkish",
  "Ukrainian",
];

const getLanguageByCode = (code: string): string => ISO6391.getName(code);

const getCodeByLanguage = (language: string): string =>
  ISO6391.getCode(language);

const getLanguages = (): string[] => languages;

export { getLanguageByCode, getCodeByLanguage, getLanguages };
