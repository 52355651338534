/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from "react";

import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { HiOutlineTrash } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useLockedBody } from "usehooks-ts";

import queryClient from "@app/queryClient";
import RepresentativesViewForAdmin from "@backoffice/components/representatives-view-for-admin";
import MRTable from "@components/data-display/MRTable";
import Button from "@components/data-entry/Button";
import ConfirmModal from "@components/feedback/ConfirmModal";
import Drawer, { useDrawer } from "@components/feedback/Drawer";
import Loading from "@components/feedback/Loading";
import { Organization } from "@models/old/Organization";
import OrganizationAPI from "@services/api/old/organization/OrganizationAPI";

const getOrganizationsQuery = {
  queryKey: ["get-organizations"],
  queryFn: OrganizationAPI.getOrganizations,
};

export default function BackOfficeListOrganizationsPage() {
  const [selectedOrganizationId, setSelectedOrganizationId] = React.useState<
    string | undefined
  >(undefined);
  const [organizationToDelete, setOrganizationToDelete] = React.useState<
    string | undefined
  >(undefined);
  const {
    data: organizations,
    isPending,
    isError,
    error,
  } = useQuery(getOrganizationsQuery);

  const columnHelper = createColumnHelper<Organization>();

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        id: "impersonate",
        cell: ({ row }) => {
          const { id } = row.original;
          return (
            <div className="flex grow">
              <Button
                type="button"
                theme="ICON"
                testId={`connect-as-${id}`}
                onClick={async () => {
                  setSelectedOrganizationId(id);
                }}
              >
                <BsFillPersonLinesFill size={20} />
              </Button>
            </div>
          );
        },
      }),
      columnHelper.accessor("id", {
        header: () => "Id",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("name", {
        header: () => "Name",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("address.formattedAddress", {
        header: () => "Address",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("timezone", {
        header: () => "Timezone",
        cell: (info) => info.getValue(),
      }),
      columnHelper.display({
        id: "onboarding",
        header: "Onboarding",
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => {
          if (row.original.onboardingStatus === "EMAIL_SENT") {
            return "Waiting for organization confirmation";
          }
          if (row.original.onboardingStatus === "DONE") {
            return "Onboarding done";
          }
          return (
            <Link
              className="text-primaryElectricBlue"
              to={`/backoffice/onboard-organization/${row.original.id}/information`}
            >
              Continue onboarding
            </Link>
          );
        },
      }),
      columnHelper.display({
        id: "delete",
        cell: ({ row }) => {
          const { id } = row.original;
          return (
            <div className="flex grow">
              <Button
                type="button"
                theme="ICON"
                testId={`delete-organization-${id}`}
                onClick={async () => {
                  setOrganizationToDelete(id);
                }}
              >
                <HiOutlineTrash size={20} />
              </Button>
            </div>
          );
        },
      }),
    ],
    [columnHelper],
  );

  const isOpen = !!selectedOrganizationId;
  const [locked, setLocked] = useLockedBody(isOpen);
  useEffect(() => {
    if (!isOpen && locked) {
      setLocked(false);
    }
  }, [isOpen, setLocked, locked]);

  const drawer = useDrawer({
    onClose: () => setSelectedOrganizationId(undefined),
    backdrop: true,
  });

  if (isPending) return <Loading type="screen" />;
  if (isError) throw error;

  return (
    <>
      {organizations.length === 0 && "No organizations created so far"}
      {organizations.length > 0 && (
        <div className="w-full p-4">
          <h2 className="text-3xl font-medium px-1 py-4">Organizations</h2>
          <div className="p-4 rounded-lg bg-primaryLightElectricBlue">
            <MRTable
              data={organizations?.sort((a, b) => a.name.localeCompare(b.name))}
              columns={columns}
              paginationPlacement="none"
            />
          </div>
          <ConfirmModal
            show={!!organizationToDelete}
            title="Delete organization"
            confirmLabel="Are you really sure?"
            cancelLabel={t("Components.buttons.cancel")}
            theme="DANGER"
            onCancel={() => {
              setOrganizationToDelete(undefined);
            }}
            onConfirm={() => {
              if (!organizationToDelete) return;
              OrganizationAPI.deleteOrganization(organizationToDelete).then(
                () =>
                  queryClient.invalidateQueries({
                    queryKey: ["get-organizations"],
                  }),
              );
              setOrganizationToDelete(undefined);
            }}
          >
            Are you sure you want to delete this organization?
          </ConfirmModal>

          <Drawer {...drawer.props} backdrop isOpen={isOpen} size="LARGE">
            {selectedOrganizationId && (
              <RepresentativesViewForAdmin
                organizationId={selectedOrganizationId}
              />
            )}
          </Drawer>
        </div>
      )}
    </>
  );
}
