import React from "react";

import { Trans } from "react-i18next";
import { toast } from "react-toastify";

import Button from "@components/data-entry/Button";
import Checkbox from "@components/data-entry/Checkbox";
import RadioGroupInput from "@components/data-entry/Radio";
import CalloutBox from "@components/feedback/CalloutBox";
import Drawer, { DrawerBody, useDrawer } from "@components/feedback/Drawer";
import BottomBar from "@components/layout/BottomBar";
import { useUniqueStringArray } from "@helpers/Array";
import { usePrefixedTranslation } from "@helpers/Translation";
import { InvitationSendEndpoint } from "@services/api/invitations/send-invitations";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import LogService from "@services/log/service";

type Props = {
  contactIds: string[];
  showrooms: {
    id: string;
    name: string;
  }[];
};

export default function SendInvitationsCTA({ contactIds, showrooms }: Props) {
  const { t, pt, pk } = usePrefixedTranslation("Invitation.SendInvitationsCTA");
  const { organization } = useOrganizationAppContext();
  const drawer = useDrawer({
    backdrop: true,
    initial: false,
    needsConfirmation: false,
    onClose: () => {},
  });

  const [groupedInvitations, setGroupedInvitations] =
    React.useState<boolean>(false);
  const selectedShowroomIds = useUniqueStringArray([]);

  const sendInvitationsMutation = InvitationSendEndpoint.useHook({
    organizationId: organization.id,
  });

  return (
    <>
      <Button
        disabled={contactIds.length === 0}
        theme="PRIMARY"
        onClick={drawer.openDrawer}
      >
        {pt("send-invitations")}
      </Button>
      <Drawer {...drawer.props} drawerTitle={pt("drawer-title")}>
        <DrawerBody className="grow">
          <CalloutBox type="WARNING">
            <Trans
              i18nKey={pk("callout")}
              components={{
                ul: <ul className="list-disc" />,
                li: <li className="ml-5" />,
              }}
            />
          </CalloutBox>
          <p>{pt("which-showrooms")}</p>
          <div className="grid grid-cols-2 gap-2">
            {showrooms.map((showroom) => (
              <Checkbox
                key={showroom.id}
                id={`showroom-${showroom.id}`}
                name={`showroom-${showroom.id}`}
                checked={selectedShowroomIds.state.includes(showroom.id)}
                onChange={() => selectedShowroomIds.toggle(showroom.id)}
                type="button"
                value={showroom.id}
                label={showroom.name}
              />
            ))}
          </div>

          <p>{pt("grouped-or-not")}</p>
          <div className="grid grid-cols-2">
            <RadioGroupInput
              name="grouped-invitation"
              value={`${groupedInvitations}`}
              onChange={(v) => setGroupedInvitations(v === "true")}
              options={[
                {
                  label: pt("individual-invitation"),
                  value: "false",
                },
                {
                  label: pt("grouped-invitation"),
                  value: "true",
                  disabled: selectedShowroomIds.state.length < 2,
                },
              ]}
            />
          </div>
        </DrawerBody>
        <BottomBar>
          <Button onClick={() => drawer.closeWithoutConfirmation()}>
            {t("Common.cancel")}
          </Button>
          <Button
            disabled={selectedShowroomIds.state.length === 0}
            theme="PRIMARY"
            loading={sendInvitationsMutation.isPending}
            onClick={() => {
              sendInvitationsMutation
                .mutateAsync({
                  contactIds,
                  showroomIds: selectedShowroomIds.state,
                  grouped: groupedInvitations,
                })
                .then((res) => {
                  toast.success(
                    t("Toast.invitations-sent", { nbEmails: res.length }),
                  );
                  drawer.closeWithoutConfirmation();
                })
                .catch((err) => {
                  toast.error(t("Toast.invitations-error"));
                  LogService.error(err);
                });
            }}
          >
            {pt("send")}
          </Button>
        </BottomBar>
      </Drawer>
    </>
  );
}
