import React from "react";

import Loading from "@components/feedback/Loading";
import { GetAllBrandsEndpoint } from "@services/api/brands/get-all-brands";
import { brandToOption } from "@shared/helpers/option-maker";
import OrganizationInformationForm, {
  OrganizationInformationFormData,
  useOrganizationInformationForm,
} from "@shared/organization/organization-information-form";

interface AdminOnboardingFormProps
  extends ReturnType<typeof useOrganizationInformationForm> {
  admin?: boolean;
  existing?: OrganizationInformationFormData;
  onSubmit: (
    onboardingInformation: OrganizationInformationFormData,
  ) => Promise<void>;
  className?: string;
}

export default function AdminOnboardingForm(props: AdminOnboardingFormProps) {
  const { data: brands, status: getBrandsStatus } =
    GetAllBrandsEndpoint.useHook();

  if (getBrandsStatus === "pending") {
    return <Loading type="screen" />;
  }

  if (getBrandsStatus === "error") {
    throw new Error("could not fetch brands");
  }

  const brandsOptions = brands
    .filter((b) => b.organizationId === null)
    .map(brandToOption);

  return (
    <OrganizationInformationForm
      brandsOptions={brandsOptions}
      admin
      {...props}
    />
  );
}
