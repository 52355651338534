import React from "react";

import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { NavLink } from "react-router-dom";

import Button from "@components/data-entry/Button";
import WithConditionalRendering from "@components/high-order-components/conditional-render";
import { AddedAccessControlProps } from "@shared/components/access-control";

import { useSidebarContext } from "./context/sidebar";

interface SideBarItemSingleProps extends AddedAccessControlProps {
  label: string;
  icon?: JSX.Element;
  path: string;
  className?: string;
  isSubItem?: boolean;
}

export interface SideBarItemProps extends SideBarItemSingleProps {
  subItems?: SideBarItemSingleProps[];
  onClick?: () => void;
  isExpanded?: boolean;
}

function NavigationItem(props: SideBarItemProps) {
  const {
    label,
    icon,
    path,
    subItems,
    className,
    isExpanded = false,
    isSubItem = false,
    onClick,
  } = props;
  const { isFold, isOpen, setIsOpen } = useSidebarContext();
  return (
    <NavLink
      to={path}
      aria-expanded={isExpanded}
      aria-label={`link for ${label}`}
      className="w-full"
      onClick={(e) => {
        if (subItems) {
          e.preventDefault();
          if (onClick) {
            onClick();
          }
        }
        if (isOpen && !subItems) {
          setIsOpen(false);
        }
      }}
    >
      {({ isActive }) => (
        <span
          aria-label={`label for ${label}`}
          className={`flex items-center w-full justify-between rounded-lg p-2 hover:bg-primaryLightElectricBlue hover:text-primaryElectricBlue ${
            isActive
              ? "bg-primaryLightElectricBlue text-primaryElectricBlue"
              : ""
          } ${isFold && !isSubItem ? "py-4" : ""} ${className || ""}`}
        >
          <span
            className={`flex ${
              isFold ? "flex-col items-center w-full" : "flex-row"
            } items-center gap-2`}
          >
            {icon || ""}
            {(!isFold || (isFold && isSubItem)) && (
              <span
                className={`flex w-full items-center gap ${
                  isFold && subItems ? "text-end" : "text-start"
                } ${isFold && isSubItem ? "justify-start" : "justify-center"}`}
              >
                {label}
              </span>
            )}
          </span>
          {!isFold && subItems && (
            <Button
              theme="ICON"
              aria-label={`expand ${label}`}
              testId={`expand ${label}`}
              className="flex items-center justify-center w-6 h-6 hover:text-primaryBlack"
            >
              {!isExpanded ? <HiChevronDown /> : <HiChevronUp />}
            </Button>
          )}
        </span>
      )}
    </NavLink>
  );
}

export default WithConditionalRendering(NavigationItem);
