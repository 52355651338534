export interface ChartData {
  name: string;
  value: number;
  color?: string;
}

// added new colors because other colors didn't match the ones from the design
export const bookedThroughColors = [
  "#FFF784", // Sunshine Yellow
  "#FFD099", // Peach Puff
  "#A3D6FF", // Sky Blue
];

export const formatColors = [
  "#D7A3FF", // Lavender Purple
  "#A3EEFF", // Aqua Blue
];

export const collectionsColors = [
  "FFA3E3", // pastel pink
  "A3BDFF", // pastel blue
  "D7A3FF", // pastel purple
  "9EFFDC", // pastel green
  "FFD59E", // pastel orange
  "FFECB3", // pastel yellow
  "FFC1A3", // pastel peach
  "A3FFE8", // pastel turquoise
  "C1FFA3", // pastel lime green
  "F0A3FF", // pastel magenta
  "A3E1FF", // pastel sky blue
  "FFE3A3", // pastel gold
];

export const lightChartColors = [
  "#ffadd2", // magenta-3
  "#d3adf7", // purple-3
  "#adc6ff", // geekblue-3
  "#91caff", // blue-3
  "#87e8de", // cyan-3
  "#b7eb8f", // green-3
  "#eaff8f", // lime-3
  "#fffb8f", // yellow-3
  "#ffe58f", // gold-3
  "#ffd591", // orange-3
  "#ffbb96", // volcano-3
  "#ffa39e", // red-3
];

export const darkChartColors = [
  "#eb2f96", // magenta-6
  "#722ed1", // purple-6
  "#2f54eb", // geekblue-6
  "#1677ff", // blue-6
  "#13c2c2", // cyan-6
  "#52c41a", // green-6
  "#a0d911", // lime-6
  "#fadb14", // yellow-6
  "#faad14", // gold-6
  "#fa8c16", // orange-6
  "#fa541c", // volcano-6
  "#f5222d", // red-6
];

export const chartColors = [
  "#f759ab", // magenta-5
  "#9254de", // purple-5
  "#597ef7", // geekblue-5
  "#4096ff", // blue-5
  "#36cfc9", // cyan-5
  "#73d13d", // green-5
  "#bae637", // lime-5
  "#ffec3d", // yellow-5
  "#ffc53d", // gold-5
  "#ffa940", // orange-5
  "#ff7a45", // volcano-5
  "#ff4d4f", // red-5
];

export const placeholderData: ChartData[] = [
  {
    name: "...",
    value: 20,
  },
  {
    name: "...",
    value: 10,
  },
  {
    name: "...",
    value: 5,
  },
];
