import React from "react";

import { t } from "i18next";
import {
  HiOutlineCalendar,
  HiOutlineClock,
  HiOutlineLocationMarker,
  HiOutlinePencil,
  HiOutlineTrash,
  HiOutlineUserGroup,
  HiOutlineVideoCamera,
} from "react-icons/hi";

import Button from "@components/data-entry/Button";
import { Showroom } from "@models/Showroom";
import { SpecialRequestOldModel } from "@models/SpecialRequest";

interface BookedSpecialRequestCardProps {
  specialRequest: SpecialRequestOldModel;
  showroom: Showroom;
  onEditSpecialRequest: (specialRequest: SpecialRequestOldModel) => void;
  onRemoveSpecialRequest: (specialRequest: SpecialRequestOldModel) => void;
}

export default function BookedSpecialRequestCard({
  specialRequest,
  showroom,
  onEditSpecialRequest,
  onRemoveSpecialRequest,
}: BookedSpecialRequestCardProps) {
  const { startTime, endTime } = specialRequest;
  return (
    <div
      data-testid={`specialRequest-card-${specialRequest.id}`}
      className="flex justify-between p-4 rounded-lg border-2 border-dashed border-primaryElectricBlue bg-white"
    >
      <div className="flex flex-col gap-2">
        <div className="text-primaryElectricBlue leading-6 text-base font-bold">
          {t("Booking.dashboard.special-request-title")}
        </div>
        <div className="flex mt-2 gap-2 items-center">
          <HiOutlineCalendar className="w-4 h-4" />
          <span>{startTime.formatDateAtTimezone(showroom.timezone)}</span>
        </div>
        <div className="flex gap-2 items-center">
          <HiOutlineClock className="w-4 h-4" />
          <span>
            {startTime.formatTimeAtTimezone(showroom.timezone)}
            {" - "}
            {endTime.formatTimeAtTimezone(showroom.timezone)}
          </span>
        </div>
        <div className="flex gap-2 items-center">
          <HiOutlineUserGroup className="w-4 h-4" />
          <span>
            {specialRequest.attendees
              .map((contact) => contact.firstName)
              .join(", ")}
          </span>
        </div>
        <div className="flex gap-2 items-center">
          {specialRequest.format === "IN_PERSON" && (
            <>
              <HiOutlineLocationMarker className="w-4 h-4" />
              <span>{showroom.formattedAddress}</span>
            </>
          )}
          {specialRequest.format === "VIRTUAL" &&
            specialRequest.virtualMeetingApp && (
              <>
                {/* TODO: NEED TO FETCH THE VIRTUAL URL */}
                <HiOutlineVideoCamera className="w-4 h-4" />
                <a
                  href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_YzEwZjRlZjMtYmU0Zi00YjY1LWIwZjktZjQ2ZjQxZjMxYzEz%40thread.v2/0?context=%7b%22Tid%22%3a%22b6c0b6e5-9f5e-4e8a-8b6c-1a7a2e0a8c7e%22%2c%22Oid%22%3a%22f4c4b4d4-8c8f-4b1f-9b9f-1a8c8f4b1f9b%22%7d"
                  className="underline text-primaryElectricBlue"
                  target="_blank"
                  rel="noreferrer"
                >
                  Virtual&nbsp;-&nbsp;
                  {t(`Common.virtual-tool.${specialRequest.virtualMeetingApp}`)}
                </a>
              </>
            )}
        </div>
      </div>
      <div className="flex gap-6 items-start">
        <Button
          testId={`edit-special-request-${specialRequest.id}`}
          theme="ICON"
          type="button"
          onClick={() => onEditSpecialRequest(specialRequest)}
        >
          <HiOutlinePencil className="w-4 h-4" />
        </Button>
        <Button
          testId={`delete-special-request-${specialRequest.id}`}
          theme="ICON"
          type="button"
          onClick={() => onRemoveSpecialRequest(specialRequest)}
        >
          <HiOutlineTrash className="w-4 h-4 text-statusRedDark" />
        </Button>
      </div>
    </div>
  );
}
