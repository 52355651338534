import { useMutation } from "@tanstack/react-query";

import { Account } from "@models/old/Account";
import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

export const putArchiveAccountEndpoint = {
  path: (organizationId: Organization["id"], accountId: Account["id"]) =>
    `/organizations/${organizationId}/accounts/${accountId}/archive`,
};

export function usePutArchiveAccount(organizationId: string) {
  return useMutation({
    mutationFn: (accountId: string) =>
      axiosInstance.put(
        putArchiveAccountEndpoint.path(organizationId, accountId),
      ),
  });
}
