import React, { useState } from "react";

import { HiCheck, HiPencil, HiTrash } from "react-icons/hi2";
import { toast } from "react-toastify";

import modaresaLogoShort from "@app/assets/logos/moda-resa-logo.svg";
import Tag from "@components/data-display/Tag";
import Tooltip from "@components/data-display/Tooltip";
import Button from "@components/data-entry/Button";
import EditableText from "@components/data-entry/EditableText";
import TextField from "@components/data-entry/TextField";
import { usePrefixedTranslation } from "@helpers/Translation";
import { Collection } from "@models/Collection";
import { BrandCancelPartnershipEndpoint } from "@services/api/brands/cancel-partnership";
import { BrandUpdateEndpoint } from "@services/api/brands/update-brand";
import { CollectionArchiveEndpoint } from "@services/api/collections/archive";
import { CollectionCreateEndpoint } from "@services/api/collections/create";

type Props = {
  organizationId: string;
  brand: {
    id: string;
    name: string;
    organizationId: string | null;
  };
  collections: Collection[];
};

export default function AgencyCollectionGroup({
  organizationId,
  brand,
  collections,
}: Props) {
  const { pt, t } = usePrefixedTranslation("Profile.AgencyCollectionGroup");
  const [editMode, setEditMode] = useState(false);
  const [brandName, setBrandName] = useState(brand.name);
  // remote actions
  // remove brand partnership (archive all collections for that brand)
  const { mutateAsync: cancelBrandPartnership } =
    BrandCancelPartnershipEndpoint.useHook({
      organizationId,
    });
  const { mutateAsync: updateBrand } = BrandUpdateEndpoint.useHook();

  const archiveCollectionMutation = CollectionArchiveEndpoint.useHook({
    organizationId,
  });

  const { mutateAsync: createCollection } = CollectionCreateEndpoint.useHook({
    organizationId,
  });

  const handleBrandCancelPartnership = () =>
    cancelBrandPartnership({ brandId: brand.id })
      .then(() => {
        toast.success(t("Toast.brand-removed-success"));
      })
      .catch(() => {
        toast.error(t("Toast.brand-removed-failure"));
      });

  const handleBrandUpdate = () =>
    updateBrand({ brandId: brand.id, data: { name: brandName } })
      .then(() => {
        toast.success(t("Toast.brand-update-success"));
        setEditMode(false);
      })
      .catch(() => {
        toast.error(t("Toast.brand-update-error"));
      });

  return (
    <div className="flex flex-col gap-4 p-4 rounded border border-primaryLightElectricBlue">
      <div className="flex justify-between">
        <h3 className="flex gap-2 items-center m-0">
          {editMode ? (
            <>
              <TextField value={brandName} onChange={setBrandName} />
              <Button
                theme="PRIMARY"
                rounded="medium"
                onClick={handleBrandUpdate}
              >
                <HiCheck className="w-6 h-6" />
              </Button>
            </>
          ) : (
            brand.name
          )}
        </h3>
        <div className="flex gap-2 items-center">
          {brand.organizationId && (
            <Tooltip
              content={pt("cannot-create-collections-for-modaresa-brands")}
            >
              <img
                className="h-4 cursor-help"
                alt="modaresa logo"
                src={modaresaLogoShort}
              />
            </Tooltip>
          )}
          <Button
            renderIf={!brand.organizationId}
            label="edit brand"
            onClick={() => setEditMode(true)}
            theme="ICON"
          >
            <HiPencil className="w-4 h-4" />
          </Button>
          <Button
            title={pt("delete-partnership")}
            label="remove brand partnership"
            onClick={handleBrandCancelPartnership}
            theme="ICON"
            className="shrink-0"
          >
            <HiTrash className="fill-primaryRed w-6 h-6" />
          </Button>
        </div>
      </div>
      <div className="flex gap-2 flex-wrap">
        {collections.map((c) => (
          <Tag
            closable={!brand.organizationId}
            key={c.id}
            onClose={() =>
              archiveCollectionMutation
                .mutateAsync({ collectionId: c.id })
                .then(() => {
                  toast.success(t("Toast.collection-deletion-success"));
                })
                .catch(() => {
                  toast.error(t("Toast.collection-deletion-failure"));
                })
            }
          >
            {c.name}
          </Tag>
        ))}
        <EditableText
          className="border rounded-full border-primaryElectricBlue px-2 text-primaryElectricBlue hover:bg-primaryLightElectricBlue"
          key={collections.length}
          renderIf={!brand.organizationId}
          label={`create collection for brand ${brand.name}`}
          value=""
          placeholder={t("Shared.AgencyCollectionGroup.collection-placeholder")}
          onSave={(collectionName) =>
            collectionName !== "" &&
            createCollection({
              data: { name: collectionName, brandId: brand.id },
            })
          }
          display={t("Shared.AgencyCollectionGroup.add-collection")}
        />
      </div>
    </div>
  );
}
