import { Appointment } from "@models/old/Appointment";
import { Contact } from "@models/old/Contact";
import axiosInstance from "@services/api/config";

interface ContactUpdateAPIData {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
}

const BookingAPIPaths = {
  contacts: (invitationId: string, contactId: Contact["id"]) =>
    `/bookings/${invitationId}/contacts/${contactId}`,
  invitedColleagues: (invitationId: string) =>
    `/bookings/${invitationId}/invited-colleagues/`,
  deleteAppointment: (
    invitationId: string,
    appointmentId: Appointment["id"],
    email: boolean,
  ) =>
    `/bookings/${invitationId}/appointments/${appointmentId}${
      email ? "?email=true" : ""
    }`,
  updateAppointmentAttendees: (
    invitationId: string,
    appointmentId: Appointment["id"],
    email: boolean,
  ) =>
    `/bookings/${invitationId}/appointments/${appointmentId}/contacts${
      email ? "?email=true" : ""
    }`,
};

const BookingAPI = {
  updateContact: (
    invitationId: string,
    contactId: Contact["id"],
    contactData: ContactUpdateAPIData,
  ) =>
    axiosInstance.patch(BookingAPIPaths.contacts(invitationId, contactId), {
      id: contactId,
      ...contactData,
    }),

  deleteAppointment: (
    invitationId: string,
    appointmentId: Appointment["id"],
    sendEmail: boolean,
  ) =>
    axiosInstance.delete(
      BookingAPIPaths.deleteAppointment(invitationId, appointmentId, sendEmail),
    ),

  updateAppointmentAttendees: (
    invitationId: string,
    appointmentId: Appointment["id"],
    contactIds: Contact["id"][],
    email: boolean,
  ) =>
    axiosInstance.patch(
      BookingAPIPaths.updateAppointmentAttendees(
        invitationId,
        appointmentId,
        email,
      ),
      {
        contactIds,
      },
    ),

  getInvitedColleagues: (invitationId: string) =>
    axiosInstance
      .get<Contact[]>(BookingAPIPaths.invitedColleagues(invitationId))
      .then((res) => res.data),
};

export const BookingMutations = {
  updateContact: () => ({
    mutationFn: ({
      invitationId,
      contactId,
      formData,
    }: {
      invitationId: string;
      contactId: Contact["id"];
      formData: ContactUpdateAPIData;
    }) => BookingAPI.updateContact(invitationId, contactId, formData),
  }),
  updateAppointmentAttendees: () => ({
    mutationFn: ({
      invitationId,
      appointmentId,
      contactIds,
    }: {
      invitationId: string;
      appointmentId: Appointment["id"];
      contactIds: Contact["id"][];
    }) =>
      BookingAPI.updateAppointmentAttendees(
        invitationId,
        appointmentId,
        contactIds,
        true,
      ),
  }),
  deleteAppointment: () => ({
    mutationFn: ({
      invitationId,
      appointmentId,
    }: {
      invitationId: string;
      appointmentId: Appointment["id"];
    }) => BookingAPI.deleteAppointment(invitationId, appointmentId, true),
  }),
};
