import React, { useState } from "react";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { HiChevronLeft, HiChevronRight, HiRefresh } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import AdminOnboardingBreadcrumb from "@backoffice/components/onboarding/components/AdminOnboardingBreadcrumb";
import UploadError from "@backoffice/components/onboarding/components/UploadError";
import MRTable from "@components/data-display/MRTable";
import Button from "@components/data-entry/Button";
import FilesUploadInput, {
  FileToUpload,
} from "@components/data-entry/FilesUploadInput";
import CalloutBox from "@components/feedback/CalloutBox";
import ConfirmModal from "@components/feedback/ConfirmModal";
import Loading from "@components/feedback/Loading";
import BottomBar from "@components/layout/BottomBar";
import { AccountWithCollections } from "@models/old/Account";
import { AdminOnboardingStep } from "@models/old/Organization";
import { UploadValidationError } from "@models/types/UploadValidationError";
import OrganizationAPI from "@services/api/old/organization/OrganizationAPI";

const getOrganizationQuery = (organizationId: string) => ({
  queryKey: ["get-organization", organizationId],
  queryFn: () => OrganizationAPI.getOrganization(organizationId),
});

const getOrganizationAccountsWithCollection = (organizationId: string) => ({
  queryKey: ["get-organization-accounts-with-collections", organizationId],
  queryFn: () => OrganizationAPI.getAccountsWithCollections(organizationId),
});

export default function BackOfficeOnboardingAccountUploadPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { organizationId } = useParams();
  if (!organizationId) {
    throw new Error(
      "AccoBackOfficeOnboardingAccountUploadPage - no organizationId",
    );
  }
  const organizationAccountsQuery =
    getOrganizationAccountsWithCollection(organizationId);
  const {
    data: organization,
    isPending: isLoadingOrganization,
    isError: isErrorOrganization,
    error: errorOrganization,
  } = useQuery(getOrganizationQuery(organizationId));
  const {
    data: accounts,
    isFetching: isFetchingAccounts,
    isError: isErrorAccounts,
    error: errorAccounts,
  } = useQuery({
    ...organizationAccountsQuery,
    initialData: [],
  });

  const [error, setError] = useState<UploadValidationError[] | string | null>(
    null,
  );
  const [isRedo, setIsRedo] = useState<boolean>(false);

  const columnHelper = createColumnHelper<AccountWithCollections>();
  const columns = React.useMemo(() => {
    let collectionColumns: ColumnDef<AccountWithCollections>[] = [];
    if (
      accounts.length > 0 &&
      accounts[0].collections &&
      accounts[0].collections.length > 0
    ) {
      collectionColumns = accounts[0].collections?.map((collection) =>
        columnHelper.display({
          id: collection.name,
          header: `${collection.name} status`,
          cell: ({ row }: CellContext<AccountWithCollections, unknown>) => {
            const collectionForAccount = row.original.collections?.find(
              (s) => collection.name === s.name,
            );
            if (collectionForAccount) {
              return collectionForAccount.status;
            }
            return "";
          },
        }),
      );
    }

    const columnModel = [
      columnHelper.accessor("name", {
        header: () => "Name",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("type", {
        header: () => "Type",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("status", {
        header: () => "Account Status",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("city", {
        header: () => "City",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("countryCode", {
        header: () => "Country",
        cell: (info) => info.getValue(),
      }),
      ...collectionColumns,
    ];

    return columnModel;
  }, [columnHelper, accounts]);

  const [fileList, setFileList] = React.useState<FileToUpload[]>([]);
  const handleFileChange = (files: FileToUpload[] | null) => {
    if (files === null || files.length === 0) {
      setFileList([]);
      setError(null);
    } else {
      OrganizationAPI.uploadAccounts(organizationId, files[0].file)
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: organizationAccountsQuery.queryKey,
          });
          setError(null);
          setFileList(files.map((file) => ({ ...file, status: "ok" })));
          toast.success("Organization accounts uploaded");
        })
        .catch((err) => {
          setFileList(files.map((file) => ({ ...file, status: "error" })));
          if (err.errors) {
            setError(err.errors);
          } else {
            setError(err.message);
          }
        });
    }
  };

  if (isLoadingOrganization) {
    return <Loading type="screen" />;
  }

  if (isErrorOrganization) {
    throw errorOrganization;
  }
  if (isErrorAccounts) {
    throw errorAccounts;
  }

  return (
    <div className="px-10">
      <AdminOnboardingBreadcrumb
        currentStep={AdminOnboardingStep.ACCOUNTS_UPLOAD}
      />
      <h1 className="heading-1">{organization.name}</h1>
      <CalloutBox className="w-2/4 my-4">
        Make sure that all the information in the file is written correctly and
        that there are no duplicates before importing it. Please import the
        three tabs of the file separately.
      </CalloutBox>
      <h3 className="font-bold">Organization Accounts</h3>
      {accounts.length === 0 && (
        <FilesUploadInput
          onFileChange={handleFileChange}
          parentFiles={fileList}
          authorizedFileExtension={[".csv"]}
          disabled={accounts.length !== 0}
          className="w-5/12 max-w-xl"
        />
      )}

      <div className="flex flex-col p-8 my-4 border rounded-lg bg-primaryLightElectricBlue">
        {accounts.length === 0 && (
          <>
            <div>No accounts imported so far</div>
            {error && <UploadError type="Accounts" error={error} />}
          </>
        )}
        {accounts.length > 0 && (
          <>
            <div className="pb-2 text-primaryElectricBlue">
              {accounts.length} imported Accounts
            </div>
            <MRTable
              data={accounts}
              columns={columns}
              paginationPlacement="none"
            />
          </>
        )}
      </div>

      <BottomBar>
        <div>
          {accounts.length > 0 && (
            <Button
              onClick={() => {
                setIsRedo(true);
              }}
              theme="DANGER"
            >
              <HiRefresh className="w-6 h-6" />
              <span>Redo accounts</span>
            </Button>
          )}
        </div>
        <div className="flex justify-end gap-4">
          <Button
            onClick={() => {
              navigate(
                `/backoffice/onboard-organization/${organization.id}/information`,
              );
            }}
            theme="TERTIARY"
          >
            <HiChevronLeft className="w-6 h-6" />
            <span>Organization informations</span>
          </Button>
          <Button
            disabled={isFetchingAccounts || accounts.length === 0}
            onClick={() => {
              navigate(
                `/backoffice/onboard-organization/${organization.id}/contacts-upload`,
              );
            }}
            theme="PRIMARY"
          >
            <span>Next : import contacts</span>
            <HiChevronRight className="w-6 h-6" />
          </Button>
        </div>
      </BottomBar>

      <ConfirmModal
        show={isRedo}
        title="Delete all accounts ?"
        confirmLabel="Yes, redo"
        onCancel={() => {
          setIsRedo(false);
        }}
        onConfirm={() => {
          OrganizationAPI.redoUploadAccounts(organization.id).then(() => {
            queryClient.invalidateQueries({
              queryKey: organizationAccountsQuery.queryKey,
            });
            setFileList([]);
            setIsRedo(false);
          });
        }}
      >
        <span>
          You are about to delete all imported accounts.
          <br />
          Are you sure of your choice?
        </span>
      </ConfirmModal>
    </div>
  );
}
