import React, { useState } from "react";

import { Portal, Transition } from "@headlessui/react";

import Button from "@components/data-entry/Button";
import { fadeSlideInOutProps } from "@components/transitions/FadeInOut";
import usePopup, { Placement } from "@helpers/Popup";
import { UserRole } from "@models/types/enums";
import { addAccessControlProps } from "@shared/components/access-control";

interface ButtonMenuItemProps {
  setIsOpen: (isOpen: boolean) => void;
  item: {
    label: string;
    onClick: () => void;
  };
}

function ButtonMenuItemRender({ item, setIsOpen }: ButtonMenuItemProps) {
  return (
    <li key={item.label}>
      <button
        className="w-full px-4 text-left py-3 hover:bg-gray-100"
        aria-label={item.label}
        type="button"
        onClick={() => {
          item.onClick();
          setIsOpen(false);
        }}
      >
        {item.label}
      </button>
    </li>
  );
}

const ButtonMenuItem = addAccessControlProps(ButtonMenuItemRender);

interface ButtonMenuProps {
  label: string;
  theme?:
    | "PRIMARY"
    | "SECONDARY"
    | "TERTIARY"
    | "DANGER"
    | "SUCCESS"
    | "ICON"
    | "NONE";
  icon?: React.ReactNode;
  position?: Placement;
  items: Array<{
    label: string;
    onClick: () => void;
    aclRoles?: UserRole[];
  }>;
}

function ButtonMenu({
  label,
  icon,
  theme = "PRIMARY",
  items,
  position = "bottom",
}: ButtonMenuProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const popup = usePopup({
    placement: position,
    hasOffset: [5, 0],
  });

  return (
    <div
      className="relative flex flex-col"
      ref={popup.setReferenceElement}
      onBlur={() => {
        setIsOpen(false);
      }}
    >
      <Button
        label={label}
        type="button"
        theme={theme}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span>{label}</span>
        {icon && <span>{icon}</span>}
      </Button>
      <Portal as="div" className="fixed z-popover">
        <div
          ref={popup.setPopupElement}
          style={popup.styles.popper}
          {...popup.attributes.popper}
        >
          <Transition
            as="div"
            show={isOpen}
            aria-hidden={!isOpen}
            {...fadeSlideInOutProps}
          >
            <ul className="min-w-max bg-white border rounded-lg">
              {items.map((item) => (
                <ButtonMenuItem
                  key={item.label}
                  item={item}
                  setIsOpen={setIsOpen}
                  aclRoles={item.aclRoles}
                />
              ))}
            </ul>
          </Transition>
        </div>
      </Portal>
    </div>
  );
}

export default ButtonMenu;
