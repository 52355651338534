import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Organization } from "@models/Organization";
import { Portfolio } from "@models/Portfolio";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";
import { getPortfoliosEndpoint } from "@services/api/portfolios/get-portfolios";
import LogService from "@services/log/service";

export interface DeletePortfolioHookParams {
  organizationId: Organization["id"];
}

export interface DeletePortfolioMutationParams {
  portfolioId: Portfolio["id"];
}

type DeletePortfolioParams = DeletePortfolioHookParams &
  DeletePortfolioMutationParams;

export const deletePortfolioEndpoint = {
  path: ({ organizationId, portfolioId }: DeletePortfolioParams) =>
    `/organizations/${organizationId}/portfolios/${portfolioId}`,
  call: (pathParams: DeletePortfolioParams) =>
    axiosInstance
      .delete(deletePortfolioEndpoint.path(pathParams))
      .then((response) => response.data),
};

export function useDeletePortfolio({
  organizationId,
}: DeletePortfolioHookParams) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation({
    mutationFn: ({ portfolioId }: DeletePortfolioMutationParams) =>
      deletePortfolioEndpoint.call({ portfolioId, organizationId }),
    onSuccess: () => {
      toast.success(t("Toast.portfolio-deleted"));
      queryClient.invalidateQueries({
        queryKey: getAPIQueryKey(
          getPortfoliosEndpoint.path({ organizationId }),
        ),
      });
    },
    onError: (err) => {
      toast.error(t("Toast.portfolio-deleted-fail"));
      toast.error(`${err}`);
      LogService.error(`${err}`);
    },
  });
}
