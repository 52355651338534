import { useMutation } from "@tanstack/react-query";

import { SpecialRequestOldModel } from "@models/SpecialRequest";
import axiosInstance from "@services/api/config";

export const deleteSpecialRequestEndpoint = {
  path: (
    invitationId: string,
    specialRequestId: SpecialRequestOldModel["id"],
  ) => `/bookings/${invitationId}/special-requests/${specialRequestId}`,
};

export function useDeleteSpecialRequest(invitationId: string) {
  return useMutation({
    mutationFn: (specialRequestId: SpecialRequestOldModel["id"]) =>
      axiosInstance.delete(
        deleteSpecialRequestEndpoint.path(invitationId, specialRequestId),
      ),
  });
}
