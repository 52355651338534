import { useMutation } from "@tanstack/react-query";

import { SpecialRequestOldModel } from "@models/SpecialRequest";
import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

export const postDeclineSpecialRequestEndpoint = {
  path: (
    organizationId: Organization["id"],
    specialRequestId: SpecialRequestOldModel["id"],
  ) =>
    `/organizations/${organizationId}/special-requests/${specialRequestId}/decline`,
};

export interface SpecialRequestDeclineInput {
  specialRequestId: SpecialRequestOldModel["id"];
  body: {
    reason: string | null;
  };
}

interface Output {
  showroomId: string;
}

export function usePostDeclineSpecialRequest(organizationId: string) {
  return useMutation({
    mutationFn: (params: SpecialRequestDeclineInput) =>
      axiosInstance
        .post<Output>(
          postDeclineSpecialRequestEndpoint.path(
            organizationId,
            params.specialRequestId,
          ),
          params.body,
        )
        .then((r) => r.data),
  });
}
