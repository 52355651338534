import React from "react";

import { useNavigate } from "react-router-dom";

import Button from "@components/data-entry/Button";

export default function ShowroomViewPage() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-16 w-16 mx-auto text-gray-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 2a8 8 0 100 16 8 8 0 000-16zM5.293 8.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 6.414V14a1 1 0 11-2 0V6.414L5.293 8.707z"
            clipRule="evenodd"
          />
        </svg>
        <h1 className="text-3xl font-bold text-gray-800 mt-4">Coming Soon</h1>
        <p className="text-gray-600 mt-2">
          We are working hard to bring you an amazing showroom experience.
        </p>
        <p className="text-gray-600">Stay tuned for updates!</p>
        <div className="justify-center">
          <Button onClick={() => navigate(-1)}>Go back</Button>
        </div>
      </div>
    </div>
  );
}
