import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { RealDate, ZonedDate } from "@helpers/Date";
import { Merge } from "@helpers/Object";
import { AccountDetails } from "@models/old/Account";
import { Collection } from "@models/old/Collection";
import { Contact } from "@models/old/Contact";
import { RepresentativeRole } from "@models/old/Representative";
import { Seller } from "@models/old/Seller";
import {
  AppointmentFormat,
  AppointmentTypeEnum,
  VirtualMeetingApps,
} from "@models/types/enums";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";
import fetchBookingAppointmentsEndpoint from "@services/api/old/booking/fetch-appointments";

export interface UpdateBookingAppointmentInput {
  bookingId?: string;
  id: string;
  showroom: {
    id: string;
  };
  seller: {
    id: string;
  };
  collection: {
    id: string;
  } | null;
  account: {
    id: string;
  };
  // contacts should have at least one item
  attendees: [
    {
      id: string;
    },
    ...{
      id: string;
    }[],
  ];
  startTime: ZonedDate;
  endTime: ZonedDate;
  format: AppointmentFormat;
  virtualMeetingApp: VirtualMeetingApps | null;
  type: AppointmentTypeEnum;
}

export type UpdateBookingAppointmentInputSerialized = Merge<
  UpdateBookingAppointmentInput,
  { startTime: string; endTime: string }
>;

function serialize(
  obj: UpdateBookingAppointmentInput,
): UpdateBookingAppointmentInputSerialized {
  return {
    ...obj,
    startTime: obj.startTime.realDate.toISOString(),
    endTime: obj.endTime.realDate.toISOString(),
  };
}
function unserialize(
  obj: UpdateBookingAppointmentResponseRaw,
): UpdateBookingAppointmentResponse {
  return {
    ...obj,
    startTime: new ZonedDate(new Date(obj.startTime) as RealDate),
    endTime: new ZonedDate(new Date(obj.endTime) as RealDate),
  };
}

interface UpdateBookingAppointmentResponseRaw {
  id: string;
  title: string;
  accountOtb: number | null;
  showroom: {
    id: string;
    formattedAddress: string;
    timezone: string;
  };
  startTime: string;
  endTime: string;
  format: AppointmentFormat;
  virtualMeetingApp: VirtualMeetingApps | null;
  type: AppointmentTypeEnum;
  account: AccountDetails;
  portfolios: {
    name: string;
    color: string;
    manager: {
      firstName: string;
      lastName: string;
      role: RepresentativeRole;
    };
  }[];
  seller: Seller;
  collection: Collection | null;
  attendees: NonEmptyArray<Contact>;
  warnings: (
    | "NO_VIRTUAL_TOOL_WARNING"
    | "VIRTUAL_MEETING_APP_WARNING"
    | "APPOINTMENT_TYPE_WARNING"
  )[];
}

type UpdateBookingAppointmentResponse = Merge<
  UpdateBookingAppointmentResponseRaw,
  {
    startTime: ZonedDate;
    endTime: ZonedDate;
  }
>;

export const updateBookingAppointmentQuery = {
  path: (invitationId: string, appointmentId: string) =>
    `/bookings/${invitationId}/appointments/${appointmentId}`,
};

export function useUpdateBookingAppointment(invitationId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      appointmentId,
      appointment,
    }: {
      appointmentId: string;
      appointment: UpdateBookingAppointmentInput;
    }) =>
      axiosInstance
        .put<
          any,
          AxiosResponse<UpdateBookingAppointmentResponseRaw>,
          UpdateBookingAppointmentInputSerialized
        >(
          updateBookingAppointmentQuery.path(invitationId, appointmentId),
          serialize(appointment),
        )
        .then((response) => unserialize(response.data))
        .finally(() => {
          queryClient.invalidateQueries({
            queryKey: getAPIQueryKey(
              fetchBookingAppointmentsEndpoint.path(invitationId),
            ),
          });
        }),
  });
}
