import React, { useCallback } from "react";

import { useMutation } from "@tanstack/react-query";
import { flag } from "country-emoji";
import {
  areIntervalsOverlapping,
  eachDayOfInterval,
  format,
  max,
  min,
} from "date-fns";
import { useTranslation } from "react-i18next";
import {
  HiChevronLeft,
  HiOutlineClock,
  HiOutlineLocationMarker,
  HiOutlineMail,
  HiOutlinePhone,
  HiOutlineUsers,
  HiOutlineVideoCamera,
} from "react-icons/hi";
import { toast } from "react-toastify";

import showroomsData, {
  ShowroomData,
} from "@app/modules/cruising/showroomData";
import Tabs, { useTabs } from "@components/data-display/Tabs";
import Button from "@components/data-entry/Button";
import Checkbox from "@components/data-entry/Checkbox";
import CustomDatePicker from "@components/data-entry/CustomDatepicker";
import BottomBar from "@components/layout/BottomBar";
import { useIsLargestScreen } from "@components/layout/Breakpoint";
import { formatDate } from "@helpers/Date";
import ConditionalRender from "@shared/components/on";

const optimizationApiUrl = import.meta.env.VITE_OPTIMIZATION_API_URL;

interface ShowroomOption {
  label: string;
  value: number | string;
  data: ShowroomData;
}

type ApiShowroom = {
  showroom: string;
  duration: number;
  address: string;
  coordinates: [number, number];
};
type ApiInput = Array<{
  appointment: ApiShowroom;
  "possible-dates": Array<{
    date: string;
    timewindows: Array<[string, string]>;
  }>;
}>;

type ApiOutput<Meta = {}> = Array<{
  date: string;
  tour: {
    appointement: ApiShowroom & Meta;
    time: string;
  }[];
}>;

type TripPlanification = ApiOutput<{ meta: ShowroomData }>;

function fromApiShowroomToShowroomWithMeta(
  showroom: ApiShowroom,
  showroomsDataList: ShowroomData[],
): TripPlanification[number]["tour"][number]["appointement"] {
  const correspondingShowroom = showroomsDataList.find(
    (sd) => showroom.showroom === sd.name && showroom.address === sd.address,
  );

  if (!correspondingShowroom) {
    // eslint-disable-next-line no-console
    console.error(showroom, showroomsDataList);
    throw new Error(
      "Did not find corresponding showroom, something unexpected happened",
    );
  }

  return {
    ...showroom,
    meta: correspondingShowroom,
  };
}

function showroomDataToOption(showroomData: ShowroomData): ShowroomOption {
  return {
    label: showroomData.name,
    value: showroomData.id,
    data: showroomData,
  };
}

function showroomOptionToApiInputRow(
  showroomOption: ShowroomOption,
  fromDate: Date,
  toDate: Date,
): ApiInput[number] {
  return {
    "possible-dates": eachDayOfInterval({
      start: fromDate,
      end: toDate,
    }).map((date) => ({
      date: format(date, "yyyy-MM-dd"),
      timewindows: areIntervalsOverlapping(
        { start: fromDate, end: toDate },
        {
          start: showroomOption.data.from,
          end: showroomOption.data.to,
        },
      )
        ? [[showroomOption.data.openingHour, showroomOption.data.closingHour]]
        : [],
    })),
    appointment: {
      showroom: showroomOption.data.name,
      duration: showroomOption.data.duration,
      address: showroomOption.data.address,
      coordinates: showroomOption.data.coordinates,
    },
  };
}

function formatApiTime(time: string) {
  return time.replace("-", ":");
}

function addDuration(timeStr: string, duration: number) {
  const [startHr, startMin] = formatApiTime(timeStr).split(":").map(Number);

  const endMinsTmp = startMin + duration;

  const hrToAdd = Math.floor(endMinsTmp / 60);
  const endHr = startHr + hrToAdd;
  const endMin = endMinsTmp % 60;

  return [`${endHr}`.padStart(2, "0"), `${endMin}`.padStart(2, "0")].join(":");
}

export default function CruisingPlanMyTripPage() {
  const { t } = useTranslation();
  const { mutateAsync } = useMutation({
    mutationFn: (variables: ApiInput) =>
      fetch(`${optimizationApiUrl}/optimize`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify(variables),
      }).then(
        (res) =>
          res.json() as Promise<{
            result: ApiOutput;
            status: "success";
          }>,
      ),
  });

  const isLarge = useIsLargestScreen();

  // init
  const showroomsOptions: ShowroomOption[] =
    showroomsData.map(showroomDataToOption);

  // showroom selection state
  const [fromDate, setFromDate] = React.useState<Date | undefined>(
    min(showroomsData.map((s) => s.from)),
  );
  const [toDate, setToDate] = React.useState<Date | undefined>(
    max(showroomsData.map((s) => s.to)),
  );
  const [selectedShowrooms, setSelectedShowrooms] = React.useState<
    ShowroomOption[]
  >([]);
  const isShowroomSelected = useCallback(
    (showroomOption: ShowroomOption) =>
      selectedShowrooms.map((b) => b.value).includes(showroomOption.value),
    [selectedShowrooms],
  );

  const toggleShowroom = useCallback(
    (showroomOption: ShowroomOption) => {
      const newValue = isShowroomSelected(showroomOption)
        ? selectedShowrooms.filter((s) => s.value !== showroomOption.value)
        : [...selectedShowrooms, showroomOption];
      setSelectedShowrooms(newValue);
    },
    [isShowroomSelected, selectedShowrooms],
  );

  // trip planification state
  const { tab, setTab } = useTabs({ initial: 0 });
  const [tripPlanification, setTripPlanification] =
    React.useState<TripPlanification>([]);

  const tripTabItems = tripPlanification.map((dayTrip, index) => ({
    label: formatDate(new Date(dayTrip.date)),
    tab: index,
  }));

  const handlePlanMyTrip = useCallback(() => {
    if (!fromDate || !toDate) {
      return false; // dates are required
    }
    const input: ApiInput = selectedShowrooms.map((showroomData) =>
      showroomOptionToApiInputRow(showroomData, fromDate, toDate),
    );
    mutateAsync(input)
      .then((res) => {
        const tripPlan = res.result.filter(
          (dayTrip) => dayTrip.tour.length > 0,
        );

        // find corresponding showroomData for each ApiShowroom
        const tripPlanWithMeta: TripPlanification = tripPlan.map((datePlan) => {
          const tourWithMeta: TripPlanification[number]["tour"] =
            datePlan.tour.map((datePlace) => {
              const showroomWithMeta: TripPlanification[number]["tour"][number]["appointement"] =
                fromApiShowroomToShowroomWithMeta(
                  datePlace.appointement,
                  showroomsData,
                );

              return {
                appointement: showroomWithMeta,
                time: datePlace.time,
              };
            });
          return {
            date: datePlan.date,
            tour: tourWithMeta,
          };
        });

        setTripPlanification(tripPlanWithMeta);
        setTab(0);
      })
      .catch((err) => {
        toast.error(err);
        // eslint-disable-next-line no-console
        console.error(err);
        setTripPlanification([]);
      });

    return true;
  }, [fromDate, mutateAsync, selectedShowrooms, setTab, toDate]);

  const cancelTripPlanification = () => {
    setTripPlanification([]);
    setTab(0);
  };

  const cannotSubmit = selectedShowrooms.length === 0 || !fromDate || !toDate;

  const isAvailable = (showroomOption: ShowroomOption) =>
    fromDate && toDate
      ? areIntervalsOverlapping(
          { start: showroomOption.data.from, end: showroomOption.data.to },
          {
            start: fromDate,
            end: toDate,
          },
        )
      : true;

  return (
    <div className="flex w-full h-full">
      <aside
        className={`transition-all duration-200 relative flex flex-col w-full lg:w-1/3 ${
          tripPlanification.length > 0 && "hidden lg:flex"
        } h-full shadow-sidebar pb-[5rem]`}
      >
        <h3 className="p-4 mb-4 text-2xl font-bold ">
          {t("Cruising.plan-my-trip.which-organizations")}
        </h3>
        <div className="pl-4 pr-8 space-y-4">
          <p>{t("Cruising.plan-my-trip.specify-your-availability")}</p>
          <div className="flex items-center gap-2">
            <label htmlFor="fromDate">
              {t("Cruising.plan-my-trip.from")} {"  "}
              <CustomDatePicker
                id="fromDate"
                intlOptions={{
                  month: "numeric",
                  day: "numeric",
                  year: "numeric",
                }}
                multiple={false}
                minDate={min(showroomsData.map((s) => s.from))}
                maxDate={toDate}
                onChange={(date) => {
                  if (!date) {
                    setSelectedShowrooms([]);
                    return setFromDate(undefined);
                  }
                  if (date instanceof Date) {
                    setSelectedShowrooms([]);
                    return setFromDate(date);
                  }
                  return false;
                }}
                value={fromDate}
                placeholder={t("Cruising.plan-my-trip.from-date")}
              />
            </label>
            <label htmlFor="toDate">
              {t("Cruising.plan-my-trip.to")} {"  "}
              <CustomDatePicker
                id="toDate"
                multiple={false}
                minDate={fromDate}
                maxDate={max(showroomsData.map((s) => s.to))}
                onChange={(date) => {
                  if (!date) {
                    setSelectedShowrooms([]);
                    return setToDate(undefined);
                  }
                  if (date instanceof Date) {
                    setSelectedShowrooms([]);
                    return setToDate(date);
                  }
                  return false;
                }}
                value={toDate}
                placeholder={t("Cruising.plan-my-trip.to-date")}
              />
            </label>
          </div>
        </div>

        <div className="pl-4 pr-8 space-y-4 overflow-y-scroll grow">
          {showroomsOptions.filter(isAvailable).map((showroomOption) => (
            <Checkbox
              type="button"
              key={showroomOption.value}
              checked={isShowroomSelected(showroomOption)}
              name={`showroom-checkbox-${showroomOption.label}`}
              id={`showroom-checkbox-${showroomOption.label}`}
              onChange={() => toggleShowroom(showroomOption)}
              label={showroomOption.label}
            />
          ))}
        </div>

        <BottomBar sticky={false} className="items-center px-4">
          {tripPlanification.length === 0 ? (
            <Button
              theme="PRIMARY"
              onClick={handlePlanMyTrip}
              disabled={cannotSubmit}
              className="justify-center w-full"
            >
              {t("Cruising.plan-my-trip.show-me-the-best-way")}
            </Button>
          ) : (
            <Button
              theme={isLarge ? "PRIMARY" : "TERTIARY"}
              onClick={isLarge ? handlePlanMyTrip : cancelTripPlanification}
              disabled={cannotSubmit}
              className="justify-center w-full"
            >
              <HiChevronLeft />
              {isLarge
                ? t("Cruising.plan-my-trip.refresh-itinerary")
                : t("Cruising.plan-my-trip.back-to-selection")}
            </Button>
          )}
        </BottomBar>
      </aside>

      <main
        className={`items-center h-full p-4 ${
          tripPlanification.length === 0
            ? "hidden sm:flex sm:flex-col sm:justify-center"
            : "flex flex-col"
        } grow`}
      >
        <ConditionalRender
          renderIf={tripPlanification.length > 0}
          fallback={
            <span className="place-self-center">
              {t("Cruising.plan-my-trip.placeholder")}
            </span>
          }
        >
          <Tabs
            className="place-self-start"
            tab={tab}
            items={tripTabItems}
            handleClick={(item) => setTab(item.tab)}
          />
          <div className="w-full py-4 overflow-scroll border-t cursor-pointer border-primaryLightGray max-lg:mb-16">
            {tripPlanification[Number(tab)]
              ? tripPlanification[Number(tab)].tour.map((place, index, arr) => (
                  <div
                    className="grid grid-cols-10 group"
                    key={`${place.time}-${place.appointement.showroom}`}
                  >
                    <div className="mr-4 lg:col-start-1 col-span-2 relative border-r border-primaryLightGrey flex items-start text-md gap-2 justify-end pr-4 pt-5 after:content-[''] after:absolute after:-right-2 after:top-6 after:w-4 after:h-4 after:bg-white after:rounded-full after:border-4 after:transition-all group-hover:after:border-[0.5rem] after:border-primaryElectricBlue">
                      {formatApiTime(place.time)} -{" "}
                      {addDuration(place.time, place.appointement.duration)}
                    </div>
                    <div
                      className="grid grid-cols-1 col-span-8 gap-2 mb-4 mr-4"
                      key={place.appointement.showroom}
                    >
                      {/** COUNTRY/CITY BADGE */}
                      {(index === 0 ||
                        place.appointement.meta.city !==
                          arr[index - 1].appointement.meta.city) && (
                        <div
                          className={`w-fit rounded-full space-x-2 ${
                            index % 2 === 0
                              ? "bg-primaryLightElectricBlue"
                              : "bg-statusOrangeLight"
                          } px-2`}
                        >
                          <span>{flag(place.appointement.meta.country)}</span>
                          <span>{place.appointement.meta.city}</span>
                        </div>
                      )}

                      {/** APPOINTMENT CARD */}
                      <div
                        className={`col-start-1 col-span-4 grid grid-cols-[16px_repeat(11,_minmax(0,_1fr))] gap-x-2 rounded-lg ${
                          index % 2 === 0
                            ? "bg-primaryLightElectricBlue"
                            : "bg-statusOrangeLight"
                        } p-4`}
                      >
                        {/** FIRST ROW */}
                        <span className="col-span-11 text-lg font-bold text-primaryElectricBlue">
                          {place.appointement.showroom}
                        </span>
                        <span className="flex justify-end col-span-1 gap-2 text-lg font-bold text-primaryElectricBlue text-primaryBlack">
                          {place.appointement.meta.formats.includes(
                            "IN_PERSON",
                          ) && <HiOutlineUsers />}
                          {place.appointement.meta.formats.includes(
                            "VIRTUAL",
                          ) && <HiOutlineVideoCamera />}
                        </span>

                        {/** IN PERSON ROW */}
                        {place.appointement.meta.formats.includes(
                          "IN_PERSON",
                        ) && (
                          <>
                            <span className="flex items-center justify-start w-4">
                              <HiOutlineLocationMarker />
                            </span>
                            <span className="flex items-center col-span-11 text-md">
                              {place.appointement.meta.address}{" "}
                              {place.appointement.meta.zipCode}{" "}
                              {place.appointement.meta.city} |{" "}
                              {t(
                                "Cruising.plan-my-trip.showroom-open-from-to",
                                {
                                  opensAt: formatApiTime(
                                    place.appointement.meta.openingHour,
                                  ),
                                  closesAt: formatApiTime(
                                    place.appointement.meta.closingHour,
                                  ),
                                },
                              )}
                            </span>
                          </>
                        )}
                        {/** VIRTUAL ROW */}
                        {place.appointement.meta.formats.includes(
                          "VIRTUAL",
                        ) && (
                          <>
                            <span className="flex items-center justify-start w-4">
                              <HiOutlineClock />
                            </span>
                            <span className="flex items-center col-span-11 gap-2 text-md">
                              {t(
                                "Cruising.plan-my-trip.showroom-open-from-to",
                                {
                                  opensAt: formatApiTime(
                                    place.appointement.meta.openingHour,
                                  ),
                                  closesAt: formatApiTime(
                                    place.appointement.meta.closingHour,
                                  ),
                                },
                              )}
                            </span>
                          </>
                        )}

                        {/** CONTACT EMAIL ROW */}
                        {place.appointement.meta.contact.email && (
                          <>
                            <span className="flex items-center justify-start w-4">
                              <HiOutlineMail />
                            </span>
                            <span className="flex items-center col-span-11 gap-2 text-md">
                              {place.appointement.meta.contact.email}
                            </span>
                          </>
                        )}

                        {/** CONTACT PHONE ROW */}
                        {place.appointement.meta.contact.phoneNumber && (
                          <>
                            <span className="flex items-center justify-start w-4">
                              <HiOutlinePhone />
                            </span>
                            <span className="flex items-center col-span-4 gap-2 text-md">
                              {place.appointement.meta.contact.phoneNumber}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              : null}
            <BottomBar sticky={false} className="absolute w-full lg:hidden">
              <Button
                theme="TERTIARY"
                onClick={cancelTripPlanification}
                disabled={selectedShowrooms.length === 0}
                className="justify-center w-full"
              >
                <HiChevronLeft />
                {t("Cruising.plan-my-trip.back-to-selection")}
              </Button>
            </BottomBar>
          </div>
        </ConditionalRender>
      </main>
    </div>
  );
}
