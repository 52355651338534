import React, { FormEvent, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { HiCheck } from "react-icons/hi2";
import { groupBy } from "remeda";

import Button from "@components/data-entry/Button";
import MultiSelect from "@components/data-entry/MultiSelect";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import CalloutBox from "@components/feedback/CalloutBox";
import Loading from "@components/feedback/Loading";
import BottomBar from "@components/layout/BottomBar";
import { Showroom } from "@models/Showroom";
import { GetShowroomAppointments } from "@services/api/showroom/get-appointments";
import { RemoveSellersFromShowroom } from "@services/api/showroom/remove-sellers";
import { fullName } from "@shared/helpers/formatters";

interface Seller {
  id: string;
  firstName: string;
  lastName: string;
}

type Props = {
  organizationId: string;
  showroom: Showroom & {
    sellers: Seller[];
  };
  onSuccess: () => void;
};

export default function ShowroomRemoveSellerForm({
  organizationId,
  showroom,
  onSuccess,
}: Props) {
  const { t } = useTranslation();
  const [sellersToRemove, setSellersToRemove] = useState<Seller[]>([]);

  const {
    data: appointments,
    isFetching: isFetchingAppointments,
    isError: isErrorAppointments,
  } = GetShowroomAppointments.useHook({
    organizationId,
    showroomId: showroom.id,
  });

  const { mutateAsync: removeSellersMutation } =
    RemoveSellersFromShowroom.useHook({
      organizationId,
      showroomId: showroom.id,
    });

  if (isFetchingAppointments || isErrorAppointments) {
    return <Loading type="screen" />;
  }

  const appointmentsBySeller = groupBy(
    appointments || [],
    (appt) => appt.seller.id || "none",
  );

  const availableSellers = showroom.sellers;
  const isValid = sellersToRemove.length > 0;
  const isSellerDisabled = (option: SelectOptionBase) =>
    appointmentsBySeller[option.value] &&
    appointmentsBySeller[option.value].length > 0;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    removeSellersMutation({
      sellerIds: sellersToRemove.map((s) => s.id),
    }).then(() => {
      onSuccess();
    });
  };

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit}>
      <div className="flex flex-col gap-6 p-10 pt-6 grow">
        <CalloutBox type="INFORMATIVE">
          <Trans
            i18nKey="SalesCampaign.overview.remove-sellers-form.callout"
            components={{
              br: <br />,
              strong: <strong className="font-bold" />,
            }}
          />
        </CalloutBox>
        <div className="flex flex-col">
          <label htmlFor="sellers">
            {t("SalesCampaign.overview.remove-sellers-form.sellers-label")}
          </label>
          <MultiSelect
            id="sellers"
            name="sellers"
            options={availableSellers.map((s) => ({
              label: fullName(s),
              value: s.id,
            }))}
            isOptionDisabled={isSellerDisabled}
            onChange={(selectedSellersOptions) => {
              const selectedIds = selectedSellersOptions.map((o) => o.value);
              setSellersToRemove(
                showroom.sellers.filter((s) => selectedIds.includes(s.id)),
              );
            }}
            value={sellersToRemove.map((s) => ({
              label: fullName(s),
              value: s.id,
            }))}
          />
        </div>
      </div>
      <BottomBar>
        <Button theme="PRIMARY" type="submit" disabled={!isValid}>
          {t("SalesCampaign.overview.remove-sellers-form.submit")}
          <HiCheck className="w-5 h-5" />
        </Button>
      </BottomBar>
    </form>
  );
}
