import React from "react";

import { t } from "i18next";
import { toast } from "react-toastify";

import ArchivedAccountsTable from "@app/modules/crm/components/accounts/archived-accounts-table";
import CRMHeader from "@app/modules/crm/components/header";
import queryClient from "@app/queryClient";
import Loading from "@components/feedback/Loading";
import { AccountWithCollections } from "@models/old/Account";
import { getFullAccountsEndpoint } from "@services/api/accounts/get-full-accounts";
import { getAPIQueryKey } from "@services/api/helper";
import {
  queryKeysFetchAccountsArchived,
  useFetchAccountsArchived,
} from "@services/api/old/accounts/fetch-accounts-archived";
import { queryKeysFetchAccountsCount } from "@services/api/old/accounts/fetch-accounts-count";
import { queryKeysFetchAccountsArchivedCount } from "@services/api/old/accounts/fetch-archived-count";
import { usePutRestoreAccount } from "@services/api/old/accounts/put-restore-account";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import LogService from "@services/log/service";
import { pageWithAccessControl } from "@shared/components/access-control";
import { ACL_ADMINS_AGENTS_MANAGERS } from "@shared/components/access-control/helpers";

function CRMArchivedAccounts() {
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const { mutateAsync: putRestoreAccount } =
    usePutRestoreAccount(organizationId);

  const { data: accountList, isLoading: isLoadingList } =
    useFetchAccountsArchived(organizationId);

  const handleRestoreAccount = (account: AccountWithCollections) => {
    putRestoreAccount(account?.id)
      .then(async () => {
        await queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(
            getFullAccountsEndpoint.path(organizationId),
          ),
        });
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchAccountsArchived(organizationId),
        });
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchAccountsCount(organizationId),
        });
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchAccountsArchivedCount(organizationId),
        });
        toast.success(t("CRM.archived-accounts.confirm-account-restore"));
      })
      .catch((err) => {
        LogService.error(err);
      });
  };

  return (
    <>
      <CRMHeader />
      <div className="p-6">
        {isLoadingList && <Loading type="button" />}
        {!isLoadingList && (
          <ArchivedAccountsTable
            accounts={
              accountList?.sort((a, b) => a.name.localeCompare(b.name)) || []
            }
            onClickRestore={handleRestoreAccount}
          />
        )}
      </div>
    </>
  );
}

export default pageWithAccessControl(
  ACL_ADMINS_AGENTS_MANAGERS,
  CRMArchivedAccounts,
);
