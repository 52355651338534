import * as React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import modaCruisingLogo from "@app/assets/logos/moda-cruising.svg";
import Button from "@components/data-entry/Button";

export default function CruisingWelcomePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const gotoCruising = () => {
    navigate("/cruising/plan-my-trip");
  };

  return (
    <div className="rounded-lg border border-primaryLightGray max-w-[20rem] min-w-[24rem]">
      <div className="flex flex-col items-center p-4 space-y-4 border-b bg-primaryLightElectricBlue border-primaryLightGray">
        <img alt="booked" className="h-6" src={modaCruisingLogo} />
        <span>{t("Cruising.welcome.subtitle")}</span>
      </div>
      <div className="flex flex-col gap-4 p-4">
        <p>{t("Cruising.welcome.description.first-paragraph")}</p>
        <p>{t("Cruising.welcome.description.second-paragraph")}</p>
        <Button
          onClick={gotoCruising}
          theme="PRIMARY"
          className="mt-10 justify-center"
        >
          {t("Cruising.welcome.plan-my-trip")}
        </Button>
      </div>
    </div>
  );
}
