import { z } from "zod";

import { RealDate, ZonedDate } from "@helpers/Date";
import { AccountWithCollections } from "@models/old/Account";
import { Collection } from "@models/old/Collection";
import { Contact } from "@models/old/Contact";
import { SellerWithAvailabilityStatus } from "@models/old/Seller";
import {
  AppointmentFormat,
  AppointmentTypeEnum,
  VirtualMeetingAppChoice,
  appointmentFormatSchema,
  appointmentTypeSchema,
  virtualMeetingAppSchema,
} from "@models/types/enums";

import { zonedDateHydratingSchema } from "./types/Dates";

export const specialRequestSchema = z.object({
  id: z.string().uuid(),
  endTime: zonedDateHydratingSchema,
  format: appointmentFormatSchema,
  invitationId: z.string().uuid(),
  languages: z.array(z.string()),
  showroomId: z.string().uuid(),
  startTime: zonedDateHydratingSchema,
  status: z.enum(["PENDING", "ACCEPTED", "DECLINED"]),
  type: appointmentTypeSchema,
  virtualMeetingApp: virtualMeetingAppSchema.nullable(),
});

export interface SpecialRequestOldModel {
  id?: string;
  startTime: ZonedDate;
  endTime: ZonedDate;
  format: AppointmentFormat;
  type: AppointmentTypeEnum;
  virtualMeetingApp: VirtualMeetingAppChoice | null;
  languages: string[];
  status: "PENDING" | "ACCEPTED" | "DECLINED";
  account: AccountWithCollections;
  attendees: Contact[];
  collection: Collection;
  sellers: SellerWithAvailabilityStatus[];
  showroomId: string;
}

export type SpecialRequestOldModelSerialized = Omit<
  SpecialRequestOldModel,
  "startTime" | "endTime"
> & {
  startTime: string;
  endTime: string;
};

export const SpecialRequestOldModelToInput = (
  specialRequest: SpecialRequestOldModel,
  timezone: string,
): SpecialRequestInput => ({
  id: specialRequest.id,
  selectedDate: specialRequest.startTime.toLocalDate(timezone),
  fromHour: specialRequest.startTime.toLocalDate(timezone),
  toHour: specialRequest.endTime.toLocalDate(timezone),
  languages: specialRequest.languages,
  format: specialRequest.format,
  type: specialRequest.type,
  virtualMeetingApp: specialRequest.virtualMeetingApp,
});

export const unserializeSpecialRequestOldModel = (
  serializedSpecialRequest: SpecialRequestOldModelSerialized,
): SpecialRequestOldModel => ({
  ...serializedSpecialRequest,
  startTime: new ZonedDate(
    new Date(serializedSpecialRequest.startTime) as RealDate,
  ),
  endTime: new ZonedDate(
    new Date(serializedSpecialRequest.endTime) as RealDate,
  ),
});

export interface SpecialRequestInput {
  id?: string;
  selectedDate: Date;
  fromHour: Date;
  toHour: Date;
  languages: string[];
  format: AppointmentFormat;
  type: AppointmentTypeEnum;
  virtualMeetingApp: VirtualMeetingAppChoice | null;
}
