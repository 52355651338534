import React, { useState } from "react";

import { t } from "i18next";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";

import { translateFormatWithApp } from "@booking/helpers/virtual-apps-handler";
import Tag from "@components/data-display/Tag";
import Button from "@components/data-entry/Button";
import MultiSelect from "@components/data-entry/MultiSelect";
import { useIsMobile } from "@components/layout/Breakpoint";
import FadeInOut from "@components/transitions/FadeInOut";
import { AppointmentFormatWithMeetingApp } from "@models/types/enums";

interface BookingFilters {
  languages?: string[];
  formats?: AppointmentFormatWithMeetingApp[];
}

interface AppointmentSlotFiltersProps {
  languages: string[];
  formats: AppointmentFormatWithMeetingApp[];
  values: BookingFilters;
  onFormatChange: (value: AppointmentFormatWithMeetingApp[]) => void;
  onLanguageChange: (value: string[]) => void;
}

export default function AppointmentSlotFilters({
  languages,
  formats,
  values,
  onFormatChange,
  onLanguageChange,
}: AppointmentSlotFiltersProps) {
  const isMobile = useIsMobile();
  const [displayFilters, setDisplayFilters] = useState(false);

  const toggleFilters = () => {
    setDisplayFilters(!displayFilters);
  };

  const isFormatFiltered = (values.formats || []).length > 0 ? 1 : 0;
  const isLanguageFiltered = (values.languages || []).length > 0 ? 1 : 0;
  const countFilters = isFormatFiltered + isLanguageFiltered;

  return (
    <div
      data-testid="container-slot-select-filters"
      className="relative flex flex-col"
    >
      {isMobile && (
        <Button
          className="w-full flex justify-center items-center mb-2"
          theme="PRIMARY"
          onClick={toggleFilters}
        >
          {countFilters > 0 && (
            <Tag type="number" size="sm" theme="PRIMARY-REVERSED">
              {countFilters}
            </Tag>
          )}
          {t("Booking.AppointmentBookingSlotSelect.filters.filters")}
          <HiOutlineAdjustmentsHorizontal className="w-4 h-4" />
        </Button>
      )}
      <FadeInOut
        show={(!isMobile || displayFilters) && countFilters > 0}
        className="absolute top-0 right-0 place-self-end"
      >
        <button
          type="button"
          aria-label="clear-all-filters"
          className="underline text-primaryElectricBlue"
          onClick={() => {
            onFormatChange([]);
            onLanguageChange([]);
            toggleFilters();
          }}
        >
          {t("Booking.AppointmentBookingSlotSelect.filters.clear-fields")}
        </button>
      </FadeInOut>
      <FadeInOut show={!isMobile || displayFilters}>
        <div className="flex flex-col lg:flex-row gap-4">
          <div className="w-full lg:w-1/2 flex flex-col gap-2">
            <label className="font-medium" htmlFor="formats">
              {t("Booking.AppointmentBookingSlotSelect.filters.formats")}
            </label>
            <MultiSelect
              placeholder={t(
                "Booking.AppointmentBookingSlotSelect.filters.formats-placeholder",
              )}
              id="formats"
              name="formats"
              value={(values.formats || []).map((formatWithApp) => ({
                label: translateFormatWithApp(formatWithApp, t),
                value: formatWithApp,
              }))}
              onChange={(value) => {
                onFormatChange(
                  value.map((i) => i.value as AppointmentFormatWithMeetingApp),
                );
                toggleFilters();
              }}
              options={
                formats &&
                formats.map((formatWithApp) => ({
                  label: translateFormatWithApp(formatWithApp, t),
                  value: formatWithApp,
                }))
              }
            />
          </div>
          <div className="w-full lg:w-1/2 flex flex-col gap-2">
            <label className="font-medium" htmlFor="languages">
              {t("Booking.AppointmentBookingSlotSelect.filters.languages")}
            </label>
            <MultiSelect
              placeholder={t(
                "Booking.AppointmentBookingSlotSelect.filters.languages-placeholder",
              )}
              id="languages"
              name="languages"
              value={(values.languages || []).map((l) => ({
                label: t(`Common.language.${l}`),
                value: l,
              }))}
              onChange={(value) => {
                onLanguageChange(value.map((i) => i.value));
                toggleFilters();
              }}
              options={
                languages &&
                languages.map((l) => ({
                  label: t(`Common.language.${l}`),
                  value: l,
                }))
              }
            />
          </div>
        </div>
      </FadeInOut>
    </div>
  );
}
