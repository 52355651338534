import React from "react";

import { Trans } from "react-i18next";

import { ShowroomSeason } from "@models/types/enums";

interface MeetingReportPageTitleProps {
  season: ShowroomSeason;
  year: number;
}

function MeetingReportPageTitle(props: MeetingReportPageTitleProps) {
  const { season, year } = props;

  return (
    <h1 className="heading-1 px-6">
      <Trans
        i18nKey="CRM.meeting-report.title"
        values={{ salesCampaignName: `${season} ${year}` }}
      >
        CRM.meeting-report.title
      </Trans>
    </h1>
  );
}

export default MeetingReportPageTitle;
