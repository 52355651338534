import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { competitorsGroupSchema } from "@models/CompetitorGroup";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";
import { getCompetitorsGroupsEndpoint } from "@services/api/organization/get-competitors-groups";

export interface DeleteCompetitorsGroupHookParams {
  organizationId: string;
}
export interface DeleteCompetitorsGroupCallParams {
  competitorsGroupId: string;
}

export type DeleteCompetitorsGroupParams = DeleteCompetitorsGroupHookParams &
  DeleteCompetitorsGroupCallParams;

export const deleteCompetitorsGroupSchema = competitorsGroupSchema;

export type DeleteCompetitorsGroupResponse = z.infer<
  typeof deleteCompetitorsGroupSchema
>;

export const deleteCompetitorsGroupEndpoint = {
  path: ({
    competitorsGroupId,
    organizationId,
  }: DeleteCompetitorsGroupParams) =>
    `/organizations/${organizationId}/competitors-groups/${competitorsGroupId}`,
  call: ({
    organizationId,
    competitorsGroupId,
  }: DeleteCompetitorsGroupParams) =>
    axiosInstance
      .delete<DeleteCompetitorsGroupResponse>(
        deleteCompetitorsGroupEndpoint.path({
          organizationId,
          competitorsGroupId,
        }),
      )
      .then((response) => response.data),
};

export function useDeleteCompetitorsGroup(
  hookParams: DeleteCompetitorsGroupHookParams,
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (callParams: DeleteCompetitorsGroupCallParams) =>
      deleteCompetitorsGroupEndpoint.call({
        ...hookParams,
        ...callParams,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getAPIQueryKey(getCompetitorsGroupsEndpoint.path(hookParams)),
      });
    },
  });
}
