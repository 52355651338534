import React from "react";

import { User } from "@models/User";
import { withBuyerOnly } from "@shared/components/guards/authentication";

function BuyerOrderDeadlinesPage({ user }: { user: User }) {
  return <div>Buyer Order Deadlines Page : {user.email}</div>;
}

export default withBuyerOnly(BuyerOrderDeadlinesPage);
