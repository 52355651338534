import { z } from "zod";

import {
  zonedDateHydratingSchema,
  zonedDateSerializingSchema,
} from "@models/types/Dates";
import {
  AppointmentFormatList,
  AppointmentTypeList,
  BookedThroughList,
  virtualMeetingAppWithANYSchema,
} from "@models/types/enums";

export const AppointmentWarningsList = [
  "NO_VIRTUAL_TOOL_WARNING",
  "VIRTUAL_MEETING_APP_WARNING",
  "APPOINTMENT_TYPE_WARNING",
  "NOT_PREFERRED_SELLER_WARNING",
] as const;
export const appointmentWarningSchema = z.enum(AppointmentWarningsList);
export type AppointmentWarning = z.infer<typeof appointmentWarningSchema>;

export const appointmentStatusSchema = z.enum([
  "PLANNED",
  "BUYER_ARRIVED",
  "ONGOING",
  "DONE",
  "CANCELLED",
]);

export const appointmentStatusList = [
  "PLANNED",
  "BUYER_ARRIVED",
  "ONGOING",
  "DONE",
  "CANCELLED",
] as const;

/**
 * APPOINTMENTS NEED TWO SCHEMAS BECAUSE WE ARE USING ZOD TO TRANSFORM SOME PROPERTIES INTO CUSTOM OBJECTS
 * z.parse only does one way transformation, so we cannot hydrate/serialize with the same schema
 * So there must be :
 * - one for hydrating (when retrieving data from the API)
 * - one for serializing (when pushing data to the API)
 *
 * Both will use the same base, only transformed fields are to be separated
 */
const appointmentBaseSchema = z.object({
  id: z.string(),
  format: z.enum(AppointmentFormatList).nullable(),
  type: z.enum(AppointmentTypeList),
  virtualMeetingApp: virtualMeetingAppWithANYSchema.nullable(),
  warnings: z.array(appointmentWarningSchema),
  title: z.string().nullable(),
  accountOtb: z.number().nullable(),
  status: appointmentStatusSchema,
  bookedThrough: z.enum(BookedThroughList),
});

/**
 * Hydrating schema
 */
export const appointmentHydratingSchema = appointmentBaseSchema.extend({
  startTime: zonedDateHydratingSchema,
  endTime: zonedDateHydratingSchema,
});

/**
 * Serializing schema
 */
export const appointmentSerializingSchema = appointmentBaseSchema.extend({
  startTime: zonedDateSerializingSchema,
  endTime: zonedDateSerializingSchema,
});

export type Appointment = z.infer<typeof appointmentHydratingSchema>;
export type SerializedAppointment = z.infer<
  typeof appointmentSerializingSchema
>;
