import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

import { localDateToUnzonedDate, localTimeToUnzonedTime } from "@helpers/Date";
import axiosInstance from "@services/api/config";
import { GetCruisingShowroomsEndpoint } from "@services/api/cruising/get-showrooms";

export namespace OptimizeCruisingShowroomsEndpoint {
  export const outputSchema = z.object({
    result: z.array(
      z.object({
        date: z.string(),
        tour: z.array(
          z.object({
            appointement: z.object({
              showroom: z.string(),
              duration: z.number(),
              address: z.string(),
              coordinates: z.array(z.number()).length(2),
            }),
            time: z.string(),
          }),
        ),
      }),
    ),
    status: z.string(),
  });
  export type Output = z.infer<typeof outputSchema>;

  export const inputSchema = z.array(
    z.object({
      id: z.string().optional(),
      name: z.string(),
      formattedAddress: z.string(),
      postalCode: z.string(),
      city: z.string(),
      countryCode: z.string(),
      dates: z
        .array(z.date().transform((d) => localDateToUnzonedDate(d)))
        .min(1),
      openingHour: z.date().transform((d) => localTimeToUnzonedTime(d)),
      closingHour: z.date().transform((d) => localTimeToUnzonedTime(d)),
      latitude: z.number(),
      longitude: z.number(),
      duration: z.number().min(15),
    }),
  );
  export type Input = z.infer<typeof inputSchema>;

  export const call = (params: Input) =>
    axiosInstance
      .post("/cruising/showrooms", inputSchema.parse(params))
      .then((r) => outputSchema.parse(r.data));

  export const useHook = () => {
    const invalidateGet = GetCruisingShowroomsEndpoint.useInvalidate();
    return useMutation({
      mutationFn: (variables: Input) => call(variables),
      onSuccess: () => {
        invalidateGet();
      },
    });
  };
}
