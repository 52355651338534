import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Organization } from "@models/old/Organization";
import {
  Representative,
  RepresentativeUpsertData,
} from "@models/old/Representative";
import axiosInstance from "@services/api/config";

import { queryKeysFetchRepresentatives } from "./fetch-representatives";

export const putRepresentativeEndpoint = {
  path: (
    organizationId: Organization["id"],
    representativeId: Representative["id"],
  ) => `/organizations/${organizationId}/representatives/${representativeId}`,
};

export const postRepresentativeEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/representatives`,
};

export function useUpsertRepresentative(
  organizationId: Organization["id"],
  representativeId?: Representative["id"],
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (representative: RepresentativeUpsertData) =>
      representativeId
        ? axiosInstance.put(
            putRepresentativeEndpoint.path(organizationId, representativeId),
            representative,
          )
        : axiosInstance.post(
            postRepresentativeEndpoint.path(organizationId),
            representative,
          ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [queryKeysFetchRepresentatives(organizationId)],
      }),
  });
}
