import React from "react";

import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { HiCheck, HiOutlineX } from "react-icons/hi";
import { z } from "zod";

import Button from "@components/data-entry/Button";
import TextField from "@components/data-entry/TextField";
import BottomBar from "@components/layout/BottomBar";
import { Representative } from "@models/old/Representative";
import { VirtualMeetingAppsList } from "@models/types/enums";
import virtualToolsValidator from "@shared/representative/virtual-tools-validator";

type FormValues = {
  virtualMeetingAppLinks: Record<string, string> | null;
};

interface VirtualToolsProps {
  user: Representative;
  onSubmit: (user: Representative) => void;
}

function VirtualTools({ user, onSubmit }: VirtualToolsProps) {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm<FormValues>({
    mode: "onTouched",
    defaultValues: {
      virtualMeetingAppLinks: user?.virtualMeetingAppLinks,
    },
  });

  // validate tool link whenever there is a link and the user has a campaign in progress
  const validateEmptyToolLink =
    (user &&
      user.useInActiveSalesCampaignNames &&
      user.useInActiveSalesCampaignNames.length > 0) ||
    false;

  const isVirtualToolRequiredFilled = virtualToolsValidator(
    validateEmptyToolLink,
    user.virtualMeetingAppLinks,
  );

  const onSubmitForm = (data: FormValues) => {
    const virtualMeetingAppLinks = data.virtualMeetingAppLinks
      ? Object.entries(data.virtualMeetingAppLinks)
          .filter(([_key, value]) => !!value) // remove empty values
          .reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: value,
            }),
            {},
          )
      : null;

    const representative: Representative = {
      ...user,
      virtualMeetingAppLinks,
    };
    reset(data);
    onSubmit(representative);
  };

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit(onSubmitForm)}>
      <div className="flex flex-col grow gap-6 p-6 lg:p-10">
        {VirtualMeetingAppsList.map((app) => (
          <div className="w-full lg:w-1/3" key={app}>
            <label
              className="font-medium"
              htmlFor={`virtualMeetingAppLinks.${app}`}
            >
              {t(`MyProfile.form.virtual-tool.${app}`)}
            </label>
            <div className="mt-2">
              <Controller
                name={`virtualMeetingAppLinks.${app}`}
                control={control}
                rules={{
                  validate: {
                    required: (value) =>
                      isVirtualToolRequiredFilled(app, value),
                    invalid: async (value) => {
                      if (value) {
                        return z.string().url().safeParse(value).success;
                      }
                      return true;
                    },
                  },
                }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <TextField
                    id={`virtualMeetingAppLinks.${app}`}
                    placeholder={t(
                      "MyProfile.form.virtual-tool-field-placeholder",
                    )}
                    className={error && "border-primaryRed"}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onClearField={() => onBlur()}
                  />
                )}
              />
              {errors.virtualMeetingAppLinks &&
                errors.virtualMeetingAppLinks[app] &&
                errors.virtualMeetingAppLinks[app]?.type === "invalid" && (
                  <p className="text-sm italic text-primaryRed">
                    {t("Common.form.this-is-not-valid-url")}
                  </p>
                )}
              {errors.virtualMeetingAppLinks &&
                errors.virtualMeetingAppLinks[app] &&
                errors.virtualMeetingAppLinks[app]?.type === "required" && (
                  <p className="text-sm italic text-primaryRed">
                    {t("Common.form.virtual-tool-error-empty")}
                  </p>
                )}
            </div>
          </div>
        ))}
      </div>
      <BottomBar>
        <Button
          theme="TERTIARY"
          type="button"
          onClick={() => {
            reset();
          }}
        >
          {t("Components.buttons.cancel-changes")}
          <HiOutlineX className="text-2xl" />
        </Button>
        <Button theme="PRIMARY" disabled={!isValid} className="items-center">
          {t("Components.buttons.save-changes")}
          <HiCheck className="text-2xl" />
        </Button>
      </BottomBar>
    </form>
  );
}

export default VirtualTools;
