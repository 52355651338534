import { useQuery } from "@tanstack/react-query";

import { Representative } from "@models/OrganizationRepresentative";
import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

// @FIXME: split fetchFullRepresentatives from fetchLightRepresentatives
export const fetchRepresentativesEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/representatives/full`,
};

type FetchRepresentativesResponse = Representative[];

export const queryKeysFetchRepresentatives = (organizationId: string) =>
  fetchRepresentativesEndpoint.path(organizationId).split("/");

export const useFetchRepresentatives = (organizationId: string) =>
  useQuery({
    queryKey: queryKeysFetchRepresentatives(organizationId),
    queryFn: () =>
      axiosInstance
        .get<FetchRepresentativesResponse>(
          fetchRepresentativesEndpoint.path(organizationId),
        )
        .then((res) => res.data),
  });
