import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import { collectionSchema } from "@models/Collection";
import { Organization } from "@models/Organization";
import {
  representativeSchema,
  virtualMeetingAppSchema,
} from "@models/OrganizationRepresentative";
import { showroomSchema } from "@models/Showroom";
import Showroom from "@models/old/Showroom";
import { AccountAppointmentTypeList } from "@models/types/enums";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export namespace GetShowroomEndpoint {
  export const outputSchema = showroomSchema.extend({
    addressComponents: z.any(),
    collections: z.array(
      collectionSchema
        .pick({ id: true, name: true })
        .extend({ brand: brandSchema }),
    ),
    sellers: z.array(
      representativeSchema
        .pick({
          firstName: true,
          id: true,
          languages: true,
          lastName: true,
          virtualMeetingAppLinks: true,
        })
        .extend({
          virtualMeetingApps: virtualMeetingAppSchema,
          appointmentTypes: z.array(z.enum(AccountAppointmentTypeList)),
        }),
    ),
  });

  export type Output = z.infer<typeof outputSchema>;

  export interface HookParams {
    organizationId: Organization["id"];
    showroomId: Showroom["id"];
  }

  export function path({ organizationId, showroomId }: HookParams) {
    return `/organizations/${organizationId}/showrooms/${showroomId}`;
  }

  export function call(params: HookParams) {
    return axiosInstance
      .get<Output>(path(params))
      .then((r) => outputSchema.parse(r.data));
  }

  export function useHook(params: HookParams) {
    return useQuery({
      queryFn: () => call(params),
      queryKey: getAPIQueryKey(path(params)),
    });
  }
}
