import { useContext } from "react";

import { User } from "@models/User";

import { AuthenticationContext } from "./AuthenticationContext";

export default function useAuthentication() {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error(
      "useAuthentication must be used within a AuthenticationProvider",
    );
  }
  return context;
}

/**
 * Hook helper that makes sure the current user is authenticated
 * It will throw an error if user is not authenticated
 *
 * ex: const user: User = useAuthenticatedUser();
 */
export function useAuthenticatedUser(): User {
  const { currentUser } = useAuthentication();
  if (!currentUser) {
    throw new Error("user not authenticated");
  }
  return currentUser;
}
