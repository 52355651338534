import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toFormData } from "axios";
import { z } from "zod";

import { openingDaySerializingSchema } from "@models/Showroom";
import {
  localDateSerializingSchema,
  localTimeSerializingSchema,
} from "@models/types/Dates";
import {
  AppointmentFormatList,
  CollectionAgnosticAppointmentTypeList,
  CollectionRelatedAppointmentTypeList,
  ShowroomSeasonList,
  appointmentTypeSchema,
} from "@models/types/enums";
import axiosInstance from "@services/api/config";

import { getAPIQueryKey } from "../helper";
import { GetShowroomsEndpoint } from "./get-showrooms";

export namespace ShowroomUpdateEndpoint {
  export const inputSerializingSchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
    season: z.enum(ShowroomSeasonList),
    year: z.number(),
    timezone: z.string(),
    formattedAddress: z.string(),
    postalCode: z.string(),
    city: z.string(),
    countryCode: z.string().length(2),
    addressComponents: z.any(),
    directions: z.string().nullable(),
    lunchBreakStartingHour: localTimeSerializingSchema.nullable(),
    lunchBreakEndingHour: localTimeSerializingSchema.nullable(),
    openingHour: localTimeSerializingSchema,
    closingHour: localTimeSerializingSchema,
    openingDays: z.array(openingDaySerializingSchema),
    collections: z.array(z.object({ id: z.string() })),
    appointmentFormats: z.array(z.enum(AppointmentFormatList)),
    appointmentTypes: z.array(appointmentTypeSchema),
    specialRequestsAllowed: z.boolean(),
    appointmentTypesDuration: z.array(
      z.union([
        z.object({
          type: z.enum(CollectionRelatedAppointmentTypeList),
          collectionId: z.string(),
          duration: z.number(),
        }),
        z.object({
          type: z.enum(CollectionAgnosticAppointmentTypeList),
          duration: z.number(),
        }),
      ]),
    ),
    appointmentIncrement: z.number().nullable(),
    sellers: z.array(
      z.object({
        id: z.string(),
        appointmentTypes: z.array(appointmentTypeSchema),
      }),
    ),
    lastAllowedBookingDate: localDateSerializingSchema.nullable(),
    numberOfSeats: z.number().nullable(),
  });
  export type Input = z.input<typeof inputSerializingSchema>;

  export const outputHydratingSchema = z.object({});
  export type Output = z.output<typeof outputHydratingSchema>;

  interface HookParams {
    showroomId: string;
    organizationId: string;
  }

  interface MutationParams {
    files: File[];
    data: Input;
  }

  type Params = HookParams & MutationParams;

  export const path = ({ organizationId, showroomId }: Params) =>
    `organizations/${organizationId}/showrooms/${showroomId}`;

  export const call = (params: Params) => {
    const { data, files } = params;

    const body = inputSerializingSchema.parse(data);
    const formData = toFormData(body);
    files.forEach((file) => formData.append("files", file));

    return axiosInstance
      .put(path(params), formData)
      .then((res) => outputHydratingSchema.parse(res.data));
  };

  export function useHook(hookParams: HookParams) {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
          ...hookParams,
        }),
      onSuccess: () => {
        // @todo add queries to invalidate
        queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(GetShowroomsEndpoint.path(hookParams)),
        });
      },
    });
  }
}
