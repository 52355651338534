import * as React from "react";

import { Outlet } from "react-router-dom";

import CRMContext, {
  useInitCRMDrawerContext,
} from "@app/modules/crm/context/crm-drawers";

export default function CRMLayout() {
  const contextValue = useInitCRMDrawerContext();
  return (
    <CRMContext.Provider value={contextValue}>
      <Outlet />
    </CRMContext.Provider>
  );
}
