import React from "react";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { VscCalendar } from "react-icons/vsc";
import { toast } from "react-toastify";

import Button from "@components/data-entry/Button";
import CustomDatePicker from "@components/data-entry/CustomDatepicker";
import { UpdateOrderDeadline } from "@services/api/order-deadline/update-order-deadline";

interface FormValues {
  deadline: Date | null;
}

interface OrderDeadlineFormProps {
  deadline: Date | null | undefined;
  brandId: string;
  season: string;
  year: number;
  closeModal?: () => void;
}

function OrderDeadlineForm({
  deadline,
  brandId,
  season,
  year,
  closeModal,
}: OrderDeadlineFormProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      deadline: deadline || undefined,
    },
  });
  const { t } = useTranslation();

  const { mutateAsync: updateOrderDeadline } = UpdateOrderDeadline.useHook({
    brandId,
    season,
    year,
  });
  const onSaveDeadline = (data: FormValues) => {
    updateOrderDeadline({
      deadline: data.deadline || undefined,
    })
      .then(() => {
        toast.success(t("Shared.order-deadline.updated"));
        if (closeModal) {
          closeModal();
        }
      })
      .catch((error) => {
        console.error(t("Shared.order-deadline.update-error"), error);
        toast.error(t("Shared.order-deadline.update-error"));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSaveDeadline)}>
      <div className="relative mb-4">
        <div className="flex justify-center">
          <label className="font-light" htmlFor="date">
            {t("Shared.order-deadline.enter-deadline")}
          </label>
        </div>
        <span className="lg:w-1/2 mt-10">
          <Controller
            name="deadline"
            control={control}
            rules={{ required: t("Shared.order-deadline.date-required") }}
            render={({ field: { onChange, value } }) => (
              <div className="relative mt-4 w-full flex justify-center">
                <CustomDatePicker
                  id="deadline"
                  value={value || null}
                  placeholder={
                    <div className="flex justify-center items-center gap-2 w-full lg:w-80">
                      <VscCalendar />
                      <span className="text-grey">
                        {t("Shared.order-deadline.selected-date-placeholder")}
                      </span>
                    </div>
                  }
                  minDate={new Date()}
                  onChange={(date) => {
                    if (date === null) {
                      onChange();
                    } else {
                      onChange(date);
                    }
                  }}
                  intlOptions={{
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                  }}
                />
              </div>
            )}
          />
          {errors.deadline && (
            <p className="text-xs italic text-primaryRed text-center pt-2">
              {errors.deadline.message}
            </p>
          )}
        </span>
      </div>
      <div className="flex justify-center space-x-4">
        <Button
          type="button"
          className="flex justify-center"
          onClick={closeModal}
        >
          {t("Shared.order-deadline.close-button")}
        </Button>
        <Button
          type="submit"
          className="bg-blue-500 text-white hover:bg-blue-600"
        >
          {t("Shared.order-deadline.save-deadline")}
        </Button>
      </div>
    </form>
  );
}

export default OrderDeadlineForm;
