import React from "react";

import { AdminOnboardingStep } from "@models/types/enums";

import BreadCrumbItem from "../../../../onboarding/components/BreadCrumbItem";

function AdminOnboardingBreadcrumb({
  currentStep,
}: {
  currentStep: AdminOnboardingStep;
}) {
  return (
    <div className="flex justify-around pt-12 sticky top-0 right-0 bg-white z-header">
      <BreadCrumbItem
        step={AdminOnboardingStep.ORGANIZATION_CREATION}
        currentStep={currentStep}
        label="Organization information"
        page="information"
      />
      <BreadCrumbItem
        step={AdminOnboardingStep.ACCOUNTS_UPLOAD}
        currentStep={currentStep}
        label="Import accounts"
        page="accounts-upload"
      />
      <BreadCrumbItem
        step={AdminOnboardingStep.CONTACTS_UPLOAD}
        currentStep={currentStep}
        label="Import contacts"
        page="contacts-upload"
      />
      <BreadCrumbItem
        step={AdminOnboardingStep.REPRESENTATIVES_UPLOAD}
        currentStep={currentStep}
        label="Import representatives"
        page="representatives-upload"
      />
    </div>
  );
}

export default AdminOnboardingBreadcrumb;
