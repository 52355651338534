import React from "react";

import { Trans } from "react-i18next";
import { HiOutlinePencil } from "react-icons/hi";
import { HiLifebuoy } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import Button from "@components/data-entry/Button";
import { Contact } from "@models/Contact";
import { FEATURE_MODACRUISING_TOGGLE } from "@services/feature-flags";
import CrispButton from "@shared/components/CrispButton";

interface BuyerInformationsProps {
  contact: Pick<Contact, "firstName" | "email" | "phoneNumber">;
  onClickEdit: () => void;
}

function BuyerInformations(props: BuyerInformationsProps) {
  const navigate = useNavigate();
  const {
    contact: { firstName, email, phoneNumber },
    onClickEdit,
  } = props;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <h2 className="heading-2-mobile lg:heading-2 flex items-center">
          <span>
            <Trans
              i18nKey="Booking.buyer-greeting"
              values={{ firstName }}
              components={{
                span: <span className="text-primaryElectricBlue" />,
              }}
            />
          </span>
        </h2>
        {/* button mobile only */}
        <Button
          type="button"
          theme="ICON"
          onClick={onClickEdit}
          className="text-primaryElectricBlue rounded-full p-1 block lg:hidden"
        >
          <HiOutlinePencil />
        </Button>
      </div>
      <div className="gap-2 hidden lg:flex">
        <span className="text-primaryElectricBlue">{email}</span>|
        <span>{phoneNumber}</span>
        <Button
          type="button"
          theme="ICON"
          onClick={onClickEdit}
          className="text-primaryElectricBlue rounded-full p-1 ml-1"
        >
          <HiOutlinePencil />
        </Button>
      </div>
      <div>
        <CrispButton />
      </div>
      {FEATURE_MODACRUISING_TOGGLE && (
        <div>
          <Button
            type="button"
            onClick={() => {
              navigate("/cruising");
            }}
            theme="SECONDARY"
            className="text-primaryElectricBlue rounded-full p-1 ml-1 group flex items-center"
          >
            <HiLifebuoy />
            ModaCruising
          </Button>
        </div>
      )}
    </div>
  );
}

export default BuyerInformations;
