import { useState } from "react";

import { useTranslation } from "react-i18next";

export default function useCrisp() {
  const [isCrispOpen, setIsCrispOpen] = useState(false);
  const { t } = useTranslation();

  return {
    trigger: () => {
      const crisp = document.querySelector<HTMLAnchorElement>(
        ".crisp-client div#crisp-chatbox > div > a",
      );
      if (crisp) {
        crisp.click();
        setIsCrispOpen(!isCrispOpen);
      } else {
        alert(t("Components.sidebar.crisp-unavailable"));
      }
    },
    isCrispOpen,
  };
}
