import React from "react";

import InlineTableFilterSelect from "@components/data-entry/inline-filter/select";
import InlineTableFilterText from "@components/data-entry/inline-filter/text";
import InlineTableFilterWrapper from "@components/data-entry/inline-filter/wrapper";
import { usePrefixedTranslation } from "@helpers/Translation";

type FilterValues = {
  portfolioId: string | "any";
};

type Props = {
  values?: FilterValues;
  portfolios: {
    id: string;
    name: string;
  }[];
  onChange: (values: FilterValues) => void;
};

export default function PortfolioFilter({
  onChange,
  portfolios,
  values = {
    portfolioId: "all",
  },
}: Props) {
  const { pt } = usePrefixedTranslation("Invitation.PortfolioFilter");
  const handleChange = (v: FilterValues["portfolioId"]) => {
    const newValues: FilterValues = {
      portfolioId: v,
    };
    onChange(newValues);
  };
  return (
    <InlineTableFilterWrapper>
      <InlineTableFilterText>{pt("in-portfolio")}</InlineTableFilterText>
      <InlineTableFilterSelect
        value={values.portfolioId}
        options={[
          {
            label: pt("any-portfolio"),
            value: "any",
          },
          ...portfolios.map((p) => ({
            label: p.name,
            value: p.id,
          })),
        ]}
        onChange={handleChange}
      />
    </InlineTableFilterWrapper>
  );
}
