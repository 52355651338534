import { useTranslation } from "react-i18next";

/**
 * This hook provides a convenient way to handle translations in a component
 * Since we use <DOMAIN>.<COMPONENT>.key as a convention for translation keys
 * @param prefix the prefix of the translation key
 * @returns 3 functions, pt, t, pk
 */
export function usePrefixedTranslation(prefix: string) {
  const { t } = useTranslation();

  const pk = (suffix: string) => `${prefix}.${suffix}`;
  const pt = (suffix: string, arg?: Record<string, string | number>) =>
    t(pk(suffix), arg);
  return {
    /**
     * This is the same function as the useTranslation hook used everywhere already
     */
    t,
    /**
     * This function is used to translate a string using the prefix set in the hook + the suffix
     * @param suffix the suffix of the translation key
     * @param arg any values that could be transposed in the translation
     * @returns the translated string
     */
    pt,
    /**
     * This function is used to generate a translation key using the prefix set in the hook + the suffix
     * @param suffix the suffix of the translation key
     * @returns the translation key
     */
    pk,
  };
}
