/* eslint-disable react/no-unstable-nested-components */
import React from "react";

import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";

import HoverCard from "@components/data-display/HoverCard";
import MRTable from "@components/data-display/MRTable";
import Tag from "@components/data-display/Tag";
import TagCell from "@components/data-display/table/tag-cell";
import Button from "@components/data-entry/Button";
import { Representative } from "@models/OrganizationRepresentative";
import {
  ACL_ADMINS,
  ACL_ADMINS_MANAGERS,
} from "@shared/components/access-control/helpers";
import {
  ellipseWithCount,
  fullName,
  getRoleName,
} from "@shared/helpers/formatters";

export type RepresentativeInTable = Pick<
  Representative,
  | "id"
  | "firstName"
  | "lastName"
  | "position"
  | "role"
  | "markets"
  | "email"
  | "phoneNumber"
  | "languages"
  | "invitationStatus"
> & {
  portfolios: { name: string }[];
};

interface RepresentativesTableProps<Rep extends RepresentativeInTable> {
  representatives: Rep[];
  onClickEdit: (representative: Rep) => void;
  onClickDelete: (representative: Rep) => void;
  onClickInvite: (representativeId: string) => void;
}

export default function RepresentativesTable<
  Rep extends RepresentativeInTable,
>({
  representatives,
  onClickEdit,
  onClickDelete,
  onClickInvite,
}: RepresentativesTableProps<Rep>) {
  const columnHelper = createColumnHelper<RepresentativeInTable>();
  const columns = React.useMemo(
    () => [
      columnHelper.display({
        header: t("Representatives.table-header.name"),
        cell: ({ row: { original } }) => (
          <HoverCard
            renderIf={fullName(original).length > 20}
            fallbackProp="children"
            button={
              <div className="min-w-[10rem] max-w-[30rem] truncate">
                {fullName(original)}
              </div>
            }
          >
            <div className="min-w-[10rem]">{fullName(original)}</div>
          </HoverCard>
        ),
      }),
      columnHelper.accessor("position", {
        header: t("Representatives.table-header.position"),
        cell: (info) => info.getValue() || "-",
      }),
      columnHelper.accessor("role", {
        header: t("Representatives.table-header.role"),
        cell: (info) => getRoleName(info.getValue()) || "-",
      }),
      columnHelper.display({
        header: t("Representatives.table-header.markets"),
        cell: ({ row }) => {
          const { markets } = row.original;

          const ellipsis = ellipseWithCount(
            markets.map((market) => t(`Common.market-type.${market}`)),
            1,
          );

          return (
            <TagCell maxWidth="max-w-[25rem]" values={ellipsis.slice}>
              <HoverCard
                renderIf={ellipsis.length > 0}
                fallbackProp="children"
                button={
                  <Tag theme="PRIMARY" size="sm">
                    +{ellipsis.length}
                  </Tag>
                }
              >
                {ellipsis.sliced.map((text) => (
                  <Tag title={text} className="min-w-full" key={text}>
                    {text}
                  </Tag>
                ))}
              </HoverCard>
            </TagCell>
          );
        },
      }),
      columnHelper.display({
        header: t("Representatives.table-header.portfolios"),
        cell: ({ row }) => {
          const { portfolios } = row.original;

          const ellipsis = ellipseWithCount(
            portfolios?.map((portfolio) => portfolio.name) || [],
            1,
          );

          return (
            <TagCell maxWidth="max-w-[25rem]" values={ellipsis.slice}>
              <HoverCard
                renderIf={ellipsis.length > 0}
                fallbackProp="children"
                button={
                  <Tag theme="PRIMARY" size="sm">
                    +{ellipsis.length}
                  </Tag>
                }
              >
                {ellipsis.sliced.map((text) => (
                  <Tag title={text} className="min-w-full" key={text}>
                    {text}
                  </Tag>
                ))}
              </HoverCard>
            </TagCell>
          );
        },
      }),
      columnHelper.accessor("email", {
        header: t("Representatives.table-header.email"),
        cell: (info) => info.getValue() || "-",
      }),
      columnHelper.display({
        meta: {
          cellClassName: "sticky right-0 bg-white",
        },
        id: "actions",
        cell: ({ row }) => {
          const { id, role, invitationStatus } = row.original;
          return (
            <div className="flex items-center justify-end gap-4 p-2">
              {(invitationStatus === "ADDED" ||
                invitationStatus === "INVITED") && (
                <Button
                  aclRoles={ACL_ADMINS}
                  type="button"
                  theme={invitationStatus === "ADDED" ? "PRIMARY" : "SECONDARY"}
                  testId={`invite-representative-${id}`}
                  onClick={() => {
                    onClickInvite(row.original.id);
                  }}
                >
                  {invitationStatus === "ADDED"
                    ? t("Representatives.buttons.invite")
                    : t("Representatives.buttons.resend-invite")}
                </Button>
              )}
              <Button
                aclRoles={ACL_ADMINS_MANAGERS}
                type="button"
                theme="ICON"
                testId={`edit-representative-${id}`}
                onClick={() => {
                  // casting as Rep because generic typing doesn't work well with react-table
                  onClickEdit(row.original as Rep);
                }}
              >
                <HiOutlinePencil className="w-4 h-4 text-primaryElectricBlue" />
              </Button>
              {role !== "ORGANIZATION_MAIN_ADMIN" && (
                <Button
                  aclRoles={ACL_ADMINS}
                  type="button"
                  theme="ICON"
                  testId={`delete-representative-${id}`}
                  onClick={() => {
                    // casting as Rep because generic typing doesn't work well with react-table
                    onClickDelete(row.original as Rep);
                  }}
                >
                  <HiOutlineTrash className="w-4 h-4 text-statusRedDark" />
                </Button>
              )}
            </div>
          );
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnHelper],
  );

  return (
    <MRTable
      data={representatives}
      columns={columns}
      cellClassName="border rounded-md"
      noDataLabel={t("Representatives.no-results")}
      displayDataLabel={t("Representatives.footer-display")}
      autoResetPageIndex
    />
  );
}
