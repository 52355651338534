export class ObjectIterator<T extends Record<string, any>> {
  private array: T[] = [];

  constructor(array: T[]) {
    this.array = array;
  }

  mapTo<K extends keyof T>(field: K): T[K][] {
    return this.array.map((i) => i[field]);
  }

  getArray() {
    return this.array;
  }

  mapToIterator<K extends keyof T>(field: K): ObjectIterator<T[K]> {
    return new ObjectIterator(this.mapTo(field));
  }

  filter(filterFn: (item: T, index: number, array: T[]) => boolean) {
    this.array = this.array.filter(filterFn);
    return this;
  }
}
