import { AccountWithCollections } from "@models/old/Account";
import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

export const fetchAccountsSearchByNameEndpoint = {
  path: (organizationId: Organization["id"], name: string) =>
    `/organizations/${organizationId}/accounts/search-by-name/${encodeURI(
      name,
    )}`,
};

type FetchAccountsByParamResponse = AccountWithCollections[];

export const fetchAccountsSearchByName = (
  organizationId: string,
  name: string,
) =>
  axiosInstance
    .get<FetchAccountsByParamResponse>(
      fetchAccountsSearchByNameEndpoint.path(organizationId, name),
    )
    .then((res) => res.data);
