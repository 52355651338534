import React, { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  colsClass: string;
}

export function BlueRow({ children, colsClass }: Props) {
  return (
    <div
      className={`bg-primaryLightElectricBlue mt-2 mb-2 rounded-lg grid ${colsClass}`}
    >
      {children}
    </div>
  );
}
