import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { appointmentHydratingSchema } from "@models/Appointment";
import { brandSchema } from "@models/Brand";
import { collectionSchema } from "@models/Collection";
import { contactSchema } from "@models/Contact";
import { showroomSchema } from "@models/Showroom";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export namespace BookingGetBookedAppointmentsEndpoint {
  export const outputSchema = z.array(
    appointmentHydratingSchema.extend({
      attendees: z.array(
        contactSchema.pick({
          id: true,
          firstName: true,
          lastName: true,
          email: true,
          markets: true,
          phoneNumber: true,
          position: true,
        }),
      ),
      showroom: showroomSchema.pick({
        id: true,
        timezone: true,
        formattedAddress: true,
      }),
      collection: collectionSchema
        .pick({
          id: true,
          name: true,
        })
        .extend({
          brand: brandSchema.pick({
            id: true,
            name: true,
          }),
        })
        .nullable(),
    }),
  );
  export type Output = z.infer<typeof outputSchema>;

  export interface HookParams {
    invitationId: string;
  }

  // @todo add path
  export function path({ invitationId }: HookParams) {
    return `/bookings/${invitationId}/appointments`;
  }
  export const getQueryKeys = (p: HookParams) => getAPIQueryKey(path(p));

  export function call(params: HookParams) {
    return axiosInstance
      .get<Output>(path(params))
      .then((r) => outputSchema.parse(r.data))
      .catch((err) => {
        if (err.issues) {
          console.error(err.issues);
        }
      });
  }

  export function useHook(params: HookParams) {
    return useQuery({
      queryKey: getAPIQueryKey(path(params)),
      queryFn: () => call(params),
    });
  }
}
