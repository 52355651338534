import React from "react";

import { Navigate } from "react-router-dom";

import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import ConditionalRender from "@shared/components/on";

export default function OnboardingDone({
  children,
}: {
  children: React.JSX.Element;
}) {
  const {
    organization: { onboardingStatus },
  } = useOrganizationAppContext();

  return (
    <ConditionalRender
      renderIf={onboardingStatus === "DONE"}
      fallback={<Navigate to="/onboarding/information" />}
    >
      {children}
    </ConditionalRender>
  );
}
