import React from "react";

import InlineTableFilterSelect from "@components/data-entry/inline-filter/select";
import InlineTableFilterText from "@components/data-entry/inline-filter/text";
import InlineTableFilterWrapper from "@components/data-entry/inline-filter/wrapper";
import { usePrefixedTranslation } from "@helpers/Translation";
import { MarketType } from "@models/types/enums";
import { translateMarketType } from "@shared/helpers/translater";

type FilterValues = {
  market: MarketType | "any";
};

type Props = {
  values?: FilterValues;
  onChange: (values: FilterValues) => void;
};

export default function MarketFilter({
  onChange,
  values = { market: "any" },
}: Props) {
  const { pt, t } = usePrefixedTranslation("Invitation.MarketFilter");
  const handleValueChange = (newValue: FilterValues["market"]) => {
    const newValues: FilterValues = {
      ...values,
      market: newValue,
    };
    onChange(newValues);
  };
  return (
    <InlineTableFilterWrapper>
      <InlineTableFilterText>{pt("contact-market-is")}</InlineTableFilterText>
      <InlineTableFilterSelect<FilterValues["market"]>
        value={values.market}
        options={[
          {
            label: pt("any-market"),
            value: "any",
          },
          {
            label: t(translateMarketType("WOMEN")),
            value: "WOMEN",
          },
          {
            label: t(translateMarketType("MEN")),
            value: "MEN",
          },

          {
            label: t(translateMarketType("CHILDREN")),
            value: "CHILDREN",
          },

          {
            label: t(translateMarketType("HOME")),
            value: "HOME",
          },
        ]}
        onChange={handleValueChange}
      />
    </InlineTableFilterWrapper>
  );
}
