import React from "react";

import { addHours, compareAsc, format } from "date-fns";
import { useTranslation } from "react-i18next";
import { HiArrowRight } from "react-icons/hi";
import { MdSettingsBackupRestore } from "react-icons/md";

import { useShowroomForm } from "@app/modules/showroom/form/hook";
import Button from "@components/data-entry/Button";
import TimePicker from "@components/data-entry/TimePicker";

interface HoursIntervalPerDayPickerProps {
  form: ReturnType<typeof useShowroomForm>["form"];
  day: Date;
  defaultOpeningHour: Date;
  defaultClosingHour: Date;
  index: number;
}

function CustomOpeningHoursByDayPicker(props: HoursIntervalPerDayPickerProps) {
  const { form, day, defaultOpeningHour, defaultClosingHour, index } = props;
  const {
    watch,
    formState: { errors },
    setValue,
  } = form;
  const { t } = useTranslation();

  const { openingDays } = form.watch();

  const customOpeningHoursByDay = openingDays.reduce(
    (acc, curr) => ({
      ...acc,
      [format(curr.day, "yyyy-MM-dd")]: {
        openingHour: curr.customOpeningHour,
        closingHour: curr.customClosingHour,
      },
    }),
    {} as Record<
      string,
      { openingHour: Date | null; closingHour: Date | null }
    >,
  );

  const formattedDay = format(day, "yyyy-MM-dd");
  let customOpening = defaultOpeningHour;
  if (
    customOpeningHoursByDay &&
    customOpeningHoursByDay[formattedDay] &&
    customOpeningHoursByDay[formattedDay].openingHour
  ) {
    customOpening = customOpeningHoursByDay[formattedDay].openingHour!;
  }
  let customClosing = defaultClosingHour;
  if (
    customOpeningHoursByDay &&
    customOpeningHoursByDay[formattedDay] &&
    customOpeningHoursByDay[formattedDay].closingHour
  ) {
    customClosing = customOpeningHoursByDay[formattedDay].closingHour!;
  }

  const customized =
    compareAsc(customOpening, defaultOpeningHour) !== 0 ||
    compareAsc(customClosing, defaultClosingHour) !== 0;
  return (
    <div className="flex flex-col xl:flex-row items-start gap-2 xl:items-center justify-between">
      <div className="flex gap-4 w-full justify-between">
        <p
          className={`w-full ${
            customized ? "font-bold text-primaryElectricBlue" : ""
          }`}
        >
          {format(day, "EEEE dd/MM/yy")}
        </p>
        <Button
          type="button"
          theme="LINK"
          onClick={() => {
            setValue(`openingDays.${index}`, {
              day,
              keyAccountsClosingHour: null,
              keyAccountsOpeningHour: null,
              customOpeningHour: null,
              customClosingHour: null,
            });
          }}
          disabled={!customized}
          className={`flex xl:hidden ${
            customized
              ? "text-primaryElectricBlue hover:text-primaryDarkElectricBlue"
              : "text-primaryBlack"
          }`}
        >
          <MdSettingsBackupRestore className="w-5 h-5" />
          Reset
        </Button>
      </div>
      <div className="flex w-full items-center xl:justify-end gap-2">
        {/* Showroom opening hour */}
        <div
          className="w-1/2"
          data-testid={`customOpeningHoursByDay.${formattedDay}.openingHour`}
        >
          <TimePicker
            className="inline"
            placeholder={t(
              "SalesCampaign.showroom.global-informations.customopeningHour.placeholder",
            )}
            name={`customOpeningHoursByDay.${formattedDay}.openingHour`}
            options={{
              firstHourOfDay: 6,
              totalHours: 16,
              timeStep: 15,
            }}
            onChange={(newOpeningHour) => {
              if (!newOpeningHour) {
                return; // should not be able to unselect openingHour
              }
              if (newOpeningHour >= customClosing) {
                setValue(
                  `openingDays.${index}.customClosingHour`,
                  addHours(newOpeningHour, 1),
                );
              } else {
                setValue(
                  `openingDays.${index}.customClosingHour`,
                  customClosing,
                ); // refreshes the input
              }
              setValue(
                `openingDays.${index}.customOpeningHour`,
                newOpeningHour,
              );
            }}
            defaultValue={
              watch(`openingDays.${index}.customOpeningHour`) ??
              defaultOpeningHour
            }
          />
          {errors.openingHour?.message && (
            <p className="text-xs italic text-primaryRed">
              {`${errors.openingHour?.message}`}
            </p>
          )}
        </div>
        <HiArrowRight className="w-5 h-5" />
        {/* Showroom closing hour */}
        <div
          className="w-1/2"
          data-testid={`customOpeningHoursByDay.${index}.customClosingHour`}
        >
          <TimePicker
            className="inline"
            placeholder={t(
              "SalesCampaign.showroom.global-informations.customclosingHour.placeholder",
            )}
            name={`customOpeningHoursByDay.${index}.customClosingHour`}
            onChange={(v) => {
              form.setValue(`openingDays.${index}.customClosingHour`, v);
            }}
            minTime={watch(`openingDays.${index}.customOpeningHour`)}
            options={{
              firstHourOfDay: 6,
              totalHours: 16,
              timeStep: 15,
            }}
            defaultValue={
              watch(`openingDays.${index}.customClosingHour`) ??
              defaultClosingHour
            }
          />
          {errors.closingHour?.message && (
            <p className="text-xs italic text-primaryRed">
              {`${errors.closingHour?.message}`}
            </p>
          )}
        </div>
        <Button
          type="button"
          theme="LINK"
          onClick={() => {
            setValue(`openingDays.${index}`, {
              day,
              keyAccountsClosingHour: null,
              keyAccountsOpeningHour: null,
              customOpeningHour: null,
              customClosingHour: null,
            });
          }}
          disabled={!customized}
          className={`hidden xl:flex ${
            customized
              ? "text-primaryElectricBlue hover:text-primaryDarkElectricBlue"
              : "text-primaryBlack"
          }`}
        >
          <MdSettingsBackupRestore className="w-5 h-5" />
          <span className="hidden 2xl:block">Reset</span>
        </Button>
      </div>
    </div>
  );
}

export default CustomOpeningHoursByDayPicker;
