import { UserRole } from "@models/types/enums";

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  associatedOrganizations: {
    organizationId: string;
    representativeId: string;
  }[];
}

export const isAdmin = (user: User) => user.role === "MODARESA_ADMIN";

export default User;
