import { useMutation } from "@tanstack/react-query";

import { Organization } from "@models/old/Organization";
import { Representative } from "@models/old/Representative";
import axiosInstance from "@services/api/config";

export const putAcceptInvitationRepresentativeEndpoint = {
  path: (
    organizationId: Organization["id"],
    representativeId: Representative["id"],
  ) =>
    `/organizations/${organizationId}/representatives/${representativeId}/accept-invitation`,
};

export function usePutAcceptInvitationRepresentative(organizationId: string) {
  return useMutation({
    mutationFn: (RepresentativeId: string) =>
      axiosInstance.put(
        putAcceptInvitationRepresentativeEndpoint.path(
          organizationId,
          RepresentativeId,
        ),
      ),
  });
}
