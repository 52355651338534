import { useQuery } from "@tanstack/react-query";

import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

export const fetchRepresentativesCountEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/representatives/count`,
};

export const queryKeysFetchRepresentativesCount = (organizationId: string) =>
  fetchRepresentativesCountEndpoint.path(organizationId).split("/");

export const useFetchRepresentativesCount = (organizationId: string) =>
  useQuery({
    queryKey: queryKeysFetchRepresentativesCount(organizationId),
    queryFn: () =>
      axiosInstance
        .get<{
          count: number;
        }>(fetchRepresentativesCountEndpoint.path(organizationId))
        .then((res) => res.data),
  });
