import React, { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";
import { z } from "zod";

import CalloutBox from "@components/feedback/CalloutBox";
import ConfirmModal from "@components/feedback/ConfirmModal";
import Drawer, { useDrawer } from "@components/feedback/Drawer";
import Loading from "@components/feedback/Loading";
import { Portfolio, PortfolioFormData } from "@models/old/Portfolio";
import { canBeASeller } from "@models/old/Representative";
import OrganizationAPI from "@services/api/old/organization/OrganizationAPI";
import {
  invalidatePortfoliosWithCapabilitiesQuery,
  useFetchPortfoliosWithCapabilities,
} from "@services/api/old/organization/fetch-portfolios-with-capabilities";
import { useCreatePortfolio } from "@services/api/portfolios/create-portfolio";
import { useDeletePortfolio } from "@services/api/portfolios/delete-portfolio";
import { useUpdatePortfolio } from "@services/api/portfolios/update-portfolio";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { useGetCollections } from "@shared/collections/collection-hooks";
import { ACL_ADMINS } from "@shared/components/access-control/helpers";

import PortfoliosGroup, { PortfolioGroup } from "./portfolio-group";
import SetupTab from "./setup-tab";
import StructureTab from "./structure-tab";

type TabStep = "SETUP" | "STRUCTURE";

function PortfoliosOverview() {
  const { t } = useTranslation();
  const { organization } = useOrganizationAppContext();

  const [portfolioToDelete, setPortfolioToDelete] = useState<
    Portfolio | undefined
  >();

  const [portfolioFormData, setPortfolioFormData] =
    useState<PortfolioFormData>();

  const drawer = useDrawer({
    backdrop: false,
    needsConfirmation: true,
  });
  const [step, setStep] = useState<TabStep>("SETUP");

  const {
    data: collections,
    isPending: isCollectionLoading,
    isError: isCollectionError,
    error: collectionError,
  } = useGetCollections(organization.id);

  const {
    data: allPortfolios,
    isPending: isPortfolioLoading,
    isError: isPortfolioError,
    error: portfolioError,
  } = useFetchPortfoliosWithCapabilities(organization.id);

  const { data: representatives } = useQuery({
    queryKey: ["representatives"],
    queryFn: async () => OrganizationAPI.getRepresentatives(organization.id),
  });

  const { data: accounts } = useQuery({
    queryKey: ["accounts"],
    queryFn: ({ signal }) =>
      OrganizationAPI.getAccounts(organization.id, signal),
  });

  const { mutateAsync: createPortfolioMutation } = useCreatePortfolio({
    organizationId: organization.id,
  });
  const { mutateAsync: updatePortfolioMutation } = useUpdatePortfolio({
    organizationId: organization.id,
  });
  const { mutateAsync: deletePortfolioMutation } = useDeletePortfolio({
    organizationId: organization.id,
  });

  if (isCollectionError || isPortfolioError) {
    return (
      <div className="flex items-center justify-center my-10 grow">
        {`Oops ${collectionError ?? portfolioError}`}
      </div>
    );
  }

  if (isCollectionLoading || isPortfolioLoading) {
    return (
      <div className="flex items-center justify-center my-10 grow">
        <Loading type="screen" />
      </div>
    );
  }

  const groupedPortfolios: PortfolioGroup[] = (
    organization.brand ? [organization.brand] : organization.partnerBrands
  ).map((partnerBrand) => {
    const brandPortfolios = allPortfolios.filter(
      (p) => p.brandId === partnerBrand.id,
    );

    return {
      title: partnerBrand.name,
      brand: partnerBrand,
      portfolios: brandPortfolios,
    };
  });

  const deletePortfolio = (portfolio: Portfolio) => {
    deletePortfolioMutation({ portfolioId: portfolio.id }).then(() => {
      setPortfolioToDelete(undefined);
      invalidatePortfoliosWithCapabilitiesQuery(organization.id);
    });
  };

  const isSalesCampaignCurrentlyActive = (allPortfolios || []).some(
    (portfolio) => portfolio.deletingBlockedBy.length > 0,
  );

  return (
    <div className="flex flex-col justify-center w-full">
      <div className="flex flex-wrap lg:no-wrap gap-4 w-full mb-6">
        <div className="flex flex-col gap-4 w-full lg:w-auto lg:max-w-[50%] lg:grow">
          {isSalesCampaignCurrentlyActive && (
            <CalloutBox aclRoles={ACL_ADMINS} type="INFORMATIVE">
              {t("Shared.portfolios.overview.info-disabled-toggle")}
            </CalloutBox>
          )}
        </div>
      </div>
      {groupedPortfolios.length > 0 && (
        <div className="flex flex-col gap-y-8 gap-x-6">
          {groupedPortfolios.map((group) => (
            <div key={group.title}>
              <PortfoliosGroup
                group={group}
                onAddPortfolio={() => {
                  drawer.openDrawer();
                  setStep("SETUP");
                  setPortfolioFormData({
                    collectionId: null,
                    name: "",
                    accounts: [],
                    sellers: [],
                    color: "#FF9999",
                    brandId: group.brand.id,
                  });
                }}
                onEditPortfolio={(portfolio) => {
                  drawer.openDrawer();
                  setStep("SETUP");
                  setPortfolioFormData(portfolio);
                }}
                onDeletePortfolio={setPortfolioToDelete}
              />
            </div>
          ))}
        </div>
      )}
      <Drawer
        {...drawer.props}
        name="portfolio-edit"
        backdrop
        size="LARGE"
        isScrollable={false}
        drawerTitle={
          <div className="flex items-center gap-2">
            <span
              className="flex-none inline-block w-6 h-6 rounded-full"
              style={{
                backgroundColor: portfolioFormData?.color || "white",
              }}
            />
            <h2 className="heading-2-mobile lg:heading-2">
              {portfolioFormData?.name
                ? portfolioFormData.name
                : t("Shared.portfolios.new")}
            </h2>
          </div>
        }
      >
        <div className="mb-4">
          <nav
            aria-label="edit portfolio navigation"
            className="flex text-lg border-b-2 cursor-default border-primaryDarkGrey"
          >
            <button
              type="button"
              className={`p-4 -mb-px border-b-2 ${
                step === "SETUP"
                  ? "border-current text-primaryElectricBlue"
                  : "border-transparent cursor-pointer"
              }`}
              onClick={() => setStep("SETUP")}
            >
              Setup
            </button>
            <button
              type="button"
              className={`p-4 -mb-px border-b-2 ${
                step === "STRUCTURE"
                  ? "border-current text-primaryElectricBlue"
                  : "border-transparent"
              }`}
            >
              Structure
            </button>
          </nav>
        </div>
        {step === "SETUP" && portfolioFormData && representatives && (
          <SetupTab
            representatives={representatives.filter((r) => canBeASeller(r))}
            accountPortfolio={portfolioFormData}
            onSave={(item: PortfolioFormData) => {
              setPortfolioFormData({
                ...portfolioFormData,
                color: item.color,
                manager: item.manager,
                name: item.name,
                sellers: item.sellers,
              });
              setStep("STRUCTURE");
            }}
          />
        )}
        {step === "STRUCTURE" && portfolioFormData && accounts && (
          <StructureTab
            accounts={accounts}
            portfolio={portfolioFormData}
            portfolios={allPortfolios}
            onPrevious={() => {
              setStep("SETUP");
            }}
            onSave={(item: PortfolioFormData) => {
              const validation = z
                .object({
                  id: z.string().nullish(),
                  color: z.string(),
                  name: z.string(),
                  collectionId: z.string().nullable(),
                  brandId: z.string(),
                  accounts: z.array(
                    z.object({
                      id: z.string(),
                    }),
                  ),
                  sellers: z.array(
                    z.object({
                      id: z.string(),
                    }),
                  ),
                  manager: z.object({
                    id: z.string(),
                  }),
                })
                .safeParse({
                  ...portfolioFormData,
                  accounts: item.accounts,
                });

              if (validation.success) {
                const {
                  data: { id },
                } = validation;
                const promise = id
                  ? updatePortfolioMutation({
                      portfolio: {
                        ...validation.data,
                        id,
                      },
                    })
                  : createPortfolioMutation({
                      portfolio: validation.data,
                    });
                return promise.then(() => {
                  drawer.closeWithoutConfirmation();
                  setStep("SETUP");
                });
              }

              throw new Error("invalid form data");
            }}
          />
        )}
      </Drawer>
      <ConfirmModal
        show={portfolioToDelete !== undefined}
        onConfirm={
          () =>
            portfolioToDelete
              ? deletePortfolio(portfolioToDelete)
              : setPortfolioToDelete(undefined) // force to undefined in case we had a falsy value for some reason
        }
        onCancel={() => setPortfolioToDelete(undefined)}
        title={t("Shared.portfolios.overview.confirm-delete.title")}
        confirmLabel={t("Common.yes-delete")}
      >
        <Trans
          i18nKey={`Shared.portfolios.overview.confirm-delete.${organization.accountPortfolioType}-body`}
          values={{
            portfolioName: portfolioToDelete?.name,
            collectionName: collections.find(
              (c) => c.id === portfolioToDelete?.collectionId,
            )?.name,
          }}
          components={{
            strong: <strong className="font-bold" />,
          }}
        >
          Shared.portfolios.overview.delete-confirm-body
        </Trans>
      </ConfirmModal>
      <ConfirmModal
        show={drawer.isConfirming}
        onConfirm={() => {
          drawer.closeWithoutConfirmation();
          setStep("SETUP");
        }}
        onCancel={drawer.cancelClosingDrawer}
        title={t("Shared.portfolios.overview.cancel-edit.title")}
        confirmLabel={t("Common.yes-cancel")}
      >
        <Trans
          i18nKey="Shared.portfolios.overview.cancel-edit.body"
          components={{
            br: <br />,
          }}
        >
          Shared.portfolios.overview.cancel-edit.body
        </Trans>
      </ConfirmModal>
    </div>
  );
}

export default PortfoliosOverview;
