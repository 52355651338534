import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { HiOutlineCheck } from "react-icons/hi";
import { toast } from "react-toastify";
import { z } from "zod";

import BookingCardPanel from "@booking/components/appointment/card";
import MultiTextField from "@components/data-entry/MultiTextField";
import CalloutBox from "@components/feedback/CalloutBox";
import { Contact } from "@models/Contact";
import { useSendForwardEmails } from "@services/api/old/booking/send-forward-emails";

interface AppointmentBookingAdditionalEmailsSelectProps {
  contacts: Pick<Contact, "id" | "email">[];
  onSkip: () => void;
  onSubmit: (additionalEmails: string[]) => void;
  invitationId: string;
}
export default function AppointmentBookingAdditionalEmailsSelect({
  contacts,
  onSkip,
  onSubmit,
  invitationId,
}: AppointmentBookingAdditionalEmailsSelectProps) {
  const { t } = useTranslation();
  const [additionalEmails, setAdditionalEmails] = React.useState<string[]>([]);
  const [isInvalidEmail, setIsInvalidEmail] = React.useState<boolean>(false);

  const { mutateAsync: sendForwardEmails } = useSendForwardEmails(invitationId);

  useEffect(() => {
    const updateValidation = () => {
      setIsInvalidEmail(false);
    };
    updateValidation();
  }, [additionalEmails]);

  return (
    <BookingCardPanel
      title={t("Booking.AppointmentBookingAdditionalEmailsSelect.title")}
      step={6}
      onNext={() => {
        sendForwardEmails({
          additionalRecipientEmails: additionalEmails,
          appointmentIds: [],
        })
          .then(() =>
            toast.success(
              t(
                "Booking.AppointmentBookingAdditionalEmailsSelect.invitations-sent",
              ),
            ),
          )
          .then(() => onSubmit(additionalEmails));
      }}
      nextLabel={t(
        "Booking.AppointmentBookingAdditionalEmailsSelect.send-confirmation-emails",
      )}
      nextIcon={<HiOutlineCheck className="w-5 h-5" />}
      onSkip={onSkip}
      isNextDisabled={additionalEmails.length === 0}
    >
      <div className="flex flex-col justify-start w-full gap-4">
        <CalloutBox className="w-full" type="SUCCESS">
          <p>{t("Booking.AppointmentBookingAdditionalEmailsSelect.success")}</p>
          {contacts && (
            <ul className="pl-6 mt-3">
              {contacts.map((contact) => (
                <li className="list-disc" key={contact.id}>
                  {contact.email}
                </li>
              ))}
            </ul>
          )}
        </CalloutBox>
        <div className="flex flex-col gap-4 mt-2">
          <strong className="text-base font-bold">
            {t(
              "Booking.AppointmentBookingAdditionalEmailsSelect.any-new-email",
            )}
          </strong>
          <div>
            <label className="flex flex-col" htmlFor="email">
              {t(
                "Booking.AppointmentBookingAdditionalEmailsSelect.additional-email",
              )}
              <span className="text-xs text-grey">
                {t(
                  "Booking.AppointmentBookingAdditionalEmailsSelect.additional-email-description",
                )}
              </span>
            </label>
            <div className="flex flex-col w-full lg:max-w-lg mt-2">
              <MultiTextField
                id="email"
                className={isInvalidEmail ? "border-primaryRed" : ""}
                values={additionalEmails}
                onEnter={(rawEmail: string) => {
                  const zodEmail = z.string().email().safeParse(rawEmail);
                  if (zodEmail.success) {
                    setAdditionalEmails([
                      ...new Set([...additionalEmails, zodEmail.data]),
                    ]);
                  } else {
                    setIsInvalidEmail(true);
                  }
                }}
                onRemove={(value: string) =>
                  setAdditionalEmails(
                    additionalEmails.filter((v) => v !== value),
                  )
                }
                placeholder={t("Common.form.email-placeholder")}
              />
              {isInvalidEmail && (
                <p className="text-xs italic text-primaryRed">
                  {t("Common.form.this-is-not-valid-email")}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </BookingCardPanel>
  );
}
