import React from "react";

import { useShowroomStorage } from "@app/modules/showroom/form/helpers";
import {
  ValidShowroomFormData,
  defaultFormValues,
} from "@app/modules/showroom/form/hook";
import { CreateShowroomEndpoint } from "@services/api/showroom/create-showroom";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";

import ShowroomForm from "./ShowroomForm";

/**
 * This component is responsible for :
 * - storing the showroom creation state in local storage
 * - submitting the form to the create endpoint
 */
export default function ShowroomCreate({ onSubmit }: { onSubmit: () => void }) {
  const [storedData, setStoredData] = useShowroomStorage();

  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const { mutateAsync: apiCreateShowroom, status: createShowroomStatus } =
    CreateShowroomEndpoint.useHook({
      organizationId,
    });

  const handleCreateShowroom = (data: ValidShowroomFormData) =>
    apiCreateShowroom({
      files: data.newAttachments,
      data: {
        lunchBreakStartingHour: data.lunchStartingHour,
        lunchBreakEndingHour: data.lunchEndingHour,
        addressComponents: data.address.addressComponents,
        appointmentTypesDuration: data.appointmentDurations,
        appointmentIncrement: data.increment,
        name: data.name,
        timezone: data.timezone,
        directions: data.directions,
        openingDays: data.openingDays,
        openingHour: data.openingHour,
        closingHour: data.closingHour,
        collections: data.collections,
        appointmentTypes: data.appointmentTypes,
        sellers: data.sellers,
        appointmentFormats: data.appointmentFormats,
        specialRequestsAllowed: data.specialRequestsAllowed,
        numberOfSeats: data.numberOfSeats,
        lastAllowedBookingDate: data.appointmentDeadline,
        formattedAddress: data.address.formattedAddress,
        postalCode: data.address.postalCode,
        city: data.address.city,
        countryCode: data.address.countryCode,
        year: data.year,
        season: data.season,
      },
    }).then(() => {
      setStoredData(defaultFormValues);
      onSubmit();
    });

  return (
    <ShowroomForm
      defaultValues={storedData}
      onStepChange={setStoredData}
      onSubmit={handleCreateShowroom}
      submitStatus={createShowroomStatus}
    />
  );
}
