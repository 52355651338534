import React, { useEffect, useMemo, useState } from "react";

import { useQueries } from "@tanstack/react-query";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import queryClient from "@app/queryClient";
import SpecialRequestItem from "@calendar/components/special-request-item";
import Popover from "@components/data-display/Popover";
import Tabs, { TabsProps, useTabs } from "@components/data-display/Tabs";
import Tag from "@components/data-display/Tag";
import Button from "@components/data-entry/Button";
import TextField from "@components/data-entry/TextField";
import ConfirmModal from "@components/feedback/ConfirmModal";
import { Showroom } from "@models/Showroom";
import { SpecialRequestOldModel } from "@models/SpecialRequest";
import {
  fetchSpecialRequestsQuery,
  queryKeysFetchSpecialRequestsByShowroom,
} from "@services/api/old/booking/fetch-special-requests-by-showroom";
import {
  SpecialRequestConfirmInput,
  usePostConfirmSpecialRequest,
} from "@services/api/old/booking/post-confirm-special-request";
import {
  SpecialRequestDeclineInput,
  usePostDeclineSpecialRequest,
} from "@services/api/old/booking/post-decline-special-request";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import LogService from "@services/log/service";
import { componentAccessControl } from "@shared/components/access-control";
import { ACL_ADMINS_AGENTS_MANAGERS } from "@shared/components/access-control/helpers";

function findShowroom<
  SR extends { showroomId: string },
  S extends { id: string },
>(specialRequest: SR, showrooms: S[]) {
  const showroom = showrooms.find((s) => s.id === specialRequest.showroomId);
  if (!showroom) {
    throw new Error("Special request showroom not found");
  }
  return showroom;
}

interface SpecialRequestsProps {
  showrooms: Pick<Showroom, "id" | "timezone">[];
  selectedDate: Date;
  setSelectedDate: (d: Date) => void;
}

function SpecialRequestsList({
  showrooms,
  selectedDate,
  setSelectedDate,
}: SpecialRequestsProps) {
  const { t } = useTranslation();
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const [selectedSpecialRequest, setSelectedSpecialRequest] = useState<
    SpecialRequestOldModel | undefined
  >();
  const [isConfirmDeclineOpen, setIsConfirmDeclineOpen] =
    useState<boolean>(false);
  const [declineReason, setDeclineReason] = useState<string>();

  const { mutateAsync: postDeclineSpecialRequest, isPending: isDeclining } =
    usePostDeclineSpecialRequest(organizationId);

  const { mutateAsync: postConfirmSpecialRequest, isPending: isAccepting } =
    usePostConfirmSpecialRequest(organizationId);

  const specialRequestsQueries = useQueries({
    queries: showrooms.map((s) =>
      fetchSpecialRequestsQuery(organizationId, s.id),
    ),
  });

  const pendingSpecialRequests = specialRequestsQueries
    .flatMap((q) => q.data || [])
    .filter((req) => req.status === "PENDING")
    .sort(
      (a, b) => a.startTime.realDate.getTime() - b.startTime.realDate.getTime(),
    );

  const uniqueDates = useMemo(() => {
    const dateSet = new Set<number>();
    pendingSpecialRequests.forEach((req: SpecialRequestOldModel) => {
      const specialRequestShowroom = findShowroom(req, showrooms);
      const dateKey = new Date(
        req.startTime
          .toLocalDate(specialRequestShowroom.timezone)
          .getFullYear(),
        req.startTime.toLocalDate(specialRequestShowroom.timezone).getMonth(),
        req.startTime.toLocalDate(specialRequestShowroom.timezone).getDate(),
      );
      dateSet.add(dateKey.getTime());
    });
    return Array.from(dateSet);
  }, [pendingSpecialRequests, showrooms]);

  const tabs: TabsProps["items"] = uniqueDates.map((date: number) => ({
    label: format(new Date(date), "MMM do"),
    tab: date.toString(),
  }));

  const { tab: currentTab, setTab } = useTabs({
    initial: tabs.length > 0 ? tabs[0].tab : "none",
  });

  // Set the first tab as active if there is no active tab, also when tabs exists
  useEffect(() => {
    if (currentTab === "none" && tabs.length > 0) {
      setTab(tabs[0].tab);
    }
  }, [tabs, currentTab, setTab]);

  useEffect(() => {
    const tabForSelectedDate = tabs.find(
      (tab) => tab.tab === selectedDate.getTime().toString(),
    );
    if (tabForSelectedDate) {
      setTab(tabForSelectedDate.tab);
    }
  }, [selectedDate, setTab, tabs]);

  const filteredSpecialRequests = useMemo(
    () =>
      pendingSpecialRequests?.filter((request) => {
        const specialRequestShowroom = findShowroom(request, showrooms);
        const requestDate = request.startTime.toLocalDate(
          specialRequestShowroom.timezone,
        );
        return currentTab !== "none"
          ? isSameDay(requestDate, new Date(Number(currentTab)))
          : false;
      }),
    [currentTab, pendingSpecialRequests, showrooms],
  );

  const handleDeclineSpecialRequest = () => {
    if (!selectedSpecialRequest) return;

    const params: SpecialRequestDeclineInput = {
      body: {
        reason: declineReason || null,
      },
      specialRequestId: selectedSpecialRequest.id,
    };

    postDeclineSpecialRequest(params)
      .then(async (result) => {
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchSpecialRequestsByShowroom(
            organizationId,
            result.showroomId,
          ),
        });
        setIsConfirmDeclineOpen(false);
        toast.success(
          t("Calendar.special-requests.confirm-special-request-declined"),
        );
      })
      .catch((err) => {
        setIsConfirmDeclineOpen(false);
        LogService.error(err);
      })
      .finally(() => {
        setSelectedSpecialRequest(undefined);
      });
  };

  const handleAcceptSpecialRequest = (sellerId: string, language: string) => {
    if (!selectedSpecialRequest) return;

    const params: SpecialRequestConfirmInput = {
      body: {
        sellerId,
        selectedLanguage: language,
      },
      specialRequestId: selectedSpecialRequest.id,
    };

    postConfirmSpecialRequest(params)
      .then(async (result) => {
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchSpecialRequestsByShowroom(
            organizationId,
            result.showroomId,
          ),
        });
        toast.success(
          t("Calendar.special-requests.confirm-special-request-accepted"),
        );
      })
      .catch((err) => {
        LogService.error(err);
      });
  };

  return (
    <>
      <Popover
        cardClassname="w-80"
        button={
          <Button theme="SECONDARY">
            {t("Calendar.special-requests.special-request-button")}
            <Tag theme="PRIMARY" size="sm">
              {pendingSpecialRequests?.length}
            </Tag>
          </Button>
        }
        placement="bottom-end"
      >
        <>
          <Tabs
            aria-label="tabs for special requests items"
            className="mt-4 border-b"
            items={tabs}
            tabWidth={108}
            tab={currentTab}
            handleClick={(item) => {
              setSelectedDate(new Date(Number(item.tab)));
            }}
          />
          <div className="flex flex-col gap-4 max-h-[34rem] overflow-y-auto no-scrollbar p-4">
            {filteredSpecialRequests?.map((specialRequest) => (
              <SpecialRequestItem
                isLoading={isAccepting || isDeclining}
                key={specialRequest.id}
                showroom={findShowroom(specialRequest, showrooms)}
                specialRequest={specialRequest}
                onConfirm={(specialRequestParam, sellerId, language) => {
                  setSelectedSpecialRequest(specialRequestParam);
                  handleAcceptSpecialRequest(sellerId, language);
                }}
                onDecline={(specialRequestParam) => {
                  setSelectedSpecialRequest(specialRequestParam);
                  setIsConfirmDeclineOpen(true);
                }}
              />
            ))}
            {filteredSpecialRequests?.length === 0 && (
              <div className="text-center text-primaryDarkGrey">
                {t("Calendar.special-requests.no-requests")}
              </div>
            )}
          </div>
        </>
      </Popover>
      <ConfirmModal
        show={isConfirmDeclineOpen}
        title={t("Calendar.special-requests.decline-title")}
        confirmLabel={t("Calendar.special-requests.decline-confirm-button")}
        cancelLabel={t("Components.confirm-modal.cancel")}
        theme="DANGER"
        onCancel={() => {
          setIsConfirmDeclineOpen(false);
          setSelectedSpecialRequest(undefined);
        }}
        onConfirm={handleDeclineSpecialRequest}
      >
        <div>
          <Trans
            i18nKey="Calendar.special-requests.decline-content"
            components={{
              bold: <strong className="font-bold" />,
              break: <br />,
            }}
          >
            Calendar.special-requests.decline-content
          </Trans>
        </div>
        <div className="mt-4">
          <label className="font-medium text-base" htmlFor="reason">
            {t("Calendar.special-requests.decline-reason-label")}
          </label>
          <TextField
            id="reason"
            name="reason"
            value={declineReason}
            onChange={(value) => setDeclineReason(value)}
            placeholder={t(
              "Calendar.special-requests.decline-reason-placeholder",
            )}
          />
        </div>
      </ConfirmModal>
    </>
  );
}

export default componentAccessControl(
  ACL_ADMINS_AGENTS_MANAGERS,
  SpecialRequestsList,
);
