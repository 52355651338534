import { useMutation } from "@tanstack/react-query";

import { Contact } from "@models/Contact";
import { Organization } from "@models/Organization";
import axiosInstance from "@services/api/config";

export const putRestoreContactEndpoint = {
  path: (organizationId: Organization["id"], contactId: Contact["id"]) =>
    `/organizations/${organizationId}/contacts/${contactId}/restore`,
};

export function usePutRestoreContact(organizationId: string) {
  return useMutation({
    mutationFn: (contactId: string) =>
      axiosInstance.put(
        putRestoreContactEndpoint.path(organizationId, contactId),
      ),
  });
}
