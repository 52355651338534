import { TagProps } from "@components/data-display/Tag";
import { PortfolioWithManager } from "@models/old/Portfolio";

import { Contact } from "./Contact";

export const AccountStatusList = [
  "PROSPECT",
  "ACTIVE",
  "NOT_ACCESSIBLE",
] as const;
export type AccountStatus = (typeof AccountStatusList)[number];

const AccountTypeList = [
  "BOUTIQUE",
  "DEPARTMENT",
  "FRANCHISE",
  "MULTIBRAND",
  "ONLINE",
] as const;
export type AccountType = (typeof AccountTypeList)[number];

export const AccountsStatusColors: Record<AccountStatus, TagProps["theme"]> = {
  ACTIVE: "GREEN",
  PROSPECT: "BLUE",
  NOT_ACCESSIBLE: "RED",
};

export interface Account {
  id: string;
  name: string;
}

export interface AccountUpdate {
  id: string;
}

export interface AccountDetails {
  id: string;
  name: string;
  status: AccountStatus;
  city: string;
  countryCode: string;
  contacts?: Contact[];
}

export interface AccountWithCollections {
  id: string;
  name: string;
  type: AccountType;
  status: AccountStatus;
  isKeyClient?: boolean;
  city: string;
  countryCode: string;
  collections?: {
    id?: string;
    name: string;
    status: AccountStatus;
    brand: {
      id: string;
      name: string;
    };
  }[];
  portfolios?: PortfolioWithManager[];
}

export interface AccountUpsertData {
  id?: string;
  name: string;
  type: AccountType;
  status: AccountStatus;
  city: string;
  countryCode: string;
  continentCode: string;
  collections: {
    id: string;
    status: AccountStatus;
  }[];
  portfolios: {
    id: string;
  }[];
}

export const isAccountDetails = (
  account: AccountUpdate | AccountDetails | undefined,
): account is AccountDetails =>
  account !== undefined && (account as AccountDetails).city !== undefined;
