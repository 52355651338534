import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { accountSchema } from "@models/Account";
import { appointmentHydratingSchema } from "@models/Appointment";
import { brandSchema } from "@models/Brand";
import { collectionSchema } from "@models/Collection";
import { contactSchema } from "@models/Contact";
import { meetingReportSchema } from "@models/MeetingReport";
import {
  organizationRepresentativeSchema,
  representativeSchema,
} from "@models/OrganizationRepresentative";
import { portfolioSchema } from "@models/Portfolio";
import { showroomSchema } from "@models/Showroom";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export namespace GetBuyerAppointment {
  export const outputSchema = appointmentHydratingSchema
    .pick({
      id: true,
      bookedThrough: true,
      title: true,
      startTime: true,
      endTime: true,
      type: true,
      format: true,
      accountOtb: true,
      virtualMeetingApp: true,
      status: true,
      warnings: true,
    })
    .extend({
      showroom: showroomSchema.pick({
        id: true,
        organizationId: true,
        formattedAddress: true,
        timezone: true,
        city: true,
        countryCode: true,
        directions: true,
        season: true,
        year: true,
      }),
      collection: collectionSchema
        .pick({
          id: true,
          name: true,
        })
        .extend({
          brand: brandSchema.pick({
            id: true,
            name: true,
          }),
        }),
      account: accountSchema.pick({
        id: true,
        name: true,
        city: true,
        countryCode: true,
        status: true,
        type: true,
        isKeyClient: true,
      }),
      attendees: z.array(
        contactSchema.pick({
          id: true,
          firstName: true,
          lastName: true,
          email: true,
          phoneNumber: true,
          position: true,
          markets: true,
        }),
      ),
      seller: representativeSchema.pick({
        id: true,
        virtualMeetingAppLinks: true,
        firstName: true,
        lastName: true,
        email: true,
        languages: true,
      }),
      meetingReport: meetingReportSchema.pick({}).nullish(),
      collectionInterests: z.array(z.any()), // @todo not use any
      portfolios: portfolioSchema
        .pick({
          id: true,
          brandId: true,
          name: true,
          color: true,
          collectionId: true,
        })
        .extend({
          manager: representativeSchema.pick({
            id: true,
            firstName: true,
            lastName: true,
            role: true,
            email: true,
          }),
          sellers: z.array(
            organizationRepresentativeSchema.pick({
              id: true,
              firstName: true,
              lastName: true,
              role: true,
              virtualMeetingApps: true,
            }),
          ),
        }),
    });

  export type Output = z.infer<typeof outputSchema>;

  // @todo add hook params
  export interface HookParams {
    appointmentId: string;
  }

  export function path({ appointmentId }: HookParams) {
    return `/buyer/appointments/${appointmentId}`;
  }

  export const getQueryKeys = (p: HookParams) => getAPIQueryKey(path(p));

  export function call(params: HookParams) {
    return axiosInstance
      .get<Output>(path(params))
      .then((r) => outputSchema.parse(r.data));
  }

  export function useHook(params: HookParams) {
    return useQuery({
      queryKey: getQueryKeys(params),
      queryFn: () => call(params),
    });
  }
}
