/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

import { HiExclamation, HiOutlineChevronRight } from "react-icons/hi";
import { HiOutlineCheckCircle, HiTrash, HiXMark } from "react-icons/hi2";

import Tag from "@components/data-display/Tag";
import Breakpoint, { useIsMobile } from "@components/layout/Breakpoint";
import { usePrefixedTranslation } from "@helpers/Translation";

interface AppointmentCollectionItemProps {
  title: string;
  subtitle?: string;
  id: string;
  tags: string[];
  isSelected: boolean;
  onSelectTab: () => void;
  onDelete?: () => void;
  children?: React.ReactNode;
  status: "OK" | "PENDING" | "CANCELLED";
}

export default function AppointmentCollectionItem({
  title,
  id,
  tags,
  isSelected,
  onSelectTab,
  children,
  status,
  subtitle,
  onDelete,
}: AppointmentCollectionItemProps) {
  const { pt } = usePrefixedTranslation("");
  const isMobile = useIsMobile();

  const selectedBorderStyle = isMobile
    ? "border-b-4 border-b-primaryElectricBlue"
    : "border-l-4 border-l-primaryElectricBlue";
  const unselectedBorderStyle = isMobile
    ? ""
    : "border-b border-b-primaryLightGrey";

  const selectedStyle = `bg-primaryLightElectricBlue ${selectedBorderStyle}`;

  return (
    <li
      onClick={() => onSelectTab()}
      aria-label={`collection-${id}`}
      className={`flex flex-col cursor-pointer p-4 ${
        isSelected ? selectedStyle : unselectedBorderStyle
      }`}
    >
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-1">
          <span
            className={`font-medium ${
              isSelected ? "text-primaryElectricBlue" : ""
            }`}
          >
            {title}
          </span>
          <span className="text-primaryDarkGrey text-xs">
            {tags.join(" | ")}
          </span>
          {subtitle ? (
            <Breakpoint from="md">
              <Tag className="text-sm mt-2">{subtitle}</Tag>
            </Breakpoint>
          ) : null}
        </div>
        <div className="flex justify-end items-center gap-1">
          {status !== "OK" && onDelete && (
            <button
              type="button"
              aria-label={pt("cancel appointment")}
              onClick={() => onDelete()}
              className="text-primaryDarkGrey"
            >
              <HiTrash className="w-6 h-6 fill-statusRedDark" />
            </button>
          )}
          {status === "OK" && (
            <HiOutlineCheckCircle className="w-6 h-6 text-statusGreenDark" />
          )}
          {status === "PENDING" && (
            <HiExclamation className="w-6 h-6 fill-statusOrangeDark" />
          )}
          {status === "CANCELLED" && (
            <HiXMark className="w-6 h-6 text-statusRedDark" />
          )}
          <Breakpoint from="md">
            <HiOutlineChevronRight className="w-6 h-6" />
          </Breakpoint>
        </div>
      </div>
      {children}
    </li>
  );
}
