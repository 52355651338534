import React from "react";

import { PhoneNumberUtil } from "google-libphonenumber";
import { IoIosClose } from "react-icons/io";
import { CountryIso2, PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

interface PhoneNumberInputProps {
  id?: string;
  placeholder?: string;
  value: string | undefined;
  initialCountry?: CountryIso2;
  onChange: (value: string | null) => void;
  readonly?: boolean;
}

function PhoneNumberInput(props: PhoneNumberInputProps) {
  const {
    id,
    placeholder,
    value,
    initialCountry,
    onChange,
    readonly = false,
  } = props;

  const clearField = () => {
    onChange(null);
  };

  return (
    <div className="w-full text-gray-700">
      <div
        className={`flex justify-between rounded-lg form-input ${
          readonly ? "bg-primaryLightestGrey" : ""
        }`}
      >
        <PhoneInput
          disabled={readonly}
          placeholder={placeholder}
          inputProps={{
            title: "enter phone number",
            id,
            "aria-labelledby": id,
          }}
          className={`w-full gap-2 ${readonly ? "bg-primaryLightestGrey" : ""}`}
          inputClassName="grow"
          inputStyle={{
            border: "0px",
            fontFamily: "NeueMontreal-Regular",
          }}
          defaultCountry={initialCountry || "fr"}
          countrySelectorStyleProps={{
            buttonStyle: {
              border: "0px",
            },
          }}
          disableDialCodePrefill
          value={value}
          onChange={(phone) => onChange(phone)}
        />
        {!readonly && (
          <button
            disabled={readonly}
            type="button"
            name="clear-textfield-phonenumber"
            title="clear phone number"
            className="cursor-pointer"
            onClick={clearField}
          >
            <IoIosClose className="w-8 h-8" />
          </button>
        )}
      </div>
    </div>
  );
}

PhoneNumberInput.defaultProps = {
  initialCountry: "fr",
  id: undefined,
  placeholder: "",
};

export default PhoneNumberInput;
