import { useMutation } from "@tanstack/react-query";

import { ContactUpsertData } from "@models/old/Contact";
import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

export const putContactEndpoint = {
  path: (organizationId: Organization["id"], contactId: string) =>
    `/organizations/${organizationId}/contacts/${contactId}`,
};

export const postContactEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/contacts`,
};

export function useUpsertContact(
  organizationId: Organization["id"],
  contactId?: string,
) {
  return useMutation({
    mutationFn: (contact: ContactUpsertData) =>
      contactId
        ? axiosInstance.put(
            putContactEndpoint.path(organizationId, contactId),
            contact,
          )
        : axiosInstance.post(postContactEndpoint.path(organizationId), contact),
  });
}
