import React from "react";

import { RadioGroup } from "@headlessui/react";
import { useTranslation } from "react-i18next";

interface RadioOptionComponentProps<T> {
  checked: boolean;
  active: boolean;
  option: Omit<OptionItem<T>, "component">;
}

function RadioOptionComponent<T extends string>({
  active,
  checked,
  option,
}: RadioOptionComponentProps<T>) {
  const checkedClassNames = `group ${
    checked
      ? "checked border-primaryElectricBlue bg-primaryLightElectricBlue"
      : ""
  }`;
  const activeClassNames = active ? "active" : "";
  const disabledClassNames = !option.disabled
    ? `cursor-pointer hover:bg-primaryLightElectricBlue`
    : "cursor-not-allowed opacity-50";

  const { t } = useTranslation();
  return (
    <div
      title={t("Common.unavailable-option")}
      className={`flex items-center border border-primaryGrey rounded-lg px-6 py-4 ${disabledClassNames} ${checkedClassNames} ${activeClassNames}`}
    >
      <i className="group-[.checked]:border-4 group-[.checked]:border-primaryElectricBlue transition-all duration-200 border-box block min-w-[0.75rem] min-h-[0.75rem] bg-white rounded-full border border-primaryGrey mr-4" />
      <div>{option.label}</div>
    </div>
  );
}

export interface OptionItem<T> {
  value: T;
  disabled?: boolean;
  label: React.ReactNode;
  component?: React.FC<RadioOptionComponentProps<T>>;
}

interface RadioGroupInputProps<T> {
  name: string;
  value: T | undefined;
  onChange: (newValue: T) => void;
  options: OptionItem<T>[];
}

export default function RadioGroupInput<T extends string>(
  props: RadioGroupInputProps<T>,
) {
  const { value, onChange, options, name } = props;
  return (
    <RadioGroup
      aria-label={name}
      value={value}
      onChange={onChange}
      className="flex flex-col gap-4 md:flex-row"
    >
      {options.map(
        ({ component: OptionComponent = RadioOptionComponent, ...option }) => (
          <RadioGroup.Option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {({ active, checked }) => (
              <RadioGroup.Label>
                <OptionComponent
                  option={option}
                  active={active}
                  checked={checked}
                />
              </RadioGroup.Label>
            )}
          </RadioGroup.Option>
        ),
      )}
    </RadioGroup>
  );
}
