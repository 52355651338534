import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { accountSchema } from "@models/Account";
import { brandSchema } from "@models/Brand";
import { budgetSchema } from "@models/Budget";
import { collectionSchema } from "@models/Collection";
import { contactSchema } from "@models/Contact";
import { organizationSchema } from "@models/Organization";
import {
  representativeSchema,
  virtualMeetingAppLinksSchema,
  virtualMeetingAppSchema,
} from "@models/OrganizationRepresentative";
import { portfolioSchema } from "@models/Portfolio";
import { showroomSchema } from "@models/Showroom";
import { AccountAppointmentTypeList } from "@models/types/enums";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export namespace BookingGetInvitationInfoEndpoint {
  export const outputSchema = z.object({
    organization: organizationSchema.pick({
      accountPortfolioType: true,
      banner: true,
      id: true,
      name: true,
    }),
    contact: contactSchema.extend({
      account: accountSchema
        .pick({
          id: true,
          isKeyClient: true,
          name: true,
        })
        .extend({
          salesBudgets: z.array(z.any()), // @todo define a better schema
        }),
    }),
    portfolios: z.array(
      portfolioSchema
        .pick({
          brandId: true,
          collectionId: true,
          id: true,
          name: true,
          color: true,
        })
        .extend({
          sellers: z.array(
            representativeSchema
              .pick({
                id: true,
                firstName: true,
                lastName: true,
                role: true,
              })
              .extend({
                virtualMeetingApps: virtualMeetingAppSchema,
              }),
          ),
          manager: representativeSchema.pick({
            id: true,
            firstName: true,
            lastName: true,
            email: true,
            role: true,
          }),
        }),
    ),
    invitedColleagues: z.array(
      contactSchema.pick({
        id: true,
        firstName: true,
        lastName: true,
        email: true,
        phoneNumber: true,
      }),
    ),
    showrooms: z.array(
      showroomSchema
        .omit({
          addressComponents: true,
        })
        .extend({
          sellers: z.array(
            representativeSchema
              .pick({
                id: true,
                firstName: true,
                lastName: true,
                languages: true,
              })
              .extend({
                virtualMeetingAppLinks: virtualMeetingAppLinksSchema.nullable(),
                virtualMeetingApps: virtualMeetingAppSchema,
                appointmentTypes: z.array(z.enum(AccountAppointmentTypeList)),
              }),
          ),
          collections: z.array(
            collectionSchema.extend({
              brand: brandSchema,
              budgets: budgetSchema
                .pick({
                  accountId: true,
                  collectionId: true,
                  season: true,
                  year: true,
                  otb: true,
                })
                .nullable(),
            }),
          ),
        }),
    ),
  });

  export type Output = z.infer<typeof outputSchema>;

  export interface HookParams {
    invitationId: string;
  }

  export function path({ invitationId }: HookParams) {
    return `/bookings/${invitationId}`;
  }
  export const getQueryKeys = (p: HookParams) => getAPIQueryKey(path(p));

  export function call(params: HookParams) {
    return axiosInstance
      .get<Output>(path(params))
      .then((r) => outputSchema.parse(r.data));
  }

  export function useHook(params: HookParams) {
    return useQuery({
      queryKey: getAPIQueryKey(path(params)),
      queryFn: () => call(params),
    });
  }
}
