import {
  addMinutes,
  getHours,
  getMinutes,
  isSameDay,
  max,
  min,
  set,
  setMinutes,
  startOfDay,
} from "date-fns";
import addHours from "date-fns/addHours";

import { Showroom } from "@models/Showroom";
import { isOpenDay } from "@models/old/Showroom";
import { END_OF_DAY, START_OF_DAY } from "@models/types/Constant";
import { DateInterval } from "@models/types/DateInterval";

type PartialShowroom = Pick<
  Showroom,
  | "openingHour"
  | "closingHour"
  | "lunchBreakEndingHour"
  | "lunchBreakStartingHour"
  | "openingDays"
>;

export type OpenDayParams = {
  type: "open_day";
  calendar: DateInterval;
  showroom: DateInterval;
  lunch: DateInterval | null;
  customOpeningHour: Date | null;
  customClosingHour: Date | null;
};

export type ClosedDayParams = {
  type: "closed_day";
  calendar: DateInterval;
};

export type DayParams = OpenDayParams | ClosedDayParams;

function getCustomHour(
  showroom: PartialShowroom,
  selectedDay: Date,
  type: "openingHour" | "closingHour",
): Date | null {
  const openingDay = showroom.openingDays?.find((d) =>
    isSameDay(d.day, selectedDay),
  );
  const customHour =
    type === "openingHour"
      ? openingDay?.customOpeningHour
      : openingDay?.customClosingHour;

  if (customHour) {
    return set(customHour, {
      year: selectedDay.getFullYear(),
      month: selectedDay.getMonth(),
      date: selectedDay.getDate(),
    });
  }
  return null;
}

export function buildShowroomDayParams(
  selectedDay: Date,
  showroom?: PartialShowroom,
): DayParams {
  if (showroom === undefined) {
    return {
      type: "closed_day",
      calendar: {
        start: addMinutes(startOfDay(selectedDay), START_OF_DAY * 60),
        end: addMinutes(startOfDay(selectedDay), END_OF_DAY * 60),
      },
    };
  }

  // compute opening/closing time in the calendar
  const openingDatetime = addMinutes(
    startOfDay(selectedDay),
    getHours(showroom.openingHour) * 60 + getMinutes(showroom.openingHour),
  );
  const closingDatetime = addMinutes(
    startOfDay(selectedDay),
    getHours(showroom.closingHour) * 60 + getMinutes(showroom.closingHour),
  );

  const startDatetime = addHours(setMinutes(openingDatetime, 0), -2);
  const endDatetime = addHours(setMinutes(closingDatetime, 0), 2);

  if (!isOpenDay(showroom, selectedDay)) {
    return {
      type: "closed_day",
      calendar: {
        start: startDatetime,
        end: endDatetime,
      },
    };
  }

  let lunchBreakInterval = null;
  if (showroom.lunchBreakStartingHour && showroom.lunchBreakEndingHour) {
    const lunchStartHourInMinutes =
      getHours(showroom.lunchBreakStartingHour) * 60 +
      getMinutes(showroom.lunchBreakStartingHour);
    // compute lunch start/end time in the calendar
    const lunchBreakStartDatetime = addMinutes(
      startOfDay(selectedDay),
      lunchStartHourInMinutes,
    );
    // compute lunch start/end hour in minutes
    const lunchEndHourInMinutes =
      getHours(showroom.lunchBreakEndingHour) * 60 +
      getMinutes(showroom.lunchBreakEndingHour);
    const lunchBreakEndDatetime = addMinutes(
      startOfDay(selectedDay),
      lunchEndHourInMinutes,
    );
    lunchBreakInterval = {
      start: lunchBreakStartDatetime,
      end: lunchBreakEndDatetime,
    };
  }

  const customOpeningHour = getCustomHour(showroom, selectedDay, "openingHour");
  const customClosingHour = getCustomHour(showroom, selectedDay, "closingHour");

  return {
    type: "open_day",
    calendar: {
      start: startDatetime,
      end: endDatetime,
    },
    showroom: {
      start: customOpeningHour || openingDatetime,
      end: customClosingHour || closingDatetime,
    },
    lunch: lunchBreakInterval,
    customOpeningHour,
    customClosingHour,
  };
}

export function buildDayParams(
  selectedDay: Date,
  showrooms: PartialShowroom[],
): DayParams {
  const allDayParams = showrooms.map((s) =>
    buildShowroomDayParams(selectedDay, s),
  );

  const isOneShowroomOpen = allDayParams.some((p) => p.type === "open_day");
  const type = isOneShowroomOpen ? "open_day" : "closed_day";

  const calendarStart = min(allDayParams.flatMap((p) => p.calendar.start));
  const calendarEnd = max(allDayParams.flatMap((p) => p.calendar.end));

  if (type === "closed_day") {
    return {
      type,
      calendar: {
        start: calendarStart,
        end: calendarEnd,
      },
    };
  }

  const openShowroomDayParams = allDayParams.filter(
    (p) => p.type === "open_day",
  ) as OpenDayParams[];
  const showroomStart = min(
    openShowroomDayParams.flatMap((p) => p.showroom.start),
  );
  const showroomEnd = max(openShowroomDayParams.flatMap((p) => p.showroom.end));
  return {
    type,
    calendar: {
      start: calendarStart,
      end: calendarEnd,
    },
    showroom: {
      start: showroomStart,
      end: showroomEnd,
    },
    customOpeningHour: null,
    customClosingHour: null,
    lunch: null,
  };
}
