import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import { organizationSchema } from "@models/Organization";
import { GetAllBrandsEndpoint } from "@services/api/brands/get-all-brands";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";
import { GetOrganizationEndpoint } from "@services/api/organization/get-organization";

// @todo rename endpoint
export namespace BrandUpdateEndpoint {
  export const inputSchema = z.object({
    name: z.string().min(1),
  });
  export type Input = z.infer<typeof inputSchema>;

  // @todo add output schema
  export const outputSchema = brandSchema
    .pick({
      id: true,
      name: true,
      organizationId: true,
    })
    .extend({
      organizationsPartners: z.array(organizationSchema.pick({ id: true })),
    });
  export type Output = z.infer<typeof outputSchema>;

  interface MutationParams {
    brandId: string;
    data: Input;
  }

  type Params = MutationParams;

  export const path = ({ brandId }: Params) => `/brands/${brandId}`;

  export const call = (params: Params) =>
    axiosInstance
      .put(path(params), inputSchema.parse(params.data))
      .then((res) => outputSchema.parse(res.data));

  export function useHook() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: call,
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(GetAllBrandsEndpoint.path()),
        });
        // invalidate every organization query
        data.organizationsPartners.forEach((org) =>
          queryClient.invalidateQueries({
            queryKey: getAPIQueryKey(
              GetOrganizationEndpoint.path({
                organizationId: org.id,
              }),
            ),
          }),
        );
      },
    });
  }
}
