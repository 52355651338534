import React, { PropsWithChildren, PropsWithoutRef, forwardRef } from "react";

import { omit } from "remeda";

// interface to get the keys that could be used as fallbackProp
type ReactNodeKeys<T> = {
  [K in keyof T]: T[K] extends React.ReactNode ? K : never;
}[keyof T];

/**
 * CONDITIONAL RENDERING WORKFLOW
 * @see https://excalidraw.com/#json=xeuSyZizkL06KNc52lgpI,YJYKdGlAyeU4lz0iv5MGOQ
 *
 * Usage covers those needs :
 * - I want to display a button if it is not disabled
 * - I want to display a tooltip if there are anything to show
 */
export interface ConditionalRenderingProps<
  ComponentProps extends PropsWithChildren<{}>,
> {
  /**
   * default: true
   *
   * If this prop is true, the component will render normally
   */
  renderIf?: boolean;
  /**
   * default: null
   *
   * If this prop is not falsy, it will render if "renderIf" is false
   * If set, "fallbackProp" will be ignored
   */
  fallback?: React.ReactNode;
  /**
   * default: undefined
   *
   * Use this prop to render a component's prop as a fallback
   * Examples :
   * - fallbackProp="children" will render the children if "renderIf" is false
   * - fallbackProp="content" will render the prop "content" if "renderIf" is false
   */
  fallbackProp?: ReactNodeKeys<ComponentProps>;
}

function extractComponentProps<ComponentProps extends {}>(
  props: PropsWithoutRef<
    ComponentProps & ConditionalRenderingProps<ComponentProps>
  >,
): ComponentProps {
  return omit(props, [
    "renderIf",
    "fallback",
    "fallbackProp",
  ]) as unknown as ComponentProps;
}

/**
 * This HOC helps rendering or wrapping components conditionnally
 */
export default function WithConditionalRendering<ComponentProps extends {}>(
  Component: React.ComponentType<ComponentProps>,
) {
  return forwardRef(
    (
      props: PropsWithoutRef<
        ComponentProps & ConditionalRenderingProps<ComponentProps>
      >,
      ref,
    ) => {
      const {
        renderIf = true,
        fallback = null,
        fallbackProp = undefined,
      } = props;
      const rest = extractComponentProps(props);

      if (renderIf) {
        return <Component ref={ref} {...(rest as unknown as ComponentProps)} />;
      }

      if (fallback) {
        return fallback;
      }

      if (fallbackProp) {
        return rest[fallbackProp] as React.ReactNode;
      }

      return null;
    },
  );
}
