import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Organization } from "@models/Organization";
import { OrganizationRepresentative } from "@models/OrganizationRepresentative";
import { Showroom } from "@models/Showroom";
import { AppointmentTypeEnum } from "@models/types/enums";

import axiosInstance from "../config";
import { getAPIQueryKey } from "../helper";

interface AddSellerCallParams {
  sellerId: OrganizationRepresentative["id"];
  appointmentTypes: AppointmentTypeEnum[];
}

export const addSellerEndpoint = {
  path: ({ organizationId, showroomId }: AddSellerHookParams) =>
    `/organizations/${organizationId}/showrooms/${showroomId}/add-seller`,
  call: (
    { organizationId, showroomId }: AddSellerHookParams,
    formData: AddSellerCallParams,
  ) =>
    axiosInstance.post(
      addSellerEndpoint.path({ organizationId, showroomId }),
      formData,
    ),
};

export interface AddSellerHookParams {
  organizationId: Organization["id"];
  showroomId: Showroom["id"];
}

export function useSalesCampaignAddSellerMutation(params: AddSellerHookParams) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (formData: AddSellerCallParams) =>
      addSellerEndpoint.call(params, formData),
    onSuccess: () => {
      toast.success(t("Toast.seller-added"));
      queryClient.invalidateQueries({
        queryKey: getAPIQueryKey(addSellerEndpoint.path(params)),
      });
    },
  });
}
