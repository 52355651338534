/* eslint-disable react/no-unstable-nested-components */
import React from "react";

import { createColumnHelper } from "@tanstack/react-table";
import { flag } from "country-emoji";
import { t } from "i18next";
import { HiExclamation, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";

import HoverCard from "@components/data-display/HoverCard";
import MRTable from "@components/data-display/MRTable";
import Tag from "@components/data-display/Tag";
import Tooltip from "@components/data-display/Tooltip";
import TagCell from "@components/data-display/table/tag-cell";
import Button from "@components/data-entry/Button";
import { UserRole } from "@models/types/enums";
import { GetContactsEndpoint } from "@services/api/contacts/get-contacts";
import {
  ellipseWithCount,
  fullName,
  getRoleName,
} from "@shared/helpers/formatters";

const rolesWithAccess: UserRole[] = [
  "AGENT",
  "ORGANIZATION_ADMIN",
  "ORGANIZATION_MAIN_ADMIN",
  "ORGANIZATION_MANAGER",
];
interface ContactsTableProps {
  contacts: GetContactsEndpoint.Output;
  onClickEdit: (contact: GetContactsEndpoint.OutputItem) => void;
  onClickDelete: (contact: GetContactsEndpoint.OutputItem) => void;
}

export default function ContactsTable({
  contacts,
  onClickEdit,
  onClickDelete,
}: ContactsTableProps) {
  const columnHelper = createColumnHelper<GetContactsEndpoint.OutputItem>();

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        header: t("CRM.contacts.table-header.name"),
        cell: ({ row: { original } }) => (
          <HoverCard
            renderIf={fullName(original).length > 20}
            fallbackProp="children"
            button={
              <div className="min-w-[10rem] max-w-[30rem] truncate">
                {fullName(original)}
              </div>
            }
          >
            <div className="min-w-[10rem]">{fullName(original)}</div>
          </HoverCard>
        ),
      }),
      columnHelper.display({
        header: t("CRM.contacts.table-header.account"),
        cell: ({ row }) => {
          const {
            original: {
              account: { name },
            },
          } = row;

          return (
            <HoverCard
              renderIf={name.length > 20}
              fallbackProp="children"
              button={
                <div className="min-w-[10rem] max-w-[30rem] truncate">
                  {name}
                </div>
              }
            >
              <div className="min-w-[10rem]">{name}</div>
            </HoverCard>
          );
        },
      }),
      columnHelper.display({
        header: t("CRM.contacts.table-header.email"),
        cell: ({ row }) => {
          const {
            original: { email, emailStatus },
          } = row;
          return (
            <div className="relative flex gap-2">
              {emailStatus === "BOUNCED" && (
                <Tooltip
                  placement="right"
                  content={
                    <div className="w-80">
                      {t("CRM.contacts.email-status-bounced")}
                    </div>
                  }
                >
                  <HiExclamation className="w-6 h-6 fill-statusOrangeDark" />
                </Tooltip>
              )}
              {email}
            </div>
          );
        },
      }),
      columnHelper.accessor("phoneNumber", {
        header: t("CRM.contacts.table-header.phone-number"),
        cell: (info) => (
          <div className="min-w-[9rem]">{info.getValue() || "-"}</div>
        ),
      }),
      columnHelper.accessor("position", {
        header: t("CRM.contacts.table-header.position"),
        cell: (info) => info.getValue() || "-",
      }),
      columnHelper.display({
        header: t("CRM.contacts.table-header.portfolios"),
        cell: ({ row }) => {
          const { portfolios } = row.original;

          const list: string[] = [];
          if (portfolios && portfolios?.length > 0) {
            portfolios.forEach((portfolio) => {
              const {
                manager: { firstName, lastName, role },
              } = portfolio;

              list.push(
                `${portfolio.name} - ${firstName} ${lastName.slice(
                  0,
                  1,
                )}. (${getRoleName(role)})`,
              );
            });
          }

          const ellipsis = ellipseWithCount(list, 1);

          return (
            <TagCell maxWidth="max-w-[25rem]" values={ellipsis.slice}>
              <HoverCard
                renderIf={ellipsis.length > 0}
                fallbackProp="children"
                button={
                  <Tag theme="PRIMARY" size="sm">
                    +{ellipsis.length}
                  </Tag>
                }
              >
                {ellipsis.sliced.map((text) => (
                  <Tag title={text} className="min-w-full" key={text}>
                    {text}
                  </Tag>
                ))}
              </HoverCard>
            </TagCell>
          );
        },
      }),
      columnHelper.display({
        header: t("CRM.contacts.table-header.market"),
        cell: ({ row }) => {
          const { markets } = row.original;

          const ellipsis = ellipseWithCount(
            markets.map((market) => t(`Common.market-type.${market}`)),
            1,
          );

          return (
            <TagCell maxWidth="max-w-[25rem]" values={ellipsis.slice}>
              {ellipsis.length > 0 && (
                <HoverCard
                  button={
                    <Tag theme="PRIMARY" size="sm">
                      +{ellipsis.length}
                    </Tag>
                  }
                >
                  {ellipsis.sliced.map((text) => (
                    <Tag title={text} className="min-w-full" key={text}>
                      {text}
                    </Tag>
                  ))}
                </HoverCard>
              )}
            </TagCell>
          );
        },
      }),
      columnHelper.display({
        header: t("CRM.contacts.table-header.origin"),
        cell: ({ row }) => {
          const {
            original: {
              account: { city, countryCode },
            },
          } = row;
          return (
            countryCode && (
              <Tag icon={flag(countryCode)} className="w-fit">
                {city}
              </Tag>
            )
          );
        },
      }),
      columnHelper.display({
        id: "actions",
        meta: {
          cellClassName: "sticky right-0 bg-white",
        },
        cell: ({ row }) => {
          const { id } = row.original;
          return (
            <div className="flex items-center justify-end gap-4 p-2">
              <Button
                aclRoles={rolesWithAccess}
                type="button"
                theme="ICON"
                testId={`edit-contact-${id}`}
                onClick={() => {
                  onClickEdit(row.original);
                }}
              >
                <HiOutlinePencil className="w-4 h-4 text-primaryElectricBlue" />
              </Button>
              <Button
                aclRoles={rolesWithAccess}
                type="button"
                theme="ICON"
                testId={`delete-contact-${id}`}
                onClick={() => onClickDelete(row.original)}
              >
                <HiOutlineTrash className="w-4 h-4 text-statusRedDark" />
              </Button>
            </div>
          );
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnHelper],
  );

  return (
    <MRTable
      data={contacts}
      columns={columns}
      cellClassName="border rounded-md"
      noDataLabel={t("CRM.contacts.no-results")}
      displayDataLabel={t("CRM.contacts.footer-display")}
      autoResetPageIndex
    />
  );
}
