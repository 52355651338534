import React from "react";

import { t } from "i18next";
import { BsFileEarmarkPerson } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { HiOutlineVideoCamera } from "react-icons/hi";

import prospectIcon from "@app/assets/icons/prospect.svg";
import { AccountStatus } from "@models/old/Account";
import {
  AppointmentFormat,
  AppointmentTypeEnum,
  VirtualMeetingAppChoice,
} from "@models/types/enums";

export const getAppointmentFormatIcon = (format: AppointmentFormat) => {
  switch (format) {
    case "VIRTUAL":
      return <HiOutlineVideoCamera className="inline-block" />;
    case "IN_PERSON":
    default:
      return <FiUsers className="inline-block" />;
  }
};

export const getAppointmentFormatLabel = (format: AppointmentFormat) => {
  switch (format) {
    case "VIRTUAL":
      return t("Calendar.appointment.form.virtual");
    case "IN_PERSON":
    default:
      return t("Calendar.appointment.form.in-person");
  }
};

export const getAppointmentVirtualMeetingAppLabel = (
  virtualMeetingApp: VirtualMeetingAppChoice,
) => {
  switch (virtualMeetingApp) {
    case "ZOOM":
      return "Zoom";
    case "GOOGLE_MEET":
      return "Google Meet";
    case "MICROSOFT_TEAMS":
      return "Microsoft Teams";
    case "ANY":
      return t("Calendar.appointment.form.any-virtual-tool");
    default:
      return virtualMeetingApp;
  }
};

// TODO move this to translation by array/objects
export const getAppointmentTypeLabel = (type: AppointmentTypeEnum) => {
  switch (type) {
    case AppointmentTypeEnum.BUYING_APPOINTMENT:
      return t("Calendar.appointment.form.buying-meeting");
    case AppointmentTypeEnum.WALKTHROUGH:
      return t("Calendar.appointment.form.walkthrough");
    default:
      return type;
  }
};

export const getAccountStatusIcon = (status: AccountStatus) => {
  switch (status) {
    case "PROSPECT":
      return (
        <img
          src={prospectIcon}
          className="w-4 h-4 fill-primaryBlack"
          alt="prospect"
        />
      );
    case "ACTIVE":
    default:
      return <BsFileEarmarkPerson className="w-3 h-3 text-statusGreenDark" />;
  }
};
