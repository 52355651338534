import { GenericAbortSignal, toFormData } from "axios";

import {
  Account,
  AccountDetails,
  AccountWithCollections,
} from "@models/old/Account";
import { Collection, CollectionCreate } from "@models/old/Collection";
import { Contact, ContactDetails } from "@models/old/Contact";
import { Organization } from "@models/old/Organization";
import { Representative } from "@models/old/Representative";
import { SalesCampaignWithAggregate } from "@models/old/SalesCampaign";
import { GeographicalFilter, Geography } from "@models/types/Geography";
import { OrganizationInformation } from "@models/types/OrganizationInformation";
import salesCampaignMapper from "@services/api/old/sales-campaign/SalesCampaignMapper";
import { GetOrganizationEndpoint } from "@services/api/organization/get-organization";
import { OrganizationInformationFormData } from "@shared/organization/organization-information-form";

import axiosInstance from "../../config";

export const OrganizationAPIPaths = {
  getOrganization: (organizationId: string) =>
    `/organizations/${organizationId}`,
  getCollections: (organizationId: string) =>
    `/organizations/${organizationId}/collections`,
  salesCampaigns: (organizationId: string) =>
    `/organizations/${organizationId}/sales-campaigns`,
};

const OrganizationAPI = {
  getOrganizations: (): Promise<Organization[]> =>
    axiosInstance.get(`/organizations`).then((res) => res.data),

  getOrganization: (id: string): Promise<GetOrganizationEndpoint.Output> =>
    axiosInstance
      .get(OrganizationAPIPaths.getOrganization(id))
      .then((res) => res.data),

  deleteOrganization: (id: string): Promise<Organization> =>
    axiosInstance.delete(OrganizationAPIPaths.getOrganization(id)),

  startOnboarding: (
    onboardingInformation: OrganizationInformationFormData,
  ): Promise<Organization> =>
    axiosInstance
      .post(`/organizations/onboarding`, toFormData(onboardingInformation))
      .then((res) => res.data),

  getOrganizationInformation: (
    organizationId: string,
  ): Promise<OrganizationInformation> =>
    axiosInstance
      .get(`/organizations/${organizationId}/onboarding-information`)
      .then((res) => res.data),

  updateOrganizationInformation: (
    organizationId: string,
    onboardingInformation: OrganizationInformationFormData,
  ): Promise<Organization> =>
    axiosInstance
      .post(
        `/organizations/${organizationId}/update-organization-information`,
        toFormData(onboardingInformation),
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )
      .then((res) => res.data),

  finishOnboarding: (organizationId: string) =>
    axiosInstance.put(`/organizations/${organizationId}/finish-onboarding`),

  uploadAccounts: (
    id: string,
    file: File,
  ): Promise<AccountWithCollections[]> => {
    const formData = new FormData();
    formData.append("file", file);
    return axiosInstance
      .post(`/organizations/${id}/upload-accounts`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  },

  uploadContacts: (id: string, file: File): Promise<ContactDetails[]> => {
    const formData = new FormData();
    formData.append("file", file);
    return axiosInstance
      .post(`/organizations/${id}/upload-contacts`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  },

  uploadOrganizationRepresentatives: (
    id: string,
    file: File,
  ): Promise<Representative[]> => {
    const formData = new FormData();
    formData.append("file", file);
    return axiosInstance
      .post(`/organizations/${id}/upload-representatives`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  },

  redoUploadAccounts: (id: string): Promise<void> =>
    axiosInstance.delete(`/organizations/${id}/accounts/redo-upload`),

  redoUploadContacts: (id: string): Promise<void> =>
    axiosInstance.delete(`/organizations/${id}/contacts/redo-upload`),

  redoUploadRepresentatives: (id: string): Promise<void> =>
    axiosInstance.delete(`/organizations/${id}/representatives/redo-upload`),

  sendOnboardingEmail: (id: string): Promise<void> =>
    axiosInstance.post(`/organizations/${id}/send-onboarding-email`),

  createCollection: (
    organizationId: string,
    collection: CollectionCreate,
  ): Promise<Collection> =>
    axiosInstance
      .post(`organizations/${organizationId}/collections`, collection)
      .then((res) => res.data),

  updateCollection: (
    organizationId: string,
    collection: Collection,
  ): Promise<Collection> =>
    axiosInstance
      .put(
        `organizations/${organizationId}/collections/${collection.id}`,
        collection,
      )
      .then((res) => res.data),

  archiveCollection: (
    organizationId: string,
    collectionId: string,
  ): Promise<{ id: string }> =>
    axiosInstance
      .put(
        `organizations/${organizationId}/collections/${collectionId}/archive`,
      )
      .then((res) => res.data),

  getRepresentatives: (
    id: string,
    signal?: GenericAbortSignal,
  ): Promise<Representative[]> =>
    axiosInstance
      .get(`organizations/${id}/representatives`, {
        signal,
      })
      .then((res) => res.data),

  getAccounts: (
    id: string,
    signal?: GenericAbortSignal,
  ): Promise<AccountDetails[]> =>
    axiosInstance
      .get(`organizations/${id}/accounts`, {
        signal,
      })
      .then((res) => res.data),

  getAccountsWithCollections: (
    id: string,
    signal?: GenericAbortSignal,
  ): Promise<AccountWithCollections[]> =>
    axiosInstance
      .get(`organizations/${id}/accounts/full`, {
        signal,
      })
      .then((res) => res.data),

  getCollections: (
    organizationId: string,
    signal?: GenericAbortSignal,
  ): Promise<Collection[]> =>
    axiosInstance
      .get(OrganizationAPIPaths.getCollections(organizationId), {
        signal,
      })
      .then((res) => res.data),

  getFilteredAccounts: (
    id: string,
    accountName?: string,
    geographicalFilters?: GeographicalFilter[],
    signal?: GenericAbortSignal,
  ): Promise<AccountDetails[]> => {
    const params: any = {};
    if (accountName) {
      params.accountName = accountName;
    }
    const headers: any = {};
    if (geographicalFilters) {
      headers.geographicalFilters = JSON.stringify(geographicalFilters);
    }
    return axiosInstance
      .get(`organizations/${id}/accounts`, {
        params,
        headers,
        signal,
      })
      .then((res) => res.data);
  },

  getGeographyTreeOptions(
    id: string,
    accountName?: string,
    accountStatus?: string,
    signal?: GenericAbortSignal,
  ): Promise<Geography> {
    const params: any = {};
    if (accountName) {
      params.accountName = accountName;
    }
    return axiosInstance
      .get(`organizations/${id}/accounts/geography`, {
        params,
        signal,
      })
      .then((res) => res.data);
  },

  getContactsForAccountId: (
    id: string,
    accountId: string,
    signal?: GenericAbortSignal,
  ): Promise<Contact[]> =>
    axiosInstance
      .get(`organizations/${id}/contacts/by-account/${accountId}`, {
        signal,
      })
      .then((res) => res.data),

  getContacts: (
    id: string,
    signal?: GenericAbortSignal,
  ): Promise<ContactDetails[]> =>
    axiosInstance
      .get(`organizations/${id}/contacts`, {
        signal,
      })
      .then((res) => res.data),

  switchAccountPortfolioType: (id: string): Promise<void> =>
    axiosInstance.post(`organizations/${id}/switch-account-portfolio-type`),

  getSalesCampaigns: (
    organizationId: Organization["id"],
  ): Promise<SalesCampaignWithAggregate[]> =>
    axiosInstance
      .get(OrganizationAPIPaths.salesCampaigns(organizationId))
      .then((res) => res.data.map(salesCampaignMapper)),
};

export const OrganizationQueries = {
  getOrganization: (organizationId: Organization["id"]) => ({
    queryKey: ["organization", organizationId],
    queryFn: () => OrganizationAPI.getOrganization(organizationId),
  }),
  getCollections: (organizationId: Organization["id"]) => ({
    queryKey: ["organization-collections", organizationId],
    queryFn: () => OrganizationAPI.getCollections(organizationId),
  }),
  getSalesCampaigns: (organizationId: Organization["id"]) => ({
    queryKey: ["sales-campaigns", organizationId],
    queryFn: () => OrganizationAPI.getSalesCampaigns(organizationId),
  }),
  getAccounts: (organizationId: Organization["id"]) => ({
    queryKey: ["accounts", organizationId],
    queryFn: () => OrganizationAPI.getAccounts(organizationId),
  }),
  getContactsForAccountId: (
    organizationId: Organization["id"],
    accountId: Account["id"],
  ) => ({
    queryKey: ["organization-account-contacts", organizationId, accountId],
    queryFn: () =>
      OrganizationAPI.getContactsForAccountId(organizationId, accountId),
  }),
};

export default OrganizationAPI;
