import React, { HTMLProps } from "react";

import { IoIosClose } from "react-icons/io";

import Button from "./Button";

interface TextAreaFieldProps
  extends Omit<HTMLProps<HTMLTextAreaElement>, "onChange" | "defaultValue"> {
  className?: string;
  icon?: JSX.Element;
  onChange?: (v: string) => void;
  onClearField?: () => void;
  value?: string;
  clearable?: boolean;
  hasError?: boolean;
  rows: number;
}

function TextField(props: TextAreaFieldProps) {
  const {
    className,
    icon,
    onChange,
    onClearField,
    id,
    value = "",
    clearable = true,
    hasError = false,
    rows,
    ...rest
  } = props;

  const isDisabled = rest.disabled || rest.readOnly;
  const clearField = () => {
    if (onChange && !isDisabled) {
      onChange("");
      if (onClearField) onClearField();
    }
  };

  return (
    <div className="relative w-full h-full text-gray-700">
      {/* added border-primaryLightGrey so that the otb, total budget and notes inputs match the border from the element above which provides appointment info */}
      <div
        className={`flex items-center rounded-lg form-input ${
          isDisabled ? "bg-primaryLightestGrey" : ""
        } ${className || ""} 

        ${hasError ? "border-primaryRed" : "border-primaryLightGrey"}`}
      >
        {icon && <div className="justify-center -mr-px">{icon}</div>}
        <textarea
          {...props}
          className={`w-full outline-none ${
            isDisabled ? "bg-primaryLightestGrey hover:cursor-default" : ""
          } ${icon ? "px-3" : "px-2 py-1"}`}
          id={id}
          name={id}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          value={value || ""}
          rows={rows}
          {...rest}
          style={{ resize: "none" }}
        />
        {clearable && !isDisabled && onChange && (
          <Button
            theme="ICON"
            type="button"
            title="clear field"
            className="cursor-pointer absolute top-2 right-2"
            onClick={clearField}
          >
            <IoIosClose className="w-6 h-6" />
          </Button>
        )}
      </div>
    </div>
  );
}

export default TextField;
