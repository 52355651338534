import React from "react";

import { toast } from "react-toastify";

import Button from "@components/data-entry/Button";
import Checkbox from "@components/data-entry/Checkbox";
import CalloutBox from "@components/feedback/CalloutBox";
import Drawer, { DrawerBody, useDrawer } from "@components/feedback/Drawer";
import BottomBar from "@components/layout/BottomBar";
import { useUniqueStringArray } from "@helpers/Array";
import { usePrefixedTranslation } from "@helpers/Translation";
import { InvitationSendFollowupsEndpoint } from "@services/api/invitations/send-followups";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import LogService from "@services/log/service";

type Props = {
  contactIds: string[];
  showrooms: {
    id: string;
    name: string;
  }[];
};

export default function SendFollowupsCTA({ contactIds, showrooms }: Props) {
  const { t, pt } = usePrefixedTranslation("Invitation.SendFollowupsCTA");
  const { organization } = useOrganizationAppContext();
  const drawer = useDrawer({
    backdrop: true,
    initial: false,
    needsConfirmation: false,
    onClose: () => {},
  });

  const selectedShowroomIds = useUniqueStringArray([]);

  const sendFollowups = InvitationSendFollowupsEndpoint.useHook({
    organizationId: organization.id,
  });

  return (
    <>
      <Button
        disabled={contactIds.length === 0}
        theme="PRIMARY"
        onClick={drawer.openDrawer}
      >
        {pt("send-followups")}
      </Button>
      <Drawer {...drawer.props} drawerTitle={pt("drawer-title")}>
        <DrawerBody className="grow">
          <CalloutBox type="WARNING">{pt("callout")}</CalloutBox>
          <p>{pt("which-showrooms")}</p>
          {showrooms.map((showroom) => (
            <div className="grid grid-cols-2" key={showroom.id}>
              <Checkbox
                id={`collection-${showroom.id}`}
                name={`collection-${showroom.id}`}
                checked={selectedShowroomIds.state.includes(showroom.id)}
                onChange={() => selectedShowroomIds.toggle(showroom.id)}
                type="button"
                value={showroom.id}
                label={showroom.name}
              />
            </div>
          ))}
        </DrawerBody>
        <BottomBar>
          <Button theme="SECONDARY" onClick={drawer.closeWithoutConfirmation}>
            {t("Common.cancel")}
          </Button>
          <Button
            theme="PRIMARY"
            loading={sendFollowups.isPending}
            disabled={selectedShowroomIds.state.length === 0}
            onClick={() => {
              sendFollowups
                .mutateAsync({
                  contactIds,
                  showroomIds: selectedShowroomIds.state,
                })
                .then((res) => {
                  toast.success(
                    t("Toast.followups-sent", {
                      nbEmails: res.length,
                    }),
                  );
                  drawer.closeWithoutConfirmation();
                })
                .catch((err) => {
                  toast.success(t("Toast.followups-error"));
                  LogService.error(err);
                });
            }}
          >
            {pt("send")}
          </Button>
        </BottomBar>
      </Drawer>
    </>
  );
}
