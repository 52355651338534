import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toFormData } from "axios";
import { z } from "zod";

import { openingDaySerializingSchema, showroomSchema } from "@models/Showroom";
import {
  localDateSerializingSchema,
  localTimeSerializingSchema,
} from "@models/types/Dates";
import {
  AppointmentFormatList,
  CollectionAgnosticAppointmentTypeList,
  CollectionRelatedAppointmentTypeList,
  ShowroomSeasonList,
  appointmentTypeSchema,
} from "@models/types/enums";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";
import { GetShowroomEndpoint } from "@services/api/showroom/get-showroom";
import { GetShowroomsEndpoint } from "@services/api/showroom/get-showrooms";

export namespace CreateShowroomEndpoint {
  export const inputSchema = z.object({
    name: z.string(),
    season: z.enum(ShowroomSeasonList),
    year: z.number(),
    timezone: z.string(),
    formattedAddress: z.string(),
    postalCode: z.string(),
    city: z.string(),
    countryCode: z.string().length(2),
    addressComponents: z.any(),
    directions: z.string().nullable(),
    lunchBreakStartingHour: localTimeSerializingSchema.nullable(),
    lunchBreakEndingHour: localTimeSerializingSchema.nullable(),
    openingHour: localTimeSerializingSchema,
    closingHour: localTimeSerializingSchema,
    openingDays: z.array(openingDaySerializingSchema),
    collections: z.array(z.object({ id: z.string() })),
    appointmentFormats: z.array(z.enum(AppointmentFormatList)),
    appointmentTypes: z.array(appointmentTypeSchema),
    specialRequestsAllowed: z.boolean(),
    appointmentTypesDuration: z.array(
      z.union([
        z.object({
          type: z.enum(CollectionRelatedAppointmentTypeList),
          collectionId: z.string(),
          duration: z.number(),
        }),
        z.object({
          type: z.enum(CollectionAgnosticAppointmentTypeList),
          duration: z.number(),
        }),
      ]),
    ),
    appointmentIncrement: z.number().nullable(),
    sellers: z.array(
      z.object({
        id: z.string(),
        appointmentTypes: z.array(appointmentTypeSchema),
      }),
    ),
    lastAllowedBookingDate: localDateSerializingSchema.nullable(),
    numberOfSeats: z.number().nullable(),
  });
  export type Input = z.input<typeof inputSchema>;

  export const outputSchema = showroomSchema
    .pick({
      id: true,
    })
    .extend({});
  export type Output = z.infer<typeof outputSchema>;

  interface HookParams {
    organizationId: string;
  }

  interface MutationParams {
    data: Input;
    files: File[];
  }

  type Params = HookParams & MutationParams;

  export const path = ({ organizationId }: Params) =>
    `/organizations/${organizationId}/showrooms`;

  export const call = (params: Params) => {
    const { data, files } = params;

    const body = inputSchema.parse(data);
    const formData = toFormData(body);
    files.forEach((file) => formData.append("files", file));

    return axiosInstance
      .postForm(path(params), formData)
      .then((res) => outputSchema.parse(res.data));
  };

  export function useHook(hookParams: HookParams) {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
          ...hookParams,
        }),
      onSuccess: (result) => {
        queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(
            GetShowroomEndpoint.path({
              organizationId: hookParams.organizationId,
              showroomId: result.id,
            }),
          ),
        });
        queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(
            GetShowroomsEndpoint.path({
              organizationId: hookParams.organizationId,
            }),
          ),
        });
      },
    });
  }
}
