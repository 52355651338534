/* eslint-disable react/no-array-index-key */
import React from "react";

import { COLUMN_WIDTH } from "@calendar/style";
import { fullName } from "@shared/helpers/formatters";

interface DailyHeaderProps {
  sellers: {
    firstName: string;
    lastName: string;
  }[];
}

function DailyHeader(props: DailyHeaderProps) {
  const { sellers } = props;

  const gridTemplateColumns = `50px repeat(${sellers.length}, ${COLUMN_WIDTH}rem)`;

  // TODO: https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/

  return (
    <div className="ml-10 lg:top-0">
      <div
        className="grid"
        data-testid="seller-columns"
        style={{
          gridTemplateColumns,
        }}
      >
        <div className="p-2" />
        {sellers.map((seller, key) => (
          <div key={`sellerColumn_${key}`} className="bg-white border">
            <div
              data-testid="seller-column-name"
              className="p-2 text-md text-primaryBlack text-center"
            >
              {fullName(seller, true)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DailyHeader;
