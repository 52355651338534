import {
  AppointmentFormat,
  AppointmentFormatWithMeetingApp,
  VirtualMeetingApps,
} from "@models/types/enums";

export function removeVirtualMeetingAppsFromAppointmentFormats(
  appointmentFormats: AppointmentFormatWithMeetingApp[],
): AppointmentFormatWithMeetingApp[] {
  return appointmentFormats.filter((app) => !app.startsWith("VIRTUAL_"));
}

export function addVirtualMeetingAppsToAppointmentFormats(
  apps: VirtualMeetingApps[],
): AppointmentFormatWithMeetingApp[] {
  return apps.map((app) => `VIRTUAL_${app}` as AppointmentFormatWithMeetingApp);
}

export function isVirtualMeetingApp(
  appointmentFormat: AppointmentFormatWithMeetingApp,
): boolean {
  return appointmentFormat.startsWith("VIRTUAL_");
}

export function containsVirtualMeetingApp(
  appointmentFormats: AppointmentFormatWithMeetingApp[],
): boolean {
  return appointmentFormats.some(isVirtualMeetingApp);
}

export function extractFormat(
  formatWithApp: AppointmentFormatWithMeetingApp,
): AppointmentFormat {
  return isVirtualMeetingApp(formatWithApp) ? "VIRTUAL" : "IN_PERSON";
}

export function extractMeetingApp(
  formatWithApp: AppointmentFormatWithMeetingApp,
): VirtualMeetingApps | undefined {
  if (formatWithApp === "IN_PERSON") {
    return undefined;
  }

  return formatWithApp.replace("VIRTUAL_", "") as VirtualMeetingApps;
}

export function combineFormatAndMeetingApp(
  format: AppointmentFormat,
  meetingApp?: VirtualMeetingApps,
): AppointmentFormatWithMeetingApp {
  return format === "VIRTUAL" && meetingApp
    ? `${format}_${meetingApp}`
    : "IN_PERSON";
}

export function translateFormatWithApp(
  formatWithApp: AppointmentFormatWithMeetingApp,
  t: (s: string) => string,
) {
  const format = extractFormat(formatWithApp);
  const app = extractMeetingApp(formatWithApp);
  return app
    ? `${t(`Common.appointment-format.${format}`)} - ${t(
        `Common.virtual-tool.${app}`,
      )}`
    : t(`Common.appointment-format.${format}`);
}
