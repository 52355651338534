/* eslint-disable react/no-unstable-nested-components */
import React from "react";

import { createColumnHelper } from "@tanstack/react-table";
import { flag } from "country-emoji";
import { t } from "i18next";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { HiOutlineStar, HiStar } from "react-icons/hi2";
import { TbReportMoney } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";

import HoverCard from "@components/data-display/HoverCard";
import MRTable from "@components/data-display/MRTable";
import Tag from "@components/data-display/Tag";
import Tooltip from "@components/data-display/Tooltip";
import TagCell from "@components/data-display/table/tag-cell";
import Button from "@components/data-entry/Button";
import { Account } from "@models/Account";
import { AccountsStatusColors, UserRole } from "@models/types/enums";
import { useSwitchKeyAccount } from "@services/api/accounts/switch-key-account";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { ellipseWithCount, getRoleName } from "@shared/helpers/formatters";

import { AccountWithCollections } from "./type";

const rolesWithAccess: UserRole[] = [
  "AGENT",
  "ORGANIZATION_ADMIN",
  "ORGANIZATION_MAIN_ADMIN",
  "ORGANIZATION_MANAGER",
];

interface AccountsTableProps {
  accounts: AccountWithCollections[];
  onClickEdit: (account: AccountWithCollections) => void;
  onClickDelete: (account: AccountWithCollections) => void;
}

interface Collection {
  name: string;
  status?: Account["status"];
}

export default function AccountsTable({
  accounts,
  onClickEdit,
  onClickDelete,
}: AccountsTableProps) {
  const columnHelper = createColumnHelper<AccountWithCollections>();
  const { organization } = useOrganizationAppContext();
  const navigate = useNavigate();

  const { mutateAsync: updateAccountKeyClient } = useSwitchKeyAccount({
    organizationId: organization.id,
  });
  const columns = React.useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () => t("CRM.accounts.table-header.name"),
        cell: (info) => (
          // remove truncate so that the comlete account name is displayed
          <div className="flex gap-2 items-center min-w-[5rem]">
            <Tooltip
              placement="bottom-start"
              content={
                <div className="min-w-[5rem] truncate">
                  {info.row.original.isKeyClient
                    ? t("CRM.accounts.unset-is-key-account")
                    : t("CRM.accounts.set-is-key-account")}
                </div>
              }
            >
              <Button
                theme="NONE"
                label={`${
                  info.row.original.isKeyClient ? "un" : ""
                }set-key-account-${info.row.original.id}`}
                className="flex items-center justify-center h-full"
                onClick={() => {
                  updateAccountKeyClient({
                    account: {
                      id: info.row.original.id as Account["id"],
                    },
                  });
                }}
              >
                {info.row.original.isKeyClient && (
                  <HiStar
                    aria-label={`${info.row.original.name}-isKeyClient`}
                    className="text-secondaryCalendulaGold"
                  />
                )}
                {!info.row.original.isKeyClient && (
                  <HiOutlineStar
                    aria-label={`${info.row.original.name}-isNotKeyClient`}
                    className="text-primaryGrey"
                  />
                )}
              </Button>
            </Tooltip>
            <div className="truncate">
              <p className="truncate">{info.row.original.name}</p>
            </div>
          </div>
        ),
      }),
      columnHelper.display({
        id: "meeting-report-link",
        cell: ({ row }) => (
          <Link to={`/crm/accounts/${row.id}/meeting-report`}>
            <TbReportMoney className="p-1 border border-primaryElectricBlue rounded-full w-6 h-6 transition-colors duration-300 hover:bg-primaryLightElectricBlue text-primaryElectricBlue" />
          </Link>
        ),
      }),
      columnHelper.display({
        header: t("CRM.accounts.table-header.origin"),
        cell: ({ row }) => {
          const {
            original: { city, countryCode },
          } = row;
          return (
            <Tag icon={flag(countryCode)} className="w-fit">
              {city}
            </Tag>
          );
        },
      }),
      columnHelper.display({
        header: t("CRM.accounts.table-header.type"),
        cell: ({ row }) => t(`Common.account-type.${row.original.type}`),
      }),
      columnHelper.display({
        id: "status",
        header: t("CRM.accounts.table-header.status"),
        cell: ({ row }) => {
          const {
            original: { collections },
          } = row;
          if (collections && collections.length > 0) {
            const aggregations = collections
              .sort((a, b) => a.status.localeCompare(b.status))
              .reduce(
                (acc, cur) => ({
                  ...acc,
                  [cur.status]: (acc[cur.status] || 0) + 1,
                }),
                {} as Record<Account["status"], number>,
              );
            return (
              <div className="flex flex-wrap items-center gap-2">
                {Object.keys(aggregations).map((key) => {
                  const collectionsByStatus = collections.filter(
                    (c: Collection) => c.status === key,
                  );
                  return (
                    <Tooltip
                      key={`account-status-${key}`}
                      content={collectionsByStatus.map((c) => (
                        <span key={c.name}>
                          {c.name} - {c.brand.name}
                        </span>
                      ))}
                    >
                      <Tag
                        type="dotted"
                        theme={AccountsStatusColors[key as Account["status"]]}
                        className="mb-1 cursor-pointer w-fit h-fit"
                        withCount={aggregations[key as Account["status"]]}
                      >
                        {t(
                          `SalesCampaign.invitation.account-status.${key.toString()}`,
                        )}
                      </Tag>
                    </Tooltip>
                  );
                })}
              </div>
            );
          }
          return "--";
        },
      }),
      columnHelper.display({
        header: t("CRM.accounts.table-header.portfolios"),
        cell: ({ row }) => {
          const { portfolios } = row.original;

          const list: string[] = [];
          if (portfolios && portfolios?.length > 0) {
            portfolios.forEach((portfolio) => {
              const {
                manager: { firstName, lastName, role },
              } = portfolio;

              list.push(
                `${portfolio.name} - ${firstName} ${lastName.slice(
                  0,
                  1,
                )}. (${getRoleName(role)})`,
              );
            });
          }

          const ellipsis = ellipseWithCount(list, 1);
          return (
            <TagCell maxWidth="max-w-[25rem]" values={ellipsis.slice}>
              <HoverCard
                renderIf={ellipsis.length > 0}
                fallbackProp="children"
                button={
                  <Tag theme="PRIMARY" size="sm">
                    +{ellipsis.length}
                  </Tag>
                }
              >
                {ellipsis.sliced.map((text) => (
                  <Tag title={text} className="min-w-full" key={text}>
                    {text}
                  </Tag>
                ))}
              </HoverCard>
            </TagCell>
          );
        },
      }),
      columnHelper.display({
        id: "actions",
        meta: {
          cellClassName: "sticky right-0 bg-white",
        },
        cell: ({ row }) => {
          const { id } = row.original;
          return (
            <div className="flex items-center justify-end gap-4 p-2">
              <Button
                aclRoles={rolesWithAccess}
                type="button"
                theme="ICON"
                testId={`edit-account-${id}`}
                onClick={() => {
                  onClickEdit(row.original);
                }}
              >
                <HiOutlinePencil className="w-4 h-4 text-primaryElectricBlue" />
              </Button>
              <Button
                aclRoles={rolesWithAccess}
                type="button"
                theme="ICON"
                testId={`delete-account-${id}`}
                onClick={() => onClickDelete(row.original)}
              >
                <HiOutlineTrash className="w-4 h-4 text-statusRedDark" />
              </Button>
            </div>
          );
        },
      }),
    ],
    [
      columnHelper,
      navigate,
      onClickDelete,
      onClickEdit,
      updateAccountKeyClient,
    ],
  );

  return (
    <MRTable
      data={accounts}
      columns={columns}
      cellClassName="border rounded-md"
      noDataLabel={t("CRM.accounts.no-results")}
      displayDataLabel={t("Common.accounts")}
      autoResetPageIndex
    />
  );
}
