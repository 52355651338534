import React from "react";

import { useTranslation } from "react-i18next";
import { HiOutlinePlusCircle, HiPlusCircle } from "react-icons/hi";

import Button from "@components/data-entry/Button";
import { Brand } from "@models/Brand";
import { PortfolioWithCapabilities } from "@services/api/old/organization/fetch-portfolios-with-capabilities";
import {
  ACL_ADMINS,
  useUserRole,
} from "@shared/components/access-control/helpers";

import PortfolioItem from "./portfolio-item";

export interface PortfolioGroup {
  title: Brand["name"] | string;
  portfolios: PortfolioWithCapabilities[];
  brand: Brand;
}

interface PortfolioListProps {
  group: PortfolioGroup;
  onAddPortfolio: (group: PortfolioGroup) => void;
  onEditPortfolio: (portfolio: PortfolioWithCapabilities) => void;
  onDeletePortfolio: (portfolio: PortfolioWithCapabilities) => void;
}

function PortfoliosGroup(props: PortfolioListProps) {
  const { t } = useTranslation();
  const { group, onAddPortfolio, onEditPortfolio, onDeletePortfolio } = props;

  const { title, portfolios } = group;

  const { isAdmin } = useUserRole();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center">
        <h2 className="heading-2 capitalize grow">{title}</h2>
        {portfolios.length > 0 && (
          <Button
            aclRoles={ACL_ADMINS}
            label={`new portfolio for ${title}`}
            onClick={() => onAddPortfolio(group)}
            theme="PRIMARY"
          >
            <HiOutlinePlusCircle className="w-6 h-6" />
            <span>{t("Shared.portfolios.new")}</span>
          </Button>
        )}
      </div>
      <div className="flex gap-6 flex-wrap">
        {portfolios.length > 0 &&
          portfolios.map((portfolio) => (
            <div key={portfolio.id} className="w-[calc(50%-0.75rem)]">
              <PortfolioItem
                portfolio={portfolio}
                onEditPortfolio={onEditPortfolio}
                onDeletePortfolio={onDeletePortfolio}
                deleteDisabled={portfolio.deletingBlockedBy.length > 0}
              />
            </div>
          ))}
      </div>
      {isAdmin && portfolios.length === 0 && (
        <div className="min-h-[10rem] flex justify-center mt-4 rounded-xl border-dashed border-2 border-primaryLightGrey">
          <Button
            type="button"
            theme="NONE"
            aria-label={`new portfolio for ${title}`}
            className="flex items-center gap-2"
            onClick={() => onAddPortfolio(group)}
          >
            <HiPlusCircle className="w-6 h-6 fill-primaryElectricBlue" />
            <span className="text-xl font-medium text-primaryElectricBlue">
              {t("Shared.portfolios.new")}
            </span>
          </Button>
        </div>
      )}
    </div>
  );
}

export default PortfoliosGroup;
