import React from "react";

import { OnboardingStep } from "@models/old/Organization";

import BreadCrumbItem from "./BreadCrumbItem";

function Breadcrumb({ currentStep }: { currentStep: OnboardingStep }) {
  return (
    <div className="flex justify-between">
      <BreadCrumbItem
        step={OnboardingStep.ORGANIZATION_CONFIRMATION}
        currentStep={currentStep}
        label="Organization informations"
        page="information"
      />
      <BreadCrumbItem
        step={OnboardingStep.SHOWROOM_STRUCTURE}
        currentStep={currentStep}
        label="Showroom structure"
        page="showroom-structure"
      />
      <BreadCrumbItem
        step={OnboardingStep.ACCOUNT_PORTFOLIO}
        currentStep={currentStep}
        label="Account portfolio"
        page="account-portfolio"
      />
    </div>
  );
}

export default Breadcrumb;
