import React, { useEffect } from "react";

import { Portal, Transition } from "@headlessui/react";
import {
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiOutlineMenu,
  HiOutlineUserCircle,
  HiOutlineX,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import modaresaLogoShort from "@app/assets/logos/moda-resa-logo.svg";
import modaresaLogo from "@app/assets/logos/moda-resa.svg";
import Button from "@components/data-entry/Button";
import { useIsLargestScreen } from "@components/layout/Breakpoint";

import NavigationMenu from "./NavigationMenu";
import SidebarContext, { useInitSidebarContext } from "./context/sidebar";

function SideBar() {
  const isScreenLarge = useIsLargestScreen();

  const contextValue = useInitSidebarContext();
  const { isOpen, setIsOpen, isFold, setIsFold } = contextValue;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isScreenLarge) {
      setIsFold(false);
      setIsOpen(false);
    }
  }, [isScreenLarge, setIsFold, setIsOpen]);

  return (
    <SidebarContext.Provider value={contextValue}>
      <div
        className={`no-scrollbar w-full flex flex-col lg:gap-10 lg:py-10 overflow-y-auto bg-white lg:h-screen lg:border-r border-primaryLightGrey ${
          isOpen && !isScreenLarge
            ? "absolute top-0 h-screen w-screen z-drawer"
            : ""
        } ${
          isFold ? "lg:w-32 lg:px-2 text-xs" : "lg:w-[16rem] lg:px-4 text-base"
        }`}
      >
        {/* HEADER */}
        <div className="flex flex-row h-16 lg:h-5 py-5 lg:py-0 px-6 lg:gap-4 items-center justify-between bg-white lg:justify-center">
          {/* MENU OPENING AND CLOSE BUTTON MOBILE VERSION */}
          <Button
            theme="ICON"
            className="lg:hidden"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {!isOpen ? (
              <HiOutlineMenu className="w-5 h-5" />
            ) : (
              <HiOutlineX className="w-5 h-5" />
            )}
          </Button>
          {/* MODARESA LOGO */}
          {isFold && (
            <img className="h-4" alt="modaresa logo" src={modaresaLogoShort} />
          )}
          {!isFold && (
            <img className="h-5" alt="modaresa logo" src={modaresaLogo} />
          )}
          {/* FOLD AND UNFOLD BUTTON LARGE SCREEN VERSION */}
          <Button
            theme="ICON"
            className={`w-full hidden lg:flex ${
              isFold ? "justify-center" : "justify-end"
            }`}
            onClick={() => setIsFold(!isFold)}
          >
            {!isFold ? (
              <HiChevronDoubleLeft className="w-6 h-6" />
            ) : (
              <HiChevronDoubleRight className="w-6 h-6" />
            )}
          </Button>
          {/* PROFILE BUTTON MOBILE VERSION */}
          <Button
            theme="ICON"
            className="lg:hidden"
            onClick={() => {
              if (isOpen && !isScreenLarge) {
                setIsOpen(false);
              }
              navigate("my-profile");
            }}
          >
            <HiOutlineUserCircle className="w-6 h-6" />
          </Button>
        </div>
        {/* NAVIGATION MENU WITH ANIMATION LARGE SCREEN VERSION */}
        {isScreenLarge && <NavigationMenu />}
        {/* NAVIGATION MENU WITH ANIMATION MOBILE VERSION */}
        <Portal
          as="div"
          className="absolute h-[calc(100vh-4rem)] top-16 z-drawer"
        >
          <Transition
            show={!isScreenLarge && isOpen}
            enter="transition transform duration-300 ease-in-out"
            enterFrom="-translate-y-full opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transition transform duration-300 ease-in-out"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="-translate-y-full opacity-0"
            className="w-screen flex flex-col h-full p-6 lg:p-0 justify-between bg-white lg:overflow-y-visible lg:visible lg:translate-y-0 lg:transition-none"
            aria-hidden={!isOpen}
          >
            <NavigationMenu />
          </Transition>
        </Portal>
      </div>
    </SidebarContext.Provider>
  );
}

export default SideBar;
