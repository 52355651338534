import React from "react";

import InlineTableFilterToggle from "@components/data-entry/inline-filter/toggle";
import InlineTableFilterWrapper from "@components/data-entry/inline-filter/wrapper";
import { usePrefixedTranslation } from "@helpers/Translation";

type FilterValues = {
  isKeyAccountFilter?: boolean;
};

type Props = {
  values?: FilterValues;
  onChange: (isKeyAccountFilter: boolean | undefined) => void;
};

export default function KeyAccountFilter({
  values = { isKeyAccountFilter: undefined },
  onChange,
}: Props) {
  const { pt } = usePrefixedTranslation("Invitation.KeyAccountFilter");

  const handleChange = () => {
    // cycles like this undefined > true > false > undefined > ...
    let value: boolean | undefined;
    if (values.isKeyAccountFilter === undefined) {
      value = true;
    } else if (values.isKeyAccountFilter === true) {
      value = false;
    }
    onChange(value);
  };
  return (
    <InlineTableFilterWrapper>
      <InlineTableFilterToggle
        checked={values.isKeyAccountFilter !== undefined}
        onClick={handleChange}
      >
        {values.isKeyAccountFilter === true && <>{pt("only-key-accounts")}★</>}
        {values.isKeyAccountFilter === false && (
          <>{pt("only-not-key-accounts")}☆</>
        )}
        {values.isKeyAccountFilter === undefined && <>{pt("all-accounts")}☆★</>}
      </InlineTableFilterToggle>
    </InlineTableFilterWrapper>
  );
}
