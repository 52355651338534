import React from "react";

import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  HiOutlineBriefcase,
  HiOutlineCalendar,
  HiOutlineEye,
  HiOutlineMail,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";
import { HiRocketLaunch } from "react-icons/hi2";
import { TbHanger } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

import Tag, { TagProps } from "@components/data-display/Tag";
import Tooltip from "@components/data-display/Tooltip";
import Button from "@components/data-entry/Button";
import { formatDateToISO } from "@helpers/Date";
import { Showroom } from "@models/Showroom";
import { ShowroomActivateEndpoint } from "@services/api/showroom/activate-showroom";
import {
  ACL_ADMINS,
  ACL_ADMINS_AGENTS_MANAGERS,
  ACL_ADMINS_MANAGERS,
} from "@shared/components/access-control/helpers";
import { getFirstOpeningDate } from "@shared/showroom/helpers";

type ShowroomStatus = "DRAFT" | "PAST" | "SHOWROOM_ONGOING";

const colors: Record<ShowroomStatus, TagProps["theme"]> = {
  DRAFT: "GREY",
  SHOWROOM_ONGOING: "GREEN",
  PAST: "GREY",
};

interface ShowroomViewCardProps {
  showroom: Showroom & {
    collections: {
      id: string;
    }[];
    sellers: {
      id: string;
    }[];
  };
  status: ShowroomStatus;
  onEdit: () => void;
  onDelete: () => void;
}

function ShowroomViewCard({
  showroom,
  status,
  onEdit,
  onDelete,
}: ShowroomViewCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, name, collections, sellers, season, year } = showroom;

  const sellersIds = sellers.map((s) => s.id);
  const collectionsIds = collections.map((c) => c.id);
  const openingDate = getFirstOpeningDate(showroom);

  const { mutateAsync: activateShowroom } = ShowroomActivateEndpoint.useHook({
    organizationId: showroom.organizationId,
  });

  return (
    <div className="px-6 py-4 border border-primaryElectricBlue bg-white rounded-lg">
      <div className="flex items-start justify-between mb-4">
        <div className="flex flex-wrap lg:flex-nowrap items-center gap-2">
          {status !== "DRAFT" && status !== "PAST" && (
            <Tag theme={colors[status]} type="dotted">
              {t(`SalesCampaign.miniview.${status}`)}
            </Tag>
          )}
          <div className="line-clamp-1 min-w-32">{name}</div>
        </div>
        <div className="flex gap-4 py-2 items-center">
          {status !== "DRAFT" && (
            <Tooltip
              placement="top"
              content={t("SalesCampaign.miniview.view-tooltip")}
            >
              <Button
                aclRoles={ACL_ADMINS}
                theme="ICON"
                label={`view showroom ${name}`}
                type="button"
                onClick={() =>
                  navigate(
                    `/calendar/${season}/${year}/daily/archive/${formatDateToISO(openingDate)}`,
                  )
                }
              >
                <HiOutlineEye />
              </Button>
            </Tooltip>
          )}
          {status === "DRAFT" && (
            <Tooltip
              placement="top"
              content={t("SalesCampaign.miniview.activate-tooltip")}
            >
              <Button
                aclRoles={ACL_ADMINS_MANAGERS}
                theme="ICON"
                label={`activate showroom ${name}`}
                type="button"
                className="bg-primaryElectricBlue fill-white text-white p-2"
                onClick={() => activateShowroom({ showroomId: id })}
              >
                <HiRocketLaunch className="w-4 h-4" />
              </Button>
            </Tooltip>
          )}
          {status !== "PAST" && status !== "SHOWROOM_ONGOING" && (
            <div className="flex gap-4">
              <Tooltip
                placement="top"
                content={t("SalesCampaign.miniview.edit-tooltip")}
              >
                <Button
                  aclRoles={ACL_ADMINS}
                  theme="ICON"
                  label={`edit showroom ${name}`}
                  type="button"
                  onClick={onEdit}
                >
                  <HiOutlinePencil />
                </Button>
              </Tooltip>
              <Tooltip
                placement="top"
                content={t("SalesCampaign.miniview.delete-tooltip")}
              >
                <Button
                  aclRoles={ACL_ADMINS}
                  theme="ICON"
                  label={`delete showroom ${name}`}
                  type="button"
                  onClick={onDelete}
                >
                  <HiOutlineTrash className="text-statusRedDark" />
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-wrap gap-4 items-center justify-between">
        <div className="flex gap-2">
          <div className="text-primaryDarkGrey">
            <div className="text-xs hidden md:flex">
              {t("SalesCampaign.miniview.opening-date-title")}
            </div>
            <div className="flex items-center gap-2 text-base">
              <HiOutlineCalendar />
              {openingDate ? format(openingDate, "dd/MM/yyyy") : "-"}
            </div>
          </div>
          <div className="w-px h-6 lg:h-10 bg-grey" />
          <div className="text-primaryDarkGrey">
            <div className="text-xs hidden md:flex">
              {t("SalesCampaign.miniview.collections-title")}
            </div>
            <div className="flex items-center gap-2">
              <TbHanger />
              {collectionsIds?.length ?? "-"}
            </div>
          </div>
          <div className="w-px h-6 lg:h-10 bg-grey" />
          <div className="text-primaryDarkGrey">
            <div className="text-xs hidden md:flex">
              {t("SalesCampaign.miniview.sellers-title")}
            </div>
            <div className="flex items-center gap-2">
              <HiOutlineBriefcase />
              {sellersIds?.length ?? "-"}
            </div>
          </div>
        </div>
        {status === "SHOWROOM_ONGOING" && (
          <Button
            aclRoles={ACL_ADMINS_AGENTS_MANAGERS}
            theme="PRIMARY"
            className="font-medium flex justify-center w-full 2xl:w-auto"
            onClick={() => navigate(`/invitations`)}
          >
            <HiOutlineMail />
            {t("SalesCampaign.miniview.manage-invite-button")}
          </Button>
        )}
      </div>
    </div>
  );
}

export default ShowroomViewCard;
