import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { organizationSchema } from "@models/Organization";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";
import { GetOrganizationCollectionsEndpoint } from "@services/api/organization/get-collections";

export namespace OrganizationPartnersWithOnboardedBrandEndpoint {
  export const inputSchema = z.object({
    brandId: z.string(),
    collections: z.array(z.string()),
  });
  export type Input = z.infer<typeof inputSchema>;

  export const outputSchema = organizationSchema;
  export type Output = z.infer<typeof outputSchema>;

  interface HookParams {
    organizationId: string;
  }

  interface MutationParams {
    data: Input;
  }

  type Params = HookParams & MutationParams;

  export const path = ({ data: { brandId }, organizationId }: Params) =>
    `/organizations/${organizationId}/partner-brands/${brandId}`;

  export const call = (params: Params) =>
    axiosInstance
      .post(path(params), inputSchema.parse(params.data))
      .then((res) => outputSchema.parse(res.data));

  export function useHook(hookParams: HookParams) {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
          ...hookParams,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(
            GetOrganizationCollectionsEndpoint.path(hookParams),
          ),
        });
      },
    });
  }
}
