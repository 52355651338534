/* eslint-disable react/no-array-index-key */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

import { useTranslation } from "react-i18next";
import { FiMapPin } from "react-icons/fi";
import { HiDocumentText } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import { baseURL } from "@app/services/global";
import {
  getAccountStatusIcon,
  getAppointmentFormatIcon,
  getAppointmentFormatLabel,
  getAppointmentTypeLabel,
  getAppointmentVirtualMeetingAppLabel,
} from "@calendar/helpers/formatters";
import { Calendar } from "@calendar/types";
import Button from "@components/data-entry/Button";
import { fullName } from "@shared/helpers/formatters";
import { translateAccountStatus } from "@shared/helpers/translater";

type Contact = Calendar.Appointment["attendees"][number];

interface AppointmentDialogViewProps {
  appointment: Calendar.AccountAppointment;
}

function AppointmentDialogView(props: AppointmentDialogViewProps) {
  const { appointment } = props;
  const navigate = useNavigate();

  const { t } = useTranslation();

  const {
    showroom: { timezone },
  } = appointment;

  const startTime = appointment.startTime.formatTimeAtTimezone(timezone);
  const endTime = appointment.endTime.formatTimeAtTimezone(timezone);
  const virtualMeetingLink = `${baseURL}/appointments/${appointment.id}/video-meeting-link`;

  const hasNoVirtualTool = appointment.warnings.includes(
    "NO_VIRTUAL_TOOL_WARNING",
  );

  const hasVirtualToolNotConfigured =
    hasNoVirtualTool ||
    appointment.warnings.includes("VIRTUAL_MEETING_APP_WARNING");

  const isMeetingReportFullyFilled =
    appointment.meetingReport !== null &&
    appointment.meetingReport.otb !== null &&
    appointment.meetingReport.actualBudget !== null;

  return (
    <>
      {appointment.account ? (
        <>
          <div data-testid="appointment-account-name">
            <h2 className="text-3xl font-medium">{appointment.account.name}</h2>
          </div>
          <div>
            <div>{getAppointmentTypeLabel(appointment.type)}</div>
            {appointment.collection && (
              <div data-testid="appointment-collection">
                {appointment.collection.name}
              </div>
            )}
            <div className="flex gap-2">
              <div className="flex items-center gap-1">
                <FiMapPin />
                <span data-testid="appointment-account-city">
                  {appointment.account.city}
                </span>
              </div>
              <div className="flex items-center gap-1">
                {getAccountStatusIcon(appointment.account.status)}
                <span className="capitalize">
                  {t(translateAccountStatus(appointment.account.status))}
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>{getAppointmentTypeLabel(appointment.type)}</div>
      )}
      <div className="h-10" />
      <div className="flex flex-col space-y-2">
        <div className="flex gap-1 item">
          <span className="font-medium">
            {t("Calendar.appointment.form.date")}:
          </span>
          <span>
            {appointment.startTime.formatLocalizedDateAtTimezone(timezone)}
          </span>
        </div>
        <div className="flex gap-1 item">
          <span className="font-medium">
            {t("Calendar.appointment.form.time")}:
          </span>
          <span>{`${startTime} - ${endTime}`}</span>
        </div>
        <div className="flex gap-1 item">
          <span className="font-medium">
            {t("Calendar.appointment.form.appointment-type")}:
          </span>
          <span className="capitalize">
            {getAppointmentTypeLabel(appointment.type)}
          </span>
          {appointment.warnings.includes("APPOINTMENT_TYPE_WARNING") && (
            <span role="alert" className="text-statusOrangeDark">
              ⚠️ {t("Calendar.appointment.form.error.not-handled-by-seller")}
            </span>
          )}
        </div>
        <div className="flex gap-1 item">
          <span className="font-medium">
            {t("Calendar.appointment.form.appointment-format")}:
          </span>
          {appointment.format ? (
            <div className="flex items-center gap-1">
              {getAppointmentFormatIcon(appointment.format)}
              {getAppointmentFormatLabel(appointment.format)}
            </div>
          ) : null}

          {hasNoVirtualTool && (
            <span role="alert" className="text-statusOrangeDark">
              ⚠️ {t("Calendar.appointment.form.error.not-handled-by-seller")}
            </span>
          )}
        </div>
        {appointment.format === "IN_PERSON" && (
          <div className="flex gap-1 item" data-testid="appointment-address">
            <span className="font-medium">
              {t("Calendar.appointment.form.address")}:
            </span>
            <span>{appointment.showroom.formattedAddress}</span>
          </div>
        )}
        {appointment.format === "VIRTUAL" && (
          <>
            <div className="flex gap-1 item">
              <span className="font-medium">
                {t("Calendar.appointment.form.virtual-label")}:
              </span>
              <div className="flex items-center gap-1">
                {appointment.virtualMeetingApp ? (
                  getAppointmentVirtualMeetingAppLabel(
                    appointment.virtualMeetingApp,
                  )
                ) : (
                  <span role="alert" className="text-statusOrangeDark">
                    {t(
                      "Calendar.appointment.form.error.virtual-not-configured",
                    )}
                    :
                  </span>
                )}
              </div>
            </div>
            <div className="flex gap-1 item" data-testid="appointment-link">
              <span className="font-medium">
                {t("Calendar.appointment.form.link")}:
              </span>
              <span>
                {hasVirtualToolNotConfigured && (
                  <span role="alert" className="text-statusOrangeDark">
                    {t(
                      "Calendar.appointment.form.error.virtual-link-not-config-by-seller",
                    )}
                  </span>
                )}
                {!hasVirtualToolNotConfigured && (
                  <a
                    className="text-primaryElectricBlue"
                    href={virtualMeetingLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {virtualMeetingLink}
                  </a>
                )}
              </span>
            </div>
          </>
        )}
        <Button
          theme="SECONDARY"
          onClick={() =>
            navigate(
              `/crm/accounts/${appointment.account.id}/meeting-report/${appointment.id}`,
            )
          }
          className="w-fit"
        >
          {isMeetingReportFullyFilled && (
            <HiDocumentText className="text-statusGreenDark" />
          )}
          {!isMeetingReportFullyFilled && (
            <HiDocumentText className="text-primaryDarkGrey" />
          )}
          {t("Calendar.appointment.form.meeting-report")}
        </Button>
      </div>
      {appointment.collectionInterests.length > 0 && (
        <>
          <hr className="my-6" />
          <ul className="flex flex-col space-y-2">
            <span className="font-medium">
              {t("Calendar.appointment.form.collection-interests")}:
            </span>
            {appointment.collectionInterests.map((collectionInterest) => (
              <li key={collectionInterest.id}>
                <span>{collectionInterest.name}</span>
                <span className="text-primaryGrey">
                  {" - "}
                  {collectionInterest.brand.name}
                </span>
              </li>
            ))}
          </ul>
        </>
      )}
      <hr className="my-6" />
      <div className="flex flex-col space-y-2">
        <div>
          <span className="font-medium">
            {t("Calendar.appointment.form.portfolios")}:
          </span>
          <ul>
            {appointment.portfolios.map((portfolio) => (
              <li key={portfolio.name}>{`${portfolio.name} - ${fullName(
                portfolio.manager,
              )}`}</li>
            ))}
          </ul>
        </div>
        <div className="flex gap-1">
          <span className="font-medium">
            {t("Calendar.appointment.form.seller")}:
          </span>
          <span>{fullName(appointment.seller)}</span>

          {appointment.warnings.includes("NOT_PREFERRED_SELLER_WARNING") && (
            <span role="alert" className="text-statusOrangeDark">
              ⚠️ {t("Calendar.appointment.form.error.not-preferred-seller")}
            </span>
          )}
        </div>
        {appointment.attendees && (
          <div className="pt-2">
            <div className="font-medium lg:-mb-2">
              {t("Calendar.appointment.form.contacts")}:
            </div>
            <div className="flex flex-col space-y-3 lg:space-x-2 lg:space-y-0 lg:mt-2 lg:flex-row lg:items-start">
              {appointment.attendees.map((contact: Contact, index) => (
                <div key={`acc_seller_key_${index}`} className="text-sm w-44">
                  <p className="text-base font-normal truncate text-primaryBlack">
                    {fullName(contact)}
                  </p>
                  <p className="truncate hover:break-words text-primaryDarkGrey">
                    {t("Calendar.appointment.attendees.position")}
                    {": "}
                    {contact.position}
                  </p>
                  <p className="truncate hover:break-words text-primaryDarkGrey">
                    {t("Calendar.appointment.attendees.markets")}
                    {": "}
                    {contact.markets.map((m) => m.toLowerCase()).join(", ")}
                  </p>
                  <p className="truncate hover:break-words text-primaryDarkGrey">
                    {contact.email}
                  </p>
                  <p className="truncate text-primaryDarkGrey">
                    {contact.phoneNumber}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default React.memo(AppointmentDialogView);
