import React from "react";

import { flag } from "country-emoji";
import { useTranslation } from "react-i18next";
import { HiOutlineLocationMarker } from "react-icons/hi";

import { Cruising } from "@app/modules/cruising/types";
import { formatLongLocalizedDate, formatTime } from "@helpers/Date";

function formatApiTime(time: string) {
  return time.replace("-", ":");
}

function addDuration(timeStr: string, duration: number) {
  const [startHr, startMin] = formatApiTime(timeStr).split(":").map(Number);

  const endMinsTmp = startMin + duration;

  const hrToAdd = Math.floor(endMinsTmp / 60);
  const endHr = startHr + hrToAdd;
  const endMin = endMinsTmp % 60;

  return [`${endHr}`.padStart(2, "0"), `${endMin}`.padStart(2, "0")].join(":");
}

function addMeta(
  resultShowroom: Cruising.TripPlanification[number]["tour"][number]["appointement"],
  showrooms: Cruising.NewShowroomList,
) {
  const correspondingShowroom = showrooms.find(
    (s) =>
      resultShowroom.showroom === s.name &&
      resultShowroom.address === s.address.formattedAddress,
  );

  if (!correspondingShowroom) {
    // eslint-disable-next-line no-console
    console.error(resultShowroom, showrooms);
    throw new Error(
      "Did not find corresponding showroom, something unexpected happened",
    );
  }

  return {
    ...resultShowroom,
    meta: {
      city: correspondingShowroom.address.city,
      country: correspondingShowroom.address.countryCode,
      address: correspondingShowroom.address.formattedAddress,
      openingHour: correspondingShowroom.startTime,
      closingHour: correspondingShowroom.endTime,
    },
  };
}

type Props = {
  tripPlan: Cruising.TripPlanification;
  showrooms: Cruising.NewShowroomList;
};

export default function TripPlanification({ tripPlan, showrooms }: Props) {
  const { t } = useTranslation();

  const tripPlanWithMeta = tripPlan
    .toSorted((stepLeft, stepRight) =>
      new Date(stepLeft.date).getTime() < new Date(stepRight.date).getTime()
        ? -1
        : 1,
    )
    .map(({ date, tour }) => ({
      date,
      tour: tour.map(({ appointement, time }) => ({
        appointement: addMeta(appointement, showrooms),
        time,
      })),
    }));

  return (
    <>
      {tripPlanWithMeta
        // display only dates with appointments
        .filter(({ tour }) => tour.length > 0)
        .map(({ date, tour }) => (
          <div key={date}>
            <h2 className="font-bold border-4 p-2 rounded-full border-primaryElectricBlue text-primaryElectricBlue">
              {formatLongLocalizedDate(new Date(date))}
            </h2>
            {tour.map((place, index, arr) => (
              <div
                className="grid grid-cols-10 group"
                key={`${place.time}-${place.appointement.showroom}`}
              >
                <div className="mr-4 lg:col-start-1 col-span-2 relative border-r border-primaryLightGrey flex items-start text-md gap-2 justify-end pr-4 pt-5 after:content-[''] after:absolute after:-right-2 after:top-6 after:w-4 after:h-4 after:bg-white after:rounded-full after:border-4 after:transition-all group-hover:after:border-[0.5rem] after:border-primaryElectricBlue">
                  {formatApiTime(place.time)} -{" "}
                  {addDuration(place.time, place.appointement.duration)}
                </div>
                <div
                  className="grid grid-cols-1 col-span-8 gap-2 mb-4 mr-4"
                  key={place.appointement.showroom}
                >
                  {/** COUNTRY/CITY BADGE */}
                  {(index === 0 ||
                    place.appointement.meta.city !==
                      arr[index - 1].appointement.meta.city) && (
                    <div
                      className={`w-fit rounded-full space-x-2 ${
                        index % 2 === 0
                          ? "bg-primaryLightElectricBlue"
                          : "bg-statusOrangeLight"
                      } px-2`}
                    >
                      <span>{flag(place.appointement.meta.country)}</span>
                      <span>{place.appointement.meta.city}</span>
                    </div>
                  )}

                  {/** APPOINTMENT CARD */}
                  <div
                    className={`col-start-1 col-span-4 grid grid-cols-[16px_repeat(11,_minmax(0,_1fr))] gap-x-2 rounded-lg ${
                      index % 2 === 0
                        ? "bg-primaryLightElectricBlue"
                        : "bg-statusOrangeLight"
                    } p-4`}
                  >
                    {/** FIRST ROW */}
                    <span className="col-span-12 text-lg font-bold text-primaryElectricBlue">
                      {place.appointement.showroom}
                    </span>

                    {/** IN PERSON ROW */}
                    <span className="flex items-center justify-start w-4">
                      <HiOutlineLocationMarker />
                    </span>
                    <span className="flex items-center col-span-11 text-md">
                      {place.appointement.meta.address}
                      {" | "}
                      {t("Cruising.TripPlanification.showroom-open-from-to", {
                        opensAt: formatTime(
                          place.appointement.meta.openingHour,
                        ),
                        closesAt: formatTime(
                          place.appointement.meta.closingHour,
                        ),
                      })}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
    </>
  );
}
