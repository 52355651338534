import React from "react";

import { differenceInHours, getHours } from "date-fns";

import { ROW_HEIGHT } from "@calendar/style";
import { DateInterval } from "@models/types/DateInterval";

function TimeColumn(props: { interval: DateInterval }) {
  const { interval } = props;

  const startHour: number = getHours(interval.start);
  const numberOfRows: number = differenceInHours(interval.end, interval.start);

  const rows = [];
  for (let index = 0; index < numberOfRows; index++) {
    const hour = startHour + index;
    rows.push(
      <div
        className="text-sm text-center bg-white text-primaryDarkGrey relative "
        data-testid="time-column"
        id={`${hour}:00`}
        key={`time-column-${hour}`}
      >
        {hour}:00
      </div>,
    );
  }

  const gridTemplateRows = `repeat(auto-fill, ${ROW_HEIGHT * 4}rem)`;

  return (
    <div
      data-testid="time-columns"
      className="grid grid-rows-timeSlot sticky z-sidebar left-0 pr-2"
      style={{ gridTemplateRows }}
    >
      {rows}
    </div>
  );
}

export default TimeColumn;
