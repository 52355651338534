import React from "react";

import { Portal } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { IoIosClose } from "react-icons/io";

import Button from "@components/data-entry/Button";
import FadeInOut from "@components/transitions/FadeInOut";

import Backdrop from "../layout/Backdrop";

function InformationModal({
  title,
  closeLabel,
  onClose,
  children,
  show,
}: {
  title: string;
  closeLabel?: string;
  onClose: () => void;
  children: React.ReactNode;
  show: boolean;
}) {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
    <Portal as="div" className="fixed z-modal">
      <FadeInOut show={show}>
        <div className="flex justify-center items-center fixed top-0 left-0 w-screen h-screen ">
          <Backdrop onClick={handleClose} />
          <div
            role="dialog"
            aria-modal="true"
            className="mx-3 max-w-lg lg:w-[28rem] fixed bg-white p-6 shadow-md rounded-lg"
          >
            <div className="flex justify-between items-center">
              <div className="py-2 font-medium">{title}</div>
              <Button
                className="cursor-pointer"
                onClick={handleClose}
                data-testid="modal-close"
                theme="ICON"
              >
                <IoIosClose className="w-8 h-8" />
              </Button>
            </div>
            <div>
              <div className="py-4">{children}</div>
              <div className="flex justify-end">
                <Button
                  type="button"
                  theme="PRIMARY"
                  className="w-24 justify-center"
                  onClick={handleClose}
                  testId="information-dialog-close"
                >
                  {closeLabel || (
                    <span>{t("Components.buttons.close-modal")}</span>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </FadeInOut>
    </Portal>
  );
}

export default InformationModal;
