import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { budgetSchema } from "@models/Budget";
import axiosInstance from "@services/api/config";

export namespace BookingSaveBuyerOtbsEndpoint {
  export const inputSchema = z.object({
    buyerOtbs: z.array(
      z.object({
        collectionId: budgetSchema.shape.collectionId,
        otb: budgetSchema.shape.otb,
      }),
    ),
  });
  export type Input = z.infer<typeof inputSchema>;

  export const outputSchema = z.array(budgetSchema);
  export type Output = z.infer<typeof outputSchema>;

  interface HookParams {
    invitationId: string;
  }

  interface MutationParams {
    data: Input;
  }

  type Params = HookParams & MutationParams;

  export const path = ({ invitationId }: Params) =>
    `/bookings/${invitationId}/buyer-otbs`;

  export const call = (params: Params) =>
    axiosInstance
      .post(path(params), inputSchema.parse(params.data))
      .then((res) => res.data)
      .catch(console.error);

  export function useHook(hookParams: HookParams) {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
          ...hookParams,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [],
        });
      },
    });
  }
}
