import React, { ReactNode, createContext, useMemo } from "react";

import { useLocalStorage } from "usehooks-ts";

import { SalesCampaign } from "@models/SalesCampaign";
import { GetOrganizationEndpoint } from "@services/api/organization/get-organization";

export type ApplicationContextType = {
  organization?: GetOrganizationEndpoint.Output;
  setOrganization: (organization?: GetOrganizationEndpoint.Output) => void;
  currentSalesCampaignId: SalesCampaign["id"] | null;
  setCurrentSalesCampaignId: (id: SalesCampaign["id"] | null) => void;
  isAccountPortfolioBasedOnCollections: boolean;
};

export const ApplicationContext = createContext({} as ApplicationContextType);

export function ApplicationContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [currentSalesCampaignId, setCurrentSalesCampaignId] = useLocalStorage<
    SalesCampaign["id"] | null
  >("current-sales-campaign", null);

  const [organization, setOrganization] = useLocalStorage<
    GetOrganizationEndpoint.Output | undefined
  >("current-organization", undefined);

  const context: ApplicationContextType = useMemo(
    () => ({
      organization,
      setOrganization,
      currentSalesCampaignId,
      setCurrentSalesCampaignId,
      isAccountPortfolioBasedOnCollections: organization
        ? organization.accountPortfolioType === "PORTFOLIO_BASED_ON_COLLECTIONS"
        : false,
    }),
    [
      organization,
      setOrganization,
      currentSalesCampaignId,
      setCurrentSalesCampaignId,
    ],
  );
  return (
    <ApplicationContext.Provider value={context}>
      {children}
    </ApplicationContext.Provider>
  );
}
