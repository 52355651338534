import React from "react";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HiCheck } from "react-icons/hi";

import Button from "@components/data-entry/Button";
import MultiSelect from "@components/data-entry/MultiSelect";
import { OptionGroupItem } from "@components/data-entry/Select";
import SingleSelect from "@components/data-entry/SingleSelect";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import CalloutBox from "@components/feedback/CalloutBox";
import Loading from "@components/feedback/Loading";
import BottomBar from "@components/layout/BottomBar";
import { OrganizationRepresentative } from "@models/OrganizationRepresentative";
import { Showroom } from "@models/Showroom";
import { AppointmentTypeEnum } from "@models/types/enums";
import { useFetchRepresentatives } from "@services/api/old/representatives/fetch-representatives";
import { useSalesCampaignAddSellerMutation } from "@services/api/sales-campaigns/add-seller";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import LogService from "@services/log/service";
import { fullName } from "@shared/helpers/formatters";

export function createOptionsFromSellers(
  sellers: {
    id: string;
    firstName: string;
    lastName: string;
    portfolios?: { name: string }[];
  }[],
) {
  const options: OptionGroupItem["options"] = [];
  sellers.forEach((seller) => {
    options.push({
      label: fullName(seller),
      subLabel: seller.portfolios?.map((p) => p.name).join(", "),
      value: seller.id,
    });
  });

  return options;
}

interface SalesCampaignAddSellerFormProps {
  showroom: Showroom & {
    sellers: {
      id: OrganizationRepresentative["id"];
      firstName: OrganizationRepresentative["firstName"];
      lastName: OrganizationRepresentative["lastName"];
    }[];
  };
  onSuccess: () => void;
}

export interface SalesCampaignAddSellerFormData {
  seller: OrganizationRepresentative["id"];
  appointmentTypes: SelectOptionBase[];
}

export function useSalesCampaignAddSellerForm() {
  const form = useForm<SalesCampaignAddSellerFormData>({});
  return {
    form,
  };
}

function SalesCampaignAddSellerForm(props: SalesCampaignAddSellerFormProps) {
  const { showroom, onSuccess } = props;
  const { sellers } = showroom;
  const { t } = useTranslation();
  const { form } = useSalesCampaignAddSellerForm();
  const { organization } = useOrganizationAppContext();

  const { id: organizationId } = organization;

  const { mutateAsync: addSellerToShowroomMutation } =
    useSalesCampaignAddSellerMutation({
      organizationId,
      showroomId: showroom.id,
    });

  const { data: fetchedRepresentatives } =
    useFetchRepresentatives(organizationId);
  if (!fetchedRepresentatives) {
    return <Loading type="screen" />;
  }
  const availableSellers = fetchedRepresentatives?.filter(
    (representative) =>
      !sellers?.find((seller) => seller.id === representative.id),
  );

  const onSubmit = (data: SalesCampaignAddSellerFormData) => {
    addSellerToShowroomMutation({
      sellerId: data.seller,
      appointmentTypes: data.appointmentTypes.map(
        (at) => at.value as AppointmentTypeEnum,
      ),
    })
      .then(onSuccess)
      .catch((err) => {
        LogService.error(err);
      });
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = form;

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-6 p-10 pt-6 grow">
        {availableSellers.length === 0 && (
          <CalloutBox type="WARNING">
            {t("SalesCampaign.overview.add-seller-form.no-sellers")}
          </CalloutBox>
        )}
        <div className="flex flex-col gap-2">
          <label htmlFor="showroom-sellers">
            {t("SalesCampaign.overview.add-seller-form.select-seller.label")}
          </label>
          <Controller
            name="seller"
            control={control}
            rules={{
              validate: {
                atLeastOneRequired: (value) =>
                  !!value ||
                  t("SalesCampaign.showroom.structure.sellers.required"),
              },
            }}
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <SingleSelect
                value={{
                  label: fullName(
                    availableSellers.find((seller) => seller.id === value) || {
                      firstName: "--",
                      lastName: "--",
                    },
                  ),
                  value,
                }}
                name={name}
                id="showroom-sellers"
                onChange={(o) => onChange(o?.value)}
                options={createOptionsFromSellers(availableSellers)}
                disabled={availableSellers.length === 0}
                className={`${error && "border-primaryRed"}`}
                placeholder={t(
                  "SalesCampaign.overview.add-seller-form.select-seller.placeholder",
                )}
              />
            )}
          />
          {errors.seller?.message && (
            <p className="p-4 text-primaryRed">{errors.seller.message}</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="appointmentTypes">
            {t(
              "SalesCampaign.overview.add-seller-form.select-appointment-type.label",
            )}
          </label>
          <Controller
            name="appointmentTypes"
            control={control}
            rules={{
              validate: {
                atLeastOneRequired: (value) =>
                  (value && value.length > 0) ||
                  t("SalesCampaign.showroom.structure.sellers.required"),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <MultiSelect
                id="appointmentTypes"
                name="appointmentTypes"
                placeholder={t(
                  "SalesCampaign.overview.add-seller-form.select-appointment-type.placeholder",
                )}
                value={value}
                disabled={availableSellers.length === 0}
                options={showroom.appointmentTypes.map((at) => ({
                  label: t(`Common.appointment-type.${at}`),
                  value: at,
                }))}
                onChange={onChange}
              />
            )}
          />
          {errors.appointmentTypes?.message && (
            <p className="p-4 text-primaryRed">
              {errors.appointmentTypes.message}
            </p>
          )}
        </div>
      </div>
      <BottomBar>
        <Button theme="PRIMARY" type="submit" disabled={!isValid}>
          {t("SalesCampaign.overview.add-seller-form.submit")}
          <HiCheck className="w-5 h-5" />
        </Button>
      </BottomBar>
    </form>
  );
}

export default SalesCampaignAddSellerForm;
