import React from "react";

import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import queryClient from "@app/queryClient";
import AdminOnboardingBreadcrumb from "@backoffice/components/onboarding/components/AdminOnboardingBreadcrumb";
import Loading from "@components/feedback/Loading";
import { AdminOnboardingStep, Organization } from "@models/old/Organization";
import OnboardingFormContainer from "@onboarding/components/OnboardingFormContainer";
import OrganizationAPI from "@services/api/old/organization/OrganizationAPI";
import { OrganizationInformationFormData } from "@shared/organization/organization-information-form";

const getOrganizationOnboardingInformationQuery = (organizationId: string) => ({
  queryKey: ["get-organization-onboarding-infos", organizationId],
  queryFn: () => OrganizationAPI.getOrganizationInformation(organizationId),
});

export default function AdminOnboardingOrganizationInformationPage() {
  const navigate = useNavigate();
  const { organizationId } = useParams();

  if (!organizationId) {
    throw new Error(
      "AdminOnboardingOrganizationInformationPage - no organizationId",
    );
  }

  const {
    data: onboardingInformation,
    isLoading,
    isError,
    error,
  } = useQuery(getOrganizationOnboardingInformationQuery(organizationId));

  if (isLoading) {
    return <Loading type="screen" />;
  }

  if (isError) {
    throw error;
  }

  const onSubmit = (onboarding: OrganizationInformationFormData) => {
    if (organizationId) {
      return OrganizationAPI.updateOrganizationInformation(
        organizationId,
        onboarding,
      ).then((updatedOrganization: Organization) => {
        queryClient.invalidateQueries({
          queryKey: ["get-organization-onboarding-infos", organizationId],
        });
        toast.success(`Organization ${updatedOrganization.name} updated`);
        navigate(
          `/backoffice/onboard-organization/${organizationId}/accounts-upload`,
        );
      });
    }
    return OrganizationAPI.startOnboarding(onboarding).then(
      (createdOrganization: Organization) => {
        queryClient.invalidateQueries({
          queryKey: ["get-organization-onboarding-infos", organizationId],
        });
        toast.success(`Organization ${createdOrganization.name} created`);
        navigate(
          `/backoffice/onboard-organization/${createdOrganization.id}/accounts-upload`,
        );
      },
    );
  };

  return (
    <div className="h-full flex flex-col">
      <div className="px-10">
        <AdminOnboardingBreadcrumb
          currentStep={AdminOnboardingStep.ORGANIZATION_CREATION}
        />
      </div>
      <OnboardingFormContainer
        admin
        existing={
          onboardingInformation
            ? { ...onboardingInformation, bannerFile: null }
            : undefined
        }
        onSubmit={onSubmit}
      />
    </div>
  );
}
