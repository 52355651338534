import React from "react";

import { Outlet, useNavigation } from "react-router-dom";

import Loading from "@components/feedback/Loading";
import StopImpersonation from "@services/authentication/stop-impersonation";
import ErrorBoundary from "@services/error/error-boundary";
import { AuthOnly } from "@shared/components/guards/authentication";
import OnboardingNotDone from "@shared/components/guards/onboarding-not-done";

function OnboardingLayout() {
  const navigation = useNavigation();

  return (
    <AuthOnly>
      <OnboardingNotDone>
        <div className="flex justify-start w-full">
          <div className="flex h-full w-full">
            <main className="w-full flex flex-col grow">
              {navigation.state !== "idle" ? (
                <Loading type="screen" />
              ) : (
                <ErrorBoundary>
                  <Outlet />
                </ErrorBoundary>
              )}
            </main>
          </div>
          <StopImpersonation />
        </div>
      </OnboardingNotDone>
    </AuthOnly>
  );
}

export default OnboardingLayout;
