import React from "react";

import { cva } from "class-variance-authority";

import { CheckboxMark } from "@components/data-entry/Checkbox";
import MultiSelect from "@components/data-entry/MultiSelect";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";

export interface GroupedSelectProps {
  id?: string;
  title: string;
  description?: string;
  options: SelectOptionBase[];
  selected: SelectOptionBase[];
  onChange: (options: Readonly<SelectOptionBase[]>) => void;
}

const containerStyle = cva("border rounded-md p-4", {
  variants: {
    checked: {
      true: "border-primaryElectricBlue bg-primaryLightElectricBlue",
      false: "border-primaryGrey",
    },
  },
});

const titleStyle = cva("", {
  variants: {
    checked: {
      true: "font-medium",
      false: "",
    },
  },
});

export default function GroupedSelect({
  id,
  title,
  description,
  options,
  selected,
  onChange,
}: GroupedSelectProps) {
  const checked = selected.length > 0;

  return (
    <div className={containerStyle({ checked })}>
      <button
        className="flex items-center gap-4"
        type="button"
        onClick={() => {
          onChange(checked ? [] : options);
        }}
        id={id}
      >
        <CheckboxMark state={checked} />
        <div className={titleStyle({ checked })}>{title}</div>
      </button>
      {checked && (
        <>
          {description && <div>{description}</div>}
          <MultiSelect options={options} value={selected} onChange={onChange} />
        </>
      )}
    </div>
  );
}
