import React from "react";

import { useTranslation } from "react-i18next";

import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import CollectionsOverviewAgency from "@shared/collections/agency-collection-overview";
import CollectionsOverview from "@shared/collections/collection-overview";

function OrganizationProfileCollections() {
  const { t } = useTranslation();
  const { organization } = useOrganizationAppContext();

  return (
    <div className="p-10 grow">
      <h1 className="heading-1 mb-10">
        {t("OrganizationProfile.collections.title")}
      </h1>
      <div className="flex gap-6">
        {organization.type === "AGENCY" ? (
          <CollectionsOverviewAgency />
        ) : (
          <CollectionsOverview />
        )}
      </div>
    </div>
  );
}

export default OrganizationProfileCollections;
