import React from "react";

import TextField from "@components/data-entry/TextField";
import { usePrefixedTranslation } from "@helpers/Translation";

type Props = {
  value: string;
  onChange: (value: string) => void;
};

export default function WildcardFilter({ onChange, value }: Props) {
  const { pt } = usePrefixedTranslation("Invitation.WildcardFilter");
  return (
    <TextField
      value={value}
      onChange={onChange}
      placeholder={pt("placeholder")}
    />
  );
}
