import React, { useEffect, useMemo, useState } from "react";

import Checkbox from "@components/data-entry/Checkbox";
import TextField from "@components/data-entry/TextField";
import { usePrefixedTranslation } from "@helpers/Translation";

import { CalendarFilter } from "./filters.manager";

interface UseCalendarFiltersProps {
  showrooms: CalendarFilter.CalendarFilterShowroom[];
  onFilterChange: (text: string) => void;
}

export function useCalendarFilters({
  showrooms,
  onFilterChange,
}: UseCalendarFiltersProps) {
  const [values, setValues] = useState(
    CalendarFilter.Manager.buildAvailableValues(showrooms),
  );
  const [textFilter, setTextFilter] = useState("");

  useEffect(() => {
    setValues(CalendarFilter.Manager.buildAvailableValues(showrooms));
  }, [showrooms]);

  const filterManager = useMemo(
    () => new CalendarFilter.Manager(showrooms, values),
    [values, showrooms],
  );

  const handleChange = (newValues: CalendarFilter.FilterValues) => {
    setValues(newValues);
  };

  const handleFilterChange = (t: string) => {
    onFilterChange(t);
    setTextFilter(t);
  };

  return {
    onFilterChange: handleFilterChange,
    filterValue: textFilter,
    onChange: handleChange,
    filterManager,
    selectedShowroomIds: Object.keys(values),
    selectedBrandIds: Object.values(values).reduce(
      (acc, showroom) => acc.concat(Object.keys(showroom)),
      [] as string[],
    ),
    selectedCollectionIds: Object.values(values).reduce(
      (acc, showroom) =>
        acc.concat(
          Object.values(showroom).reduce(
            (acc2, brandCollectionIds) => acc2.concat(brandCollectionIds),
            [],
          ),
        ),
      [] as string[],
    ),
  };
}

function FilterItem({ checked, onClick, label, children }: any) {
  return (
    <li className="flex flex-col">
      <label
        className="peer flex gap-2 items-center cursor-pointer hover:bg-gray-100 px-1 rounded-t-md"
        htmlFor={`checkbox-${label}`}
      >
        <Checkbox
          id={`checkbox-${label}`}
          name={`checkbox-${label}`}
          onChange={onClick}
          checked={checked}
        />
        {label}
      </label>
      {children && (
        <ul className="peer-hover:bg-gray-100/30 flex flex-col list-none pl-4">
          {children}
        </ul>
      )}
    </li>
  );
}

type Props = ReturnType<typeof useCalendarFilters> & {};

export default function CalendarFilters({
  onFilterChange,
  filterValue,
  filterManager,
  onChange,
}: Props) {
  const { pt } = usePrefixedTranslation("Calendar.CalendarFilters");

  return (
    <>
      <div>
        <TextField
          onChange={onFilterChange}
          value={filterValue}
          placeholder={pt("filter-placeholder")}
        />
      </div>
      <ul className="flex flex-col">
        {filterManager.filterDisplay.map((showroom) => (
          <FilterItem
            key={showroom.id}
            label={showroom.name}
            onClick={() => onChange(filterManager.toggleShowroom(showroom.id))}
            checked={filterManager.isShowroomSelected(showroom.id)}
          >
            {showroom.brands.map((brand) => (
              <FilterItem
                key={brand.id}
                label={brand.name}
                onClick={() =>
                  onChange(filterManager.toggleBrand(showroom.id, brand.id))
                }
                checked={filterManager.isBrandSelected(showroom.id, brand.id)}
              >
                {brand.collections.map((collection) => (
                  <FilterItem
                    key={collection.id}
                    label={collection.name}
                    onClick={() =>
                      onChange(
                        filterManager.toggleCollection(
                          showroom.id,
                          brand.id,
                          collection.id,
                        ),
                      )
                    }
                    checked={filterManager.isCollectionSelected(
                      showroom.id,
                      brand.id,
                      collection.id,
                    )}
                  />
                ))}
              </FilterItem>
            ))}
          </FilterItem>
        ))}
      </ul>
    </>
  );
}
