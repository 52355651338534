import React from "react";

import { t } from "i18next";
import { toast } from "react-toastify";

import ArchivedRepresentativesTable from "@app/modules/representatives/components/archived-representatives-table";
import RepresentativesHeader from "@app/modules/representatives/components/header";
import queryClient from "@app/queryClient";
import Loading from "@components/feedback/Loading";
import { Representative } from "@models/old/Representative";
import { queryKeysFetchRepresentatives } from "@services/api/old/representatives/fetch-representatives";
import {
  queryKeysFetchRepresentativesArchived,
  useFetchRepresentativesArchived,
} from "@services/api/old/representatives/fetch-representatives-archived";
import { queryKeysFetchRepresentativesArchivedCount } from "@services/api/old/representatives/fetch-representatives-archived-count";
import { queryKeysFetchRepresentativesCount } from "@services/api/old/representatives/fetch-representatives-count";
import { usePutRestoreRepresentative } from "@services/api/old/representatives/put-restore-representative";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import LogService from "@services/log/service";

function RepresentativesArchived() {
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const { mutateAsync: putRestoreRepresentative } =
    usePutRestoreRepresentative(organizationId);

  const { data: representatives, isLoading: isLoadingList } =
    useFetchRepresentativesArchived(organizationId);

  const handleRestoreRepresentative = (representative: Representative) => {
    putRestoreRepresentative(representative?.id)
      .then(async () => {
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchRepresentatives(organizationId),
        });
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchRepresentativesArchived(organizationId),
        });
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchRepresentativesCount(organizationId),
        });
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchRepresentativesArchivedCount(organizationId),
        });
        toast.success(
          t("Representatives.archived.confirm-representative-restore"),
        );
      })
      .catch((err) => {
        LogService.error(err);
      });
  };

  return (
    <div>
      <RepresentativesHeader />
      <div className="p-10">
        {isLoadingList && <Loading type="button" />}
        {!isLoadingList && (
          <ArchivedRepresentativesTable
            representatives={
              representatives?.sort((a, b) =>
                a.firstName.localeCompare(b.firstName),
              ) || []
            }
            onClickRestore={handleRestoreRepresentative}
          />
        )}
      </div>
    </div>
  );
}

export default RepresentativesArchived;
