import React from "react";

import { Navigate, Outlet, useNavigation } from "react-router-dom";

import Loading from "@components/feedback/Loading";
import useApplicationContext from "@services/application/useApplicationContext";
import useAuthentication from "@services/authentication/useAuthentication";
import ErrorBoundary from "@services/error/error-boundary";
import SideBar from "@shared/components/frontoffice/SideBar";
import { AuthOnly } from "@shared/components/guards/authentication";
import OnboardingDone from "@shared/components/guards/onboarding-done";

function LayoutFrontOffice() {
  const navigation = useNavigation();
  const { currentUser } = useAuthentication();
  const { organization } = useApplicationContext();

  if (currentUser && currentUser.role === "MODARESA_ADMIN" && !organization) {
    return <Navigate to="/backoffice/list-organizations" replace />;
  }

  return (
    <AuthOnly>
      <OnboardingDone>
        <div className="flex justify-start w-full h-full">
          <div className="flex flex-col w-full h-full lg:flex-row">
            <SideBar />
            <main className="w-full h-screen overflow-auto no-scrollbar">
              {navigation.state !== "idle" ? (
                <Loading type="screen" />
              ) : (
                <ErrorBoundary>
                  <Outlet />
                </ErrorBoundary>
              )}
            </main>
          </div>
        </div>
      </OnboardingDone>
    </AuthOnly>
  );
}

export default LayoutFrontOffice;
