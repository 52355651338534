import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import {
  HiMail,
  HiOutlineBriefcase,
  HiOutlineChartSquareBar,
  HiOutlineCog,
  HiOutlineHome,
  HiOutlineLogout,
  HiOutlineUserCircle,
  HiOutlineUserGroup,
} from "react-icons/hi";
import { HiCalendarDays } from "react-icons/hi2";

import Button from "@components/data-entry/Button";
import { UserRole } from "@models/types/enums";
import useAuthentication from "@services/authentication/useAuthentication";

import CrispButton from "../CrispButton";
import {
  ACL_ADMINS_AGENTS_MANAGERS,
  ACL_ADMINS_MANAGERS,
} from "../access-control/helpers";
import SideBarItem from "./SideBarItem";
import { useSidebarContext } from "./context/sidebar";

interface SidebarItemDef {
  path: string;
  label: string;
  icon?: React.JSX.Element;
  aclRoles?: UserRole[];
  subItems?: SidebarItemDef[];
}

function NavigationMenu() {
  const { isFold } = useSidebarContext();
  const { signOut, stopImpersonation, impersonation } = useAuthentication();
  const { t } = useTranslation();

  const [expandedItem, setExpandedItem] = useState<string | null>(null);

  const sideBarItems: SidebarItemDef[] = [
    {
      path: "dashboard",
      label: t("Components.sidebar.dashboard"),
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
      icon: <HiOutlineHome className="w-6 h-6" />,
    },
    {
      path: "showroom",
      label: t("Components.sidebar.sales-campaigns"),
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
      icon: <HiOutlineChartSquareBar className="w-6 h-6" />,
    },
    {
      path: "crm",
      label: t("Components.sidebar.crm.name"),
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
      icon: <HiOutlineUserGroup className="w-6 h-6" />,
      subItems: [
        {
          label: t("Components.sidebar.crm.accounts"),
          path: "crm/accounts",
        },
        {
          label: t("Components.sidebar.crm.contacts"),
          path: "crm/contacts",
        },
        {
          label: t("Components.sidebar.crm.competitors-groups"),
          path: "crm/competitors",
          aclRoles: ACL_ADMINS_MANAGERS,
        },
        {
          label: t("Components.sidebar.crm.accounts-archived"),
          path: "crm/archived-accounts",
        },
        {
          label: t("Components.sidebar.crm.contacts-archived"),
          path: "crm/archived-contacts",
        },
      ],
    },
    {
      path: "representatives/list",
      label: t("Components.sidebar.organization-representatives"),
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
      icon: <HiOutlineBriefcase className="w-6 h-6" />,
    },
    {
      path: "organization-profile",
      label: t("Components.sidebar.organization-profile.name"),
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
      icon: <HiOutlineCog className="w-6 h-6" />,
      subItems: [
        {
          label: t("Components.sidebar.organization-profile.information"),
          path: "organization-profile/information",
        },
        {
          label: t("Components.sidebar.organization-profile.collections"),
          path: "organization-profile/collections",
        },
        {
          label: t("Components.sidebar.organization-profile.portfolios"),
          path: "organization-profile/portfolios",
        },
      ],
    },
    {
      path: "calendar",
      label: t("Components.sidebar.calendar"),
      icon: <HiCalendarDays className="w-6 h-6" />,
    },
    {
      path: "invitations",
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
      label: t("Components.sidebar.invitations"),
      icon: <HiMail className="w-6 h-6" />,
    },
  ];

  return (
    <div className="flex flex-col gap-6 justify-between grow">
      <nav aria-label="Main Navigation" className="flex flex-col gap-2 lg:grow">
        <ul className="flex flex-col gap-2">
          {sideBarItems.map((item) => (
            <li key={item.path}>
              <SideBarItem
                aclRoles={item.aclRoles}
                path={item.path}
                label={item.label}
                icon={item.icon}
                subItems={item.subItems}
                isExpanded={expandedItem ? expandedItem === item.path : false}
                onClick={() => {
                  if (expandedItem === item.path) {
                    setExpandedItem(null);
                  } else {
                    setExpandedItem(item.path);
                  }
                }}
              />
            </li>
          ))}
        </ul>
      </nav>
      {/* FOOTER */}
      <div className="flex flex-col justify-end gap-2">
        {/* PROFILE BUTTON (HIDDEN IN MOBILE VERSION) */}
        <SideBarItem
          path="my-profile"
          label="My Profile"
          icon={<HiOutlineUserCircle className="w-6 h-6" />}
          className="hidden lg:flex"
        />
        <CrispButton />
        {/* IMPERSONATION OR LOG OUT BUTTONS */}
        {impersonation ? (
          <Button
            theme="DANGER"
            type="button"
            label="button for stop impersonation"
            className={`hover:opacity-70 flex gap-2 ${
              isFold ? "flex-col" : "flex-row"
            }`}
            onClick={() => stopImpersonation()}
          >
            <HiOutlineLogout className="w-6 h-6" />
            {!isFold && t("Components.sidebar.stop-impersonation")}
          </Button>
        ) : (
          <Button
            theme="ICON"
            type="button"
            label="button for log out"
            className={`flex items-center gap-2 p-2 rounded-lg hover:bg-primaryLightElectricBlue hover:text-primaryElectricBlue ${
              isFold ? "flex-col" : "flex-row"
            }`}
            onClick={() => signOut()}
          >
            <HiOutlineLogout className="w-6 h-6" />
            {!isFold && t("Components.sidebar.logout")}
          </Button>
        )}
      </div>
    </div>
  );
}

export default NavigationMenu;
