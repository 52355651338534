import { useMutation } from "@tanstack/react-query";

import { Account } from "@models/old/Account";
import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

export const putRestoreAccountEndpoint = {
  path: (organizationId: Organization["id"], accountId: Account["id"]) =>
    `/organizations/${organizationId}/accounts/${accountId}/restore`,
};

export function usePutRestoreAccount(organizationId: string) {
  return useMutation({
    mutationFn: (accountId: string) =>
      axiosInstance.put(
        putRestoreAccountEndpoint.path(organizationId, accountId),
      ),
  });
}
