import { useQuery } from "@tanstack/react-query";

import { Organization } from "@models/old/Organization";
import { Representative } from "@models/old/Representative";
import axiosInstance from "@services/api/config";

export const fetchRepresentativeEndpoint = {
  path: (
    organizationId: Organization["id"],
    representativeId: Representative["id"],
  ) => `/organizations/${organizationId}/representatives/${representativeId}`,
};

type FetchRepresentativeByParamResponse = Representative;

export const fetchRepresentative = (
  organizationId: string,
  representativeId: string,
) =>
  axiosInstance
    .get<FetchRepresentativeByParamResponse>(
      fetchRepresentativeEndpoint.path(organizationId, representativeId),
    )
    .then((res) => res.data);

export const queryKeysFetchRepresentative = (
  organizationId: string,
  representativeId: string,
) =>
  fetchRepresentativeEndpoint.path(organizationId, representativeId).split("/");

export const useFetchRepresentative = (
  organizationId: string,
  representativeId: string,
) =>
  useQuery({
    queryKey: queryKeysFetchRepresentative(organizationId, representativeId),
    queryFn: () => fetchRepresentative(organizationId, representativeId),
  });
