import { VirtualMeetingApps } from "@models/types/enums";

import { MarketType } from "./MarketType";

export const RepresentativeRoleList = [
  "ORGANIZATION_MAIN_ADMIN",
  "ORGANIZATION_ADMIN",
  "AGENT",
  "FREELANCE",
  "ORGANIZATION_MANAGER",
] as const;
export type RepresentativeRole = (typeof RepresentativeRoleList)[number];

const RepresentativeInvitationStatusList = [
  "ADDED",
  "INVITED",
  "ACCEPTED",
] as const;
type RepresentativeInvitationStatus =
  (typeof RepresentativeInvitationStatusList)[number];

export interface Representative {
  id: string;
  invitationStatus: RepresentativeInvitationStatus;
  firstName: string;
  lastName: string;
  email: string | null;
  phoneNumber: string | null;
  languages: string[];
  position: string | null;
  role: RepresentativeRole;
  markets: MarketType[];
  virtualMeetingAppLinks?: Partial<Record<VirtualMeetingApps, string>> | null;
  portfolios?: {
    id: string;
    name: string;
    collectionId: string | null;
  }[];
  useInActiveSalesCampaignNames?: string[];
}

export type RepresentativeUpsertData = Omit<
  Representative,
  "id" | "portfolios" | "invitationStatus"
> & {
  id?: string;
  portfolios?: {
    id: string;
  }[];
};

export interface RepresentativeUpdate {
  id: string;
}

export function canBePortfolioManager(representative: Representative): boolean {
  return (
    representative.role === "ORGANIZATION_MANAGER" ||
    representative.role === "ORGANIZATION_MAIN_ADMIN" ||
    representative.role === "ORGANIZATION_ADMIN" ||
    representative.role === "AGENT"
  );
}

export function canBeASeller(representative: Representative): boolean {
  return (
    representative.markets.length > 0 && representative.languages.length > 0
  );
}
