import React, { useEffect } from "react";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HiCheck, HiOutlineX } from "react-icons/hi";

import Button from "@components/data-entry/Button";
import MultiSelect from "@components/data-entry/MultiSelect";
import TextField from "@components/data-entry/TextField";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import BottomBar from "@components/layout/BottomBar";
import { Representative } from "@models/old/Representative";
import { MarketType, MarketTypesList } from "@models/types/enums";
import { getCodeByLanguage, getLanguages } from "@services/languages";

type FormValues = {
  firstName: string;
  lastName: string;
  markets: SelectOptionBase[];
  phoneNumber?: string;
  languages: SelectOptionBase[];
};

interface PersonalInfoProps {
  user: Representative;
  onSubmit: (user: Representative) => void;
}

function PersonalInfo({ user, onSubmit }: PersonalInfoProps) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    trigger,
    watch,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber || "",
      markets: user?.markets?.map((market) => ({
        label: t(`Common.market-type.${market}`),
        value: market,
      })),
      languages: user?.languages?.map((code) => ({
        label: t(`Common.language.${code.toLowerCase()}`),
        value: code.toLowerCase(),
      })),
    },
  });

  const firstname = watch("firstName");
  const lastname = watch("lastName");
  const phoneNumber = watch("phoneNumber");
  const markets = watch("markets");
  const languages = watch("languages");
  useEffect(() => {
    trigger();
  }, [firstname, lastname, phoneNumber, markets, languages]);

  const onSubmitForm = (data: FormValues) => {
    const representative: Representative = {
      ...user,
      ...data,
      markets: data.markets.map((market) => market.value as MarketType),
      languages: data.languages.map((language) => language.value),
    };
    reset(data);
    onSubmit(representative);
  };

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit(onSubmitForm)}>
      <div className="flex flex-col grow gap-6 p-6 lg:p-10">
        <div className="w-full lg:w-1/3">
          <label className="font-medium" htmlFor="firstName">
            {t("MyProfile.form.firstName-label")}
          </label>
          <div className="mt-2">
            <Controller
              name="firstName"
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange, value, onBlur },
                fieldState: { error },
              }) => (
                <TextField
                  id="firstName"
                  placeholder={t("MyProfile.form.firstName-placeholder")}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  hasError={!!error}
                />
              )}
            />
            {errors.firstName && errors.firstName.type === "required" && (
              <p className="text-sm italic text-primaryRed">
                {t("Common.form.this-is-required")}
              </p>
            )}
          </div>
        </div>
        <div className="w-full lg:w-1/3">
          <label className="font-medium" htmlFor="lastName">
            {t("MyProfile.form.lastName-label")}
          </label>
          <div className="mt-2">
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange, value, onBlur },
                fieldState: { error },
              }) => (
                <TextField
                  id="lastName"
                  placeholder={t("MyProfile.form.lastName-placeholder")}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  hasError={!!error}
                />
              )}
            />
            {errors.lastName && errors.lastName.type === "required" && (
              <p className="text-sm italic text-primaryRed">
                {t("Common.form.this-is-required")}
              </p>
            )}
          </div>
        </div>
        <div className="w-full lg:w-1/3">
          <label className="font-medium" htmlFor="phoneNumber">
            {t("MyProfile.form.phoneNumber-label")}
          </label>
          <div className="mt-2">
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <TextField
                  id="phoneNumber"
                  placeholder={t("MyProfile.form.phoneNumber-placeholder")}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
        </div>
        <div className="w-full lg:w-1/3">
          <label className="font-medium" htmlFor="markets">
            {t("MyProfile.form.markets-select")}
          </label>
          <div className="mt-2">
            <Controller
              name="markets"
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <MultiSelect
                  id="markets"
                  name="markets"
                  placeholder={t("MyProfile.form.markets-select-placeholder")}
                  value={value}
                  onChange={onChange}
                  options={MarketTypesList.map<SelectOptionBase>((m) => ({
                    label: t(`Common.market-type.${m}`),
                    value: m,
                  }))}
                  className={error && "border-primaryRed"}
                />
              )}
            />
            {errors.markets && errors.markets.type === "required" && (
              <p className="text-sm italic text-primaryRed">
                {t("Common.form.this-is-required")}
              </p>
            )}
          </div>
        </div>
        <div className="w-full lg:w-1/3">
          <label className="font-medium" htmlFor="languages">
            {t("MyProfile.form.languages-select")}
          </label>
          <div className="mt-2">
            <Controller
              name="languages"
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange, value, onBlur },
                fieldState: { error },
              }) => (
                <MultiSelect
                  id="languages"
                  name="languages"
                  placeholder={t("MyProfile.form.languages-select-placeholder")}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={getLanguages().map<SelectOptionBase>((l) => ({
                    label: t(`Common.language.${l}`),
                    value: getCodeByLanguage(l),
                  }))}
                  className={error && "border-primaryRed"}
                />
              )}
            />
            {errors.languages && errors.languages.type === "required" && (
              <p className="text-sm italic text-primaryRed">
                {t("Common.form.this-is-required")}
              </p>
            )}
          </div>
        </div>
      </div>
      <BottomBar>
        <Button
          theme="TERTIARY"
          type="button"
          onClick={() => {
            reset();
          }}
        >
          {t("Components.buttons.cancel-changes")}
          <HiOutlineX className="text-2xl" />
        </Button>
        <Button theme="PRIMARY" disabled={!isValid} className="items-center">
          {t("Components.buttons.save-changes")}
          <HiCheck className="text-2xl" />
        </Button>
      </BottomBar>
    </form>
  );
}

export default PersonalInfo;
