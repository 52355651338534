//
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import axiosInstance from "@services/api/config";

import { getAPIQueryKey } from "../helper";
import { GetShowroomsEndpoint } from "./get-showrooms";

export namespace DeleteShowroomEndpoint {
  // @todo add output schema
  export const outputHydratingSchema = z.object({});
  export type Output = z.output<typeof outputHydratingSchema>;

  interface HookParams {
    organizationId: string;
  }

  interface MutationParams {
    showroomId: string;
  }

  type Params = HookParams & MutationParams;

  export const path = ({ organizationId, showroomId }: Params) =>
    `/organizations/${organizationId}/showrooms/${showroomId}`;

  export const call = (params: Params) =>
    axiosInstance.delete(path(params)).then((res) => res.data);

  export function useHook(hookParams: HookParams) {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
          ...hookParams,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(GetShowroomsEndpoint.path(hookParams)),
        });
      },
    });
  }
}
