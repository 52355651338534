import { useMutation } from "@tanstack/react-query";

import { Organization } from "@models/old/Organization";
import { Representative } from "@models/old/Representative";
import axiosInstance from "@services/api/config";

export const putRestoreRepresentativeEndpoint = {
  path: (
    organizationId: Organization["id"],
    RepresentativeId: Representative["id"],
  ) =>
    `/organizations/${organizationId}/Representatives/${RepresentativeId}/restore`,
};

export function usePutRestoreRepresentative(organizationId: string) {
  return useMutation({
    mutationFn: (RepresentativeId: string) =>
      axiosInstance.put(
        putRestoreRepresentativeEndpoint.path(organizationId, RepresentativeId),
      ),
  });
}
