import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { BsFilter } from "react-icons/bs";
import { HiOutlineSearch } from "react-icons/hi";

import Button from "@components/data-entry/Button";
import MultiSelectTree from "@components/data-entry/MultiSelectTree";
import Select from "@components/data-entry/Select";
import TextField from "@components/data-entry/TextField";
import { convertGeographyToMultiOptionItemTree } from "@helpers/GeographyToMultiOptionItemTree";
import { AccountDetails } from "@models/old/Account";
import { buildGeography } from "@models/types/Geography";
import {
  AccountPortfolioFilter,
  anyFilterActivated,
  numberOfHiddenFilters,
} from "@onboarding/components/accountPortfolio/components/AccountPortfolioFilter";

interface FiltersProps {
  accounts: AccountDetails[];
  filters: AccountPortfolioFilter;
  onChange: (values: AccountPortfolioFilter) => void;
}

function Filters(props: FiltersProps) {
  const { accounts, filters, onChange } = props;
  const { t } = useTranslation();
  const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false);

  const geographyOptions = convertGeographyToMultiOptionItemTree(
    buildGeography(accounts),
  );

  return (
    <div className="flex flex-col gap-4 p-4 border rounded-md bg-primaryLight item">
      {anyFilterActivated(filters) && (
        <div className="flex justify-end item">
          <button
            type="button"
            className="underline text-primaryElectricBlue"
            onClick={() => {
              onChange({
                geography: undefined,
                accountName: undefined,
                accountStatus: undefined,
              });
            }}
          >
            clear all filters
          </button>
        </div>
      )}
      <div className="flex gap-4 item">
        <div className="flex flex-col w-full gap-2 item">
          <label className="item font-medium" htmlFor="account-name-filter">
            Search for an account name
          </label>
          <TextField
            id="account-name-filter"
            icon={<HiOutlineSearch className="w-5 h-5 text-gray-600" />}
            className="item"
            placeholder="Type to search"
            value={filters.accountName}
            onChange={(value) => {
              onChange({
                ...filters,
                accountName: value,
              });
            }}
          />
        </div>
        <div className="flex flex-col w-full gap-2 item">
          <label className="item font-medium" htmlFor="geography-filter">
            {t("Shared.portfolios.structure-tab.location-input-label")}
          </label>
          <MultiSelectTree
            id="geography-filter"
            name="geography filter"
            options={geographyOptions}
            defaultValues={filters.geography}
            onChange={(values) => {
              onChange({
                ...filters,
                geography: values,
              });
            }}
          />
        </div>
        <div className="flex items-end item">
          <Button
            onClick={() => setIsOpenFilters(!isOpenFilters)}
            theme="PRIMARY"
          >
            <span>Filters</span>
            {numberOfHiddenFilters(filters) > 0 && (
              <span className="px-2 py-0 rounded-full text-primaryBlack bg-white">
                {numberOfHiddenFilters(filters)}
              </span>
            )}
            <BsFilter className="w-6 h-6" />
          </Button>
        </div>
      </div>
      {/* 2nd row */}
      {isOpenFilters && (
        <div className="flex justify-between gap-4 item">
          <div className="flex flex-col gap-2 item">
            <span className="font-medium">Account Status</span>
            <Select
              name="accountStatus"
              placeholder="All"
              onChange={(status) => {
                onChange({
                  ...filters,
                  accountStatus: status,
                });
              }}
              defaultValue={{
                value: filters.accountStatus,
              }}
              firstOption={{
                label: "All",
                value: null,
              }}
              showGroups={false}
              className="min-w-[9rem]"
              options={[
                {
                  key: "grp1",
                  label: "group",
                  options: [
                    {
                      label: "Active",
                      value: "ACTIVE",
                    },
                    {
                      label: "Prospect",
                      value: "PROSPECT",
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Filters;
