import { useQuery } from "@tanstack/react-query";

import {
  SpecialRequestOldModelSerialized,
  unserializeSpecialRequestOldModel,
} from "@models/SpecialRequest";
import axiosInstance from "@services/api/config";

export const fetchSpecialRequestsByShowroomEndpoint = {
  path: (organizationId: string, showroomId: string) =>
    `/organizations/${organizationId}/showrooms/${showroomId}/special-requests`,
};

export type FetchSpecialRequestsResponse = SpecialRequestOldModelSerialized[];

export const queryKeysFetchSpecialRequestsByShowroom = (
  organizationId: string,
  showroomId: string,
) =>
  fetchSpecialRequestsByShowroomEndpoint
    .path(organizationId, showroomId)
    .split("/");

const callApi = async (organizationId: string, showroomId: string) =>
  axiosInstance
    .get<FetchSpecialRequestsResponse>(
      fetchSpecialRequestsByShowroomEndpoint.path(organizationId, showroomId),
    )
    .then((res) => res.data)
    .then((specialRequests) =>
      specialRequests.map(unserializeSpecialRequestOldModel),
    );

export const fetchSpecialRequestsQuery = (
  organizationId: string,
  showroomId: string,
) => ({
  queryKey: queryKeysFetchSpecialRequestsByShowroom(organizationId, showroomId),
  queryFn: () => callApi(organizationId, showroomId),
});

export const useFetchSpecialRequestsByShowroom = (
  organizationId: string,
  showroomId: string,
) =>
  useQuery({
    queryKey: queryKeysFetchSpecialRequestsByShowroom(
      organizationId,
      showroomId,
    ),
    queryFn: () => callApi(organizationId, showroomId),
  });
