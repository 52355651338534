export function contains(
  str1: string,
  str2: string,
  caseSensitive: boolean = false,
) {
  const compareStr1 = caseSensitive ? str1 : str1.toLowerCase();
  const compareStr2 = caseSensitive ? str2 : str2.toLowerCase();
  return compareStr1.includes(compareStr2);
}

export class NumberFormatter {
  static toString(num: number): string {
    return num.toLocaleString("en-US");
  }

  static fromString(str: string): number {
    return parseFloat(str.replace(/,/g, ""));
  }
}
