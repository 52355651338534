/* eslint-disable react/no-unstable-nested-components */
import React from "react";

import { createColumnHelper } from "@tanstack/react-table";
import { flag } from "country-emoji";
import { t } from "i18next";

import HoverCard from "@components/data-display/HoverCard";
import MRTable from "@components/data-display/MRTable";
import Tag from "@components/data-display/Tag";
import Button from "@components/data-entry/Button";
import { UserRole } from "@models/types/enums";

import { AccountWithCollections } from "./type";

const rolesWithAccess: UserRole[] = [
  "AGENT",
  "ORGANIZATION_ADMIN",
  "ORGANIZATION_MAIN_ADMIN",
  "ORGANIZATION_MANAGER",
];
interface ArchivedAccountsTableProps {
  accounts: AccountWithCollections[];
  onClickRestore: (account: AccountWithCollections) => void;
}

export default function ArchivedAccountsTable({
  accounts,
  onClickRestore,
}: ArchivedAccountsTableProps) {
  const columnHelper = createColumnHelper<AccountWithCollections>();

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () => t("CRM.archived-accounts.table-header.name"),
        cell: (info) => (
          <HoverCard
            renderIf={info.row.original.name.length > 36}
            fallbackProp="children"
            button={
              <div className="min-w-[10rem] max-w-[20rem] truncate">
                {info.row.original.name}
              </div>
            }
          >
            {info.row.original.name}
          </HoverCard>
        ),
      }),
      columnHelper.display({
        header: t("CRM.archived-accounts.table-header.origin"),
        cell: ({ row }) => {
          const {
            original: { city, countryCode },
          } = row;
          return (
            <Tag icon={flag(countryCode)} className="w-fit">
              {city}
            </Tag>
          );
        },
      }),
      columnHelper.display({
        id: "actions",
        meta: {
          cellClassName: "sticky right-0 bg-white",
        },
        cell: ({ row }) => {
          const { id } = row.original;
          return (
            <div className="flex items-center justify-end mr-4">
              <Button
                aclRoles={rolesWithAccess}
                type="button"
                theme="PRIMARY"
                testId={`restore-account-${id}`}
                onClick={() => onClickRestore(row.original)}
              >
                {t("CRM.archived-accounts.table-header.restore-button")}
              </Button>
            </div>
          );
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnHelper],
  );

  return (
    <MRTable
      data={accounts}
      columns={columns}
      cellClassName="border rounded-md"
      noDataLabel={t("CRM.archived-accounts.no-results")}
      displayDataLabel={t("CRM.archived-accounts.data-label")}
    />
  );
}
