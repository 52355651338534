import React from "react";

import { HiChevronRight } from "react-icons/hi2";

import AdminOnboardingForm from "@backoffice/components/onboarding/admin-onboarding-form";
import Button from "@components/data-entry/Button";
import BottomBar from "@components/layout/BottomBar";
import OnboardingForm from "@onboarding/components/OnboardingForm";
import {
  OrganizationInformationFormData,
  useOrganizationInformationForm,
} from "@shared/organization/organization-information-form";

interface OnboardingFormContainerProps {
  admin?: boolean;
  existing?: OrganizationInformationFormData;
  onSubmit: (
    onboardingInformation: OrganizationInformationFormData,
  ) => Promise<void>;
  className?: string;
}

export default function OnboardingFormContainer(
  props: OnboardingFormContainerProps,
) {
  const { admin, ...rest } = props;
  const { onSubmit, existing } = props;

  const organizationInformationProps = useOrganizationInformationForm({
    onSubmit,
    existing,
  });
  const { isValid, updating, handleSubmit, onSubmitForm } =
    organizationInformationProps;

  return (
    <div className="h-full w-full flex flex-col grow pt-10">
      <div className="pb-18 grow px-10">
        {admin ? (
          <AdminOnboardingForm {...rest} {...organizationInformationProps} />
        ) : (
          <OnboardingForm {...rest} {...organizationInformationProps} />
        )}
      </div>
      <BottomBar>
        <Button
          theme="PRIMARY"
          disabled={!isValid}
          loading={updating}
          onClick={handleSubmit(onSubmitForm)}
          testId="onboarding-button-next"
        >
          {updating && "Updating"}
          {!updating && !admin && "Next : showroom structure"}
          {!updating && admin && "Next : import accounts"}
          <HiChevronRight className="w-6 h-6" />
        </Button>
      </BottomBar>
    </div>
  );
}
