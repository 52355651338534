import React, { useContext } from "react";

import { useDebounce } from "use-debounce";

export type CRMDrawerType =
  | "upsert-account"
  | "upsert-contact"
  | "upsert-competitors";
type CRMContextType = {
  drawer?: CRMDrawerType;
  setDrawer: (d: CRMDrawerType | undefined) => void;
  openAccountUpsertDrawer: () => void;
  openContactUpsertDrawer: () => void;
  openCompetitorsGroupUpsertDrawer: () => void;
  setIsOpenDrawer: (d: CRMDrawerType) => (open: boolean) => void;
  closeDrawer: () => void;
};

const CRMContext = React.createContext<CRMContextType>({
  drawer: undefined,
  setDrawer: () => {},
  openAccountUpsertDrawer: () => {},
  openContactUpsertDrawer: () => {},
  openCompetitorsGroupUpsertDrawer: () => {},
  closeDrawer: () => {},
  setIsOpenDrawer: () => () => {},
});
export function useInitCRMDrawerContext() {
  const [drawer, setDrawer] = React.useState<CRMDrawerType | undefined>();

  // when drawer is set while navigating, the animation does not play unless we debounce the value
  const [debouncedDrawer] = useDebounce(drawer, 50);
  const contextValue: CRMContextType = React.useMemo(
    () => ({
      drawer: debouncedDrawer,
      setDrawer,
      openAccountUpsertDrawer: () => setDrawer("upsert-account"),
      openContactUpsertDrawer: () => setDrawer("upsert-contact"),
      openCompetitorsGroupUpsertDrawer: () => setDrawer("upsert-competitors"),
      closeDrawer: () => setDrawer(undefined),
      setIsOpenDrawer: (drawerName) => (open) =>
        setDrawer(open ? drawerName : undefined),
    }),
    [debouncedDrawer],
  );

  return contextValue;
}

export function useCRMDrawerContext() {
  return useContext(CRMContext);
}

export default CRMContext;
