import React from "react";

import { flag } from "country-emoji";
import { useTranslation } from "react-i18next";

import SingleSelect from "@components/data-entry/SingleSelect";

const languageFlags: Record<string, string> = {
  en: "gb",
  fr: "fr",
};

type LanguageSelectorProps = {
  id?: string;
};

export default function LanguageSelector({ id }: LanguageSelectorProps) {
  const { i18n } = useTranslation();
  return (
    <SingleSelect
      inputId={id}
      openMenuOnFocus
      value={{
        label: flag(languageFlags[i18n.language]) || "",
        value: i18n.language,
      }}
      options={[
        {
          options: ["en", "fr"].map((language) => ({
            label: flag(languageFlags[language]) || "",
            value: language,
          })),
        },
      ]}
      onChange={(option) => (option ? i18n.changeLanguage(option.value) : null)}
    />
  );
}
