import { useMutation } from "@tanstack/react-query";

import { AccountUpsertData } from "@models/old/Account";
import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

export const putAccountEndpoint = {
  path: (organizationId: Organization["id"], accountId: string) =>
    `/organizations/${organizationId}/accounts/${accountId}`,
};

export const postAccountEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/accounts`,
};

export function useUpsertAccount(
  organizationId: Organization["id"],
  accountId?: string,
) {
  return useMutation({
    mutationFn: (account: AccountUpsertData) =>
      accountId
        ? axiosInstance.put(
            putAccountEndpoint.path(organizationId, accountId),
            account,
          )
        : axiosInstance.post(postAccountEndpoint.path(organizationId), account),
  });
}
