import React, { useContext } from "react";

import { useIsLargestScreen } from "@components/layout/Breakpoint";
import LogService from "@services/log/service";

type SidebarContextType = {
  isFold: boolean;
  setIsFold: (isFold: boolean) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const SidebarContext = React.createContext<SidebarContextType>({
  isFold: false,
  setIsFold: () => LogService.error("setIsFold not instantiated"),
  isOpen: true,
  setIsOpen: () => LogService.error("setIsOpen not instantiated"),
});

export function useInitSidebarContext() {
  const isScreenLarge = useIsLargestScreen();
  const [isOpen, setIsOpen] = React.useState<boolean>(isScreenLarge);
  const [isFold, setIsFold] = React.useState<boolean>(false);

  const contextValue = React.useMemo(
    () => ({
      isFold,
      setIsFold,
      isOpen,
      setIsOpen,
    }),
    [isFold, isOpen],
  );
  return contextValue;
}

export function useSidebarContext() {
  return useContext(SidebarContext);
}

export default SidebarContext;
