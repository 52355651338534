import React from "react";

import { useTranslation } from "react-i18next";
import { HiCheck, HiOutlineX } from "react-icons/hi";

import Button from "@components/data-entry/Button";
import BottomBar from "@components/layout/BottomBar";
import {
  ACL_ADMINS,
  useUserRole,
} from "@shared/components/access-control/helpers";
import OrganizationInformationForm, {
  OrganizationInformationFormData,
  useOrganizationInformationForm,
} from "@shared/organization/organization-information-form";

export interface OrganizationProfileInformationEditProps {
  onSubmit: (
    organizationInformation: OrganizationInformationFormData,
  ) => Promise<void>;
  onCancel: () => Promise<void>;
  organizationInformation: OrganizationInformationFormData;
}

function OrganizationProfileInformationEdit(
  props: OrganizationProfileInformationEditProps,
) {
  const { onSubmit, organizationInformation, onCancel } = props;
  const { t } = useTranslation();
  const { isAdmin } = useUserRole();
  const organizationInformationProps = useOrganizationInformationForm({
    onSubmit,
    existing: organizationInformation,
  });
  const { isValid, updating, handleSubmit } = organizationInformationProps;

  return (
    <div className="h-[calc(100vh-3rem-62px)] flex flex-col">
      <div className="p-10 pb-18 grow">
        <h1 className="heading-1 mb-10">{organizationInformation.name}</h1>
        <OrganizationInformationForm
          brandsOptions={[]}
          readonly={!isAdmin}
          {...organizationInformationProps}
        />
      </div>
      <BottomBar aclRoles={ACL_ADMINS}>
        <Button theme="TERTIARY" onClick={() => onCancel()}>
          {t("Components.buttons.cancel-changes")}
          <HiOutlineX className="text-2xl" />
        </Button>
        <Button
          theme="PRIMARY"
          disabled={!isValid}
          loading={updating}
          className="items-center"
          onClick={handleSubmit(onSubmit)}
        >
          {updating && t("Components.buttons.updating")}
          {!updating && t("Components.buttons.save-changes")}
          <HiCheck className="text-2xl" />
        </Button>
      </BottomBar>
    </div>
  );
}

export default OrganizationProfileInformationEdit;
