import React from "react";

import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import OrganizationProfileInformationEdit from "@app/modules/organization-profile/organization-profile-information-edit";
import queryClient from "@app/queryClient";
import Loading from "@components/feedback/Loading";
import { Organization } from "@models/old/Organization";
import OrganizationAPI from "@services/api/old/organization/OrganizationAPI";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { OrganizationInformationFormData } from "@shared/organization/organization-information-form";

const getOrganizationInformationQuery = (organizationId: string) => ({
  queryKey: ["get-organization-onboarding-infos", organizationId],
  queryFn: () => OrganizationAPI.getOrganizationInformation(organizationId),
});

function OrganizationProfileInformation() {
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const onSubmit = (organizationInformation: OrganizationInformationFormData) =>
    OrganizationAPI.updateOrganizationInformation(
      organizationId,
      organizationInformation,
    ).then(async (updatedOrganization: Organization) => {
      toast.success(`Organization ${updatedOrganization.name} updated`);
      await queryClient.invalidateQueries({
        queryKey: ["get-organization-onboarding-infos"],
      });
    });

  const { data, isPending, isError, error, refetch } = useQuery({
    ...getOrganizationInformationQuery(organizationId),
  });

  if (isPending) {
    return <Loading type="screen" />;
  }

  if (isError) {
    throw error;
  }

  return (
    <OrganizationProfileInformationEdit
      onSubmit={onSubmit}
      onCancel={async () => {
        await refetch();
      }}
      organizationInformation={{
        ...data,
        bannerFile: null, // the file input is always null by default
      }}
    />
  );
}

export default OrganizationProfileInformation;
