import React from "react";

import LoadingFetch from "@components/feedback/LoadingFetch";
import { Brand } from "@models/Brand";
import { GetOrganizationCollectionsWithCapabilitiesEndpoint } from "@services/api/organization/get-collections-with-capabilities";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import AgencyCollectionForm from "@shared/collections/agency-collection-form";
import AgencyCollectionGroup from "@shared/collections/agency-collection-group";

function CollectionsOverviewAgency() {
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const {
    data: collections = [],
    status,
    error,
  } = GetOrganizationCollectionsWithCapabilitiesEndpoint.useHook({
    organizationId,
  });

  // compute data
  const brandsWithCollections = collections.reduce(
    (acc, curr) => {
      if (!curr.brand) {
        return acc;
      }

      const brandInArrayIdx = acc.findIndex(
        (b) => b.brand.id === curr.brand?.id,
      );
      if (brandInArrayIdx === -1) {
        acc.push({ brand: curr.brand, collections: [curr] });
      } else {
        acc[brandInArrayIdx].collections.push(curr);
      }
      return acc;
    },
    [] as {
      brand: Brand;
      collections: any[];
    }[],
  );

  return (
    <div className="w-full flex flex-wrap-reverse lg:no-wrap gap-4">
      <div className="flex flex-col gap-4 w-full lg:w-auto lg:max-w-[50%] lg:grow">
        <main className="flex flex-col gap-4">
          {/** BLOCK TO ADD A NEW BRAND / LINK TO AN MR BRAND */}
          <AgencyCollectionForm organizationId={organizationId} />
          {/** categories whether they are linked or not */}
          <LoadingFetch status={status} error={error}>
            {brandsWithCollections.map((brandWithCollections) => (
              <AgencyCollectionGroup
                key={brandWithCollections.brand.id}
                organizationId={organizationId}
                brand={brandWithCollections.brand}
                collections={brandWithCollections.collections}
              />
            ))}
          </LoadingFetch>
        </main>
      </div>
    </div>
  );
}

CollectionsOverviewAgency.defaultProps = {
  isOnboarding: false,
};

export default CollectionsOverviewAgency;
