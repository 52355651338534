import React from "react";

import { useParams } from "react-router-dom";

function AccountOverview() {
  const { accountId } = useParams();
  return <div>Single Account Page {accountId}</div>;
}

export default AccountOverview;
