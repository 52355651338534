import { Portfolio } from "@models/old/Portfolio";
import { VirtualMeetingApps } from "@models/types/enums";

interface ObjectWithSellers {
  sellers: {
    id: string;
  }[];
}
export function extractSellers<T extends ObjectWithSellers>(
  portfolios: T[],
): T["sellers"] {
  const sellers = portfolios
    .flatMap((p) => p.sellers) // get sellers
    .filter(
      // keep the sellers which index equal to the first time they appear in the array
      (seller, index, array) =>
        array.findIndex((duplicate) => duplicate.id === seller.id) === index,
    );
  return sellers;
}

export function getPortfolioSellers<
  P extends Portfolio,
  S extends {
    id: string;
  },
>(portfolio: P, sellers: S[]) {
  return sellers.filter((s) =>
    portfolio.sellers.map((ps) => ps.id).includes(s.id),
  );
}

export function getPorfoliosWithoutVirtualCoverage<
  P extends Portfolio,
  S extends {
    id: string;
    virtualMeetingAppLinks?: Partial<Record<VirtualMeetingApps, string>> | null;
  },
>(portfolios: P[], sellers: S[]): P[] {
  return portfolios.filter((portfolio) => {
    const portfolioSellers = getPortfolioSellers(portfolio, sellers);

    return portfolioSellers.every(
      (seller) =>
        !seller.virtualMeetingAppLinks ||
        Object.entries(seller.virtualMeetingAppLinks).length === 0,
    );
  });
}
