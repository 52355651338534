import React, { useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { HiCheck, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { IoIosClose } from "react-icons/io";

import Tooltip from "@components/data-display/Tooltip";
import Button from "@components/data-entry/Button";
import TextField from "@components/data-entry/TextField";
import { Collection } from "@models/old/Collection";
import { CollectionWithCapabilities } from "@services/api/old/collections/fetch-collections-with-capabilities";
import { ACL_ADMINS } from "@shared/components/access-control/helpers";

interface CollectionItemProps {
  collection: CollectionWithCapabilities;
  onRename: (collection: Collection) => Promise<void>;
  onArchive: (collection: Collection) => void;
  isLastCollection: boolean;
}

function CollectionItem(props: CollectionItemProps) {
  const { collection, onRename, onArchive, isLastCollection } = props;
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState<string>(collection.name);

  const isArchivingBlocked =
    collection.archivingBlockedBy.length > 0 || isLastCollection;
  const isEditingBlocked = collection.updatingBlockedBy.length > 0;

  return (
    <div key={collection.id}>
      {isEditing && (
        <div className="p-2 border-2 rounded-md border-primaryElectricBlue  flex gap-2 justify-between items-center">
          <div className="flex flex-col gap-2">
            <label className="font-medium" htmlFor="collection-name">
              {t("Shared.collections.input.label")}
            </label>
            <TextField
              id="collection-name"
              onChange={(value) => {
                setName(value || "");
              }}
              value={name}
              className="w-80"
            />
          </div>
          <div className="flex flex-col items-end align-between">
            <Button
              label="cancel collection edition"
              type="button"
              theme="ICON"
              className="cursor-pointer"
              onClick={() => setIsEditing(false)}
            >
              <IoIosClose className="w-8 h-8" />
            </Button>
            <Button
              label="update collection"
              disabled={!name}
              onClick={async () => {
                await onRename({
                  ...collection,
                  name,
                });
                setIsEditing(false);
              }}
              theme="PRIMARY"
              className="shrink-0"
            >
              <HiCheck className="w-6 h-6" />
              <span> {t("Shared.collections.update.btn")}</span>
            </Button>
          </div>
        </div>
      )}
      {!isEditing && (
        <div
          data-testid={collection.id}
          className="flex items-center p-2 border-2 rounded-lg"
        >
          <span className="p-2 text-lg font-medium grow">
            {collection.name}
          </span>
          <span className="flex-none">
            <Tooltip
              renderIf={isEditingBlocked}
              fallbackProp="children"
              aclRoles={ACL_ADMINS}
              content={
                <div className="w-48">
                  {t("Shared.collections.edit.disabled-tooltip")}
                </div>
              }
            >
              <Button
                label={`edit collection ${collection.name}`}
                type="button"
                theme="ICON"
                className="p-2"
                disabled={isEditingBlocked}
                onClick={() => setIsEditing(true)}
              >
                <HiOutlinePencil className="w-6 h-6" />
              </Button>
            </Tooltip>
            <Tooltip
              renderIf={isArchivingBlocked}
              fallbackProp="children"
              aclRoles={ACL_ADMINS}
              content={
                <div className="w-48">
                  {collection.archivingBlockedBy.length > 0 && (
                    <Trans
                      i18nKey="Shared.collections.archive.disabled-tooltip"
                      components={{
                        break: <br />,
                      }}
                    />
                  )}
                  {collection.archivingBlockedBy.length === 0 &&
                    isLastCollection && (
                      <Trans
                        i18nKey="Shared.collections.archive.one-collection-remaining-tooltip"
                        components={{
                          break: <br />,
                        }}
                      />
                    )}
                </div>
              }
            >
              <Button
                label={`archive collection ${collection.name}`}
                type="button"
                theme="ICON"
                className="p-2 text-primaryRed"
                disabled={isArchivingBlocked}
                onClick={() => onArchive(collection)}
              >
                <HiOutlineTrash className="w-6 h-6" />
              </Button>
            </Tooltip>
          </span>
        </div>
      )}
    </div>
  );
}

export default CollectionItem;
