import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { formatDateToISO } from "@helpers/Date";
import { Organization } from "@models/Organization";
import { zonedDateSerializingSchema } from "@models/types/Dates";
import axiosInstance from "@services/api/config";
import { GetDailyCalendarEndpoint } from "@services/api/sales-campaigns/get-daily-calendar";

export namespace CreateBusySlotsEndpoint {
  interface HookParams {
    organizationId: Organization["id"];
  }

  const inputSchema = z.object({
    showroomId: z.string().uuid(),
    title: z.string(),
    sellerIds: z.array(z.string().uuid()),
    slots: z.array(
      z.object({
        startTime: zonedDateSerializingSchema,
        endTime: zonedDateSerializingSchema,
      }),
    ),
  });

  type MutationParams = z.input<typeof inputSchema>;

  type Params = MutationParams & HookParams;

  const path = ({ organizationId, showroomId }: Params) =>
    `/organizations/${organizationId}/showrooms/${showroomId}/busy-slots`;

  const call = (params: Params) =>
    axiosInstance.post(path(params), inputSchema.parse(params));

  export function useHook(hookParams: HookParams) {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({ ...hookParams, ...mutationParams }),

      // invalidate every calendar query on impacted dates
      onSuccess: (_data, variables) => {
        variables.slots.forEach((slot) =>
          queryClient.invalidateQueries({
            queryKey: GetDailyCalendarEndpoint.queryKeys({
              ...hookParams,
              showroomId: variables.showroomId,
              dayAsString: formatDateToISO(slot.startTime.realDate),
            }),
          }),
        );
      },
    });
  }
}
