import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { HiOutlineCheckCircle } from "react-icons/hi2";

import BookedAppointmentCard from "@booking/components/booked-appointment-card";
import BookedSpecialRequestCard from "@booking/components/booked-special-request-card";
import { Appointment } from "@models/Appointment";
import { Brand } from "@models/Brand";
import { Collection } from "@models/Collection";
import { Contact } from "@models/Contact";
import { Showroom } from "@models/Showroom";
import { SpecialRequestOldModel } from "@models/SpecialRequest";
import { AppointmentTypeEnum } from "@models/types/enums";
import { translateAppointmentType } from "@shared/helpers/translater";

type GenericCollection = Pick<Collection, "id" | "name"> & {
  brand: Pick<Brand, "id" | "name">;
};

type GenericAppointment = Appointment & {
  collection: GenericCollection | null;
  attendees: Pick<Contact, "firstName">[];
  showroom: Pick<Showroom, "id" | "formattedAddress" | "timezone">;
};

interface BookedAppointmentsListProps<Appt extends GenericAppointment> {
  appointments: Appt[];
  specialRequests: SpecialRequestOldModel[];
  showroom: Showroom;
  onEditAttendees: (appointment: Appt) => void;
  onEditAppointment: (appointment: Appt) => void;
  onRemoveAppointment: (appointment: Appt) => void;
  onEditSpecialRequest: (specialRequest: SpecialRequestOldModel) => void;
  onRemoveSpecialRequest: (specialRequest: SpecialRequestOldModel) => void;
}

interface AppointmentGroup<A extends GenericAppointment> {
  appointmentType: AppointmentTypeEnum;
  collection: GenericCollection | null;
  appointments: A[];
}
export function groupAppointments<A extends GenericAppointment>(
  appointments: A[],
): AppointmentGroup<A>[] {
  const appointmentGroups: AppointmentGroup<A>[] = [];
  appointments.forEach((appointment: A) => {
    const currentGroup = {
      appointmentType: appointment.type,
      collection: appointment.collection,
      appointments: [appointment],
    };
    const groupIndex = appointmentGroups.findIndex(
      (group) =>
        group.appointmentType === currentGroup.appointmentType &&
        group.collection?.id === currentGroup.collection?.id,
    );
    if (groupIndex === -1) {
      appointmentGroups.push(currentGroup);
    } else {
      appointmentGroups[groupIndex].appointments.push(appointment);
    }
  });

  return appointmentGroups.sort((groupA, groupB) => {
    if (groupA.collection && groupB.collection) {
      return groupA.collection.name.localeCompare(groupB.collection.name);
    }
    return groupA.collection ? 1 : -1;
  });
}

function BookedAppointmentsList<Appt extends GenericAppointment>(
  props: BookedAppointmentsListProps<Appt>,
) {
  const {
    onEditAttendees,
    onEditAppointment,
    onRemoveAppointment,
    appointments,
    specialRequests,
    onEditSpecialRequest,
    onRemoveSpecialRequest,
    showroom,
  } = props;
  const { t } = useTranslation();

  const appointmentGroups = groupAppointments(appointments);

  let totalAppointments = useMemo(
    () => Object.keys(appointmentGroups).length,
    [appointmentGroups],
  );

  totalAppointments += specialRequests.length;

  return (
    <div className="rounded-lg h-fit p-6 grow border border-secondaryPurple">
      <h3 className="heading-3-mobile lg:heading-3 pb-6 flex items-center gap-2">
        <HiOutlineCheckCircle className="w-6 h-6 text-statusGreenDark" />

        {t("Booking.dashboard.appointment-list.title", {
          accountName: showroom.name,
        })}
      </h3>
      <div>
        {totalAppointments === 0 &&
          t("Booking.dashboard.appointment-list.empty")}
      </div>
      {totalAppointments > 0 && (
        <div className="flex flex-col gap-6">
          {specialRequests.map((specialRequest) => (
            <div
              className="flex flex-col gap-6"
              key={specialRequest.collection?.id}
            >
              <div className="text-base font-bold">
                {specialRequest.collection?.name}
              </div>
              <BookedSpecialRequestCard
                key={specialRequest.id}
                showroom={showroom}
                specialRequest={specialRequest}
                onEditSpecialRequest={(item) => {
                  onEditSpecialRequest(item);
                }}
                onRemoveSpecialRequest={(item) => {
                  onRemoveSpecialRequest(item);
                }}
              />
            </div>
          ))}
          {appointmentGroups.map((group) => {
            const {
              appointments: groupedAppointments,
              collection,
              appointmentType,
            } = group;
            return (
              <div
                className="flex flex-col gap-6"
                key={collection?.id || appointmentType}
              >
                <div
                  className="text-base"
                  data-testid={`appointment-list-collectionId-${
                    collection?.id || appointmentType
                  }`}
                >
                  {collection ? (
                    <>
                      <span className="text-lg font-bold">
                        {collection.name}
                      </span>
                      <span className="text-sm text-primaryDarkGrey">
                        {" - "}
                        {collection.brand.name}
                      </span>
                    </>
                  ) : (
                    t(translateAppointmentType(appointmentType))
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  {groupedAppointments.map((appointItem: Appt) => (
                    <BookedAppointmentCard<Appt>
                      key={appointItem.id}
                      appointment={appointItem}
                      onEditAttendees={(item) => {
                        onEditAttendees(item);
                      }}
                      onEditAppointment={(item) => {
                        onEditAppointment(item);
                      }}
                      onRemoveAppointment={(item) => {
                        onRemoveAppointment(item);
                      }}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default BookedAppointmentsList;
