import React from "react";

import { addAccessControlProps } from "@shared/components/access-control";

interface BottomBarProps {
  children: React.ReactNode;
  sticky?: boolean;
  theme?: "WHITE" | "GREY" | "NONE";
  className?: string;
}

function BottomBar({
  children,
  sticky = true,
  theme = "GREY",
  className,
}: BottomBarProps) {
  let bgColor = "";
  let border = "";
  switch (theme) {
    case "GREY":
      bgColor = "bg-primaryLightestGrey";
      border = "";
      break;
    case "WHITE":
      bgColor = "bg-white";
      border = "border-t border-t-primaryElectricBlue";
      break;
    case "NONE":
      bgColor = "";
      border = "";
      break;
    default:
      break;
  }

  return (
    <footer
      className={`${
        sticky ? "sticky" : "absolute"
      } gap-2 h-18 bottom-0 right-0 flex justify-center sm:justify-end w-full p-4 ${bgColor} ${border} ${className}`}
    >
      {children}
    </footer>
  );
}

export default addAccessControlProps(BottomBar);
