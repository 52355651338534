/* eslint-disable react/no-unstable-nested-components */
import React from "react";

import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import { TbPlugConnected } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

import MRTable from "@components/data-display/MRTable";
import Button from "@components/data-entry/Button";
import {
  OrganizationRepresentativesForAdmin,
  useGetOrganizationRepresentativesForAdmin,
} from "@services/api/organization/get-organization-representatives-for-admin";
import useAuthentication from "@services/authentication/useAuthentication";
import { fullName, getRoleName } from "@shared/helpers/formatters";

interface ListRepresentativeDrawerProps {
  organizationId: string;
}

function RepresentativesViewForAdmin({
  organizationId,
}: ListRepresentativeDrawerProps) {
  const navigate = useNavigate();
  const { impersonateRepresentative } = useAuthentication();

  const { data: representatives } = useGetOrganizationRepresentativesForAdmin({
    organizationId,
  });
  const columnHelper =
    createColumnHelper<OrganizationRepresentativesForAdmin>();

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        id: "actions",
        cell: ({ row }) => {
          const { id, email, userId } = row.original;
          if (!userId) {
            return null;
          }
          return (
            <div className="flex items-center justify-end gap-4 p-2">
              <Button
                type="button"
                theme="ICON"
                testId={`edit-representative-${id}`}
                onClick={async () => {
                  await impersonateRepresentative(organizationId, email);
                  navigate("/");
                }}
              >
                <TbPlugConnected className="w-4 h-4 text-primaryElectricBlue" />
              </Button>
            </div>
          );
        },
      }),
      columnHelper.display({
        header: t("Representatives.table-header.name"),
        cell: ({ row: { original } }) => (
          <div className="min-w-[10rem]">{fullName(original)}</div>
        ),
      }),
      columnHelper.accessor("role", {
        header: t("Representatives.table-header.role"),
        cell: (info) => getRoleName(info.getValue()) || "-",
      }),
      columnHelper.accessor("email", {
        header: t("Representatives.table-header.email"),
        cell: (info) => info.getValue() || "-",
      }),
      columnHelper.accessor("invitationStatus", {
        header: t("Representatives.table-header.invitation-status"),
        cell: (info) => info.getValue() || "-",
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnHelper],
  );

  return (
    <MRTable
      data={representatives}
      columns={columns}
      cellClassName="border rounded-md"
      noDataLabel={t("Representatives.no-results")}
      displayDataLabel={t("Representatives.footer-display")}
    />
  );
}

export default RepresentativesViewForAdmin;
