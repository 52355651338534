import React from "react";

import { t } from "i18next";
import { toast } from "react-toastify";

import ArchivedContactsTable from "@app/modules/crm/components/contacts/archived-contacts-table";
import CRMHeader from "@app/modules/crm/components/header";
import queryClient from "@app/queryClient";
import Loading from "@components/feedback/Loading";
import { getFullAccountsEndpoint } from "@services/api/accounts/get-full-accounts";
import { GetContactsEndpoint } from "@services/api/contacts/get-contacts";
import { getAPIQueryKey } from "@services/api/helper";
import { queryKeysFetchContactsArchivedCount } from "@services/api/old/contacts/fetch-archived-count";
import { queryKeysFetchContacts } from "@services/api/old/contacts/fetch-contacts";
import {
  queryKeysFetchContactsArchived,
  useFetchContactsArchived,
} from "@services/api/old/contacts/fetch-contacts-archived";
import { queryKeysFetchContactsCount } from "@services/api/old/contacts/fetch-contacts-count";
import { usePutRestoreContact } from "@services/api/old/contacts/put-restore-contact";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import LogService from "@services/log/service";
import { pageWithAccessControl } from "@shared/components/access-control";
import { ACL_ADMINS_AGENTS_MANAGERS } from "@shared/components/access-control/helpers";

function CRMArchivedContacts() {
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const { mutateAsync: putRestoreContact } =
    usePutRestoreContact(organizationId);

  const { data: contactsList, isLoading: isLoadingList } =
    useFetchContactsArchived(organizationId);

  const handleRestoreContact = (contact: GetContactsEndpoint.OutputItem) => {
    putRestoreContact(contact?.id)
      .then(async () => {
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchContacts(organizationId),
        });
        await queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(
            getFullAccountsEndpoint.path(organizationId),
          ),
        });
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchContactsArchived(organizationId),
        });
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchContactsCount(organizationId),
        });
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchContactsArchivedCount(organizationId),
        });
        toast.success(t("CRM.archived-contacts.confirm-contact-restore"));
      })
      .catch((err) => {
        LogService.error(err);
      });
  };

  return (
    <>
      <CRMHeader />
      <div className="p-6">
        {isLoadingList && <Loading type="button" />}
        {!isLoadingList && (
          <ArchivedContactsTable
            contacts={contactsList || []}
            onClickRestore={handleRestoreContact}
          />
        )}
      </div>
    </>
  );
}

export default pageWithAccessControl(
  ACL_ADMINS_AGENTS_MANAGERS,
  CRMArchivedContacts,
);
