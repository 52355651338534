import { AccountDetails } from "@models/old/Account";
import { GeographicalFilter } from "@models/types/Geography";

import { convertMultiOptionItemTreeToGeographicalFilter } from "../../../../../helpers/GeographyToMultiOptionItemTree";
import countries from "../../../../../services/countries";
import { AccountPortfolioFilter } from "./AccountPortfolioFilter";

const geographicalFilter = (
  account: AccountDetails,
  geographicalFilters: GeographicalFilter[],
): boolean => {
  const { continentCode } = countries[account.countryCode];
  return geographicalFilters.some((filter) => {
    switch (filter.type) {
      case "city":
        return account.city === filter.value;
      case "continent":
        return continentCode === filter.value;
      default:
        return account.countryCode === filter.value;
    }
  });
};

const accountFilter =
  (filter: AccountPortfolioFilter, selectedAccounts: string[]) =>
  (account: AccountDetails): boolean => {
    let result = true;
    if (filter.showOnlySelected) {
      result = selectedAccounts.includes(account.id) && result;
    }
    if (filter.accountStatus) {
      result = account.status === filter.accountStatus && result;
    }
    if (filter.accountName) {
      const re = new RegExp(filter.accountName, "ig");
      result = re.test(account.name) && result;
    }
    if (filter.geography && filter.geography.length > 0) {
      const geographicalFilters =
        convertMultiOptionItemTreeToGeographicalFilter(filter.geography);
      result = geographicalFilter(account, geographicalFilters) && result;
    }
    return result;
  };

const filterAccounts = <T extends AccountDetails>(
  accounts: T[],
  filter: AccountPortfolioFilter,
  selectedAccounts: string[],
): T[] => {
  const filterFn = accountFilter(filter, selectedAccounts);
  return accounts.filter(filterFn);
};

export default filterAccounts;
