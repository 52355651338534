import { z } from "zod";

import { ShowroomSeasonList } from "./types/enums";

export const budgetSchema = z.object({
  season: z.enum(ShowroomSeasonList),
  year: z.number(),
  accountId: z.string().uuid(),
  collectionId: z.string().uuid(),

  otb: z.number().nullable(),
  targetBudget: z.number().nullable(),
  actualBudget: z.number().nullable(),
  notes: z.string().nullable(),
});

export type Budget = z.infer<typeof budgetSchema>;
